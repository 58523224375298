import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

//hooks
import useMutationAddNewRefurralCode from '../__hooks/useMutationAddNewRefurralCode';
//types
import { IDialogProps, IAddNewRefurralCodeSchema } from 'types';
//validation
import { AddNewRefurralCodeSchema } from './Validation';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddNewRefurralCodeDialog = ({ open, handleClose }: IDialogProps) => {
  const handleDialogDismiss = () => {
    setValue('code', '');
    handleClose();
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IAddNewRefurralCodeSchema>({ resolver: yupResolver(AddNewRefurralCodeSchema) });

  //mutation
  const { addNewRefurralCodeMutate, newCodeIsAdding } =
    useMutationAddNewRefurralCode(handleDialogDismiss);

  const onSubmit = (data: IAddNewRefurralCodeSchema) => addNewRefurralCodeMutate(data);

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      TransitionComponent={Transition}
      onClose={handleDialogDismiss}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle bgcolor="darkblue" color="white">
        Add New Refurral Code
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          width="30vw"
          height="auto"
          m={2}
          position="relative"
        >
          <Form.Label>Refurral Code</Form.Label>
          <Form.Control
            bsPrefix="input form-control mb-0 py-3"
            placeholder="Enter refurral code"
            {...register('code')}
          />
          {errors && errors.code && <span className="text-error mt-2">{errors.code.message}</span>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" sx={{ bgcolor: 'lightgray' }} onClick={handleDialogDismiss}>
          Cancel
        </Button>
        <Button variant="contained" disabled={newCodeIsAdding} onClick={handleSubmit(onSubmit)}>
          {newCodeIsAdding ? (
            <AiOutlineLoading3Quarters
              size={20}
              color="blue"
              style={{ margin: '0.2rem' }}
              className="loading-animation svg-loading"
            />
          ) : (
            'Add'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewRefurralCodeDialog;
