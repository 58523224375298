import React, { useContext, useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { CalendarMonth } from '@mui/icons-material';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import 'bootstrap/dist/css/bootstrap.min.css';

//services
import { AuthState } from 'services/auth';
//types
import { IPawfficeHour, TAuthContext, TError } from 'types';
//hooks
import { useMutationRegisterToPawfficeHour } from '../__hooks/useMutationRegisterToPawfficeHour';
import { useMutationUnregisterFromPawfficeHour } from '../__hooks/useMutationUnregisterFromPawfficeHour';
//components
import KitImg from 'components/common/KitImg';
import TTButton from 'components/common/TTButton';
//styles
import './index.scss';
import { AxiosError } from 'axios';

const PawfficeHours = ({
  isLoading,
  data,
  error,
}: {
  isLoading: boolean;
  data: IPawfficeHour[];
  error: AxiosError<TError> | null;
}) => {
  const buttonName = useRef<string>();
  const {
    state: { breederId },
  } = useContext(AuthState) as TAuthContext;
  const [showMore, setShowMore] = useState(false);
  const [expandedPosition, setExpandedPosition] = useState(0);

  //Mutation hooks
  const { registerPawfficeHourMutate, isRegistring, registerPawfficeHourError } =
    useMutationRegisterToPawfficeHour();
  const { unregisterPawfficeHourMutate, isUnregistring, unregisterPawfficeHourError } =
    useMutationUnregisterFromPawfficeHour();

  useEffect(() => {
    if (!showMore) setExpandedPosition(0);
  }, [showMore]);

  if (isLoading)
    return (
      <div className="d-flex justify-content-center my-3">
        <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
      </div>
    );

  if (error || !data)
    return (
      <div className="d-flex justify-content-center my-2">
        <>
          {error && (
            <p className="text-error">{error?.response?.data.ExceptionMessage || error?.message}</p>
          )}
        </>
      </div>
    );

  return (
    <div className="pawffice_hours">
      <div className="upcoming_pawffice_hour_heading">UPCOMING PAWFFICE HOURS</div>
      {data
        .filter((_, idx) => (showMore ? true : idx === 0))
        .map((pawffice, idx) =>
          expandedPosition === idx ? (
            <div key={idx}>
              <div className="card_image_container">
                <KitImg src={pawffice.pawfficeImageUrl} kitwidth={800} />
              </div>
              <div className="pawffice_info_container">
                <p className="pawffice_name">{pawffice.name}</p>
                <span className="host_name">Hosted by {pawffice.host.hostName}</span>
                <div className="d-flex justify-content-between mt-2 mb-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <CalendarMonth sx={{ color: '#CC7191' }} fontSize="small" />
                    <span className="date_text">
                      {moment(pawffice.startDate).format('MMM D, yyyy h:mm A')}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center ms-2">
                    <CalendarMonth sx={{ color: '#CC7191' }} fontSize="small" />
                    <span className="date_text">
                      {moment(pawffice.startDate)
                        .add(pawffice.duration.hours, 'hours')
                        .add(pawffice.duration.minutes, 'minutes')
                        .format('MMM D, yyyy h:mm A')}
                    </span>
                  </div>
                </div>

                <TTButton
                  className="btn-secondary-navy register_button"
                  width="100%"
                  height={40}
                  disabled={buttonName.current === 'PHMain' && (isRegistring || isUnregistring)}
                  loading={buttonName.current === 'PHMain' && (isRegistring || isUnregistring)}
                  text={pawffice.isRegistered ? 'Unregister' : 'Register'}
                  onClick={() => {
                    buttonName.current = 'PHMain';
                    if (pawffice.isRegistered) {
                      unregisterPawfficeHourMutate({
                        pawfficeId: pawffice._id,
                        breederId,
                      });
                    } else {
                      registerPawfficeHourMutate({
                        pawfficeId: pawffice._id,
                        breederId,
                      });
                    }
                  }}
                />

                {registerPawfficeHourError && (
                  <p className="text-error">
                    {registerPawfficeHourError?.response?.data.ExceptionMessage ||
                      registerPawfficeHourError?.message}
                  </p>
                )}
                {unregisterPawfficeHourError && (
                  <p className="text-error">
                    {unregisterPawfficeHourError?.response?.data.ExceptionMessage ||
                      unregisterPawfficeHourError?.message}
                  </p>
                )}
              </div>
            </div>
          ) : (
            <div
              className="pawffice_item"
              key={pawffice._id}
              onClick={() => setExpandedPosition(idx)}
            >
              <div className="pawffice_item_info">
                <div>
                  <p className="pawffice_name">{pawffice.name}</p>
                  <div className="d-flex">
                    <p className="host_name">{pawffice.host.hostName}</p>
                    <p className="date_text">
                      {moment(pawffice.startDate).format('MMM D, yyyy h:mm A')}
                    </p>
                  </div>
                </div>
                {buttonName.current === `PH${idx}` && (isRegistring || isUnregistring) ? (
                  <div className="d-flex align-self-bottom">
                    <AiOutlineLoading3Quarters
                      className="loading-animation svg-loading"
                      size={20}
                      color="#cc7191"
                    />
                  </div>
                ) : (
                  <p
                    className="register"
                    onClick={e => {
                      e.stopPropagation();
                      buttonName.current = `PH${idx}`;
                      if (pawffice.isRegistered) {
                        unregisterPawfficeHourMutate({
                          pawfficeId: pawffice._id,
                          breederId,
                        });
                      } else {
                        registerPawfficeHourMutate({ pawfficeId: pawffice._id, breederId });
                      }
                    }}
                  >
                    {pawffice.isRegistered ? 'Unregister' : 'Register'}
                  </p>
                )}
              </div>
              <div className="divider" />
            </div>
          ),
        )}
      <p className="show_more_less mt-2" onClick={() => setShowMore(!showMore)}>
        {showMore ? 'Show Less' : 'Show More'}
      </p>
    </div>
  );
};

export default PawfficeHours;
