import * as Yup from 'yup';

const behavioralCharacteristicsSchema = {
  characterstick: Yup.string().required('This field is required.'),
};

export const EditBreedEncyclopaediaSchema = Yup.object().shape({
  profilePic: Yup.string().required('This field is required.'),
  breedName: Yup.string().required('This field is required.'),
  shortDescription: Yup.string().required('This field is required.'),
  lifeExpectancy: Yup.string(),
  funFact: Yup.string(),
  breedType: Yup.array()
    .required('This field is required.')
    .min(1, 'Please select atleast 1 breed type.'),
  variationsSize: Yup.boolean(),
  generalSize: Yup.array()
    .required('This field is required.')
    .min(1, 'Please select atleast one size.')
    .when('variationsSize', (variationsSize, schema) => {
      if (!variationsSize) return schema.max(1, 'You can only select one size.');

      return schema;
    }),
  temperament: Yup.array()
    .required('This field is required.')
    .min(1, 'Please select atleast 1 temperament.'),
  coat: Yup.array().required('This field is required.').min(1, 'Please select atleast 1 coat.'),
  shedding: Yup.array()
    .required('This field is required.')
    .min(1, 'Please select atleast 1 shedding level.'),
  isHypoallergenic: Yup.boolean(),
  living: Yup.array()
    .required('This field is required.')
    .min(1, 'Please select atleast 1 living area.'),
  speciality: Yup.array()
    .required('This field is required.')
    .min(1, 'Please select atleast 1 speciality.'),
  barking: Yup.number().required('This field is required.'),
  barkingDescription: Yup.string().required('This field is required.'),
  cuteness: Yup.number().required('This field is required.'),
  cutenessDescription: Yup.string().required('This field is required.'),
  cuddliness: Yup.number().required('This field is required.'),
  cuddlinessDescription: Yup.string().required('This field is required.'),
  athleticism: Yup.number().required('This field is required.'),
  athleticismDescription: Yup.string().required('This field is required.'),
  maintenance: Yup.number().required('This field is required.'),
  maintenanceDescription: Yup.string().required('This field is required.'),
  trainability: Yup.number().required('This field is required.'),
  trainabilityDescription: Yup.string().required('This field is required.'),
  adaptability: Yup.number().required('This field is required.'),
  adaptabilityDescription: Yup.string().required('This field is required.'),
  energy: Yup.number().required('This field is required.'),
  energyDescription: Yup.string().required('This field is required.'),
  kidScore: Yup.number().required('This field is required.'),
  kidScoreDescription: Yup.string().required('This field is required.'),
  imageCarousel: Yup.array()
    .required('This field is required.')
    .min(1, 'Please add atleast 1 image.'),
  historyImage: Yup.string().required('This field is required.'),
  history: Yup.string().required('This field is required.'),
  behavioralCharacteristics: Yup.array()
    .of(Yup.object().shape(behavioralCharacteristicsSchema))
    .required('This field is required.')
    .min(1, 'Please add atleast 1 characterstick.'),
  petOwner: Yup.string().required('This field is required.'),
  health: Yup.string().required('This field is required.'),
  breedingSummary: Yup.string().required('This field is required.'),
  similarBreeds: Yup.array()
    .required('This field is required.')
    .min(1, 'Please add atleast 1 breed.'),
  // relatedArticles: Yup.array().required('This field is required.'),
});
