// TODO change all names from articles to videos
import React, { useEffect, useContext, useRef } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { includes, isEmpty } from 'lodash';
import 'react-datepicker/src/stylesheets/datepicker.scss';

//services
import { AuthState } from 'services/auth';
//hooks
import { useQueryUserList } from '../../Users/__hooks/useQueryUserList';
import useMutationAddNewVideo from '../__hooks/useMutationAddNewVideo';
import useMutationUpdateVideo from '../__hooks/useMutationUpdateVideo';
//types
import { IDialogProps, IAddNewVideoSchema, TAuthContext } from 'types';
//components
import InfoView from 'components/common/InfoView';
import CheckBox from 'components/common/CheckBox';
//validation
import { AddNewVideoSchema } from './Validation';
//styles
import { multiSelectStyles, selectStyles } from 'components/styled/select';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddNewVideoDialog({
  open,
  handleClose,
  knowledgeTagsOptions,
  userRolesOptions,
  videoDetails,
}: IDialogProps) {
  const TAG_LIMIT = 4;
  const errorSpanRef = useRef<HTMLDivElement | null>(null);

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: { breederId },
  } = useContext(AuthState) as TAuthContext;

  const {
    register,
    watch,
    handleSubmit,
    setError,
    control,
    trigger,
    clearErrors,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IAddNewVideoSchema>({
    resolver: yupResolver(AddNewVideoSchema),
    defaultValues: !isEmpty(videoDetails)
      ? {
          vimeoId: videoDetails?.vimeoId,
          title: videoDetails?.title,
          tags:
            videoDetails?.tags && videoDetails?.tags.map(item => ({ label: item, value: item })),
          visibility:
            videoDetails?.visibility &&
            videoDetails?.visibility.map(item => ({ label: item, value: item })),
          locked: videoDetails?.locked,
          publishStartDate: videoDetails?.publishStartDate,
          publishEndDate: videoDetails?.publishEndDate,
          createdBy: {
            label: `${videoDetails?.createdBy?.displayName} (${videoDetails?.createdBy?.email})`,
            value: `${videoDetails?.createdBy?.displayName} (${videoDetails?.createdBy?.email})`,
          },
        }
      : {},
  });

  const handleDialogDismiss = () => {
    reset();
    setValue('title', '');
    resetApiState();
    resetApiStateUpdate();
    handleClose();
  };
  const scrollToBottom = () =>
    errorSpanRef.current?.scrollIntoView({ inline: 'end', behavior: 'smooth' });

  //Mutation hooks
  const { data, refetchUserList } = useQueryUserList();
  const { addNewVideoMutate, addNewVideoIsLoading, videoCreationError, resetApiState } =
    useMutationAddNewVideo(handleDialogDismiss, scrollToBottom);
  const { updateVideoMutate, updateVideoIsLoading, videoUpdateError, resetApiStateUpdate } =
    useMutationUpdateVideo(handleDialogDismiss, scrollToBottom);
  useEffect(() => {
    if (open) refetchUserList();
  }, [open, refetchUserList]);

  const tags = watch('tags');

  useEffect(() => {
    if (!tags || !setError || !clearErrors) return;

    if (tags.length > 0) {
      clearErrors('tags');
    } else {
      setError('tags', { type: 'min', message: 'This field is required.' });
    }
  }, [tags, setError, clearErrors]);

  const onSubmit = (addNewVideoData: IAddNewVideoSchema) => {
    const author = data?.find(item => includes(addNewVideoData.createdBy.label, item.email));
    const videoTags = addNewVideoData.tags.map(item => item.value);
    const visibileRoles = addNewVideoData.visibility.map(item => item.value);

    const payload = {
      vimeoId: addNewVideoData.vimeoId,
      title: addNewVideoData.title,
      tags: videoTags,
      createdBy: author?._id || '',
      locked: addNewVideoData.locked,
      visibility: visibileRoles,
      publishStartDate: moment(addNewVideoData.publishStartDate).utc().format(),
      publishEndDate: addNewVideoData.publishEndDate
        ? moment(addNewVideoData.publishEndDate).utc().format()
        : '',
    };

    if (videoDetails?._id !== null) {
      return updateVideoMutate({ ...payload, _id: videoDetails?._id });
    } else {
      return addNewVideoMutate(payload);
    }
  };

  return (
    <div>
      <Dialog
        maxWidth="lg"
        open={open}
        TransitionComponent={Transition}
        onClose={handleDialogDismiss}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle bgcolor="darkblue" color="white">
          Add New Video
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            width="50vw"
            height="70vh"
            m={2}
            position="relative"
          >
            <Form.Label>Vimeo Id</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0 py-3"
              placeholder="Enter vimeo id"
              {...register('vimeoId')}
            />
            {errors && errors.vimeoId && (
              <span className="text-error mt-2">{errors.vimeoId.message}</span>
            )}

            <Form.Label>Title</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0 py-3"
              placeholder="Enter title"
              {...register('title')}
            />
            {errors && errors.title && (
              <span className="text-error mt-2">{errors.title.message}</span>
            )}

            <InfoView
              p={0}
              mt={3}
              label="Author Id"
              zIndex={102}
              value={
                <Controller
                  control={control}
                  name="createdBy"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      isSearchable
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onChange={(val: any) => onChange(val)}
                      value={value}
                      options={data?.map(item => ({
                        label: `${item.displayName} (${item.email})`,
                        value: `${item.displayName} (${item.email})`,
                      }))}
                      placeholder="Enter for which roles this article will be locked"
                      styles={selectStyles}
                      classNamePrefix="tt-select"
                    />
                  )}
                />
              }
              vertical
            />
            {errors && errors.createdBy && (
              <span className="text-error">{errors.createdBy.message}</span>
            )}

            <CheckBox
              checkboxClassName="mt-3"
              text="Unlock for Basic Breeder"
              defaultChecked={isEmpty(videoDetails?.locked)}
              onChange={e => {
                if (e.target.checked) setValue('locked', []);
                else setValue('locked', ['free']);
              }}
            />

            <InfoView
              p={0}
              mt={3}
              zIndex={100}
              label="Visible for:"
              value={
                <Controller
                  control={control}
                  name="visibility"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      isSearchable
                      value={value}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onChange={(val: any, actionMeta: any) => {
                        if (
                          watch('visibility') &&
                          watch('visibility').length >= TAG_LIMIT &&
                          actionMeta.action === 'select-option'
                        ) {
                          setError('visibility', {
                            type: 'custom',
                            message: ' The maximum number of tags you can select here is 4',
                          });
                        } else if (actionMeta.action === 'select-option') {
                          trigger('visibility');

                          return onChange(val);
                        } else if (
                          actionMeta.action === 'remove-value' &&
                          watch('visibility').length <= 1
                        ) {
                          setError('visibility', {
                            type: 'custom',
                            message: 'You must have at least one tag',
                          });
                        } else if (actionMeta.action === 'remove-value') {
                          trigger('visibility');

                          return onChange(val);
                        }
                      }}
                      options={userRolesOptions}
                      placeholder="Enter for which roles this article will be visible"
                      styles={multiSelectStyles}
                      classNamePrefix="tt-select"
                    />
                  )}
                />
              }
              vertical
            />
            {errors && errors.visibility && (
              <span className="text-error mt-2">{errors.visibility.message}</span>
            )}

            <Box display="flex" flexDirection="row" gap="20px" mt={2}>
              <InfoView
                flex={1}
                p={0}
                label="Start Date:"
                value={
                  <Controller
                    control={control}
                    name="publishStartDate"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        selected={value ? moment(value).toDate() : null}
                        minDate={moment(
                          moment().utc().utcOffset('-0700').format('yyyy-MM-DD'),
                        ).toDate()}
                        onChange={(date: Date) => onChange(date)}
                        popperClassName="react-datepicker-popper red-border"
                        dateFormat="yyyy-MM-dd"
                        placeholderText="YYYY-MM-DD"
                        customInput={<Form.Control bsPrefix="input form-control mt-2" />}
                      />
                    )}
                  />
                }
                vertical
              />

              <InfoView
                flex={1}
                p={0}
                label="End Date:"
                value={
                  <Controller
                    control={control}
                    name="publishEndDate"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        selected={value ? moment(value).toDate() : null}
                        minDate={
                          watch('publishStartDate')
                            ? moment(watch('publishStartDate')).toDate()
                            : null
                        }
                        onChange={(date: Date) => onChange(date)}
                        popperClassName="react-datepicker-popper"
                        dateFormat="yyyy-MM-dd"
                        placeholderText="YYYY-MM-DD"
                        customInput={<Form.Control bsPrefix="input form-control mt-2" />}
                      />
                    )}
                  />
                }
                vertical
              />
            </Box>
            <Box display="flex" flexDirection="row" gap="20px">
              <Box flex={1}>
                {errors && errors.publishStartDate && (
                  <span className="text-error mt-2">{errors.publishStartDate.message}</span>
                )}
              </Box>
              <Box flex={1}>
                {errors && errors.publishEndDate && (
                  <span className="text-error mt-2">{errors.publishEndDate.message}</span>
                )}
              </Box>
            </Box>

            <InfoView
              p={0}
              mt={3}
              label="Video tags:"
              value={
                <Controller
                  control={control}
                  name="tags"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      isSearchable
                      value={value}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onChange={(val: any, actionMeta: any) => {
                        if (
                          watch('tags') &&
                          watch('tags').length >= TAG_LIMIT &&
                          actionMeta.action === 'select-option'
                        ) {
                          setError('tags', {
                            type: 'custom',
                            message: ' The maximum number of tags you can select here is 4',
                          });
                        } else if (actionMeta.action === 'select-option') {
                          trigger('tags');

                          return onChange(val);
                        } else if (
                          actionMeta.action === 'remove-value' &&
                          watch('tags').length <= 1
                        ) {
                          setError('tags', {
                            type: 'custom',
                            message: 'You must have at least one tag',
                          });
                        } else if (actionMeta.action === 'remove-value') {
                          trigger('tags');

                          return onChange(val);
                        }
                      }}
                      options={knowledgeTagsOptions}
                      placeholder="Enter tags here (E.g. IT/Software, Pet parent communications, etc.)"
                      styles={multiSelectStyles}
                      classNamePrefix="tt-select"
                    />
                  )}
                />
              }
              vertical
            />
            {errors && errors.tags && (
              <span className="text-error mt-2">{errors.tags.message}</span>
            )}

            <span ref={errorSpanRef} className="text-error mt-4">
              {videoCreationError?.response?.data.ExceptionMessage || videoCreationError?.message}
            </span>
            <span ref={errorSpanRef} className="text-error mt-4">
              {videoUpdateError?.response?.data.ExceptionMessage || videoUpdateError?.message}
            </span>
            <br />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" sx={{ bgcolor: 'lightgray' }} onClick={handleDialogDismiss}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={addNewVideoIsLoading || updateVideoIsLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {addNewVideoIsLoading || updateVideoIsLoading ? (
              <AiOutlineLoading3Quarters
                size={20}
                color="blue"
                style={{ margin: '0.2rem' }}
                className="loading-animation svg-loading"
              />
            ) : (
              'Yes'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
