//services
import API from 'services/api';

//types
import {
  IBuyerFormPayload,
  IBuyerSettingsPayload,
  IAlgorithmTestingPayload,
  IBuyerAccountPayload,
} from 'types';

//Buyers
export const getBuyerAccountDetails = () => API.get('/buyer-account/details');
export const addBuyer = (payload: IBuyerFormPayload) => API.post('/buyer', payload);
export const updateBuyerSettings = ({ data }: { data: IBuyerSettingsPayload }) =>
  API.post('/buyer-account/change-matching-preferences', data);
export const updateMatchAcceptance = () => API.put('/buyer-account/accepts-cron-emails');
export const updateBuyerProfile = (data: IBuyerAccountPayload) =>
  API.put('/buyer-account/profile', data);
export const activateBuyerProfile = () => API.put('/buyer-account/activate-profile');

//Match
export const getImportantFactors = () => API.get('/factors');
export const getPrototypeDefaultWeights = () => API.get('/match/prototype-default-weights');
export const testMatchPrototypeWithBuyerInfo = (data: IAlgorithmTestingPayload) =>
  API.post('/buyer-account/match-prototype', data);

//Verify phone number
export const sendCodeToPhone = (phoneNumber: string) => API.put('/sms/send-code', { phoneNumber });
export const verifySMSCode = (smsCode: string) => API.put('/sms/verify-sms-code', { smsCode });
