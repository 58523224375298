import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { deleteConversation, deleteReply } from 'services/api/apiCalls/admin';
//types
import { ICommentRequestPayload, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useMutationDeleteConversationOrReply = (setDialogContent: (dialogContent: null) => void) => {
  const queryClient = useQueryClient();

  const { mutate: deleteConversationOrReplyMutate, isLoading: isDeletingComment } = useMutation<
    AxiosResponse<string>,
    AxiosError<TError>,
    ICommentRequestPayload
  >(
    data =>
      data.type.toLowerCase() === 'new reply' ? deleteReply(data.id) : deleteConversation(data.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.COMMENT_LIST, {
          refetchInactive: true,
        });
        setDialogContent(null);
      },
    },
  );

  return {
    deleteConversationOrReplyMutate,
    isDeletingComment,
  };
};

export default useMutationDeleteConversationOrReply;
