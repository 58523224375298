import React, { useState, useMemo, useRef } from 'react';
import { Box } from '@mui/material';
import { MdVerified } from 'react-icons/md';
import MaterialReactTable, { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';

//hooks
import { useQueryGetUnvouchedUsers } from './__hooks/useQueryGetUnvouchedUsers';
import useMutationSetToVouched from './__hooks/useMutationSetToVouched';
//types
import { IVouchedTableRows, IDialogContent } from 'types';
//components
import TTButton from 'components/common/TTButton';
import ActionButton from 'components/common/ActionButton';
import AlertDialog from 'components/common/AlertDialog';
//constants
import { csvOptions } from 'helpers/cvsOptions';

const VouchedTable = () => {
  const userRef = useRef('');
  const [dialogContent, setDialogContent] = useState<IDialogContent | null>(null);

  //Mutation hooks
  const { isError, data, total, isLoading, isFetching } = useQueryGetUnvouchedUsers();
  const { mutate, isLoading: isVouchingUser } = useMutationSetToVouched(setDialogContent);

  const columns = useMemo<MRT_ColumnDef<IVouchedTableRows>[]>(
    () => [
      {
        header: 'ID',
        accessorKey: '_id',
        size: 200,
      },
      {
        header: 'First Name',
        accessorKey: 'firstName',
        size: 200,
      },
      {
        header: 'Last Name',
        accessorKey: 'lastName',
        size: 200,
      },
      {
        header: 'Display Name',
        accessorKey: 'email',
        size: 200,
      },
      {
        header: 'Business Program',
        accessorKey: 'businessProgram',
        size: 200,
      },
    ],
    [],
  );

  // Download table to CSV format logic
  const csvConfig = csvOptions(
    columns,
    `Unlinked_shopify_accounts_${moment().utc().utcOffset('-0700').format('MMMM_D_YYYY_hh_mm_A')}`,
  );
  const csvExporter = new ExportToCsv(csvConfig);
  const csvData = useMemo(
    () =>
      data?.map(item => ({
        _id: item._id,
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
        businessProgram: item.businessProgram,
      })),
    [data],
  );
  const handleExportData = () => {
    csvExporter.generateCsv(csvData);
  };

  const handleSetToVouchedPress = (row: MRT_Row<IVouchedTableRows>) => {
    userRef.current = row.original._id;
    setDialogContent({
      title: 'Set to Vouched?',
      message: 'You are about to set this user to Vouched. You really want to do it?',
    });
  };

  // Render table actions column
  const renderTableActions = ({ row }: { row: MRT_Row<IVouchedTableRows> }) => {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" gap="0.5rem">
        <ActionButton
          title="Set to Vouched"
          color="#8bc2de"
          Icon={<MdVerified />}
          onClick={() => handleSetToVouchedPress(row)}
        />
      </Box>
    );
  };

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data ?? []}
        rowCount={total ?? 0}
        initialState={{ pagination: { pageSize: 10, pageIndex: 0 } }}
        memoMode="cells"
        state={{
          isLoading: isLoading,
          showAlertBanner: isError,
          showProgressBars: isFetching,
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 20, 50],
        }}
        enableColumnOrdering
        enableGlobalFilter={false}
        renderTopToolbarCustomActions={() => (
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              p: '0.5rem',
              pb: '2rem',
            }}
          >
            <TTButton
              className="btn-primary-orchid"
              height={40}
              width={200}
              disabled={isLoading || isFetching}
              text="Export to CSV"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
            />
          </Box>
        )}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={renderTableActions}
      />

      {/* Confirmation Dialog */}
      <AlertDialog
        isOpened={Boolean(dialogContent)}
        dialogContent={dialogContent}
        isLoading={isVouchingUser}
        onAgree={() => {
          mutate(userRef.current);
        }}
        handleClose={() => setDialogContent(null)}
      />
    </>
  );
};

export default VouchedTable;
