/* eslint-disable @typescript-eslint/no-explicit-any */
export const csvOptions = (columns: any, filename = 'TellTail.csv') => {
  return {
    filename,
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c: any) => (c.header ? c.header : c)),
  };
};
