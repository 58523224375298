import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

//services
import { getBuyerDetailsForAdmin } from 'services/api/apiCalls/admin';
//types
import { IBuyerInfo } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useQueryGetBuyerDetails = (buyerAccountId: string) => {
  const {
    data: buyerInfo,
    error: errorBuyer,
    isLoading: isLoadingBuyer,
    isFetching: isFetchingBuyer,
  } = useQuery<AxiosResponse<IBuyerInfo>, Error>(
    [QUERY_KEYS.BUYER_ACCOUNT_DETAILS_FOR_ADMIN],
    () => getBuyerDetailsForAdmin(buyerAccountId),
    {
      retry: false,
    },
  );

  return { errorBuyer, buyerInfo: buyerInfo?.data, isLoadingBuyer, isFetchingBuyer };
};
