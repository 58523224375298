import LogRocket from 'logrocket';

//types
import { IEventSink, IEventSinkMeta } from 'types';

function eventSink(eventData: IEventSink, eventMetaData: IEventSinkMeta) {
  return LogRocket.track(eventData.type, {
    ...eventData,
    ...eventMetaData,
  });

  //TODO we will add more events here, for GA, our custom event tracking API, etc.
}

export default eventSink;
