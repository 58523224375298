import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { deletePawfficeHour } from 'services/api/apiCalls/admin';
//types
import { TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useMutationDeletePawfficeHour = (setDialogContent: (dialogContent: null) => void) => {
  const queryClient = useQueryClient();

  const { mutate: deletePawfficeHourMutate, isLoading: isDeleting } = useMutation<
    AxiosResponse<string>,
    AxiosError<TError>,
    string
  >(pawfficeId => deletePawfficeHour(pawfficeId), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.ADMIN_PAWFFICE_HOURS_LIST, {
        refetchInactive: true,
      });
      setDialogContent(null);
    },
  });

  return {
    deletePawfficeHourMutate,
    isDeleting,
  };
};
