import React from 'react';
import { GroupHeadingProps, components } from 'react-select';

import { ISelectOption } from 'types';

const GroupHeading = (props: GroupHeadingProps<ISelectOption, false>) => (
  <div style={{ borderBottom: '1px solid #d0d4e1 ', padding: '5px 0px' }}>
    <components.GroupHeading {...props} />
  </div>
);

export default GroupHeading;
