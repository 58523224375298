import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { flagForReview } from 'services/api/apiCalls/admin';
//types
import { TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useMutationFlagForReview = (setDialogContent: (dialogContent: null) => void) => {
  const queryClient = useQueryClient();

  const { mutate: flagForReviewMutate, isLoading: isFlagingForReview } = useMutation<
    AxiosResponse<string>,
    AxiosError<TError>,
    string
  >(breederId => flagForReview(breederId), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.BREEDER_LIST, {
        refetchInactive: true,
      });
      setDialogContent(null);
    },
  });

  return {
    flagForReviewMutate,
    isFlagingForReview,
  };
};

export default useMutationFlagForReview;
