import React from 'react';

//types
import { ICheckBox } from 'types';
//styles
import './index.scss';

const CheckBox = ({ id, text, checkboxClassName, ...props }: ICheckBox) => {
  return (
    <label htmlFor={id} className={`checkbox ${checkboxClassName && checkboxClassName}`}>
      <input id={id} type="checkbox" {...props} />
      <span></span>
      {text}
    </label>
  );
};

export default CheckBox;
