import { AxiosRequestConfig, AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { isEmpty } from 'lodash';

export const setInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      if (config.headers === undefined) {
        config.headers = {};
      }

      if (!isEmpty(window.localStorage.getItem('DEV_PANEL_BACKEND_URL'))) {
        config.baseURL = window.localStorage.getItem('DEV_PANEL_BACKEND_URL') as string;
      }

      const accessToken = window.localStorage.getItem('ACCESS_TOKEN');

      if (accessToken) {
        config.headers['Authorization'] = 'Bearer ' + accessToken;
      }

      return config;
    },
    (error: AxiosError) => Promise.reject(error),
  );

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    error => {
      const originalRequest = error.config;

      // TODO We will need to update this file API calls, I just created random ones that
      // TODO we can easily switch once we know exact routes, NO REFRESH TOKEN LOGIC YET
      if (error.response.status === 401 && originalRequest.url === '/user/status') {
        window.localStorage.removeItem('ACCESS_TOKEN');
        window.localStorage.removeItem('BREEDER_ID');

        window.location.href = '/login';

        return Promise.reject(error);
      }

      if (
        (error.response.status === 404 || error.response.status === 500) &&
        originalRequest.url === '/user/status'
      ) {
        window.localStorage.removeItem('ACCESS_TOKEN');
        window.localStorage.removeItem('BREEDER_ID');

        window.location.href = '/login';

        return Promise.reject(error);
      }

      // Check if any api except login gives 403 error, remove access token
      // and breederId. Also, navigate user to login page with error code 403.
      if (error.response.status === 403 && originalRequest.url !== '/auth/login') {
        window.localStorage.removeItem('ACCESS_TOKEN');
        window.localStorage.removeItem('BREEDER_ID');

        window.location.href = '/login?error=403';

        return Promise.reject(error);
      }

      // if (error.response.status === 401 && !originalRequest._retry) {
      //   originalRequest._retry = true;

      //   const formData = new FormData();
      //   formData.append('grant_type', 'refresh_token');
      //   formData.append('refresh_token', window.localStorage.getItem('REFRESH_TOKEN') || '');

      //   return axiosInstance
      //     .post('/connect/token', formData, {
      //       headers: { 'Content-Type': 'multipart/form-data' },
      //     })
      //     .then(res => {
      //       if (res.status === 201 || res.status === 200) {
      //         // 1) Put token to localStorage
      //         window.localStorage.setItem('ACCESS_TOKEN', res.data.accessToken);

      //         // 2) Change Authorization header
      //         const accessToken = window.localStorage.getItem('ACCESS_TOKEN');
      //         axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;

      //         // 3) return originalRequest object with Axios.
      //         return axiosInstance(originalRequest);
      //       }
      //     })
      //     .catch(err => {
      //       if (err.response.status === 400) {
      //         window.localStorage.removeItem('ACCESS_TOKEN');
      //         // window.localStorage.removeItem('REFRESH_TOKEN');

      //         window.location.href = '/login';
      //       }
      //     });
      // }

      return Promise.reject(error);
    },
  );
};
