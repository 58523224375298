import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useToasts } from 'react-toast-notifications';
import { useNavigate } from 'react-router-dom';

//services
import { signIn } from 'services/api/apiCalls/auth';
//types
import { ILoginForm, ILoginResponse, TLoginMutation } from 'types';

const useMutationLogin = ({ setTokens, setError, resendConfirmationEmail }: TLoginMutation) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const { mutate, status, error } = useMutation<ILoginResponse, AxiosError<Error>, ILoginForm>(
    signIn,
    {
      onSuccess: data => {
        if (data !== undefined) {
          setTokens(data?.data?.accessToken);
        } else {
          setError('password', {
            type: 'custom',
            message: 'Username or password is incorrect',
          });
        }
      },
      onError: (err, payload) => {
        /**
         * if error is 401 - Show error message for invalid credentials
         * else if error is 403 - Show error message for forbidden access
         */
        if (err?.response?.status === 401) {
          setError('username', {
            type: 'custom',
            message: err?.response?.data?.message || 'Username or password is incorrect',
          });
          setError('password', {
            type: 'custom',
            message: err?.response?.data?.message || 'Username or password is incorrect',
          });

          addToast(err?.response?.data?.message || 'Username or password is incorrect.', {
            appearance: 'error',
            autoDismiss: true,
          });
        } else if (err?.response?.status === 403) {
          addToast(
            'Sorry, there is no active account associated with this address. If this is in error, please contact us at hello@telltail.com.',
            {
              appearance: 'error',
              autoDismiss: false,
            },
          );
        }

        if (err?.response?.data?.message === 'Please confirm your email') {
          resendConfirmationEmail({ emailAddress: payload.username as string });
          window.scrollTo({ top: 0 });

          navigate('/resend-verification-email');
        }
      },
    },
  );

  return {
    mutate,
    status,
    error,
  };
};

export default useMutationLogin;
