import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { updateMatchAcceptance } from 'services/api/apiCalls/buyer';
//types
import { IBuyerInfo, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useMutationUpdateMatchAcceptance = () => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation<AxiosResponse<IBuyerInfo>, AxiosError<TError>>(updateMatchAcceptance, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.BUYER_ACCOUNT_DETAILS, {
        refetchInactive: true,
      });
      addToast('Your Match preferences has been updated!', {
        appearance: 'success',
        autoDismiss: true,
      });
    },
    onError: async () => {
      addToast('Something went wrong with your request.', {
        appearance: 'error',
        autoDismiss: true,
      });
    },
  });
};
