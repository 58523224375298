import React, { useState, useRef, useEffect } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { map, isEmpty } from 'lodash';

//types
import { TBuyerStepper } from 'types';
//hooks
import { useMutationVerifySMSCode } from '../__hooks/useMutationVerifyPhoneNumber';
//styles
import '../index.scss';

export default function SMSVerification({ setStep, step, buyerInfo }: TBuyerStepper) {
  const CODE_LENGTH = 6;
  const [values, setValues] = useState<string[]>(Array(CODE_LENGTH).fill(''));
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

  // Mutation hooks
  const { mutate: verifySMSCode, isLoading } = useMutationVerifySMSCode();

  const onComplete = (code: string) => {
    return verifySMSCode(code);
  };

  const focusNextInput = (index: number): void => {
    if (index < CODE_LENGTH - 1) {
      inputsRef.current[index + 1]?.focus();
    }
  };

  const focusPrevInput = (index: number): void => {
    if (index > 0) {
      inputsRef.current[index - 1]?.focus();
    }
  };

  const handleChange = (value: string, index: number): void => {
    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues);

    if (value) {
      focusNextInput(index);
    }

    if (newValues.every(num => num !== '')) {
      onComplete(newValues.join(''));
    }
  };

  const handlePaste = (e: React.ClipboardEvent): void => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text').slice(0, CODE_LENGTH).split('');
    if (pasteData.length === CODE_LENGTH) {
      setValues(pasteData);
      onComplete(pasteData.join(''));
      // Set a timeout to ensure the state is set before trying to focus
      setTimeout(() => inputsRef.current[CODE_LENGTH - 1]?.focus(), 0);
    }
  };

  useEffect(() => {
    inputsRef.current[0]?.focus();
  }, []);

  return (
    <div className="check-email-container">
      <h2 className="text-center">SMS Verification</h2>
      <h5 className="text-center">
        A 6-digit code has been sent to <span>{buyerInfo.phone}</span> for verification
      </h5>

      <div className="six-digit-verify" onPaste={handlePaste}>
        {map(values, (value, index) => (
          <input
            className={`digit-cell ${!isEmpty(value) && 'digit-cell--active'}`}
            key={`input-${index}`}
            ref={el => (inputsRef.current[index] = el)}
            value={value}
            onChange={e => handleChange(e.target.value, index)}
            onKeyDown={e => {
              if (e.key === 'Backspace' && !values[index]) {
                focusPrevInput(index);
              }
            }}
            maxLength={1}
            type="text"
            inputMode="numeric"
            pattern="\d*"
          />
        ))}
      </div>

      <p className="text">
        Didn’t receive a code?{' '}
        <span
          className={`text__link ${isLoading && 'text--disabled'}`}
          onClick={() => {
            setStep(step - 1);
          }}
        >
          {isLoading ? (
            <AiOutlineLoading3Quarters
              size={20}
              color="#cc7191"
              className="loading-animation svg-loading"
            />
          ) : (
            'Resend or use a different number.'
          )}
        </span>
      </p>
    </div>
  );
}
