import React from 'react';

//types
import { IBreederPublic } from 'types';

const AboutMe = ({
  isAuthenticated,
  breederInfo,
}: {
  isAuthenticated: boolean;
  breederInfo: IBreederPublic;
}) => {
  const facebookUrl = breederInfo.facebookUrl;
  const instagramUrl = breederInfo.instagramUrl;
  const tikTokUrl = breederInfo.tikTokUrl;
  const twitterUrl = breederInfo.twitterUrl;
  const youtubeUrl = breederInfo.youtubeUrl;
  const websiteUrl = breederInfo.breedingProgramWebsite;

  return (
    <div id="about" className="breederProfile__aboutMe network">
      <div className="breederProfile__aboutMeContainer">
        <h5>Get in touch</h5>
        <div className="d-flex flex-wrap w-100 h-100 mt-4">
          {websiteUrl && (
            <div className="network_container">
              <p className="site">Website</p>
              <div className="site light">
                <a
                  href={!websiteUrl.startsWith('http') ? `https://${websiteUrl}` : websiteUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {websiteUrl}
                </a>
              </div>
            </div>
          )}
          {breederInfo.yearsOfExperience && (
            <div className="network_container">
              <p className="site">Years of Experience</p>
              <div className="site light">{breederInfo.yearsOfExperience}</div>
            </div>
          )}
          {facebookUrl && (
            <div className="network_container">
              <p className="site">Facebook</p>
              <div className="site light">
                <a
                  href={!facebookUrl.startsWith('http') ? `https://${facebookUrl}` : facebookUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {facebookUrl}
                </a>
              </div>
            </div>
          )}
          {instagramUrl && (
            <div className="network_container">
              <p className="site">Instagram</p>
              <div className="site light">
                <a
                  href={!instagramUrl.startsWith('http') ? `https://${instagramUrl}` : instagramUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {instagramUrl}
                </a>
              </div>
            </div>
          )}
          {isAuthenticated && breederInfo.email && (
            <div className="network_container">
              <p className="site">Email</p>
              <div className="site light">
                <a href={`mailto:${breederInfo.email}`} rel="noreferrer">
                  {breederInfo.email}
                </a>
              </div>
            </div>
          )}
          {tikTokUrl && (
            <div className="network_container">
              <p className="site">TikTok</p>
              <div className="site light">
                <a
                  href={!tikTokUrl.startsWith('http') ? `https://${tikTokUrl}` : tikTokUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {tikTokUrl}
                </a>
              </div>
            </div>
          )}
          {youtubeUrl && (
            <div className="network_container">
              <p className="site">YouTube</p>
              <div className="site light">
                <a
                  href={!youtubeUrl.startsWith('http') ? `https://${youtubeUrl}` : youtubeUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {youtubeUrl}
                </a>
              </div>
            </div>
          )}
          {twitterUrl && (
            <div className="network_container">
              <p className="site">Twitter</p>
              <div className="site light">
                <a
                  href={!twitterUrl.startsWith('http') ? `https://${twitterUrl}` : twitterUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {twitterUrl}
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default AboutMe;
