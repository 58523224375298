import * as Yup from 'yup';

export const ChangeEmailSchema = Yup.object().shape({
  newEmail: Yup.string().required('This field is required.').email('Invalid email.'),
});

export const BasicInfoSchema = (isProperAddress: boolean) =>
  Yup.object().shape({
    displayName: Yup.string().required('This field is required.'),
    firstName: Yup.string().required('This field is required.'),
    middleName: Yup.string().nullable(),
    lastName: Yup.string().required('This field is required.'),
    businessProgram: Yup.string().required('This field is required.'),
    fullAddress: Yup.string()
      .required('This field is required.')
      .test('isProperAddress', 'Please enter your full street address.', function () {
        return isProperAddress;
      }),
  });
