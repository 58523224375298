import React from 'react';
import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

//hooks
import useQueryGetRefurralCodeDetails from './__hooks/useQueryGetRefurralCodeDetails';
//types
import { IRefurralCodeUser } from 'types';

const RefurralCodeUser = ({
  isHeading,
  codeUser,
  number,
}: {
  isHeading?: boolean;
  number?: string;
  codeUser?: IRefurralCodeUser;
}) => {
  const bgColor = isHeading ? 'darkblue' : '#0000';
  const textColor = isHeading ? 'white' : '#333';

  return (
    <>
      <Box display="flex" bgcolor={bgColor}>
        <Box width="10%" display="flex" p={1}>
          <Typography
            variant="subtitle1"
            color={textColor}
            fontWeight={isHeading ? 'bold' : 'normal'}
          >
            {isHeading ? 'Number' : number}
          </Typography>
        </Box>

        <Box sx={{ width: '0.5px', background: textColor }} />

        <Box flex={0.5} display="flex" p={1}>
          <Typography
            variant="subtitle1"
            color={textColor}
            fontWeight={isHeading ? 'bold' : 'normal'}
          >
            {isHeading ? 'Name' : codeUser?.name}
          </Typography>
        </Box>

        <Box sx={{ width: '0.5px', background: textColor }} />

        <Box flex={0.8} display="flex" p={1}>
          <Typography
            variant="subtitle1"
            color={textColor}
            fontWeight={isHeading ? 'bold' : 'normal'}
          >
            {isHeading ? 'Email' : codeUser?.email}
          </Typography>
        </Box>

        <Box sx={{ width: '0.5px', background: textColor }} />

        <Box flex={1} display="flex" p={1}>
          <Typography
            variant="subtitle1"
            color={textColor}
            fontWeight={isHeading ? 'bold' : 'normal'}
          >
            {isHeading ? 'Program Name' : codeUser?.programName}
          </Typography>
        </Box>
      </Box>
      <Box borderBottom="0.5px solid black" />
    </>
  );
};

const RefurralCodeDetails = ({
  refurralCodeId,
  isExpanded,
}: {
  refurralCodeId: string;
  isExpanded: boolean;
}) => {
  const { isLoading, isError, data } = useQueryGetRefurralCodeDetails(refurralCodeId, isExpanded);

  if (isLoading)
    return (
      <Box display="flex" justifyContent="center">
        <AiOutlineLoading3Quarters
          size={30}
          style={{ margin: '0.2rem' }}
          className="loading-animation svg-loading"
        />
      </Box>
    );

  if (isError || !data)
    return (
      <p className="text-no-found" style={{ color: 'red' }}>
        Something went wrong.
      </p>
    );

  return (
    <Box border="0.5px solid black">
      <RefurralCodeUser isHeading />
      {!isEmpty(data) ? (
        data.map((codeUser, key) => (
          <RefurralCodeUser key={codeUser.email + key} codeUser={codeUser} number={`${key + 1}`} />
        ))
      ) : (
        <p className="text-no-found">No code user found.</p>
      )}
    </Box>
  );
};

export default RefurralCodeDetails;
