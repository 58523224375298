/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useState, useRef } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { Button, DialogActions } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BsCameraFill } from 'react-icons/bs';
import { isEmpty } from 'lodash';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

//types
import { ICreateProfileForm, IUpdateBreeder } from 'types';
//hooks
import useMutationUpdateProfileInfo from './__hooks/useMutationUpdateProfileInfo';
//components
import KitImg from 'components/common/KitImg';
//assets
import NoImageAvailable from 'assets/images/adminPanel/no-profile-pic.svg';
//helpers
import { getImage } from 'helpers/getImage';
//validation
import { CreateProfileSchema } from './Validation';
//services
import { getCloudflareUploadUrl, uploadImageForForms } from 'services/api/apiCalls/cloudflare';

const CreateProfile = ({ breeder, setShowSuccess, onCancelPress }: IUpdateBreeder) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [programPicture, setProgramPicture] = useState<File | string | null>(
    breeder.profilePicUrl || null,
  );

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<ICreateProfileForm>({
    resolver: yupResolver(CreateProfileSchema),
    defaultValues: !isEmpty(breeder)
      ? {
          profilePicUrl: breeder.profilePicUrl,
          breederProgramDescription: breeder.breederProgramDescription,
          responsibleBreedingDescription: breeder.responsibleBreedingDescription,
        }
      : {},
  });

  //Mutation hooks
  const { isLoading, mutate } = useMutationUpdateProfileInfo({
    setShowSuccess,
    setError,
  });

  const handleClickBrowse = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;

    const fileReader = new FileReader();
    fileReader.onload = () => {
      setValue('profilePicUrl', file.name);
      clearErrors('profilePicUrl');
      setProgramPicture(file);
    };
    fileReader.readAsDataURL(file);
  };

  const onSubmit: SubmitHandler<ICreateProfileForm> = async data => {
    const cloudflareUploadUrl = await getCloudflareUploadUrl();
    const programPictureUrl =
      cloudflareUploadUrl !== null && typeof programPicture !== 'string'
        ? await uploadImageForForms({
            pathAndName: 'programPicture',
            image: programPicture!,
            url: cloudflareUploadUrl,
          })
        : programPicture;

    const payload = {
      profilePicUrl: programPictureUrl as string,
      breederProgramDescription: data.breederProgramDescription || '',
      responsibleBreedingDescription: data.responsibleBreedingDescription || '',
    };

    mutate({ payload, breederId: breeder._id });
  };

  return (
    <>
      <div className="signupOnboarding__inputs">
        {/* Program picture */}
        <Form onSubmit={handleSubmit(onSubmit)} id="create-profile-form">
          <h2 className="mt-0">Upload Program Picture</h2>
          <Row>
            <Col
              xs={12}
              md={4}
              className={`d-flex flex-column  ${
                errors.breederProgramDescription ? 'mb-0 input-error' : 'mb-2'
              }`}
            >
              {programPicture ? (
                <KitImg
                  className="kit-image"
                  alt="Profile Image"
                  // TODO change when we have the image
                  src={getImage(programPicture, NoImageAvailable)}
                  kitwidth={200}
                />
              ) : (
                <div className={`breederProfile__uploadImg`}>
                  <BsCameraFill />
                </div>
              )}
              <input
                ref={fileInputRef}
                type="file"
                id="imageFile"
                accept="image/*"
                onChange={handleClickBrowse}
                onClick={event => {
                  (event.target as HTMLInputElement).value = ''; // Workarround to trigger onChange event for same file
                }}
                hidden
              />
              <p
                className="btn btn-link"
                style={{ fontSize: 14, marginTop: programPicture ? 10 : 0 }}
                onClick={() => fileInputRef.current?.click()}
              >
                {!programPicture ? 'Add Program Picture' : 'Change Program Picture'}
              </p>
            </Col>
          </Row>

          {/* User and his program */}
          <h2 className="mt-3">About Me and My Program...</h2>
          <Row>
            <Col
              xs={12}
              className={`mt-2 small ${
                errors.breederProgramDescription ? 'mb-0 input-error' : 'mb-4'
              }`}
            >
              <Form.Label>
                A very short description of me and my program* (max 140 characters)
              </Form.Label>
              <Form.Control
                bsPrefix="input form-control mb-0"
                type="text"
                placeholder="Enter text"
                as="textarea"
                maxLength={140}
                rows={3}
                {...register('breederProgramDescription')}
              />
              {errors && errors.breederProgramDescription && (
                <p className="mb-2 text-error">{errors.breederProgramDescription.message}</p>
              )}
            </Col>
          </Row>

          <Row>
            <Col
              xs={12}
              className={`mt-0 small ${
                errors.responsibleBreedingDescription ? 'mb-0 input-error' : 'mb-4'
              }`}
            >
              <Form.Label>I believe responsible breeding means...*</Form.Label>
              <Form.Control
                bsPrefix="input form-control mb-0"
                type="text"
                placeholder="Enter text"
                as="textarea"
                maxLength={1000}
                {...register('responsibleBreedingDescription')}
              />
              {errors && errors.responsibleBreedingDescription && (
                <p className="mb-2 text-error">{errors.responsibleBreedingDescription.message}</p>
              )}
            </Col>
          </Row>
        </Form>
      </div>
      <DialogActions
        sx={{ position: 'absolute', left: 0, bottom: 0, width: '100%', background: 'white' }}
      >
        <Button onClick={onCancelPress}>Close</Button>
        <Button disabled={isSubmitting || isLoading} onClick={handleSubmit(onSubmit)}>
          {isSubmitting || isLoading ? (
            <AiOutlineLoading3Quarters
              size={20}
              color="blue"
              style={{ margin: '0.2rem' }}
              className="loading-animation svg-loading"
            />
          ) : (
            'Update'
          )}
        </Button>
      </DialogActions>
    </>
  );
};

export default CreateProfile;
