import React, { useState, useMemo, useRef } from 'react';
import { Box } from '@mui/material';
import MaterialReactTable, { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { Delete } from '@mui/icons-material';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';

//hooks
import useQueryRefurralCodes from './__hooks/useQueryRefurralCodes';
import useMutationDeleteRefurralCode from './__hooks/useMutationDeleteRefurralCode';
//types
import { IDialogContent, IRefurralCode } from 'types';
//components
import TTButton from 'components/common/TTButton';
import ActionButton from 'components/common/ActionButton';
import AlertDialog from 'components/common/AlertDialog';
import RefurralCodeDetails from './RefurralCodeDetails';
import AddNewRefurralCodeDialog from './AddNewRefurralCodeDialog';
//constants
import { csvOptions } from 'helpers/cvsOptions';

const RefurralCodes = () => {
  const refurralCodeToDelete = useRef('');
  const [isAddRefurralCodeDialogVisible, setIsAddRefurralCodeDialogVisible] = useState(false);
  const [dialogContent, setDialogContent] = useState<IDialogContent | null>(null);

  //Query hooks
  const { isError, data, total, isLoading, refetchRefurralCodeList, isFetching } =
    useQueryRefurralCodes();
  //Mutation hooks
  const { deleteRefurralCodeMutate, isDeleting } = useMutationDeleteRefurralCode(setDialogContent);

  const columns = useMemo<MRT_ColumnDef<IRefurralCode>[]>(
    () => [
      {
        header: 'Refurral Code',
        accessorKey: 'code',
      },
      {
        header: 'Used Count',
        accessorKey: 'count',
      },
      {
        header: 'Status',
        accessorKey: 'active',
        accessorFn: row => (row.active ? 'Active' : 'Inactive'),
      },
    ],
    [],
  );

  // Download table to CSV format logic
  const csvRefurralCodeOptions = csvOptions(
    columns,
    `Refurral_Codes_TellTail_${moment().utc().utcOffset('-0700').format('MMMM_D_YYYY_hh_mm_A')}`,
  );
  const csvExporter = new ExportToCsv(csvRefurralCodeOptions);
  const csvData = useMemo(
    () =>
      data?.map(refurralCode => ({
        refurralCode: refurralCode.code,
        usedCount: refurralCode.count,
        active: refurralCode.active,
      })),
    [data],
  );
  const handleExportData = () => {
    csvExporter.generateCsv(csvData);
  };

  // Handle table actions logic
  const handleDeletePress = (row: MRT_Row<IRefurralCode>) => {
    refurralCodeToDelete.current = row.original._id;

    setDialogContent({
      title: 'Delete Refurral Code?',
      message: `Are you sure you want to delete ${row.original.code}?`,
    });
  };

  const renderRefurralCodeTableActions = ({ row }: { row: MRT_Row<IRefurralCode> }) => {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" gap="0.5rem">
        <ActionButton title="Delete" Icon={<Delete />} onClick={() => handleDeletePress(row)} />
      </Box>
    );
  };

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data ?? []}
        rowCount={total ?? 0}
        initialState={{ pagination: { pageSize: 10, pageIndex: 0 } }}
        memoMode="cells"
        state={{
          isLoading,
          showAlertBanner: isError,
          showProgressBars: isFetching,
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 20, 50],
        }}
        enableColumnOrdering
        enableGlobalFilter={false}
        renderTopToolbarCustomActions={() => (
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              p: '0.5rem',
              pb: '2rem',
            }}
          >
            <TTButton
              className="btn-primary-orchid"
              height={40}
              width={200}
              disabled={isLoading || isFetching}
              text="Export to CSV"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
            />
            <TTButton
              className="btn-primary-orchid"
              height={40}
              width={200}
              marginLeft="10px"
              text="Add New Code"
              onClick={() => setIsAddRefurralCodeDialogVisible(true)}
            />
          </Box>
        )}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={renderRefurralCodeTableActions}
        renderDetailPanel={({ row }) =>
          row.original.code && (
            <RefurralCodeDetails
              refurralCodeId={row.original._id}
              isExpanded={row.getIsExpanded()}
            />
          )
        }
      />

      {/* Confirmation Dialog */}
      <AlertDialog
        isOpened={Boolean(dialogContent)}
        dialogContent={dialogContent}
        isLoading={isDeleting}
        onAgree={() => {
          if (refurralCodeToDelete) deleteRefurralCodeMutate(refurralCodeToDelete.current);
        }}
        handleClose={() => setDialogContent(null)}
      />

      {/* Add New Refurral Code Dialog */}
      {isAddRefurralCodeDialogVisible && (
        <AddNewRefurralCodeDialog
          open={isAddRefurralCodeDialogVisible}
          handleClose={() => {
            refetchRefurralCodeList();

            setIsAddRefurralCodeDialogVisible(false);
          }}
        />
      )}
    </>
  );
};

export default RefurralCodes;
