import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

//services
import { getBreed } from 'services/api/apiCalls/breeds';
//types
import { IBreedDetails } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useQueryBreedDetails = (id: string, enabled: boolean) => {
  const {
    data: breedDetails,
    error: errorBreedDetails,
    isLoading: isLoadingBreedDetails,
    isFetching: isFetchingBreedDetails,
  } = useQuery<AxiosResponse<IBreedDetails>, Error>([QUERY_KEYS.BREED, id], () => getBreed(id), {
    enabled: !!enabled,
    retry: false,
  });

  return {
    errorBreedDetails,
    breedDetails: breedDetails?.data,
    isLoadingBreedDetails,
    isFetchingBreedDetails,
  };
};
