import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

//services
import { getUserStatus } from 'services/api/apiCalls/auth';
import { useDataLayer } from 'services/dataLayerContext';
//helpers
import {
  determineSubscriptionType,
  determineSubscriptionStatus,
  determineMemberType,
} from 'helpers/users';
//types
import { IUserInfo } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useQueryUserStatus = (setBreederId: (breederId: string) => void, enabled = false) => {
  const pushToDataLayer = useDataLayer();

  const {
    data: dataUser,
    error: errorUser,
    isLoading: isLoadingUser,
    isFetching: isFetchingUser,
  } = useQuery<AxiosResponse<IUserInfo>, Error>([QUERY_KEYS.USER_STATUS], () => getUserStatus(), {
    enabled: enabled,
    notifyOnChangeProps: ['data'],
    onSuccess: data => {
      const subscriptionType = determineSubscriptionType(data.data.subscriptionType);
      const memberType = determineMemberType(data.data.userRoles);
      const subscriptionStatus = determineSubscriptionStatus(data.data.subscriptionStatus);

      // Heap Analytics methods
      try {
        window.heap.load(process.env.REACT_APP_HEAP_ANALYTICS_API_KEY);
        // eslint-disable-next-line no-empty
      } catch (error) {}
      window.heap.identify(data.data.userId);
      window.heap.addUserProperties({ 'Member Type': memberType });
      window.heap.addUserProperties({ email: data.data.email });

      setBreederId(data.data.breederId);
      if (memberType === 'Buyer') window.localStorage.setItem('BUYER_ID', data.data.userId);

      // Data layer logic
      memberType !== 'Admin' &&
        pushToDataLayer({
          event: 'getSubscriptionType',
          componentName: 'Status API success',
          subscriptionType,
          subscriptionStatus,
          memberType,
        });
    },
  });

  return { errorUser, userInfo: dataUser?.data, isLoadingUser, isFetchingUser };
};
