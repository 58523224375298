import { useMutation, useQueryClient } from 'react-query';
// import { PutObjectCommandInput } from '@aws-sdk/client-s3';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { updateBreeder } from 'services/api/apiCalls/breeder';
//types
import { IBreeder, TError, IBreederPayload } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useMutationUpdateBreederInfo = (breederId: string) => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation<AxiosResponse<IBreeder>, AxiosError<TError>, IBreederPayload>(updateBreeder, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.BREEDER, breederId], {
        refetchInactive: true,
      });

      addToast('Your Program information has been updated!', {
        appearance: 'success',
        autoDismiss: true,
      });
    },
    onError: async () => {
      addToast('Something went wrong with your request.', {
        appearance: 'error',
        autoDismiss: true,
      });
    },
  });
};
