import React, { useContext, useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { FiInfo } from 'react-icons/fi';
// import { MdKeyboardArrowDown } from 'react-icons/md';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { includes, map, find } from 'lodash';

//types
import { TAuthContext, IUserInfo, IBreedWithSizeVairation } from 'types';
//components
import MyAccount from './MyAccount';
import DownloadMaterials from './DownloadMaterials';
import MyLitters from './MyLitters';
import MyProgram from './MyProgram';
import MyGallery from './MyGallery';
import MyLeadsBasic from './MyLeadsBasic';
import MyLeadsPro from './MyLeadsPro';
import MyReferrals from './MyReferrals';
import TTButton from 'components/common/TTButton';
import { Tooltip, TooltipTrigger, TooltipContent } from 'components/common/Tooltip';
//services
import { AuthState } from 'services/auth';
//helpers
import { getTabImage } from 'helpers/tabs';
//constants
import { breederAccountTabs, basicBreederAccountTabs } from 'constants/tabs';
import { QUERY_KEYS } from 'constants/queryKeys';
//hooks
import { useQueryBreedsWithSizeVariation } from 'pages/BuyerRegistration/__hooks/useQueryBreedsWithSizeVariation';
import { useQueryBreederInfo } from 'hooks/commonQueryHooks/useQueryBreederInfo';
//styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
//assets
import document from 'assets/images/breederProfile/document.svg';

const BreederAccount = ({ id }: { id?: string }) => {
  const queryClient = useQueryClient();
  const { userId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    state: { breederId, isAuthenticated },
  } = useContext(AuthState) as TAuthContext;
  const profileBreederId = id !== undefined ? id : userId !== undefined ? userId : breederId;

  //Query hooks
  const userInfo = queryClient.getQueryData(QUERY_KEYS.USER_STATUS) as {
    data: IUserInfo | undefined;
  };

  const { breeds, isLoadingBreeds, errorBreeds } = useQueryBreedsWithSizeVariation();
  const { errorBreeder, breederInfo, isLoadingBreeder, isFetchingBreeder } = useQueryBreederInfo(
    profileBreederId,
    isAuthenticated,
  );

  const isInReview = userInfo?.data?.subscriptionStatus === 'in-review';
  const activeAccountTabs =
    userInfo?.data?.isSfcBreeder && !isInReview ? breederAccountTabs : basicBreederAccountTabs;
  const [activeAccountTab, setActiveAccountTab] = useState(activeAccountTabs[0]);

  useEffect(() => {
    const acceptedTabPaths = map(activeAccountTabs, 'path');
    const currentTabPath = location.pathname.split('/')[2];
    if (currentTabPath && includes(acceptedTabPaths, currentTabPath)) {
      setActiveAccountTab(find(activeAccountTabs, { path: currentTabPath }) || activeAccountTab);
    }
  }, [activeAccountTabs, location.pathname, activeAccountTab]);

  if (isLoadingBreeder || isFetchingBreeder || isLoadingBreeds) {
    return (
      <div className="profileInfo__container">
        <Row>
          <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
        </Row>
      </div>
    );
  } else if (errorBreeder || errorBreeds) {
    return null;
  } else
    return (
      <>
        {breederInfo !== undefined && breeds !== undefined && (
          <div className="desktop-container">
            {userInfo?.data?.isSfcBreeder === true && (
              <div className="referral-banner" onClick={() => navigate('/profile/referrals')} />
            )}

            {activeAccountTab.path === 'program' &&
              userInfo?.data?.isSfcBreeder &&
              userInfo?.data?.isVouched &&
              !isInReview && (
                <div className="public_button_container">
                  <Link
                    className="text-decoration-none"
                    to={`/public-profile/${breederId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TTButton
                      type="submit"
                      className="btn-secondary-navy"
                      text={
                        <>
                          View My Public Profile{' '}
                          <img src={document} alt="public_profile_personal" />
                        </>
                      }
                      width="auto"
                      height="40px"
                      fontSize="12px"
                      borderRadius="10px"
                    />
                  </Link>
                </div>
              )}

            <Row className="profileInfo__container loaded">
              <Col xs={0} xl={4} className="profileInfo__tabsContainer">
                <div className="profileInfo__tabs">
                  {activeAccountTabs.map((tab, index) => (
                    <div
                      key={index}
                      className={`${
                        activeAccountTab.name === tab.name && 'active'
                      } profileInfo__tab`}
                      onClick={() => {
                        setActiveAccountTab(tab);
                        navigate(`/profile/${tab.path}`);
                      }}
                    >
                      <img src={getTabImage(tab.path)} alt="TT breeder" />
                      <p onClick={() => navigate(`/profile/${tab.path}`)}>{tab.name}</p>
                    </div>
                  ))}
                </div>
              </Col>

              <Col xs={12} xl={8}>
                <div className="profileInfo__content">
                  <div className="d-flex align-items-center">
                    <h4>{activeAccountTab.name}</h4>
                    <Tooltip>
                      <TooltipTrigger className="tooltip_trigger_account">
                        <FiInfo size={20} />
                      </TooltipTrigger>
                      <TooltipContent className="tooltip_account">
                        {activeAccountTab.tooltip}
                      </TooltipContent>
                    </Tooltip>
                  </div>

                  {activeAccountTab.path === 'leads' &&
                    (userInfo?.data?.isSfcBreeder ? (
                      <MyLeadsPro
                        isSfcBreeder={userInfo?.data?.isSfcBreeder as boolean}
                        isInReview={isInReview}
                      />
                    ) : (
                      <MyLeadsBasic
                        isSfcBreeder={userInfo?.data?.isSfcBreeder as boolean}
                        isInReview={isInReview}
                      />
                    ))}
                  {activeAccountTab.path === 'litters' && (
                    <MyLitters
                      breeds={breeds.map(val => ({
                        label: val.breedName,
                        value: val.breedName,
                      }))}
                      breederBreeds={map(breederInfo.breedsAndLeads, breedAndLead => ({
                        label: breedAndLead.breed,
                        value: breedAndLead.breed,
                      }))}
                    />
                  )}
                  {activeAccountTab.path === 'settings' && (
                    <MyAccount breederInfo={breederInfo} breederId={breederId} />
                  )}
                  {activeAccountTab.path === 'program' && (
                    <MyProgram
                      breederInfo={breederInfo}
                      breederId={breederId}
                      breeds={breeds as IBreedWithSizeVairation[]}
                    />
                  )}
                  {activeAccountTab.path === 'gallery' && (
                    <MyGallery breederInfo={breederInfo} breederId={breederId} />
                  )}
                  {activeAccountTab.path === 'materials' && userInfo?.data?.isSfcBreeder && (
                    <DownloadMaterials breederInfo={breederInfo} breederId={breederId} />
                  )}
                  {activeAccountTab.path === 'referrals' && (
                    <MyReferrals
                      isSfcBreeder={userInfo?.data?.isSfcBreeder as boolean}
                      breederInfo={breederInfo}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        )}

        {breederInfo !== undefined && breeds !== undefined && (
          <div className="mobile-container">
            {userInfo?.data?.isSfcBreeder === true && (
              <div className="referral-banner" onClick={() => navigate('/profile/referrals')} />
            )}

            <div className="profileInfo__content w-100">
              <h4
                className={`${activeAccountTab.path === 'leads' && 'leads'} ${
                  isInReview && 'in-review'
                }`}
              >
                {activeAccountTab.name}
              </h4>

              {activeAccountTab.path === 'leads' &&
                (userInfo?.data?.isSfcBreeder && !isInReview ? (
                  <MyLeadsPro
                    isSfcBreeder={userInfo?.data?.isSfcBreeder as boolean}
                    isInReview={isInReview}
                  />
                ) : (
                  <MyLeadsBasic
                    isSfcBreeder={userInfo?.data?.isSfcBreeder as boolean}
                    isInReview={isInReview}
                  />
                ))}
              {activeAccountTab.path === 'litters' && (
                <MyLitters
                  breeds={breeds.map(val => ({
                    label: val.breedName,
                    value: val.breedName,
                  }))}
                  breederBreeds={map(breederInfo.breedsAndLeads, breedAndLead => ({
                    label: breedAndLead.breed,
                    value: breedAndLead.breed,
                  }))}
                />
              )}
              {activeAccountTab.path === 'settings' && (
                <MyAccount breederInfo={breederInfo} breederId={breederId} />
              )}
              {activeAccountTab.path === 'program' && (
                <MyProgram
                  breederInfo={breederInfo}
                  breederId={breederId}
                  breeds={breeds as IBreedWithSizeVairation[]}
                />
              )}
              {activeAccountTab.path === 'gallery' && (
                <MyGallery breederInfo={breederInfo} breederId={breederId} />
              )}
              {activeAccountTab.path === 'materials' && userInfo?.data?.isSfcBreeder && (
                <DownloadMaterials breederInfo={breederInfo} breederId={breederId} />
              )}
              {activeAccountTab.path === 'referrals' && (
                <MyReferrals
                  isSfcBreeder={userInfo?.data?.isSfcBreeder as boolean}
                  breederInfo={breederInfo}
                />
              )}
            </div>
          </div>
        )}
      </>
    );
};

export default BreederAccount;
