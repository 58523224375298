import { StylesConfig } from 'react-select';
//types
import { TSelectIsMulti, TSelectIsNotMulti, TSelectOptionsType } from 'types';

export const selectStyles: StylesConfig<TSelectOptionsType, TSelectIsNotMulti> = {
  control: (styles, state) => ({
    ...styles,
    color: '#142669',
    borderRadius: '12px',
    fontFamily: '"Mont", Arial, Helvetica, sans-serif',
    fontSize: '1rem',
    height: '48px',
    width: '100%',
    boxShadow: 'none',
    border: '1px solid #D0D4E1',
    marginBottom: '0',
    borderColor: state.isFocused || state.menuIsOpen ? '#D68EA7 !important' : styles.borderColor,
  }),
  valueContainer: styles => ({
    ...styles,
    padding: '0 8px',
    height: '48px',
  }),
  singleValue: styles => ({
    ...styles,
    color: '#142669',
  }),
  input: styles => ({
    ...styles,
    padding: 0,
    height: '48px',
    color: '#142669',
    margin: 0,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  clearIndicator: () => ({
    display: 'none',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: '#CC7191',
  }),

  placeholder: styles => ({ ...styles, color: '#8993B4' }),
};

export const multiSelectStyles: StylesConfig<TSelectOptionsType, TSelectIsMulti> = {
  control: (styles, state) => ({
    ...styles,
    color: '#142669',
    borderRadius: '12px',
    fontFamily: '"Mont", Arial, Helvetica, sans-serif',
    fontSize: '1rem',
    minHeight: '48px',
    width: '100%',
    boxShadow: 'none',
    border: '1px solid #D0D4E1',
    marginBottom: '0',
    borderColor: state.isFocused || state.menuIsOpen ? '#D68EA7 !important' : styles.borderColor,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  clearIndicator: () => ({
    display: 'none',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: '#CC7191',
  }),
  multiValue: styles => {
    return {
      ...styles,
      backgroundColor: '#F5E3E9',
      color: '#E6B8C8',
      borderRadius: '8px',
    };
  },
  multiValueLabel: styles => ({
    ...styles,
    color: '#CC7191',
  }),
  input: styles => ({
    ...styles,
    border: 'none !important',
  }),

  placeholder: css => ({ ...css, color: '#8993B4' }),
};

export const selectStylesBreedDirectory: StylesConfig<TSelectOptionsType, TSelectIsNotMulti> = {
  control: (styles, state) => ({
    ...styles,
    color: '#142669',
    borderRadius: '1rem',
    fontFamily: '"Mont", Arial, Helvetica, sans-serif',
    fontSize: '1rem',
    height: '48px',
    minWidth: '300px',
    maxWidth: '500px',
    width: '80%',
    boxShadow: 'none',
    border: '1px solid #D0D4E1',
    marginBottom: '0',
    borderColor: state.isFocused || state.menuIsOpen ? '#D68EA7 !important' : styles.borderColor,
  }),
  valueContainer: styles => ({
    ...styles,
    padding: '0 8px',
    height: '48px',
  }),
  singleValue: styles => ({
    ...styles,
    color: '#142669',
  }),
  input: styles => ({
    ...styles,
    padding: 0,
    height: '48px',
    color: '#142669',
    margin: 0,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  clearIndicator: () => ({
    display: 'none',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: '#CC7191',
  }),

  placeholder: styles => ({ ...styles, color: '#8993B4' }),
};
