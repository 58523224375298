import React, { useMemo, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { Delete } from '@mui/icons-material';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

//hooks
import useQueryGetPawfficeHour from '../__hooks/useQueryGetPawfficeHour';
import { useMutationDeleteAttendee } from '../__hooks/useMutationDeleteAttendee';
//types
import { IDialogContent } from 'types';
//components
import Banner from 'components/common/HeadingBanner';
import TagItem from 'components/common/TagItem';
import InfoView from 'components/common/InfoView';
import KitImg from 'components/common/KitImg';
import ActionButton from 'components/common/ActionButton';
import AlertDialog from 'components/common/AlertDialog';
import TTButton from 'components/common/TTButton';
import AddNewAttendeeDialog from '../AddNewAttendeeDialog';
//styles
import './index.scss';

const PawfficeHoursDetailsPage = () => {
  const { id } = useParams();
  const attendeeId = useRef<string | null>();
  const [dialogContent, setDialogContent] = useState<IDialogContent | null>(null);
  const [showAddNewAttendeeDialog, setShowAddNewAttendeeDialog] = useState(false);

  //Query hooks
  const { isLoading, error, pawfficeHour, refetchPawfficeHour } = useQueryGetPawfficeHour(id!);
  //Mutation hooks
  const { deleteAttendeeMutate, isDeleting } = useMutationDeleteAttendee(setDialogContent);

  const endTime = useMemo(
    () =>
      pawfficeHour
        ? moment(pawfficeHour.startDate)
            .add(pawfficeHour.duration.hours, 'hours')
            .add(pawfficeHour.duration.minutes, 'minutes')
            .format('LLL')
        : undefined,
    [pawfficeHour],
  );

  // Handle table actions logic
  const handleDeletePress = (breederName: string, breederId: string) => {
    attendeeId.current = breederId;
    setDialogContent({
      title: 'Delete Attendee?',
      message: `Are you sure you want to delete ${breederName}?`,
    });
  };

  if (isLoading)
    return (
      <div className="d-flex width-full justify-content-center my-3">
        <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
      </div>
    );

  if (error || !pawfficeHour)
    return (
      <div className="d-flex width-full justify-content-center my-2">
        <>
          {error && (
            <p className="text-error">{error?.response?.data.ExceptionMessage || error?.message}</p>
          )}
        </>
      </div>
    );

  return (
    <>
      <Banner
        title={pawfficeHour.name}
        subTitle={`Hosted by ${pawfficeHour.host.hostName}`}
        bgColor="orchid"
      />
      <div className="pawffice_hours_details">
        <div className="pawffice_details_container">
          <div className="deal_image_container">
            <KitImg src={pawfficeHour.pawfficeImageUrl} kitwidth={800} />
          </div>
          <InfoView label="Pawffice Hour Id:" value={pawfficeHour._id} />
          <InfoView label="Start Date:" value={moment(pawfficeHour.startDate).format('LLL')} />
          <InfoView
            label="Duration:"
            value={`${pawfficeHour.duration.hours} hr ${pawfficeHour.duration.minutes} min (till ${endTime})`}
          />
          <InfoView
            label="Link:"
            value={
              <a href={pawfficeHour.call.link} target="_blank" rel="noopener noreferrer">
                {pawfficeHour.call.link}
              </a>
            }
          />

          <InfoView
            label="Dial:"
            value={
              <a href={`tel:${pawfficeHour.call.dialNumber}`}>{pawfficeHour.call.dialNumber}</a>
            }
          />
          <InfoView label="Description:" value={pawfficeHour.description || 'N/A'} />
          <InfoView
            label="Visibility:"
            value={
              <ul className="pawffice_tags mt-0">
                {pawfficeHour.visibility.map((visibility, idx) => (
                  <TagItem key={visibility + idx} name={visibility.toUpperCase()} />
                ))}
              </ul>
            }
          />
          <InfoView
            label="Tags:"
            value={
              <ul className="pawffice_tags mt-0">
                {pawfficeHour.tags.map((tag, idx) => (
                  <TagItem key={tag + idx} name={tag.toUpperCase()} />
                ))}
              </ul>
            }
          />

          <div className="d-flex justify-content-end mt-4 mb-3 me-2">
            <TTButton
              className="btn-primary-orchid"
              height={40}
              width={250}
              marginLeft="10px"
              text="Add New Attendee"
              onClick={() => setShowAddNewAttendeeDialog(true)}
            />
          </div>

          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>Full Name</th>
                <th>Email</th>
                <th>Program Name</th>
                <th>User ID</th>
                <th>SignUp Date</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {pawfficeHour.attendees.map((attendee, idx) => {
                return (
                  <tr key={attendee.breederId + idx}>
                    <td>{attendee.fullName}</td>
                    <td>{attendee.email}</td>
                    <td>{attendee.programName}</td>
                    <td>{attendee.breederId}</td>
                    <td>{moment(attendee.signUpDate).format('LLL')}</td>
                    <td>
                      <ActionButton
                        title="Delete"
                        Icon={<Delete />}
                        onClick={() => handleDeletePress(attendee.fullName, attendee.breederId)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {isEmpty(pawfficeHour.attendees) && <p className="text-no-found">No attendee found</p>}

          <AlertDialog
            isOpened={Boolean(dialogContent)}
            dialogContent={dialogContent}
            isLoading={isDeleting}
            onAgree={() => {
              if (attendeeId.current) {
                deleteAttendeeMutate({
                  pawfficeId: pawfficeHour._id,
                  breederId: attendeeId.current,
                });
              }
            }}
            handleClose={() => setDialogContent(null)}
          />

          {showAddNewAttendeeDialog && (
            <AddNewAttendeeDialog
              open={showAddNewAttendeeDialog}
              handleClose={() => {
                refetchPawfficeHour();
                setShowAddNewAttendeeDialog(false);
              }}
              pawfficeId={pawfficeHour._id}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PawfficeHoursDetailsPage;
