export const buyerNavLinks = [
  {
    groupName: 'My Account',
    links: [
      {
        name: 'My Matches',
        path: '/profile/matches',
      },
      {
        name: 'Match Settings',
        path: '/profile/match-settings',
      },
      {
        name: 'Account Settings',
        path: '/profile/information',
      },
    ],
  },
  {
    groupName: 'resources',
    links: [
      {
        name: 'Learning Center',
        path: 'https://www.telltail.com/learning-center',
      },
      {
        name: 'Breedopedia',
        path: '/breedopedia',
      },
      {
        name: 'Help & FAQs',
        path: '/faq',
      },
    ],
  },
];
