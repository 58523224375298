/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { AiOutlineArrowRight, AiOutlineArrowLeft, AiOutlineLink } from 'react-icons/ai';

//hooks
import { useQueryBreedDetails } from '../__hooks/useQueryBreedDetails';
import useWindowSize from 'hooks/useWindowSize';
//constants
import { breedDetailsTabs } from 'constants/tabs';
//components
import TTButton from 'components/common/TTButton';
import Paw from 'assets/images/breedDirectory/Paw2';
import { Tooltip, TooltipTrigger, TooltipContent } from 'components/common/Tooltip';
//types
import { ISimilarBreedsData } from 'types';
//helpers
import { aOrAn } from 'helpers/string';
//assets
import placeholderDog from 'assets/images/breedDirectory/placeholderDog.png';
import breedLvlInfo from 'assets/images/breedDirectory/breedLvlInfo.svg';
import breederExample from 'assets/images/breedDirectory/breederExample.png';
//styles
import './index.scss';

const LevelComponent = ({ level }: { level: number }) => {
  return (
    <div className="d-flex align-items-center gap-1">
      {Array(level)
        .fill(0)
        .map((_, key) => (
          <Paw key={key} fill="#CC7191" />
        ))}

      {level &&
        Array(5 - level)
          .fill(0)
          .map((_, key) => <Paw key={key} fill="#D0D4E1" />)}
    </div>
  );
};

const BreedDetails = () => {
  const navigate = useNavigate();
  const size = useWindowSize();
  const { breedId } = useParams() as { breedId: string };
  const [selectedTab, setSelectedTab] = useState<string>('overview');
  const [displayMoreInfoOverview, setDisplayMoreInfoOverview] = useState<boolean>(true);
  const [displayMoreInfoBehavior, setDisplayMoreInfoBehavior] = useState<boolean>(true);
  const [displayMoreInfoHealth, setDisplayMoreInfoHealth] = useState<boolean>(true);
  const [displayMoreInfoHistory, setDisplayMoreInfoHistory] = useState<boolean>(true);
  const [displayMoreInfoBreeding, setDisplayMoreInfoBreeding] = useState<boolean>(true);
  const [displayMoreInfoGallery, setDisplayMoreInfoGallery] = useState<boolean>(true);
  const [centralImageIndex, setCentralImageIndex] = useState<number>(0);
  const [tabsBackground, setTabsBackground] = useState<string>('transparent');
  const [copyUrlText, setCopyUrlText] = useState(
    size.width > 768 ? 'Copy / Share URL' : 'Copy URL',
  );

  //Query hooks
  const { breedDetails, isFetchingBreedDetails, errorBreedDetails } = useQueryBreedDetails(
    breedId,
    true,
  );

  useEffect(() => {
    const listenScrollEvent = () => {
      size.width > 768
        ? window.scrollY > 500
          ? setTabsBackground('white')
          : setTabsBackground('transparent')
        : window.scrollY > 240
        ? setTabsBackground('white')
        : setTabsBackground('transparent');
    };

    window.addEventListener('scroll', listenScrollEvent);

    return () => {
      window.removeEventListener('scroll', listenScrollEvent);
    };
  }, [size.width]);

  if (isFetchingBreedDetails)
    return (
      <div className="breeed_details_loader">
        <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
      </div>
    );
  else if (errorBreedDetails) return <span>Something went wrong</span>;
  else
    return (
      <div className="breeed_details_container">
        <div className="breeed_details_header">
          <div className="header_left">
            <p className="header_left_links">
              <div>
                <AiOutlineArrowLeft
                  onClick={() => navigate('/breedopedia')}
                  className="me-2 h-100"
                  size="20"
                />
                <span onClick={() => navigate('/breedopedia')} className="me-2">
                  Back to Breeds
                </span>
              </div>

              {size.width > 768 ? (
                <>
                  /
                  <span
                    onClick={() => navigate(`/breedopedia/${breedDetails?._id}`)}
                    className="ms-2"
                  >
                    {breedDetails?.breedName}
                  </span>
                </>
              ) : (
                <a
                  className="tt-hover gap-2 text-decoration-none"
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                    setCopyUrlText('URL is copied to clipboard!');
                    setTimeout(() => {
                      setCopyUrlText('Copy URL');
                    }, 2000);
                  }}
                >
                  <AiOutlineLink size={'15px'} />
                  <div>{copyUrlText}</div>
                </a>
              )}
            </p>
            <div>
              <h1>{breedDetails?.breedName}</h1>
              <p>{breedDetails?.shortDescription}</p>
            </div>
            {size.width > 768 && (
              <a
                className="tt-hover gap-2 text-decoration-none"
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href);
                  setCopyUrlText('URL is copied to clipboard!');
                  setTimeout(() => {
                    setCopyUrlText('Copy / Share URL');
                  }, 2000);
                }}
              >
                <AiOutlineLink size={'25px'} />
                <div>{copyUrlText}</div>
              </a>
            )}
          </div>
          {size.width > 768 && (
            <div className="header_right">
              <div className="header_logo_container">
                <img
                  src={breedDetails?.profilePic ? breedDetails?.profilePic : placeholderDog}
                  className="header_logo"
                />
              </div>
            </div>
          )}
        </div>

        <div className="breed_details_content">
          <div className="content_left">
            <div className={`breed_details_tabs ${tabsBackground !== 'transparent' && 'colored'}`}>
              {breedDetailsTabs.map(tab => (
                <div
                  key={tab.href}
                  className="breed_details_tab"
                  onClick={() => setSelectedTab(tab.href)}
                >
                  <p
                    className={`${selectedTab === tab.href && 'active'} ${
                      tabsBackground !== 'transparent' && 'colored'
                    }`}
                  >
                    {tab.name}
                  </p>
                  {selectedTab === tab.href && <div className="breed_details_tabline" />}
                </div>
              ))}
            </div>

            {selectedTab === 'overview' && (
              <>
                <div className="breed_details_card">
                  <div className="d-flex justify-content-between w-100">
                    <h5>Breed Overview</h5>
                    {displayMoreInfoOverview ? (
                      <MdKeyboardArrowUp
                        className=""
                        size={30}
                        onClick={() => setDisplayMoreInfoOverview(!displayMoreInfoOverview)}
                      />
                    ) : (
                      <MdKeyboardArrowDown
                        className="cursor-pointer"
                        size={30}
                        onClick={() => setDisplayMoreInfoOverview(!displayMoreInfoOverview)}
                      />
                    )}
                  </div>

                  {displayMoreInfoOverview && (
                    <>
                      <div className="d-flex align-items-center flex-wrap w-100 gap-4">
                        <div className="breed_details_levels">
                          <h6>Breed Type</h6>
                          <p>{breedDetails?.breedType}</p>
                        </div>

                        <div className="breed_details_levels">
                          <h6>Size</h6>
                          <p>
                            {breedDetails?.generalSize.map((val, key) =>
                              breedDetails?.generalSize?.length - 1 === key ? `${val}` : `${val}, `,
                            )}
                          </p>
                        </div>

                        <div className="breed_details_levels">
                          <h6>Temperament</h6>
                          <p>
                            {breedDetails?.temperament.map((val, key) =>
                              breedDetails?.temperament?.length - 1 === key ? `${val}` : `${val}, `,
                            )}
                          </p>
                        </div>

                        <div className="breed_details_levels">
                          <h6>Coat Type</h6>
                          <p>
                            {breedDetails?.coat.map((val, key) =>
                              breedDetails?.coat?.length - 1 === key ? `${val}` : `${val}, `,
                            )}
                          </p>
                        </div>

                        <div className="breed_details_levels">
                          <h6>Hypoallergenic</h6>
                          <p>{breedDetails?.isHypoallergenic ? 'Yes' : 'No'}</p>
                        </div>

                        <div className="breed_details_levels">
                          <h6>Shedding Level</h6>
                          <p>
                            {breedDetails?.shedding.map((val, key) =>
                              breedDetails?.shedding?.length - 1 === key ? `${val}` : `${val}, `,
                            )}
                          </p>
                        </div>

                        <div className="breed_details_levels">
                          <div className="d-flex align-items-start gap-2">
                            <h6>Barking Level</h6>

                            <Tooltip>
                              <TooltipTrigger className="tooltip_trigger_account">
                                <img
                                  src={breedLvlInfo}
                                  alt="breedLvlInfo"
                                  className="h-50 d-flex align-items-start"
                                />
                              </TooltipTrigger>
                              <TooltipContent className="tooltip_account">
                                {breedDetails?.barkingDescription}
                              </TooltipContent>
                            </Tooltip>
                          </div>
                          <LevelComponent level={breedDetails?.barking as number} />
                        </div>

                        <div className="breed_details_levels">
                          <div className="d-flex align-items-start gap-2">
                            <h6>Cuteness</h6>

                            <Tooltip>
                              <TooltipTrigger className="tooltip_trigger_account">
                                <img
                                  src={breedLvlInfo}
                                  alt="breedLvlInfo"
                                  className="h-50 d-flex align-items-start"
                                />
                              </TooltipTrigger>
                              <TooltipContent className="tooltip_account">
                                {breedDetails?.cutenessDescription}
                              </TooltipContent>
                            </Tooltip>
                          </div>
                          <LevelComponent level={breedDetails?.cuteness as number} />
                        </div>

                        <div className="breed_details_levels">
                          <div className="d-flex align-items-start gap-2">
                            <h6>Cuddliness</h6>

                            <Tooltip>
                              <TooltipTrigger className="tooltip_trigger_account">
                                <img
                                  src={breedLvlInfo}
                                  alt="breedLvlInfo"
                                  className="h-50 d-flex align-items-start"
                                />
                              </TooltipTrigger>
                              <TooltipContent className="tooltip_account">
                                {breedDetails?.cuddlinessDescription}
                              </TooltipContent>
                            </Tooltip>
                          </div>
                          <LevelComponent level={breedDetails?.cuddliness as number} />
                        </div>

                        <div className="breed_details_levels">
                          <div className="d-flex align-items-start gap-2">
                            <h6>Athleticism</h6>

                            <Tooltip>
                              <TooltipTrigger className="tooltip_trigger_account">
                                <img
                                  src={breedLvlInfo}
                                  alt="breedLvlInfo"
                                  className="h-50 d-flex align-items-start"
                                />
                              </TooltipTrigger>
                              <TooltipContent className="tooltip_account">
                                {breedDetails?.athleticismDescription}
                              </TooltipContent>
                            </Tooltip>
                          </div>
                          <LevelComponent level={breedDetails?.athleticism as number} />
                        </div>

                        <div className="breed_details_levels">
                          <div className="d-flex align-items-start gap-2">
                            <h6>Maintenance</h6>

                            <Tooltip>
                              <TooltipTrigger className="tooltip_trigger_account">
                                <img
                                  src={breedLvlInfo}
                                  alt="breedLvlInfo"
                                  className="h-50 d-flex align-items-start"
                                />
                              </TooltipTrigger>
                              <TooltipContent className="tooltip_account">
                                {breedDetails?.maintenanceDescription}
                              </TooltipContent>
                            </Tooltip>
                          </div>
                          <LevelComponent level={breedDetails?.maintenance as number} />
                        </div>

                        <div className="breed_details_levels">
                          <div className="d-flex align-items-start gap-2">
                            <h6>Trainability</h6>

                            <Tooltip>
                              <TooltipTrigger className="tooltip_trigger_account">
                                <img
                                  src={breedLvlInfo}
                                  alt="breedLvlInfo"
                                  className="h-50 d-flex align-items-start"
                                />
                              </TooltipTrigger>
                              <TooltipContent className="tooltip_account">
                                {breedDetails?.trainabilityDescription}
                              </TooltipContent>
                            </Tooltip>
                          </div>
                          <LevelComponent level={breedDetails?.trainability as number} />
                        </div>

                        <div className="breed_details_levels">
                          <div className="d-flex align-items-start gap-2">
                            <h6>Adaptability</h6>

                            <Tooltip>
                              <TooltipTrigger className="tooltip_trigger_account">
                                <img
                                  src={breedLvlInfo}
                                  alt="breedLvlInfo"
                                  className="h-50 d-flex align-items-start"
                                />
                              </TooltipTrigger>
                              <TooltipContent className="tooltip_account">
                                {breedDetails?.adaptabilityDescription}
                              </TooltipContent>
                            </Tooltip>
                          </div>
                          <LevelComponent level={breedDetails?.adaptability as number} />
                        </div>

                        <div className="breed_details_levels">
                          <div className="d-flex align-items-start gap-2">
                            <h6>Energy Level</h6>

                            <Tooltip>
                              <TooltipTrigger className="tooltip_trigger_account">
                                <img
                                  src={breedLvlInfo}
                                  alt="breedLvlInfo"
                                  className="h-50 d-flex align-items-start"
                                />
                              </TooltipTrigger>
                              <TooltipContent className="tooltip_account">
                                {breedDetails?.energyDescription}
                              </TooltipContent>
                            </Tooltip>
                          </div>
                          <LevelComponent level={breedDetails?.energy as number} />
                        </div>

                        <div className="breed_details_levels">
                          <div className="d-flex align-items-start gap-2">
                            <h6>Kid-Friendliness</h6>

                            <Tooltip>
                              <TooltipTrigger className="tooltip_trigger_account">
                                <img
                                  src={breedLvlInfo}
                                  alt="breedLvlInfo"
                                  className="h-50 d-flex align-items-start"
                                />
                              </TooltipTrigger>
                              <TooltipContent className="tooltip_account">
                                {breedDetails?.kidScoreDescription}
                              </TooltipContent>
                            </Tooltip>
                          </div>
                          <LevelComponent level={breedDetails?.kidScore as number} />
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="breed_details_card blue">
                  <h4>
                    Looking for {aOrAn(breedDetails?.breedName as string)} {breedDetails?.breedName}
                    ?
                  </h4>
                  <TTButton
                    className="btn-primary-orchid"
                    text="Match with a breeder!"
                    height="50px"
                    width="-webkit-fill-available"
                    maxWidth="290px"
                    fontSize="1rem"
                    borderRadius="10px"
                    onClick={() => navigate('/buyer-registration')}
                  />
                </div>

                {breedDetails?.imageCarousel?.length !== 0 && (
                  <div className="breed_details_card">
                    <div className="d-flex justify-content-between w-100">
                      <h5>Image Gallery</h5>
                      {displayMoreInfoGallery ? (
                        <MdKeyboardArrowUp
                          className="cursor-pointer"
                          size={30}
                          onClick={() => setDisplayMoreInfoGallery(!displayMoreInfoGallery)}
                        />
                      ) : (
                        <MdKeyboardArrowDown
                          className="cursor-pointer"
                          size={30}
                          onClick={() => setDisplayMoreInfoGallery(!displayMoreInfoGallery)}
                        />
                      )}
                    </div>

                    {displayMoreInfoGallery && (
                      <>
                        <div className="breed_details_gallery">
                          <div
                            className={`arrow_left ${centralImageIndex === 0 && 'display_none'}`}
                            onClick={() =>
                              centralImageIndex !== 0 && setCentralImageIndex(centralImageIndex - 1)
                            }
                          >
                            <AiOutlineArrowLeft size={40} />
                          </div>
                          {breedDetails?.imageCarousel?.map((image, index) => (
                            <div
                              className={`breed_details_image_container_gallery ${
                                centralImageIndex === index && 'central'
                              } ${centralImageIndex === index + 1 && 'first_left'} ${
                                centralImageIndex === index - 1 && 'first_right'
                              } ${centralImageIndex > index + 1 && 'hide_left'} ${
                                centralImageIndex < index - 1 && 'hide_right'
                              }`}
                              key={index}
                            >
                              <img src={image} />
                            </div>
                          ))}
                          <div
                            className={`arrow_right ${
                              centralImageIndex ===
                                (breedDetails?.imageCarousel?.length as number) - 1 &&
                              'display_none'
                            }`}
                            onClick={() =>
                              centralImageIndex !==
                                (breedDetails?.imageCarousel?.length as number) - 1 &&
                              setCentralImageIndex(centralImageIndex + 1)
                            }
                          >
                            <AiOutlineArrowRight size={40} />
                          </div>
                        </div>

                        <div className="breed_details_dots">
                          {breedDetails?.imageCarousel?.map((_, index) => (
                            <div
                              className={`breed_details_dot ${
                                centralImageIndex === index && 'colored'
                              }`}
                              key={index}
                              onClick={() => setCentralImageIndex(index)}
                            />
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </>
            )}

            {(selectedTab === 'behavior' || selectedTab === 'overview') && (
              <>
                <div className="breed_details_card">
                  <div className="d-flex justify-content-between w-100">
                    <h5>Behavioral Characteristics</h5>
                    {displayMoreInfoBehavior ? (
                      <MdKeyboardArrowUp
                        className="cursor-pointer"
                        size={30}
                        onClick={() => setDisplayMoreInfoBehavior(!displayMoreInfoBehavior)}
                      />
                    ) : (
                      <MdKeyboardArrowDown
                        className="cursor-pointer"
                        size={30}
                        onClick={() => setDisplayMoreInfoBehavior(!displayMoreInfoBehavior)}
                      />
                    )}
                  </div>

                  {displayMoreInfoBehavior && (
                    <>
                      <p>
                        {breedDetails?.shortDescription}
                        <br />
                        They are known for:
                      </p>
                      <ul>
                        {breedDetails?.behavioralCharacteristics.map((characteristic, index) => (
                          <li key={index}>{characteristic}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              </>
            )}

            {(selectedTab === 'history' || selectedTab === 'overview') && (
              <>
                <div className="breed_details_card">
                  <div className="d-flex justify-content-between w-100">
                    <h5>History of the Breed</h5>
                    {displayMoreInfoHistory ? (
                      <MdKeyboardArrowUp
                        className="cursor-pointer"
                        size={30}
                        onClick={() => setDisplayMoreInfoHistory(!displayMoreInfoHistory)}
                      />
                    ) : (
                      <MdKeyboardArrowDown
                        className="cursor-pointer"
                        size={30}
                        onClick={() => setDisplayMoreInfoHistory(!displayMoreInfoHistory)}
                      />
                    )}
                  </div>

                  {displayMoreInfoHistory && (
                    <div className="left_side_with_img">
                      <p className="seventy">{breedDetails?.history}</p>

                      <div className="right_side_with_img">
                        <div className="breed_details_image_container">
                          <img src={breedDetails?.historyImage} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

            {(selectedTab === 'health' || selectedTab === 'overview') && (
              <>
                <div className="breed_details_card">
                  <div className="d-flex justify-content-between w-100">
                    <h5>Health Concerns</h5>
                    {displayMoreInfoHealth ? (
                      <MdKeyboardArrowUp
                        className="cursor-pointer"
                        size={30}
                        onClick={() => setDisplayMoreInfoHealth(!displayMoreInfoHealth)}
                      />
                    ) : (
                      <MdKeyboardArrowDown
                        className="cursor-pointer"
                        size={30}
                        onClick={() => setDisplayMoreInfoHealth(!displayMoreInfoHealth)}
                      />
                    )}
                  </div>

                  {displayMoreInfoHealth && <p>{breedDetails?.health}</p>}
                </div>
              </>
            )}

            {(selectedTab === 'breeding' || selectedTab === 'overview') && (
              <>
                <div className="breed_details_card">
                  <div className="d-flex justify-content-between w-100">
                    <h5>Considerations for Breeding</h5>
                    {displayMoreInfoBreeding ? (
                      <MdKeyboardArrowUp
                        className="cursor-pointer"
                        size={30}
                        onClick={() => setDisplayMoreInfoBreeding(!displayMoreInfoBreeding)}
                      />
                    ) : (
                      <MdKeyboardArrowDown
                        className="cursor-pointer"
                        size={30}
                        onClick={() => setDisplayMoreInfoBreeding(!displayMoreInfoBreeding)}
                      />
                    )}
                  </div>

                  {displayMoreInfoBreeding && (
                    <>
                      <p>{breedDetails?.breedingSummary}</p>

                      {/* <div className="d-flex gap-2 w-100">
                        <img src={health} className="breeding_category_logos" />
                        <div className="d-flex flex-column">
                          <p className="bold mb-1">Health Screenings</p>
                          <p>
                            Conducting health screenings for common issues like hip and elbow
                            dysplasia, progressive retinal atrophy (PRA), exercise-induced collapse
                            (EIC), and hereditary nasal parakeratosis (HNPK) is essential. These
                            screenings help identify potential health risks and allow breeders to
                            make informed decisions about the suitability of breeding pairs.
                          </p>
                        </div>
                      </div>

                      <div className="d-flex gap-2 w-100">
                        <img src={genetic} className="breeding_category_logos" />
                        <div className="d-flex flex-column">
                          <p className="bold mb-1">Genetic Testing</p>
                          <p>
                            Performing genetic testing can provide insight into the potential health
                            risks of offspring. By knowing the genetic makeup of both the sire and
                            dam, breeders can minimize the risk of passing on inheritable
                            conditions.
                          </p>
                        </div>
                      </div>

                      <div className="d-flex gap-2 w-100">
                        <img src={breedingFrequency} className="breeding_category_logos" />
                        <div className="d-flex flex-column">
                          <p className="bold mb-1">Breeding Frequency</p>
                          <p>
                            Responsible breeders should also consider the frequency of breeding for
                            their dams. It's essential to give the female Labrador Retriever enough
                            time to recover between litters to maintain her overall health and
                            well-being. Generally, it is recommended that a dam should not have more
                            than one litter per year.
                          </p>
                        </div>
                      </div>

                      <div className="d-flex gap-2 w-100">
                        <img src={practices} className="breeding_category_logos" />
                        <div className="d-flex flex-column">
                          <p className="bold mb-1">Responsible Breeding Practices</p>
                          <p>
                            Ethical breeders should prioritize the health and well-being of their
                            dogs, providing appropriate prenatal care for the dam and postnatal care
                            for puppies. Proper socialization, vaccinations, and veterinary care
                            should be provided to ensure the puppies are healthy and well-adjusted
                            before being placed in new homes.
                          </p>
                        </div>
                      </div>

                      <div className="d-flex gap-2 w-100">
                        <img src={standards} className="breeding_category_logos" />
                        <div className="d-flex flex-column">
                          <p className="bold mb-1">Conformation to Breed Standards</p>
                          <p>
                            Adhering to breed standards helps maintain the integrity of the Labrador
                            Retriever breed. Breeders should aim to produce dogs with the physical
                            and behavioral characteristics that define the breed, such as a strong,
                            athletic build, a waterproof coat, and an otter-like tail.
                          </p>
                        </div>
                      </div>

                      <div className="d-flex gap-2 w-100">
                        <img src={temperament} className="breeding_category_logos" />
                        <div className="d-flex flex-column">
                          <p className="bold mb-1">Temperament</p>
                          <p>
                            Breeding dogs should exhibit a stable temperament, intelligence, and a
                            strong desire to please. Labrador Retrievers should be friendly,
                            outgoing, and good with children and other pets. Breeding dogs with
                            aggressive or overly timid tendencies should be avoided.
                          </p>
                        </div>
                      </div> */}
                    </>
                  )}
                </div>
              </>
            )}

            {selectedTab === 'overview' && (
              <div className="breed_details_similar_breeds_container">
                <h5>Similar Dog Breeds</h5>
                <div className="cards_container similar">
                  {breedDetails?.similiarBreedsData?.map((breed: ISimilarBreedsData) => (
                    <div
                      className="card similar"
                      key={breed._id}
                      onClick={() => {
                        setCentralImageIndex(0);
                        navigate(`/breedopedia/${breed?._id}`);
                      }}
                    >
                      <div className="image_container">
                        <img
                          src={breed.profilePicture !== '' ? breed.profilePicture : placeholderDog}
                        />
                      </div>

                      <h6>{breed.breedName}</h6>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="content_right">
            <div className="breed_details_card right">
              <div className="breed_details_image_container right">
                <img src={breederExample} className="right" />
              </div>

              <h6 className="px-3">Connect with a Certified {breedDetails?.breedName} Breeder</h6>
              <TTButton
                className="btn-primary-orchid mx-3 mb-3"
                text="Connect me to a breeder"
                height="50px"
                width="-webkit-fill-available"
                fontSize="1rem"
                borderRadius="10px"
                onClick={() => navigate('/buyer-registration')}
              />
            </div>
          </div>
        </div>
      </div>
    );
};

export default BreedDetails;
