import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Slide } from '@mui/material';
import { Row } from 'react-bootstrap';
import { TransitionProps } from '@mui/material/transitions';
import { Close } from '@mui/icons-material';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

//types
import { IDialogProps } from 'types';
//hooks
import { useQueryAdminBreederInfo } from '../__hooks/useQueryAdminBreederInfo';
//components
import TabPanel from 'components/common/TabPanel';
import BasicInfo from './BasicInfo';
import AboutPorgram from './AboutProgram';
import AboutBusiness from './AboutBusiness';
import LearnAndConnect from './LearnAndConnect';
import CreateProfile from './CreateProfile';
//styles
import './index.scss';
//constants
import { adminPanelEditUserSubTabs } from 'constants/tabs';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditUserProfileDialog = ({ open, handleClose, displayName, userId }: IDialogProps) => {
  const [active, setActive] = useState(adminPanelEditUserSubTabs[0]);
  const [showSuccess, setShowSuccess] = useState(false);

  //Query hooks
  const { breederInfo, errorBreeder, isLoadingBreeder, isFetchingBreeder } =
    useQueryAdminBreederInfo(userId!);

  useEffect(() => {
    if (showSuccess) setShowSuccess(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, setShowSuccess]);

  const loadViews = () => {
    if (isLoadingBreeder || isFetchingBreeder) {
      return (
        <div className="profileInfo__container">
          <Row>
            <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
          </Row>
        </div>
      );
    } else if (errorBreeder || !breederInfo) {
      return null;
    }

    return (
      <Box
        display="flex"
        flexDirection="column"
        width="1300px"
        height="80vh"
        overflow="hidden"
        mb={4}
      >
        <TabPanel
          tabsList={adminPanelEditUserSubTabs}
          current={active.value}
          setActive={setActive}
        />
        {showSuccess && (
          <Box
            sx={{
              backgroundColor: 'green',
              color: 'white',
              px: 1.5,
              py: 0.5,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>Updated successfully.</span>
            <div style={{ cursor: 'pointer' }} onClick={() => setShowSuccess(false)}>
              <Close />
            </div>
          </Box>
        )}
        <div className="editUserProfile">
          <div className="editUserProfile__cotainer">
            <div className="editUserProfile__content">
              {active.value === 'step1' && (
                <BasicInfo
                  breeder={breederInfo}
                  setShowSuccess={setShowSuccess}
                  onCancelPress={handleClose}
                />
              )}
              {active.value === 'step2' && (
                <AboutPorgram
                  breeder={breederInfo}
                  setShowSuccess={setShowSuccess}
                  onCancelPress={handleClose}
                />
              )}
              {active.value === 'step3' && (
                <AboutBusiness
                  breeder={breederInfo}
                  setShowSuccess={setShowSuccess}
                  onCancelPress={handleClose}
                />
              )}
              {active.value === 'step4' && (
                <LearnAndConnect
                  breeder={breederInfo}
                  setShowSuccess={setShowSuccess}
                  onCancelPress={handleClose}
                />
              )}
              {active.value === 'step5' && (
                <CreateProfile
                  breeder={breederInfo}
                  setShowSuccess={setShowSuccess}
                  onCancelPress={handleClose}
                />
              )}
            </div>
          </div>
        </div>
      </Box>
    );
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      maxWidth="xl"
    >
      <DialogTitle>{`Edit ${displayName} Profile`}</DialogTitle>
      <DialogContent>{loadViews()}</DialogContent>
    </Dialog>
  );
};

export default EditUserProfileDialog;
