import * as Yup from 'yup';

export const phoneNumberSchema = Yup.object().shape({
  phoneNumber: Yup.string().test(
    'phoneNumber',
    'Please enter valid phone number!',
    function (value) {
      if (value) {
        const schema = Yup.string().matches(/^\+[1-9]\d{1,14}$/);
        const result = schema.isValidSync(value);

        return result;
      }

      return true;
    },
  ),
});
