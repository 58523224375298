export const breederCSVColumns = [
  'Breeder Id',
  'User Id',
  'First Name',
  'Middle Name',
  'Last Name',
  'Display Name',
  'Email',
  'Business Program',
  'Business Details',
  'Years Of Experience',
  'Aprox Annual Sell Puppies',
  'Number Of Dams',
  'Number Of Studs',
  'Refurral Code',
  'Address',
  'City',
  'ZipCode',
  'State',
  'Country',
  'Approved',
  'Breeds',
  'Dams Location',
  'Dams Sleep Location',
  'Is Use Stud Services',
  'Is Sell Stud Services',
  'Minimal Puppy Age To Send',
  'Breed New Dam',
  'Dam Breed Lifetime',
  'Minimal Dam Age To Breed',
  'Dam Breed In 18 Months Period',
  'Dam Breed In Lifetime',
  'Dam Food',
  'Puppies Food',
  'Is Health Test',
  'Labs',
  'Health Tests',
  'Is Vaccinate Puppies',
  'Vaccination Description',
  'Is Socialize Puppies',
  'Socialize Description',
  'Is Reproductive Veterinarian',
  'Vet Name',
  'Vet Email',
  'Vet Phone Number',
  'Breeding Practices',
  'Litter Availability',
  'Is Share Info With Parents',
  'Is Sell Puppy To Third Party',
  'Third Party Name',
  'Is Fill Application',
  'Is Contract Signed',
  'Is Pick Puppy From Home',
  'Is Stay In Touch',
  'Program Details',
  'Is Completed Breeder Education',
  'Breeder Education Detail',
  'Education Programs',
  'Is Belong To Club',
  'Breeder Clubs',
  'Breeding Program Website',
  'Facebook Url',
  'Instagram Url',
  'Twitter Url',
  'Youtube Url',
  'TikTok Url',
  'Facebook Groups',
  'Profile Picture Url',
  'Breeder Program Description',
  'Responsible Breeding Description',
  'T-Shirt Size',
  'Status',
  'Short Description',
  'Additional Info',
  'Hear About Us',
  'Knowledge Tags',
  'Is Referral Valid',
  'Subscription Type',
  'Subscription Status',
  'User Roles',
];
