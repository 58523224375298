//navlinks
import { basicBreederNavLinks } from './basicBreederNavLinks';
import { buyerNavLinks } from './buyerNavLinks';
import { premiumBreederNavLinks } from './premiumBreederNavLinks';
import { publicNavLinks } from './publicNavLinks';
//types
import { IUserInfo } from 'types';

export const getNavLinks = (isAuthenticated: boolean, userInfo: IUserInfo | undefined) => {
  if (!isAuthenticated || !userInfo) return publicNavLinks;

  if (userInfo.isBuyer) return buyerNavLinks;
  else if (!userInfo.isSfcBreeder) return basicBreederNavLinks;
  else if (userInfo.isSfcBreeder) return premiumBreederNavLinks;

  return publicNavLinks;
};
