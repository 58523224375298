/* eslint-disable @typescript-eslint/no-explicit-any */
import { useInfiniteQuery } from 'react-query';
import { without } from 'lodash';

//services
import { getActivityFeed } from 'services/api/apiCalls/activityFeed';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

function useQueryGetActivityFeed(filters: string[]) {
  const filtersWithoutParentFilters = without(
    filters,
    'business',
    'homes',
    'health',
    'because',
    'pregnancy',
    'care',
  );

  const {
    status,
    data,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
  } = useInfiniteQuery(
    [QUERY_KEYS.ACTIVITY_FEED_LIST, filters],
    async ({ pageParam = 1 }) =>
      getActivityFeed({
        page: pageParam,
        limit: 12,
        filter: filtersWithoutParentFilters.join(','),
      }),
    {
      getNextPageParam: lastPage => {
        if (lastPage.meta.currentPage === lastPage.meta.totalPages) {
          return undefined;
        } else return lastPage.meta.currentPage + 1;
      },
    },
  );

  return {
    status,
    data: data,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
  };
}

export default useQueryGetActivityFeed;
