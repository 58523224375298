import React, { memo } from 'react';
import { map } from 'lodash';

//types
import { IAdminTabs } from 'types';
//styles
import './index.scss';

const Tabs = ({ tabsList, current, setActive }: IAdminTabs) => {
  return (
    <div className="tabs-admin border-0 m-0">
      <ul className="tab-list-admin">
        {map(tabsList, ({ value, label }) => (
          <li
            className={`tab-item-admin ${current === value && 'active'}`}
            key={value}
            onClick={() => setActive({ value, label })}
          >
            {label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default memo(Tabs);
