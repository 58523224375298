import React from 'react';
import { Dialog } from '@mui/material';
import { map } from 'lodash';

//types
import { ILitterInfoExtended } from 'types';
//components
import TTButton from 'components/common/TTButton';

const ConfirmPopup = ({
  confirmPopupData,
  handleClose,
}: {
  confirmPopupData: ILitterInfoExtended;
  handleClose: () => void;
}) => {
  return (
    <Dialog
      maxWidth="lg"
      open={true}
      keepMounted
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: '12px', overflow: 'hidden' } }}
    >
      <div className="confirmation-popup">
        <div className="confirmation-popup__content">
          <p className="mb-5">
            Are you sure you want to toggle all leads for (
            <strong>{confirmPopupData.breedName}</strong>) off?
          </p>
          <div className="d-flex justify-content-center gap-2">
            <TTButton
              className="btn-primary-navy"
              width="173px"
              height="40px"
              text="Close"
              fontSize="12px"
              onClick={() => {
                handleClose();
              }}
            />
            <TTButton
              className="btn-primary-orchid"
              width="173px"
              height="40px"
              text="Toggle Off"
              fontSize="12px"
              onClick={() => {
                confirmPopupData.updateBreederInfo({
                  data: {
                    ...confirmPopupData.breederInfo,
                    breedsAndLeads: map(
                      confirmPopupData.breederInfo.breedsAndLeads,
                      breedAndLead => {
                        if (breedAndLead.breed === confirmPopupData.breedName) {
                          return {
                            breed: confirmPopupData.breedName,
                            leads: !confirmPopupData.isReceiveLeadsForBreed,
                          };
                        } else {
                          return breedAndLead;
                        }
                      },
                    ),
                  },
                  id: confirmPopupData.breederId,
                });
                handleClose();
              }}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmPopup;
