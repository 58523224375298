import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { UseFormSetError } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';

//services
import { subscriptionEnd } from 'services/api/apiCalls/admin';
//types
import { ISubscriptionEndPayload, ISubscriptionEndSchema, INote, TError } from 'types';

const useMutationSubscriptionEnd = (
  setError: UseFormSetError<ISubscriptionEndSchema>,
  handleClose: () => void,
  subscriptionEndType: string,
) => {
  const { addToast } = useToasts();

  const { mutate: subscriptionEndMutate, isLoading: isSubmitting } = useMutation<
    AxiosResponse<INote>,
    AxiosError<TError>,
    ISubscriptionEndPayload
  >(data => subscriptionEnd(subscriptionEndType, data), {
    onSuccess: () => {
      addToast('Request submitted successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
      handleClose();
    },
    onError: err => {
      setError('note', {
        type: 'custom',
        message: err?.response?.data?.ExceptionMessage || err?.message,
      });
    },
  });

  return {
    subscriptionEndMutate,
    isSubmitting,
  };
};

export default useMutationSubscriptionEnd;
