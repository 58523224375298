//services
import API from 'services/api';
//types
import {
  IAboutBusinessPayload,
  IAboutEducationPayload,
  IAddDeleteAttendeeSchema,
  IAddNewArticlePayload,
  IAddNewRefurralCodeSchema,
  IAddNotePayload,
  IBasicInfoPayload,
  ICreateEditDealPayload,
  ICreateEditPawfficeHourPayload,
  ICreateUserProfilePayload,
  IUpdateAboutProgramPayload,
  IUpdateUserPayload,
  IAddNewVideoPayload,
  IUpdateShopifyUnprocessedOrdersPayload,
  ICreateEditBreedEncyclopaediaPayload as ICreateEditBreedEncyclopaediaPayload,
  IBuyerSettingsPayload,
  ISubscriptionEndPayload,
  IUpdateBreederEmailPayload,
  IUpdateBuyerEmailPayload,
} from 'types';

// Users
export const getUserList = async function () {
  return API.get('/panel/breeder/active');
};
export const getBreederList = async function (status: string, query = {}) {
  const params = new URLSearchParams(query).toString();

  return API.get(`/panel/breeder/list/${status}?${params}`);
};
export const getBuyerList = async function (query = {}) {
  const params = new URLSearchParams(query).toString();

  return API.get(`/panel/buyer-account/info-list?${params}`);
};
export const getBuyerDetailsForAdmin = (buyerAccountId: string) =>
  API.get(`/panel/buyer-account/details/${buyerAccountId}`);
export const updateUserStatus = (data: IUpdateUserPayload) =>
  API.put(`/approval/${data.breederId}`, data);
export const restoreUserStatus = (breederId: string) => API.put(`/approval/restore/${breederId}`);
export const updateBuyerDetailsByAdmin = (
  buyerAccountId: string,
  { data }: { data: IBuyerSettingsPayload },
) => API.put(`/panel/buyer-account/info/${buyerAccountId}`, data);
export const markAsShopifyPaid = (data = {}) => API.post('/shopify/mock', data);
export const markAsVouched = (data = {}) => API.post('/user/vouched-mock', data);

// Review Queue
export const getReviewQueueList = async function (query = {}) {
  const params = new URLSearchParams(query).toString();

  return API.get(`/panel/breeder/breeders-in-review?${params}`);
};
export const getPublicBreederForAdmin = (id: string) => API.get(`/breeder-public/admin-view/${id}`);
export const flagForReview = (breederId: string) =>
  API.put(`/panel/breeder/breeder-in-review/put-in-review/${breederId}`);
export const approveAfterReview = (breederId: string) =>
  API.put(`/panel/breeder/breeder-in-review/activate/${breederId}`);

//Resources
export const getResourceList = async function (status: string, query = {}) {
  const params = new URLSearchParams(query).toString();

  return API.get(`/resource/${status}?${params}`);
};
export const getResourceArticles = async function (query = {}) {
  const params = new URLSearchParams(query).toString();

  return API.get(`/resource?${params}`);
};
export const deleteResource = (id: string) => API.delete(`/resource/${id}`);
export const undeleteResource = (id: string) => API.put(`/resource/undelete/${id}`);
// Resources => Videos
export const createVideo = (data: IAddNewVideoPayload) => API.post('/video', data);
export const updateVideo = ({
  id,
  videoPayload,
}: {
  id: string;
  videoPayload: IAddNewVideoPayload;
}) => API.put(`/video/${id}`, videoPayload);
// Resources => Articles
export const createArticle = (data: IAddNewArticlePayload) => API.post('/article', data);
export const updateArticle = ({
  id,
  articlePayload,
}: {
  id: string;
  articlePayload: IAddNewArticlePayload;
}) => API.put(`/article/${id}`, articlePayload);

// Comments
export const getCommentList = async function (status: string, query = {}) {
  const params = new URLSearchParams(query).toString();
  status = status.replaceAll('_comments', '');

  return API.get(`/community/conversationStatus/${status}?${params}`);
};
export const markAsReadConversation = (id: string) =>
  API.post('/community/conversation/read', { id });
export const markAsReadReply = (id: string) => API.post('/community/reply/read', { id });
export const deleteConversation = (conversationId: string) =>
  API.delete(`/community/conversation/${conversationId}`);
export const deleteReply = (replyId: string) => API.delete(`/community/reply/${replyId}`);

// Refurral Codes
export const getRefurralCodes = async function (query = {}) {
  const params = new URLSearchParams(query).toString();

  return API.get(`/referrals?${params}`);
};
export const getRefurralCodeDetails = async function (refurralCode: string) {
  return API.get(`/referrals/${refurralCode}`);
};
export const createRefurralCode = async function (data: IAddNewRefurralCodeSchema) {
  return API.post('/referrals', data);
};
export const deleteRefurralCode = async function (refurralCodeId: string) {
  return API.delete(`/referrals/${refurralCodeId}`);
};

// Deals
export const getAdminDealList = async function (status: string, query = {}) {
  const params = new URLSearchParams(query).toString();

  return API.get(`/deals/${status}?${params}`);
};
export const createDeal = (data: ICreateEditDealPayload) => API.post('/deals', data);
export const editDeal = (dealId: string, data: ICreateEditDealPayload) =>
  API.put(`/deals/${dealId}`, data);
export const deleteDeal = (dealId: string) => API.delete(`/deals/${dealId}`);
export const undeleteDeal = (dealId: string) => API.put(`/deals/undelete/${dealId}`);
export const pinDeal = (dealId: string) => API.put(`/deals/pin/${dealId}`);
export const unpinDeal = (dealId: string) => API.put(`/deals/unpin/${dealId}`);

// Pawffice Hours
export const getAdminPawfficeHourList = async function (status: string, query = {}) {
  const params = new URLSearchParams(query).toString();

  return API.get(`/pawffice-hours/${status}?${params}`);
};
export const getPawfficeHour = (pawfficeId: string) => {
  return API.get(`/pawffice-user/pawffice/${pawfficeId}`);
};
export const createPawfficeHour = (data: ICreateEditPawfficeHourPayload) =>
  API.post('/pawffice-hours', data);
export const editPawfficeHour = (pawfficeId: string, data: ICreateEditPawfficeHourPayload) =>
  API.put(`/pawffice-hours/${pawfficeId}`, data);
export const deletePawfficeHour = (pawfficeId: string) =>
  API.delete(`/pawffice-hours/${pawfficeId}`);
export const addNewAttendeeToPawfficeHour = (pawfficeId: string, data: IAddDeleteAttendeeSchema) =>
  API.put(`/pawffice-attendee/add/${pawfficeId}`, data);
export const deleteNewAttendeeFromPawfficeHour = (
  pawfficeId: string,
  data: IAddDeleteAttendeeSchema,
) => API.put(`/pawffice-attendee/remove/${pawfficeId}`, data);

// CRM
export const getAdminBreederLogsList = function (breederId: string) {
  return API.get(`/panel/breeder/change-logs/${breederId}`);
};
export const getAdminBuyerLogsList = function (buyerAccountId: string) {
  return API.get(`/panel/buyer-account/change-logs/${buyerAccountId}`);
};
export const getAdminBreederNoteList = async function (breederId: string, query = {}) {
  const params = new URLSearchParams(query).toString();
  const res = await API.get(`/panel/breeder/notes/${breederId}?${params}`);

  return res.data;
};
export const getAdminBuyerNoteList = async function (buyerAccountId: string, query = {}) {
  const params = new URLSearchParams(query).toString();
  const res = await API.get(`/panel/buyer-account/notes/${buyerAccountId}?${params}`);

  return res.data;
};
export const addNoteForBreederByAdmin = ({ payload, userId }: IAddNotePayload) =>
  API.post(`/panel/breeder/notes/${userId}`, payload);
export const addNoteForBuyerByAdmin = ({ payload, userId }: IAddNotePayload) =>
  API.post(`/panel/buyer-account/notes/${userId}`, payload);
export const updateBasicInfoByAdmin = ({ payload, breederId }: IBasicInfoPayload) =>
  API.put(`/panel/breeder/basic-info/${breederId}`, payload);
export const updateAboutProgramByAdmin = ({ payload, breederId }: IUpdateAboutProgramPayload) =>
  API.put(`/panel/breeder/program/${breederId}`, payload);
export const updateAboutBusinessByAdmin = ({ payload, breederId }: IAboutBusinessPayload) =>
  API.put(`/panel/breeder/business/${breederId}`, payload);
export const updateAboutEducationByAdmin = ({ payload, breederId }: IAboutEducationPayload) =>
  API.put(`/panel/breeder/education/${breederId}`, payload);
export const updateProfileInfoByAdmin = ({ payload, breederId }: ICreateUserProfilePayload) =>
  API.put(`/panel/breeder/account/${breederId}`, payload);
export const updateBreederEmailByAdmin = ({ payload, breederId }: IUpdateBreederEmailPayload) =>
  API.put(`/panel/breeder/email/${breederId}`, payload);
export const updateBuyerEmailByAdmin = ({ payload, buyerAccountId }: IUpdateBuyerEmailPayload) =>
  API.put(`/panel/buyer-account/email/${buyerAccountId}`, payload);
export const updateMatchAcceptanceByAdmin = (buyerAccountId: string) =>
  API.put(`/panel/buyer-account/accepts-cron-emails/${buyerAccountId}`);

// Shopify unlinked accounts
export const getShopifyUnprocessed = async function (query = {}) {
  const params = new URLSearchParams(query).toString();

  return API.get(`/badge-purchase/all-unprocessed?${params}`);
};
export const updateShopifyUnprocessed = ({
  payload,
}: {
  payload: IUpdateShopifyUnprocessedOrdersPayload;
}) => API.put('/badge-purchase/connect', payload);
export const deleteShopifyUnprocessed = (id: string) => API.delete(`/badge-purchase/${id}`);

// Vouched => Unvouched users
export const getUnvouchedUsers = async function (query = {}) {
  const params = new URLSearchParams(query).toString();

  return API.get(`/panel/breeder/not-vouched?${params}`);
};
export const setToVouched = (id: string) => API.put(`/panel/breeder/admin-vouch/${id}`);

//Breed Encylopaedia
export const getBreedsForAdmin = async (params = {}) => {
  const queryParams = new URLSearchParams(params).toString();

  return API.get(`/dogpedia-admin?${queryParams}`);
};
export const getBreedDetailsForAdmin = (breedId: string) => API.get(`/dogpedia-admin/${breedId}`);
export const getAdminEncyclopaediaFilterDropdowns = () => API.get('/dogpedia-admin/dropdowns');
export const createBreedEncyclopaedia = (data: ICreateEditBreedEncyclopaediaPayload) =>
  API.post('/dogpedia-admin', data);
export const editBreedEncyclopaedia = (
  breedId: string,
  data: ICreateEditBreedEncyclopaediaPayload,
) => API.put(`/dogpedia-admin/${breedId}`, data);
export const deleteBreedEncyclopaedia = (breedId: string) =>
  API.delete(`/dogpedia-admin/${breedId}`);

//Subscription
export const subscriptionEnd = (subscriptionEndType: string, data: ISubscriptionEndPayload) => {
  if (subscriptionEndType === 'downgrade-next') {
    return API.post(`/panel/subscription/downgrade-request/${data.userId}`, data);
  } else if (subscriptionEndType === 'downgrade-immediate') {
    return API.post(`/panel/subscription/downgrade/${data.userId}`, data);
  } else {
    return API.post(`/panel/subscription/cancel/${data.userId}`, data);
  }
};
