import * as Yup from 'yup';

export const CreateNewDealSchema = Yup.object().shape({
  vendorName: Yup.string().required('This field is required.'),
  vendorLogo: Yup.string().required('This field is required.'),
  startDate: Yup.date()
    .required('This field is required.')
    .when('endDate', (endDate, schema) =>
      endDate && endDate !== 'Invalid Date'
        ? schema.max(endDate, "Start date can't be after end date.")
        : undefined,
    ),
  endDate: Yup.date().nullable().typeError('Invalid Date'),
  dealPicture: Yup.string().required('This field is required.'),
  dealTitle: Yup.string()
    .max(140, 'This field only has 140 char limits.')
    .required('This field is required.'),
  dealDescription: Yup.string(),
  discountCode: Yup.string()
    .required('This field is required.')
    .matches(/^\S*$/, 'Please do not use white space.'),
  visibility: Yup.array().min(1, 'This field is required.').default([]),
  tags: Yup.array().min(1, 'This field is required.').default([]),
});
