import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useQueryClient } from 'react-query';
import { isArray, isEmpty } from 'lodash';
import moment from 'moment';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

//types
import { IDialogProps, ILogInfo, IImportantFactorPayload } from 'types';
//hooks
import useQueryGetLogList from '../__hooks/useQueryGetLogList';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LogsList = ({ userId, isBuyer }: { userId: string; isBuyer: boolean }) => {
  //Query hooks
  const { isLoading, logs, error } = useQueryGetLogList(userId, isBuyer);

  const showLogValues = (logInfo: ILogInfo) => {
    if (
      (typeof logInfo.oldValue === 'string' && typeof logInfo.newValue === 'string') ||
      (typeof logInfo.oldValue === 'number' && typeof logInfo.newValue === 'number')
    ) {
      const oldValue =
        (logInfo.fieldName === 'goHomeDate'
          ? moment(logInfo.oldValue).format('LLL')
          : logInfo.oldValue) || 'Empty';
      const newValue =
        (logInfo.fieldName === 'goHomeDate'
          ? moment(logInfo.newValue).format('LLL')
          : logInfo.newValue) || 'Empty';

      return (
        <>
          <span>
            Field Name: <strong>{logInfo.fieldName}</strong>
          </span>
          <ul>
            <li>
              <i>
                Changed from <strong>{oldValue}</strong> to <strong>{newValue}</strong>.
              </i>
            </li>
          </ul>
        </>
      );
    } else if (isArray(logInfo.oldValue) && isArray(logInfo.newValue)) {
      return (
        <>
          <span>
            Field Name: <strong>{logInfo.fieldName}</strong>
          </span>
          <ul>
            {logInfo.oldValue[0] &&
            typeof logInfo.oldValue[0] !== 'string' &&
            'factor' in logInfo.oldValue[0] ? (
              logInfo.oldValue.map((val, index) => (
                <li key={val.factor + index}>
                  <i>
                    Changed <strong>{val.factor}</strong> from{' '}
                    <strong>{val.isFlexible ? 'true' : 'false'}</strong> to{' '}
                    <strong>
                      {(logInfo.newValue as IImportantFactorPayload[])[index]?.isFlexible
                        ? 'true'
                        : 'false'}
                    </strong>
                    .
                  </i>
                </li>
              ))
            ) : (
              <i>
                Changed from <strong>[{logInfo.oldValue?.join(', ')}]</strong> to{' '}
                <strong>[{logInfo.newValue?.join(', ')}]</strong>
              </i>
            )}
          </ul>
        </>
      );
    }

    return (
      <>
        <span>
          Field Name: <strong>{logInfo.fieldName}</strong>
        </span>
        <ul>
          <li>
            <i>
              Changed from <strong>{(logInfo.oldValue as string) || 'Empty'}</strong> to{' '}
              <strong>{(logInfo.newValue as string) || 'Empty'}</strong>.
            </i>
          </li>
        </ul>
      </>
    );
  };

  if (isLoading)
    return (
      <div className="d-flex width-full justify-content-center my-3">
        <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
      </div>
    );

  if (error)
    return (
      <div className="d-flex width-full justify-content-center my-2">
        {error && (
          <p className="text-error">{error.response?.data.ExceptionMessage || error.message}</p>
        )}
      </div>
    );

  return (
    <div style={{ maxHeight: '50vh', overflow: 'auto' }}>
      <ul>
        {!logs || isEmpty(logs) ? (
          <div className="d-flex width-full justify-content-center mt-3">
            <p>No log found.</p>
          </div>
        ) : (
          logs.map((log, idx) => (
            <li key={log.changeDate + idx} style={{ margin: '8px 0px', color: 'blue' }}>
              <strong>
                [{log.admin.adminFullName}, {moment(log.changeDate).format('LLL')}]
              </strong>{' '}
              <ol>
                {log.log?.map((logInfo, index) => (
                  <li
                    key={logInfo.fieldName + index}
                    style={{ fontSize: '0.9rem', margin: '8px 0px', color: '#333' }}
                  >
                    {showLogValues(logInfo)}
                  </li>
                ))}
              </ol>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

const LogsDialog = ({ open, handleClose, userId, displayName, isBuyer }: IDialogProps) => {
  const queryClient = useQueryClient();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
    >
      <DialogTitle>Logs for {displayName}</DialogTitle>
      <DialogContent sx={{ overflow: 'hidden' }}>
        <Box display="flex" flexDirection="column" width="35vw">
          <LogsList userId={userId!} isBuyer={isBuyer || false} />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            queryClient.removeQueries(QUERY_KEYS.ADMIN_BUYER_LOGS_LIST);
            queryClient.removeQueries(QUERY_KEYS.ADMIN_BREEDER_LOGS_LIST);
            handleClose();
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogsDialog;
