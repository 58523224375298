import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { createBreedEncyclopaedia, editBreedEncyclopaedia } from 'services/api/apiCalls/admin';
//types
import { ICreateEditBreedEncyclopaediaPayload, TError } from 'types';

export const useMutationCreateNewBreedEncyclopaedia = (
  handleDialogDismiss: () => void,
  scrollToBottom: () => void,
) => {
  const { addToast } = useToasts();

  const {
    mutate: createNewBreedEncyclopaediaMutate,
    isLoading: isCreatingNewBreedEncyclopaedia,
    error: createNewBreedEncyclopaediaError,
    reset: creatNewBreedEncyclopaediaResetApiState,
  } = useMutation<AxiosResponse<string>, AxiosError<TError>, ICreateEditBreedEncyclopaediaPayload>(
    data => createBreedEncyclopaedia(data),
    {
      onSuccess: () => {
        addToast('Breedopedia created successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
        handleDialogDismiss();
      },
      onError: () => {
        scrollToBottom();
      },
    },
  );

  return {
    createNewBreedEncyclopaediaMutate,
    isCreatingNewBreedEncyclopaedia,
    createNewBreedEncyclopaediaError,
    creatNewBreedEncyclopaediaResetApiState,
  };
};

export const useMutationEditBreedEncyclopaedia = (
  handleDialogDismiss: () => void,
  scrollToBottom: () => void,
) => {
  const { addToast } = useToasts();

  const {
    mutate: editBreedEncyclopaediaMutate,
    isLoading: isEditingBreedEncyclopaedia,
    error: editBreedEncyclopaediaError,
    reset: editBreedEncyclopaediaResetApiState,
  } = useMutation<AxiosResponse<string>, AxiosError<TError>, ICreateEditBreedEncyclopaediaPayload>(
    data => editBreedEncyclopaedia(data._id!, data),
    {
      onSuccess: () => {
        addToast('Breedopedia updated successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
        handleDialogDismiss();
      },
      onError: () => {
        scrollToBottom();
      },
    },
  );

  return {
    editBreedEncyclopaediaMutate,
    isEditingBreedEncyclopaedia,
    editBreedEncyclopaediaError,
    editBreedEncyclopaediaResetApiState,
  };
};
