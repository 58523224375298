import React, { createContext, useState } from 'react';

//types
import {
  IBreedopediaFiltersState,
  TBreedopediaFiltersContext,
  TBreedopediaFiltersProvider,
} from 'types';

const initialState: IBreedopediaFiltersState = {
  selectedFilters: window.localStorage.getItem('BREEDOPEDIA_FILTERS')
    ? JSON.parse(window.localStorage.getItem('BREEDOPEDIA_FILTERS') || '')
    : [],
};

export const BreedopediaFiltersState = createContext<TBreedopediaFiltersContext | null>(null);

const BreedoPediaFiltersProvider = ({ children }: TBreedopediaFiltersProvider) => {
  const [selectedFilters, setSelectedFilters] = useState<string[]>(initialState.selectedFilters);

  const setBreedopediaFilters = (filters: string[]) => {
    window.localStorage.setItem('BREEDOPEDIA_FILTERS', JSON.stringify(filters));
    setSelectedFilters(filters);
  };

  const value = {
    state: {
      selectedFilters,
    },
    setBreedopediaFilters,
  };

  return (
    <BreedopediaFiltersState.Provider value={value}>{children}</BreedopediaFiltersState.Provider>
  );
};

export default BreedoPediaFiltersProvider;
