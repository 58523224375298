import React, { ChangeEvent, useState } from 'react';
import { includes, filter, find, map, intersection } from 'lodash';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

//types
import { IActivityFeedFilterTypes } from 'types';
//components
import CheckBox from 'components/common/CheckBox';
//constants
import {
  activityFeedSubFiltersDependencies,
  activityFeedSubfiltersPerParent,
} from 'constants/filters';
//styles
import './index.scss';

const Filters = ({
  level = 1,
  listOfFilters,
  selectedFilters,
  setSelectedFilters,
  isParentFilterSemiChecked,
  setIsParentFilterSemiChecked,
}: IActivityFeedFilterTypes) => {
  const [openDropdowns, setOpendropdowns] = useState<string[]>([
    'homes',
    'health',
    'because',
    'pregnancy',
    'care',
  ]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const currentList = find(listOfFilters, { label: e.target.name });
    const subFilters = currentList ? currentList.subFilters : undefined;

    // If its a main filter (has subfilters) logic
    if (subFilters) {
      setIsParentFilterSemiChecked({ ...isParentFilterSemiChecked, [e.target.name]: false });
      const subFiltersLabels = map(subFilters, subfilter => subfilter.label);

      // We need to remove all subfilters if we are removing selected parent filter
      if (includes(selectedFilters, e.target.name)) {
        const removedChildrenFilters = filter(
          selectedFilters,
          val => !includes(subFiltersLabels, val) && val !== e.target.name,
        );

        setSelectedFilters(removedChildrenFilters);
      } else {
        setSelectedFilters([...selectedFilters, ...subFiltersLabels, e.target.name]);
      }
    }
    // If its a subFilter logic (no additional subfilters)
    else {
      const parentFilter = activityFeedSubFiltersDependencies[e.target.name];

      // We need to remove subfilter and a parent filter
      if (includes(selectedFilters, e.target.name)) {
        // If there is only one child element checked we need to remove semi-checked mark
        // from parent filter because there will be no child filters left checked
        intersection(selectedFilters, activityFeedSubfiltersPerParent[parentFilter]).length === 1 &&
          setIsParentFilterSemiChecked({ ...isParentFilterSemiChecked, [parentFilter]: false });
        // If every child is checked and one is getting unchecked we need to set parent to semi-checked
        intersection(activityFeedSubfiltersPerParent[parentFilter], selectedFilters).length ===
          activityFeedSubfiltersPerParent[parentFilter].length &&
          setIsParentFilterSemiChecked({ ...isParentFilterSemiChecked, [parentFilter]: true });

        setSelectedFilters(
          filter(selectedFilters, val => val !== e.target.name && val !== parentFilter),
        );
      } else {
        // If there is only one child unchecked we need to change parent filter semi-checked state to false
        // because all childs will be checked after this action
        intersection(activityFeedSubfiltersPerParent[parentFilter], selectedFilters).length + 1 ===
        activityFeedSubfiltersPerParent[parentFilter].length
          ? setIsParentFilterSemiChecked({ ...isParentFilterSemiChecked, [parentFilter]: false })
          : setIsParentFilterSemiChecked({ ...isParentFilterSemiChecked, [parentFilter]: true });

        intersection(activityFeedSubfiltersPerParent[parentFilter], selectedFilters).length + 1 ===
        activityFeedSubfiltersPerParent[parentFilter].length
          ? setSelectedFilters([...selectedFilters, e.target.name, parentFilter])
          : setSelectedFilters([...selectedFilters, e.target.name]);
      }
    }
  };

  return (
    <div className={`resource__filtersContainer ${level === 2 && 'subfilter'}`}>
      {listOfFilters?.map(({ label, value, subFilters }, idx) => {
        return (
          <div key={`${label}${idx}`} className="w-100">
            <div className={`single-filter ${level === 2 && 'subfilter'} `}>
              <CheckBox
                text={value}
                id={`${label}_${idx}`}
                name={label}
                checked={includes(selectedFilters, label)}
                onChange={handleChange}
                checkboxClassName={`${isParentFilterSemiChecked[label] === true && 'semi-checked'}`}
              />

              {level === 1 &&
                (includes(openDropdowns, label) ? (
                  <MdKeyboardArrowUp
                    size={32}
                    className="text-center cursor-pointer"
                    onClick={() => setOpendropdowns(filter(openDropdowns, val => val !== label))}
                  />
                ) : (
                  <MdKeyboardArrowDown
                    size={32}
                    className="text-center cursor-pointer"
                    onClick={() => setOpendropdowns([...openDropdowns, label])}
                  />
                ))}
            </div>

            {!includes(openDropdowns, label) && subFilters && (
              <Filters
                level={2}
                listOfFilters={subFilters}
                setSelectedFilters={setSelectedFilters}
                selectedFilters={selectedFilters}
                isParentFilterSemiChecked={isParentFilterSemiChecked}
                setIsParentFilterSemiChecked={setIsParentFilterSemiChecked}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Filters;
