import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { updateBreeder } from 'services/api/apiCalls/breeder';
//types
import { IBreeder, TError, IBreederPayload, TStepper } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useMutationUpdateBreederInfo = ({ setStep, step, isLastStep }: TStepper) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { addToast } = useToasts();

  return useMutation<AxiosResponse<IBreeder>, AxiosError<TError>, IBreederPayload>(updateBreeder, {
    onSuccess: async () => {
      window.scrollTo({ top: 0 });
      addToast('Your information has been updated!', {
        appearance: 'success',
        autoDismiss: true,
      });
      if (step === 10) {
        setStep(10);
      } else if (isLastStep) {
        await queryClient.invalidateQueries(QUERY_KEYS.USER_STATUS, {
          refetchInactive: true,
        });

        navigate('/profile/leads');
      } else {
        setStep(step + 1);
      }
      await queryClient.invalidateQueries(QUERY_KEYS.BREEDER, {
        refetchInactive: true,
      });
    },
    onError: async () => {
      addToast('Something went wrong with your request.', {
        appearance: 'error',
        autoDismiss: true,
      });
    },
  });
};
