import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { changePassword } from 'services/api/apiCalls/auth';
//types
import { TError, IChangePasswordPayload } from 'types';

export const useMutationChangePassword = () => {
  const { addToast } = useToasts();

  return useMutation<
    AxiosResponse<{ message: string }>,
    AxiosError<TError>,
    IChangePasswordPayload
  >(changePassword, {
    onSuccess: () => {
      addToast('Your password is successfully updated', {
        appearance: 'success',
        autoDismiss: true,
      });
    },
    onError: () => {
      addToast('Something went wrong with changing your password!', {
        appearance: 'error',
        autoDismiss: true,
      });
    },
  });
};
