import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { getKnowledgeTagsCategories } from 'services/api/apiCalls/tags';
//types
import { ICategory, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useQueryKnowledgeTagsCategories = (enabled = false) => {
  const {
    data: knowledgeTagsCategories,
    error: errorKnowledgeTagsCategories,
    isLoading: isLoadingKnowledgeTagsCategories,
  } = useQuery<AxiosResponse<ICategory[]>, AxiosError<TError>>(
    QUERY_KEYS.KNOWLEDGE_TAGS_CATEGORIES,
    getKnowledgeTagsCategories,
    {
      enabled,
    },
  );

  return {
    errorKnowledgeTagsCategories,
    knowledgeTagsCategories: knowledgeTagsCategories?.data?.sort((a, b) =>
      a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
    ),
    isLoadingKnowledgeTagsCategories,
  };
};

export default useQueryKnowledgeTagsCategories;
