/* eslint-disable @typescript-eslint/no-explicit-any */
import { useInfiniteQuery } from 'react-query';

//services
import { getResources } from 'services/api/apiCalls/resources';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

function useQueryResourceList(filters: string[], enabled = false) {
  const {
    status,
    data,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
  } = useInfiniteQuery(
    [QUERY_KEYS.RESOURCES, filters],
    async ({ pageParam = 1 }) =>
      getResources({
        page: pageParam,
        limit: 12,
        filter: filters.join(','),
      }),
    {
      enabled,
      getNextPageParam: lastPage => {
        if (lastPage.meta.currentPage === lastPage.meta.totalPages) {
          return undefined;
        } else return lastPage.meta.currentPage + 1;
      },
    },
  );

  return {
    status,
    data,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
  };
}

export default useQueryResourceList;
