import React from 'react';
import { ParallaxLayer } from '@react-spring/parallax';
import { animated, useSpring } from '@react-spring/web';

//assets
import screeningAgainstPuppymeal from 'assets/images/registrationAndSignIn/screeningAgainstPuppymeal.svg';
import youAreVerified from 'assets/images/registrationAndSignIn/youAreVerified.svg';
import inReview from 'assets/images/registrationAndSignIn/inReview.svg';
//styles
import '../index.scss';

export default function Page({
  offset,
  onClick,
  active,
}: {
  offset: number;
  active: number;
  onClick: () => void;
}) {
  // const pathsRef: RefObject<SVGSVGElement> = useRef(null);
  const textProps = useSpring({
    from: { transform: 'rotateX(0deg)', color: '#142669' },
    to: { transform: 'rotateX(360deg)', color: '#cc7191' },
    config: { duration: 2000 },
    delay: 1000,
    loop: true, // This will make the animation loop
  });
  const HEADLINES = [
    <div className="parallax-heading centered" key={1}>
      Screening against <animated.span style={textProps}>Puppymill</animated.span> database...
    </div>,
    <div className="parallax-heading centered" key={2}>
      You are being verified...
    </div>,
    <div className="parallax-heading centered" key={3}>
      The team is thoroughly reviewing your application
      <h4>In most cases, you’ll be approved in 1-2 business days</h4>
    </div>,
  ];

  // function setKeyframesPercentage(fallPercent: number, key: string) {
  //   const keyframes = `
  //       @keyframes fall${key} {
  //           0%, 100% {
  //               transform: translateY(0);
  //           }
  //           ${fallPercent - 0.01}% {
  //               transform: translateY(var(--fall-distance));
  //           }
  //           ${fallPercent}% {
  //               transform: translateY(var(--rise-distance));
  //           }
  //           99.99% {
  //               transform: translateY(-1px);
  //           }
  //       }
  //   `;

  //   const styleElement = document.createElement('style');
  //   styleElement.id = 'dynamicKeyframes'; // Assign an ID for easy removal
  //   document.head.appendChild(styleElement);
  //   styleElement.appendChild(document.createTextNode(keyframes)); // Insert the keyframes
  // }

  // useEffect(() => {
  //   const paths = pathsRef?.current?.querySelectorAll('.falling-path');
  //   paths &&
  //     paths.forEach((path, key) => {
  //       if (path instanceof SVGPathElement) {
  //         const d = path.getAttribute('d');
  //         if (d) {
  //           const y = parseFloat(d.split(' ')[1]);
  //           setKeyframesPercentage(((540 - Math.abs(y)) / 540) * 100, key.toString());

  //           const fallDistance = 540 - y;
  //           const riseDistance = -Math.abs(y);
  //           const animationName = `fall${key}`;

  //           path.style.setProperty('--fall-distance', `${fallDistance}px`);
  //           path.style.setProperty('--rise-distance', `${riseDistance}px`);
  //           path.style.setProperty('--animation-name', animationName);
  //         }
  //       }
  //     });
  // }, []);

  return (
    <>
      <ParallaxLayer offset={offset} speed={2} onClick={onClick}>
        {HEADLINES[offset]}
      </ParallaxLayer>

      <ParallaxLayer offset={offset} speed={0.3} onClick={onClick}>
        <div className={`parallax-image-container__line${offset}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="280%"
            height="171"
            viewBox="0 0 1950 171"
            fill="none"
          >
            <defs>
              <linearGradient
                id="gradient"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="0%"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0%" stopColor="#81B29A" stopOpacity="1" />
                <stop offset="3%" stopColor="#D0D4E1" stopOpacity="1" />
                <animate attributeName="x1" from="0%" to="40%" dur="2s" repeatCount="indefinite" />
              </linearGradient>
            </defs>
            <path
              d="M0 6C273.206 6 273.206 165 546.482 165C819.759 165 819.759 6 1092.96 6C1366.17 6 1366.24 165 1639.52 165C1912.79 165 1912.72 6 2186 6"
              stroke={active === offset ? 'url(#gradient)' : '#81B29A'}
              strokeWidth="11"
              strokeMiterlimit="10"
              strokeDasharray="22 22"
            />
          </svg>
        </div>
      </ParallaxLayer>

      <ParallaxLayer offset={offset} speed={0.3} onClick={onClick}>
        <div className={`parallax-image-container__white${offset}`} />
      </ParallaxLayer>

      <ParallaxLayer offset={offset} speed={0.4} onClick={onClick}>
        <div className={`parallax-image-container parallax-image-container__${offset}`}>
          {offset === 0 && <img src={screeningAgainstPuppymeal} />}
          {offset === 1 && <img src={youAreVerified} />}
          {offset === 2 && <img src={inReview} />}
          {/* {offset === 2 && <Confetti ref={pathsRef} />} */}
        </div>
      </ParallaxLayer>
    </>
  );
}
