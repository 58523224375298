import React from 'react';

//types
import { IConvertImgUrl } from 'types';
//styles
import './index.scss';
//helpers
import { convertImgUrl } from 'helpers/string';

const KitImg = ({ src, kitwidth, onClick, className, imgClassName, ...props }: IConvertImgUrl) => {
  return (
    <div className={`default-kit-image ${className}`} onClick={onClick}>
      <img {...props} src={convertImgUrl(src, kitwidth)} className={imgClassName} />
    </div>
  );
};

export default KitImg;
