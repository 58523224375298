import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { getShopifyUnprocessed } from 'services/api/apiCalls/admin';
//types
import { IShopifyUnlinkedAccounts, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useQueryGetShopifyUnprocessed = () => {
  const {
    isError,
    data,
    isLoading,
    refetch: refetchShopifyUnprocessed,
    isFetching,
  } = useQuery<AxiosResponse<IShopifyUnlinkedAccounts>, AxiosError<TError>>(
    [QUERY_KEYS.SHOPIFY_UNPROCESSED_LIST],
    () => getShopifyUnprocessed({ page: 1, limit: 1000000 }),
    {
      keepPreviousData: true,
    },
  );

  return {
    isError,
    data: data?.data?.items,
    total: Number(data?.data?.meta.totalItems),
    isLoading,
    refetchShopifyUnprocessed,
    isFetching,
  };
};
