import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import LogRocket from 'logrocket';
import { includes } from 'lodash';

//services
import { getBuyerAccountDetails } from 'services/api/apiCalls/buyer';
//types
import { IBuyerInfo } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';
//helpers
import { determineMemberType, determineSubscriptionType } from 'helpers/users';

export const useQueryBuyerInfo = (enabled: boolean, isBuyer: boolean | undefined) => {
  const isEnabled = enabled && isBuyer && isBuyer !== undefined;

  const {
    data: buyerInfo,
    error: errorBuyer,
    isLoading: isLoadingBuyer,
    isFetching: isFetchingBuyer,
  } = useQuery<AxiosResponse<IBuyerInfo>, Error>(
    [QUERY_KEYS.BUYER_ACCOUNT_DETAILS],
    () => getBuyerAccountDetails(),
    {
      enabled: !!isEnabled,
      retry: false,
      onSuccess: data => {
        // Identify buyer for LogRocket session
        const userId = window.localStorage.getItem('BUYER_ID') || 'Unknown';
        LogRocket.identify(userId, {
          userId: userId,
          name: `${data.data?.firstName} ${data.data?.lastName}` || 'Unknown',
          email: data.data?.email || 'Unknown',
          ttUser: includes(data.data?.email, 'telltail'),
          subscriptionType: determineSubscriptionType(data.data?.subscriptionType),
          memberType: determineMemberType(data.data.userRoles),
        });
      },
    },
  );

  return { errorBuyer, buyerInfo: buyerInfo?.data, isLoadingBuyer, isFetchingBuyer };
};
