import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { getAdminDealList } from 'services/api/apiCalls/admin';
import { getUserRoles } from 'services/api/apiCalls/roles';
//types
import { IAdminDealsResponse, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useQueryAdminDealList = (status: string) => {
  const {
    isError,
    data,
    isLoading,
    refetch: refetchDealList,
    isFetching,
  } = useQuery<AxiosResponse<IAdminDealsResponse>, AxiosError<TError>>(
    [QUERY_KEYS.ADMIN_DEAL_LIST, status],
    () => getAdminDealList(status, { page: 1, limit: 100000 }),
    {
      keepPreviousData: true,
    },
  );

  return {
    isError,
    data: data?.data?.items.sort(function (a, b) {
      return new Date(b.startDate).valueOf() - new Date(a.startDate).valueOf();
    }),
    total: Number(data?.data?.meta.itemsCount),
    isLoading,
    refetchDealList,
    isFetching,
  };
};

export const useQueryUserRoles = () => {
  const {
    data: userRoles,
    error: errorUserRoles,
    isLoading: isLoadingUserRoles,
  } = useQuery<AxiosResponse<{ roles: string[] }>, Error>(QUERY_KEYS.USER_ROLES, getUserRoles);

  return {
    errorUserRoles,
    userRoles: userRoles?.data.roles?.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0)),
    isLoadingUserRoles,
  };
};
