import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { getReviewQueueList } from 'services/api/apiCalls/admin';
//types
import { IReviewQueueBreeder, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useQueryUsersInReview = () => {
  const {
    isError,
    data,
    isLoading,
    refetch: refetchReviewQueue,
    isFetching,
  } = useQuery<AxiosResponse<IReviewQueueBreeder>, AxiosError<TError>>(
    [QUERY_KEYS.REVIEW_QUEUE_LIST],
    () => getReviewQueueList({ page: 1, limit: 1000000 }),
    {
      keepPreviousData: true,
    },
  );

  return {
    isError,
    data: data?.data?.items,
    total: Number(data?.data?.meta.totalItems),
    isLoading,
    refetchReviewQueue,
    isFetching,
  };
};
