import React, { useState, useMemo, useRef } from 'react';
import { Box } from '@mui/material';
import MaterialReactTable, { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { Delete, Edit, Restore } from '@mui/icons-material';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';

//hooks
import { useQueryKnowledgeTags } from 'pages/BreederAccount/__hooks/useQueryKnowledgeTags';
import { useQueryResourceList } from './__hooks/useQueryResourceList';
import useMutationDeleteResource from './__hooks/useMutationDeleteResource';
import useMutationUndeleteResource from './__hooks/useMutationUndeleteResource';
import { useQueryUserRoles } from '../Deals/__hooks/useQuery';
import useQueryVideoDetails from './__hooks/useQueryVideoDetails';
import useQueryArticleDetails from './__hooks/useQueryArticleDetails';
//types
import { IDialogContent, IResourcesItem } from 'types';
//components
import TTButton from 'components/common/TTButton';
import KitImg from 'components/common/KitImg';
import ActionButton from 'components/common/ActionButton';
import AlertDialog from 'components/common/AlertDialog';
import AdminArticleDetails from './AdminArticleDetails';
import AdminVideoDetails from './AdminVideoDetails';
import AddNewArticleDialog from './AddNewArticleDialog';
import AddNewVideoDialog from './AddNewVideoDialog';
//constants
import { csvOptions } from 'helpers/cvsOptions';

const ResourceTable = ({ activeTab }: { activeTab: { label: string; value: string } }) => {
  const articleRef = useRef('');
  const [articleDialogVisible, setArticleDialogVisible] = useState(false);
  const [videoDialogVisible, setVideoDialogVisible] = useState(false);
  const [dialogContent, setDialogContent] = useState<IDialogContent | null>(null);
  const [resourceAction, setResourceAction] = useState<string | null>(null);
  const [rowIdPressed, setRowIdPressed] = useState<string | null>(null);

  const [videoId, setVideoId] = useState<string | null>(null);
  const [articleId, setArticleId] = useState<string | null>(null);

  //Query hooks
  const { isLoadingVideoDetails, isFetchingVideoDetails, videoDetails } = useQueryVideoDetails(
    videoId as string,
    !!videoId,
    setVideoDialogVisible,
  );
  const { isLoadingArticleDetails, isFetchingArticleDetails, articleDetails } =
    useQueryArticleDetails(articleId as string, !!articleId, setArticleDialogVisible);

  const { userRoles, isLoadingUserRoles } = useQueryUserRoles();
  const { knowledgeTags, isLoadingKnowledgeTags } = useQueryKnowledgeTags();
  const { isError, data, total, isLoading, refetchResourceList, isFetching } = useQueryResourceList(
    activeTab.value,
  );
  //Mutation hooks
  const { deleteResourceMutate, isDeleting } = useMutationDeleteResource(
    setDialogContent,
    setResourceAction,
  );
  const { undeleteResourceMutate, isUndeleting } = useMutationUndeleteResource(
    setDialogContent,
    setResourceAction,
  );

  const columns = useMemo<MRT_ColumnDef<IResourcesItem>[]>(
    () => [
      {
        header: 'Type',
        accessorKey: 'resourceType',
        accessorFn: row => row?.resourceType?.charAt(0)?.toUpperCase() + row.resourceType.slice(1),
        size: 100,
      },
      {
        header: 'Image',
        accessorKey: 'resource.imageUrl',
        size: 150,
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }: { row: MRT_Row<IResourcesItem> }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '120px',
              height: '150px',
            }}
          >
            <KitImg
              src={
                row?.original?.resourceType === 'article'
                  ? row?.original?.resource?.imageUrl ||
                    'https://telltail-breeder-resources.s3.amazonaws.com/images/profiles/App Icon Square.png'
                  : row?.original?.resource?.thumbnailImage
              }
              kitwidth={360}
              alt="Article Image"
              style={{
                width: '120px',
                height: '150px',
                borderRadius: '10px',
                marginRight: '5px',
                objectFit: 'cover',
                alignSelf: 'left',
              }}
            />
          </Box>
        ),
      },
      {
        header: 'Title',
        accessorKey: 'resource.title',
        size: 200,
      },
      {
        header: 'Author',
        accessorKey: 'resource.authorName',
        accessorFn: row =>
          row.resourceType === 'article'
            ? row?.resource?.authorName
            : row?.resource?.createdBy?.displayName,
        size: 150,
      },

      {
        header: 'Start Date',
        accessorKey: 'resource.publishStartDate',
        accessorFn: row =>
          moment.utc(row?.resource?.publishStartDate).utcOffset('-0700').format('LLL'),
        size: 250,
      },
      {
        header: 'End Date',
        accessorKey: 'resource.publishEndDate',
        accessorFn: row =>
          moment.utc(row?.resource?.publishEndDate).utcOffset('-0700').format('LLL'),
        size: 250,
      },
      {
        header: 'Visibility',
        accessorKey: 'resource.visibility',
        // make function that return string of fields in array divided by comma and Capitalize first letter
        accessorFn: row =>
          row?.resource?.visibility
            ?.map((item: string) => {
              return item;
            })
            .join(', '),
        size: 200,
      },
      {
        header: 'Locked',
        accessorKey: 'resource.locked',
        // make function that return string of fields in array divided by comma and Capitalize first letter
        accessorFn: row =>
          row?.resource?.locked
            ?.map((item: string) => {
              return item;
            })
            .join(', '),
        size: 200,
      },
    ],
    [],
  );

  // Download table to CSV format logic
  const csvArticleOptions = csvOptions(
    columns,
    `Articles_TellTail_${moment().utc().utcOffset('-0700').format('MMMM_D_YYYY_hh_mm_A')}`,
  );
  const csvExporter = new ExportToCsv(csvArticleOptions);
  const csvData = useMemo(
    () =>
      data?.map(item => ({
        image:
          item?.resourceType === 'article'
            ? item?.resource?.imageUrl
            : item?.resource?.thumbnailImage,
        title: item?.resource?.title,
        author:
          item.resourceType === 'article'
            ? item?.resource?.authorName || item?.resource?.createdBy?.name
            : item?.resource?.createdBy?.name,
        created_date: moment.utc(item?.resource?.createdDate).utcOffset('-0700').format('LLL'),
      })),
    [data],
  );
  const handleExportData = () => {
    csvExporter.generateCsv(csvData);
  };

  // Handle table actions logic
  const handleDeletePress = (row: MRT_Row<IResourcesItem>) => {
    articleRef.current = row.original._id;
    setResourceAction('delete');
    setDialogContent({
      title: 'Delete Resource?',
      message:
        'You are about to delete this resource from the platform, do you really want to do it?',
    });
  };

  const handleUndeletePress = (row: MRT_Row<IResourcesItem>) => {
    articleRef.current = row.original._id;
    setResourceAction('undelete');
    setDialogContent({
      title: 'Undelete Resource?',
      message:
        'Remember to change the start and end dates on this resource to make sure it shows up on the platform',
    });
  };

  const handleEditPress = (row: MRT_Row<IResourcesItem>) => {
    if (row.original.resourceType === 'article') {
      setArticleId(row.original.resource._id);
    } else if (row.original.resourceType === 'video') {
      setVideoId(row.original.resource._id);
    }

    setRowIdPressed(row.original._id);
  };

  // Render table actions column
  const renderArticleTableActions = ({ row }: { row: MRT_Row<IResourcesItem> }) => {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" gap="0.5rem">
        {activeTab.value !== 'deleted' && (
          <>
            <ActionButton
              color="#afafaf"
              title="Edit"
              Icon={
                (isLoadingVideoDetails ||
                  isFetchingVideoDetails ||
                  isLoadingArticleDetails ||
                  isFetchingArticleDetails) &&
                rowIdPressed === row.original._id ? (
                  <AiOutlineLoading3Quarters />
                ) : (
                  <Edit />
                )
              }
              onClick={async () => {
                handleEditPress(row);
              }}
            />
            <ActionButton title="Delete" Icon={<Delete />} onClick={() => handleDeletePress(row)} />
          </>
        )}
        {activeTab.value === 'deleted' && (
          <ActionButton
            color="#81b29a"
            title="Restore"
            Icon={<Restore />}
            onClick={() => handleUndeletePress(row)}
          />
        )}
      </Box>
    );
  };

  const knowledgeTagsOptions = knowledgeTags?.map(tag => ({ value: tag, label: tag }));
  const userRolesOptions = userRoles
    ?.filter(val => val !== 'admin')
    .map(role => ({ value: role, label: role }));

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data ?? []}
        rowCount={total ?? 0}
        initialState={{ pagination: { pageSize: 10, pageIndex: 0 } }}
        memoMode="cells"
        state={{
          isLoading: isLoading || isLoadingKnowledgeTags || isLoadingUserRoles,
          showAlertBanner: isError,
          showProgressBars: isFetching,
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 20, 50],
        }}
        enableColumnOrdering
        enableGlobalFilter={false}
        renderTopToolbarCustomActions={() => (
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              p: '0.5rem',
              pb: '2rem',
            }}
          >
            <TTButton
              className="btn-primary-orchid"
              height={40}
              width={180}
              disabled={isLoading || isFetching}
              text="Export to CSV"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
            />
            <TTButton
              className="btn-primary-orchid"
              height={40}
              width={180}
              marginLeft="10px"
              text="Add Article"
              onClick={() => setArticleDialogVisible(true)}
            />
            <TTButton
              className="btn-primary-orchid"
              height={40}
              width={180}
              marginLeft="10px"
              text="Add Video"
              onClick={() => setVideoDialogVisible(true)}
            />
          </Box>
        )}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={renderArticleTableActions}
        renderDetailPanel={({ row }) =>
          row.original.resourceType === 'article' ? (
            <AdminArticleDetails article={{ ...row.original.resource }} />
          ) : (
            <AdminVideoDetails video={{ ...row.original.resource }} />
          )
        }
      />

      {/* Confirmation Dialog */}
      <AlertDialog
        isOpened={Boolean(dialogContent)}
        dialogContent={dialogContent}
        isLoading={isDeleting || isUndeleting}
        onAgree={() => {
          if (resourceAction === 'delete') {
            articleRef.current && deleteResourceMutate(articleRef.current);
          } else if (resourceAction === 'undelete') {
            articleRef.current && undeleteResourceMutate(articleRef.current);
          } else {
            setDialogContent(null);
          }
        }}
        handleClose={() => setDialogContent(null)}
      />

      {/* Add New Article Dialog */}
      {articleDialogVisible && (
        <AddNewArticleDialog
          open={articleDialogVisible}
          handleClose={() => {
            setArticleId(null);
            refetchResourceList();

            setArticleDialogVisible(false);
          }}
          knowledgeTagsOptions={knowledgeTagsOptions}
          userRolesOptions={userRolesOptions}
          articleDetails={articleDetails?.data}
        />
      )}

      {/* Add New Video Dialog */}
      {videoDialogVisible && (
        <AddNewVideoDialog
          open={videoDialogVisible}
          handleClose={() => {
            setVideoId(null);

            refetchResourceList();

            setVideoDialogVisible(false);
          }}
          knowledgeTagsOptions={knowledgeTagsOptions}
          userRolesOptions={userRolesOptions}
          videoDetails={videoDetails?.data}
        />
      )}
    </>
  );
};

export default ResourceTable;
