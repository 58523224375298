import React, { useState } from 'react';
import { Col, Form, Button, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { isEmpty } from 'lodash';

//validation
import { ForgotPasswordSchema, validateChangePassword } from './Validation';
//components
import SendCodeToEmailForm from './SendCodeToEmailForm';
import TTButton from 'components/common/TTButton';
//hooks
import { useMutationChangePassword } from './__hooks/useMutationChangePassword';
//types
import { IForgotPasswordForm } from 'types';
//styles
import './index.scss';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const [email, setEmail] = useState('');

  // Form handling
  const {
    register,
    handleSubmit,
    setError,
    getFieldState,
    formState: { errors },
  } = useForm<IForgotPasswordForm>({
    resolver: yupResolver(ForgotPasswordSchema),
  });

  // Mutation hooks
  const { mutate, isLoading: isUpdatingPassword } = useMutationChangePassword();

  const onSubmit = async (data: IForgotPasswordForm) => {
    await validateChangePassword({
      newPassword: data.newPassword,
      confirmNewPassword: data.confirmNewPassword,
      setError,
    });

    if (
      !isEmpty(errors) ||
      !isEmpty(getFieldState('newPassword').error) ||
      !isEmpty(getFieldState('confirmNewPassword').error)
    ) {
      return errors;
    } else {
      mutate({
        resetPasswordToken: data.resetPasswordToken,
        newPassword: data.newPassword,
        email: email,
      });
    }
  };

  return (
    <div className="forgot">
      <Container>
        <span className="forgot__back" onClick={() => navigate('/')}>
          <AiOutlineArrowLeft />
          Back
        </span>
      </Container>
      <Container className="forgot__container">
        <div>
          <h2 className="mb-4">Forgot Password</h2>
          <SendCodeToEmailForm
            setShowChangePasswordForm={setShowChangePasswordForm}
            setEmail={setEmail}
          />

          {showChangePasswordForm && (
            <Form onSubmit={handleSubmit(onSubmit)} id="forgotPasswordForm">
              <Col xs={12} className="mt-5 mb-4">
                <Form.Label className="form-labels">Verification code*</Form.Label>
                <Form.Control
                  bsPrefix="input form-control mb-0"
                  type="string"
                  placeholder="Enter the verification code"
                  {...register('resetPasswordToken')}
                />
                <small className="forgot__note">
                  An email with the verification code has been sent to the email address you
                  entered. Please check your email and enter the verification code here.
                </small>
              </Col>

              {/* Password */}
              <Col xs={12} className={`${errors.newPassword ? 'mb-0 input-error' : 'mb-4'} `}>
                <Form.Label className="form-labels">Password*</Form.Label>
                <Form.Control
                  data-private
                  bsPrefix="form-control input mb-0"
                  type="password"
                  placeholder="Enter New password"
                  {...register('newPassword')}
                />
                {errors && errors.newPassword && (
                  <p className="text-error mb-2">{errors.newPassword.message}</p>
                )}
              </Col>

              <Col
                xs={12}
                className={`${errors.confirmNewPassword ? 'mb-0 input-error' : 'mb-4'} `}
              >
                <Form.Label className="form-labels">Confirm Password*</Form.Label>
                <Form.Control
                  data-private
                  bsPrefix="form-control input mb-0"
                  type="password"
                  placeholder="Confirm New password"
                  {...register('confirmNewPassword')}
                />
                {errors && errors.confirmNewPassword && (
                  <p className="text-error mb-2">{errors.confirmNewPassword.message}</p>
                )}
              </Col>

              <div className="password__requirement">
                <h4> Password must have:</h4>
                <ul>
                  <li>At least one upper case letter</li>
                  <li>At least one lower case letter</li>
                  <li>At least one number/digit (between 0 and 9)</li>
                  <li>At least one special character (e.g. !, #, $, &, etc.)</li>
                  <li>Be at least 8 characters in length (and up to 20)</li>
                </ul>
              </div>

              {/* Buttons area */}
              <div className="d-flex w-100 mt-2 breeder__buttons align-items-center justify-content-center">
                <TTButton
                  type="submit"
                  className="btn-primary-orchid"
                  text="Change Password"
                  width="100%"
                  height="50px"
                  fontSize=".9rem"
                  loading={isUpdatingPassword}
                />
              </div>
            </Form>
          )}

          <p className="forgot__noAccount mt-3">
            Need more help? Contact the team <a href="mailto:hello@telltail.com">here</a>.
          </p>
          <p className="forgot__horizontalDivider">
            <span>or</span>
          </p>
          <p className="forgot__noAccount">
            No account yet? Apply here. Waiting for your application to be reviewed? You can expect
            a response within one week from submission.
          </p>
          {/* TODO PRIORITY correct link for breeders and buyers */}
          <Link to="/breeder-registration">
            <Button bsPrefix="forgot__button create" style={{ width: '100%', height: '50px' }}>
              Apply to Join the Community
            </Button>
          </Link>
          <p className="forgot__noAccount">
            By signing up, you agree to our{' '}
            <span
              className="forgot__privacyPolicy"
              onClick={() => {
                window.scrollTo({ top: 0 });
                navigate('/terms');
              }}
            >
              Terms of Use
            </span>{' '}
            &{' '}
            <span
              className="forgot__privacyPolicy"
              onClick={() => {
                window.scrollTo({ top: 0 });
                navigate('/privacy');
              }}
            >
              Privacy Policy
            </span>
          </p>
        </div>
      </Container>
    </div>
  );
};
export default ForgotPassword;
