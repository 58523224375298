import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { getBreederList } from 'services/api/apiCalls/admin';
//types
import { IAdminBreedersResponse, TPaginationState, TError, TColumnFilterState } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useQueryBreeederList = (
  status: string,
  pagination: TPaginationState,
  columnFilters: TColumnFilterState[],
  enabled: boolean,
) => {
  const {
    isError: isBreederListError,
    data,
    isLoading: isLoadingBreederList,
    refetch: refetchBreederList,
    isFetching: isFetchingBreederList,
  } = useQuery<AxiosResponse<IAdminBreedersResponse>, AxiosError<TError>>(
    [
      QUERY_KEYS.BREEDER_LIST,
      status,
      pagination.pageIndex,
      pagination.pageSize,
      JSON.stringify(columnFilters),
    ],
    () => {
      const filtersObj = columnFilters.reduce(
        (acc, { id, value }) => ({ ...acc, [id]: value }),
        {},
      );

      return getBreederList(status, {
        page: pagination.pageIndex + 1,
        limit: pagination.pageSize,
        ...filtersObj,
      });
    },
    {
      enabled,
      keepPreviousData: true,
    },
  );

  return {
    isBreederListError,
    breeders: data?.data?.items,
    totalBreeders: Number(data?.data?.meta.totalItems),
    isLoadingBreederList,
    refetchBreederList,
    isFetchingBreederList,
  };
};
