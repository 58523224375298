const BREEDER = {
  //getBreeder
  BREEDER: 'BREEDER',
  //getUserStatus
  USER_STATUS: 'USER_STATUS',
  //getBreeders
  BREEDERS: 'BREEDERS',
  //getPublicBreeder
  PUBLIC_BREEDER: 'PUBLIC_BREEDER',
  //getBreederMatches
  BREEDER_MATCHES: 'BREEDER_MATCHES',
};

export default BREEDER;
