import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { getVideo } from 'services/api/apiCalls/videos';
//types
import { IVideo, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useQueryVideoDetails = (id: string) => {
  const { isLoading, isError, isFetching, error, data } = useQuery<
    AxiosResponse<IVideo>,
    AxiosError<TError>
  >([QUERY_KEYS.VIDEO_DETAILS, id], () => getVideo(id));

  return {
    isFetching,
    isLoading,
    isError,
    error,
    data: data?.data,
  };
};
export default useQueryVideoDetails;
