import { useQuery } from 'react-query';
import { AxiosResponse, AxiosError } from 'axios';

//services
import { getImportantFactors } from 'services/api/apiCalls/buyer';
//types
import { IImportantFactor, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useQueryImpFactors = () => {
  const {
    data,
    error: errorImpFactors,
    isLoading: isLoadingImpFactors,
  } = useQuery<AxiosResponse<IImportantFactor[]>, AxiosError<TError>>(
    QUERY_KEYS.IMPORTANT_FACTORS,
    getImportantFactors,
  );

  return {
    errorImpFactors,
    impFactors: data?.data,
    isLoadingImpFactors,
  };
};
