import Rachel from 'assets/images/registrationAndSignIn/rachel.png';
import Jennifer from 'assets/images/registrationAndSignIn/jennifer.png';
import Liza from 'assets/images/registrationAndSignIn/liza.png';
import Kjristen from 'assets/images/registrationAndSignIn/kjristen.png';

export const mockMatchesForNonActiveBuyer = [
  {
    breederId: 'breeder-1',
    breederName: 'Rachel M.',
    breederProfileImage: Rachel,
    matchDate: '',
    breederProgramName: 'Your Next Breeder',
    score: 0,
    breederCity: 'City',
    breederState: 'State',
    breederCountry: '',
    goHomeDate: '',
    breederEmail: 'ra**********@gm***.com',
    minPrice: 0,
    maxPrice: 0,
    desiredBreeds: ['Appenzeller Sennenhund'],
    isNew: false,
    breederPhone: '(***) ***-****',
    zipCode: '',
  },
  {
    breederId: 'breeder-2',
    breederName: 'Jennifer R.',
    breederProfileImage: Jennifer,
    matchDate: '',
    breederProgramName: 'Your Next Breeder',
    score: 0,
    breederCity: 'City',
    breederState: 'State',
    breederCountry: '',
    goHomeDate: '',
    breederEmail: 'je**********@gm***.com',
    minPrice: 0,
    maxPrice: 0,
    desiredBreeds: ['Appenzeller Sennenhund'],
    isNew: false,
    breederPhone: '(***) ***-****',
    zipCode: '',
  },
  {
    breederId: 'breeder-3',
    breederName: 'Liza M.',
    breederProfileImage: Liza,
    matchDate: '',
    breederProgramName: 'Your Next Breeder',
    score: 0,
    breederCity: 'City',
    breederState: 'State',
    breederCountry: '',
    goHomeDate: '',
    breederEmail: 'li**********@gm***.com',
    minPrice: 0,
    maxPrice: 0,
    desiredBreeds: ['Appenzeller Sennenhund'],
    isNew: false,
    breederPhone: '(***) ***-****',
    zipCode: '',
  },
  {
    breederId: 'breeder-4',
    breederName: 'Kjirsten B.',
    breederProfileImage: Kjristen,
    matchDate: '',
    breederProgramName: 'Your Next Breeder',
    score: 0,
    breederCity: 'City',
    breederState: 'State',
    breederCountry: '',
    goHomeDate: '',
    breederEmail: 'kj**********@gm***.com',
    minPrice: 0,
    maxPrice: 0,
    desiredBreeds: ['Appenzeller Sennenhund'],
    isNew: false,
    breederPhone: '(***) ***-****',
    zipCode: '',
  },
];
