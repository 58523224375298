import React, { useState, useMemo, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { Box } from '@mui/material';
import MaterialReactTable, { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import moment from 'moment';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem, Divider } from 'rc-menu';
import { Edit, Subscriptions, Visibility } from '@mui/icons-material';

//hooks
import useMutationMarkAsVouched from './__hooks/useMutationMarkAsVouched';
import { useQueryUsersInReview } from './__hooks/useQueryUsersInReview';
import SubscriptionEndDialog from '../Users/SubscriptionEndDialog';
//types
import { IReviewQueueTableRows, IDialogContent, IRequestPayload } from 'types';
//components
import TTButton from 'components/common/TTButton';
import ActionButton from 'components/common/ActionButton';
import EditUserProfileDialog from '../Users/EditUserProfileDialog';
import useMutationApproveAfterReview from './__hooks/useMutationApproveAfterReview';
import AlertDialog from 'components/common/AlertDialog';
//constants
import { csvOptions } from 'helpers/cvsOptions';
import { QUERY_KEYS } from 'constants/queryKeys';
//styles
import { menu_item_style, menu_item_style__disabled } from 'components/styled/menu';
import 'bootstrap/dist/css/bootstrap.min.css';

const ReviewQueueTable = () => {
  const queryClient = useQueryClient();
  const requestPayload = useRef<IRequestPayload>();
  const [dialogContent, setDialogContent] = useState<IDialogContent | null>(null);
  const [editUserProfileDialogContent, setEditUserProfileDialogContent] = useState<
    IReviewQueueTableRows | undefined
  >();
  const [subscriptionDialogContent, setSubscriptionDialogContent] = useState<
    { subscriptionEndType: string; user: IReviewQueueTableRows } | undefined
  >();

  // Query hooks
  const { isError, data, total, isLoading, isFetching } = useQueryUsersInReview();

  //Mutation hooks
  const { markAsVouchedMutate, isMarkingAsVouched } = useMutationMarkAsVouched(setDialogContent);
  const { approveAfterReviewMutate, isApproving } = useMutationApproveAfterReview(setDialogContent);

  const columns = useMemo<MRT_ColumnDef<IReviewQueueTableRows>[]>(
    () => [
      {
        header: 'ID',
        accessorKey: 'breederId',
        size: 200,
      },
      {
        header: 'First Name',
        accessorKey: 'firstName',
        size: 200,
      },
      {
        header: 'Last Name',
        accessorKey: 'lastName',
        size: 200,
      },
      {
        header: 'Email',
        accessorKey: 'email',
        size: 200,
      },
    ],
    [],
  );

  // Download table to CSV format logic
  const csvConfig = csvOptions(
    columns,
    `Users_in_review_${moment().utc().utcOffset('-0700').format('MMMM_D_YYYY_hh_mm_A')}`,
  );
  const csvExporter = new ExportToCsv(csvConfig);
  const csvData = useMemo(
    () =>
      data?.map(item => ({
        _id: item.breederId,
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
      })),
    [data],
  );

  const handleExportData = () => {
    csvExporter.generateCsv(csvData);
  };

  const handleMarkAsVouched = (row: MRT_Row<IReviewQueueTableRows>) => {
    requestPayload.current = {
      payload: { email: row.original.email },
      type: 'type_mark_as_vouched',
    };
    setDialogContent({
      title: `Mark ${row.original.firstName} ${row.original.lastName} as vouched?`,
      message: `Are you sure you want to mark ${row.original.firstName} ${row.original.lastName} as vouched?`,
    });
  };

  const handleApproveThisBreeder = (row: MRT_Row<IReviewQueueTableRows>) => {
    requestPayload.current = {
      payload: row.original.breederId,
      type: 'type_approve_this_breeder',
    };
    setDialogContent({
      title: `Approve ${row.original.firstName} ${row.original.lastName}?`,
      message: `Are you sure you want to approve ${row.original.firstName} ${row.original.lastName}?`,
    });
  };

  // Render table actions column
  const renderTableActions = ({ row }: { row: MRT_Row<IReviewQueueTableRows> }) => {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" gap="0.5rem">
        {/* Dropdown - View Breeder Profile and Vouched Job */}
        <Dropdown
          trigger={['click']}
          placement="bottomRight"
          overlay={() => (
            <Menu
              onClick={({ key }) => {
                if (key === 'view-profile')
                  window
                    .open(`/public-profile/${row.original.breederId}?from=admin`, '_blank')
                    ?.focus();
                else if (key === 'view-vouched') {
                  row.original.isPersona
                    ? window
                        .open(
                          `https://app.withpersona.com/dashboard/inquiries/${row.original.vouchSessionId}`,
                          '_blank',
                        )
                        ?.focus()
                    : window
                        .open(
                          `https://app.vouched.id/account/jobs/${row.original.vouchSessionId}`,
                          '_blank',
                        )
                        ?.focus();
                }
              }}
              style={{ width: 200, background: '#333' }}
            >
              <MenuItem key="view-profile" style={menu_item_style}>
                View Profile
              </MenuItem>
              <Divider style={{ background: 'white', height: 1 }} />
              <MenuItem
                key="view-vouched"
                style={row.original.vouchSessionId ? menu_item_style : menu_item_style__disabled}
                disabled={!row.original.vouchSessionId}
              >
                View Vouched Job
              </MenuItem>
            </Menu>
          )}
          animation="slide-up"
        >
          <ActionButton title="View" Icon={<Visibility />} color="black" />
        </Dropdown>

        <ActionButton
          title="Edit"
          Icon={<Edit />}
          color="gray"
          disabled={isLoading || isFetching}
          onClick={() => setEditUserProfileDialogContent(row.original)}
        />

        {/* Dropdown - View Breeder Profile and Vouched Job */}
        <Dropdown
          trigger={['click']}
          overlay={() => (
            <Menu
              onClick={({ key }) => {
                if (key === 'approve-account') return handleApproveThisBreeder(row);
                else if (key === 'mark-as-vouched') return handleMarkAsVouched(row);

                setSubscriptionDialogContent({
                  subscriptionEndType: key,
                  user: row.original,
                });
              }}
              style={{ width: 200, background: '#8293ee' }}
            >
              {process.env.REACT_APP_ENV_TIER !== 'production' && (
                <>
                  <MenuItem key="mark-as-vouched" style={menu_item_style}>
                    Mark as Vouched
                  </MenuItem>
                  <Divider style={{ background: 'white', height: 1 }} />
                </>
              )}
              <MenuItem key="approve-account" style={menu_item_style}>
                Approve this account
              </MenuItem>
              <Divider style={{ background: 'white', height: 1 }} />
              <MenuItem key="close-account" style={menu_item_style}>
                Close this account
              </MenuItem>
            </Menu>
          )}
        >
          <ActionButton title="Subscription" Icon={<Subscriptions />} color="#8293ee" />
        </Dropdown>
      </Box>
    );
  };

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data ?? []}
        rowCount={total ?? 0}
        initialState={{ pagination: { pageSize: 10, pageIndex: 0 } }}
        memoMode="cells"
        state={{
          isLoading: isLoading,
          showAlertBanner: isError,
          showProgressBars: isFetching,
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 20, 50],
        }}
        enableColumnOrdering
        enableGlobalFilter={false}
        renderTopToolbarCustomActions={() => (
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              p: '0.5rem',
              pb: '2rem',
            }}
          >
            <TTButton
              className="btn-primary-orchid"
              height={40}
              width={200}
              disabled={isLoading || isFetching}
              text="Export to CSV"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
            />
          </Box>
        )}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={renderTableActions}
        renderDetailPanel={() => true}
      />

      {/* Edit Breeder Profile Pop Up */}
      {Boolean(editUserProfileDialogContent) && (
        <EditUserProfileDialog
          open={Boolean(editUserProfileDialogContent)}
          handleClose={async () => {
            setEditUserProfileDialogContent(undefined);
            await queryClient.invalidateQueries(QUERY_KEYS.REVIEW_QUEUE_LIST, {
              refetchInactive: true,
            });
          }}
          displayName={`${editUserProfileDialogContent?.firstName} ${editUserProfileDialogContent?.lastName}`}
          userId={editUserProfileDialogContent?.breederId}
        />
      )}

      {Boolean(subscriptionDialogContent) && (
        <SubscriptionEndDialog
          open={Boolean(subscriptionDialogContent)}
          handleClose={() => {
            setSubscriptionDialogContent(undefined);
            queryClient.invalidateQueries(QUERY_KEYS.REVIEW_QUEUE_LIST, {
              refetchInactive: true,
            });
          }}
          displayName={`${subscriptionDialogContent?.user?.firstName} ${subscriptionDialogContent?.user?.lastName}`}
          userId={subscriptionDialogContent?.user?.userId}
          subscriptionEndType={subscriptionDialogContent?.subscriptionEndType}
        />
      )}

      {/* Confirmation Dialog */}
      <AlertDialog
        isOpened={Boolean(dialogContent)}
        dialogContent={dialogContent}
        isLoading={isMarkingAsVouched || isApproving}
        onAgree={() => {
          if (!requestPayload.current) return;

          if (requestPayload.current.type === 'type_approve_this_breeder') {
            approveAfterReviewMutate(requestPayload.current.payload);
          } else if (requestPayload.current.type === 'type_mark_as_vouched') {
            markAsVouchedMutate(requestPayload.current.payload);
          }
        }}
        handleClose={() => setDialogContent(null)}
      />
    </>
  );
};

export default ReviewQueueTable;
