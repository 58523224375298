import React, { ChangeEvent, useState, useRef, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Select from 'react-select';
import { capitalize } from 'lodash';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import 'react-datepicker/src/stylesheets/datepicker.scss';

//types
import { IDialogProps, ICreateEditDealSchema } from 'types';
//hooks
import {
  useMutationCreateNewDeal,
  useMutationEditDeal,
} from '../__hooks/useMutationCreateEditNewDeal';
//components
import InfoView from 'components/common/InfoView';
import KitImg from 'components/common/KitImg';
import RichTextEditor from 'components/common/SunTextEditor';
//assets
import NoImageAvailable from 'assets/images/adminPanel/no-profile-pic.svg';
//constants
import validExtensions from 'constants/images/validExtensions';
//helpers
import { getImage } from 'helpers/getImage';
//validations
import { CreateNewDealSchema } from './Validation';
//styles
import { multiSelectStyles } from 'components/styled/select';
//services
import { getCloudflareUploadUrl, uploadImageForForms } from 'services/api/apiCalls/cloudflare';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateEditNewDealDialog = ({
  open,
  handleClose,
  knowledgeTagsOptions,
  dealToEdit,
}: IDialogProps) => {
  const TAG_LIMIT = 3;
  const IMAGE_SIZE = 200;
  const errorSpanRef = useRef<HTMLDivElement | null>(null);
  const userRoles = ['admin', 'free', 'buyer'];

  const [vendorLogo, setVendorLogo] = useState<File | string | null>(null);
  const [dealPicture, setDealPicture] = useState<File | string | null>(null);

  const {
    register,
    watch,
    handleSubmit,
    setError,
    control,
    trigger,
    clearErrors,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ICreateEditDealSchema>({
    resolver: yupResolver(CreateNewDealSchema),
  });

  // Filling form with exiting deal, we want to edit
  useEffect(() => {
    if (!dealToEdit) return;

    reset({ ...dealToEdit, endDate: dealToEdit.endDate === '' ? undefined : dealToEdit.endDate });
    setVendorLogo(dealToEdit.vendorLogo);
    setDealPicture(dealToEdit.dealPicture);
  }, [dealToEdit, reset]);

  const handleDialogDismiss = () => {
    reset({
      vendorName: '',
      dealTitle: '',
      dealDescription: '',
      discountCode: '',
    });
    setVendorLogo(null);
    setDealPicture(null);
    creatNewDealResetApiState();
    editDealResetApiState();
    handleClose();
  };

  const scrollToBottom = () =>
    errorSpanRef.current?.scrollIntoView({ inline: 'end', behavior: 'smooth' });

  //Mutation hooks
  const { createNewDealMutate, isCreatingNewDeal, createNewDealError, creatNewDealResetApiState } =
    useMutationCreateNewDeal(handleDialogDismiss, scrollToBottom);
  const { editDealMutate, isEditingDeal, editDealError, editDealResetApiState } =
    useMutationEditDeal(handleDialogDismiss, scrollToBottom);

  const getButtonText = () => (dealToEdit ? 'Update' : 'Create');

  const handleClickBrowse = (e: ChangeEvent<HTMLInputElement>, isVendorLogo: boolean) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;

    if (validExtensions.includes(file?.type)) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        if (isVendorLogo) {
          setValue('vendorLogo', file.name);
          clearErrors('vendorLogo');
          setVendorLogo(file);
        } else {
          setValue('dealPicture', file.name);
          clearErrors('dealPicture');
          setDealPicture(file);
        }
      };
      fileReader.readAsDataURL(file);
    }
  };

  const handleDescriptionEditorStateChange = useCallback(
    (editorState: string) => {
      setValue('dealDescription', editorState, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [setValue],
  );

  const onSubmit = async (dealData: ICreateEditDealSchema) => {
    const tags = dealData.tags.map(item => item.value);
    const cloudflareUploadUrl = await getCloudflareUploadUrl();
    const cloudflareUploadUrlTwo = await getCloudflareUploadUrl();

    const vendorLogoUrl =
      cloudflareUploadUrl !== null && typeof vendorLogo !== 'string'
        ? await uploadImageForForms({
            pathAndName: 'vendor',
            image: vendorLogo!,
            url: cloudflareUploadUrl,
          })
        : vendorLogo;

    const dealPictureUrl =
      cloudflareUploadUrlTwo !== null && typeof dealPicture !== 'string'
        ? await uploadImageForForms({
            pathAndName: 'deal',
            image: dealPicture!,
            url: cloudflareUploadUrlTwo,
          })
        : dealPicture;

    const payload = {
      ...dealData,
      startDate: moment(dealData.startDate).format('yyyy-MM-DD'),
      endDate: dealData.endDate ? moment(dealData.endDate).format('yyyy-MM-DD') : '',
      vendorLogo: vendorLogoUrl as string,
      dealPicture: dealPictureUrl as string,
      discountCode: dealData.discountCode.toUpperCase(),
      tags,
    };

    if (dealToEdit) {
      editDealMutate(payload);
    } else {
      createNewDealMutate(payload);
    }
  };

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="create-deal-dialog-slide-description"
    >
      <DialogTitle bgcolor="darkblue" color="white">
        Create New Deal
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          width="50vw"
          height="70vh"
          m={2}
          position="relative"
        >
          <Form.Label>Vendor Name</Form.Label>
          <Form.Control
            bsPrefix="input form-control mb-0 py-3"
            placeholder="Enter vendor name"
            {...register('vendorName')}
          />
          {errors && errors.vendorName && (
            <span className="text-error mt-2">{errors.vendorName.message}</span>
          )}

          <Box display="flex" flexDirection="row" gap="20px" mt={2}>
            <InfoView
              p={0}
              flex={1}
              label="Vendor Logo:"
              value={
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Card elevation={3}>
                    <KitImg
                      src={getImage(vendorLogo, NoImageAvailable)}
                      kitwidth={IMAGE_SIZE}
                      style={{
                        width: IMAGE_SIZE,
                        maxHeight: IMAGE_SIZE,
                        background: '#f5f5f5',
                        objectFit: 'contain',
                      }}
                    />
                  </Card>
                  {errors && errors.vendorLogo && (
                    <span className="text-error mt-2">{errors.vendorLogo.message}</span>
                  )}

                  <Box mt={2}>
                    <input
                      type="file"
                      id="admin_vendor_image"
                      accept="image/*"
                      onChange={e => handleClickBrowse(e, true)}
                      onClick={event => {
                        (event.target as HTMLInputElement).value = ''; // Workarround to trigger onChange event for same file
                      }}
                      hidden
                    />
                    <label htmlFor="admin_vendor_image">
                      <Button variant="outlined" component="span">
                        {vendorLogo ? 'Change Vendor Logo' : 'Add Vendor Logo'}
                      </Button>
                    </label>
                  </Box>
                </Box>
              }
              vertical
            />

            <InfoView
              p={0}
              flex={1}
              label="Deal Picture:"
              value={
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Card elevation={3}>
                    <KitImg
                      src={getImage(dealPicture, NoImageAvailable)}
                      kitwidth={IMAGE_SIZE}
                      style={{
                        width: IMAGE_SIZE,
                        maxHeight: IMAGE_SIZE,
                        background: '#f5f5f5',
                        objectFit: 'contain',
                      }}
                    />
                  </Card>
                  {errors && errors.dealPicture && (
                    <span className="text-error mt-2">{errors.dealPicture.message}</span>
                  )}

                  <Box mt={2}>
                    <input
                      type="file"
                      id="admin_deal_image"
                      accept="image/*"
                      onChange={e => handleClickBrowse(e, false)}
                      onClick={event => {
                        (event.target as HTMLInputElement).value = ''; // Workarround to trigger onChange event for same file
                      }}
                      hidden
                    />
                    <label htmlFor="admin_deal_image">
                      <Button variant="outlined" component="span">
                        {dealPicture ? 'Change Deal Picture' : 'Add Deal Picture'}
                      </Button>
                    </label>
                  </Box>
                </Box>
              }
              vertical
            />
          </Box>

          <Box display="flex" flexDirection="row" gap="20px" mt={2}>
            <InfoView
              flex={1}
              p={0}
              label="Start Date:"
              value={
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      selected={value ? moment(value).toDate() : null}
                      minDate={moment(
                        moment().utc().utcOffset('-0700').format('yyyy-MM-DD'),
                      ).toDate()}
                      onChange={(date: Date) => onChange(date)}
                      popperClassName="react-datepicker-popper red-border"
                      dateFormat="yyyy-MM-dd"
                      placeholderText="YYYY-MM-DD"
                      customInput={<Form.Control bsPrefix="input form-control mt-2" />}
                    />
                  )}
                />
              }
              vertical
            />

            <InfoView
              flex={1}
              p={0}
              label="End Date:"
              value={
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      selected={value ? moment(value).toDate() : null}
                      minDate={watch('startDate') ? moment(watch('startDate')).toDate() : null}
                      onChange={(date: Date) => onChange(date)}
                      popperClassName="react-datepicker-popper"
                      dateFormat="yyyy-MM-dd"
                      placeholderText="YYYY-MM-DD"
                      customInput={<Form.Control bsPrefix="input form-control mt-2" />}
                    />
                  )}
                />
              }
              vertical
            />
          </Box>
          {errors && errors.startDate && (
            <span className="text-error mt-2">{errors.startDate.message}</span>
          )}

          <Box mt={2}>
            <Form.Label>Deal Title</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0 py-3"
              placeholder="Enter deal title"
              {...register('dealTitle')}
            />
            {errors && errors.dealTitle && (
              <span className="text-error mt-2">{errors.dealTitle.message}</span>
            )}
          </Box>

          <InfoView
            p={0}
            mt={3}
            label="Deal Description:"
            value={
              <RichTextEditor
                key={dealToEdit?.dealDescription}
                awsFolderName={process.env.REACT_APP_AWS_OTHERS_FOLDER_PATH!}
                containerClassName="mt-1"
                editorStyle={{ borderRadius: 5, height: 600, maxHeight: 600, overflow: 'scroll' }}
                fileNamePrefix="deal_description"
                onEditorStateChange={handleDescriptionEditorStateChange}
                knowledgeTagsOptions={knowledgeTagsOptions}
                editorState={dealToEdit?.dealDescription}
              />
            }
            vertical
          />

          <Box mt={2}>
            <Form.Label>Discount Code</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0 py-3"
              placeholder="Enter discount code"
              {...register('discountCode')}
            />
            {errors && errors.discountCode && (
              <span className="text-error mt-2">{errors.discountCode.message}</span>
            )}
          </Box>

          <InfoView
            p={0}
            mt={3}
            label="Visibility:"
            value={
              <Box display="flex" flexDirection="row">
                {userRoles?.map((userRole, idx) => (
                  <Controller
                    key={userRole + idx}
                    control={control}
                    name="visibility"
                    render={({ field: { onChange, value } }) => (
                      <Box display="flex" alignItems="center">
                        <Checkbox
                          id={userRole + idx}
                          key={open + ''}
                          defaultChecked={dealToEdit?.visibility.some(item => item === userRole)}
                          name={userRole}
                          onChange={e => {
                            trigger('visibility');

                            return onChange(
                              e.target.checked
                                ? [...(value || []), userRole]
                                : value?.filter(role => role !== userRole),
                            );
                          }}
                        />
                        <label htmlFor={userRole + idx}>
                          {userRole === 'free' ? 'Breeder' : capitalize(userRole)}
                        </label>
                      </Box>
                    )}
                  />
                ))}
              </Box>
            }
            vertical
          />
          {errors && errors.visibility && (
            <span className="text-error mt-2">{errors.visibility.message}</span>
          )}

          <Box mt={2}>
            <Controller
              control={control}
              name="tags"
              render={({ field: { onChange, value } }) => (
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  isSearchable
                  value={value}
                  onMenuOpen={() => setTimeout(scrollToBottom, 100)}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(val: any, actionMeta: any) => {
                    if (
                      watch('tags') &&
                      watch('tags').length >= TAG_LIMIT &&
                      actionMeta.action === 'select-option'
                    ) {
                      setError('tags', {
                        type: 'custom',
                        message: ' The maximum number of tags you can select here is 3',
                      });
                    } else if (actionMeta.action === 'select-option') {
                      return onChange(val);
                    } else if (actionMeta.action === 'remove-value' && watch('tags').length <= 1) {
                      setError('tags', {
                        type: 'custom',
                        message: 'You must have at least one tag',
                      });
                    } else if (actionMeta.action === 'remove-value') {
                      trigger('tags');

                      return onChange(val);
                    }
                  }}
                  options={knowledgeTagsOptions}
                  placeholder="Enter tags here (E.g. IT/Software, Pet parent communications, etc.)"
                  styles={multiSelectStyles}
                  classNamePrefix="tt-select"
                />
              )}
            />

            {errors && errors.tags && (
              <span className="text-error mt-2">{errors.tags.message}</span>
            )}
          </Box>

          <span ref={errorSpanRef} className="text-error mt-4">
            {createNewDealError?.response?.data.ExceptionMessage || createNewDealError?.message}
          </span>

          <span ref={errorSpanRef} className="text-error mt-4">
            {editDealError?.response?.data.ExceptionMessage || editDealError?.message}
          </span>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogDismiss}>Cancel</Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitting || isCreatingNewDeal || isEditingDeal}
        >
          {isSubmitting || isCreatingNewDeal || isEditingDeal ? (
            <AiOutlineLoading3Quarters
              size={20}
              color="blue"
              style={{ margin: '0.2rem' }}
              className="loading-animation svg-loading"
            />
          ) : (
            getButtonText()
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEditNewDealDialog;
