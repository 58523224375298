import { Dispatch, SetStateAction } from 'react';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

//services
import { getBreed } from 'services/api/apiCalls/breeds';
//types
import { IBreedDetails, ISimilarBreedsData } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useQueryBreedDetails = (
  id: string,
  enabled: boolean,
  setSimilarBreeds: Dispatch<SetStateAction<ISimilarBreedsData[]>>,
  similarBreeds: ISimilarBreedsData[],
) => {
  const {
    data: breedDetails,
    error: errorBreedDetails,
    isLoading: isLoadingBreedDetails,
    isFetching: isFetchingBreedDetails,
  } = useQuery<AxiosResponse<IBreedDetails>, Error>([QUERY_KEYS.BREED, id], () => getBreed(id), {
    onSuccess: data => {
      !isEmpty(data.data.similiarBreedsData) &&
        setSimilarBreeds(
          uniqBy([...similarBreeds, ...(data.data.similiarBreedsData as [])], '_id'),
        );
    },
    enabled: !!enabled,
    retry: false,
  });

  return {
    errorBreedDetails,
    breedDetails: breedDetails?.data,
    isLoadingBreedDetails,
    isFetchingBreedDetails,
  };
};
