import React from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import parse from 'html-react-parser';
import { useQueryClient } from 'react-query';
import { AiOutlineArrowLeft, AiOutlineLoading3Quarters } from 'react-icons/ai';

//hooks
import useQueryGetDealDetails from '../__hooks/useQueryGetDealDetails';
//types
import { IUserInfo } from 'types';
//components
import Banner from 'components/common/HeadingBanner';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';
//styles
import './index.scss';

const DealDetails = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { dealId } = useParams();

  const userInfo = queryClient.getQueryData(QUERY_KEYS.USER_STATUS) as {
    data: IUserInfo | undefined;
  };
  // Query hooks
  const { isLoading, error, deal } = useQueryGetDealDetails(dealId as string);

  if (isLoading)
    return (
      <div className="d-flex width-full justify-content-center my-3">
        <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
      </div>
    );

  if (
    error ||
    !deal ||
    userInfo?.data?.isSfcBreeder === false ||
    userInfo?.data?.isSfcBreeder === undefined
  )
    return (
      <div className="d-flex width-full justify-content-center my-2">
        <p className="text-error">{error?.response?.data.ExceptionMessage || error?.message}</p>
      </div>
    );

  return (
    <>
      <Banner
        title="Tail-Wagging Perks for Breeders!"
        subTitle={
          <span>
            Valid: {moment(deal.startDate).format('MMMM Do, YYYY')} -{' '}
            {deal.endDate ? moment(deal.endDate).format('MMMM Do, YYYY') : 'Infinite'}
          </span>
        }
        bgColor="darkGreen"
        tags={deal.tags}
      />

      <Container className="deal_details">
        <div className="deal_container">
          <div className="deal_back" onClick={() => navigate(-1)}>
            <AiOutlineArrowLeft />
            Back
          </div>

          <div className="deal_image_container">
            <img src={deal.dealPicture} alt={deal.dealTitle} />
          </div>

          <div className="deal_info_container">
            <div className="vendor_info_container">
              <img className="vendor_logo" src={deal.vendorLogo} />
              <span className="vendor_name">{deal.vendorName}</span>
            </div>
            <p className="deal_title">{deal.dealTitle}</p>
            <p className="deal_description">
              {parse(
                (deal.dealDescription || '')
                  .replace(/href/g, "target='_blank' href")
                  .replaceAll('/tr:w-300', '/tr:w-2000'),
              )}
            </p>

            <div className="divider" />

            <p className="discount_code_label">Discount Code</p>
            <p className="discount_code">{deal.discountCode}</p>
          </div>
        </div>
      </Container>
    </>
  );
};

export default DealDetails;
