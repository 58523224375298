import React, { useState, Fragment } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { MdOutlineDeleteForever } from 'react-icons/md';
import { CgProfile } from 'react-icons/cg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

//types
import { IBreeder } from 'types';
//components
import ImageUpload from './ImageUpload';
import TTButton from 'components/common/TTButton';
//hooks
import { useMutationDeleteBreederGallery } from './__hooks/useMutationUploadBreederGallery';
import { useMutationUpdateBreederInfo } from './__hooks/useMutationUpdateBreederInfo';

const MyGallery = ({ breederInfo, breederId }: { breederInfo: IBreeder; breederId: string }) => {
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);

  //Mutation hooks
  const { deleteBreederImage, isDeletingBreederImage } = useMutationDeleteBreederGallery();
  const { mutate, isLoading: isUpdatingUserProfile } = useMutationUpdateBreederInfo(breederId);

  const renderCustomThumbs = () =>
    breederInfo.breederGallery.map((image, index) => (
      <Fragment key={index}>
        <img src={image} alt={image} />
        <TTButton
          className="btn-primary-orchid delete_btn_sfc_dl"
          text={<MdOutlineDeleteForever size={20} />}
          disabled={isDeletingBreederImage}
          width="35px"
          height="35px"
          onClick={() => deleteBreederImage({ breederGalleryUrl: image, id: breederId })}
        />
        <TTButton
          className={`${
            breederInfo.profilePicUrl === image ? 'btn-primary-orchid' : 'btn-neutral-navy'
          }  bookmark_btn_sfc_dl`}
          text={<CgProfile size={20} />}
          width="35px"
          height="35px"
          disabled={isUpdatingUserProfile}
          onClick={() => mutate({ data: { ...breederInfo, profilePicUrl: image }, id: breederId })}
        />
      </Fragment>
    ));

  return (
    <>
      <div className="gallery_icons_info">
        <TTButton
          className="btn-neutral-navy bookmark_btn_info"
          text={<CgProfile size={20} />}
          width="30px"
          height="30px"
        />{' '}
        Clicking on this icon will make the selected image your profile picture.
        <br />
        <TTButton
          className="btn-primary-orchid bookmark_btn_info"
          text={<CgProfile size={20} />}
          width="30px"
          height="30px"
        />{' '}
        Your current profile picture is highlighted in pink.
      </div>

      <div className="gallery">
        <Carousel infiniteLoop width="400px" renderThumbs={renderCustomThumbs}>
          {breederInfo.breederGallery.map((image, index) => (
            <div className="gallery_img" key={index}>
              <img alt={image || ''} src={image} />
            </div>
          ))}
        </Carousel>
      </div>

      <ImageUpload
        setUploadedImages={setUploadedImages}
        uploadedImages={uploadedImages}
        breederId={breederId}
        numberOfImages={breederInfo.breederGallery.length}
      />
    </>
  );
};

export default MyGallery;
