import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

//components
import BreedEncyclopaediaTable from './BreedEncyclopaediaTable';

const BreedEncyclopaedia = () => {
  return (
    <>
      <h3 className="mb-4">Breedopedia</h3>
      <BreedEncyclopaediaTable />
    </>
  );
};

export default BreedEncyclopaedia;
