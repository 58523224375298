import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { registerToPawfficeHour } from 'services/api/apiCalls/pawfficeHour';
//types
import { IAddDeleteAttendeeSchema, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useMutationRegisterToPawfficeHour = () => {
  const queryClient = useQueryClient();

  const {
    mutate: registerPawfficeHourMutate,
    isLoading: isRegistring,
    error: registerPawfficeHourError,
  } = useMutation<AxiosResponse<string>, AxiosError<TError>, IAddDeleteAttendeeSchema>(
    data => registerToPawfficeHour(data.pawfficeId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.PAWFFICE_HOUR_LIST, {
          refetchInactive: true,
        });
      },
    },
  );

  return {
    registerPawfficeHourMutate,
    isRegistring,
    registerPawfficeHourError,
  };
};
