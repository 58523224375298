import { Dispatch, SetStateAction } from 'react';
import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { testMatchPrototypeWithBuyerInfo } from 'services/api/apiCalls/buyer';
//types
import { TError, IAlgorithmTestingPayload, IPrototypeAlgorithmTestResults } from 'types';

export const useMutationTestMatchingAlgorithm = (
  setAlgorithmTestResults: Dispatch<SetStateAction<IPrototypeAlgorithmTestResults>>,
) => {
  const { addToast } = useToasts();

  return useMutation<
    AxiosResponse<IPrototypeAlgorithmTestResults>,
    AxiosError<TError>,
    IAlgorithmTestingPayload
  >(testMatchPrototypeWithBuyerInfo, {
    onSuccess: async data => {
      setAlgorithmTestResults(data?.data);
      addToast('Match algorithm data has been fetched!', {
        appearance: 'success',
        autoDismiss: true,
      });
    },
    onError: async () => {
      addToast('Something went wrong with your request.', {
        appearance: 'error',
        autoDismiss: true,
      });
    },
  });
};
