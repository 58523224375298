import React, { ChangeEvent, useEffect, useState } from 'react';
import { includes, filter } from 'lodash';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

//types
import { IBreedsFilterTypes } from 'types';
//components
import CheckBox from 'components/common/CheckBox';
//styles
import './index.scss';

const Filters = ({
  level = 2,
  filterList,
  selectedFilters,
  setSelectedFilters,
  setSelectedBreed,
}: IBreedsFilterTypes) => {
  const [openDropdowns, setOpendropdowns] = useState<string[]>(['Breed type']);

  useEffect(() => {
    const open =
      filterList
        ?.map(mFilter =>
          mFilter.categoryTags.some(tag => selectedFilters?.includes(tag.slugName))
            ? mFilter.categoryName
            : '',
        )
        ?.filter(item => item !== '') || [];
    setOpendropdowns(prev => [...prev, ...open]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterList]);

  const getBarkingMark = (slugName: string) => {
    switch (slugName) {
      case 'barking':
        return 'Quieter breeds';
      case 'adaptability':
        return 'Adapts well to change';
      case 'friendliness':
        return 'Good with kids';
      case 'cuddliness':
        return 'Very affectionate';
      case 'high-energy':
        return 'Very active';
      case 'low-energy':
        return 'Lower energy';
      case 'maintenance':
        return 'Lower maintenance';
      case 'trainability':
        return 'Easy to train';
      default:
        return '';
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    // We need to remove filter if it is already selected
    if (includes(selectedFilters, e.target.name)) {
      setSelectedFilters(filter(selectedFilters, val => val !== e.target.name));
      // Or select it if it is not selected
    } else {
      setSelectedFilters([...selectedFilters, e.target.name]);
    }
  };

  return (
    <div className="breed_directory__filtersContainer">
      <div className="filter_heading">
        <h5>Filters</h5>
        <p
          className="cursor-pointer"
          onClick={() => {
            setSelectedFilters([]);
            setSelectedBreed(null);
          }}
        >
          Clear search
        </p>
      </div>
      {filterList?.map(({ categoryName, categoryTags }, idx) => {
        return (
          <div key={`${categoryName}${idx}`} className="w-100">
            <div className="filter_category_name_container">
              <p className="filter_category_name">{categoryName}</p>
              {includes(openDropdowns, categoryName) ? (
                <MdKeyboardArrowUp
                  size={32}
                  className="text-center cursor-pointer"
                  onClick={() =>
                    setOpendropdowns(filter(openDropdowns, val => val !== categoryName))
                  }
                />
              ) : (
                <MdKeyboardArrowDown
                  size={32}
                  className="text-center cursor-pointer"
                  onClick={() => setOpendropdowns([...openDropdowns, categoryName])}
                />
              )}
            </div>
            {includes(openDropdowns, categoryName) &&
              categoryName !== 'Characteristics' &&
              categoryTags?.map(({ name, slugName }, index) => (
                <div className={`single-filter ${level === 2 && 'subfilter'}`} key={index}>
                  <CheckBox
                    text={name}
                    id={`${slugName}_${idx}`}
                    name={slugName}
                    checked={includes(selectedFilters, slugName)}
                    onChange={handleChange}
                  />
                </div>
              ))}

            {includes(openDropdowns, categoryName) &&
              categoryName === 'Characteristics' &&
              categoryTags?.map(({ slugName }, index) => (
                <div
                  className={`single-filter ${level === 2 && 'subfilter'} characteristics`}
                  key={index}
                >
                  <CheckBox
                    text={getBarkingMark(slugName)}
                    id={`${slugName}_${idx}`}
                    name={slugName}
                    checked={includes(selectedFilters, slugName)}
                    onChange={handleChange}
                  />
                </div>
              ))}
          </div>
        );
      })}
    </div>
  );
};

export default Filters;
