import { intersection, isEmpty, flatMap } from 'lodash';
import { IRoutesConfig } from 'types';

export default function getAllowedRoutes(
  claims: string[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  routeAccessConfig: IRoutesConfig[] | any,
): IRoutesConfig[] {
  // If no claims is sent return original array, if not return array filtered by claims
  if (isEmpty(claims)) return routeAccessConfig;

  return flatMap(routeAccessConfig, function (route) {
    if (isEmpty(route.permission) || intersection(route.permission, claims).length) {
      return isEmpty(route.children)
        ? route
        : { ...route, children: getAllowedRoutes([], route.children) };
    } else return [];
  });
}
