import { Dispatch, SetStateAction } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { setIsVouched } from 'services/api/apiCalls/auth';
import { setVouchFlagged } from 'services/api/apiCalls/breederApplication';

//types
import { TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useMutationIsVouched = (step: number, setStep: Dispatch<SetStateAction<number>>) => {
  // const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError<TError>, { vouchedSessionId: string }>(
    setIsVouched,
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(QUERY_KEYS.USER_STATUS, {
        //   refetchInactive: true,
        // });

        window.scrollTo({ top: 0 });
        setStep(8);
      },
    },
  );
};

export const useMutationSetVouchFlagged = () => {
  const queryClient = useQueryClient();

  const { mutate, error, isLoading } = useMutation<AxiosResponse<void>, AxiosError<TError>, string>(
    setVouchFlagged,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.USER_STATUS, {
          refetchInactive: true,
        });
      },
    },
  );

  return {
    mutate,
    isLoading,
    error,
  };
};
