/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

//types
import { TProtectedR, TPublicR } from 'types';

export const ProtectedRoute = ({ children, isAuthenticated, userInfo }: TProtectedR): any => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const firstPath = location.pathname.split('/')[1];
  const followUpLink = location?.state?.followUpLink;

  if (isAuthenticated) {
    if (
      !userInfo?.isBuyer &&
      userInfo?.isSfcBreeder === false &&
      userInfo?.seenCertificateOffer === false &&
      firstPath !== 'get-certified'
    ) {
      return (
        <Navigate
          to={`/get-certified?${userInfo?.registrationStep}&${searchParams}`}
          state={{ prevPath: 'login' }}
        />
      );
    } else if (
      !userInfo?.isBuyer &&
      userInfo?.isSfcBreeder === true &&
      userInfo?.isVouched === false &&
      firstPath !== 'get-certified'
    ) {
      return (
        <Navigate
          to={`/get-certified?${userInfo?.registrationStep}&${searchParams}`}
          state={{ prevPath: 'login' }}
        />
      );
    } else if (
      userInfo?.isBuyer === true &&
      userInfo?.hasCompletedRegistration === false &&
      firstPath !== 'finish-buyer-registration'
    ) {
      return (
        <Navigate to={`/finish-buyer-registration?${searchParams}`} state={{ prevPath: 'login' }} />
      );
    } else if (
      // If user is SfcBreeder or buyer, we are redirecting to profile route or follow up link
      ((userInfo?.isVouched && userInfo?.isSfcBreeder) || userInfo?.isBuyer === true) &&
      firstPath === 'get-certified'
    ) {
      if (followUpLink) {
        return (
          <Navigate
            to={`${followUpLink}?${searchParams}`}
            state={{ followUpLink: followUpLink, prevPath: 'login' }}
          />
        );
      } else {
        return <Navigate to={`/profile?${searchParams}`} state={{ prevPath: 'login' }} />;
      }
    }

    return children;
  } else {
    return <Navigate to={`/login?${searchParams}`} />;
  }
};

export const PublicRoute = ({
  children,
  isAuthenticated,
  userInfo,
  breederInfo,
}: TPublicR): any => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const firstPath = location.pathname.split('/')[1];
  const followUpLink = location?.state?.followUpLink;

  if (isAuthenticated) {
    // If user is authenticated and is on a public only route, we are redirecting to profile route
    if (
      (userInfo?.isBuyer ||
        (breederInfo?.hearAboutUs !== '' && breederInfo?.hearAboutUs !== undefined)) &&
      firstPath === 'breeder-registration'
    ) {
      return <Navigate to={`/profile?${searchParams}`} />;
    } else if (
      firstPath === 'login' ||
      firstPath === 'buyer-login' ||
      firstPath === 'forgot-password' ||
      firstPath === 'buyer' ||
      firstPath === 'buyer/submitted' ||
      firstPath === 'buyer-registration' ||
      firstPath === ''
    ) {
      if (followUpLink) {
        return (
          <Navigate
            to={`${followUpLink}?${searchParams}`}
            state={{ followUpLink: followUpLink, prevPath: 'login' }}
          />
        );
      } else {
        return <Navigate to={`/profile?${searchParams}`} state={{ prevPath: 'login' }} />;
      }
    }

    return children;
  } else {
    return children;
  }
};
