/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
// @ts-ignore
import IntlTelInput from 'intl-tel-input/react/build/IntlTelInput.esm';
import 'intl-tel-input/build/css/intlTelInput.css';

//types
import { IPhoneNumberForm, IBuyerInfo } from 'types';
//validation
import { phoneNumberSchema } from './Validation';
//constants
import { numberValidationErrors } from 'constants/lists/numberValidationErrors';
//hooks
import { useMutationSendCodeToPhone } from '../__hooks/useMutationVerifyPhoneNumber';
//components
import TTButton from 'components/common/TTButton';
import SMSVerification from '../SMSVerification';
//styles
import '../index.scss';

export default function VerifyPhoneNumber({ buyerInfo }: { buyerInfo: IBuyerInfo }) {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<IPhoneNumberForm>({
    resolver: yupResolver(phoneNumberSchema),
    defaultValues: {
      phoneNumber: buyerInfo.phone,
    },
  });
  const [step, setStep] = useState(1);
  const [errorCode, setErrorCode] = useState(0);

  // Mutation hooks
  const { mutate, isLoading } = useMutationSendCodeToPhone({ setStep, step });

  const setIsValid = (isValid: boolean) => {
    if (isValid) {
      clearErrors('phoneNumber');
    } else {
      const errorMessage = numberValidationErrors[errorCode] || 'Invalid number';

      setError('phoneNumber', {
        type: 'custom',
        message: errorMessage,
      });
    }
  };

  const onSubmit = (data: IPhoneNumberForm) => {
    window.scrollTo({ top: 0 });

    return mutate(data.phoneNumber);
  };

  return (
    <div className="registration-container">
      {step === 1 && (
        <div className="about-program-container">
          <h2>
            Connect with Certified
            <br /> Breeders, faster.
          </h2>
          <h5>Verify your phone number to share your info with your breeder matches.</h5>

          <Form
            onSubmit={handleSubmit(onSubmit)}
            className="about-program-container__form"
            id="verifyPhoneNumberForm"
          >
            <Row className="fill-available">
              <Col xs={12} className={`${errors.phoneNumber ? 'mb-0 input-error' : 'mb-4'} `}>
                <Form.Label className="form-labels">Phone Number</Form.Label>
                <br />
                <Controller
                  control={control}
                  name={'phoneNumber'}
                  render={({ field: { onChange, value } }) => (
                    <IntlTelInput
                      initialValue={value}
                      onChangeNumber={onChange}
                      onChangeValidity={setIsValid}
                      onChangeErrorCode={setErrorCode}
                      initOptions={{
                        initialCountry: 'us',
                        utilsScript:
                          'https://cdn.jsdelivr.net/npm/intl-tel-input@19.2.19/build/js/utils.js',
                      }}
                    />
                  )}
                />
                {errors && errors.phoneNumber && (
                  <p className="text-error mb-2 text-error-number-verify">
                    {errors.phoneNumber.message}
                  </p>
                )}
              </Col>
            </Row>

            <p className="verify-number-description">
              By verifying your phone number, you agree to allow TellTail and TellTail Certified
              Breeders to send you messages. Your plan’s messaging rates may apply.
            </p>

            <div className="buttons-container">
              <span
                className="skip-button"
                onClick={() => {
                  window.scrollTo({ top: 0 });
                  navigate('/profile/information');
                  window.location.reload();
                }}
              >
                Skip for now
              </span>

              <TTButton
                type="submit"
                height="50px"
                className="btn-primary-orchid"
                text="Continue"
                disabled={isLoading}
              />
            </div>
          </Form>
        </div>
      )}

      {step === 2 && <SMSVerification buyerInfo={buyerInfo} step={step} setStep={setStep} />}
    </div>
  );
}
