import React from 'react';
import { createContext, useContext } from 'react';

//types
import { TPushFunctionType, IDataLayerProviderProps, IDataLayerObject } from 'types';

const DataLayerContext = createContext<TPushFunctionType | undefined>(undefined);

export const useDataLayer = (): TPushFunctionType => {
  const context = useContext(DataLayerContext);
  if (!context) {
    throw new Error('useDataLayer must be used within a DataLayerProvider');
  }

  return context;
};

export const DataLayerProvider: React.FC<IDataLayerProviderProps> = ({
  children,
}: IDataLayerProviderProps) => {
  const pushToDataLayer = (obj: IDataLayerObject) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(obj);
  };

  return <DataLayerContext.Provider value={pushToDataLayer}>{children}</DataLayerContext.Provider>;
};
