/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import { isEmpty } from 'lodash';

//components
import TTButton from 'components/common/TTButton';
//validation
import { AboutProgramSchema } from './Validation';
//types
import { IAboutProgramNewProfileForm, IBreeder, IBreedWithSizeVairation } from 'types';
//hooks
import { useMutationUpdateBreederInfo } from './__hooks/useMutationUpdateBreederInfo';
//styles
import { multiSelectStyles } from 'components/styled/select';

const AboutProgram = ({
  breederInfo,
  breederId,
  breeds,
}: {
  breederInfo: IBreeder;
  breederId: string;
  breeds: IBreedWithSizeVairation[];
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IAboutProgramNewProfileForm>({
    resolver: yupResolver(AboutProgramSchema),
    defaultValues: !isEmpty(breederInfo)
      ? {
          businessProgram: breederInfo?.businessProgram,
          breedsInProgram:
            breederInfo?.breeds && breederInfo?.breeds.map(item => ({ label: item, value: item })),
          yearsOfExperience: breederInfo?.yearsOfExperience,
          breederProgramDescription:
            breederInfo?.breederProgramDescription || breederInfo?.programDetails,
          breedingProgramWebsite: breederInfo?.breedingProgramWebsite,
          facebookUrl: breederInfo?.facebookUrl,
          instagramUrl: breederInfo?.instagramUrl,
          twitterUrl: breederInfo?.twitterUrl,
          youtubeUrl: breederInfo?.youtubeUrl,
          tikTokUrl: breederInfo?.tikTokUrl,
        }
      : {},
  });

  const breedsDropdown = useMemo(
    () =>
      breeds?.map((breed: { breedName: string }) => ({
        label: breed.breedName,
        value: breed.breedName,
      })),
    [breeds],
  );

  //Mutation hooks
  const { mutate, isLoading: isUpdatingUserProfile } = useMutationUpdateBreederInfo(breederId);

  const onSubmit: SubmitHandler<IAboutProgramNewProfileForm> = data => {
    const payload = {
      data: {
        ...breederInfo,
        businessProgram: data.businessProgram || '',
        breeds: data.breedsInProgram ? data.breedsInProgram.map(item => item.label) : [],
        yearsOfExperience: Number(data.yearsOfExperience),
        breederProgramDescription: data.breederProgramDescription || '',
        programDetails: data.breederProgramDescription || '',
        breedingProgramWebsite: data.breedingProgramWebsite || '',
        facebookUrl: data.facebookUrl || '',
        instagramUrl: data.instagramUrl || '',
        twitterUrl: data.twitterUrl || '',
        youtubeUrl: data.youtubeUrl || '',
        tikTokUrl: data.tikTokUrl || '',
        middleName: breederInfo?.middleName || '',
        hearAboutUs: breederInfo?.hearAboutUs || '',
      },
      id: breederId,
    };

    mutate(payload);
  };

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="my_acc_form"
      id="breederProfileMyProgramForm"
    >
      <div className="my_program_subtitle">
        With the exception of your home address, information shared below will appear in your public
        profile.
      </div>
      <Row className="mt-5">
        <Col xs={12} md={6} className={`${errors.businessProgram ? 'mb-0 input-error' : 'mb-4'} `}>
          <Form.Label className="form-labels">Program name (20 characters max)*</Form.Label>
          <Form.Control
            bsPrefix="input form-control mb-0"
            type="text"
            placeholder="Enter business name"
            disabled
            {...register('businessProgram')}
          />
          {errors && errors.businessProgram && (
            <p className="text-error mb-2">{errors.businessProgram.message}</p>
          )}
        </Col>

        <Col xs={12} md={6}>
          <Form.Label className="form-labels">Years of experience?*</Form.Label>
          <Form.Control
            bsPrefix={`input form-control ${
              errors.yearsOfExperience ? 'mb-0 input-error' : 'mb-4'
            }  `}
            type="number"
            placeholder="Enter Years"
            maxLength={10}
            {...register('yearsOfExperience')}
          />
          {errors && errors.yearsOfExperience && (
            <p className="text-error mb-1">{errors.yearsOfExperience.message}</p>
          )}
        </Col>
      </Row>

      <Col xs={12} className={`${errors.breedsInProgram ? 'mb-0 input-error' : 'mb-4'}`}>
        <Form.Label className="form-labels">Breeds in program*</Form.Label>
        <Controller
          control={control}
          name="breedsInProgram"
          render={({ field: { onChange, value } }) => (
            <Select
              closeMenuOnSelect={false}
              isMulti
              isSearchable
              value={value}
              onChange={(val: any) => onChange(val)}
              options={breedsDropdown}
              placeholder={
                isEmpty(value)
                  ? 'Select breeds in program'
                  : `${value.length} option${value.length > 1 && 's'} selected`
              }
              styles={multiSelectStyles}
              classNamePrefix="tt-select"
            />
          )}
        />
        {errors && errors.breedsInProgram && (
          <p className="text-error mb-2">{errors.breedsInProgram.message}</p>
        )}
      </Col>

      <Row>
        <Col
          xs={12}
          className={`mt-2 small ${errors.breederProgramDescription ? 'mb-0 input-error' : 'mb-4'}`}
        >
          <Form.Label className="form-labels">
            Program description* (max 2000 characters)
          </Form.Label>
          <Form.Control
            bsPrefix="form-control mb-0"
            type="text"
            placeholder="Enter text"
            as="textarea"
            maxLength={2000}
            rows={10}
            {...register('breederProgramDescription')}
          />
          {errors && errors.breederProgramDescription && (
            <p className="mb-2 text-error">{errors.breederProgramDescription.message}</p>
          )}
        </Col>
      </Row>

      {/* SOCIAL MEDIA SECTION */}
      <Row>
        <Col xs={12} md={6} className="mb-4">
          <Form.Label className="form-labels">Facebook</Form.Label>
          <Form.Control
            bsPrefix="input form-control mb-0"
            type="text"
            placeholder="Enter URL"
            {...register('facebookUrl')}
          />
        </Col>

        <Col xs={12} md={6} className="mb-4">
          <Form.Label className="form-labels">Instagram</Form.Label>
          <Form.Control
            bsPrefix="input form-control mb-0"
            type="text"
            placeholder="Enter URL"
            {...register('instagramUrl')}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6} className="mb-4">
          <Form.Label className="form-labels">Twitter</Form.Label>
          <Form.Control
            bsPrefix="input form-control mb-0"
            type="text"
            placeholder="Enter URL"
            {...register('twitterUrl')}
          />
        </Col>

        <Col xs={12} md={6} className="mb-4">
          <Form.Label className="form-labels">YouTube</Form.Label>
          <Form.Control
            bsPrefix="input form-control mb-0"
            type="text"
            placeholder="Enter URL"
            {...register('youtubeUrl')}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6} className="mb-4">
          <Form.Label className="form-labels">Website</Form.Label>
          <Form.Control
            bsPrefix="input form-control mb-0"
            type="text"
            placeholder="Enter URL"
            {...register('breedingProgramWebsite')}
          />
        </Col>
        <Col xs={12} md={6} className="mb-4">
          <Form.Label className="form-labels">TikTok</Form.Label>
          <Form.Control
            bsPrefix="input form-control mb-0"
            type="text"
            placeholder="Enter URL"
            {...register('tikTokUrl')}
          />
        </Col>
      </Row>

      {/* Buttons area */}
      <div className="d-flex w-100 mt-4  align-items-center justify-content-end">
        <TTButton
          type="submit"
          className="btn-primary-orchid save_btn"
          text="Save"
          fontSize="12px"
          borderRadius="10px"
          loading={isUpdatingUserProfile}
          width="100px"
          height="40px"
        />
      </div>
    </Form>
  );
};

export default AboutProgram;
