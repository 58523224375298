/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState, useRef } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { Button, Checkbox, DialogActions } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Check, Close } from '@mui/icons-material';
import { Autocomplete } from '@react-google-maps/api';
import { isEmpty, find, includes } from 'lodash';

//types
import { IBasicInfoSchema, IUpdateBreeder, IUpdateEmailSchema, TGoogleMapAddress } from 'types';
//helpers
import { getPostalCode, getFullAddress } from 'helpers/googleMaps';
//validation
import { BasicInfoSchema, ChangeEmailSchema } from './Validation';
//hooks
import useMutationBasicInfo from './__hooks/useMutationBasicInfo';
import useMutationUpdateBreederEmail from './__hooks/useMutationUpdateBreederEmail';
import { useMutationProvideAdminAccess } from './__hooks/useMutationProvideAdminAccess';
//styles
import './index.scss';

const BasicInfo = ({ breeder, setShowSuccess, onCancelPress }: IUpdateBreeder) => {
  const placesInputRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<google.maps.places.Autocomplete>();
  const [parsedAddress, setParsedAddress] = useState<TGoogleMapAddress | null>(
    breeder
      ? {
          streetAddress: breeder.address,
          city: breeder.city,
          state: breeder.state,
          postalCode: breeder.zipCode,
          country: breeder.country,
          lat: Number(breeder.geoLat),
          lng: Number(breeder.geoLong),
        }
      : null,
  );
  const [isProperAddress, setIsProperAddress] = useState(true);
  const selectedAddress = useRef(
    parsedAddress ? getFullAddress(parsedAddress) : breeder.fullAddress,
  );

  // react hook form to edit email
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setError: setError2,
    formState: { errors: errors2, isDirty: isDirty2 },
  } = useForm<IUpdateEmailSchema>({
    resolver: yupResolver(ChangeEmailSchema),
    defaultValues: {
      newEmail: breeder.email,
    },
  });

  // react hook form to edit breeder's basic info
  const {
    register,
    handleSubmit,
    setError,
    getValues,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm<IBasicInfoSchema>({
    resolver: yupResolver(BasicInfoSchema(isProperAddress)),
    defaultValues: {
      firstName: breeder.firstName,
      middleName: breeder.middleName,
      lastName: breeder.lastName,
      displayName: breeder.displayName,
      businessProgram: breeder.businessProgram,
      fullAddress: parsedAddress ? getFullAddress(parsedAddress) : breeder.fullAddress,
      streetAddress: breeder.address,
      city: breeder.city,
      state: { value: breeder.state, label: breeder.state },
      zipCode: breeder.zipCode,
      country: { value: breeder.country, label: breeder.country },
    },
  });

  //Mutation hooks
  const { isLoading, mutate } = useMutationBasicInfo({ setShowSuccess, setError });
  const { isUpdatingEmail, updateEmailMutate } = useMutationUpdateBreederEmail({
    setShowSuccess,
    setError: setError2,
  });
  const { provideAdminAccessMutate, isProviding } = useMutationProvideAdminAccess({
    setShowSuccess,
    setError,
  });

  const watchAddress = watch('fullAddress');
  const isEmailVerified = breeder.emailConfirmed;

  const onSubmit2 = (payload: IUpdateEmailSchema) =>
    updateEmailMutate({ payload, breederId: breeder._id });

  useEffect(() => {
    if (!isEmpty(watchAddress)) trigger('fullAddress');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProperAddress, trigger, watchAddress, watch]);

  const handlePlaceChanged = async () => {
    if (!inputRef.current) return;

    const place = inputRef.current.getPlace();

    if (!place) return;

    // Address field which contains street_number and route considers as full and accepted address
    const streetNumber = find(place?.address_components, val =>
      includes(val.types, 'street_number'),
    )?.long_name;
    const route = find(place?.address_components, val => includes(val.types, 'route'))?.long_name;
    setIsProperAddress(!!streetNumber && !!route);

    let postalCode = find(place?.address_components, val =>
      includes(val.types, 'postal_code'),
    )?.long_name;

    if (!postalCode && place.geometry?.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      postalCode = await getPostalCode(lat, lng);
    }

    selectedAddress.current = placesInputRef.current?.value || '';
    const tempParsedAddress = {
      streetAddress: `${streetNumber} ${route}`,
      city: find(place?.address_components, val => includes(val.types, 'locality'))?.long_name,
      state: find(place?.address_components, val =>
        includes(val.types, 'administrative_area_level_1'),
      )?.short_name,
      postalCode,
      country: find(place?.address_components, val => includes(val.types, 'country'))?.long_name,
      lat: place?.geometry?.location?.lat() || 0,
      lng: place?.geometry?.location?.lng() || 0,
    };
    setParsedAddress(tempParsedAddress);

    setValue('fullAddress', getFullAddress(tempParsedAddress) || '');
  };

  const onSubmit = (data: IBasicInfoSchema) => {
    if (selectedAddress.current.toLowerCase() !== placesInputRef.current?.value.toLowerCase()) {
      return setError('fullAddress', {
        type: 'custom',
        message: 'Please enter your full street address.',
      });
    }

    const payload = {
      displayName: data.displayName,
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      businessProgram: data.businessProgram,
      fullAddress: data.fullAddress,
      address: parsedAddress?.streetAddress ? parsedAddress?.streetAddress : breeder.address,
      city: parsedAddress?.city ? parsedAddress?.city : breeder.city,
      state: parsedAddress?.state ? parsedAddress?.state : breeder.state,
      zipCode: parsedAddress?.postalCode ? parsedAddress?.postalCode : breeder.zipCode,
      country: parsedAddress?.country ? parsedAddress?.country : breeder.country,
      geoLong: parsedAddress?.lng.toString() as string,
      geoLat: parsedAddress?.lat.toString() as string,
    };

    mutate({ payload, breederId: breeder._id });
  };

  return (
    <>
      <div className="signupOnboarding__desc align_center signupOnboarding__inputs mt-0">
        <Row>
          <Col xs={12} className={`${errors2.newEmail ? 'mb-0 input-error' : 'mb-4'}`}>
            <Form.Label>Email*</Form.Label>
            <div className="d-flex gap-2">
              <Form.Control
                bsPrefix="input form-control mb-0"
                type="email"
                placeholder="Enter email"
                {...register2('newEmail')}
              />

              {!isDirty2 && (
                <div className="d-flex align-items-center">
                  {isEmailVerified ? (
                    <Check sx={{ color: 'green' }} />
                  ) : (
                    <Close sx={{ color: 'red' }} />
                  )}
                  <span className={`verified ${!isEmailVerified && 'not'}`}>
                    {isEmailVerified ? 'Verified' : 'Not Verified'}
                  </span>
                </div>
              )}

              <Button
                disabled={!isDirty2 || isUpdatingEmail}
                variant="contained"
                className="change-email"
                onClick={handleSubmit2(onSubmit2)}
              >
                {isUpdatingEmail ? (
                  <AiOutlineLoading3Quarters
                    size={20}
                    color="blue"
                    style={{ margin: '0.2rem' }}
                    className="loading-animation svg-loading"
                  />
                ) : (
                  'Change Email'
                )}
              </Button>
            </div>
            {errors2 && errors2.newEmail && (
              <p className="text-error mb-2">{errors2.newEmail.message}</p>
            )}
          </Col>
        </Row>
        <Form onSubmit={handleSubmit(onSubmit)} id="refurral-form">
          {/* Personal info */}
          <Row>
            <Col xs={12} md={6} className={`${errors.firstName ? 'mb-0 input-error' : 'mb-4'} `}>
              <Form.Label>First Name*</Form.Label>
              <Form.Control
                bsPrefix="input form-control mb-0"
                type="text"
                placeholder="Enter first name"
                {...register('firstName')}
                onChange={e => {
                  const value = e.target.value;

                  setValue('firstName', value, { shouldValidate: true });
                  setValue('displayName', `${value} ${getValues('lastName')}`, {
                    shouldValidate: true,
                  });
                }}
              />
              {errors && errors.firstName && (
                <p className="text-error mb-2">{errors.firstName.message}</p>
              )}
            </Col>

            <Col xs={12} md={6} className="mb-4">
              <Form.Label>Middle Name</Form.Label>
              <Form.Control
                bsPrefix="input form-control mb-0"
                type="text"
                placeholder="Enter middle name"
                {...register('middleName')}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6} className={`${errors.lastName ? 'mb-0 input-error' : 'mb-4'} `}>
              <Form.Label>Last Name*</Form.Label>
              <Form.Control
                bsPrefix="input form-control mb-0"
                type="text"
                placeholder="Enter last name"
                {...register('lastName')}
                onChange={e => {
                  const value = e.target.value;

                  setValue('lastName', value, { shouldValidate: true });
                  setValue('displayName', `${getValues('firstName')} ${value}`, {
                    shouldValidate: true,
                  });
                }}
              />
              {errors && errors.lastName && (
                <p className="text-error mb-2">{errors.lastName.message}</p>
              )}
            </Col>

            <Col xs={12} md={6} className={`${errors.displayName ? 'mb-0 input-error' : 'mb-4'} `}>
              <Form.Label>Display Name*</Form.Label>
              <Form.Control
                bsPrefix="input form-control mb-0"
                type="text"
                placeholder="Enter display name"
                {...register('displayName')}
              />
              {errors && errors.displayName && (
                <p className="text-error mb-2">{errors.displayName.message}</p>
              )}
            </Col>
          </Row>

          <Row>
            <Col
              xs={12}
              md={6}
              className={`${errors.businessProgram ? 'mb-0 input-error' : 'mb-4'} `}
            >
              <Form.Label>Business Name*</Form.Label>
              <Form.Control
                bsPrefix="input form-control mb-0"
                type="text"
                placeholder="Enter business name"
                {...register('businessProgram')}
              />
              {errors && errors.businessProgram && (
                <p className="text-error mb-2">{errors.businessProgram.message}</p>
              )}
            </Col>
          </Row>

          {/* Address */}
          <Row>
            <Autocomplete
              onLoad={ref => (inputRef.current = ref)}
              types={['address']}
              onPlaceChanged={handlePlaceChanged}
            >
              <Col xs={12} className={`${errors.fullAddress ? 'mb-0 input-error' : 'mb-4'}`}>
                <Form.Label className="form-labels">Address*</Form.Label>
                <Col className="d-flex align-items-center gap-2 mb-3">
                  <input
                    ref={placesInputRef}
                    className="input form-control mb-0"
                    type="text"
                    placeholder="Type here to see address suggestions"
                    defaultValue={watch('fullAddress')}
                  />
                  {!isEmpty(watch('fullAddress')) && (
                    <p
                      className="m-0 info"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (placesInputRef.current) placesInputRef.current.value = '';

                        setValue('fullAddress', '');
                        trigger('fullAddress');
                      }}
                    >
                      Clear
                    </p>
                  )}
                </Col>
                {errors && errors.fullAddress && (
                  <p className="text-error mb-2">{errors.fullAddress.message}</p>
                )}
              </Col>
            </Autocomplete>
          </Row>

          {/* Admin access */}
          <Row>
            <Col xs={12}>
              {isProviding ? (
                <AiOutlineLoading3Quarters
                  size={20}
                  color="black"
                  style={{ margin: '0rem 0.8rem' }}
                  className="loading-animation svg-loading"
                />
              ) : (
                <Checkbox
                  id="admin-check"
                  key={open + ''}
                  defaultChecked={breeder.userRoles?.includes('admin')}
                  onChange={e =>
                    provideAdminAccessMutate({
                      userId: breeder.userId!,
                      roles: e.target.checked ? ['breeder', 'admin'] : ['breeder'],
                    })
                  }
                />
              )}
              <label htmlFor="admin-check">Provide admin access to this breeder.</label>
            </Col>
          </Row>
        </Form>
      </div>

      <DialogActions
        sx={{ position: 'absolute', left: 0, bottom: 0, width: '100%', background: 'white' }}
      >
        <Button onClick={onCancelPress}>Close</Button>
        <Button disabled={isLoading} onClick={handleSubmit(onSubmit)}>
          {isLoading ? (
            <AiOutlineLoading3Quarters
              size={20}
              color="blue"
              style={{ margin: '0.2rem' }}
              className="loading-animation svg-loading"
            />
          ) : (
            'Update'
          )}
        </Button>
      </DialogActions>
    </>
  );
};

export default BasicInfo;
