import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

//components
import TTButton from 'components/common/TTButton';
import KitImg from 'components/common/KitImg';
import TagItem from 'components/common/TagItem';
import Comments from './Comments';
//hooks
import useMutationFollowDiscussion from './__hooks/useMutationFollowDiscussion';
import useMutationLikeDislikeResource from './__hooks/useMutationLikeDislikeResource';
//assets
import likeIcon from 'assets/images/discussion/activityLike.svg';
import likedIcon from 'assets/images/discussion/activityLiked.svg';
import chatIcon from 'assets/images/discussion/chat.svg';
import followIcon from 'assets/images/discussion/follow.svg';
import followingIcon from 'assets/images/discussion/following.svg';
import video from 'assets/images/video.png';
import playVideo from 'assets/images/resourceCenter/playVideo.svg';
//types
import { IVideo } from 'types';
//styles
import './index.scss';

const ArticleCard = ({
  item,
  setShowWHoleCard,
  showWholeCard,
  itemId,
}: {
  item: IVideo;
  status: string;
  isFetching: boolean;
  setShowWHoleCard: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  showWholeCard: { [key: string]: boolean };
  itemId: string;
}) => {
  const [showReplies, setShowReplies] = useState(false);
  const [repliesCount, setRepliesCount] = useState(0);

  // Mutation hooks
  const { followDiscussionMutate, isLoadingFollowUnfollow } = useMutationFollowDiscussion();
  const { likeDislikeMutate, isLoadingLikeDislike } = useMutationLikeDislikeResource();

  useEffect(() => {
    if (item.replies?.length) {
      let count = item.replies.length;
      item.replies.forEach(elem => {
        count += elem.replies?.length | 0;
      });
      setRepliesCount(count);
    }
  }, [item]);

  return (
    <>
      {/* Article heading */}
      <div className="card-heading">
        <Row className="m-0">
          <Col className="m-0 p-0">
            <KitImg
              width="39"
              src={item?.createdBy?.profileImage || ''}
              kitwidth="40"
              imgClassName="kit-image-heading"
            />
          </Col>
          <Col className="m-0 p-0 cursor-pointer">
            <Link
              to={`/profile/${item?.createdBy?._id}`}
              onClick={() => {
                window.scrollTo({ top: 0 });
              }}
              className="text-light"
            >
              <p className="heading-text-left-up"> {item?.createdBy?.displayName}</p>
              <p className="heading-text-left-down">{item?.createdBy?.name}</p>
            </Link>
          </Col>
        </Row>
        <div className="heading-text-right">{moment(item.updatedDate).format('MMM DD, YYYY')}</div>
      </div>

      {/* Article image */}
      <Link
        to={`/video/${item._id}`}
        onClick={() => {
          window.scrollTo({ top: 0 });
        }}
        className="text-light"
      >
        <div className="image-container">
          <KitImg
            width="100%"
            src={video}
            kitwidth="630"
            className="kit-image-wrapper"
            imgClassName="kit-image"
          />
          <span className="article-badge">VIDEO</span>

          <span className="play__badge">
            <img src={playVideo} alt="Play" />
          </span>
        </div>
      </Link>

      {/* Card text area */}
      <Col className="card-text-area">
        <Link
          to={`/video/${item._id}`}
          onClick={() => {
            window.scrollTo({ top: 0 });
          }}
          className="text-light"
        >
          <h5 className="card-title-text">{item.title}</h5>
        </Link>

        {/* Tags section */}
        <ul className="comment__tags">
          {item?.createdBy?.breederTags &&
            item?.createdBy?.breederTags.map((tag, idx) => <TagItem key={idx} name={tag} />)}
        </ul>
      </Col>

      {/* Footer */}
      <div className="footer">
        {/* Replies and follow icons */}
        <div className="footer-left">
          <div
            className={`tt-hover ${isLoadingLikeDislike && 'disable-click'}`}
            onClick={() => likeDislikeMutate(item.conversationId)}
          >
            <img src={item.isLiked ? likedIcon : likeIcon} alt="" />
            <span>
              {isLoadingLikeDislike ? (
                <AiOutlineLoading3Quarters className="loading-animation svg-following" />
              ) : (
                `${item.likes} ${item.likes !== 1 ? 'Likes' : 'Like'}`
              )}
            </span>
          </div>

          <div
            className="tt-hover"
            onClick={() => {
              setShowWHoleCard({ ...showWholeCard, [itemId]: true });
              setShowReplies(!showReplies);
            }}
          >
            <img src={chatIcon} alt="" />
            <span>
              {repliesCount} {repliesCount <= 1 ? 'Comment' : 'Comments'}
            </span>
          </div>

          <div
            className={`tt-hover ${isLoadingFollowUnfollow && 'disable-click'}`}
            onClick={() => followDiscussionMutate(item.conversationId)}
          >
            <img
              src={'isFollowing' in item && item.isFollowing ? followingIcon : followIcon}
              alt="follow"
            />
            <span>
              {isLoadingFollowUnfollow ? (
                <AiOutlineLoading3Quarters className="loading-animation svg-following" />
              ) : 'isFollowing' in item && item.isFollowing ? (
                'Following'
              ) : (
                'Follow'
              )}
            </span>
          </div>
        </div>

        <Link
          to={`/video/${item._id}`}
          onClick={() => {
            window.scrollTo({ top: 0 });
          }}
          className="text-light"
          target="_blank"
          rel="noopener noreferrer"
        >
          <TTButton
            className="btn-secondary-navy reply-btn"
            text="Reply"
            width="75px"
            height="32px"
            fontSize="12px"
          />
        </Link>
      </div>

      {/* Comments */}
      {showReplies && item?.replies && item?.replies?.length > 0 && (
        <>
          <div className="comment-top-border" onClick={() => setShowReplies(!showReplies)}>
            {showReplies ? (
              <MdKeyboardArrowDown color="#8993B4" className="icon-arrow" />
            ) : (
              <MdKeyboardArrowUp />
            )}
            <div className="top-border" />
          </div>

          <div className="activity-comments container m-0" id="comments">
            {item?.replies?.map((reply, idx) => (
              <div key={`${reply._id}${idx}`}>
                <Comments
                  item={reply}
                  level={1}
                  isFetching={false}
                  resourceLink={`/video/${item._id}`}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default ArticleCard;
