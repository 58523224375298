import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

//components
import ShopifyUnlinkedTable from './ShopifyUnlinkedTable';

const Shopify = () => {
  return (
    <>
      <h3 className="mb-4">Shopify unmatched orders</h3>
      <ShopifyUnlinkedTable />
    </>
  );
};

export default Shopify;
