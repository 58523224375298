/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import { includes } from 'lodash';

//services
import { createConversationReply } from 'services/api/apiCalls/community';
//types
import { IReply, IReplyPayload, TError } from 'types';
//helpers
import eventSink from 'helpers/eventSink';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useMutationCreateDiscussionChildReply = () => {
  const queryClient = useQueryClient();
  const userInfo = queryClient.getQueryData(QUERY_KEYS.USER_STATUS);
  // @ts-ignore
  const breederInfo = queryClient.getQueryData([QUERY_KEYS.BREEDER, userInfo.data.breederId]);

  const {
    mutate: createConversationChildReplyMutate,
    isLoading: createConversationChildReplyIsLoading,
  } = useMutation<AxiosResponse<IReply>, AxiosError<TError>, IReplyPayload>(
    data => createConversationReply(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.COMMUNITY_CONVERSATIONS_BY_ID, {
          refetchInactive: true,
        });
        queryClient.invalidateQueries(QUERY_KEYS.VIDEO_DETAILS);
        queryClient.invalidateQueries(QUERY_KEYS.ARTICLE_DETAILS);

        eventSink(
          {
            // @ts-ignore
            userId: breederInfo?.data?.userId,
            // @ts-ignore
            ttUser: includes(breederInfo?.data?.email, 'telltail'),
            time: moment().toISOString(),
            type: 'create',
            subtype: 'reply_to_reply',
          },
          { title: 'create reply_to_reply', url: location.pathname },
        );
      },
    },
  );

  return { createConversationChildReplyMutate, createConversationChildReplyIsLoading };
};

export default useMutationCreateDiscussionChildReply;
