import * as Yup from 'yup';

export const createAccountSchema = isProperAddress =>
  Yup.object().shape({
    firstName: Yup.string().required('This field is required.'),
    lastName: Yup.string().required('This field is required.'),
    emailAddress: Yup.string().email('Invalid email').required('This field is required.'),
    phoneNumber: Yup.string()
      .test('phoneNumber', 'Please enter valid phone number!', function (value) {
        if (value) {
          const schema = Yup.string().matches(/^\+[1-9]\d{1,14}$/);
          const result = schema.isValidSync(value);

          return result;
        }

        return true;
      })
      .required('This field is required.'),
    fullAddress: Yup.string()
      .required(
        'This field is required. Please select a location from the provided dropdown suggestions.',
      )
      .test('isProperAddress', 'Please enter your full street address.', function () {
        return isProperAddress;
      }),
    password: Yup.string()
      .required('This field is required.')
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,}$/,
        'Password must meet requirements.',
      ),
    confirmPassword: Yup.string()
      .required('This field is required.')
      .oneOf([Yup.ref('password'), null], 'Passwords should match.'),
    acceptedTermsOfService: Yup.bool()
      .default(false)
      .oneOf([true], 'You must accept the terms and conditions.'),
  });
