import { Dispatch, SetStateAction } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { undeleteResource } from 'services/api/apiCalls/admin';
//types
import { TError, IDialogContent } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useMutationUndeleteVideo = (
  setDialogContent: (dialogContent: IDialogContent | null) => void,
  setResourceAction: Dispatch<SetStateAction<string | null>>,
) => {
  const queryClient = useQueryClient();

  const { mutate: undeleteResourceMutate, isLoading: isUndeleting } = useMutation<
    AxiosResponse<string>,
    AxiosError<TError>,
    string
  >(videoId => undeleteResource(videoId), {
    onSuccess: () => {
      queryClient.removeQueries(QUERY_KEYS.RESOURCE_LIST);

      setResourceAction(null);
      setDialogContent(null);
    },
  });

  return {
    undeleteResourceMutate,
    isUndeleting,
  };
};

export default useMutationUndeleteVideo;
