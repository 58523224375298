import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { getArticle } from 'services/api/apiCalls/articles';
//types
import { IArticle, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useQueryArticleDetails = (
  id: string,
  enabled = false,
  setArticleDialogVisible: (articleDialogVisible: boolean) => void,
) => {
  const {
    isLoading: isLoadingArticleDetails,
    isFetching: isFetchingArticleDetails,
    data: articleDetails,
    isSuccess: isSuccessArticleDetails,
  } = useQuery<AxiosResponse<IArticle>, AxiosError<TError>>(
    [QUERY_KEYS.ARTICLE_DETAILS, id],
    () => getArticle(id),
    {
      enabled,
      onSuccess: () => {
        setArticleDialogVisible(true);
      },
    },
  );

  return {
    isLoadingArticleDetails,
    articleDetails,
    isSuccessArticleDetails,
    isFetchingArticleDetails,
  };
};

export default useQueryArticleDetails;
