import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { createRefurralCode } from 'services/api/apiCalls/admin';
//types
import { IAddNewRefurralCodeSchema, TError } from 'types';

const useMutationAddNewRefurralCode = (handleDialogDismiss: () => void) => {
  const { addToast } = useToasts();

  const { mutate: addNewRefurralCodeMutate, isLoading: newCodeIsAdding } = useMutation<
    AxiosResponse<string>,
    AxiosError<TError>,
    IAddNewRefurralCodeSchema
  >(data => createRefurralCode(data), {
    onSuccess: () => {
      addToast('Refurral code added successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
      handleDialogDismiss();
    },
    onError: error => {
      const axiosError = error as AxiosError<TError>;
      addToast(axiosError.response?.data.ExceptionMessage || axiosError.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    },
  });

  return {
    addNewRefurralCodeMutate,
    newCodeIsAdding,
  };
};

export default useMutationAddNewRefurralCode;
