import * as Yup from 'yup';

export const ChangeEmailSchema = Yup.object().shape({
  newEmail: Yup.string().required('This field is required.').email('Invalid email.'),
});

export const personalInfoSchema = (isProperAddress: boolean) =>
  Yup.object().shape({
    firstName: Yup.string().required('This field is required.'),
    middleName: Yup.string().nullable(),
    lastName: Yup.string().required('This field is required.'),
    phoneNumber: Yup.string()
      .test('phoneNumber', 'Please enter valid phone number!', function (value) {
        if (value) {
          const schema = Yup.string().matches(/^\+[1-9]\d{1,14}$/);
          const result = schema.isValidSync(value);

          return result;
        }

        return true;
      })
      .required('This field is required.'),
    state: Yup.object(),
    fullAddress: Yup.string()
      .required('This field is required.')
      .test('isProperAddress', 'Please enter your full street address.', function () {
        return isProperAddress;
      }),
  });
