import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { getCommentList } from 'services/api/apiCalls/admin';
//types
import { IAdminCommentsResponse, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useQueryCommentList = (status: string) => {
  const {
    isError,
    data,
    isLoading,
    refetch: refetchCommentList,
    isFetching,
  } = useQuery<AxiosResponse<IAdminCommentsResponse>, AxiosError<TError>>(
    [QUERY_KEYS.COMMENT_LIST, status],
    () => getCommentList(status, { page: 1, limit: 1000000 }),
    {
      keepPreviousData: true,
    },
  );

  return {
    isError,
    data: data?.data?.items,
    total: Number(data?.data?.meta.itemsCount),
    isLoading,
    refetchCommentList,
    isFetching,
  };
};

export default useQueryCommentList;
