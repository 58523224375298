import React, { useMemo, useContext, useState, useEffect } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { find, isEmpty, filter, concat, includes, isEqual } from 'lodash';

//types
import { TStepper, IBuyerBreedsForm, TAuthContext, ISimilarBreedsData } from 'types';
//validation
import { buyerBreedsSchema } from './Validation';
//components
import TTButton from 'components/common/TTButton';
import BreedInfo from './BreedInfo';
//services
import { useDataLayer } from 'services/dataLayerContext';
import { AuthState } from 'services/auth';
//hooks
import { useQueryBreedsWithSizeVariation } from '../__hooks/useQueryBreedsWithSizeVariation';
import { useQueryBreedsDropdown } from 'pages/BreedDirectory/__hooks/useQueryBreedsDropdown';
//styles
import { multiSelectStyles } from 'components/styled/select';
import '../index.scss';

export default function BreedsInProgram({ setStep, step }: TStepper) {
  const pushToDataLayer = useDataLayer();

  const {
    setBuyerRegistrationInfo,
    state: { buyerRegistrationInfo },
  } = useContext(AuthState) as TAuthContext;
  const [similarBreeds, setSimilarBreeds] = useState<ISimilarBreedsData[]>([]);

  const {
    handleSubmit,
    control,
    watch,
    setError,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<IBuyerBreedsForm>({
    resolver: yupResolver(buyerBreedsSchema),
    defaultValues: {
      breedsInProgram: buyerRegistrationInfo?.breeds?.map(breed => ({
        label: breed,
        value: breed,
      })),
    },
  });

  //Query hooks
  const { breeds, isLoadingBreeds } = useQueryBreedsWithSizeVariation();
  const { breedsDropdown: breedsWithId, isFetchingBreedsDropdown } = useQueryBreedsDropdown();

  // Breeds dropdown for different app states
  const breedsDropdown = useMemo(
    () =>
      breeds?.map((breed: { breedName: string }) => ({
        label: breed.breedName,
        value: breed.breedName,
      })),
    [breeds],
  );

  useEffect(() => {
    const breedsInProgramNames = watch('breedsInProgram').map(breed => breed.value);
    const filteredSimilarBreeds = filter(similarBreeds, breed => {
      return !includes(breedsInProgramNames, breed.breedName);
    });

    if (!isEqual(similarBreeds, filteredSimilarBreeds)) {
      setSimilarBreeds(filteredSimilarBreeds);
    }
  }, [similarBreeds, watch]);

  const onSubmit = (data: IBuyerBreedsForm) => {
    pushToDataLayer({
      event: 'buyerBreedsInProgramClick',
      componentName: 'Buyer breeds in program',
      ...data,
    });
    setBuyerRegistrationInfo(
      'breeds',
      data.breedsInProgram.map(breed => breed.value),
    );

    setStep(step + 1);
    window.scrollTo({ top: 0 });
  };

  if (isLoadingBreeds || isFetchingBreedsDropdown)
    return (
      <div className="align_center">
        <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
      </div>
    );
  else
    return (
      <div className="about-program-container">
        <h2>What kind of breed are you looking for?</h2>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          id="buyerRegistrationBreedsForm"
          className="about-program-container__form"
        >
          <Col xs={12} className={`${errors?.breedsInProgram ? 'mb-0 input-error' : 'mb-4'}`}>
            <Form.Label className="form-labels">Please select up to 5 breeds.*</Form.Label>
            <Controller
              control={control}
              name="breedsInProgram"
              render={({ field: { onChange, value } }) => (
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  isSearchable
                  value={value}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(val: any, actionMeta: any) => {
                    if (
                      watch('breedsInProgram') &&
                      watch('breedsInProgram').length >= 5 &&
                      actionMeta.action === 'select-option'
                    ) {
                      setError('breedsInProgram', {
                        type: 'custom',
                        message: ' The maximum number of breeds you can select is 5',
                      });
                    } else if (actionMeta.action === 'select-option') {
                      trigger('breedsInProgram');

                      return onChange(val);
                    } else if (
                      actionMeta.action === 'remove-value' &&
                      watch('breedsInProgram').length <= 1
                    ) {
                      setError('breedsInProgram', {
                        type: 'custom',
                        message: 'You must select at least one breed',
                      });
                    } else if (actionMeta.action === 'remove-value') {
                      trigger('breedsInProgram');

                      return onChange(val);
                    }
                  }}
                  options={breedsDropdown}
                  placeholder="Select breeds"
                  styles={multiSelectStyles}
                  classNamePrefix="tt-select"
                />
              )}
            />
            {errors?.breedsInProgram && (
              <p className="text-error mb-2">{errors.breedsInProgram?.message}</p>
            )}
          </Col>
        </Form>

        <div className="buyer-breeds-container">
          {watch('breedsInProgram')?.map((breed, index) => (
            <BreedInfo
              allBreeds={watch('breedsInProgram')}
              key={index}
              breedName={breed.label}
              breedId={find(breedsWithId, { label: breed.label })?.value}
              withSimilarBreeds={index === watch('breedsInProgram').length - 1}
              similarBreeds={similarBreeds}
              setSimilarBreeds={setSimilarBreeds}
              removeBreed={(breedValue: string) =>
                setValue(
                  'breedsInProgram',
                  filter(watch('breedsInProgram'), item => item.value !== breedValue),
                )
              }
              addBreed={(breedValue: string) =>
                setValue(
                  'breedsInProgram',
                  concat(watch('breedsInProgram'), { value: breedValue, label: breedValue }),
                )
              }
            />
          ))}
        </div>

        <div className="form-button-wrapper">
          <TTButton
            height="50px"
            type="submit"
            className="btn-primary-orchid"
            text="Continue"
            form="buyerRegistrationBreedsForm"
            disabled={isLoadingBreeds || isEmpty(watch('breedsInProgram'))}
          />
        </div>
      </div>
    );
}
