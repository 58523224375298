import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { deleteDeal, undeleteDeal } from 'services/api/apiCalls/admin';
//types
import { TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useMutationDeleteDeal = (setDialogContent: (dialogContent: null) => void) => {
  const queryClient = useQueryClient();

  const { mutate: deleteDealMutate, isLoading: isDeleting } = useMutation<
    AxiosResponse<string>,
    AxiosError<TError>,
    string
  >(dealId => deleteDeal(dealId), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.ADMIN_DEAL_LIST, {
        refetchInactive: true,
      });
      setDialogContent(null);
    },
  });

  return {
    deleteDealMutate,
    isDeleting,
  };
};

export const useMutationUndeleteDeal = (setDialogContent: (dialogContent: null) => void) => {
  const queryClient = useQueryClient();

  const { mutate: undeleteDealMutate, isLoading: isUndeleting } = useMutation<
    AxiosResponse<string>,
    AxiosError<TError>,
    string
  >(dealId => undeleteDeal(dealId), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.ADMIN_DEAL_LIST, {
        refetchInactive: true,
      });
      setDialogContent(null);
    },
  });

  return {
    undeleteDealMutate,
    isUndeleting,
  };
};
