import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { isEmpty, map } from 'lodash';
import { useQueryClient } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import 'bootstrap/dist/css/bootstrap.min.css';

//components
import TTButton from 'components/common/TTButton';
import KitImg from 'components/common/KitImg';
//types
import { Matches, IUserInfo } from 'types';
//constant
import { QUERY_KEYS } from 'constants/queryKeys';
import { mockMatchesForNonActiveBuyer } from 'constants/mockMatchesForNonActiveBuyer';
//helpers
import { formatAddressForBreederLeads, formatUSPhoneNumber } from 'helpers/string';
//assets
import defaultProfileImg from 'assets/images/breederProfile/defaultLogo.svg';
import chat from 'assets/images/breederProfile/leadsChat.svg';
import email from 'assets/images/breederProfile/leadsEmail.svg';
import call from 'assets/images/breederProfile/leadsCall.svg';
import copy from 'assets/images/breederProfile/leadsCopy.svg';
import lock from 'assets/images/breederProfile/lock.svg';

const MockMatchCard = ({ match, onLockClick }: { match: Matches; onLockClick: () => void }) => (
  <div className="leads-card">
    <div className="leads-card__body">
      <div className="leads-card__heading">
        <div className="leads-card__heading-left">
          <KitImg
            alt="buyer-profile-img"
            src={match.breederProfileImage || defaultProfileImg}
            kitwidth={40}
            imgClassName="leads-card__img"
          />
          <div className="d-flex flex-column align-items-start">
            <p className="leads-card__title">{match.breederProgramName}</p>
            <p className="leads-card__subtitle leads-card__subtitle--navy-50">
              {`${match.breederCity}, ${match.breederState}`}
            </p>
          </div>
        </div>
        <div className="lock-container" onClick={onLockClick}>
          <img src={lock} alt="lock" />
        </div>
      </div>

      <div className="leads-card__content">
        <div className="leads-card__field">
          <div className="d-flex flex-column w-100">
            <p className="leads-card__title">Breed</p>
            <p className="leads-card__description">{match.desiredBreeds[0]}</p>
          </div>
        </div>

        <div className="leads-card__field">
          <div className="d-flex flex-column w-100">
            <p className="leads-card__title">Name</p>
            <p className="leads-card__description">{match.breederName}</p>
          </div>
        </div>
        <div className="leads-card__field">
          <div className="d-flex flex-column">
            <p className="leads-card__title">Email</p>
            <p className="leads-card__description">{match.breederEmail}</p>
          </div>
        </div>

        <div className="leads-card__field">
          <div className="d-flex flex-column">
            <p className="leads-card__title">Phone</p>
            <p className="leads-card__description">{match.breederPhone}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const MyMatches = ({
  matches,
  hadTriggerMatchOption,
  triggeredMatching,
}: {
  matches: Matches[];
  hadTriggerMatchOption: boolean;
  triggeredMatching: boolean;
}) => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();

  const userInfo = queryClient.getQueryData(QUERY_KEYS.USER_STATUS) as {
    data: IUserInfo | undefined;
  };
  const isInReview = userInfo?.data?.subscriptionStatus === 'in-review';

  return (
    <>
      {isInReview && (
        <div className="unlock-matches">
          <h6>Get ready for leads.</h6>
          <p>
            Get ready for leads. Only TellTail Pro members can reach out to their buyer leads. We'll
            show you a preview of your leads when available, but upgrading now will give you
            immediate access to connect and chat with your buyers!
          </p>

          <TTButton
            type="submit"
            className="btn-primary-orchid"
            text="Upgrade"
            width="125px"
            height="40px"
            fontSize="12px"
            borderRadius="10px"
            onClick={() => navigate('/get-certified')}
          />
        </div>
      )}

      {isInReview && (
        <div className="unlock-matches mb-4">
          <h6>Account Approval Pending</h6>
          <p>
            Once approved, your leads will be available. In most cases, this will be completed
            within <b>1-2 business days</b>. In the meantime, check out our{' '}
            <a onClick={() => navigate('/deals')}>Deals</a> and{' '}
            <a onClick={() => navigate('/learn')}>Premium Resources</a> available exclusively to
            TellTail Pro members!
          </p>
        </div>
      )}

      {isEmpty(matches) ? (
        <>
          {hadTriggerMatchOption && !triggeredMatching ? (
            <div className="cards-container">
              {map(mockMatchesForNonActiveBuyer, (match, key) => (
                <MockMatchCard
                  key={key}
                  match={match}
                  onLockClick={() => navigate('/activate-profile')}
                />
              ))}
            </div>
          ) : (
            <div className="unlock-matches">
              <h6>Hang on tight</h6>
              <p>
                We’re searching for breeders who fit your criteria and will notify you as soon as
                you get a match.
              </p>
            </div>
          )}
        </>
      ) : (
        <div className="cards-container">
          {map(matches, (match, key) => (
            <div className="leads-card" key={key}>
              <div className="leads-card__info-banner">
                {match.isNew && <p className="leads-card__info-new-text">NEW!</p>}
                <p className="leads-card__subtitle">
                  Matched:{' '}
                  {match.matchDate
                    ? moment(match.matchDate).format('MMMM DD, YYYY')
                    : moment().format('MMMM DD, YYYY')}
                </p>
              </div>

              <div className="leads-card__body">
                <div className="leads-card__heading">
                  <div
                    className="leads-card__heading-left"
                    onClick={() => navigate(`/public-profile/${match.breederId}`)}
                  >
                    <KitImg
                      alt="buyer-profile-img"
                      src={match.breederProfileImage || defaultProfileImg}
                      kitwidth={40}
                      imgClassName="leads-card__img"
                    />
                    <div className="d-flex flex-column align-items-start">
                      <p className="leads-card__title">{match.breederProgramName}</p>
                      <p className="leads-card__subtitle leads-card__subtitle--navy-50">
                        {formatAddressForBreederLeads(
                          match.breederCity,
                          match.breederState,
                          match.breederCountry,
                          match.zipCode,
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="leads-card__content">
                  <div className="leads-card__field">
                    <div className="d-flex flex-column w-100">
                      <p className="leads-card__title">Breed</p>
                      <p className="leads-card__description">{match.desiredBreeds[0]}</p>
                    </div>
                  </div>
                  <div className="leads-card__field">
                    <div className="d-flex flex-column">
                      <p className="leads-card__title">Expected Go Home</p>
                      <p className="leads-card__description">
                        {match.goHomeDate
                          ? moment(match.goHomeDate).format('MMMM DD, YYYY')
                          : moment().format('MMMM DD, YYYY')}
                      </p>
                    </div>
                  </div>
                  <div className="leads-card__field">
                    <div className="d-flex flex-column w-100">
                      <p className="leads-card__title">Name</p>
                      <p className="leads-card__description">{match.breederName}</p>
                    </div>
                  </div>
                  <div className="leads-card__field">
                    <div className="d-flex flex-column">
                      <p className="leads-card__title">Email</p>
                      <p className="leads-card__description">{match.breederEmail}</p>
                    </div>

                    <div className="d-flex justify-content-end align-items-center gap-2">
                      <a
                        href={`mailto:${
                          match.breederEmail
                        }?cc=matches-measurement@telltail.com&subject=I'm interested in one of your ${
                          match.desiredBreeds[0] || ''
                        } puppies!`}
                      >
                        <img alt="email-logo" src={email} className="leads-card__icon" />
                      </a>
                      <img
                        alt="copy-logo"
                        src={copy}
                        className="leads-card__icon"
                        onClick={() => {
                          navigator.clipboard.writeText(match.breederEmail);
                          addToast('Email copied to clipboard.', {
                            appearance: 'success',
                            autoDismiss: true,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="leads-card__field">
                    <div className="d-flex flex-column">
                      <p className="leads-card__title">Phone</p>
                      <p className="leads-card__description">
                        {formatUSPhoneNumber(match.breederPhone)}
                      </p>
                    </div>
                    {!isEmpty(match.breederPhone) && (
                      <div className="d-flex justify-content-end align-items-center gap-2">
                        <a href={`tel:${match.breederPhone}`}>
                          <img alt="call-logo" src={call} className="leads-card__icon" />
                        </a>
                        <a href={`sms:${match.breederPhone}`}>
                          <img alt="chat-logo" src={chat} className="leads-card__icon" />
                        </a>
                        <img
                          alt="copy-logo"
                          src={copy}
                          className="leads-card__icon"
                          onClick={() => {
                            navigator.clipboard.writeText(match.breederPhone);
                            addToast('Phone number copied to clipboard.', {
                              appearance: 'success',
                              autoDismiss: true,
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>

                  <div className="leads-card__field leads-card__field--last">
                    <div className="d-flex flex-column w-100">
                      <p className="leads-card__title">Price</p>
                      <p className="leads-card__description">
                        ${match.minPrice} - ${match.maxPrice}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
export default MyMatches;
