export const sliderStyle = {
  width: '95%',
  margin: 'auto',
  color: '#CC7191',
  '& .MuiSlider-thumb': {
    color: 'white',
  },
  '& .MuiSlider-rail': {
    color: '#3C3C4350',
  },
  '& .MuiSlider-valueLabel': {
    borderRadius: 1,
    background: '#CC7191',
  },
};
