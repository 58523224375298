import React, { useContext } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

//hooks
import useMutationResendVerificationEmail from 'pages/AdminPanel/ConfirmationEmail/__hooks/useMutationResendVerificationEmail';
//types
import { TAuthContext } from 'types';
//services
import { AuthState } from 'services/auth';
//assets
import emailImg from 'assets/images/registrationAndSignIn/email.svg';
//styles
import './index.scss';

const ResendVerificationEmail = () => {
  const {
    state: { email },
  } = useContext(AuthState) as TAuthContext;

  //Mutation hooks
  const { mutate: resendConfirmationEmail, isLoading } = useMutationResendVerificationEmail();

  return (
    <div className="resend-email-container">
      <h2 className="centered">Your email is not verified!</h2>
      <img src={emailImg} alt="calendarImmediately" />
      <p className="text">
        Email may take 1-2 minutes to come through. Please make sure to check your spam or junk
        folder.
      </p>
      <p className="text">
        If after 1-2 minutes and checking spam or junk folder, you still do not see the email,{' '}
        <span
          className={`text__link ${isLoading && 'text--disabled'}`}
          onClick={() => {
            resendConfirmationEmail({ emailAddress: email });
          }}
        >
          {isLoading ? (
            <AiOutlineLoading3Quarters
              size={20}
              color="#cc7191"
              className="loading-animation svg-loading"
            />
          ) : (
            'resend email.'
          )}
        </span>
      </p>
    </div>
  );
};

export default ResendVerificationEmail;
