/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import find from 'lodash/find';

//services
import { uploadImage } from 'services/api/apiCalls/cloudflare';
//types
import { ICloudflareUpload, TError } from 'types';

const useMutationUploadFile = () => {
  const {
    mutate: uploadFileMutate,
    isLoading: isUploadingFile,
    isSuccess: isSuccessFile,
  } = useMutation<AxiosResponse<any>, AxiosError<TError>, ICloudflareUpload>(
    data => uploadImage(data),
    {
      onSuccess: (res, variable) => {
        const imageUrl = find(res?.data?.result?.variants, function (item) {
          return item.includes('/public');
        });

        const response = {
          result: [
            {
              url: imageUrl,
              name: res?.data?.result?.filename,
              size: (variable.image as File).size,
            },
          ],
        };

        return variable.uploadHandler(response);
      },
    },
  );

  return {
    uploadFileMutate,
    isUploadingFile,
    isSuccessFile,
  };
};

export default useMutationUploadFile;
