import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { getAdminPawfficeHourList } from 'services/api/apiCalls/admin';
//types
import { IAdminPawfficeHoursResponse, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useQueryAdminPawfficeHourList = (status: string) => {
  const {
    isError,
    data,
    isLoading,
    refetch: refreshPawfficeHourList,
    isFetching,
  } = useQuery<AxiosResponse<IAdminPawfficeHoursResponse>, AxiosError<TError>>(
    [QUERY_KEYS.ADMIN_PAWFFICE_HOURS_LIST, status],
    () => getAdminPawfficeHourList(status, { page: 1, limit: 100000 }),
    {
      keepPreviousData: true,
    },
  );

  return {
    isError,
    data: data?.data?.items.sort(function (a, b) {
      return new Date(b.startDate).valueOf() - new Date(a.startDate).valueOf();
    }),
    total: Number(data?.data?.meta.itemsCount),
    isLoading,
    refreshPawfficeHourList,
    isFetching,
  };
};

export default useQueryAdminPawfficeHourList;
