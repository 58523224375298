/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

//styles
import '../index.scss';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';
//services
import { AuthState } from 'services/auth';
//types
import { TStepper, IUserInfo, IBreeder, TAuthContext } from 'types';
//hooks
import { useMutationIsVouched, useMutationSetVouchFlagged } from '../__hooks/useMutationIsVouched';

const PersonaVerify = ({ step, setStep }: TStepper) => {
  const queryClient = useQueryClient();
  const {
    state: { registrationInfo },
  } = useContext(AuthState) as TAuthContext;
  const [isReady, setIsReady] = useState(false);

  const userInfo = queryClient.getQueryData(QUERY_KEYS.USER_STATUS) as { data: IUserInfo };
  const breederInfo = queryClient.getQueryData([QUERY_KEYS.BREEDER, userInfo?.data?.breederId]) as {
    data: IBreeder;
  };

  //Mutation hooks
  const { mutate: setIsVouched } = useMutationIsVouched(step, setStep);
  const { mutate: setVouchedFlagged } = useMutationSetVouchFlagged();

  useEffect(() => {
    // Function to dynamically load the Persona script
    const loadPersonaScript = (src: string, callback: () => void) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => callback();
      document.body.appendChild(script);
    };

    // Callback function to initialize the Persona client
    const initializePersona = () => {
      const client = new window.Persona.Client({
        fields: {
          nameFirst: registrationInfo?.vouchedLegalNameForm?.firstName || '',
          nameLast: registrationInfo?.vouchedLegalNameForm?.lastName || '',
        },
        templateId: 'itmpl_5ctMstZ2oxGEjuzAtUSyvivA7kTG',
        environmentId: process.env.REACT_APP_PERSONA_ENV,
        onReady: () => {
          setIsReady(true);
          client.open();
        },
        onComplete: ({
          inquiryId,
          status,
          fields,
        }: {
          inquiryId: string;
          status: string;
          fields: any;
        }) => {
          setIsVouched({ vouchedSessionId: inquiryId });
          client.destroy();
        },
      });
    };

    // Load the Persona script and initialize the client
    loadPersonaScript('https://cdn.withpersona.com/dist/persona-v4.11.0.js', initializePersona);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="about-program-container">
      <div className="d-flex flex-column">
        <h2>Complete your ID verification</h2>
        <h5 className="with-margin">
          We partner with Persona to confirm your identity for TellTail Match. This is an important
          step to eliminate scammers. Please know that your privacy is our priority.{' '}
        </h5>
      </div>

      {!isReady && (
        <div className="align_center">
          <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
        </div>
      )}
    </div>
  );
};

export default PersonaVerify;
