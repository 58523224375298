import React from 'react';
import { isEmpty } from 'lodash';
import { Col } from 'react-bootstrap';

//types
import { IBreederPublic } from 'types';

const AboutMe = ({
  isUserProfile,
  breederInfo,
}: {
  isUserProfile: boolean;
  breederInfo: IBreederPublic;
}) => {
  return (
    <div id="about" className="breederProfile__aboutMe">
      <div className="breederProfile__aboutMeContainer">
        <Col xs={12} md={12} className="break-word">
          <h5>About {isUserProfile ? 'Me' : `${breederInfo.businessProgram}`}</h5>
          <p>{breederInfo.breederProgramDescription || 'N/A'}</p>
        </Col>
        <div>
          {/* Breeds */}
          <Col>
            <h6>Breeds</h6>
            {!isEmpty(breederInfo.breeds) ? (
              <ul className="breederProfile__tags clubs">
                {breederInfo.breeds.map(
                  (program, idx) => program !== 'Other' && <li key={idx}>{program}</li>,
                )}
              </ul>
            ) : (
              <p>N/A</p>
            )}
          </Col>
          {/* Breeds */}
        </div>
      </div>
    </div>
  );
};
export default AboutMe;
