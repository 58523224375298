import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { getArticle } from 'services/api/apiCalls/articles';
//types
import { IArticle, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useQueryArticleDetails = (id: string) => {
  const { isLoading, isError, isFetching, error, data } = useQuery<
    AxiosResponse<IArticle>,
    AxiosError<TError>
  >([QUERY_KEYS.ARTICLE_DETAILS, id], () => getArticle(id));

  return {
    isFetching,
    isLoading,
    isError,
    error,
    data: data?.data,
  };
};
export default useQueryArticleDetails;
