import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { createVideo } from 'services/api/apiCalls/admin';
//types
import { IAddNewVideoPayload, TError } from 'types';

const useMutationAddNewVideo = (handleDialogDismiss: () => void, scrollToBottom: () => void) => {
  const { addToast } = useToasts();

  const {
    mutate: addNewVideoMutate,
    isLoading: addNewVideoIsLoading,
    error: videoCreationError,
    reset: resetApiState,
  } = useMutation<AxiosResponse<string>, AxiosError<TError>, IAddNewVideoPayload>(
    data => createVideo(data),
    {
      onSuccess: () => {
        addToast('Video added successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
        handleDialogDismiss();
      },
      onError: () => {
        scrollToBottom();
      },
    },
  );

  return {
    addNewVideoMutate,
    addNewVideoIsLoading,
    videoCreationError,
    resetApiState,
  };
};

export default useMutationAddNewVideo;
