import React, { Fragment, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import { isEmpty } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

//types
import { IAddNoteSchema, IDialogProps, INote } from 'types';
//hooks
import useMutationAddNote from '../__hooks/useMutationAddNote';
import useQueryGetNoteList from '../__hooks/useQueryGetNoteList';
//components
import TabPanel from 'components/common/TabPanel';
//constants
import { adminNoteSubTabs } from 'constants/tabs';
import { QUERY_KEYS } from 'constants/queryKeys';
//validation
import { AddNewNoteSchema } from './Validation';
//styles
import './index.scss';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NoteList = ({
  userId,
  filter,
  isBuyer,
}: {
  userId: string;
  filter: string;
  isBuyer: boolean;
}) => {
  const { ref, inView } = useInView();

  //Query hooks
  const { isLoading, data, error, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useQueryGetNoteList(userId, filter, isBuyer);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  if (isLoading)
    return (
      <div className="d-flex width-full justify-content-center my-3">
        <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
      </div>
    );

  if (error)
    return (
      <div className="d-flex width-full justify-content-center my-2">
        <>{error && <p className="text-error">Something went wrong</p>}</>
      </div>
    );

  return (
    <div key={filter} style={{ maxHeight: '50vh', overflow: 'auto' }}>
      <ul>
        {data?.pages?.map((page, index) => (
          <Fragment key={index}>
            {isEmpty(page.items) ? (
              <div className="d-flex width-full justify-content-center mt-3">
                <p>No note found.</p>
              </div>
            ) : (
              page.items.map((note: INote, idx: number) => (
                <li key={note.noteDate + idx} style={{ margin: '8px 0px' }}>
                  <strong>
                    [{note.admin.adminFullName}, {moment(note.noteDate).format('LLL')}]
                  </strong>{' '}
                  {note.note}
                </li>
              ))
            )}
          </Fragment>
        ))}
      </ul>
      <div className="d-flex justify-content-center">
        <button
          ref={ref}
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
          className="btn-loading"
        >
          {isFetchingNextPage ? 'Loading more...' : hasNextPage ? 'Load Newer' : ''}
        </button>
      </div>
    </div>
  );
};

const NotesDialog = ({ open, handleClose, userId, displayName, isBuyer }: IDialogProps) => {
  const queryClient = useQueryClient();

  const [active, setActive] = useState(adminNoteSubTabs[0]);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm<IAddNoteSchema>({
    resolver: yupResolver(AddNewNoteSchema),
  });

  //Mutation hooks
  const { addNoteMutate, isAdding } = useMutationAddNote(setError, reset, isBuyer || false);

  const getButtonText = () =>
    active.value === 'general' ? 'Add General Note' : 'Add Customer Service Note';

  const onSubmit = (payload: IAddNoteSchema) => {
    addNoteMutate({
      payload: { ...payload, type: active.value },
      userId: userId!,
    });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
    >
      <DialogTitle>Notes for {displayName}</DialogTitle>
      <DialogContent sx={{ overflow: 'hidden' }}>
        <TabPanel tabsList={adminNoteSubTabs} current={active.value} setActive={setActive} />
        <Box display="flex" flexDirection="column" width="35vw" mt="-1rem">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Box
              className="notes_dialog"
              display="flex"
              flexDirection="column"
              bgcolor="#f5f5f5"
              p={2}
              borderRadius={2}
            >
              <TextField
                sx={{ width: '100%' }}
                inputProps={{ className: 'textfield' }}
                color={errors.note?.message ? 'error' : 'primary'}
                label={active.value === 'general' ? 'General Note' : 'Customer Service Note'}
                {...register('note')}
              />

              {errors && errors.note && (
                <span className="text-error mt-2">{errors.note.message}</span>
              )}

              <Button
                type="submit"
                variant="contained"
                disabled={isAdding}
                onClick={handleSubmit(onSubmit)}
                sx={{ alignSelf: 'flex-end', mt: 2 }}
              >
                {isAdding ? (
                  <AiOutlineLoading3Quarters
                    size={20}
                    color="blue"
                    style={{ margin: '0.2rem' }}
                    className="loading-animation svg-loading"
                  />
                ) : (
                  getButtonText()
                )}
              </Button>
            </Box>
          </Form>
          <Box mt={2}>
            <Typography variant="h5" mb={2}>
              {active.value === 'general' ? 'General Note' : 'Customer Service Note'}:
            </Typography>
            <NoteList userId={userId!} filter={active.value} isBuyer={isBuyer || false} />
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            queryClient.removeQueries(QUERY_KEYS.ADMIN_BREEDER_NOTE_LIST);
            queryClient.removeQueries(QUERY_KEYS.ADMIN_BUYER_NOTE_LIST);
            handleClose();
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotesDialog;
