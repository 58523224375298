import React from 'react';
import { ToastProvider } from 'react-toast-notifications';

//services
import { DataLayerProvider } from 'services/dataLayerContext';
import BreedoPediaFiltersProvider from 'services/breedopediaFilters';
//styles
import './App.scss';
//hooks
import usePageTracking from 'hooks/usePageTracking';
//routes
import MainRoutes from 'routes/Main';

function App() {
  usePageTracking();

  return (
    <ToastProvider autoDismissTimeout={5000} transitionDuration={220}>
      <DataLayerProvider>
        <BreedoPediaFiltersProvider>
          <MainRoutes />
        </BreedoPediaFiltersProvider>
      </DataLayerProvider>
    </ToastProvider>
  );
}

export default App;
