import React, { useState, useEffect, Fragment } from 'react';
import { useInView } from 'react-intersection-observer';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { map, isEmpty } from 'lodash';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useToasts } from 'react-toast-notifications';

//components
import TTButton from 'components/common/TTButton';
import KitImg from 'components/common/KitImg';
//hooks
import { useQueryMatches } from './__hooks/useQueryMatches';
import {
  useMutationArchiveMatch,
  useMutationUnarchiveMatch,
} from './__hooks/useMutationArchiveUnarchiveMatch';
//helpers
import { formatAddressForBreederLeads } from 'helpers/string';
//assets
import defaultProfileImg from 'assets/images/breederProfile/defaultLogo.svg';
import chat from 'assets/images/breederProfile/leadsChat.svg';
import email from 'assets/images/breederProfile/leadsEmail.svg';
import call from 'assets/images/breederProfile/leadsCall.svg';
import copy from 'assets/images/breederProfile/leadsCopy.svg';
import locked from 'assets/images/breederProfile/leadsLocked.svg';
import noLeads from 'assets/images/breederProfile/noLeads.svg';
import scoreInfo from 'assets/images/breederProfile/scoreInfo.svg';

const MyLeads = ({ isSfcBreeder, isInReview }: { isSfcBreeder: boolean; isInReview: boolean }) => {
  const { ref, inView } = useInView();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [infoOpen, setInfoOpen] = useState<{ [key: string]: boolean }>({});
  const [leadsSubtab, setLeadsSubtab] = useState<'active' | 'archived'>('active');
  const [cardArchiveClicked, setCardArchiveClicked] = useState<{ [key: string]: boolean }>({});

  const getUserFriendlyMatchDate = (matchDate: string | undefined) => {
    if (matchDate === undefined) return 'Not sure yet';
    else {
      switch (matchDate) {
        case 'immediate':
          return 'Immediately';
        case 'short':
          return 'In 1-3 months';
        case 'unknown':
          return 'Not sure yet';

        default:
          return 'Not sure yet';
      }
    }
  };

  //Query hooks
  const {
    total: activeTotal,
    matches,
    isLoading,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useQueryMatches(false, setLeadsSubtab);
  const {
    total: archivedTotal,
    matches: matchesArchived,
    isLoading: isLoadingArchived,
    isFetching: isFetchingArchived,
    isFetchingNextPage: isFetchingNextPageArchived,
    fetchNextPage: fetchNextPageArchived,
    hasNextPage: hasNextPageArchived,
  } = useQueryMatches(true, setLeadsSubtab);
  //Mutation hooks
  const { mutate: archiveBreederMatch, isLoading: isArchiving } = useMutationArchiveMatch();
  const { mutate: unarchiveBreederMatch, isLoading: isUnarchiving } = useMutationUnarchiveMatch();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  useEffect(() => {
    if (inView && leadsSubtab === 'active' && !isFetching && !isFetchingNextPage) {
      fetchNextPage();
    } else if (
      inView &&
      leadsSubtab === 'archived' &&
      !isFetchingArchived &&
      !isFetchingNextPageArchived
    ) {
      fetchNextPageArchived();
    }
  }, [
    inView,
    leadsSubtab,
    fetchNextPage,
    fetchNextPageArchived,
    isFetching,
    isFetchingArchived,
    isFetchingNextPage,
    isFetchingNextPageArchived,
  ]);

  if (
    (leadsSubtab === 'active' && (isLoading || isArchiving)) ||
    (leadsSubtab === 'archived' && (isLoadingArchived || isUnarchiving))
  ) {
    return (
      <div className="w-100 d-flex justify-content-center">
        <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
      </div>
    );
  } else {
    const isPremiumBreederWithLeads =
      isSfcBreeder && !isInReview && (activeTotal > 0 || archivedTotal > 0);
    const isPremiumBreederWithoutLeads =
      isSfcBreeder && !isInReview && activeTotal <= 0 && archivedTotal <= 0;

    const activeTabData = leadsSubtab === 'active' ? matches : matchesArchived;

    return (
      <>
        {isInReview && (
          <div className="unlock-matches mb-4">
            <h6>Account Approval Pending</h6>
            <p>
              Once approved, your leads will be available. In most cases, this will be completed
              within <b>1-2 business days</b>. In the meantime, check out our{' '}
              <a onClick={() => navigate('/deals')}>Deals</a> and{' '}
              <a onClick={() => navigate('/learn')}>Premium Resources</a> available exclusively to
              TellTail Pro members!
            </p>
          </div>
        )}

        {isPremiumBreederWithoutLeads && (
          <div className="unlock-matches">
            <h6>You currently have no leads.</h6>
            <p>
              While you wait for the leads to pour in, please review this guide on how to optimize
              your profile or check out our <a onClick={() => navigate('/deals')}>Deals</a> and{' '}
              <a onClick={() => navigate('/learn')}>Premium Resources</a> available exclusively to
              TellTail Pro members!
            </p>
            <img alt="no-leads" src={noLeads} className="no-leads-img" />
          </div>
        )}

        {(activeTotal > 0 || archivedTotal > 0) && (
          <>
            <div className={`leads-subtabs ${isInReview && 'leads-subtabs--in-review'}`}>
              <p
                className={`leads-subtab ${leadsSubtab === 'active' && 'leads-subtab--active'} ${
                  (activeTotal === 0 || isUnarchiving) && 'leads-subtab--disabled'
                }`}
                onClick={() => activeTotal > 0 && setLeadsSubtab('active')}
              >
                Active ({activeTotal})
              </p>
              <p
                className={`leads-subtab ${leadsSubtab === 'archived' && 'leads-subtab--active'} ${
                  (archivedTotal === 0 || isArchiving) && 'leads-subtab--disabled'
                }`}
                onClick={() => archivedTotal > 0 && setLeadsSubtab('archived')}
              >
                Archived ({archivedTotal})
              </p>
            </div>
            <div className="cards-container">
              {map(activeTabData?.pages, (page, index) => (
                <Fragment key={index}>
                  {map(page.items, match => (
                    <div
                      className={`leads-card ${
                        cardArchiveClicked[match.id] && 'leads-card--disabled'
                      }`}
                      key={match.id}
                    >
                      <div
                        className={`leads-card__blur-overlay ${
                          cardArchiveClicked[match.id] && 'leads-card__blur-overlay--active'
                        }`}
                      />

                      <div className="leads-card__info-banner">
                        <div className="d-flex gap-1">
                          <p className="leads-card__info-new-text">
                            {match.isNew ? 'New match!' : 'Matched'}
                          </p>
                          <p className="leads-card__subtitle">
                            {moment(match.matchDate).format('MMMM DD, YYYY')}
                          </p>
                        </div>

                        <p
                          className="leads-card__info-archive"
                          onClick={() => {
                            if (leadsSubtab === 'active') {
                              setCardArchiveClicked({ ...cardArchiveClicked, [match.id]: true });
                            } else {
                              unarchiveBreederMatch({
                                leadId: match.id,
                                archived: false,
                              });
                            }
                          }}
                        >
                          {leadsSubtab === 'active' ? 'Archive Lead' : 'Restore lead'}
                        </p>
                      </div>

                      {!isEmpty(match.leadGrade) && (
                        <div
                          className={`leads-card__score leads-card__score--${match.leadGrade.toLowerCase()}`}
                        >
                          <p>
                            Compatibility score:{' '}
                            <span className={match.leadGrade.toLowerCase()}>{match.leadGrade}</span>{' '}
                            <img
                              alt="score-info"
                              src={scoreInfo}
                              onClick={() => {
                                if (!infoOpen[match.id]) {
                                  setInfoOpen({ ...infoOpen, [match.id]: true });
                                }
                                setInfoOpen({ ...infoOpen, [match.id]: !infoOpen[match.id] });
                              }}
                            />
                          </p>
                          {match.leadGrade === 'Great' && infoOpen[match.id] && (
                            <p className="leads-card__score-description">
                              This lead aligns exceptionally well with what you're offering,
                              suggesting a high potential for a successful match.
                            </p>
                          )}
                          {match.leadGrade === 'Good' && infoOpen[match.id] && (
                            <p className="leads-card__score-description">
                              This lead has a decent alignment with your requirements, indicating a
                              good chance for compatibility.
                            </p>
                          )}
                          {match.leadGrade === 'Ok' && infoOpen[match.id] && (
                            <p className="leads-card__score-description">
                              This lead shows some alignment with your offerings, but may require
                              more consideration to determine fit.
                            </p>
                          )}
                        </div>
                      )}

                      <div className="leads-card__body">
                        <div className="leads-card__heading">
                          <div className="leads-card__heading-left">
                            <KitImg
                              alt="buyer-profile-img"
                              src={match.buyerProfileImage || defaultProfileImg}
                              kitwidth={40}
                              imgClassName="leads-card__img"
                            />
                            <div className="d-flex flex-column align-items-start">
                              <p className="leads-card__title">{match.buyerName}</p>
                              <p className="leads-card__subtitle leads-card__subtitle--navy-50">
                                {formatAddressForBreederLeads(
                                  match.city,
                                  match.state,
                                  match.country,
                                  match.zipCode,
                                )}
                              </p>
                            </div>
                          </div>

                          {!isSfcBreeder && (
                            <img
                              alt="locked-logo"
                              src={locked}
                              className="leads-card__icon"
                              onClick={() => navigate('/get-certified')}
                            />
                          )}
                        </div>

                        <div className="leads-card__content">
                          <div className="leads-card__field">
                            <div className="d-flex flex-column w-100">
                              <p className="leads-card__title">
                                {match.desiredBreeds.length > 1
                                  ? 'Desired breeds'
                                  : 'Desired breed'}
                              </p>
                              <p className="leads-card__description">
                                {match.desiredBreeds.length > 1
                                  ? map(match.desiredBreeds, (desiredBreed, key2) => {
                                      return Number(key2) === match.desiredBreeds.length - 1
                                        ? desiredBreed
                                        : `${desiredBreed}, `;
                                    })
                                  : match.breedName}
                              </p>
                            </div>
                          </div>
                          <div className="leads-card__field">
                            <div className="d-flex flex-column">
                              <p className="leads-card__title">Desired Go Home</p>
                              <p className="leads-card__description">
                                {getUserFriendlyMatchDate(match.howSoon)}
                              </p>
                            </div>
                          </div>
                          <div className="leads-card__field">
                            <div className="d-flex flex-column">
                              <p className="leads-card__title">Email</p>
                              <p className="leads-card__description">{match.email}</p>
                            </div>
                            {isPremiumBreederWithLeads && (
                              <div className="d-flex justify-content-end align-items-center gap-2">
                                <a
                                  href={`mailto:${match.email}?cc=matches-measurement@telltail.com&subject=I am a certified ${match.breedName} breeder`}
                                >
                                  <img alt="email-logo" src={email} className="leads-card__icon" />
                                </a>
                                <img
                                  alt="copy-logo"
                                  src={copy}
                                  className="leads-card__icon"
                                  onClick={() => {
                                    navigator.clipboard.writeText(match.email);
                                    addToast('Email copied to clipboard.', {
                                      appearance: 'success',
                                      autoDismiss: true,
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          {/* We want to show "Unverified" status for the users before sms verification feature was enabled. */}
                          {/* We also only want to show phone field only for users that verified their phone while feature was enabled. */}
                          {(!match.isSmsVerificationEnabled ||
                            (match.isSmsVerificationEnabled && match.phoneVerified)) && (
                            <div className="leads-card__field leads-card__field--last">
                              <div className="d-flex flex-column">
                                <p className="leads-card__title">
                                  Phone{' '}
                                  {!match.phoneVerified && (
                                    <span className="leads-card__verification-status">
                                      Unverified
                                    </span>
                                  )}
                                  {match.phoneVerified && (
                                    <span className="leads-card__verification-status leads-card__verification-status--verified">
                                      Verified
                                    </span>
                                  )}
                                </p>{' '}
                                <p className="leads-card__description">
                                  {isPremiumBreederWithLeads ? match.phone : '(xxx) xxx-xxxx'}
                                </p>
                              </div>
                              {isPremiumBreederWithLeads && (
                                <div className="d-flex justify-content-end align-items-center gap-2">
                                  <a href={`tel:${match.phone}`}>
                                    <img alt="call-logo" src={call} className="leads-card__icon" />
                                  </a>
                                  <a href={`sms:${match.phone}`}>
                                    <img alt="chat-logo" src={chat} className="leads-card__icon" />
                                  </a>
                                  <img
                                    alt="copy-logo"
                                    src={copy}
                                    className="leads-card__icon"
                                    onClick={() => {
                                      navigator.clipboard.writeText(match.phone);
                                      addToast('Phone number copied to clipboard.', {
                                        appearance: 'success',
                                        autoDismiss: true,
                                      });
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        className={`modal-archived ${
                          cardArchiveClicked[match.id] && 'modal-archived--active'
                        }`}
                      >
                        <p className="modal-archived__title">
                          Are you sure your want to archive this lead?
                        </p>
                        <p className="modal-archived__subtitle">
                          You can reactivate your archived leads at any time.
                        </p>
                        <TTButton
                          className="btn-primary-orchid"
                          text="Archive Lead"
                          width="135px"
                          height="40px"
                          fontSize="12px"
                          borderRadius="10px"
                          onClick={() => {
                            setCardArchiveClicked({ ...cardArchiveClicked, [match.id]: false });
                            archiveBreederMatch({
                              leadId: match.id,
                              archived: true,
                            });
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </Fragment>
              ))}
              <div className="d-flex justify-content-center w-100">
                <button
                  ref={ref}
                  onClick={() =>
                    leadsSubtab === 'active' ? fetchNextPage() : fetchNextPageArchived()
                  }
                  disabled={
                    leadsSubtab === 'active'
                      ? !hasNextPage || isFetchingNextPage
                      : !hasNextPageArchived || isFetchingNextPageArchived
                  }
                  className="btn-loading"
                >
                  {leadsSubtab === 'active' &&
                    (isFetchingNextPage
                      ? 'Loading more active matches...'
                      : hasNextPage
                      ? 'Load Newer active matches'
                      : '')}
                  {leadsSubtab === 'archived' &&
                    (isFetchingNextPageArchived
                      ? 'Loading more archived matches...'
                      : hasNextPageArchived
                      ? 'Load Newer archived matches'
                      : '')}
                </button>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
};
export default MyLeads;
