import React, { useContext } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Select, { SingleValue } from 'react-select';
import { some } from 'lodash';

//types
import {
  IHearAboutUsForm,
  TSelectOptionsType,
  TAuthContext,
  TMoreAboutBreeder,
  IBreederPayload,
} from 'types';
//components
import TTButton from 'components/common/TTButton';
//constants
import { howDidYouHearAboutUs } from 'constants/dropdowns/howDidYouHearAboutUs';
//services
import { useDataLayer } from 'services/dataLayerContext';
import { AuthState } from 'services/auth';
//hooks
import { useMutationUpdateBreederInfo } from '../__hooks/useMutationUpdateBreederInfo';
//styles
import { selectStyles } from 'components/styled/select';
import '../index.scss';
//validation
import { hearAboutUsSchema } from './Validation';

const HearAboutUs = ({ setStep, step, breederInfo }: TMoreAboutBreeder) => {
  const pushToDataLayer = useDataLayer();
  const {
    setRegistrationInfo,
    state: { breederId },
  } = useContext(AuthState) as TAuthContext;
  const {
    register,
    handleSubmit,
    control,
    watch,
    trigger,
    formState: { errors },
  } = useForm<IHearAboutUsForm>({
    resolver: yupResolver(hearAboutUsSchema),
    defaultValues: {
      hearAboutUs: some(howDidYouHearAboutUs, { label: breederInfo.hearAboutUs })
        ? { label: breederInfo.hearAboutUs, value: breederInfo.hearAboutUs }
        : undefined,
      other: some(howDidYouHearAboutUs, { label: breederInfo.hearAboutUs })
        ? undefined
        : breederInfo.hearAboutUs,
    },
  });

  // Mutation hooks
  const { mutate, isLoading: isUpdatingBreederData } = useMutationUpdateBreederInfo({
    setStep,
    step,
    isLastStep: true,
  });

  const onSubmit = (data: IHearAboutUsForm) => {
    pushToDataLayer({
      event: 'hearAboutUsClick',
      componentName: 'Hear about us',
      ...data,
    });
    setRegistrationInfo('hearAboutUsForm', data);

    const breederRequestPayload = {
      data: {
        ...breederInfo,
        hearAboutUs: data.hearAboutUs.label === 'Other' ? data.other : data.hearAboutUs.label,
      },
      id: breederId,
    };
    mutate(breederRequestPayload as IBreederPayload);
  };

  return (
    <div className="hear-about-us">
      <h2>Hey, how did you hear about us?</h2>

      <Form
        onSubmit={handleSubmit(onSubmit)}
        id="breederRegistrationHearAboutUsForm"
        className="w-100"
      >
        <Row className="fill-available">
          <Col xs={12} className={`${errors?.hearAboutUs?.label ? 'mb-0 input-error' : 'mb-4'}`}>
            <Form.Label className="form-labels">How did you hear about us?*</Form.Label>
            <Controller
              control={control}
              name={'hearAboutUs'}
              render={({ field: { onChange, value } }) => (
                <Select
                  closeMenuOnSelect={true}
                  isSearchable
                  value={value}
                  onChange={(val: SingleValue<TSelectOptionsType>) => {
                    onChange(val);
                    trigger('hearAboutUs');
                  }}
                  options={howDidYouHearAboutUs}
                  placeholder="Please select one option"
                  styles={selectStyles}
                  classNamePrefix="tt-select"
                />
              )}
            />
            {errors?.hearAboutUs && (
              <p className="text-error mb-2">{errors.hearAboutUs.label?.message}</p>
            )}
          </Col>

          {watch('hearAboutUs')?.label === 'Other' && (
            <Col xs={12} className="mt-2">
              <Form.Label className="form-labels">Other</Form.Label>
              <Form.Control
                bsPrefix="input form-control mb-0"
                as="textarea"
                className="textarea"
                placeholder="Enter text"
                {...register('other')}
              />
            </Col>
          )}
        </Row>

        <div className="buttons-container">
          <TTButton
            type="submit"
            height="50px"
            className="btn-primary-orchid"
            text="Go to profile"
            loading={isUpdatingBreederData}
            disabled={isUpdatingBreederData}
          />
        </div>
      </Form>
    </div>
  );
};

export default HearAboutUs;
