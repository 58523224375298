import axios from 'axios';

//services
import { setInterceptors } from 'services/api/interceptors';

const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Set interceptor for base URL axios instance
setInterceptors(API);

export default API;
