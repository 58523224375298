import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import LogRocket from 'logrocket';
import { includes } from 'lodash';

//services
import { getBreeder } from 'services/api/apiCalls/breeder';
//types
import { IBreeder } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';
//helpers
import { determineMemberType, determineSubscriptionType } from 'helpers/users';

export const useQueryBreederInfo = (id: string, enabled: boolean) => {
  const {
    data: breederInfo,
    error: errorBreeder,
    isLoading: isLoadingBreeder,
    isFetching: isFetchingBreeder,
  } = useQuery<AxiosResponse<IBreeder>, Error>([QUERY_KEYS.BREEDER, id], () => getBreeder(id), {
    enabled: !!enabled,
    retry: false,
    onSuccess: data => {
      // Identify breeder for LogRocket session
      LogRocket.identify(data.data?.userId || 'Unknown', {
        userId: data.data?.userId || 'Unknown',
        name: data.data?.displayName || 'Unknown',
        email: data.data?.email || 'Unknown',
        ttUser: includes(data.data?.email, 'telltail'),
        subscriptionType: determineSubscriptionType(data.data?.subscriptionType),
        memberType: determineMemberType(data.data.userRoles),
      });
    },
  });

  return { errorBreeder, breederInfo: breederInfo?.data, isLoadingBreeder, isFetchingBreeder };
};
