import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Box } from '@mui/material';
import MaterialReactTable, { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import {
  Edit,
  Description,
  Comment,
  Download,
  Subscriptions,
  Visibility,
} from '@mui/icons-material';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import moment from 'moment';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem, Divider } from 'rc-menu';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'rc-dropdown/assets/index.css';

//hooks
import { useQueryBreeederList } from './__hooks/useQueryBreeederList';
import { useQueryBuyerList } from './__hooks/useQueryBuyerList';
import useMutationMarkAsShopifyPaid from './__hooks/useMutationMarkAsShopifyPaid';
import useMutationFlagForReview from '../ReviewQueue/__hooks/useMutationFlagForReview';
//types
import { IDialogContent, IBreeder, IAdminUser, TColumnFilterState, IRequestPayload } from 'types';
//components
import TTButton from 'components/common/TTButton';
import ActionButton from 'components/common/ActionButton';
import AlertDialog from 'components/common/AlertDialog';
import UserDetails from './UserDetails';
import DownloadBreederBadge from './DownloadBreederBadge';
import EditUserProfileDialog from './EditUserProfileDialog';
import EditBuyerInfoDialog from './EditBuyerInfoDialog';
import LogsDialog from './LogsDialog';
import NotesDialog from './NotesDialog';
import BuyerDetails from './BuyerDetails';
import SubscriptionEndDialog from './SubscriptionEndDialog';
//constants
import { csvOptions } from 'helpers/cvsOptions';
import { breederCSVColumns } from 'constants/breederCSVColumns';
import { buyerCSVColumns } from 'constants/buyerCSVColumns';
import { QUERY_KEYS } from 'constants/queryKeys';
//styles
import { menu_item_style } from 'components/styled/menu';

const UsersTable = ({ active }: { active: { label: string; value: string } }) => {
  const queryClient = useQueryClient();
  const requestPayload = useRef<IRequestPayload>();
  const [columnFilters, setColumnFilters] = useState<TColumnFilterState[]>([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [dialogContent, setDialogContent] = useState<IDialogContent | null>(null);
  const [editUserProfileDialogContent, setEditUserProfileDialogContent] = useState<
    IBreeder | IAdminUser | undefined
  >();
  const [downloadBadgeDialog, setDownloadBadgeDialog] = useState<IBreeder | undefined>();
  const [editBuyerInfoDialogContent, setEditBuyerInfoDialogContent] = useState<
    IBreeder | IAdminUser | undefined
  >();
  const [logsDialogContent, setLogsDialogContent] = useState<IBreeder | IAdminUser | undefined>();
  const [notesDialogContent, setNotesDialogContent] = useState<IBreeder | IAdminUser | undefined>();
  const [subscriptionDialogContent, setSubscriptionDialogContent] = useState<
    { subscriptionEndType: string; user: IBreeder | IAdminUser } | undefined
  >();

  const isBuyer = useMemo(() => active.label === 'Buyers', [active.label]);

  //Query hooks
  const {
    isBreederListError,
    breeders,
    isFetchingBreederList,
    isLoadingBreederList,
    totalBreeders,
  } = useQueryBreeederList(
    active.value.includes('breeder_') ? active.value.replace('breeder_', '') : active.value,
    pagination,
    columnFilters,
    active.label.includes('All') ||
      active.label.includes('Admin') ||
      active.label.includes('Breeder'),
  );
  const { isBuyerListError, buyers, isFetchingBuyerList, isLoadingBuyerList, totalBuyers } =
    useQueryBuyerList(
      active.value.includes('buyer_') ? active.value.replace('buyer_', '') : active.value,
      pagination,
      columnFilters,
      active.label.includes('All') || active.label.includes('Buyer'),
    );

  //Mutation hooks
  const { markAsShopifyPaidMutate, isMarkingAsShopifyPaid } =
    useMutationMarkAsShopifyPaid(setDialogContent);
  const { flagForReviewMutate, isFlagingForReview } = useMutationFlagForReview(setDialogContent);

  useEffect(() => {
    setColumnFilters([]);
    setPagination({ pageIndex: 0, pageSize: 10 });
  }, [active.label]);

  const users: (IBreeder | IAdminUser)[] = useMemo(
    () =>
      active.label.includes('All') || active.label.includes('Deleted')
        ? [...(breeders || []), ...(buyers || [])]
        : active.label.includes('Buyer')
        ? [...(buyers || [])]
        : [...(breeders || [])],
    [breeders, buyers, active.label],
  );

  const totalRows = useMemo(
    () =>
      active.label.includes('All') || active.label.includes('Deleted')
        ? totalBreeders + totalBuyers
        : active.label.includes('Buyer')
        ? totalBuyers
        : totalBreeders,
    [totalBreeders, totalBuyers, active.label],
  );

  const columns = useMemo<MRT_ColumnDef<IBreeder | IAdminUser>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'firstName',
        id: 'name',
        accessorFn: row => `${row.firstName} ${row.lastName}`,
      },
      {
        header: 'Email',
        accessorKey: 'email',
        id: 'email',
      },
      {
        header: 'Member Type',
        accessorKey: 'subscriptionType',
        accessorFn: row => row.subscriptionType.toUpperCase().replaceAll('_', ' '),
        id: 'memb_type',
        enableColumnFilter: !isBuyer,
        filterVariant: 'select',
        filterSelectOptions: [
          { text: 'Free', value: 'free' },
          { text: 'Free Premium', value: 'free_premium' },
          { text: 'Premium', value: 'premium' },
        ],
        muiTableHeadCellFilterTextFieldProps: {
          sx: { m: '0.5rem 0', width: '180px' },
        },
      },
      {
        header: 'Membership Status',
        accessorKey: 'subscriptionStatus',
        accessorFn: row => row.subscriptionStatus.toUpperCase().replaceAll('_', ' '),
        id: 'memb_status',
        enableColumnFilter: !isBuyer,
        filterVariant: 'select',
        filterSelectOptions: [
          { text: 'ACTIVE', value: 'active' },
          { text: 'INACTIVE', value: 'inactive' },
          { text: 'REVIEW', value: 'in-review' },
          { text: 'REJECTED', value: 'rejected' },
          { text: 'CANCELED', value: 'canceled' },
        ],
        muiTableHeadCellFilterTextFieldProps: {
          sx: { m: '0.5rem 0', width: '180px' },
        },
      },
      {
        header: 'Location',
        accessorKey: isBuyer ? 'location' : 'address',
        id: 'location',
      },
    ],
    [isBuyer],
  );

  // Download table to CSV format logic
  const csvBreederOptions = csvOptions(
    breederCSVColumns,
    `${active.label}_Breeders_TellTail_${moment()
      .utc()
      .utcOffset('-0700')
      .format('MMMM_D_YYYY_hh_mm_A')}`,
  );
  const csvBreedersExporter = new ExportToCsv(csvBreederOptions);
  const csvBreedersData = breeders?.map(breeder => {
    return {
      breederId: breeder._id,
      userId: breeder.userId,
      firstName: breeder.firstName,
      middleName: breeder.middleName,
      lastName: breeder.lastName,
      displayName: breeder.displayName,
      email: breeder.email,
      businessProgram: breeder.businessProgram,
      businessDetails: breeder.businessDetails,
      yearsOfExperience: breeder.yearsOfExperience,
      aproxAnnualSellPuppies: breeder.aproxAnnualSellPuppies,
      numberOfDams: breeder.numberOfDams,
      numberOfStuds: breeder.numberOfStuds,
      refurralCode: breeder.refurralCode,
      address: breeder.address,
      city: breeder.city,
      zipCode: breeder.zipCode,
      state: breeder.state,
      country: breeder.country,
      approved: breeder.approved ? 'Yes' : 'No',
      breeds: breeder.breeds?.map(item => item?.toUpperCase()).join(', '),
      damsLocation: breeder.damsLocation,
      damsSleepLocation: breeder.damsSleepLocation,
      isUseStudServices: breeder.isUseStudServices ? 'Yes' : 'No',
      isSellStudServices: breeder.isSellStudServices ? 'Yes' : 'No',
      minimalPuppyAgeToSend: breeder.minimalPuppyAgeToSend,
      breedNewDam: breeder.damsLocation,
      damBreedLifetime: breeder.damBreedInLifetime,
      minimalDamAgeToBreed: breeder.minimalDamAgeToBreed,
      damBreedIn18mPeriod: breeder.damBreedIn18mPeriod,
      damBreedInLifetime: breeder.damBreedInLifetime,
      damFood: breeder.damFood,
      puppiesFood: breeder.puppiesFood,
      isHealthTest: breeder.isHealthTest ? 'Yes' : 'No',
      labs: breeder.labs?.map(item => item?.toUpperCase()).join(', '),
      healthTests: breeder.healthTests?.map(item => item?.toUpperCase()).join(', '),
      isVaccinatePuppies: breeder.isVaccinatePuppies ? 'Yes' : 'No',
      vaccinationDescription: breeder.vaccinationDescription,
      isSocializePuppies: breeder.isSocializePuppies ? 'Yes' : 'No',
      socializeDescription: breeder.socializeDescription,
      isReproductiveVeterinarian: breeder.isReproductiveVeterinarian ? 'Yes' : 'No',
      vetName: breeder.vetName,
      vetEmail: breeder.vetEmail,
      vetPhoneNumber: breeder.vetPhoneNumber,
      breedingPractices: breeder.breedingPractices,
      litterAvailability: breeder.litterAvailability,
      isShareInfoWithParents: breeder.isShareInfoWithParents ? 'Yes' : 'No',
      isSellPuppyToThirdParty: breeder.isSellPuppyToThirdParty ? 'Yes' : 'No',
      thirdPartyName: breeder.thirdPartyName,
      isFillApplication: breeder.isFillApplication ? 'Yes' : 'No',
      isContractSigned: breeder.isContractSigned ? 'Yes' : 'No',
      isPickPuppyFromHome: breeder.isPickPuppyFromHome ? 'Yes' : 'No',
      isStayInTouch: breeder.isStayInTouch ? 'Yes' : 'No',
      programDetails: breeder.programDetails,
      isCompletedBreederEducation: breeder.isCompletedBreederEducation ? 'Yes' : 'No',
      breederEducationDetail: breeder.breederEducationDetail,
      educationPrograms: breeder.educationPrograms?.map(item => item?.toUpperCase()).join(', '),
      isBelongToClub: breeder.isBelongToClub ? 'Yes' : 'No',
      breederClubs: breeder.breederClubs?.map(item => item?.toUpperCase()).join(', '),
      breedingProgramWebsite: breeder.breedingProgramWebsite,
      facebookUrl: breeder.facebookUrl,
      instagramUrl: breeder.instagramUrl,
      twitterUrl: breeder.twitterUrl,
      youtubeUrl: breeder.youtubeUrl,
      tikTokUrl: breeder.tikTokUrl,
      facebookGroups: breeder.facebookGroups?.map(item => item?.toUpperCase()).join(', '),
      profilePicUrl: breeder.profilePicUrl,
      breederProgramDescription: breeder.breederProgramDescription,
      responsibleBreedingDescription: breeder.responsibleBreedingDescription,
      tshirtSize: breeder.tshirtSize,
      status: breeder.status,
      shortDescription: breeder.shortDescription,
      additionalInfo: breeder.additionalInfo,
      hearAboutUs: breeder.hearAboutUs,
      knowledgeTags: breeder.knowledgeTags?.map(item => item?.toUpperCase()).join(', '),
      isReferralValid: breeder.isReferralValid ? 'Yes' : 'No',
      subscriptionType: breeder.subscriptionType,
      subscriptionStatus: breeder.subscriptionStatus,
      userRoles: breeder.userRoles?.map(item => item?.toUpperCase()).join(', '),
    };
  });
  const csvBuyersOptions = csvOptions(
    buyerCSVColumns,
    `${active.label}_Buyers_TellTail_${moment()
      .utc()
      .utcOffset('-0700')
      .format('MMMM_D_YYYY_hh_mm_A')}`,
  );
  const csvBuyersExporter = new ExportToCsv(csvBuyersOptions);
  const csvBuyersData = buyers?.map(buyer => {
    return {
      buyerId: buyer._id,
      userId: buyer.userId,
      firstName: buyer.firstName,
      lastName: buyer.lastName,
      email: buyer.email,
      subscriptionType: buyer.subscriptionType,
      subscriptionStatus: buyer.subscriptionStatus,
      location: buyer.location,
      zipCode: buyer.zipCode,
    };
  });
  const handleExportData = () => {
    if (
      active.label.includes('All') ||
      active.label.includes('Admin') ||
      active.label.includes('Breeder')
    ) {
      csvBreedersExporter.generateCsv(csvBreedersData);
    }

    if (active.label.includes('All') || active.label.includes('Buyer')) {
      csvBuyersExporter.generateCsv(csvBuyersData);
    }
  };

  const handleMarkAsShopifyPaid = (row: MRT_Row<IBreeder | IAdminUser>) => {
    requestPayload.current = {
      payload: { email: row.original.email },
      type: 'type_mark_as_shopify_paid',
    };
    setDialogContent({
      title: `Mark ${row.original.firstName} ${row.original.lastName} as shopify paid?`,
      message: `Are you sure you want to mark ${row.original.firstName} ${row.original.lastName} as shopify paid?`,
    });
  };

  // Handle flg for review
  const handleFlagForReview = (row: MRT_Row<IBreeder | IAdminUser>) => {
    requestPayload.current = {
      payload: row.original._id,
      type: 'type_review_account',
    };
    setDialogContent({
      title: `Flag ${row.original.firstName} ${row.original.lastName} for Manual Review?`,
      message: `Are you sure you want to flag ${row.original.firstName} ${row.original.lastName} for manual review?`,
    });
  };

  const renderUserTableActions = ({ row }: { row: MRT_Row<IBreeder | IAdminUser> }) => {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" gap="0.5rem">
        {!isBuyer && (
          <ActionButton
            title="Download Badge"
            Icon={<Download />}
            color="red"
            disabled={!(row.original as IBreeder).badgeDetailsId}
            onClick={() => setDownloadBadgeDialog(row.original as IBreeder)}
          />
        )}

        {/* Dropdown - View Breeder Profile and Vouched Job */}
        {active.label.startsWith('Review') && (
          <Dropdown
            trigger={['click']}
            placement="bottomRight"
            overlay={() => (
              <Menu
                onClick={({ key }) => {
                  if (key === 'view-profile')
                    window
                      .open(`/public-profile/${(row.original as IBreeder)._id}`, '_blank')
                      ?.focus();
                  else if (key === 'view-vouched')
                    window
                      .open(
                        `https://app.vouched.id/account/jobs/${(row.original as IBreeder)._id}}`,
                        '_blank',
                      )
                      ?.focus();
                }}
                style={{ width: 200, background: '#333' }}
              >
                <MenuItem key="view-profile" style={menu_item_style}>
                  View Profile
                </MenuItem>
                <Divider style={{ background: 'white', height: 1 }} />
                <MenuItem key="view-vouched" style={menu_item_style}>
                  View Vouched Job
                </MenuItem>
              </Menu>
            )}
            animation="slide-up"
          >
            <ActionButton title="View" Icon={<Visibility />} color="black" />
          </Dropdown>
        )}

        <ActionButton
          title="Edit"
          Icon={<Edit />}
          color="gray"
          disabled={
            isLoadingBreederList ||
            isFetchingBreederList ||
            isLoadingBuyerList ||
            isFetchingBuyerList
          }
          onClick={() => {
            isBuyer
              ? setEditBuyerInfoDialogContent(row.original)
              : setEditUserProfileDialogContent(row.original);
          }}
        />
        <ActionButton
          title="Logs"
          Icon={<Comment />}
          color="blue"
          onClick={() => setLogsDialogContent(row.original)}
        />

        <ActionButton
          title="Notes"
          Icon={<Description />}
          color="green"
          onClick={() => setNotesDialogContent(row.original)}
        />

        {!active.label.startsWith('Admins') && (
          <Dropdown
            trigger={['click']}
            overlay={() => (
              <Menu
                onClick={({ key }) => {
                  if (key === 'review-account') return handleFlagForReview(row);
                  else if (key === 'mark-as-shopify-paid') return handleMarkAsShopifyPaid(row);

                  setSubscriptionDialogContent({
                    subscriptionEndType: key,
                    user: row.original,
                  });
                }}
                style={{ width: 200, background: '#8293ee' }}
              >
                {/* It's not working as expected from BE. So, I temporarily hide it on chuck's ask */}
                {/* {active.label.startsWith('Premium') && (
                  <>
                    <MenuItem key="downgrade-next" style={menu_item_style}>
                      Downgrade to basic
                    </MenuItem>

                    <Divider style={{ background: 'white', height: 1 }} />
                  </>
                )}
                {active.label.startsWith('Premium') && (
                  <>
                    <MenuItem key="downgrade-immediate" style={menu_item_style}>
                      Immediate downgrade to basic
                    </MenuItem>
                    <Divider style={{ background: 'white', height: 1 }} />
                  </>
                )} */}

                {!isBuyer &&
                  active.value === 'breeder_1' &&
                  process.env.REACT_APP_ENV_TIER !== 'production' && (
                    <>
                      <MenuItem key="mark-as-shopify-paid" style={menu_item_style}>
                        Mark as Shopify Paid
                      </MenuItem>
                      <Divider style={{ background: 'white', height: 1 }} />
                    </>
                  )}
                {!isBuyer && (
                  <>
                    <MenuItem key="review-account" style={menu_item_style}>
                      Flag for Manual Review
                    </MenuItem>
                    <Divider style={{ background: 'white', height: 1 }} />
                  </>
                )}
                <MenuItem key="close-account" style={menu_item_style}>
                  Close this account
                </MenuItem>
              </Menu>
            )}
            animation="slide-up"
          >
            <ActionButton title="Actions" Icon={<Subscriptions />} color="#8293ee" />
          </Dropdown>
        )}
      </Box>
    );
  };

  return (
    <>
      <div className="">
        <MaterialReactTable
          columns={columns}
          data={users ?? []}
          rowCount={totalRows}
          initialState={{
            columnVisibility: { status: false },
            showColumnFilters: true,
          }}
          state={{
            isLoading: isLoadingBreederList || isLoadingBuyerList,
            showAlertBanner: isBreederListError || isBuyerListError,
            showProgressBars: isFetchingBreederList || isFetchingBuyerList,
            columnFilters,
            pagination,
          }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [10, 20, 50],
          }}
          memoMode="cells"
          manualPagination
          onPaginationChange={setPagination}
          manualFiltering
          onColumnFiltersChange={setColumnFilters}
          enableColumnOrdering
          enableGlobalFilter={false}
          renderTopToolbarCustomActions={() => (
            <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', pb: '2rem', flexWrap: 'wrap' }}>
              <TTButton
                className="btn-primary-orchid"
                height={40}
                width={200}
                disabled={isFetchingBreederList || isFetchingBuyerList}
                text="Export to CSV"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
              />
            </Box>
          )}
          enableRowActions
          positionActionsColumn="last"
          renderRowActions={renderUserTableActions}
          renderDetailPanel={({ row }) =>
            isBuyer ? (
              <BuyerDetails buyerAccountId={row.original._id} />
            ) : (
              <UserDetails breederInfo={row.original as IBreeder} />
            )
          }
          muiTableHeadCellFilterTextFieldProps={{
            sx: { m: '0.5rem 0', width: '100%' },
            variant: 'outlined',
            inputProps: {
              className: 'table-filter-input',
            },
          }}
        />
      </div>

      <AlertDialog
        isOpened={Boolean(dialogContent)}
        dialogContent={dialogContent}
        isLoading={isMarkingAsShopifyPaid || isFlagingForReview}
        onAgree={() => {
          if (!requestPayload.current) return;

          if (requestPayload.current.type === 'type_review_account') {
            flagForReviewMutate(requestPayload.current.payload);
          } else if (requestPayload.current.type === 'type_mark_as_shopify_paid') {
            markAsShopifyPaidMutate(requestPayload.current.payload);
          }
        }}
        handleClose={() => setDialogContent(null)}
      />

      {Boolean(downloadBadgeDialog) && (
        <DownloadBreederBadge
          open={Boolean(downloadBadgeDialog)}
          handleClose={() => {
            setDownloadBadgeDialog(undefined);
          }}
          existingBreeder={downloadBadgeDialog}
        />
      )}

      {Boolean(editUserProfileDialogContent) && (
        <EditUserProfileDialog
          open={Boolean(editUserProfileDialogContent)}
          handleClose={async () => {
            setEditUserProfileDialogContent(undefined);
            await queryClient.invalidateQueries(QUERY_KEYS.BREEDER_LIST, {
              refetchInactive: true,
            });
          }}
          displayName={`${editUserProfileDialogContent?.firstName} ${editUserProfileDialogContent?.lastName}`}
          userId={editUserProfileDialogContent?._id}
        />
      )}

      {Boolean(editBuyerInfoDialogContent) && (
        <EditBuyerInfoDialog
          open={Boolean(editBuyerInfoDialogContent)}
          handleClose={() => {
            setEditBuyerInfoDialogContent(undefined);
          }}
          displayName={`${editBuyerInfoDialogContent?.firstName} ${editBuyerInfoDialogContent?.lastName}`}
          buyerAccountId={editBuyerInfoDialogContent?._id}
        />
      )}

      {Boolean(logsDialogContent) && (
        <LogsDialog
          open={Boolean(logsDialogContent)}
          handleClose={() => {
            setLogsDialogContent(undefined);
          }}
          displayName={`${logsDialogContent?.firstName} ${logsDialogContent?.lastName}`}
          userId={logsDialogContent?._id}
          isBuyer={isBuyer}
        />
      )}

      {Boolean(notesDialogContent) && (
        <NotesDialog
          open={Boolean(notesDialogContent)}
          handleClose={() => {
            setNotesDialogContent(undefined);
          }}
          displayName={`${notesDialogContent?.firstName} ${notesDialogContent?.lastName}`}
          userId={notesDialogContent?._id}
          isBuyer={isBuyer}
        />
      )}

      {Boolean(subscriptionDialogContent) && (
        <SubscriptionEndDialog
          open={Boolean(subscriptionDialogContent)}
          handleClose={() => {
            setSubscriptionDialogContent(undefined);
            queryClient.invalidateQueries(QUERY_KEYS.BREEDER_LIST, {
              refetchInactive: true,
            });
            queryClient.invalidateQueries(QUERY_KEYS.BUYER_LIST, {
              refetchInactive: true,
            });
          }}
          displayName={`${subscriptionDialogContent?.user?.firstName} ${subscriptionDialogContent?.user?.lastName}`}
          userId={subscriptionDialogContent?.user?.userId}
          subscriptionEndType={subscriptionDialogContent?.subscriptionEndType}
        />
      )}
    </>
  );
};

export default UsersTable;
