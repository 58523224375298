export const menu_item_style = {
  color: 'white',
  padding: 10,
  fontSize: 14,
  cursor: 'pointer',
};

export const menu_item_style__disabled = {
  ...menu_item_style,
  color: '#c6c6c6',
  backgroundColor: '#EBEBE4',
  cursor: 'default',
};
