import React, { useContext } from 'react';
import { useSearchParams, useLocation, Navigate } from 'react-router-dom';

import './index.scss';
//services
import { AuthState } from 'services/auth';
//types
import { TAuthContext } from 'types';

const NonExistingRoute = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const {
    state: { isAuthenticated },
  } = useContext(AuthState) as TAuthContext;

  return (
    <Navigate
      to={isAuthenticated ? `/profile?${searchParams}` : `/login?${searchParams}`}
      state={{ followUpLink: location.pathname }}
    />
  );
};

export default NonExistingRoute;
