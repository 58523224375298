/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from 'yup';

export const SendCodeToEmailSchema = Yup.object().shape({
  emailAddress: Yup.string().email('Invalid email').required('This field is required.'),
});

export const ForgotPasswordSchema = Yup.object().shape({
  resetPasswordToken: Yup.string().required('This field is required.').nullable(),
  newPassword: Yup.string().required('This field is required.'),
  confirmNewPassword: Yup.string().required('This field is required.'),
});

export async function validateChangePassword({ newPassword, confirmNewPassword, setError }: any) {
  const passwordPatten = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

  if (!newPassword.match(passwordPatten)) {
    return setError('newPassword', {
      type: 'custom',
      message: 'Passwords must meet requirements.',
    });
  } else if (newPassword !== confirmNewPassword) {
    return setError('confirmNewPassword', {
      type: 'custom',
      message: 'Passwords should match.',
    });
  } else {
    return false;
  }
}
