import { Dispatch, SetStateAction } from 'react';
import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { sendResetPasswordTokenToEmail } from 'services/api/apiCalls/auth';
//types
import { TError, ISendCodeToEmailForm } from 'types';

export const useMutationSendEmail = (
  setShowChangePasswordForm: Dispatch<SetStateAction<boolean>>,
  setEmail: Dispatch<SetStateAction<string>>,
) => {
  const { addToast } = useToasts();

  return useMutation<AxiosResponse<{ message: string }>, AxiosError<TError>, ISendCodeToEmailForm>(
    sendResetPasswordTokenToEmail,
    {
      onSuccess: (data, variables) => {
        setEmail(variables.emailAddress);
        addToast('Verification code is sent to your email address!', {
          appearance: 'success',
          autoDismiss: true,
        });
        setShowChangePasswordForm(true);
      },
      onError: () => {
        addToast('Your email is not registered!', {
          appearance: 'error',
          autoDismiss: true,
        });
      },
    },
  );
};
