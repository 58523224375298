import React, { useMemo } from 'react';
import { Box, Card, Typography } from '@mui/material';
import moment from 'moment';
import { isEmpty } from 'lodash';

//types
import { IPawfficeHour } from 'types';
//components
import InfoView from 'components/common/InfoView';
import TagItem from 'components/common/TagItem';

const SubHeading = ({ text }: { text: string }) => {
  return (
    <Box bgcolor="pink" py={1} pl={2}>
      <Typography variant="subtitle1" color="#333" fontWeight="bold">
        {text}
      </Typography>
    </Box>
  );
};

const PawfficeHourDetails = ({ pawfficeHour }: { pawfficeHour: IPawfficeHour }) => {
  const endDateTime = useMemo(
    () =>
      moment(pawfficeHour.startDate)
        .add(pawfficeHour.duration.hours, 'hours')
        .add(pawfficeHour.duration.minutes, 'minutes')
        .format('LLL'),
    [pawfficeHour.startDate, pawfficeHour.duration.hours, pawfficeHour.duration.minutes],
  );

  if (!pawfficeHour) return <></>;

  return (
    <Box display="flex" maxWidth="100vw">
      {/* Left Side View */}
      <Box flex={1} flexDirection="column" maxWidth="50%">
        <SubHeading text="Pawffice Hour Information" />
        <InfoView
          label="Pawffice Hour Image:"
          value={
            !isEmpty(pawfficeHour.pawfficeImageUrl) ? (
              <Box display="flex">
                <Card sx={{ width: '90%', height: 400, mt: 1 }} elevation={3}>
                  <img
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    alt="Program Picture"
                    src={pawfficeHour.pawfficeImageUrl}
                  />
                </Card>
              </Box>
            ) : (
              <p className="mt-0">No Image found.</p>
            )
          }
          vertical
        />
      </Box>

      <Box sx={{ width: 20 }} />

      {/* Right Side View */}
      <Box flex={1} flexDirection="column" maxWidth="50%">
        <SubHeading text="Pawffice Hour Information" />
        <InfoView label="Pawffice Hour Id:" value={pawfficeHour._id} />
        <InfoView label="Name:" value={pawfficeHour.name} />
        <InfoView label="Host Name:" value={pawfficeHour.host.hostName} />
        <InfoView label="Start Date:" value={moment(pawfficeHour.startDate).format('LLL')} />
        <InfoView
          label="Duration:"
          value={`${pawfficeHour.duration.hours} hr ${pawfficeHour.duration.minutes} min (till ${endDateTime})`}
        />
        <InfoView
          label="Link:"
          value={
            <a href={pawfficeHour.call.link} target="_blank" rel="noopener noreferrer">
              {pawfficeHour.call.link}
            </a>
          }
        />
        <InfoView
          label="Dial:"
          value={<a href={`tel:${pawfficeHour.call.dialNumber}`}>{pawfficeHour.call.dialNumber}</a>}
        />
        <InfoView label="Description:" value={pawfficeHour.description || 'N/A'} />
        <InfoView
          label="Visibility:"
          value={
            <ul className="breederProfile__tags mt-0">
              {pawfficeHour.visibility.map((visibility, idx) => (
                <TagItem key={visibility + idx} name={visibility.toUpperCase()} />
              ))}
            </ul>
          }
        />
        <InfoView
          label="Tags:"
          value={
            <ul className="breederProfile__tags mt-0">
              {pawfficeHour.tags.map((tag, idx) => (
                <TagItem key={tag + idx} name={tag.toUpperCase()} />
              ))}
            </ul>
          }
        />
      </Box>
    </Box>
  );
};

export default PawfficeHourDetails;
