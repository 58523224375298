import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

//services
import { getDropdownBreeds } from 'services/api/apiCalls/breeds';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useQueryBreedsDropdown = () => {
  const {
    data: breedsDropdown,
    error: errorBreedsDropdown,
    isLoading: isLoadingBreedsDropdown,
    isFetching: isFetchingBreedsDropdown,
  } = useQuery<AxiosResponse<{ breedId: string; breedName: string }[]>, Error>(
    QUERY_KEYS.BREEDS_DROPDOWN,
    getDropdownBreeds,
  );

  return {
    errorBreedsDropdown,
    breedsDropdown: breedsDropdown?.data?.map(val => ({
      value: val.breedId,
      label: val.breedName,
    })),
    isLoadingBreedsDropdown,
    isFetchingBreedsDropdown,
  };
};
