import React from 'react';

//types
import { ICurrencyInput } from 'types';
//styles
import './index.scss';

const CurrencyInput = ({ sign, placeholder, register, name }: ICurrencyInput) => {
  return (
    <div className="input-container">
      <span className="currency-sign">{sign}</span>
      <input
        type="number"
        className="currency-input input form-control mb-0"
        placeholder={placeholder}
        {...register(name)}
      />
    </div>
  );
};

export default CurrencyInput;
