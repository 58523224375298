import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { activateBuyerProfile } from 'services/api/apiCalls/buyer';
//types
import { IBuyerInfo, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useMutationActivateBuyerProfile = (handleNextDestination: () => void) => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation<AxiosResponse<IBuyerInfo>, AxiosError<TError>>(activateBuyerProfile, {
    onSuccess: async () => {
      window.scrollTo({ top: 0 });
      addToast('Your profile has been activated!', {
        appearance: 'success',
        autoDismiss: true,
      });
      await queryClient.invalidateQueries(QUERY_KEYS.BUYER_ACCOUNT_DETAILS, {
        refetchInactive: true,
      });

      handleNextDestination();
    },

    onError: async () => {
      addToast('Something went wrong with your request.', {
        appearance: 'error',
        autoDismiss: true,
      });
    },
  });
};
