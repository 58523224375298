import * as Yup from 'yup';

const isNotEmptyAndValidUrl = url => {
  return (
    url.length === 0 ||
    /^(?:(?:(?:https?|ftp):)?\/\/)?(?:www\.)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      url,
    )
  );
};

export const moreAboutProgramSchema = Yup.object().shape({
  programDetails: Yup.string().required('About My Program is required.'),
  facebookUrl: Yup.string().test('facebook', 'Please enter a valid Facebook url.', value =>
    isNotEmptyAndValidUrl(value),
  ),
  instagramUrl: Yup.string().test('instagram', 'Please enter a valid Instagram url.', value =>
    isNotEmptyAndValidUrl(value),
  ),
  twitterUrl: Yup.string().test('twitter', 'Please enter a valid Twitter(X) url.', value =>
    isNotEmptyAndValidUrl(value),
  ),
  youtubeUrl: Yup.string().test('youtube', 'Please enter a valid Youtube url.', value =>
    isNotEmptyAndValidUrl(value),
  ),
  tikTokUrl: Yup.string().test('tiktok', 'Please enter a valid Tiktok url.', value =>
    isNotEmptyAndValidUrl(value),
  ),
  breedingProgramWebsite: Yup.string().test('website', 'Please enter a valid Website url.', value =>
    isNotEmptyAndValidUrl(value),
  ),
});
