/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useContext, useRef, useState } from 'react';
import { isEmpty, map, filter } from 'lodash';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

//types
import {
  IAddLitterData,
  ILitterInfoExtended,
  TAuthContext,
  TMoreAboutBreederWithLitters,
  IAddLitterSchema,
  ILitterInfo,
} from 'types';
//hooks
import { useMutationCreateLitter, useMutationUpdateLitterInfo } from '../__hooks/useQueryLitters';
import { useMutationUpdateBreederInfo } from '../__hooks/useMutationUpdateBreederInfo';
//components
import TTButton from 'components/common/TTButton';
import LitterItem from './LitterItem';
import AddEditLitter from './AddLitter';
import ConfirmPopup from './ConfirmPopup';
//services
import { useDataLayer } from 'services/dataLayerContext';
import { AuthState } from 'services/auth';
//styles
import '../index.scss';

export default function Litters({
  setStep,
  step,
  breederInfo,
  litters,
}: TMoreAboutBreederWithLitters) {
  const pushToDataLayer = useDataLayer();
  const {
    setRegistrationInfo,
    state: { breederId },
  } = useContext(AuthState) as TAuthContext;

  const confirmPopupData = useRef<ILitterInfoExtended>();
  const addLitterData = useRef<IAddLitterData>();
  const [showAddEditLitter, setShowAddEditLitter] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [litterId, setLittterId] = useState<string | undefined>(undefined);

  //Mutation hooks
  const { mutate: createLitter, isLoading: isLoadingLitterCreation } = useMutationCreateLitter({
    setStep,
    step,
  });
  const { mutate: updateLitter, isLoading: isLoadingLitterUpdate } = useMutationUpdateLitterInfo({
    setStep,
    step,
  });
  const { mutate: updateBreederInfo, isLoading: isUpdatingBreederInfo } =
    useMutationUpdateBreederInfo({
      setStep,
      step,
    });

  const [breeds] = useState<ILitterInfo[]>(
    breederInfo.breedsAndLeads
      ? map(breederInfo.breedsAndLeads, (breed, key) => ({
          _id: key.toString(),
          breedName: breed.breed,
          litters: filter(litters, { breedName: breed.breed, autocreated: false }),
          isReceiveLeadsForBreed: breed.leads,
        }))
      : [],
  );

  const onAddLitterClick = (litterSchema: IAddLitterSchema) => {
    if (litterId) {
      updateLitter({
        data: {
          ...litterSchema,
          breedName: litterSchema?.breedName.label,
          breederId: breederId,
          notes: '',
          transportAvailable: true,
          priceFlexible: true,
        },
        id: litterId,
      });
    } else {
      createLitter({
        ...litterSchema,
        breederId: breederId,
        breedName: litterSchema?.breedName.label,
        notes: '',
        transportAvailable: true,
        priceFlexible: true,
      });
    }

    setShowAddEditLitter(false);
    setLittterId(undefined);
  };

  const onContinueClick = () => {
    pushToDataLayer({
      event: 'addedLittersClick',
      componentName: 'Added Litters',
      breeds,
    });
    setRegistrationInfo('addLittersBreeds', breeds);
    setStep(step + 1);
    window.scrollTo({ top: 0 });
  };

  if (isLoadingLitterCreation || isLoadingLitterUpdate || isUpdatingBreederInfo)
    return <AiOutlineLoading3Quarters className="loading-animation svg-loading" />;
  else
    return (
      <div className="litters-container">
        <h2>Improve your leads by adding current and upcoming litters.</h2>
        <p className="mt-2 mb-5">
          Adding a litter improves the buyers we connect you with. Please add a litter even if it's
          TBD right now.
        </p>
        <div className="litter-item-container">
          {isEmpty(breeds) ? (
            <div className="w-100 d-flex justify-content-center">
              <TTButton
                width="250px"
                height="50px"
                text="Add New Litter"
                onClick={() => setShowAddEditLitter(true)}
              />
            </div>
          ) : (
            breeds.map((breed, index) => (
              <LitterItem
                key={`${breed._id}_${index}`}
                litterInfo={breed}
                onReceiveLeadsToggle={(litter, isChecked) => {
                  updateLitter({
                    data: {
                      ...litter,
                      acceptLeads: isChecked,
                    },
                    id: litter._id,
                  });
                }}
                onAddLitterClick={data => {
                  addLitterData.current = data;
                  setShowAddEditLitter(true);
                }}
                onEditLitterClick={data => {
                  addLitterData.current = data;
                  setShowAddEditLitter(true);
                  setLittterId(data.litterData?._id);
                }}
                onReceiveLeadsForThisBreedToggle={() => {
                  confirmPopupData.current = {
                    ...breed,
                    updateBreederInfo: updateBreederInfo,
                    breederId,
                    isUpdatingBreederInfo,
                    breederInfo,
                  };
                  if (breed.isReceiveLeadsForBreed) {
                    setShowConfirmationPopup(true);
                  } else {
                    updateBreederInfo({
                      data: {
                        ...breederInfo,
                        breedsAndLeads: map(breederInfo.breedsAndLeads, breedAndLead => {
                          if (breedAndLead.breed === breed.breedName) {
                            return { breed: breed.breedName, leads: !breed.isReceiveLeadsForBreed };
                          } else {
                            return breedAndLead;
                          }
                        }),
                      },
                      id: breederId,
                    });
                  }
                }}
              />
            ))
          )}
        </div>
        <div className="buttons-container">
          {isEmpty(litters) && (
            <span
              className="skip-button"
              onClick={() => {
                setStep(step + 1);
                window.scrollTo({ top: 0 });
              }}
            >
              Skip for now
            </span>
          )}
          <TTButton
            height="50px"
            className="btn-primary-orchid"
            disabled={isEmpty(breeds)}
            text="Continue"
            onClick={onContinueClick}
          />
        </div>

        {showAddEditLitter && (
          <AddEditLitter
            addLitterData={addLitterData.current}
            handleClose={() => {
              setLittterId(undefined);
              setShowAddEditLitter(false);
            }}
            onAddLitterClick={onAddLitterClick}
            loading={isLoadingLitterCreation || isLoadingLitterUpdate}
            breederBreeds={map(breederInfo.breedsAndLeads, breedAndLead => ({
              label: breedAndLead.breed,
              value: breedAndLead.breed,
            }))}
          />
        )}

        {showConfirmationPopup && confirmPopupData.current && (
          <ConfirmPopup
            confirmPopupData={confirmPopupData.current}
            handleClose={() => setShowConfirmationPopup(false)}
          />
        )}
      </div>
    );
}
