/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { includes, filter } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import moment from 'moment';

//components
import Banner from 'components/common/HeadingBanner';
import KitImg from 'components/common/KitImg';
import Dropdown from 'components/common/Dropdown';
import TTButton from 'components/common/TTButton';
//types
import { IUserInfo } from 'types';
//hooks
import useQueryResourceList from './__hooks/useQueryResourceList';
import useQueryKnowledgeTagsCategories from 'pages/Deals/__hooks/useQueryKnowledgeTagsCategories';
//assets
import playVideo from 'assets/images/resourceCenter/playVideo.svg';
import lock from 'assets/images/deals/dealsLock.svg';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';
//styles
import './index.scss';

const Learn = () => {
  const navigate = useNavigate();
  const { ref, inView } = useInView();
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  const queryClient = useQueryClient();
  const userInfo = queryClient.getQueryData(QUERY_KEYS.USER_STATUS) as {
    data: IUserInfo | undefined;
  };

  //Query hooks
  const { knowledgeTagsCategories, isLoadingKnowledgeTagsCategories } =
    useQueryKnowledgeTagsCategories(true);
  const { status, data, isFetchingNextPage, fetchNextPage, hasNextPage } = useQueryResourceList(
    selectedFilters,
    true,
  );

  React.useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  const getActiveDropdownItem = () => {
    if (includes(selectedFilters, 'videos')) {
      return { label: 'Videos', value: 'videos' };
    } else if (includes(selectedFilters, 'articles')) {
      return { label: 'Articles', value: 'articles' };
    } else {
      return { label: 'All', value: 'all' };
    }
  };

  if (isLoadingKnowledgeTagsCategories)
    return (
      <div className="d-flex width-full justify-content-center my-3">
        <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
      </div>
    );

  return (
    <div className="learn">
      <Banner
        title="TellTail Premium Resources"
        subTitle={
          <>
            The TellTail Premium Resource Center is always growing; breeders and other experts add
            their “pawspectives” to the database of education and resources available for you to
            review!
          </>
        }
        bgColor="blue"
      />

      <div className="filters">
        {knowledgeTagsCategories?.map(category => (
          <div
            key={category.slugName}
            className={`filter-card ${
              includes(selectedFilters, category.slugName) ? 'active' : ''
            }`}
            onClick={() => {
              {
                includes(selectedFilters, category.slugName)
                  ? setSelectedFilters(filter(selectedFilters, val => val !== category.slugName))
                  : setSelectedFilters([...selectedFilters, category.slugName]);
              }
            }}
          >
            {category.name}
          </div>
        ))}
      </div>

      <Row className="learn__content p-0">
        {status === 'loading' ? (
          <>
            <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
          </>
        ) : status === 'error' ? (
          <>
            <span>Something went wrong</span>
          </>
        ) : (
          <>
            <Row className="m-0 gap-4 d-flex justify-content-between heading-resource-center">
              {/* Upgrade Banner */}
              {!userInfo.data?.isSfcBreeder && (
                <div className="learn__content__upgrade">
                  <span className="learn__content__upgrade__heading">
                    Upgrade to TellTail Pro to access our premium resources
                  </span>
                  <span className="learn__content__upgrade__sub-heading">
                    Only TellTail subscribers receive premium resources. Subscribe now to get
                    access.
                  </span>
                  <TTButton
                    className="btn-primary-orchid"
                    text="Upgrade"
                    height="32px"
                    fontSize="12px"
                    onClick={() => navigate('/get-certified')}
                  />
                </div>
              )}
              <div className="content-heading">
                <h4 className="heading-title m-0">Results</h4>
              </div>
              <div className="content-heading">
                <Dropdown
                  className="resource-dropdown"
                  items={[
                    { label: 'All', value: 'all' },
                    { label: 'Articles', value: 'articles' },
                    { label: 'Videos', value: 'videos' },
                  ]}
                  activeItem={getActiveDropdownItem()}
                  smallText
                  onItemClick={item => {
                    if (item.value === 'videos') {
                      setSelectedFilters([
                        ...filter(selectedFilters, val => val !== 'articles'),
                        item.value,
                      ]);
                    } else if (item.value === 'articles') {
                      setSelectedFilters([
                        ...filter(selectedFilters, val => val !== 'videos'),
                        item.value,
                      ]);
                    } else {
                      setSelectedFilters(
                        filter(selectedFilters, val => val !== 'videos' && val !== 'articles'),
                      );
                    }
                  }}
                />
              </div>
            </Row>

            {/* Activity feed cards section */}
            {data?.pages?.map((page: any, index: number) => (
              <Row
                className="m-0 px-4 pt-4 d-flex justify-content-center flex-wrap gap-3"
                key={index}
              >
                {page.items.map((item: any, idx: number) => (
                  <div className="mb-4 resource-card" key={`${item._id}${idx}`}>
                    {item.locked && (
                      <a className="resource-card__locked" href="/get-certified">
                        <img src={lock} alt="resource-locked" />
                        <div className="resource-card__locked__text">
                          <p>Upgrade to Unlock</p>
                        </div>
                      </a>
                    )}
                    <Link
                      to={
                        item.resourceType === 'article'
                          ? `/article/${item.resource._id}`
                          : `/video/${item.resource._id}`
                      }
                      className="text-decoration-none"
                      onClick={() => {
                        window.scrollTo({ top: 0 });
                      }}
                    >
                      {/* Card image area */}
                      {item.resourceType === 'article' ? (
                        <KitImg
                          width="100%"
                          src={item.resource.imageUrl}
                          kitwidth="500"
                          className="kit-image-wrapper"
                          imgClassName="kit-image"
                        />
                      ) : (
                        <KitImg
                          width="100%"
                          src={item.resource.thumbnailImage}
                          kitwidth="500"
                          className="kit-image-wrapper"
                          imgClassName="kit-image"
                        />
                      )}

                      {item.resourceType === 'video' && (
                        <span className="play__badge">
                          <img src={playVideo} alt="Play" />
                        </span>
                      )}

                      {/* Card text area */}
                      <Col className="card-text-area">
                        <h5 className="text-card-title">{item?.resource?.title}</h5>
                        <p className="text-author">
                          {item.resourceType === 'article'
                            ? item?.resource?.authorName
                            : item.resource.createdBy.displayName}{' '}
                          | {moment(item?.resource?.createdAt).format('MMM DD, YYYY')}
                        </p>

                        <div className="card-read-more">
                          <p className="text-read-more">
                            {item.resourceType === 'article' ? 'Read More' : 'Watch Now'}
                          </p>
                        </div>
                      </Col>
                    </Link>
                  </div>
                ))}
              </Row>
            ))}

            <div className="load-more">
              <button
                ref={ref}
                onClick={() => fetchNextPage()}
                disabled={!hasNextPage || isFetchingNextPage}
                className="btn-loading"
              >
                {isFetchingNextPage ? (
                  'Loading more...'
                ) : hasNextPage ? (
                  'Load Newer'
                ) : (
                  <div className="no-more-articles">
                    <div>More articles and videos coming soon!</div>
                    <div>
                      Reach out to{' '}
                      <a href="mailto:hello@telltail.com" className="link__text tt-hover">
                        hello@telltail.com
                      </a>{' '}
                      if you have specific requests.
                    </div>
                  </div>
                )}
              </button>
            </div>
          </>
        )}
      </Row>
    </div>
  );
};

export default Learn;
