import React, { useState, useMemo, useRef } from 'react';
import { Box, Button } from '@mui/material';
import MaterialReactTable, { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { Delete, Check } from '@mui/icons-material';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import moment from 'moment';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import 'bootstrap/dist/css/bootstrap.min.css';

//hooks
import useQueryCommentList from './__hooks/useQueryCommentList';
import useMutationMarkAsReadConversationOrReply from './__hooks/useMutationMarkAsReadConversationOrReply';
import useMutationDeleteConversationOrReply from './__hooks/useMutationDeleteConversationOrReply';
//types
import { ICommentRequestPayload, IDialogContent, ITableComment } from 'types';
//components
import TTButton from 'components/common/TTButton';
import ActionButton from 'components/common/ActionButton';
import AlertDialog from 'components/common/AlertDialog';
//constants
import { csvOptions } from 'helpers/cvsOptions';
//helpers
import { replaceStringBasedOnObjectKeyValuePairs } from 'helpers/string';

const CommentsTable = ({ active }: { active: { label: string; value: string } }) => {
  const requestPayload = useRef<ICommentRequestPayload | null>(null);
  const [dialogContent, setDialogContent] = React.useState<IDialogContent | null>(null);

  //Query hooks
  const { isError, data, total, isLoading, isFetching } = useQueryCommentList(active.value);
  //Mutation hooks
  const { markAsReadConversationOrReplyMutate, isLoadingMarkAsRead } =
    useMutationMarkAsReadConversationOrReply(setDialogContent);
  const { deleteConversationOrReplyMutate, isDeletingComment } =
    useMutationDeleteConversationOrReply(setDialogContent);

  const columns = useMemo<MRT_ColumnDef<ITableComment>[]>(
    () => [
      {
        header: 'Type',
        accessorKey: 'type',
        maxSize: 10,
      },
      {
        header: 'Author',
        accessorKey: 'author',
        maxSize: 0,
      },
      {
        header: 'Location',
        accessorKey: 'location',
        maxSize: 10,
      },
      {
        header: 'Content',
        accessorKey: 'content',
        maxSize: 10,
        Cell: ({ row }: { row: MRT_Row<ITableComment> }) => {
          const [showFull, setShowFull] = useState(false);

          return (
            <Box
              sx={{
                backgroundColor: '#f5f5f5',
                borderRadius: 2,
                width: 350,
                overflow: 'hidden',
              }}
            >
              <Box sx={{ height: showFull ? 'auto' : 50, px: 1 }}>
                {parse(
                  replaceStringBasedOnObjectKeyValuePairs(
                    row.original?.content?.replace(/href/g, "target='_blank' href") || '',
                    {
                      '<iframe': '<video controls',
                      '</iframe>': '</video>',
                    },
                  ),
                )}
              </Box>
              <Button
                variant="contained"
                fullWidth
                size="small"
                onClick={() => setShowFull(!showFull)}
              >
                {showFull ? 'Show Less' : 'Show more'}
              </Button>
            </Box>
          );
        },
      },
      {
        header: 'Link',
        accessorKey: 'link',
        maxSize: 10,
        Cell: ({ row }: { row: MRT_Row<ITableComment> }) => (
          <Link to={`${row.original.link}`} target="_blank">
            View Community
          </Link>
        ),
      },
      {
        header: 'Posted on',
        accessorKey: 'timestamp',
        maxSize: 10,
      },
    ],
    [],
  );

  const comments: ITableComment[] = useMemo(
    () =>
      (data &&
        data?.map((comment, key) => ({
          number: `${key + 1}`,
          type: comment.type, // key "name" matches `accessorKey` in ColumnDef down below
          author: comment.author,
          location: comment.location,
          content: comment.contents,
          link: `/discuss/group-conversations/${comment.locationId}/${
            comment.conversationId || comment._id
          }`,
          timestamp: moment.utc(comment.createdDate).utcOffset('-0700').format('LLL'),
          comment, // store comment info here to show in detail panel
        }))) ||
      [],
    [data],
  );

  // Download table to CSV format logic
  const csvCommentOptions = csvOptions(
    columns,
    `${active.label}_TellTail_${moment().utc().utcOffset('-0700').format('MMMM_D_YYYY_hh_mm_A')}`,
  );
  const csvExporter = new ExportToCsv(csvCommentOptions);
  const csvData = useMemo(
    () =>
      comments?.map(comment => ({
        type: comment.type,
        author: comment.author,
        location: comment.location,
        content: comment.content,
        link: comment.link,
        timestamp: comment.timestamp,
      })),
    [comments],
  );
  const handleExportData = () => {
    csvExporter.generateCsv(csvData);
  };

  // Handle table actions logic
  const handleMarkAsRead = (row: MRT_Row<ITableComment>) => {
    requestPayload.current = {
      id: row.original.comment._id,
      type: row.original.type,
      actionType: 'mark_as_read',
    };

    markAsReadConversationOrReplyMutate(requestPayload.current);
  };

  const handleDeletePress = (row: MRT_Row<ITableComment>) => {
    requestPayload.current = {
      id: row.original.comment._id,
      type: row.original.type,
      actionType: 'delete',
    };

    setDialogContent({
      title: 'Delete Comment?',
      message: `Are you sure you want to delete this comment by ${row.original.comment.author}? (Note that if the comment you are deleting has replies, those replies will be deleted too)`,
    });
  };

  const renderCommentTableActions = ({ row }: { row: MRT_Row<ITableComment> }) => {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" gap="0.5rem">
        {active.value === 'unread_comments' && (
          <ActionButton
            title="Mark as read"
            Icon={<Check />}
            color="green"
            isLoading={
              isLoadingMarkAsRead &&
              requestPayload.current?.id === row.original.comment._id &&
              requestPayload.current.actionType === 'mark_as_read'
            }
            onClick={() => handleMarkAsRead(row)}
          />
        )}

        {(active.value === 'unread_comments' || active.value === 'read_comments') && (
          <ActionButton title="Delete" Icon={<Delete />} onClick={() => handleDeletePress(row)} />
        )}
      </Box>
    );
  };

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={comments}
        rowCount={total}
        initialState={{ pagination: { pageSize: 10, pageIndex: 0 } }}
        memoMode="cells"
        state={{
          isLoading,
          showAlertBanner: isError,
          showProgressBars: isFetching,
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 20, 50],
        }}
        enableColumnOrdering
        enableGlobalFilter={false}
        renderTopToolbarCustomActions={() => (
          <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', pb: '2rem', flexWrap: 'wrap' }}>
            <TTButton
              className="btn-primary-orchid"
              height={40}
              width={200}
              disabled={isLoading || isFetching}
              text="Export to CSV"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
            />
          </Box>
        )}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={renderCommentTableActions}
        renderDetailPanel={({ row }) => (
          <div style={{ backgroundColor: 'lightblue', padding: 15, borderRadius: 8 }}>
            {parse(
              replaceStringBasedOnObjectKeyValuePairs(
                row.original?.content?.replace(/href/g, "target='_blank' href") || '',
                {
                  '<iframe': '<video controls',
                  '</iframe>': '</video>',
                },
              ),
            )}
          </div>
        )}
      />

      <AlertDialog
        isOpened={Boolean(dialogContent)}
        dialogContent={dialogContent}
        isLoading={isLoadingMarkAsRead || isDeletingComment}
        onAgree={() => {
          if (requestPayload.current) {
            if (requestPayload.current.actionType === 'delete') {
              deleteConversationOrReplyMutate(requestPayload.current);
            }
          }
        }}
        handleClose={() => setDialogContent(null)}
      />
    </>
  );
};

export default CommentsTable;
