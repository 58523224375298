import * as Yup from 'yup';

export const breederFormSchema = {
  breedName: Yup.string().required('This field is required.'),
  minWeight: Yup.number().when('isSizeVary', (isSizeVary, schema) => {
    if (isSizeVary)
      return schema
        .required('This field is required.')
        .typeError('This field is required.')
        .min(5, 'Min weight can not be less than 5 lbs.')
        .max(195, 'Min weight can not be more than 195 lbs.');

    return schema;
  }),
  maxWeight: Yup.number().when('isSizeVary', (isSizeVary, schema) => {
    if (isSizeVary)
      return schema
        .required('This field is required.')
        .typeError('This field is required.')
        .min(10, 'Max weight can not be less than 10 lbs.')
        .max(200, 'Max weight can not be more than 200 lbs.');

    return schema;
  }),
};

export const buyerMatchSettingsByAdminSchema = Yup.object().shape({
  breeds: Yup.array().required('This field is required.'),
  budgetMax: Yup.number()
    .typeError('This field is required.')
    .required('This field is required.')
    .positive('Budget must be a positive number')
    .integer('Budget must be a round number'),
  howSoon: Yup.string().required('This field is required.'),
});
