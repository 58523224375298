import React from 'react';
import {
  Control,
  Controller,
  DeepRequired,
  FieldArrayPath,
  FieldError,
  FieldErrorsImpl,
  Merge,
} from 'react-hook-form';
import { Box, Checkbox } from '@mui/material';
import { Row, Col } from 'react-bootstrap';

//components
import InfoView from 'components/common/InfoView';
//types
import { ICategory, ICreateEditBreedEncyclopaediaSchema } from 'types';

const SelectFilters = ({
  fieldLabel,
  filters,
  selectedFilters,
  fieldName,
  control,
  error,
}: {
  fieldLabel: string;
  filters: ICategory[];
  selectedFilters: string[];
  fieldName: FieldArrayPath<ICreateEditBreedEncyclopaediaSchema>;
  control: Control<ICreateEditBreedEncyclopaediaSchema>;
  error:
    | Merge<FieldError, (Merge<FieldError, FieldErrorsImpl<DeepRequired<ICategory>>> | undefined)[]>
    | undefined;
}) => {
  return (
    <>
      <InfoView
        p={0}
        mt={3}
        label={`${fieldLabel}:`}
        value={
          <Row>
            {filters?.map((filter, idx) => (
              <Col key={filter.slugName + idx} xs={4} md={3}>
                <Controller
                  control={control}
                  name={fieldName}
                  render={({ field: { onChange, value } }) => {
                    const catValue = value as ICategory[];

                    return (
                      <Box display="flex" alignItems="center">
                        <Checkbox
                          id={filter.slugName + idx}
                          defaultChecked={selectedFilters.some(item =>
                            filter.slugName.includes(item),
                          )}
                          onChange={e => {
                            return onChange(
                              e.target.checked
                                ? [...(catValue || []), filter]
                                : catValue?.filter(mFilter => mFilter.slugName !== filter.slugName),
                            );
                          }}
                        />
                        <label htmlFor={filter.slugName + idx}>{filter.name}</label>
                      </Box>
                    );
                  }}
                />
              </Col>
            ))}
          </Row>
        }
        vertical
      />
      {error && <span className="text-error mt-2">{error?.message}</span>}
    </>
  );
};

export default SelectFilters;
