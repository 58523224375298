import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { getConversationsByConversationId } from 'services/api/apiCalls/community';
//types
import { TError, IConversation } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useQueryGetConversationById = (conversationId: string) => {
  const { isLoading, isError, isFetching, error, data } = useQuery<
    AxiosResponse<IConversation>,
    AxiosError<TError>
  >(
    [QUERY_KEYS.COMMUNITY_CONVERSATIONS_BY_ID, conversationId],
    () => getConversationsByConversationId(conversationId),
    {},
  );

  return {
    isFetching,
    isLoading,
    isError,
    error,
    data: data?.data,
  };
};

export default useQueryGetConversationById;
