import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { getPawfficeHourList } from 'services/api/apiCalls/pawfficeHour';
//types
import { IAdminPawfficeHoursResponse, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useQueryPawfficeHourList = () => {
  const { isLoading, data, error } = useQuery<
    AxiosResponse<IAdminPawfficeHoursResponse>,
    AxiosError<TError>
  >(QUERY_KEYS.PAWFFICE_HOUR_LIST, () => getPawfficeHourList({ page: 1, limit: 21 }), {
    keepPreviousData: true,
  });

  return {
    isLoading,
    pawfficeHours:
      data?.data?.items.sort(function (a, b) {
        return new Date(b.startDate).valueOf() - new Date(a.startDate).valueOf();
      }) || [],
    error,
  };
};

export default useQueryPawfficeHourList;
