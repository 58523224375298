import * as Yup from 'yup';

export const NonTTBreederFormSchema = Yup.object().shape({
  breederName: Yup.string().required('This field is required.'),
  breederEmail: Yup.string().email('Invalid email').required('This field is required.'),
  buyerName: Yup.string().required('This field is required.'),
  buyerEmail: Yup.string().email('Invalid email').required('This field is required.'),
  isIdentityVerified: Yup.boolean(),
  isIdValid: Yup.boolean(),
  wasSelfieValid: Yup.boolean(),
  didIdSelfieMatch: Yup.boolean(),
  didIdNameMatch: Yup.boolean(),
});
