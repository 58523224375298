import React from 'react';

//types
import { TBreederProfilePublic } from 'types';
//components
import KitImg from 'components/common/KitImg';
import TTButton from 'components/common/TTButton';
//styles
import './index.scss';
//assets
import avatar from 'assets/images/breederProfile/avatar.png';

const ProfileInfo = ({ breederInfo, isUserProfile, isAuthenticated }: TBreederProfilePublic) => {
  return (
    <div className="profile_info">
      <KitImg
        imgClassName="profile_info_image"
        alt=""
        src={breederInfo.profilePicUrl || avatar}
        kitwidth={200}
      />
      <div className={`breeder-info ${isUserProfile && 'myProfile'}`}>
        <div className="d-flex flex-column flex-wrap justify-content-center">
          <p className="profile_info_name">{breederInfo.displayName || 'N/A'}</p>
          <p className="profile_info_program">{breederInfo.businessProgram || 'N/A'}</p>

          <div className="d-flex justify-content-center">
            <TTButton
              className="btn-primary-orchid profile_info_btn"
              text="Connect +"
              width="100%"
              height="32px"
              fontSize="12px"
              onClick={() => {
                if (isAuthenticated) {
                  window.location.href = `mailto:${breederInfo.email}?cc=matches-measurement@telltail.com&&subject=🐶 I am interested in your breeding program!&body=Hi there!%0D%0A %0D%0AI saw your profile on TellTail and I am really excited to learn more about your breeding program. I would love to connect, so please let me know how to proceed.%0D%0A %0D%0AThanks!`;
                } else {
                  window.open('https://www.telltail.com/join', '_self')?.focus();
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileInfo;
