import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { pinDeal, unpinDeal } from 'services/api/apiCalls/admin';
//types
import { TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useMutationPinDeal = (setDialogContent: (dialogContent: null) => void) => {
  const queryClient = useQueryClient();

  const { mutate: pinDealMutate, isLoading: isPinning } = useMutation<
    AxiosResponse<string>,
    AxiosError<TError>,
    string
  >(dealId => pinDeal(dealId), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.ADMIN_DEAL_LIST, {
        refetchInactive: true,
      });
      setDialogContent(null);
    },
  });

  return {
    pinDealMutate,
    isPinning,
  };
};

export const useMutationUnpinDeal = (setDialogContent: (dialogContent: null) => void) => {
  const queryClient = useQueryClient();

  const { mutate: unpinDealMutate, isLoading: isUnpinning } = useMutation<
    AxiosResponse<string>,
    AxiosError<TError>,
    string
  >(dealId => unpinDeal(dealId), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.ADMIN_DEAL_LIST, {
        refetchInactive: true,
      });
      setDialogContent(null);
    },
  });

  return {
    unpinDealMutate,
    isUnpinning,
  };
};
