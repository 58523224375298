import * as Yup from 'yup';

export const buyerAlgTestingSchema = isProperAddress =>
  Yup.object().shape({
    breeds: Yup.array()
      .required('This field is required.')
      .min(1, 'Please add atleast 1 breed.')
      .max(3, 'You can add maximum 3 breeds.'),
    fullAddress: Yup.string()
      .required(
        'This field is required. Please select a location from the provided dropdown suggestions.',
      )
      .test('isProperAddress', 'Please enter your full street address.', function () {
        return isProperAddress;
      }),
    budgetMax: Yup.object().required('This field is required.'),
    importantFactors: Yup.array(),
    goHomeDate: Yup.date().required('This field is required.').nullable().typeError('Invalid Date'),
    budgetFactor: Yup.number().required('This field is required.'),
    sizeFactor: Yup.number().required('This field is required.'),
    locationFactor: Yup.number().required('This field is required.'),
    goHomeDateFactor: Yup.number().required('This field is required.'),
  });
