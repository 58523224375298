import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

//components
import ReviewQueueTable from './ReviewQueueTable';

const ReviewQueue = () => {
  return (
    <>
      <h3 className="mb-4">Review Queue</h3>
      <ReviewQueueTable />
    </>
  );
};

export default ReviewQueue;
