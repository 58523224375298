import React, { Fragment } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';

//assets
import pencil from 'assets/images/registrationAndSignIn/pencil.png';
//types
import { IAddLitterData, ILitterInfo, ILitter } from 'types';
//components
import ToggleSwitch from 'components/common/ToggleSwitch';

const LitterItem = ({
  litterInfo,
  onReceiveLeadsToggle,
  onAddLitterClick,
  onEditLitterClick,
  onReceiveLeadsForThisBreedToggle,
}: {
  litterInfo: ILitterInfo;
  onReceiveLeadsToggle: (litter: ILitter, isChecked: boolean) => void;
  onAddLitterClick: (addLitterData: IAddLitterData) => void;
  onEditLitterClick: (addLitterData: IAddLitterData) => void;
  onReceiveLeadsForThisBreedToggle: () => void;
}) => {
  return (
    <>
      <div className="litter-item-web">
        <div className="litter">
          <h4 className="litter__title">{litterInfo.breedName}</h4>
          <div
            className="litter__add-button cursor-pointer"
            onClick={() =>
              onAddLitterClick({
                breedName: litterInfo.breedName,
              })
            }
          >
            <span>Add a litter to Improve matches</span>
          </div>
        </div>
        {!isEmpty(litterInfo.litters) && (
          <div className="litters-table">
            <div className="litters-table__row">
              <span className="litters-table__heading flex-grow-1">Go Home</span>
              <span className="litters-table__heading flex-grow-1">Weight</span>
              <span className="litters-table__heading flex-grow-1">Price</span>
              <span className="litters-table__heading flex-grow-1">Receive Leads</span>
              <span className="litters-table__heading flex-grow-0">Edit</span>
            </div>
            <div className="litters-table__divider" />
            {litterInfo.litters.map((litter, index) => (
              <Fragment key={`${litterInfo._id}_${litter._id}_${index}`}>
                <div className="litters-table__row">
                  <span className="litters-table__heading flex-grow-1">
                    {moment(litter.goHomeDate).format('MMM D, YYYY')}
                  </span>
                  <span className="litters-table__heading flex-grow-1">
                    {litter.minWeight}-{litter.maxWeight} lbs
                  </span>
                  <span className="litters-table__heading flex-grow-1">
                    ${litter.minPrice}-{litter.maxPrice}
                  </span>
                  <span className="litters-table__heading flex-grow-1">
                    <ToggleSwitch
                      id={`${litterInfo._id}-${litter._id}-leads`}
                      name={`${litterInfo._id}-${litter._id}-leads`}
                      toggleBG="#142669"
                      onChange={e => onReceiveLeadsToggle(litter, e.target.checked)}
                      checked={litter.acceptLeads}
                    />
                  </span>
                  <span
                    className="litters-table__heading flex-grow-0"
                    onClick={() =>
                      onEditLitterClick({
                        breedName: litterInfo.breedName,
                        litterData: litter,
                      })
                    }
                  >
                    <img src={pencil} alt="edit-image" />
                  </span>
                </div>
                <div
                  className={`litters-table__divider ${
                    !isEmpty(litterInfo.litters) && 'litters-table__divider--none'
                  }`}
                />
              </Fragment>
            ))}
          </div>
        )}
        {isEmpty(litterInfo.litters) && (
          <div className="receive-leads">
            <span className="receive-leads__text">Receive leads for this breed</span>
            <ToggleSwitch
              id={`receive-leads-toggle-for-${litterInfo._id}`}
              name={`receive-leads-toggle-for-${litterInfo._id}`}
              autoComplete="off"
              onChange={() => onReceiveLeadsForThisBreedToggle()}
              checked={litterInfo.isReceiveLeadsForBreed}
            />
          </div>
        )}
      </div>

      {/* Mobile version */}
      <div className="litter-item-mobile">
        <h5>{litterInfo.breedName}</h5>
        {!isEmpty(litterInfo.litters) &&
          litterInfo.litters.map((litter, index) => (
            <div key={`${litterInfo._id}_${litter._id}_${index}`} className="litter-info">
              <div className="litter-content">
                <span className="litter-content__lable">Go Home:</span>
                <span className="litter-content__value">
                  {moment(litter.goHomeDate).format('MMM D, YYYY')}
                </span>
              </div>
              <div className="litter-content">
                <span className="litter-content__lable">Weight:</span>
                <span className="litter-content__value">
                  {litter.minWeight}-{litter.maxWeight} lbs
                </span>
              </div>
              <div className="litter-content">
                <span className="litter-content__lable">Price:</span>
                <span className="litter-content__value">
                  ${litter.minPrice}-{litter.maxPrice}
                </span>
              </div>
              <div className="receive-leads">
                <span className="receive-leads__text">Receive Leads for Litter</span>
                <ToggleSwitch
                  id={`${litterInfo._id}-${litter._id}-leads`}
                  name={`${litterInfo._id}-${litter._id}-leads`}
                  toggleBG="#142669"
                  onChange={e => onReceiveLeadsToggle(litter, e.target.checked)}
                  checked={litter.acceptLeads}
                />
              </div>
              <div
                className="edit-icon"
                onClick={() =>
                  onEditLitterClick({
                    breedName: litterInfo.breedName,
                    litterData: litter,
                  })
                }
              >
                <img src={pencil} alt="edit-image" style={{ width: 16, height: 16 }} />
              </div>
            </div>
          ))}
        <div
          className="litter-item-mobile__add-button"
          onClick={() =>
            onAddLitterClick({
              breedName: litterInfo.breedName,
            })
          }
        >
          <span>Add a litter to Improve matches</span>
        </div>
      </div>
    </>
  );
};

export default LitterItem;
