/* eslint-disable no-console */
import React, { useState, useEffect, Fragment } from 'react';
import { Row } from 'react-bootstrap';
import { isEmpty } from 'lodash';

//components
import KitImg from 'components/common/KitImg';
//styles
import 'bootstrap/dist/css/bootstrap.min.css';

// TODO update with breeder ID logic when we connect everything
const MyGallery = ({
  isUserProfile,
  breederGallery,
}: {
  isUserProfile: boolean;
  breederGallery: string[];
}) => {
  const minimumVisibleImagesCount = 8;

  const [photos, setPhotos] = useState(breederGallery);

  useEffect(() => {
    if (isUserProfile) {
      setPhotos(mPhotos => ['Add Photo', ...mPhotos]);
    } else {
      setPhotos(mPhotos => mPhotos.filter(photo => photo !== 'Add Photo'));
    }
  }, [isUserProfile]);

  if (!isUserProfile && isEmpty(photos)) return <></>;

  return (
    <div id="gallery" className="breederProfile__gallery mt-4">
      <Row className={`breederProfile__galleryContainer m-0 showMore`}>
        <div className="brederProfile__sectionHead">
          <h5 style={{ marginBottom: 0 }}>My Gallery</h5>
        </div>

        <div className="breeder_gallery_images__container">
          {!isEmpty(photos) &&
            photos.map(
              (photo, index) =>
                index < minimumVisibleImagesCount && (
                  <Fragment key={photo + index}>
                    <div className="breeder_gallary_image">
                      <KitImg
                        src={photo}
                        alt="Gallery Photo"
                        imgClassName="kit-image"
                        kitwidth={500}
                      />
                    </div>
                  </Fragment>
                ),
            )}
        </div>
      </Row>
    </div>
  );
};

export default MyGallery;
