import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

//components
import TabPanel from 'components/common/TabPanel';
import DealsTable from './DealsTable';
//constants
import { adminPanelDealsSubTabs } from 'constants/tabs';

const Deals = () => {
  const [active, setActive] = useState(adminPanelDealsSubTabs[0]);

  return (
    <>
      <h3 className="mb-4">Deals</h3>
      <TabPanel tabsList={adminPanelDealsSubTabs} current={active.value} setActive={setActive} />
      <DealsTable active={active} />
    </>
  );
};

export default Deals;
