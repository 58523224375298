/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { Button, DialogActions } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import Select from 'react-select';
import { isEmpty } from 'lodash';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

//constants
import { litterAvailabilities } from 'constants/dropdowns/litterAvailabilities';
//validation
import { AboutBussinessSchema } from './Validation';
//types
import { IAboutBussinessForm, IUpdateBreeder } from 'types';
//hooks
import useMutationAboutBusiness from './__hooks/useMutationAboutBusiness';
//styles
import { selectStyles } from 'components/styled/select';

const AboutBusiness = ({ breeder, setShowSuccess, onCancelPress }: IUpdateBreeder) => {
  const {
    register,
    handleSubmit,
    setError,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<IAboutBussinessForm>({
    resolver: yupResolver(AboutBussinessSchema),
    defaultValues:
      !isEmpty(breeder) && breeder?.aproxAnnualSellPuppies !== undefined
        ? {
            litterAvailability: {
              label: breeder?.litterAvailability,
              value: breeder?.litterAvailability,
            },
            puppiesSoldAnnualy: breeder?.aproxAnnualSellPuppies,
            doesShareInfo: breeder?.isShareInfoWithParents === true ? 'YES' : 'NO',
            haveSellPuppiesToThirdParty: breeder?.isSellPuppyToThirdParty === true ? 'YES' : 'NO',
            askToFillApplication: breeder?.isFillApplication === true ? 'YES' : 'NO',
            doesSignContract: breeder?.isContractSigned === true ? 'YES' : 'NO',
            doesPickPuppiesFromHome: breeder?.isPickPuppyFromHome === true ? 'YES' : 'NO',
            doesStayInTouchForQuestions: breeder?.isStayInTouch === true ? 'YES' : 'NO',
            thirdPartyName: breeder?.thirdPartyName,
            additionalProgramDetails: breeder?.programDetails,
          }
        : {},
  });

  //Mutation hooks
  const { mutate, isLoading } = useMutationAboutBusiness({
    setShowSuccess,
    setError,
  });

  const onSubmit: SubmitHandler<IAboutBussinessForm> = data => {
    const payload = {
      litterAvailability: data.litterAvailability.label,
      aproxAnnualSellPuppies: Number(data.puppiesSoldAnnualy),
      isShareInfoWithParents: data.doesShareInfo === 'YES' ? true : false,
      isSellPuppyToThirdParty: data.haveSellPuppiesToThirdParty === 'YES' ? true : false,
      thirdPartyName: data.thirdPartyName || '',
      isFillApplication: data.askToFillApplication === 'YES' ? true : false,
      isContractSigned: data.doesSignContract === 'YES' ? true : false,
      isPickPuppyFromHome: data.doesPickPuppiesFromHome === 'YES' ? true : false,
      isStayInTouch: data.doesStayInTouchForQuestions === 'YES' ? true : false,
      programDetails: data.additionalProgramDetails || '',
    };

    mutate({ payload, breederId: breeder._id });
  };

  return (
    <>
      <div className="signupOnboarding__inputs">
        {/* GENERAL INFO */}
        <Form onSubmit={handleSubmit(onSubmit)} id="about-business-form">
          <Row>
            <Col xs={12} className={`${errors.litterAvailability ? 'mb-0 input-error' : 'mb-4'}`}>
              <Form.Label>Which best describes your litter availability?*</Form.Label>
              <Controller
                control={control}
                name="litterAvailability"
                render={({ field: { onChange, value } }) => (
                  <Select
                    closeMenuOnSelect={true}
                    isSearchable={true}
                    value={value}
                    onChange={(val: any) => onChange(val)}
                    options={litterAvailabilities}
                    placeholder="Select option"
                    hideSelectedOptions={false}
                    styles={selectStyles}
                    classNamePrefix="tt-select"
                  />
                )}
              />
              {errors && errors.litterAvailability && (
                <p className="mb-2 text-error d-block">{errors.litterAvailability.message}</p>
              )}
            </Col>

            <Col
              xs={12}
              md={8}
              className={`mt-2 ${errors.puppiesSoldAnnualy ? 'mb-0 input-error' : 'mb-4'}`}
            >
              <Form.Group>
                <Form.Label>Approximately how many puppies do you sell annually?*</Form.Label>
                <Form.Control
                  bsPrefix="input form-control mb-0"
                  type="number"
                  placeholder="Enter number of puppies"
                  maxLength={10}
                  {...register('puppiesSoldAnnualy')}
                />
              </Form.Group>
              {errors && errors.puppiesSoldAnnualy && (
                <p className="text-error mb-2">{errors.puppiesSoldAnnualy.message}</p>
              )}
            </Col>

            <Col xs={12} className={`${errors.doesShareInfo ? 'mb-0 input-error' : 'mb-4'} `}>
              <h4>
                Do you share information (photos, health records) about the dam and stud with
                prospective pet parents?*
              </h4>
              <div className="signupOnboarding__listInput">
                <label className="signupOnboarding__radio" htmlFor={'doesShareInfoYes'}>
                  YES
                  <input
                    type="radio"
                    value={'YES'}
                    id="doesShareInfoYes"
                    {...register('doesShareInfo')}
                  />
                  <span></span>
                </label>
                <label className="signupOnboarding__radio" htmlFor={'doesShareInfoNo'}>
                  NO
                  <input
                    type="radio"
                    value={'NO'}
                    id="doesShareInfoNo"
                    {...register('doesShareInfo')}
                  />
                  <span></span>
                </label>
              </div>
              {errors && errors.doesShareInfo && (
                <p className="text-error mb-2">{errors.doesShareInfo.message}</p>
              )}
            </Col>

            <Col
              xs={12}
              className={`${errors.haveSellPuppiesToThirdParty ? 'mb-0 input-error' : 'mb-4'} `}
            >
              <h4>Have you ever sold a puppy to a third-party vendor, such as a pet store?*</h4>
              <div className="signupOnboarding__listInput">
                <label
                  className="signupOnboarding__radio"
                  htmlFor={'haveSellPuppiesToThirdPartyYes'}
                >
                  YES
                  <input
                    type="radio"
                    value={'YES'}
                    id="haveSellPuppiesToThirdPartyYes"
                    {...register('haveSellPuppiesToThirdParty')}
                  />
                  <span></span>
                </label>
                <label
                  className="signupOnboarding__radio"
                  htmlFor={'haveSellPuppiesToThirdPartyNo'}
                >
                  NO
                  <input
                    type="radio"
                    value={'NO'}
                    id="haveSellPuppiesToThirdPartyNo"
                    {...register('haveSellPuppiesToThirdParty')}
                    onClick={() => setValue('thirdPartyName', undefined)}
                  />
                  <span></span>
                </label>
              </div>
              {errors && errors.haveSellPuppiesToThirdParty && (
                <span className="text-error mb-2">
                  {errors.haveSellPuppiesToThirdParty.message}
                </span>
              )}
            </Col>

            {watch('haveSellPuppiesToThirdParty') === 'YES' && (
              <Col
                xs={12}
                md={6}
                className={`mt-3 ${errors.thirdPartyName ? 'mb-0 input-error' : 'mb-4'}`}
              >
                <Form.Group>
                  <Form.Label>Fill Name*</Form.Label>
                  <Form.Control
                    bsPrefix="input form-control mb-0"
                    type="text"
                    placeholder="Enter Name"
                    {...register('thirdPartyName')}
                  />
                </Form.Group>
                {errors && errors.thirdPartyName && (
                  <span className="text-error mb-2">{errors.thirdPartyName.message}</span>
                )}
              </Col>
            )}

            {/* DO YOU TYPICALLY ASK SECTION */}
            <h2 className="mt-3">Do you typically ask pet parents to...</h2>
            <Col
              xs={12}
              md={6}
              className={`${errors.askToFillApplication ? 'mb-0 input-error' : 'mb-4'} `}
            >
              <h4>Fill out an application for a puppy?*</h4>
              <div className="signupOnboarding__listInput">
                <label className="signupOnboarding__radio" htmlFor={'askToFillApplicationYes'}>
                  YES
                  <input
                    type="radio"
                    value={'YES'}
                    id="askToFillApplicationYes"
                    {...register('askToFillApplication')}
                  />
                  <span></span>
                </label>
                <label className="signupOnboarding__radio" htmlFor={'askToFillApplicationNo'}>
                  NO
                  <input
                    type="radio"
                    value={'NO'}
                    id="askToFillApplicationNo"
                    {...register('askToFillApplication')}
                  />
                  <span></span>
                </label>
              </div>
              {errors && errors.askToFillApplication && (
                <p className="text-error mb-2">{errors.askToFillApplication.message}</p>
              )}
            </Col>

            <Col
              xs={12}
              md={6}
              className={`${errors.doesSignContract ? 'mb-0 input-error' : 'mb-4'} `}
            >
              <h4>Sign a contract to purchase a puppy?*</h4>
              <div className="signupOnboarding__listInput">
                <label className="signupOnboarding__radio" htmlFor={'doesSignContractYes'}>
                  YES
                  <input
                    type="radio"
                    value={'YES'}
                    id="doesSignContractYes"
                    {...register('doesSignContract')}
                  />
                  <span></span>
                </label>
                <label className="signupOnboarding__radio" htmlFor={'doesSignContractNo'}>
                  NO
                  <input
                    type="radio"
                    value={'NO'}
                    id="doesSignContractNo"
                    {...register('doesSignContract')}
                  />
                  <span></span>
                </label>
              </div>
              {errors && errors.doesSignContract && (
                <p className="text-error mb-2">{errors.doesSignContract.message}</p>
              )}
            </Col>

            <Col
              xs={12}
              md={6}
              className={`${errors.doesPickPuppiesFromHome ? 'mb-0 input-error' : 'mb-4'} `}
            >
              <h4>Pick up puppies from your home or kennel?*</h4>
              <div className="signupOnboarding__listInput">
                <label className="signupOnboarding__radio" htmlFor={'doesPickPuppiesFromHomeYes'}>
                  YES
                  <input
                    type="radio"
                    value={'YES'}
                    id="doesPickPuppiesFromHomeYes"
                    {...register('doesPickPuppiesFromHome')}
                  />
                  <span></span>
                </label>
                <label className="signupOnboarding__radio" htmlFor={'doesPickPuppiesFromHomeNo'}>
                  NO
                  <input
                    type="radio"
                    value={'NO'}
                    id="doesPickPuppiesFromHomeNo"
                    {...register('doesPickPuppiesFromHome')}
                  />
                  <span></span>
                </label>
              </div>
              {errors && errors.doesPickPuppiesFromHome && (
                <p className="text-error mb-2">{errors.doesPickPuppiesFromHome.message}</p>
              )}
            </Col>

            <Col
              xs={12}
              md={6}
              className={`${errors.doesStayInTouchForQuestions ? 'mb-0 input-error' : 'mb-4'} `}
            >
              <h4>Stay in touch for questions over time?*</h4>
              <div className="signupOnboarding__listInput">
                <label
                  className="signupOnboarding__radio"
                  htmlFor={'doesStayInTouchForQuestionsYes'}
                >
                  YES
                  <input
                    type="radio"
                    value={'YES'}
                    id="doesStayInTouchForQuestionsYes"
                    {...register('doesStayInTouchForQuestions')}
                  />
                  <span></span>
                </label>
                <label
                  className="signupOnboarding__radio"
                  htmlFor={'doesStayInTouchForQuestionsNo'}
                >
                  NO
                  <input
                    type="radio"
                    value={'NO'}
                    id="doesStayInTouchForQuestionsNo"
                    {...register('doesStayInTouchForQuestions')}
                  />
                  <span></span>
                </label>
              </div>
              {errors && errors.doesStayInTouchForQuestions && (
                <p className="text-error mb-2">{errors.doesStayInTouchForQuestions.message}</p>
              )}
            </Col>

            <Col
              xs={12}
              className={`mt-4 ${
                errors.doesStayInTouchForQuestions ? 'mb-0 input-error' : 'mb-4'
              } `}
            >
              <Form.Label>
                Is there anything else about your program that you would like for us to know?
              </Form.Label>
              <Form.Control
                bsPrefix="input form-control mb-0"
                type="number"
                placeholder="Enter Text"
                maxLength={3000}
                as="textarea"
                {...register('additionalProgramDetails')}
              />
              {errors && errors.additionalProgramDetails && (
                <span className="text-error">{errors.additionalProgramDetails.message}</span>
              )}
            </Col>
          </Row>
        </Form>
      </div>

      <DialogActions
        sx={{ position: 'absolute', left: 0, bottom: 0, width: '100%', background: 'white' }}
      >
        <Button onClick={onCancelPress}>Close</Button>
        <Button disabled={isLoading} onClick={handleSubmit(onSubmit)}>
          {isLoading ? (
            <AiOutlineLoading3Quarters
              size={20}
              color="blue"
              style={{ margin: '0.2rem' }}
              className="loading-animation svg-loading"
            />
          ) : (
            'Update'
          )}
        </Button>
      </DialogActions>
    </>
  );
};

export default AboutBusiness;
