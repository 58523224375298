import React, { useState, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import moment from 'moment';

//components
import Banner from 'components/common/HeadingBanner';
import Comments from './Comments';
import RichTextEditor from 'components/common/SunTextEditor';
import CBAC from 'components/common/CBAC';
//configs
import claims from 'configs/claims';
//assets
import chat from 'assets/images/discussion/chat.svg';
import chain from 'assets/images/discussion/chain.svg';
import likeIcon from 'assets/images/discussion/like.svg';
import likedIcon from 'assets/images/discussion/liked.svg';
//hooks
import useQueryVideoDetails from './__hooks/useQueryVideoDetails';
import useMutationLikeDislikeResource from 'pages/DetailsDiscussion/__hooks/useMutationLikeDislikeResource';
import { useQueryKnowledgeTags } from 'pages/BreederAccount/__hooks/useQueryKnowledgeTags';
import useMutationCreateDiscussionReply from 'pages/DetailsDiscussion/__hooks/useMutationCreateDiscussionReply';
//types
import { IReplyPayload } from 'types';
//styles
import './index.scss';

const VideoDetails = () => {
  const navigate = useNavigate();
  const [copyUrlText, setCopyUrlText] = useState('Copy / Share URL');
  const { videoId } = useParams() as { videoId: string };
  const { state } = useLocation() as { state: { videoId: string | undefined } };

  // Query hooks
  const { knowledgeTags } = useQueryKnowledgeTags();
  const { isLoading, isFetching, error, data } = useQueryVideoDetails(state?.videoId || videoId);
  // Mutation hooks
  const { likeDislikeMutate, isLoadingLikeDislike } = useMutationLikeDislikeResource();
  const { createConversationReplyMutate, createConversationReplyIsLoading } =
    useMutationCreateDiscussionReply();

  const video = useMemo(() => data, [data]);
  const knowledgeTagsOptions = knowledgeTags?.map(tag => ({ value: tag, label: tag }));

  if (isLoading) return <AiOutlineLoading3Quarters className="loading-animation svg-loading" />;
  else if (error || !video)
    return (
      <p className="text-no-found" style={{ width: '100%', color: 'red' }}>
        {error?.response?.data.ExceptionMessage}
      </p>
    );
  else {
    const handleNewReply = (payload: IReplyPayload, resetForm: () => void) => {
      const replyObj = {
        ...payload,
        conversationId: video!.conversationId,
        resetForm,
      };
      createConversationReplyMutate(replyObj);
    };

    return (
      <div className="article">
        {/* Banner */}
        <Banner
          title={video.title}
          subTitle={
            <>
              <span>By </span>
              {video.createdBy && (
                <Link
                  to={`/profile/${video.createdBy?._id}`}
                  onClick={() => {
                    window.scrollTo({ top: 0 });
                  }}
                  className="text-light"
                >
                  <span>{video.createdBy?.displayName}</span>
                </Link>
              )}
              <span>{` | ${moment(video.publishStartDate).format('LL')}`}</span>
            </>
          }
          bgColor="blue"
          tags={video.tags}
        >
          <CBAC allowedClaims={[claims.ADMIN]}>
            <div className="reactions-container">
              <div
                className="tt-hover d-flex gap-2 text-decoration-none"
                onClick={() => likeDislikeMutate(video.conversationId)}
              >
                <img src={video.isLiked ? likedIcon : likeIcon} alt="" />
                <span>
                  {isFetching || isLoadingLikeDislike ? (
                    <AiOutlineLoading3Quarters className="loading-animation svg-following" />
                  ) : (
                    `${video.likes} ${video.likes !== 1 ? 'Likes' : 'Like'}`
                  )}
                </span>
              </div>
              <a className="tt-hover d-flex gap-2 text-decoration-none" href="#comments">
                <img className="image-icon" src={chat} alt="" />
                <span>{video?.replies?.length}</span>
                <span>Comments</span>
              </a>
              <a
                className="tt-hover d-flex gap-2 text-decoration-none"
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href);
                  setCopyUrlText('URL is copied to clipboard!');
                  setTimeout(() => {
                    setCopyUrlText('Copy / Share URL');
                  }, 2000);
                }}
              >
                <img className="image-icon" src={chain} alt="" />
                <span>{copyUrlText}</span>
              </a>
            </div>
          </CBAC>
        </Banner>

        {/* Video section */}
        <Container className="article__container">
          {/* Back button */}
          <div
            className="article__back d-flex align-items-center"
            onClick={() => navigate('/learn')}
          >
            <AiOutlineArrowLeft />
            Back to Learn
          </div>

          {/* Video */}
          <div className="article__article">
            <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
              <iframe
                src={`${video.url}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="title"
              />
              <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
          </div>
        </Container>

        {/* Comments */}
        <CBAC allowedClaims={[claims.ADMIN]}>
          <div className="comment-heading-title container mt-5">Comments</div>
          <div className="video-comments container" id="comments">
            {video?.replies?.length === 0 && <div className="text-no-found">No comments yet</div>}

            {video?.replies?.map((reply, idx) => (
              <div key={`${video.conversationId}${reply._id}${idx}`}>
                <Comments
                  item={reply}
                  level={1}
                  isFetching={false}
                  conversationId={reply.conversationId}
                  knowledgeTagsOptions={knowledgeTagsOptions}
                />
              </div>
            ))}

            <RichTextEditor
              containerClassName="video-card-editor-lvl-1"
              fileNamePrefix="conversation"
              replyInfoText="Reply to this article"
              isSending={createConversationReplyIsLoading}
              existingTags={video.tags}
              handleCancelPress={() => {}}
              handleReplyPress={handleNewReply}
              knowledgeTagsOptions={knowledgeTagsOptions}
              isEdit={false}
            />
          </div>
        </CBAC>
      </div>
    );
  }
};

export default VideoDetails;
