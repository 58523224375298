import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { markAsReadConversation, markAsReadReply } from 'services/api/apiCalls/admin';
//types
import { ICommentRequestPayload, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useMutationMarkAsReadConversationOrReply = (
  setDialogContent: (dialogContent: null) => void,
) => {
  const queryClient = useQueryClient();

  const { mutate: markAsReadConversationOrReplyMutate, isLoading: isLoadingMarkAsRead } =
    useMutation<AxiosResponse<string>, AxiosError<TError>, ICommentRequestPayload>(
      data =>
        data.type.toLowerCase() === 'new reply'
          ? markAsReadReply(data.id)
          : markAsReadConversation(data.id),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(QUERY_KEYS.COMMENT_LIST, {
            refetchInactive: true,
          });
          setDialogContent(null);
        },
      },
    );

  return {
    markAsReadConversationOrReplyMutate,
    isLoadingMarkAsRead,
  };
};

export default useMutationMarkAsReadConversationOrReply;
