import { useQuery } from 'react-query';
import { AxiosResponse, AxiosError } from 'axios';

//services
import { getPrototypeDefaultWeights } from 'services/api/apiCalls/buyer';
//types
import { IDefaultMatchWeight, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useQueryDefaultFactors = () => {
  const {
    data,
    error: errorDefaultWeights,
    isLoading: isLoadingDefaultWeights,
  } = useQuery<AxiosResponse<IDefaultMatchWeight[]>, AxiosError<TError>>(
    QUERY_KEYS.PROTOTYPE_DEFAULT_WEIGHTS,
    getPrototypeDefaultWeights,
  );

  return {
    errorDefaultWeights,
    defaultWeights: data?.data,
    isLoadingDefaultWeights,
  };
};
