/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { Button, DialogActions } from '@mui/material';
import { IoIosClose } from 'react-icons/io';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import { PatternFormat } from 'react-number-format';
import { isEmpty } from 'lodash';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

//constants
import { numberOfDams } from 'constants/dropdowns/numberOfDams';
import { timesBreedDamInEightteenMonthPeriod } from 'constants/dropdowns/timesBreedDamInEightteenMonthPeriod';
import { numberOfStuds } from 'constants/dropdowns/numberOfStuds';
import { timesBreedDamInLifetime } from 'constants/dropdowns/timesBreedDamInLifetime';
import { damsLocations } from 'constants/dropdowns/damsLocations';
import { damsSleepLocations } from 'constants/dropdowns/damsSleepLocations';
import { youngestAgeBreedNewDam } from 'constants/dropdowns/youngestAgeBreedNewDam';
import { youngestAgeSendToPetParents } from 'constants/dropdowns/youngestAgeSendToPetParents';
//validation
import { AboutProgramSchema } from './Validation';
//types
import { IAboutProgramForm, IUpdateBreeder } from 'types';
//hooks
import useMutationAboutProgram from './__hooks/useMutationAboutProgram';
import { useQueryBreedsWithSizeVariation } from 'pages/BuyerRegistration/__hooks/useQueryBreedsWithSizeVariation';
//styles
import { multiSelectStyles, selectStyles } from 'components/styled/select';

const AboutPorgram = ({ breeder, setShowSuccess, onCancelPress }: IUpdateBreeder) => {
  const {
    register,
    handleSubmit,
    setError,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<IAboutProgramForm>({
    resolver: yupResolver(AboutProgramSchema),
    defaultValues: !isEmpty(breeder)
      ? {
          breedsInProgram:
            breeder?.breeds && breeder?.breeds.map(item => ({ label: item, value: item })),
          yearsOfExperience: breeder?.yearsOfExperience,
          numberOfDams: {
            label: breeder?.numberOfDams,
            value: breeder?.numberOfDams,
          },
          numberOfStuds: {
            label: breeder?.numberOfStuds,
            value: breeder?.numberOfStuds,
          },
          damsLocation: {
            label: breeder?.damsLocation,
            value: breeder?.damsLocation,
          },
          damsSleepLocation: {
            label: breeder?.damsSleepLocation,
            value: breeder?.damsSleepLocation,
          },
          doesUseStudServices: breeder?.isUseStudServices === true ? 'YES' : 'NO',
          doesSellStudServices: breeder?.isSellStudServices === true ? 'YES' : 'NO',
          youngestAgeSendToPetParents: {
            label: breeder?.minimalPuppyAgeToSend,
            value: breeder?.minimalPuppyAgeToSend,
          },
          youngestAgeBreedNewDam: {
            label: breeder?.minimalDamAgeToBreed,
            value: breeder?.minimalDamAgeToBreed,
          },
          timesBreedDamInEightteenMonthPeriod: {
            label: breeder?.minimalPuppyAgeToSend,
            value: breeder?.minimalPuppyAgeToSend,
          },
          timesBreedDamInLifetime: {
            label: breeder?.damBreedInLifetime,
            value: breeder?.damBreedInLifetime,
          },
          damsFood: breeder?.damFood,
          puppiesFood: breeder?.puppiesFood,
          doesConductTests: breeder?.isHealthTest === true ? 'YES' : 'NO',
          labs: breeder?.labs && breeder?.labs.toString(),
          healthTests: breeder?.healthTests && breeder?.healthTests.toString(),
          doesVaccinatePuppies: breeder?.isVaccinatePuppies === true ? 'YES' : 'NO',
          vaccinationDescription: breeder?.vaccinationDescription,
          doesSocializePuppies: breeder?.isSocializePuppies === true ? 'YES' : 'NO',
          socializeDescription: breeder?.socializeDescription,
          haveVeterinarian: breeder?.isReproductiveVeterinarian === true ? 'YES' : 'NO',
          vetName: breeder?.vetName,
          vetEmail: breeder?.vetEmail,
          vetPhoneNumber: breeder?.vetPhoneNumber,
          additionalInfo: breeder?.breedingPractices,
        }
      : {},
  });

  //Mutation hooks
  const { isLoading, mutate } = useMutationAboutProgram({
    setShowSuccess,
    setError,
  });
  // Query hooks
  const { breeds, isLoadingBreeds, errorBreeds } = useQueryBreedsWithSizeVariation();

  const breedsDropdown = useMemo(
    () =>
      breeds?.map((breed: { breedName: string }) => ({
        label: breed.breedName,
        value: breed.breedName,
      })),
    [breeds],
  );

  const onSubmit: SubmitHandler<IAboutProgramForm> = data => {
    const payload = {
      breeds: data.breedsInProgram ? data.breedsInProgram.map(item => item.label) : [],
      yearsOfExperience: Number(data.yearsOfExperience),
      numberOfDams: data.numberOfDams.label,
      numberOfStuds: data.numberOfStuds.label,
      damsLocation: data.damsLocation.label,
      damsSleepLocation: data.damsSleepLocation?.label || '',
      isUseStudServices: data.doesUseStudServices === 'YES' ? true : false,
      isSellStudServices: data.doesSellStudServices === 'YES' ? true : false,
      minimalPuppyAgeToSend: data.youngestAgeSendToPetParents.label,
      minimalDamAgeToBreed: data.youngestAgeBreedNewDam.label,
      damBreedIn18mPeriod: data.timesBreedDamInEightteenMonthPeriod.label,
      damBreedInLifetime: data.timesBreedDamInLifetime.label,
      damFood: data.damsFood,
      puppiesFood: data.puppiesFood,
      isHealthTest: data.doesConductTests === 'YES' ? true : false,
      labs: data.labs ? data.labs.split(',').map(val => val) : [],
      healthTests: data.healthTests ? data.healthTests.split(',').map(val => val) : [],
      isVaccinatePuppies: data.doesVaccinatePuppies === 'YES' ? true : false,
      vaccinationDescription: data.vaccinationDescription || '',
      isSocializePuppies: data.doesSocializePuppies === 'YES' ? true : false,
      socializeDescription: data.socializeDescription || '',
      isReproductiveVeterinarian: data.haveVeterinarian === 'YES' ? true : false,
      vetName: data.vetName || '',
      vetEmail: data.vetEmail || '',
      vetPhoneNumber: data.vetPhoneNumber?.toString() || '',
      breedingPractices: data.additionalInfo || '',
    };

    mutate({ payload, breederId: breeder._id });
  };

  if (isLoadingBreeds) {
    return (
      <div className="signupOnboarding__inputs">
        <Row>
          <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
        </Row>
      </div>
    );
  } else if (errorBreeds) {
    return null;
  } else
    return (
      <>
        <div className="signupOnboarding__inputs">
          {/* Breeding Experience */}
          <h2 className="mt-0">Breeding Experience</h2>
          <Form onSubmit={handleSubmit(onSubmit)} id="about-program-form">
            <Row className="mt-2">
              <Col
                xs={12}
                md={8}
                className={`${errors.breedsInProgram ? 'mb-0 input-error' : 'mb-4'}`}
              >
                <Form.Label>Breeds in program*</Form.Label>
                <Controller
                  control={control}
                  name="breedsInProgram"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      isSearchable
                      value={value}
                      onChange={(val: any) => onChange(val)}
                      options={breedsDropdown}
                      placeholder={
                        isEmpty(value)
                          ? 'Select breeds in program'
                          : `${value.length} option${value.length > 1 && 's'} selected`
                      }
                      styles={multiSelectStyles}
                      classNamePrefix="tt-select"
                    />
                  )}
                />
                {errors && errors.breedsInProgram && (
                  <p className="text-error mb-2">{errors.breedsInProgram.message}</p>
                )}
              </Col>

              <Col xs={12} md={4}>
                <Form.Label>Years of experience?*</Form.Label>
                <Form.Control
                  bsPrefix={`input form-control ${
                    errors.yearsOfExperience ? 'mb-0 input-error' : 'mb-4'
                  }  `}
                  type="number"
                  placeholder="Enter Years"
                  maxLength={10}
                  {...register('yearsOfExperience')}
                />
                {errors && errors.yearsOfExperience && (
                  <p className="text-error mb-1">{errors.yearsOfExperience.message}</p>
                )}
              </Col>
            </Row>

            {/* BREEDING PROGRAM PART 1: DAMS AND STUDS */}
            <h2 className="mt-3">In your program...</h2>
            <Row>
              <Col
                xs={12}
                md={6}
                className={`${errors.numberOfDams ? 'mb-0 input-error' : 'mb-4'} `}
              >
                <Form.Label>How many dams do you have?*</Form.Label>
                <Controller
                  control={control}
                  name="numberOfDams"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      closeMenuOnSelect={true}
                      isSearchable={true}
                      value={value}
                      onChange={(val: any) => onChange(val)}
                      options={numberOfDams}
                      placeholder="- Dams"
                      hideSelectedOptions={false}
                      styles={selectStyles}
                      classNamePrefix="tt-select"
                    />
                  )}
                />
                {errors && errors.numberOfDams && (
                  <p className="mb-2 text-error">{errors.numberOfDams.message}</p>
                )}
              </Col>

              <Col
                xs={12}
                md={6}
                className={`${errors.numberOfStuds ? 'mb-0 input-error' : 'mb-4'} `}
              >
                <Form.Label>How many studs do you have?*</Form.Label>
                <Controller
                  control={control}
                  name="numberOfStuds"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      closeMenuOnSelect={true}
                      isSearchable={true}
                      value={value}
                      onChange={(val: any) => onChange(val)}
                      options={numberOfStuds}
                      placeholder="- Studs"
                      hideSelectedOptions={false}
                      styles={selectStyles}
                      classNamePrefix="tt-select"
                    />
                  )}
                />
                {errors && errors.numberOfStuds && (
                  <p className="mb-2 text-error">{errors.numberOfStuds.message}</p>
                )}
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6} className="signupOnboarding__form">
                <Form.Group className="mb-0">
                  <Form.Label>
                    We know that breeding practices are different for most breeders, please feel
                    free to use this field to enter any additional breeding-related information
                  </Form.Label>
                  <Form.Label className="sublabel">
                    E.g. are your dams in guardian homes bred with a different frequency than those
                    living with you? Is there something not being captured accurately based on the
                    options above?, etc.
                  </Form.Label>

                  <Form.Control
                    bsPrefix="input form-control mb-0"
                    type="text"
                    as="textarea"
                    placeholder="Enter text"
                    maxLength={1000}
                    {...register('additionalInfo')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Col xs={12} className={`mt-4 ${errors.damsLocation ? 'mb-0 input-error' : 'mb-4'} `}>
              <Form.Label>Where do your dams live?*</Form.Label>
              <Controller
                control={control}
                name="damsLocation"
                render={({ field: { onChange, value } }) => (
                  <Select
                    closeMenuOnSelect={true}
                    isSearchable={true}
                    value={value}
                    onChange={(val: any) => onChange(val)}
                    options={damsLocations}
                    placeholder="Select Option"
                    hideSelectedOptions={false}
                    styles={selectStyles}
                    classNamePrefix="tt-select"
                  />
                )}
              />
              {errors && errors.damsLocation && (
                <p className="mb-2 text-error">{errors.damsLocation.message}</p>
              )}
            </Col>
            {watch('damsLocation')?.value === damsLocations[2].value && (
              <Col xs={12} className={`${errors.damsSleepLocation ? 'mb-0 input-error' : 'mb-4'} `}>
                <Form.Label>For those kept on site, where do they sleep?*</Form.Label>
                <Controller
                  control={control}
                  name="damsSleepLocation"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      closeMenuOnSelect={true}
                      isSearchable={true}
                      value={value}
                      onChange={(val: any) => onChange(val)}
                      options={damsSleepLocations}
                      placeholder="Select Option"
                      hideSelectedOptions={false}
                      styles={selectStyles}
                      classNamePrefix="tt-select"
                    />
                  )}
                />
                {errors && errors.damsSleepLocation && (
                  <span className="mb-2 text-error">{errors.damsSleepLocation.message}</span>
                )}
              </Col>
            )}

            {/* BREEDING PROGRAM PART 2: STUDS SERVICES */}
            <Row>
              <Col
                xs={12}
                md={6}
                className={`${errors.doesUseStudServices ? 'mb-0 input-error' : 'mb-4'} `}
              >
                <h4>Do you use the stud services of other breeders as part of your program?*</h4>
                <div className="signupOnboarding__listInput">
                  <label className="signupOnboarding__radio" htmlFor={'useStudYes'}>
                    YES
                    <input
                      type="radio"
                      value={'YES'}
                      id="useStudYes"
                      {...register('doesUseStudServices')}
                    />
                    <span></span>
                  </label>
                  <label className="signupOnboarding__radio" htmlFor={'useStudNo'}>
                    NO
                    <input
                      type="radio"
                      value={'NO'}
                      id="useStudNo"
                      {...register('doesUseStudServices')}
                    />
                    <span></span>
                  </label>
                </div>
                {errors && errors.doesUseStudServices && (
                  <p className="mb-2 text-error">{errors.doesUseStudServices.message}</p>
                )}
              </Col>

              <Col
                xs={12}
                md={6}
                className={`${errors.doesSellStudServices ? 'mb-0 input-error' : 'mb-4'} `}
              >
                <h4>Do you sell stud services?*</h4>
                <div className="signupOnboarding__listInput">
                  <label className="signupOnboarding__radio" htmlFor={'sellStudYes'}>
                    YES
                    <input
                      type="radio"
                      value="YES"
                      id="sellStudYes"
                      {...register('doesSellStudServices')}
                    />
                    <span></span>
                  </label>
                  <label className="signupOnboarding__radio" htmlFor={'sellStudNo'}>
                    NO
                    <input
                      type="radio"
                      value="NO"
                      id="sellStudNo"
                      {...register('doesSellStudServices')}
                    />
                    <span></span>
                  </label>
                </div>
                {errors && errors.doesSellStudServices && (
                  <span className="mb-2 text-error">{errors.doesSellStudServices.message}</span>
                )}
              </Col>
            </Row>

            {/* WHAT IS YOUNGEST AGE YOU WILL SECTION */}
            <h2 className="mt-3"> What is the youngest age you will...</h2>
            <Row>
              <Col
                xs={12}
                md={6}
                className={`${errors.youngestAgeSendToPetParents ? 'mb-0 input-error' : 'mb-4'} `}
              >
                <Form.Label>Send a puppy home to new pet parents?*</Form.Label>
                <Controller
                  control={control}
                  name="youngestAgeSendToPetParents"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      closeMenuOnSelect={true}
                      isSearchable={true}
                      value={value}
                      onChange={(val: any) => onChange(val)}
                      options={youngestAgeSendToPetParents}
                      placeholder="- years old"
                      hideSelectedOptions={false}
                      styles={selectStyles}
                      classNamePrefix="tt-select"
                    />
                  )}
                />
                {errors && errors.youngestAgeSendToPetParents && (
                  <p className="mb-2 text-error">{errors.youngestAgeSendToPetParents.message}</p>
                )}
              </Col>

              <Col
                xs={12}
                md={6}
                className={`${errors.youngestAgeBreedNewDam ? 'mb-0 input-error' : 'mb-4'} `}
              >
                <Form.Label>Breed a new dam?*</Form.Label>
                <Controller
                  control={control}
                  name="youngestAgeBreedNewDam"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      closeMenuOnSelect={true}
                      isSearchable={true}
                      value={value}
                      onChange={(val: any) => onChange(val)}
                      options={youngestAgeBreedNewDam}
                      placeholder="- years old"
                      hideSelectedOptions={false}
                      styles={selectStyles}
                      classNamePrefix="tt-select"
                    />
                  )}
                />
                {errors && errors.youngestAgeBreedNewDam && (
                  <p className="mb-2 text-error">{errors.youngestAgeBreedNewDam.message}</p>
                )}
              </Col>
            </Row>

            {/* HOW MANY TIMES DO YOU BREED EACH DAM SECTION */}
            <h2 className="mt-3">How many times do you breed each dam...</h2>
            <Row>
              <Col
                xs={12}
                md={6}
                className={`${
                  errors.timesBreedDamInEightteenMonthPeriod ? 'mb-0 input-error' : 'mb-4'
                } `}
              >
                <Form.Label>In an 18 month period?*</Form.Label>
                <Controller
                  control={control}
                  name="timesBreedDamInEightteenMonthPeriod"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      closeMenuOnSelect={true}
                      isSearchable={true}
                      value={value}
                      onChange={(val: any) => onChange(val)}
                      options={timesBreedDamInEightteenMonthPeriod}
                      placeholder="- times"
                      hideSelectedOptions={false}
                      styles={selectStyles}
                      classNamePrefix="tt-select"
                    />
                  )}
                />
                {errors && errors.timesBreedDamInEightteenMonthPeriod && (
                  <p className="mb-2 text-error">
                    {errors.timesBreedDamInEightteenMonthPeriod.message}
                  </p>
                )}
              </Col>

              <Col
                xs={12}
                md={6}
                className={`${errors.timesBreedDamInLifetime ? 'mb-0 input-error' : 'mb-4'} `}
              >
                <Form.Label>In her lifetime?*</Form.Label>
                <Controller
                  control={control}
                  name="timesBreedDamInLifetime"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      closeMenuOnSelect={true}
                      isSearchable={true}
                      value={value}
                      onChange={(val: any) => onChange(val)}
                      options={timesBreedDamInLifetime}
                      placeholder="- times"
                      hideSelectedOptions={false}
                      styles={selectStyles}
                      classNamePrefix="tt-select"
                    />
                  )}
                />
                {errors && errors.timesBreedDamInLifetime && (
                  <p className="mb-2 text-error ">{errors.timesBreedDamInLifetime.message}</p>
                )}
              </Col>
            </Row>

            {/* FOOD SECTION */}
            <h2 className="mt-3">What food do you feed your...</h2>
            <Row>
              <Col xs={12} md={6} className={`${errors.damsFood ? 'mb-0 input-error' : 'mb-4'}`}>
                <Form.Label>Dams?*</Form.Label>
                <Form.Control
                  bsPrefix={`input form-control mb-0`}
                  type="text"
                  placeholder="Enter text"
                  {...register('damsFood')}
                />
                {errors && errors.damsFood && (
                  <p className="text-error mb-2">{errors.damsFood.message}</p>
                )}
              </Col>

              <Col xs={12} md={6} className={`${errors.puppiesFood ? 'mb-0 input-error' : 'mb-4'}`}>
                <Form.Label>Puppies?*</Form.Label>
                <Form.Control
                  bsPrefix={`input form-control mb-0`}
                  type="text"
                  placeholder="Enter text"
                  {...register('puppiesFood')}
                />
                {errors && errors.puppiesFood && (
                  <p className="text-error mb-2">{errors.puppiesFood.message}</p>
                )}
              </Col>
            </Row>

            {/* DO YOU SECTION PART 1: TESTS */}
            <h2 className="mb-1 mt-3">Do you...</h2>
            <Row>
              <Col
                xs={12}
                md={6}
                className={`${errors.doesConductTests ? 'mb-0 input-error' : 'mb-4'}`}
              >
                <h4>Conduct health and/or genetic tests on your dogs?*</h4>
                <div className="signupOnboarding__listInput">
                  <label className="signupOnboarding__radio" htmlFor={'conductTestsYes'}>
                    YES
                    <input
                      type="radio"
                      value="YES"
                      id="conductTestsYes"
                      {...register('doesConductTests')}
                    />
                    <span></span>
                  </label>
                  <label className="signupOnboarding__radio" htmlFor={'conductTestsNo'}>
                    NO
                    <input
                      type="radio"
                      value="NO"
                      id="conductTestsNo"
                      {...register('doesConductTests')}
                    />
                    <span></span>
                  </label>
                </div>
                {errors && errors.doesConductTests && (
                  <p className="mb-2 text-error">{errors.doesConductTests.message}</p>
                )}

                {watch('doesConductTests') === 'YES' && (
                  <>
                    <Col xs={12} className={`mt-3 ${errors.labs ? 'mb-0 input-error' : 'mb-4'}`}>
                      <Form.Label>What labs do you use to conduct testing?*</Form.Label>
                      <Form.Label className="sublabel">(Enter text separated by commas)</Form.Label>
                      <Form.Control
                        bsPrefix={`input form-control ${errors.labs && 'mb-0 input-error'} `}
                        type="text"
                        placeholder="Enter text"
                        {...register('labs')}
                      />
                      {errors && errors.labs && (
                        <p className=" mb-2 text-error">{errors.labs.message}</p>
                      )}
                    </Col>

                    <ul className="signupOnboarding__tags">
                      {watch('labs') &&
                        watch('labs')
                          ?.split(',')
                          .map((item: string, idx) => (
                            <li key={idx}>
                              {item}
                              <IoIosClose
                                className="signupOnboading__deleteIcon"
                                onClick={() => {
                                  const currentValue = watch('labs')?.split(',');
                                  const newValue = currentValue?.filter(value => value !== item);
                                  setValue('labs', newValue?.toString());
                                }}
                              />
                            </li>
                          ))}
                    </ul>

                    <Col
                      xs={12}
                      className={`mt-3 ${errors.healthTests ? 'mb-0 input-error' : 'mb-4'}`}
                    >
                      <Form.Label>What health tests do you conduct?*</Form.Label>
                      <Form.Label className="sublabel">
                        You can either list individual tests or feel free to bucket tests with
                        phrasings such as &quot;Embark recommended breed panel + supplemental&quot;,
                        &quot;Wisdom Panel Essential&quot;, etc. (Enter text separated by commas)
                      </Form.Label>
                      <Form.Control
                        bsPrefix={`input form-control ${errors.healthTests && 'mb-0 input-error'} `}
                        type="text"
                        placeholder="Enter text"
                        {...register('healthTests')}
                      />
                      {errors && errors.healthTests && (
                        <p className="mb-2 text-error">{errors.healthTests.message}</p>
                      )}
                    </Col>

                    <ul className="signupOnboarding__tags">
                      {watch('healthTests') &&
                        watch('healthTests')
                          ?.split(',')
                          .map((item: string, idx) => (
                            <li key={idx}>
                              {item}
                              <IoIosClose
                                className="signupOnboading__deleteIcon"
                                onClick={() => {
                                  const currentValue = watch('healthTests')?.split(',');
                                  const newValue = currentValue?.filter(value => value !== item);
                                  setValue('healthTests', newValue?.toString());
                                }}
                              />
                            </li>
                          ))}
                    </ul>
                  </>
                )}
              </Col>

              {/* DO YOU SECTION PART 2: SOCIALIZE */}
              <Col
                xs={12}
                md={6}
                className={`${errors.doesSocializePuppies ? 'mb-0 input-error' : 'mb-4'}`}
              >
                <h4>Socialize your puppies before they are sent home?*</h4>
                <div className="signupOnboarding__listInput">
                  <label className="signupOnboarding__radio" htmlFor={'socializePuppiesYes'}>
                    YES
                    <input
                      type="radio"
                      value="YES"
                      id="socializePuppiesYes"
                      {...register('doesSocializePuppies')}
                    />
                    <span></span>
                  </label>
                  <label className="signupOnboarding__radio" htmlFor={'socializePuppiesNo'}>
                    NO
                    <input
                      type="radio"
                      value="NO"
                      id="socializePuppiesNo"
                      {...register('doesSocializePuppies')}
                    />
                    <span></span>
                  </label>
                </div>
                {errors && errors.doesSocializePuppies && (
                  <p className="mb-2 text-error">{errors.doesSocializePuppies.message}</p>
                )}

                {watch('doesSocializePuppies') === 'YES' && (
                  <Col
                    xs={12}
                    className={`mt-2 ${errors.socializeDescription ? 'mb-0 input-error' : 'mb-4'}`}
                  >
                    <Form.Label>Please describe</Form.Label>
                    <Form.Control
                      bsPrefix={`input form-control ${
                        errors.socializeDescription && 'mb-0 input-error'
                      } `}
                      type="text"
                      placeholder="Enter text"
                      as="textarea"
                      maxLength={1000}
                      {...register('socializeDescription')}
                    />
                    {errors && errors.socializeDescription && (
                      <p className="mb-2 text-error">{errors.socializeDescription.message}</p>
                    )}
                  </Col>
                )}
              </Col>

              {/* DO YOU SECTION PART 3: VACCINATE */}
              <Col
                xs={12}
                md={6}
                className={`${errors.doesVaccinatePuppies ? 'mb-0 input-error' : 'mb-4'}`}
              >
                <h4>Vaccinate puppies before they go home?*</h4>
                <div className="signupOnboarding__listInput">
                  <label className="signupOnboarding__radio" htmlFor={'vaccinatePuppiesYes'}>
                    YES
                    <input
                      type="radio"
                      value="YES"
                      id="vaccinatePuppiesYes"
                      {...register('doesVaccinatePuppies')}
                    />
                    <span></span>
                  </label>
                  <label className="signupOnboarding__radio" htmlFor={'vaccinatePuppiesNo'}>
                    NO
                    <input
                      type="radio"
                      value="NO"
                      id="vaccinatePuppiesNo"
                      {...register('doesVaccinatePuppies')}
                    />
                    <span></span>
                  </label>
                </div>
                {errors && errors.doesVaccinatePuppies && (
                  <p className="mb-2 text-error">{errors.doesVaccinatePuppies.message}</p>
                )}

                {watch('doesVaccinatePuppies') === 'YES' && (
                  <Col
                    xs={12}
                    className={`mt-2 small ${
                      errors.vaccinationDescription ? 'mb-0 input-error' : 'mb-4'
                    }`}
                  >
                    <Form.Label>Please describe</Form.Label>
                    <Form.Control
                      bsPrefix={`input form-control ${
                        errors.vaccinationDescription && 'mb-0 input-error'
                      } `}
                      type="text"
                      placeholder="Enter text"
                      as="textarea"
                      maxLength={1000}
                      {...register('vaccinationDescription')}
                    />
                    {errors && errors.vaccinationDescription && (
                      <p className="mb-2 text-error">{errors.vaccinationDescription.message}</p>
                    )}
                  </Col>
                )}
              </Col>

              {/* DO YOU SECTION PART 4: VETERINARIAN */}
              <Col
                xs={12}
                md={6}
                className={`${errors.haveVeterinarian ? 'mb-0 input-error' : 'mb-4'}`}
              >
                <h4>Have a reproductive veterinarian?*</h4>
                <div className="signupOnboarding__listInput">
                  <label className="signupOnboarding__radio" htmlFor={'haveVeterinarianYes'}>
                    YES
                    <input
                      type="radio"
                      value="YES"
                      id="haveVeterinarianYes"
                      {...register('haveVeterinarian')}
                    />
                    <span />
                  </label>
                  <label className="signupOnboarding__radio" htmlFor={'haveVeterinarianNo'}>
                    NO
                    <input
                      type="radio"
                      value="NO"
                      id="haveVeterinarianNo"
                      {...register('haveVeterinarian')}
                    />
                    <span />
                  </label>
                </div>
                {errors && errors.haveVeterinarian && (
                  <p className="mb-2 text-error">{errors.haveVeterinarian.message}</p>
                )}

                {watch('haveVeterinarian') === 'YES' && (
                  <Col className="mt-2">
                    <h4>Please share name and contact info if comfortable:</h4>
                    <Form.Group className={`${errors.vetName ? 'mb-0 input-error' : 'mb-3'}`}>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        bsPrefix={`input form-control ${errors.vetName && 'mb-0 input-error'} `}
                        type="text"
                        placeholder="Enter vet name"
                        {...register('vetName')}
                      />
                    </Form.Group>
                    <Form.Group className={`${errors.vetName ? 'mb-0 input-error' : 'mb-3'}`}>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        bsPrefix={`input form-control ${errors.vetName && 'mb-0 input-error'} `}
                        type="text"
                        placeholder="Enter vet email"
                        {...register('vetEmail')}
                      />
                    </Form.Group>
                    <Form.Group className={`${errors.vetName ? 'mb-0 input-error' : 'mb-3'}`}>
                      <Form.Label>Phone number</Form.Label>
                      <div style={{ display: 'flex' }}>
                        <span
                          style={{
                            background: '#ddd',
                            padding: 12,
                            alignSelf: 'flex-start',
                            marginTop: 3,
                            fontSize: 14,
                            borderRadius: 10,
                            marginRight: 10,
                            color: '#333',
                          }}
                        >
                          US +1
                        </span>
                        <div style={{ flex: 1 }}>
                          <Controller
                            control={control}
                            name="vetPhoneNumber"
                            render={({ field: { onChange, name, value } }) => (
                              <PatternFormat
                                placeholder="Enter vet phone number"
                                className={`input form-control ${
                                  errors.vetName && 'mb-0 input-error'
                                }`}
                                format="(###) ###-####"
                                mask="_"
                                name={name}
                                value={value}
                                onChange={onChange}
                              />
                            )}
                          />
                          {errors && errors.vetPhoneNumber && (
                            <p className="text-error">{errors.vetPhoneNumber.message}</p>
                          )}
                        </div>
                      </div>
                    </Form.Group>
                  </Col>
                )}
              </Col>
            </Row>
          </Form>
        </div>
        <DialogActions sx={{ position: 'absolute', bottom: 0, width: '100%', background: 'white' }}>
          <Button onClick={onCancelPress}>Close</Button>
          <Button disabled={isLoading} onClick={handleSubmit(onSubmit)}>
            {isLoading ? (
              <AiOutlineLoading3Quarters
                size={20}
                color="blue"
                style={{ margin: '0.2rem' }}
                className="loading-animation svg-loading"
              />
            ) : (
              'Update'
            )}
          </Button>
        </DialogActions>
      </>
    );
};

export default AboutPorgram;
