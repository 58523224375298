import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import Select from 'react-select';

//components
import InfoView from 'components/common/InfoView';
//hooks
import useMutationUpdateShopifyUnprocessed from '../__hooks/useMutationUpdateShopifyUnprocessed';
//types
import { IDialogProps, IUpdateShopifyUnprocessedSchema } from 'types';
//validation
import { UpdateUnprocessedSchema } from './Validation';
//styles
import { selectStyles } from 'components/styled/select';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddNewArticleDialog({
  open,
  handleClose,
  unprocessedDetails,
  users,
}: IDialogProps) {
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IUpdateShopifyUnprocessedSchema>({
    resolver: yupResolver(UpdateUnprocessedSchema),
    defaultValues: {
      id: unprocessedDetails?.id,
      orderId: unprocessedDetails?.orderId,
      contactEmailShopify: unprocessedDetails?.contactEmailShopify,
    },
  });

  const handleDialogDismiss = () => {
    reset();
    resetApiState();
    handleClose();
  };

  //Mutation hooks
  const {
    mutate,
    isLoading,
    reset: resetApiState,
  } = useMutationUpdateShopifyUnprocessed(handleDialogDismiss);

  const onSubmit = async (data: IUpdateShopifyUnprocessedSchema) => {
    const payload = {
      breederId: data.breeder.id,
      webhookId: data.id,
    };

    return mutate({ ...payload });
  };

  return (
    <div>
      <Dialog
        maxWidth="lg"
        open={open}
        TransitionComponent={Transition}
        onClose={handleDialogDismiss}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle bgcolor="darkblue" color="white">
          Connect with correct user
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            width="50vw"
            height="70vh"
            m={2}
            position="relative"
          >
            <Box>
              <Form.Label>Internal ID</Form.Label>
              <Form.Control
                bsPrefix="input form-control mb-0 py-3"
                placeholder="Enter internal ID"
                {...register('id')}
              />
            </Box>
            {errors && errors.id && <span className="text-error mt-2">{errors.id.message}</span>}

            <Box mt={3}>
              <Form.Label>Shopify Order ID</Form.Label>
              <Form.Control
                bsPrefix="input form-control mb-0 py-3"
                placeholder="Enter shopify order ID"
                {...register('orderId')}
              />
            </Box>
            {errors && errors.orderId && (
              <span className="text-error mt-2">{errors.orderId.message}</span>
            )}

            <Box mt={3}>
              <Form.Label>Shopify Email</Form.Label>
              <Form.Control
                bsPrefix="input form-control mb-0 py-3"
                placeholder="Enter shopify email"
                {...register('contactEmailShopify')}
              />
            </Box>
            {errors && errors.contactEmailShopify && (
              <span className="text-error mt-2">{errors.contactEmailShopify.message}</span>
            )}

            <InfoView
              p={0}
              mt={3}
              label="Breeder Name"
              zIndex={102}
              value={
                <Controller
                  control={control}
                  name="breeder"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      isSearchable
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onChange={(val: any) => onChange(val)}
                      value={value}
                      options={users?.map(item => ({
                        label: `${item.email} - ${item.displayName}`,
                        value: `${item.email} - ${item.displayName}`,
                        id: item._id,
                      }))}
                      placeholder="Enter for which roles this article will be locked"
                      styles={selectStyles}
                      classNamePrefix="tt-select"
                    />
                  )}
                />
              }
              vertical
            />
            {errors && errors.breeder && (
              <span className="text-error">{errors.breeder.message}</span>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" sx={{ bgcolor: 'lightgray' }} onClick={handleDialogDismiss}>
            Cancel
          </Button>
          <Button variant="contained" disabled={isLoading} onClick={handleSubmit(onSubmit)}>
            {isLoading ? (
              <AiOutlineLoading3Quarters
                size={20}
                color="blue"
                style={{ margin: '0.2rem' }}
                className="loading-animation svg-loading"
              />
            ) : (
              'Connect'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
