/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useEffect } from 'react';
import SunEditor from 'suneditor-react';
import SunEditorCore from 'suneditor/src/lib/core';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { BsEmojiSmile } from 'react-icons/bs';
import { Form } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

//types
import { IRichTextEditor, IReplySchema } from 'types';
//constants
import sortedFontOptions from 'constants/dropdowns/sunEditorFontOptions';
//components
import TTButton from 'components/common/TTButton';
//hooks
import useMutationUploadFile from './__hooks/useMutationUploadFile';
//validation
import { ReplySchema } from './Validation';
//styles
import styles from './index.module.scss';
import './index.scss';
import { multiSelectStyles } from 'components/styled/select';
//helpers
import EmojiPicker from 'helpers/emojiPicker';
//services
import { getCloudflareUploadUrl } from 'services/api/apiCalls/cloudflare';

export default function SunRTE({
  placeholder,
  fileNamePrefix,
  containerClassName,
  replyInfoText,
  existingTags,
  isSending = false,
  handleCancelPress,
  handleReplyPress,
  knowledgeTagsOptions,
  onEditorStateChange,
  editorState,
  isEdit,
}: IRichTextEditor) {
  const TAG_LIMIT = 4;
  const [editorValue, setEditorValue] = useState(editorState);

  const editor = useRef<SunEditorCore>();
  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };

  useEffect(() => {
    new EmojiPicker({
      trigger: [
        {
          selector: '.emoji-btn',
          insertInto: '.two',
        },
        {
          selector: '.emoji-btn-v2',
          insertInto: '.two',
        },
      ],
      closeButton: true,
      content: editorValue,
      editor: editor,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getReplyButtonText = (isLoading: boolean) => {
    if (isLoading) return <AiOutlineLoading3Quarters className="loading-animation svg-loading" />;
    else if (isEdit) return 'Update';
    else return 'Post';
  };

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    setError,
    control,
    trigger,
    reset,
    formState: { errors },
  } = useForm<IReplySchema>({
    resolver: yupResolver(ReplySchema),
    defaultValues: {
      tags:
        existingTags &&
        existingTags.map(tag => ({
          label: tag,
          value: tag,
        })),
    },
  });

  // Mutation hooks
  const { uploadFileMutate, isUploadingFile } = useMutationUploadFile();

  // Upload image to Cloudflare and then add the returned URL to the editor
  const handleImageUploadBefore = (files: any, info: any, uploadHandler: any) => {
    const fileName = `${fileNamePrefix}_photo_${Date.now()}.png`;

    getCloudflareUploadUrl().then(res => {
      if (res !== null) {
        uploadFileMutate({
          image: files[0],
          pathAndName: `testPhotos/${fileName}`,
          url: res,
          uploadHandler: uploadHandler,
        });
      }
    });
  };

  // Update the editor value in the form and editor state
  const updateEditorValue = (content: string) => {
    register('contents');
    setValue('contents', content, {
      shouldValidate: true,
      shouldDirty: true,
    });

    setEditorValue(content);
    onEditorStateChange && onEditorStateChange(content);
  };

  // On submit, reset the form and clear the editor
  const onSubmit = (data: IReplySchema) => {
    const resetForm = () => {
      setEditorValue('');
      reset();
    };

    const tags = data.tags.map(tag => tag.value);
    const updatedData = { ...data, contents: watch('contents') };

    return handleReplyPress && handleReplyPress({ ...updatedData, tags }, resetForm);
  };

  return (
    <div className={`${styles.root} ${containerClassName && containerClassName}`}>
      <TTButton
        type="button"
        className="emoji-btn"
        width="50px"
        height="40px"
        text={<BsEmojiSmile className="emoji-btn-v2" />}
      />

      <SunEditor
        getSunEditorInstance={getSunEditorInstance}
        setContents={editorValue}
        setOptions={{
          buttonList: [
            ['undo', 'redo'],
            ['font', 'fontSize'],
            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
            ['fontColor', 'hiliteColor'],
            ['align', 'list', 'lineHeight'],
            ['outdent', 'indent'],
            ['table', 'horizontalRule', 'link', 'image'],
            ['print'],
          ],
          defaultTag: 'div',
          minHeight: '300px',
          showPathLabel: false,
          font: sortedFontOptions,
          placeholder: placeholder,
          resizeEnable: true,
          className: 'two',
        }}
        onImageUploadBefore={handleImageUploadBefore}
        onChange={updateEditorValue}
      />
      {Boolean(replyInfoText) && errors && errors.contents && (
        <p className="text-error">{errors.contents.message}</p>
      )}
      {Boolean(replyInfoText) && (
        <>
          <Form.Group className="mt-3" controlId="formBasicTags">
            <Form.Label bsPrefix={styles.inputLabelTag}>
              Add tags to help others find your post (press space to add the tag)
            </Form.Label>
            <Controller
              control={control}
              name="tags"
              render={({ field: { onChange, value } }) => (
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  isSearchable
                  value={value}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(val: any, actionMeta: any) => {
                    if (
                      watch('tags') &&
                      watch('tags').length >= TAG_LIMIT &&
                      actionMeta.action === 'select-option'
                    ) {
                      setError('tags', {
                        type: 'custom',
                        message: ' The maximum number of tags you can select here is 4',
                      });
                    } else if (actionMeta.action === 'select-option') {
                      trigger('tags');

                      return onChange(val);
                    } else if (actionMeta.action === 'remove-value' && watch('tags').length <= 1) {
                      setError('tags', {
                        type: 'custom',
                        message: 'You must have at least one tag',
                      });
                    } else if (actionMeta.action === 'remove-value') {
                      trigger('tags');

                      return onChange(val);
                    }
                  }}
                  options={knowledgeTagsOptions}
                  placeholder="Enter tags here (E.g. IT/Software, Pet parent communications, etc.)"
                  styles={multiSelectStyles}
                  classNamePrefix="tt-select"
                />
              )}
            />

            {errors && errors.tags && (
              <span className="text-error mt-1">{errors.tags.message}</span>
            )}
          </Form.Group>

          <div className={styles.buttonContainer}>
            <TTButton
              onClick={() => {
                reset();
                if (handleCancelPress) handleCancelPress();
              }}
              className="reply-btn-sun-editor p-1 mx-2"
              width="75px"
              height="32px"
              text="Cancel"
            />

            <TTButton
              onClick={handleSubmit(onSubmit)}
              disabled={isSending || isUploadingFile}
              className="btn-secondary-navy reply-btn-sun-editor p-1"
              width="75px"
              height="32px"
              text={getReplyButtonText(isSending)}
            />
          </div>
        </>
      )}
    </div>
  );
}
