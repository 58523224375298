import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

//styles
import './index.scss';
//assets
import TellTailLogo from 'assets/images/footer/telltail_logo.svg';
import FbLogo from 'assets/images/footer/fb_logo.svg';
import InstagramLogo from 'assets/images/footer/instagram_logo.svg';
import TikTokLogo from 'assets/images/footer/tiktok_logo.svg';
import TwitterLogo from 'assets/images/footer/twitter_logo.svg';
import LinkedInLogo from 'assets/images/footer/linkedin_logo.svg';

const FooterLogo = ({ link, logo, alt }: { link: string; logo: string; alt: string }) => (
  <a href={link} target="_blank" rel="noreferrer" className="tt-hover">
    <img src={logo} alt={alt} className="footer-social-image" />
  </a>
);

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer>
      <div className="footer__top">
        <img
          className="footer__logo tt-hover"
          src={TellTailLogo}
          alt="Telltail logo"
          onClick={() => {
            window.scrollTo({ top: 0 });
            navigate('/');
          }}
        />

        <div className="d-flex justify-content-lg-center flex-column">
          <div className="footer__social__media d-flex gap-4">
            <FooterLogo
              link="https://www.facebook.com/TellTailHQ"
              logo={FbLogo}
              alt="Telltail Facebook"
            />
            <FooterLogo
              link="https://www.instagram.com/telltailpets/?hl=en"
              logo={InstagramLogo}
              alt="Telltail Instagram"
            />
            <FooterLogo
              link="https://www.tiktok.com/@telltailpets"
              logo={TikTokLogo}
              alt="Telltail TikTok"
            />
            <FooterLogo
              link="https://twitter.com/telltailpets"
              logo={TwitterLogo}
              alt="Telltail Twitter"
            />
            <FooterLogo
              link="https://www.linkedin.com/company/telltailpets/about/"
              logo={LinkedInLogo}
              alt="Telltail LinkedIn"
            />
          </div>
        </div>

        <div className="d-flex justify-content-lg-center align-items-center gap-4">
          <a href="mailto:hello@telltail.com" className="footer__contact tt-hover">
            hello@telltail.com
          </a>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <a
          target="_blank"
          href="https://www.telltail.com/terms"
          className="tt-hover footer__copyright"
          rel="noreferrer"
        >
          Terms of Use
        </a>
        <div className="divider-vertical" />
        <a
          target="_blank"
          href="https://www.telltail.com/privacy"
          className="tt-hover footer__copyright"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        <div className="divider-vertical" />

        <span className="footer__copyright">
          © 2021-{moment().format('YYYY')} TellTail Holdings Inc. All Rights Reserved.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
