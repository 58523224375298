import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { likeDislikeResource, likeDislikeResourceReply } from 'services/api/apiCalls/community';
//types
import { IFollowUnFollowCoversationResponse, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useMutationLikeDislikeResource = (isReply?: boolean) => {
  const queryClient = useQueryClient();

  const { mutate: likeDislikeMutate, isLoading: isLoadingLikeDislike } = useMutation<
    AxiosResponse<IFollowUnFollowCoversationResponse>,
    AxiosError<TError>,
    string
  >(
    conversationId =>
      isReply ? likeDislikeResourceReply(conversationId) : likeDislikeResource(conversationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.ACTIVITY_FEED_LIST, {
          refetchInactive: true,
        });
        queryClient.invalidateQueries(QUERY_KEYS.COMMUNITY_CONVERSATIONS_BY_ID);
        queryClient.invalidateQueries(QUERY_KEYS.VIDEO_DETAILS);
        queryClient.invalidateQueries(QUERY_KEYS.ARTICLE_DETAILS);
      },
    },
  );

  return {
    likeDislikeMutate,
    isLoadingLikeDislike,
  };
};

export default useMutationLikeDislikeResource;
