import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { updateArticle } from 'services/api/apiCalls/admin';
//types
import { IAddNewArticlePayload, TError } from 'types';

const useMutationUpdateArticle = (handleDialogDismiss: () => void, scrollToBottom: () => void) => {
  const { addToast } = useToasts();

  const {
    mutate: updateArticleMutate,
    isLoading: updateArticleIsLoading,
    error: articleUpdateError,
    reset: resetApiStateUpdate,
  } = useMutation<AxiosResponse<string>, AxiosError<TError>, IAddNewArticlePayload>(
    data => updateArticle({ id: data._id!, articlePayload: data }),
    {
      onSuccess: () => {
        addToast('Article updated successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
        handleDialogDismiss();
      },
      onError: () => {
        scrollToBottom();
      },
    },
  );

  return {
    updateArticleMutate,
    updateArticleIsLoading,
    articleUpdateError,
    resetApiStateUpdate,
  };
};

export default useMutationUpdateArticle;
