import { useInfiniteQuery } from 'react-query';

//services
import { getDealList } from 'services/api/apiCalls/deals';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useQueryGetDealList = (filters: string[] | []) => {
  const {
    data,
    isLoading: isLoadingDeals,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    error: errorDeals,
  } = useInfiniteQuery(
    [QUERY_KEYS.GET_DEALS, filters],
    ({ pageParam = 1 }) =>
      getDealList({
        page: pageParam,
        limit: 12,
        filters: filters.join(','),
      }),
    {
      getNextPageParam: lastPage => {
        if (lastPage.meta.currentPage === lastPage.meta.totalPages) {
          return undefined;
        } else return lastPage.meta.currentPage + 1;
      },
    },
  );

  return {
    data,
    isLoadingDeals,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    errorDeals,
  };
};

export default useQueryGetDealList;
