import React, { useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { isEmpty } from 'lodash';

//hooks
import { useQueryBreedDetails } from '../__hooks/useQueryBreedDetails';
//helpers
import { getChunk } from 'helpers/array';
//assets
import breedPlaceholder from 'assets/images/registrationAndSignIn/breedPlaceholder.png';
import close from 'assets/images/breederProfile/closeBreed.svg';
import add from 'assets/images/registrationAndSignIn/addBreed.svg';
//types
import { IBuyerProcessBreedInfoExtended } from 'types';
//styles
import '../index.scss';

export default function BreedInfo({
  breedId,
  breedName,
  withSimilarBreeds,
  similarBreeds,
  setSimilarBreeds,
  isProfile,
  removeBreed,
  addBreed,
  allBreeds,
}: IBuyerProcessBreedInfoExtended) {
  const [currentPage, setCurrentPage] = useState(0);
  //Query hooks
  const { breedDetails, isFetchingBreedDetails, errorBreedDetails } = useQueryBreedDetails(
    breedId as string,
    !!breedId,
    setSimilarBreeds,
    similarBreeds,
  );

  if (isFetchingBreedDetails)
    return (
      <div className="align_center">
        <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
      </div>
    );
  else if (errorBreedDetails || breedId === undefined)
    return (
      <>
        <div className={`breed-info-container ${isProfile && 'profile'}`}>
          <img
            src={close}
            alt="remove-breed"
            className="close-breed-img"
            onClick={() => removeBreed && removeBreed(breedName)}
          />

          <div className="breed-info-img">
            <img src={breedPlaceholder} className="img-cover" />
          </div>

          <div className="breed-info">
            <h4>{breedName}</h4>

            <div className="breed-info-text-container">
              <div className="breed-info-text breed-info-text__left">
                <p>We’re still gathering information on this breed</p>
              </div>
            </div>
          </div>
        </div>

        {withSimilarBreeds && !isEmpty(similarBreeds) && (
          <div className="similar-breeds-container">
            <h5>Other breeds you might like based on your search</h5>
            <div className="similar-breeds">
              {getChunk(similarBreeds, currentPage, 4).map(similarBreed => (
                <div className="similar-breed" key={similarBreed._id}>
                  {allBreeds && allBreeds.length < 5 && (
                    <img
                      src={add}
                      alt="add-breed"
                      className="add-breed-img"
                      onClick={() => addBreed && addBreed(similarBreed.breedName)}
                    />
                  )}
                  <div className="similar-breed-image">
                    <img src={similarBreed.profilePicture} />
                  </div>
                  <h6>{similarBreed.breedName}</h6>
                </div>
              ))}
            </div>

            <div className="similar-breeds-dots">
              {Array.from({ length: Math.ceil(similarBreeds.length / 4) }).map((_, index) => (
                <div
                  key={index}
                  className={`similar-breeds-dot ${currentPage === index && 'colored'}`}
                  onClick={() => setCurrentPage(index)}
                />
              ))}
            </div>
          </div>
        )}
      </>
    );
  else
    return (
      <>
        <div className={`breed-info-container ${isProfile && 'profile'}`}>
          <img
            src={close}
            alt="remove-breed"
            className="close-breed-img"
            onClick={() => removeBreed && removeBreed(breedName)}
          />
          <div className="breed-info-img">
            <img src={breedDetails?.profilePic} />
          </div>

          <div className="breed-info">
            <h4>{breedDetails?.breedName}</h4>

            <div className="breed-info-text-container">
              <div className="breed-info-text breed-info-text__centered">
                <p>
                  <b>Size:</b> {breedDetails?.generalSize}
                </p>
                <p>
                  <b>Coat type:</b> {breedDetails?.coat}
                </p>
              </div>
              <div className="breed-info-text breed-info-text__centered">
                <p>
                  <b>Hypoallergenic:</b> {breedDetails?.isHypoallergenic ? 'Yes' : 'No'}
                </p>
                <p>
                  <b>Shedding level:</b>{' '}
                  {!isEmpty(breedDetails?.shedding) &&
                    breedDetails?.shedding[0].replace('shedding', '')}
                </p>
              </div>
            </div>
          </div>
        </div>

        {withSimilarBreeds && !isEmpty(similarBreeds) && (
          <div className="similar-breeds-container">
            <h5>Other breeds you might like based on your search</h5>
            <div className="similar-breeds">
              {getChunk(similarBreeds, currentPage, 4).map(similarBreed => (
                <div className="similar-breed" key={similarBreed._id}>
                  {allBreeds && allBreeds.length < 5 && (
                    <img
                      src={add}
                      alt="add-breed"
                      className="add-breed-img"
                      onClick={() => addBreed && addBreed(similarBreed.breedName)}
                    />
                  )}
                  <div className="similar-breed-image">
                    <img src={similarBreed.profilePicture} />
                  </div>
                  <h6>{similarBreed.breedName}</h6>
                </div>
              ))}
            </div>

            <div className="similar-breeds-dots">
              {Array.from({ length: Math.ceil(similarBreeds.length / 4) }).map((_, index) => (
                <div
                  key={index}
                  className={`similar-breeds-dot ${currentPage === index && 'colored'}`}
                  onClick={() => setCurrentPage(index)}
                />
              ))}
            </div>
          </div>
        )}
      </>
    );
}
