/* eslint-disable no-console */
import React, { Component } from 'react';

//components
import ErrorScreen from 'pages/404';
import { IErrorBoundary } from 'types';

class ErrorBoundary extends Component<IErrorBoundary> {
  state = {
    error: null,
  };

  componentDidCatch(error: object) {
    this.setState({ error });

    if (process.env.NODE_ENV === 'production') {
      // TODO add production logger and logic
      // eslint-disable-next-line no-undef
      console.log('ERROR', error);
    } else {
      // eslint-disable-next-line no-undef
      console.log('ERROR', error);
    }
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return <ErrorScreen />;
    } else {
      return children;
    }
  }
}

export default ErrorBoundary;
