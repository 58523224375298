import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

//services
import { getAdminEncyclopaediaFilterDropdowns } from 'services/api/apiCalls/admin';
//types
import { IBreedsFiltersList } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useQueryAdminFilters = () => {
  const {
    data: breedTags,
    error: errorBreedTags,
    isLoading: isLoadingBreedTags,
  } = useQuery<AxiosResponse<IBreedsFiltersList[]>, Error>(
    QUERY_KEYS.ADMIN_ENCYCLOPAEDIA_FILTERS_DROPDOWNS,
    getAdminEncyclopaediaFilterDropdowns,
  );

  return {
    errorBreedTags,
    breedTags: breedTags?.data,
    isLoadingBreedTags,
  };
};
