import React from 'react';
import { Container } from 'react-bootstrap';

//components
import Banner from 'components/common/HeadingBanner';
//styles
import './index.scss';
//assets
import CommunityGuidelinesLogo from 'assets/images/communityGuidelines/community_guidelines_logo.svg';

const CommunityGuidelines = () => {
  return (
    <div className="community__guidelines">
      <Banner
        title="TellTail Community Guidelines"
        subTitle={<span>Last Updated: October 20, 2022</span>}
        bgColor="blue"
      />

      <Container className="community__guidelines__container">
        <div className="d-flex justify-content-center">
          <img src={CommunityGuidelinesLogo} alt="Community Guidelines Logo" />
        </div>
        <h6>About TellTail</h6>
        <p>
          TellTail is a community created with breeders and for breeders - a space where breeders
          can speak freely and be themselves without the negativity that sometimes follows this
          profession.
        </p>
        <p>
          Our community guidelines are centered around one main rule:{' '}
          <b>treat others how you wish to be treated</b>. We want TellTail to be a place to show all
          perspectives - so the clearer you make your opinion, the easier it will be for somebody
          else to understand it and potentially learn from you! But when in doubt, assume that
          others in the TellTail community have the best intentions.
        </p>
        <h6>TellTail Community Guidelines</h6>
        <p>
          TellTail was created for breeders to have a safe, supportive community of their own --
          removed from the toxicity in existing social media platforms. There are plenty of online
          spaces to be a troll – TellTail is not one of them.{' '}
        </p>
        <p>
          TellTail is for kindness, support, learning, and understanding among a community of
          trusted breeders. Breeders receive enough criticism from the world, despite the incredible
          work that they do - so TellTail is a safe space. This means that we have community
          standards, which we will enforce with the help of both diligent human moderators and
          robust technology.
        </p>
        <h6>No Bad Actors</h6>
        <p>
          TellTail is a community free from bad actors. A Bad Actor, as we define it, is someone who
          is not acting in the best interest of the overall breeding community. This can include
          (but is not limited to):
        </p>
        <ol>
          <li>Scammers posing as breeders;</li>
          <li>
            Breeders who are making little to no effort to care for the health and well-being of
            their puppies, but instead view them as a product for profit (ex: The Horrible 100 list
            from the Humane Society);
          </li>
          <li>Anti-breeder activists posing as breeders;</li>
          <li>
            Toxic, mean, bullying, or trolling breeders who tear others down, rather than build them
            up, support, and educate (ex: breeders who screenshot posts of others just to mock them
            on other social media platforms);
          </li>
          <li>Individuals engaging in hate speech or discriminatory behavior.</li>
        </ol>
        <p>
          In the review process to join TellTail we consider dozens of individual data points –
          which are taken from each breeder’s application, as well as with the assistance of a
          proprietary screening process that gives us a 360 view of each individual and their
          program.
        </p>
        <p>
          We consider various elements of the breeder's program in this review – and while it is not
          our goal to make judgments about a trusted breeder’s program, we do want to ensure that
          the community is full of individuals who are striving to do the best they can for their
          puppies, with the information they have. If a breeder’s application is not approved, our
          team will give as much detail as possible about why that decision was made, and in some
          cases, offer tailored options for an appeal.
        </p>
        <h6>Be Respectful & Supportive in Disagreeing</h6>
        <p>
          We understand that there are many different opinions within the world of responsible
          breeders, and that sometimes this can be frustrating or cause an emotional reaction.
          Please always give others the benefit of the doubt, and understand that{' '}
          <u>
            we have all joined this community to learn and support each other. Further, please know
            that while our platform is carefully moderated, we will never censor your personal
            opinion as long as you are presenting that opinion in a respectful manner, and it is
            based in fact or in experience
          </u>
          . TellTail was built on a foundation of transparency, so we encourage you to explain your
          point of view so that others can learn from you.
        </p>
        <h6>No Hate Speech</h6>
        <p>
          This means no speech that expresses hate or encourages violence towards a person or group.
          Breeders have it hard enough; they do not need to be fighting each other.
        </p>
        <h6>No Bullying</h6>
        <p>
          Use “The Golden Rule” here and treat others how you wish to be treated. This is a safe
          space for breeders to ask questions, make mistakes and learn from those mistakes, and lean
          on a strong community of responsible breeders to help them elevate their programs.
        </p>
        <p>
          Please remember, this is YOUR breeder community; if you see something that violates the
          above, please let us know by sending an email to{' '}
          <a href="mailto:hello@telltail.com">hello@telltail.com</a> with the subject line “Reported
          Violation of Community Guidelines.” TellTail was built{' '}
          <b>with breeders, and for breeders</b> -- so if you feel these guidelines should be
          revised at any point, please drop us a note <a href="mailto:hello@telltail.com">here</a>.
        </p>
      </Container>
    </div>
  );
};

export default CommunityGuidelines;
