import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { getRefurralCodeDetails } from 'services/api/apiCalls/admin';
//types
import { IRefurralCodeUser, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useQueryGetRefurralCodeDetails = (refurralCode: string, enabled: boolean) => {
  const { isError, data, isLoading } = useQuery<
    AxiosResponse<IRefurralCodeUser[]>,
    AxiosError<TError>
  >([QUERY_KEYS.REFURRAL_CODE_DETAILS, refurralCode], () => getRefurralCodeDetails(refurralCode), {
    enabled,
  });

  return {
    isError,
    data: data?.data,
    isLoading,
  };
};
export default useQueryGetRefurralCodeDetails;
