import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { getUserList } from 'services/api/apiCalls/admin';
//types
import { IBreeder, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useQueryUserList = () => {
  const {
    isError,
    data,
    isLoading,
    refetch: refetchUserList,
    isFetching,
  } = useQuery<AxiosResponse<IBreeder[]>, AxiosError<TError>>([QUERY_KEYS.USER_LIST], getUserList, {
    keepPreviousData: true,
  });

  return {
    isError,
    data: data?.data,
    total: Number(data?.data.length),
    isLoading,
    refetchUserList,
    isFetching,
  };
};
