/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState, useRef, useEffect } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { find, findIndex, isEmpty, includes } from 'lodash';
import Select from 'react-select';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Autocomplete } from '@react-google-maps/api';

//constants
import { buyersBudget } from 'constants/dropdowns/buyers';
//types
import {
  IAlgorithmTestingForm,
  ISelectOption,
  IAlgorithmTestingPayload,
  IPrototypeAlgorithmTestResults,
  TGoogleMapAddress,
} from 'types';
//validation
import { buyerAlgTestingSchema } from './Validation';
//components
import TTButton from 'components/common/TTButton';
import CheckBox from 'components/common/CheckBox';
import MyMatches from './AdminTestingMatches';
//helpers
import { getFullAddress } from 'helpers/googleMaps';
//hooks
import { useMutationTestMatchingAlgorithm } from './__hooks/useMutationTestMatchingAlgorithm';
import { useQueryImpFactors } from 'pages/BuyerAccount/__hooks/useQueryImpFactors';
import { useQueryDefaultFactors } from './__hooks/useQueryDefaultFactors';
import { useQueryBreedsWithSizeVariation } from 'pages/BuyerRegistration/__hooks/useQueryBreedsWithSizeVariation';
//styles
import { selectStyles, multiSelectStyles } from 'components/styled/select';
import './index.scss';

const MatchAlgorithmTest = () => {
  const [algorithmTestResults, setAlgorithmTestResults] = useState<
    IPrototypeAlgorithmTestResults | any
  >({});
  const placesInputRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<google.maps.places.Autocomplete>();
  const selectedAddress = useRef('');
  const [isProperAddress, setIsProperAddress] = useState(false);
  const [parsedAddress, setParsedAddress] = useState<TGoogleMapAddress | null>(null);
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    trigger,
    control,
    watch,
    formState: { errors },
  } = useForm<IAlgorithmTestingForm>({
    resolver: yupResolver(buyerAlgTestingSchema(isProperAddress)),
  });

  //Query hooks
  const { impFactors, isLoadingImpFactors, errorImpFactors } = useQueryImpFactors();
  const { breeds, isLoadingBreeds, errorBreeds } = useQueryBreedsWithSizeVariation();
  const { defaultWeights, errorDefaultWeights, isLoadingDefaultWeights } = useQueryDefaultFactors();
  // Mutation hooks
  const { mutate, isLoading } = useMutationTestMatchingAlgorithm(setAlgorithmTestResults);

  const { append, remove } = useFieldArray({
    control,
    name: 'breeds',
  });

  const breedsDropdown = useMemo(
    () =>
      breeds?.map((breed: { breedName: string }) => ({
        label: breed.breedName,
        value: breed.breedName,
      })),
    [breeds],
  );
  const watchAddress = watch('fullAddress');

  useEffect(() => {
    if (!isEmpty(watchAddress)) trigger('fullAddress');
  }, [trigger, watchAddress, watch]);

  const handlePlaceChanged = () => {
    if (!inputRef.current) return;

    const place = inputRef.current.getPlace();

    if (!place) return;

    // Address field which contains street_number and route considers as full and accepted address
    const streetNumber = find(place?.address_components, val =>
      includes(val.types, 'street_number'),
    )?.long_name;
    const route = find(place?.address_components, val => includes(val.types, 'route'))?.long_name;
    setIsProperAddress(!!streetNumber && !!route);

    selectedAddress.current = placesInputRef.current?.value || '';
    const tempParsedAddress = {
      streetAddress: `${streetNumber} ${route}`,
      city: find(place?.address_components, val => includes(val.types, 'locality'))?.long_name,
      state: find(place?.address_components, val =>
        includes(val.types, 'administrative_area_level_1'),
      )?.short_name,
      postalCode: find(place?.address_components, val => includes(val.types, 'postal_code'))
        ?.long_name,
      country: find(place?.address_components, val => includes(val.types, 'country'))?.long_name,
      lat: place?.geometry?.location?.lat() || 0,
      lng: place?.geometry?.location?.lng() || 0,
    };
    setParsedAddress(tempParsedAddress);

    setValue('fullAddress', getFullAddress(tempParsedAddress) || '');
  };

  const onSubmit = (data: IAlgorithmTestingForm) => {
    if (selectedAddress.current.toLowerCase() !== placesInputRef.current?.value.toLowerCase()) {
      return setError('fullAddress', {
        type: 'custom',
        message: 'Please select a location from the provided dropdown suggestions.',
      });
    }
    const payloadTemp = {} as any;

    if (data.budgetFactor) {
      payloadTemp.budgetFactor = Number(data.budgetFactor);
    }
    if (data.sizeFactor) {
      payloadTemp.sizeFactor = Number(data.sizeFactor);
    }
    if (data.locationFactor) {
      payloadTemp.locationFactor = Number(data.locationFactor);
    }
    if (data.goHomeDateFactor) {
      payloadTemp.goHomeDateFactor = Number(data.goHomeDateFactor);
    }

    const payload = {
      ...payloadTemp,
      fullAddress: data.fullAddress,
      streetAddress: parsedAddress?.streetAddress || '',
      city: parsedAddress?.city || '',
      state: parsedAddress?.state || '',
      zipCode: parsedAddress?.postalCode || '',
      country: parsedAddress?.country || '',
      geoLong: parsedAddress?.lng.toString() as string,
      geoLat: parsedAddress?.lat.toString() as string,
      goHomeDate: data.goHomeDate,
      breeds: data.breeds,
      budgetMin: 10,
      budgetMax: Number(data.budgetMax.value),
      importantFactors: impFactors!.map(factor => {
        return {
          factor: factor.slugName,
          isFlexible: data.importantFactors?.includes(factor.slugName) || false,
        };
      }),
    };

    mutate(payload as IAlgorithmTestingPayload);
  };

  if (isLoadingImpFactors || isLoadingBreeds || isLoadingDefaultWeights) {
    return (
      <div className="profileInfo__container_admin">
        <Row>
          <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
        </Row>
      </div>
    );
  } else if (errorImpFactors || errorBreeds || errorDefaultWeights) {
    return null;
  } else
    return (
      <div className="profileInfo__container_admin">
        <h3 className="mb-4">Test match algorithm</h3>

        <Form onSubmit={handleSubmit(onSubmit)} className="my_acc_form">
          <Row className="mt-2">
            <Col xs={12} md={12} className="mb-4">
              <Form.Label className="form-labels">
                Breeds (You can add maximum 3 breeds)*
              </Form.Label>
              <Controller
                control={control}
                name="breedsInProgram"
                render={({ field: { onChange, value } }) => (
                  <Select
                    closeMenuOnSelect={true}
                    isMulti
                    isSearchable
                    value={value}
                    onChange={(val: any, actionMeta: any) => {
                      if (actionMeta.action === 'select-option') {
                        if (
                          watch('breedsInProgram') &&
                          watch('breedsInProgram').length >= 3 &&
                          actionMeta.action === 'select-option'
                        ) {
                          return setError('breedsInProgram', {
                            type: 'custom',
                            message: 'You can add maximum 3 breeds.',
                          });
                        } else {
                          val.map((item: ISelectOption) => {
                            const currentBreeds = watch('breeds');

                            if (!find(currentBreeds, { breedName: item.label })) {
                              append({
                                breedName: item.label,
                              });
                            }
                          });

                          trigger('breeds');

                          return onChange(val);
                        }
                      } else if (actionMeta.action === 'remove-value') {
                        const currentBreeds = watch('breeds');

                        remove(
                          findIndex(currentBreeds, { breedName: actionMeta.removedValue.label }),
                        );
                        trigger('breeds');

                        return onChange(val);
                      }
                    }}
                    options={breedsDropdown}
                    placeholder="Select breeds"
                    styles={multiSelectStyles}
                    classNamePrefix="tt-select"
                  />
                )}
              />

              {errors && errors.breeds && (
                <span className="text-error mt-2">{errors.breeds.message}</span>
              )}
            </Col>
          </Row>

          <Row className="mt-2">
            <Col xs={12} md={6} className="mb-4">
              <Form.Label className="form-labels">Max Budget*</Form.Label>
              <Controller
                control={control}
                name="budgetMax"
                render={({ field: { onChange, value } }) => (
                  <Select
                    closeMenuOnSelect={true}
                    isMulti={false}
                    isSearchable={true}
                    value={value}
                    onChange={val => onChange(val)}
                    options={buyersBudget}
                    placeholder="Choose max budget (in USD)"
                    hideSelectedOptions={false}
                    styles={selectStyles}
                    classNamePrefix="tt-select"
                  />
                )}
              />
              {errors && errors.budgetMax && (
                <p className="text-error mb-2">{errors.budgetMax.message}</p>
              )}
            </Col>
            <Col xs={12} md={6} className={`${errors.goHomeDate ? 'mb-0 input-error' : 'mb-4'} `}>
              <Form.Label className="form-labels">Approximate Desired Go Home Date*</Form.Label>
              <Controller
                control={control}
                name="goHomeDate"
                render={({ field: { onChange, value, ref } }) => (
                  <DatePicker
                    selected={value ? moment(value).toDate() : null}
                    minDate={moment().toDate()}
                    onChange={(date: Date) => onChange(date)}
                    popperClassName="react-datepicker-popper"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Enter go home date"
                    customInput={
                      <Form.Control
                        bsPrefix="input form-control mb-0"
                        placeholder="Enter go home date"
                        {...register('goHomeDate')}
                      />
                    }
                    ref={ref}
                  />
                )}
              />
              {errors && errors.goHomeDate && (
                <span className="text-error mt-2">{errors.goHomeDate.message}</span>
              )}
            </Col>
          </Row>

          <Row className="mt-2">
            <Autocomplete
              onLoad={ref => (inputRef.current = ref)}
              types={['address']}
              onPlaceChanged={handlePlaceChanged}
            >
              <Col xs={12} className={`${errors.fullAddress ? 'mb-0 input-error' : 'mb-4'}`}>
                <Form.Label className="form-labels">Address*</Form.Label>
                <Col className="d-flex align-items-center gap-2">
                  <input
                    ref={placesInputRef}
                    className="input form-control mb-0"
                    type="text"
                    placeholder="Type here to see address suggestions"
                    defaultValue={watch('fullAddress')}
                  />
                  {!isEmpty(watch('fullAddress')) && (
                    <p
                      className="m-0 info"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (placesInputRef.current) placesInputRef.current.value = '';

                        setValue('fullAddress', '');
                        trigger('fullAddress');
                      }}
                    >
                      Clear
                    </p>
                  )}
                </Col>
                {errors && errors.fullAddress && (
                  <p className="text-error mb-2">{errors.fullAddress.message}</p>
                )}
              </Col>
            </Autocomplete>
          </Row>

          <p className="mt-3 imp_factors_text">Check which of the following are true</p>
          <Row className="mb-4 mt-2">
            {impFactors?.map((factor, idx) => (
              <Col key={factor.slugName + idx} xs={12} md={6} className="registration_check">
                <Controller
                  control={control}
                  name="importantFactors"
                  render={({ field: { onChange, value } }) => (
                    <CheckBox
                      text={factor.value}
                      id={factor.slugName}
                      name={factor.slugName}
                      onChange={e => {
                        return onChange(
                          e.target.checked
                            ? [...(value || []), factor.slugName]
                            : value?.filter(mFactor => mFactor !== factor.slugName),
                        );
                      }}
                    />
                  )}
                />
              </Col>
            ))}
          </Row>

          <p className="imp_factors_text_orchid big">Type in algorithm weight factors</p>
          <p className="imp_factors_text_orchid">Defaults</p>
          <Row>
            <Col xs={12} md={3}>
              <Form.Label className="form-labels">Budget factor flexible</Form.Label>
              <p>{find(defaultWeights, { factorName: 'cost' })?.weightFlexible}</p>
            </Col>
            <Col xs={12} md={3}>
              <Form.Label className="form-labels">Budget factor strict</Form.Label>
              <p>{find(defaultWeights, { factorName: 'cost' })?.weightStrict}</p>
            </Col>
            <Col xs={12} md={3}>
              <Form.Label className="form-labels">Size factor flexible</Form.Label>
              <p>{find(defaultWeights, { factorName: 'size' })?.weightFlexible}</p>
            </Col>
            <Col xs={12} md={3}>
              <Form.Label className="form-labels">Size factor strict</Form.Label>
              <p>{find(defaultWeights, { factorName: 'size' })?.weightStrict}</p>
            </Col>
            <Col xs={12} md={3}>
              <Form.Label className="form-labels">Location factor flexible</Form.Label>
              <p>{find(defaultWeights, { factorName: 'location' })?.weightFlexible}</p>
            </Col>
            <Col xs={12} md={3}>
              <Form.Label className="form-labels">Location factor strict</Form.Label>
              <p>{find(defaultWeights, { factorName: 'location' })?.weightStrict}</p>
            </Col>
            <Col xs={12} md={3}>
              <Form.Label className="form-labels">Go home date factor flexible</Form.Label>
              <p>{find(defaultWeights, { factorName: 'gohomedate' })?.weightFlexible}</p>
            </Col>
            <Col xs={12} md={3}>
              <Form.Label className="form-labels">Go home date factor strict</Form.Label>
              <p>{find(defaultWeights, { factorName: 'gohomedate' })?.weightStrict}</p>
            </Col>
          </Row>

          <p className="imp_factors_text_orchid">Test values</p>
          <Row>
            <Col xs={12} md={6} className={`${errors.budgetFactor ? 'mb-0 input-error' : 'mb-4'} `}>
              <Form.Label className="form-labels">
                Budget factor{' '}
                {watch('importantFactors')?.includes('cost')
                  ? '(for flexible budget)'
                  : '(for strict budget)'}
                *
              </Form.Label>
              <Form.Control
                bsPrefix="input form-control mb-0"
                type="float"
                placeholder="Enter factor value"
                {...register('budgetFactor')}
              />
              {errors && errors.budgetFactor && (
                <p className="text-error mb-2">{errors.budgetFactor.message}</p>
              )}
            </Col>

            <Col xs={12} md={6} className={`${errors.sizeFactor ? 'mb-0 input-error' : 'mb-4'} `}>
              <Form.Label className="form-labels">
                Size factor{' '}
                {watch('importantFactors')?.includes('size')
                  ? '(for flexible size)'
                  : '(for strict size)'}
                *
              </Form.Label>
              <Form.Control
                bsPrefix="input form-control mb-0"
                type="float"
                placeholder="Enter factor value"
                {...register('sizeFactor')}
              />
              {errors && errors.sizeFactor && (
                <p className="text-error mb-2">{errors.sizeFactor.message}</p>
              )}
            </Col>

            <Col
              xs={12}
              md={6}
              className={`${errors.locationFactor ? 'mb-0 input-error' : 'mb-4'} `}
            >
              <Form.Label className="form-labels">
                Location factor{' '}
                {watch('importantFactors')?.includes('location')
                  ? '(for strict location)'
                  : '(for flexible location)'}
                *
              </Form.Label>
              <Form.Control
                bsPrefix="input form-control mb-0"
                type="float"
                placeholder="Enter factor value"
                {...register('locationFactor')}
              />
              {errors && errors.locationFactor && (
                <p className="text-error mb-2">{errors.locationFactor.message}</p>
              )}
            </Col>

            <Col
              xs={12}
              md={6}
              className={`${errors.goHomeDateFactor ? 'mb-0 input-error' : 'mb-4'} `}
            >
              <Form.Label className="form-labels">
                Go Home date factor{' '}
                {watch('importantFactors')?.includes('gohomedate')
                  ? '(for flexible go home date)'
                  : '(for strict go home date)'}
                *
              </Form.Label>
              <Form.Control
                bsPrefix="input form-control mb-0"
                type="float"
                placeholder="Enter factor value"
                {...register('goHomeDateFactor')}
              />
              {errors && errors.goHomeDateFactor && (
                <p className="text-error mb-2">{errors.goHomeDateFactor.message}</p>
              )}
            </Col>
          </Row>

          <div className="signupOnboarding__btnContainer">
            <TTButton
              type="submit"
              className="btn-primary-orchid mt-4"
              text="Test algorithm"
              loading={isLoading}
            />
          </div>

          <div className="change_pass_container mt-4">
            <h4>Old algorithm matches</h4>
            <MyMatches matches={algorithmTestResults.v1MatchAlgoResults} isTesting={true} />
          </div>

          <div className="change_pass_container mt-4">
            <h4>New algorithm matches</h4>
            <MyMatches matches={algorithmTestResults.prottypeMatchAlgoResults} isTesting={true} />
          </div>
        </Form>
      </div>
    );
};

export default MatchAlgorithmTest;
