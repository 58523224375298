import React, { useState, useEffect, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FiInfo } from 'react-icons/fi';
import { useNavigate, useLocation } from 'react-router-dom';
import { includes, map, find } from 'lodash';

//components
import MyAccount from './MyAccount';
import MyMatches from './MyMatches';
import MatchSettings from './MatchSettings';
import Warning from './Warning';
import ActivateProfileWarning from './ActivateProfileWarning';
import ChangePassword from './ChangePassword';
import VerifyPhoneNumber from './VerifyPhoneNumber';
import { Tooltip, TooltipTrigger, TooltipContent } from 'components/common/Tooltip';
//helpers
import { getTabImage } from 'helpers/tabs';
//hooks
import { useQueryBreedsDropdown } from 'pages/BreedDirectory/__hooks/useQueryBreedsDropdown';
import { useQueryBuyerInfo } from './__hooks/useQueryBuyerInfo';
import { useQueryBreedsWithSizeVariation } from 'pages/BuyerRegistration/__hooks/useQueryBreedsWithSizeVariation';
//constants
import { buyerAccountInfoTabs } from 'constants/tabs';
//services
import { AuthState } from 'services/auth';
//types
import { IBreedWithSizeVairation, TAuthContext } from 'types';
//styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

const BuyerAccount = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    state: { isAuthenticated },
  } = useContext(AuthState) as TAuthContext;

  const [activeAccountTab, setActiveAccountTab] = useState(buyerAccountInfoTabs[0]);
  const [isVerificationProcessOn, setVerificationProcessOn] = useState(false);

  //Query hooks
  const { errorBuyer, buyerInfo, isLoadingBuyer, isFetchingBuyer } = useQueryBuyerInfo(
    isAuthenticated,
    true, //isBuyer
  );
  const { breeds, isLoadingBreeds, errorBreeds } = useQueryBreedsWithSizeVariation();
  const {
    breedsDropdown: breedsWithId,
    errorBreedsDropdown,
    isFetchingBreedsDropdown,
  } = useQueryBreedsDropdown();

  useEffect(() => {
    const acceptedTabPaths = map(buyerAccountInfoTabs, 'path');
    const currentTabPath = location.pathname.split('/')[2];

    if (currentTabPath && includes(acceptedTabPaths, currentTabPath)) {
      setActiveAccountTab(find(buyerAccountInfoTabs, { path: currentTabPath }) || activeAccountTab);
    } else if (currentTabPath === 'verify-phone') {
      setVerificationProcessOn(true);
    }
  }, [activeAccountTab, location.pathname, setVerificationProcessOn]);

  if (isLoadingBuyer || isFetchingBuyer || isLoadingBreeds || isFetchingBreedsDropdown) {
    return (
      <div className="profileInfo__container">
        <Row>
          <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
        </Row>
      </div>
    );
  } else if (errorBuyer || errorBreeds || errorBreedsDropdown) {
    return null;
  } else if (buyerInfo !== undefined && breedsWithId !== undefined) {
    return (
      <>
        {isVerificationProcessOn ? (
          <VerifyPhoneNumber buyerInfo={buyerInfo} />
        ) : (
          <>
            <div className="desktop-container">
              <Row className="profileInfo__container loaded">
                <Col xs={0} xl={4} className="profileInfo__tabsContainer">
                  <div className="profileInfo__tabs">
                    {buyerAccountInfoTabs.map((tab, index) => (
                      <div
                        className={`${
                          activeAccountTab.name === tab.name && 'active'
                        } profileInfo__tab`}
                        onClick={() => {
                          setActiveAccountTab(tab);
                        }}
                        key={index}
                      >
                        <img src={getTabImage(tab.path)} alt="TT breeder" />
                        <p onClick={() => navigate(`/profile/${tab.path}`)}>{tab.name}</p>
                      </div>
                    ))}
                  </div>
                </Col>

                <Col xs={12} xl={8}>
                  <div className="profileInfo__content">
                    <div className="d-flex align-items-center">
                      <h4>{activeAccountTab.name}</h4>
                      <Tooltip>
                        <TooltipTrigger className="tooltip_trigger_account">
                          <FiInfo size={20} />
                        </TooltipTrigger>
                        <TooltipContent className="tooltip_account">
                          {activeAccountTab.tooltip}
                        </TooltipContent>
                      </Tooltip>
                    </div>
                    {!buyerInfo.streetAddress && activeAccountTab.path === 'match-settings' && (
                      <Warning />
                    )}
                    {buyerInfo.hadTriggerMatchOption && !buyerInfo.triggeredMatching && (
                      <ActivateProfileWarning />
                    )}
                    {activeAccountTab.path === 'matches' && (
                      <MyMatches
                        matches={buyerInfo.matches}
                        hadTriggerMatchOption={buyerInfo.hadTriggerMatchOption}
                        triggeredMatching={buyerInfo.triggeredMatching}
                      />
                    )}
                    {activeAccountTab.path === 'match-settings' && (
                      <MatchSettings
                        buyerInfo={buyerInfo}
                        breeds={breeds as IBreedWithSizeVairation[]}
                        breedsWithId={breedsWithId}
                      />
                    )}
                    {activeAccountTab.path === 'information' && <MyAccount buyerInfo={buyerInfo} />}
                    {activeAccountTab.path === 'change-password' && <ChangePassword />}
                  </div>
                </Col>
              </Row>
            </div>

            <div className="mobile-container">
              <div className="profileInfo__content w-100">
                {!buyerInfo.streetAddress && activeAccountTab.path === 'match-settings' && (
                  <Warning />
                )}
                {buyerInfo.hadTriggerMatchOption && !buyerInfo.triggeredMatching && (
                  <ActivateProfileWarning />
                )}
                {activeAccountTab.path === 'matches' && (
                  <MyMatches
                    matches={buyerInfo.matches}
                    hadTriggerMatchOption={buyerInfo.hadTriggerMatchOption}
                    triggeredMatching={buyerInfo.triggeredMatching}
                  />
                )}
                {activeAccountTab.path === 'match-settings' && (
                  <MatchSettings
                    buyerInfo={buyerInfo}
                    breeds={breeds as IBreedWithSizeVairation[]}
                    breedsWithId={breedsWithId}
                  />
                )}
                {activeAccountTab.path === 'information' && <MyAccount buyerInfo={buyerInfo} />}
              </div>
            </div>
          </>
        )}
      </>
    );
  } else return null;
};

export default BuyerAccount;
