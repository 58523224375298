import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { createDeal, editDeal } from 'services/api/apiCalls/admin';
//types
import { ICreateEditDealPayload, TError } from 'types';

export const useMutationCreateNewDeal = (
  handleDialogDismiss: () => void,
  scrollToBottom: () => void,
) => {
  const { addToast } = useToasts();

  const {
    mutate: createNewDealMutate,
    isLoading: isCreatingNewDeal,
    error: createNewDealError,
    reset: creatNewDealResetApiState,
  } = useMutation<AxiosResponse<string>, AxiosError<TError>, ICreateEditDealPayload>(
    data => createDeal(data),
    {
      onSuccess: () => {
        addToast('Deal created successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
        handleDialogDismiss();
      },
      onError: () => {
        scrollToBottom();
      },
    },
  );

  return {
    createNewDealMutate,
    isCreatingNewDeal,
    createNewDealError,
    creatNewDealResetApiState,
  };
};

export const useMutationEditDeal = (
  handleDialogDismiss: () => void,
  scrollToBottom: () => void,
) => {
  const { addToast } = useToasts();

  const {
    mutate: editDealMutate,
    isLoading: isEditingDeal,
    error: editDealError,
    reset: editDealResetApiState,
  } = useMutation<AxiosResponse<string>, AxiosError<TError>, ICreateEditDealPayload>(
    data => editDeal(data._id!, data),
    {
      onSuccess: () => {
        addToast('Deal updated successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
        handleDialogDismiss();
      },
      onError: () => {
        scrollToBottom();
      },
    },
  );

  return {
    editDealMutate,
    isEditingDeal,
    editDealError,
    editDealResetApiState,
  };
};
