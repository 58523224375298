export const buyersBudget = [
  { label: '$1000', value: '1000' },
  { label: '$1500', value: '1500' },
  { label: '$2000', value: '2000' },
  { label: '$2500', value: '2500' },
  { label: '$3000', value: '3000' },
  { label: '$3500', value: '3500' },
  { label: '$4000', value: '4000' },
  { label: '$4500', value: '4500' },
  { label: '$5000', value: '5000' },
  { label: '$5500', value: '5500' },
  { label: '$6000', value: '6000' },
  { label: '$6500', value: '6500' },
  { label: '$7000', value: '7000' },
  { label: '$7500', value: '7500' },
  { label: '$8000', value: '8000' },
  { label: '$8500', value: '8500' },
  { label: '$9000', value: '9000' },
  { label: '$9500', value: '9500' },
  { label: '$10000', value: '10000' },
];

export const buyersBreedWeights = [
  { label: '5 lbs', value: '5' },
  { label: '10 lbs', value: '10' },
  { label: '15 lbs', value: '15' },
  { label: '20 lbs', value: '20' },
  { label: '25 lbs', value: '25' },
  { label: '30 lbs', value: '30' },
  { label: '35 lbs', value: '35' },
  { label: '40 lbs', value: '40' },
  { label: '45 lbs', value: '45' },
  { label: '50 lbs', value: '50' },
  { label: '55 lbs', value: '55' },
  { label: '60 lbs', value: '60' },
  { label: '65 lbs', value: '65' },
  { label: '70 lbs', value: '70' },
  { label: '75 lbs', value: '75' },
  { label: '80 lbs', value: '80' },
  { label: '85 lbs', value: '85' },
  { label: '90 lbs', value: '90' },
  { label: '95 lbs', value: '95' },
  { label: '100 lbs', value: '100' },
  { label: '105 lbs', value: '105' },
  { label: '110 lbs', value: '110' },
  { label: '115 lbs', value: '115' },
  { label: '120 lbs', value: '120' },
  { label: '125 lbs', value: '125' },
  { label: '130 lbs', value: '130' },
  { label: '135 lbs', value: '135' },
  { label: '140 lbs', value: '140' },
  { label: '145 lbs', value: '145' },
  { label: '150 lbs', value: '150' },
  { label: '155 lbs', value: '155' },
  { label: '160 lbs', value: '160' },
  { label: '165 lbs', value: '165' },
  { label: '170 lbs', value: '170' },
  { label: '175 lbs', value: '175' },
  { label: '180 lbs', value: '180' },
  { label: '185 lbs', value: '185' },
  { label: '190 lbs', value: '190' },
  { label: '195 lbs', value: '195' },
  { label: '200 lbs', value: '200' },
];
