import React, { useRef, useState, useEffect } from 'react';
import { Parallax, IParallax } from '@react-spring/parallax';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

//types
import { TMoreAboutBreeder } from 'types';
//components
import Page from './ParallaxPage';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';
//hooks
import useInterval from 'hooks/useInterval';
//styles
import '../index.scss';

export default function PuppyMillCheck({ step, setStep, breederInfo }: TMoreAboutBreeder) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const parallax = useRef<IParallax>(null);
  const [activeScreen, setActiveScreen] = useState(0);

  const scrollAndSetActive = (to: number) => {
    if (parallax.current) {
      parallax.current.scrollTo(to);
    }

    setActiveScreen(to);
  };

  useInterval(() => {
    scrollAndSetActive(activeScreen + 1);
  }, 5000);

  useEffect(() => {
    const invalidateAndNavigate = async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.USER_STATUS, {
        refetchInactive: true,
      });
      navigate('/profile');
    };

    if (activeScreen === 3) {
      breederInfo.hearAboutUs === '' || breederInfo.hearAboutUs === undefined
        ? setStep(step + 1)
        : invalidateAndNavigate();

      window.scrollTo({ top: 0 });
    }
  }, [activeScreen, setStep, step, breederInfo.hearAboutUs, navigate, queryClient]);

  return (
    <div className="puppymill-container">
      <Parallax className="parallax-container" ref={parallax} pages={3} horizontal>
        <Page offset={0} onClick={() => scrollAndSetActive(1)} active={activeScreen} />
        <Page offset={1} onClick={() => scrollAndSetActive(2)} active={activeScreen} />
        <Page offset={2} onClick={() => scrollAndSetActive(0)} active={activeScreen} />
      </Parallax>
    </div>
  );
}
