import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { updateBuyerSettings } from 'services/api/apiCalls/buyer';
//types
import { IBuyerInfo, TError, IBuyerSettingsPayload } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useMutationUpdateBuyerSettings = () => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation<
    AxiosResponse<IBuyerInfo>,
    AxiosError<TError>,
    { data: IBuyerSettingsPayload }
  >(updateBuyerSettings, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.BUYER_ACCOUNT_DETAILS, {
        refetchInactive: true,
      });
      addToast('Your Program information has been updated!', {
        appearance: 'success',
        autoDismiss: true,
      });
    },
    onError: async () => {
      addToast('Something went wrong with your request.', {
        appearance: 'error',
        autoDismiss: true,
      });
    },
  });
};
