//services
import API from 'services/api';
//types
import {
  IChangePasswordPayload,
  IRegistrationPayload,
  IBuyerRegistrationPayload,
  IValidateEmailToken,
  IResetPasswordPayload,
  IResendConfirmationEmailForm,
  IRegistrationPayloadNew,
} from 'types';

// Breeders process
export const registerBreeder = (data: IRegistrationPayload) =>
  API.post('/application/create', data);
export const registerBreederNew = (data: IRegistrationPayloadNew) =>
  API.post('/application/create', data);
export const setBreederSawOffer = () => API.put('/application/saw-certificate-offer');
export const shopifyAutoLinking = (data = {}) => API.put('/shopify-user/link-user', data);

// Buyers process
export const registerBuyer = (data: IBuyerRegistrationPayload) =>
  API.post('/buyer-account/create', data);
export const setBuyerSawOffer = () => API.put('/buyer-account/saw-offer');

// Breeders anb buyers common process
export const validateEmailToken = (data: IValidateEmailToken) =>
  API.post('/application/confirmEmail', data);
export const resendConfirmationEmail = (data: IResendConfirmationEmailForm) =>
  API.post('/user/resendConfirmationEmail', data);
export const setIsVouched = (data: { vouchedSessionId: string }) =>
  API.put('/application/vouched', data);
export const unsubscribeUser = () => API.put('/badge/unsubscribe');

export const resetPassword = (data: IResetPasswordPayload) => API.post('/user/resetPassword', data);
export const sendResetPasswordTokenToEmail = ({ emailAddress }: { emailAddress: string }) =>
  API.post('/user/forgotPassword', { emailAddress });
export const changePassword = (data: IChangePasswordPayload) =>
  API.put(`user/changePassword`, data);
export const signIn = ({
  username,
  password,
}: {
  username: string | undefined;
  password: string | undefined;
}) => API.post('/auth/login', { email: username, password });
export const getUserStatus = () => API.get('/user/status');
