import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Form, Button, Container } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useToasts } from 'react-toast-notifications';

//validation
import { DevPanelSchema } from './Validation';
//types
import { IDevPanelForm, IDevPanelContext } from 'types';
//services
import { DevPanelState } from 'services/devPanel';
//helpers
import { modifyDevPanelUrl } from 'helpers/string';
//styles
import './index.scss';

const DevPanel = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const { setDevPanelBackendUrl } = useContext(DevPanelState) as IDevPanelContext;

  // Form handling
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IDevPanelForm>({
    resolver: yupResolver(DevPanelSchema),
  });

  const onSubmit = (data: IDevPanelForm) => {
    const { backendUrl } = data;
    const modifiedBackendUrl = modifyDevPanelUrl(backendUrl);

    if (modifiedBackendUrl !== backendUrl) {
      setValue('backendUrl', modifiedBackendUrl);

      return;
    }

    setDevPanelBackendUrl(modifiedBackendUrl || '');
    navigate('/login');
    addToast('URL you entered will be used for all future requests on page.', {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  return (
    <div className="forgot">
      <Container>
        <span className="forgot__back" onClick={() => navigate('/')}>
          <AiOutlineArrowLeft />
          Back
        </span>
      </Container>
      <Container className="forgot__container">
        <h2 className="mb-4">Set URL for backend API</h2>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="m-0 mt-2 align-items-center">
            <Form.Label className="form-labels">Backend URL*</Form.Label>
            <Form.Control
              bsPrefix={`input form-control ${errors.backendUrl ? 'mb-0 input-error' : 'mb-4'} `}
              type="text"
              placeholder="Enter URL"
              {...register('backendUrl')}
            />
            {errors && errors.backendUrl && (
              <p className="text-error mb-2">{errors.backendUrl.message}</p>
            )}

            <Button bsPrefix="devpanel__button forgot" type="submit">
              Set URL for backend API
            </Button>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default DevPanel;
