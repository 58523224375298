import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import map from 'lodash/map';

//components
import TTButton from 'components/common/TTButton';
//assets
import checkmarkBlue from 'assets/images/registrationAndSignIn/checkmarkBlue.svg';
import checkmarkWhite from 'assets/images/registrationAndSignIn/checkmarkWhite.svg';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';
import { basicPointsList, premiumPointsList } from 'constants/lists/subscriptionTypeLists';
//types
import { IUserInfo, TMoreAboutBreeder } from 'types';
//hooks
import { useMutationBreederHasSeenOffer } from './__hooks/useMutationBreederHasSeenOffer';
//styles
import './index.scss';

export default function ScamFreeCertified({ breederInfo }: TMoreAboutBreeder) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const userInfo = queryClient.getQueryData(QUERY_KEYS.USER_STATUS) as { data: IUserInfo };
  //Mutation hooks
  const { mutate: setBreederSawOffer } = useMutationBreederHasSeenOffer();

  useEffect(() => {
    userInfo?.data?.seenCertificateOffer === false && setBreederSawOffer();
  }, [setBreederSawOffer, userInfo?.data?.seenCertificateOffer]);

  const skipBreederCertification = () => {
    window.scrollTo({ top: 0 });
    if (breederInfo?.hearAboutUs === '' || breederInfo?.hearAboutUs === undefined) {
      navigate('/breeder-registration', { state: { from: 'get-certified' } });
    } else {
      navigate('/profile');
    }
  };

  return (
    <div className="about-program-container">
      <div className="d-flex flex-column">
        <h2 className="centered">Choose your plan</h2>
      </div>

      <div className="about-program-desktop-offers">
        <div className="offer-card">
          <h4>TellTail Preview</h4>
          <div className="card-points">
            {map(basicPointsList, (listPoint, key) => {
              return (
                <div className="card-point" key={key}>
                  {key < 3 && <img src={checkmarkBlue} />}
                  <p className={`point ${key < 3 && 'point--selected'}`}>{listPoint}</p>
                </div>
              );
            })}
          </div>
          <div className="offer-title">Free</div>
          <TTButton
            className="btn-primary-navy"
            height="50px"
            width="100%"
            type="submit"
            text="Get Started"
            onClick={skipBreederCertification}
          />
        </div>

        <div className="offer-card offer-card__premium">
          <h4 className="premium">
            TellTail <b>Pro</b>
          </h4>
          <div className="card-points">
            {map(premiumPointsList, (listPoint, key) => {
              return (
                <div className="card-point" key={key}>
                  <img src={checkmarkWhite} />
                  <p className="point point__premium point--selected ">{listPoint}</p>
                </div>
              );
            })}
          </div>
          <div className="offer-title offer-title__premium">
            $200 <span>Annually</span>
          </div>
          <TTButton
            className="btn-primary-orchid"
            height="50px"
            width="100%"
            type="submit"
            text="Get Started"
            onClick={() =>
              (window.location.href = `https://checkout.telltail.com/products/match-subscription`)
            }
          />
        </div>
      </div>

      <p className="footer-question">
        You’ll be asked to provide a government issued ID in the following steps.
      </p>

      <p className="footer-question">
        Not ready yet?
        <span className="footer-question__link" onClick={skipBreederCertification}>
          Maybe later
        </span>
      </p>
    </div>
  );
}
