import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

//components
import TabPanel from 'components/common/TabPanel';
import CommentsTable from './CommentsTable';
//constants
import { adminPanelCommentSubTabs } from 'constants/tabs';

const Comments = () => {
  const [active, setActive] = useState(adminPanelCommentSubTabs[0]);

  return (
    <>
      <h3 className="mb-4">Comments</h3>
      <TabPanel tabsList={adminPanelCommentSubTabs} current={active.value} setActive={setActive} />
      <CommentsTable active={active} />
    </>
  );
};

export default Comments;
