import React, { useEffect, useState } from 'react';

import CLAIMS from 'configs/claims';
import { ICBAC, TCBAC } from 'types';

const initialState: ICBAC = {
  userClaims: [CLAIMS.USER],
};

export const CBACState = React.createContext({
  state: { ...initialState },
});

const CBACProvider = ({ userRoles, children }: TCBAC) => {
  const [userClaims, setUserClaims] = useState(initialState.userClaims);

  useEffect(() => {
    userRoles && setUserClaims(userRoles);
  }, [userRoles]);

  const value = {
    state: {
      userClaims,
    },
    setUserClaims,
  };

  return <CBACState.Provider value={value}>{children}</CBACState.Provider>;
};

export default CBACProvider;
