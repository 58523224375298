import React, { useMemo, useContext } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Select, { SingleValue } from 'react-select';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { map, isUndefined } from 'lodash';

//types
import { TStepper, INewAboutProgramForm, TSelectOptionsType, TAuthContext } from 'types';
//validation
import { aboutProgramSchema } from './Validation';
//components
import TTButton from 'components/common/TTButton';
import ToggleSwitch from 'components/common/ToggleSwitch';
//hooks
import { useQueryBreedsWithSizeVariation } from '../__hooks/useQueryBreedsWithSizeVariation';
//assets
import plusCircle from 'assets/images/registrationAndSignIn/plusCircle.svg';
//services
import { useDataLayer } from 'services/dataLayerContext';
import { AuthState } from 'services/auth';
//assets
import deleteImg from 'assets/images/registrationAndSignIn/delete.svg';
//styles
import { selectStyles } from 'components/styled/select';
import '../index.scss';

export default function AboutProgram({ setStep, step }: TStepper) {
  const pushToDataLayer = useDataLayer();
  const {
    setRegistrationInfo,
    state: { registrationInfo },
  } = useContext(AuthState) as TAuthContext;

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<INewAboutProgramForm>({
    resolver: yupResolver(aboutProgramSchema),
    defaultValues: {
      ...registrationInfo?.breedingProgramForm,
      breedsAndLeads: registrationInfo?.breedingProgramForm?.breedsAndLeads
        ? registrationInfo?.breedingProgramForm?.breedsAndLeads
        : [
            {
              breedsInProgram: undefined,
              leads: true,
            },
          ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'breedsAndLeads',
  });

  //Query hooks
  const { breeds, isLoadingBreeds } = useQueryBreedsWithSizeVariation();

  // Breeds dropdown for different app states
  const breedsDropdown = useMemo(
    () =>
      breeds?.map((breed: { breedName: string }) => ({
        label: breed.breedName,
        value: breed.breedName,
      })),
    [breeds],
  );
  const alreadyChosenBreeds = getValues().breedsAndLeads.map(
    field => field?.breedsInProgram?.value,
  );
  const availableBreedsDropdown = useMemo(() => {
    return breedsDropdown?.filter(breed => !alreadyChosenBreeds.includes(breed.value));
  }, [breedsDropdown, alreadyChosenBreeds]);

  const onSubmit = (data: INewAboutProgramForm) => {
    data.breedsAndLeads = map(data.breedsAndLeads, item => ({
      ...item,
      leads: isUndefined(item.leads) ? false : item.leads,
    }));

    pushToDataLayer({
      event: 'aboutProgramClick',
      componentName: 'About program',
      ...data,
    });

    setRegistrationInfo('breedingProgramForm', data);
    setStep(step + 1);
    window.scrollTo({ top: 0 });
  };

  if (isLoadingBreeds)
    return (
      <div className="align_center">
        <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
      </div>
    );
  else
    return (
      <div className="about-program-container">
        <h2>Tell us a bit about your program!</h2>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="about-program-container__form"
          id="breederRegistrationAboutProgramForm"
        >
          <Row className="fill-available">
            <Col
              xs={12}
              md={6}
              className={`${errors.businessProgramName ? 'mb-0 input-error' : 'mb-4'} `}
            >
              <Form.Label className="form-labels">Business / Program Name*</Form.Label>
              <Form.Control
                bsPrefix="input form-control mb-0"
                type="text"
                placeholder="Enter program name"
                {...register('businessProgramName')}
              />
              {errors && errors.businessProgramName && (
                <p className="text-error mb-2">{errors.businessProgramName.message}</p>
              )}
            </Col>

            <Col
              xs={12}
              md={6}
              className={`${errors.yearsOfExperience ? 'mb-0 input-error' : 'mb-4'}`}
            >
              <Form.Label className="form-labels">Years of Experience*</Form.Label>
              <Form.Control
                bsPrefix="input form-control mb-0"
                type="number"
                placeholder="Enter years of experience"
                {...register('yearsOfExperience')}
              />
              {errors && errors.yearsOfExperience && (
                <p className="text-error mb-2">{errors.yearsOfExperience.message}</p>
              )}
            </Col>
          </Row>

          {fields.map((item, index) => {
            return (
              <Row key={item.id} className="fill-available">
                <Col
                  xs={1}
                  md={1}
                  className={`${
                    errors?.breedsAndLeads?.[index]?.breedsInProgram ? 'mb-0 input-error' : 'mb-4'
                  } delete-breed hover`}
                  onClick={() => {
                    fields.length > 1 && remove(index);
                  }}
                >
                  <img src={deleteImg} alt="delete" />
                </Col>
                <Col
                  xs={11}
                  md={6}
                  className={`${
                    errors?.breedsAndLeads?.[index]?.breedsInProgram ? 'mb-0 input-error' : 'mb-4'
                  }`}
                >
                  <Form.Label className="form-labels">
                    What breeds do you have in your program?*
                  </Form.Label>
                  <Controller
                    control={control}
                    name={`breedsAndLeads.${index}.breedsInProgram`}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        closeMenuOnSelect={true}
                        isSearchable
                        isClearable
                        value={value}
                        onChange={(val: SingleValue<TSelectOptionsType>) => {
                          onChange(val);
                        }}
                        options={availableBreedsDropdown}
                        placeholder="Select breed in program"
                        styles={selectStyles}
                        classNamePrefix="tt-select"
                      />
                    )}
                  />
                  {errors?.breedsAndLeads?.[index]?.breedsInProgram && (
                    <p className="text-error mb-2">
                      {errors.breedsAndLeads?.[index]?.breedsInProgram?.message}
                    </p>
                  )}
                </Col>

                <Col
                  xs={12}
                  md={5}
                  className={`${
                    errors?.breedsAndLeads?.[index]?.leads ? 'mb-0 input-error' : 'mb-4'
                  }`}
                >
                  <div className="receive-leads">
                    <p className="receive-leads__title">Start Receiving Leads</p>
                    <div className="d-flex justify-content-between w-100">
                      <p className="receive-leads__desc">
                        To stop receiving leads, toggle them off.
                        <br />
                        You can toggle them back on at any time.
                      </p>
                      <ToggleSwitch
                        id={`breedsAndLeads.${index}.leads`}
                        name={`breedsAndLeads.${index}.leads`}
                        onChange={e => {
                          setValue(`breedsAndLeads.${index}.leads`, e.target.checked);
                          trigger(`breedsAndLeads.${index}.leads`);
                        }}
                        checked={watch(`breedsAndLeads.${index}.leads`)}
                      />
                    </div>
                  </div>

                  {errors?.breedsAndLeads?.[index]?.leads && (
                    <p className="text-error mb-2">
                      {errors.breedsAndLeads?.[index]?.leads?.message}
                    </p>
                  )}
                </Col>
              </Row>
            );
          })}

          <div
            className="add-breed"
            onClick={() =>
              append({
                breedsInProgram: undefined,
                leads: true,
              })
            }
          >
            <img src={plusCircle} alt="plus-circle" />
            <p>Add a breed</p>
          </div>

          <div className="form-button-wrapper">
            <TTButton
              height="50px"
              type="submit"
              className="btn-primary-orchid"
              text="Continue"
              loading={isLoadingBreeds}
            />
          </div>
        </Form>
      </div>
    );
}
