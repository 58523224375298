import LogRocket from 'logrocket';
// Staging: export REACT_APP_LOGROCKET_PROJECT="fmaezr/nextgen"
// Prod: export REACT_APP_LOGROCKET_PROJECT="fmaezr/nextgen-prod"

import { isJsonString } from './helpers/string';

if (process.env.REACT_APP_LOGROCKET_PROJECT) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_PROJECT, {
    rootHostname: 'telltail.com',
    network: {
      requestSanitizer: request => {
        // Remove authorization header value from request
        if (request.headers['Authorization']) {
          request.headers['Authorization'] = '';
        }

        if (request.body && isJsonString(request.body)) {
          const bodyObject = (request.body && JSON.parse(request.body)) || {};
          // Remove password value from request body
          if (bodyObject.password !== undefined) {
            bodyObject.password = '';
          }
          if (bodyObject.confirmPassword !== undefined) {
            bodyObject.confirmPassword = '';
          }
          if (bodyObject.newPassword !== undefined) {
            bodyObject.newPassword = '';
          }
          if (bodyObject.oldPassword !== undefined) {
            bodyObject.oldPassword = '';
          }
          if (bodyObject.resetPasswordToken !== undefined) {
            bodyObject.resetPasswordToken = '';
          }

          request.body = JSON.stringify(bodyObject);
        }

        return request;
      },
      responseSanitizer: response => {
        if (response.body && isJsonString(response.body)) {
          const bodyObject = (response.body && JSON.parse(response.body)) || {};

          // Remove access token from response body
          if (bodyObject.accessToken !== undefined) {
            bodyObject.accessToken = '';
          }

          response.body = JSON.stringify(bodyObject);
        }

        return response;
      },
    },
  });
}
