//services
import API from 'services/api';
//types
import { TPagination, IAddNewArticlePayload } from 'types';

//Articles
export const getArticles = async function (params: TPagination) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const queryParams = new URLSearchParams(params as any).toString();

  return API.get(`/article?${queryParams}`);
};
export const getArticlesNew = async (page: number) => {
  const res = await API.get('/article?limit=12&page=' + page);

  return res.data;
};
export const getArticlesByUser = async function (id: string) {
  return API.get(`/article/createdBy/${id}`);
};
export const getArticle = (id: string) => API.get(`/article/${id}`);
export const createArticle = (articlePayload: IAddNewArticlePayload) =>
  API.post('/article', articlePayload);
export const updateArticle = ({
  id,
  articlePayload,
}: {
  id: number;
  articlePayload: IAddNewArticlePayload;
}) => API.put(`/article/${id}`, articlePayload);
export const deleteArticle = (id: number) => API.delete(`/article/${id}`);
