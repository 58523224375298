// Rentals query keys
import ARTICLES from './articles';
import COMMUNITY from './community';
import BREEDER from './breeder';
import ADMIN from './admin';
import TAGS from './tags';
import ACTIVITY_FEED from './activityFeed';
import USER_ROLES from './roles';
import DEAL from './deal';
import PAWFFICE_HOUR from './pawfficeHour';
import RESOURCES from './resources';
import VIDEOS from './videos';
import CLOUDFLARE from './cloudflare';
import BUYER from './buyer';
import LITTERS from './litters';
import BREEDS from './breeds';

export const QUERY_KEYS = {
  ...ARTICLES,
  ...COMMUNITY,
  ...BREEDER,
  ...ADMIN,
  ...TAGS,
  ...ACTIVITY_FEED,
  ...USER_ROLES,
  ...DEAL,
  ...PAWFFICE_HOUR,
  ...RESOURCES,
  ...VIDEOS,
  ...CLOUDFLARE,
  ...BUYER,
  ...LITTERS,
  ...BREEDS,
};
