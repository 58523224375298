import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { createArticle } from 'services/api/apiCalls/admin';
//types
import { IAddNewArticlePayload, TError } from 'types';

const useMutationAddNewArticle = (handleDialogDismiss: () => void, scrollToBottom: () => void) => {
  const { addToast } = useToasts();

  const {
    mutate: addNewArticleMutate,
    isLoading: addNewArticleIsLoading,
    error: articleCreationError,
    reset: resetApiState,
  } = useMutation<AxiosResponse<string>, AxiosError<TError>, IAddNewArticlePayload>(
    data => createArticle(data),
    {
      onSuccess: () => {
        addToast('Article added successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
        handleDialogDismiss();
      },
      onError: () => {
        scrollToBottom();
      },
    },
  );

  return {
    addNewArticleMutate,
    addNewArticleIsLoading,
    articleCreationError,
    resetApiState,
  };
};

export default useMutationAddNewArticle;
