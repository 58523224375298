import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

//services
import { getBreedDetailsForAdmin } from 'services/api/apiCalls/admin';
//types
import { IBreedDetails } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useQueryGetBreedDetailsForAdmin = (id: string, enabled: boolean) => {
  const {
    data: breedDetails,
    error: errorBreedDetails,
    isLoading: isLoadingBreedDetails,
    isFetching: isFetchingBreedDetails,
  } = useQuery<AxiosResponse<IBreedDetails>, Error>(
    [QUERY_KEYS.ADMIN_GET_BREED_DETAILS, id],
    () => getBreedDetailsForAdmin(id),
    {
      enabled: !!enabled,
    },
  );

  return {
    errorBreedDetails,
    breedDetails: breedDetails?.data,
    isLoadingBreedDetails,
    isFetchingBreedDetails,
  };
};
