import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

//types
import { IDeal } from 'types';
//components
import KitImg from 'components/common/KitImg';
//styles
import './index.scss';
//assets
import dealsLock from 'assets/images/deals/dealsLock.svg';

const DealCard = ({ deal, isSfcBreeder }: { deal: IDeal; isSfcBreeder: boolean }) => {
  const { _id, vendorLogo, vendorName, dealTitle, dealPicture } = deal;

  return (
    <div className="deal_card">
      {!isSfcBreeder && (
        <a className="deal-locked" href="#upgrade">
          <img src={dealsLock} alt="deals-locked" className="deals-locked-img" />
          <div className="deal-locked-text">
            <p>Upgrade to Unlock</p>
          </div>
        </a>
      )}
      <Link
        to={isSfcBreeder ? `/deals/${_id}` : '#'}
        onClick={e => {
          window.scrollTo({ top: 0 });
          if (!isSfcBreeder) {
            e.preventDefault();
          }
        }}
        className="text-decoration-none"
      >
        <div className="card_image_container">
          <KitImg src={dealPicture} kitwidth={800} />
        </div>

        <div className="deal_info_container">
          <div className="vendor_info_container">
            <img className="vendor_logo" src={vendorLogo} />
            <span className="vendor_name">{vendorName}</span>
          </div>
          <p className="deal_title">{dealTitle}</p>
        </div>
      </Link>
    </div>
  );
};

export default DealCard;
