//assets
import changePass from 'assets/images/breederProfile/password.svg';
import personalInfo from 'assets/images/breederProfile/personalInfo.svg';
// import contactInfo from 'assets/images/breederProfile/contactInfo.svg';
// import scamFree from 'assets/images/breederProfile/scamFree.svg';
import heart from 'assets/images/breederProfile/heart.svg';
import subscription from 'assets/images/breederProfile/subscription.svg';
import myLitters from 'assets/images/breederProfile/myLitters.svg';
import download from 'assets/images/breederProfile/download.svg';
import imageGallery from 'assets/images/breederProfile/imageGallery.svg';
import myReferrals from 'assets/images/breederProfile/myReferrals.svg';

export const getTabImage = (name: string) => {
  switch (name) {
    case 'settings':
      return changePass;
    case 'program':
      return personalInfo;
    case 'gallery':
      return imageGallery;
    case 'materials':
      return download;
    case 'change-password':
      return changePass;
    case 'matches':
      return heart;
    case 'leads':
      return heart;
    case 'litters':
      return myLitters;
    case 'match-settings':
      return subscription;
    case 'information':
      return personalInfo;
    case 'referrals':
      return myReferrals;

    default:
      return '';
  }
};
