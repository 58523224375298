import React from 'react';
import { useNavigate } from 'react-router-dom';

//components
import TTButton from 'components/common/TTButton';
//assets
import login from 'assets/images/landing/login.svg';
//styles
import './index.scss';

const ThankYou = () => {
  const navigate = useNavigate();

  return (
    <div className="thank-you">
      <h2>Thank you!</h2>
      <span>This is when we talk about next steps</span>

      <img src={login} alt="login" />

      <TTButton
        width="279px"
        height="50px"
        className="btn-primary-orchid"
        text="Continue"
        onClick={() => {}}
      />
      <span className="info-text">
        Have an account?{' '}
        <span className="info-text--login" onClick={() => navigate('/login')}>
          Login
        </span>
      </span>
    </div>
  );
};

export default ThankYou;
