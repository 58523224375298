/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useRef, useState, useEffect } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { Button, DialogActions } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { find, includes, omit, isEmpty } from 'lodash';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Check, Close } from '@mui/icons-material';
import { Autocomplete } from '@react-google-maps/api';
// @ts-ignore
import IntlTelInput from 'intl-tel-input/react/build/IntlTelInput.esm';
import 'intl-tel-input/build/css/intlTelInput.css';

//validation
import { ChangeEmailSchema, personalInfoSchema } from './Validation';
//constants
import { numberValidationErrors } from 'constants/lists/numberValidationErrors';
//hooks
import { useMutationUpdateBuyerSettingsByAdmin } from '../../__hooks/useMutationUpdateBuyerSettingsByAdmin';
import useMutationUpdateBuyerEmail from '../../__hooks/useMutationUpdateBuyerEmail';
//types
import { IBuyerInfo, IBuyerMyAccountForm, IUpdateEmailSchema, TGoogleMapAddress } from 'types';
//helpers
import { getFullAddress, getPostalCode } from 'helpers/googleMaps';

const PersonalInformation = ({
  buyerAccountId,
  buyerInfo,
  onCancelPress,
}: {
  buyerAccountId: string;
  buyerInfo: IBuyerInfo;
  onCancelPress: () => void;
}) => {
  const placesInputRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<google.maps.places.Autocomplete>();
  const [parsedAddress, setParsedAddress] = useState<TGoogleMapAddress | null>(
    buyerInfo
      ? {
          streetAddress: buyerInfo.streetAddress,
          city: buyerInfo.city,
          state: buyerInfo.state,
          postalCode: buyerInfo.zipCode,
          country: buyerInfo.country,
          lat: Number(buyerInfo.geoLat),
          lng: Number(buyerInfo.geoLong),
        }
      : null,
  );
  const [errorCode, setErrorCode] = useState(0);
  const [isProperAddress, setIsProperAddress] = useState(true);
  const selectedAddress = useRef(
    parsedAddress ? getFullAddress(parsedAddress) : buyerInfo.fullAddress,
  );

  // react hook form to edit email
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setError: setError2,
    formState: { errors: errors2, isDirty: isDirty2 },
  } = useForm<IUpdateEmailSchema>({
    resolver: yupResolver(ChangeEmailSchema),
    defaultValues: {
      newEmail: buyerInfo.email,
    },
  });

  // Form handling
  const {
    control,
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    watch,
    trigger,
    formState: { errors, isDirty },
  } = useForm<IBuyerMyAccountForm>({
    resolver: yupResolver(personalInfoSchema(isProperAddress)),
    defaultValues: {
      firstName: buyerInfo.firstName,
      lastName: buyerInfo.lastName,
      emailAddress: buyerInfo.email,
      phoneNumber: buyerInfo.phone,
      fullAddress: parsedAddress ? getFullAddress(parsedAddress) : buyerInfo.fullAddress,
    },
  });

  const isEmailVerified = buyerInfo.emailConfirmed;

  // Mutation hooks
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate, isLoading } = useMutationUpdateBuyerSettingsByAdmin(buyerAccountId);
  const { isUpdatingEmail, updateEmailMutate } = useMutationUpdateBuyerEmail(setError2);

  const watchAddress = watch('fullAddress');

  useEffect(() => {
    if (!isEmpty(watchAddress)) trigger('fullAddress');
  }, [isProperAddress, trigger, watchAddress, watch]);

  const handlePlaceChanged = async () => {
    if (!inputRef.current) return;

    const place = inputRef.current.getPlace();

    if (!place) return;

    // Address field which contains street_number and route considers as full and accepted address
    const streetNumber = find(place?.address_components, val =>
      includes(val.types, 'street_number'),
    )?.long_name;
    const route = find(place?.address_components, val => includes(val.types, 'route'))?.long_name;
    setIsProperAddress(!!streetNumber && !!route);

    let postalCode = find(place?.address_components, val =>
      includes(val.types, 'postal_code'),
    )?.long_name;

    if (!postalCode && place.geometry?.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      postalCode = await getPostalCode(lat, lng);
    }

    selectedAddress.current = placesInputRef.current?.value || '';
    const tempParsedAddress = {
      streetAddress: `${streetNumber} ${route}`,
      city: find(place?.address_components, val => includes(val.types, 'locality'))?.long_name,
      state: find(place?.address_components, val =>
        includes(val.types, 'administrative_area_level_1'),
      )?.short_name,
      postalCode,
      country: find(place?.address_components, val => includes(val.types, 'country'))?.long_name,
      lat: place?.geometry?.location?.lat() || 0,
      lng: place?.geometry?.location?.lng() || 0,
    };
    setParsedAddress(tempParsedAddress);

    setValue('fullAddress', getFullAddress(tempParsedAddress) || '', { shouldDirty: true });
  };

  const setIsValid = (isValid: boolean) => {
    if (isValid) {
      clearErrors('phoneNumber');
    } else {
      const errorMessage = numberValidationErrors[errorCode] || 'Invalid number';

      setError('phoneNumber', {
        type: 'custom',
        message: errorMessage,
      });
    }
  };

  const onSubmit2 = (payload: IUpdateEmailSchema) => updateEmailMutate({ payload, buyerAccountId });

  const onSubmit = async (data: IBuyerMyAccountForm) => {
    if (selectedAddress.current.toLowerCase() !== placesInputRef.current?.value.toLowerCase()) {
      return setError('fullAddress', {
        type: 'custom',
        message: 'Please enter your full street address.',
      });
    }

    const buyerInfoWithoutMatches = omit(buyerInfo, ['matches']);

    const buyerRequestPayload = {
      data: {
        ...buyerInfoWithoutMatches,
        breeds: buyerInfo.breeds ? buyerInfo.breeds.map(item => item.breedName) : [],
        ...data,
        phone: data.phoneNumber,
        fullAddress: data.fullAddress,
        streetAddress: parsedAddress?.streetAddress
          ? parsedAddress?.streetAddress
          : buyerInfo.streetAddress,
        city: parsedAddress?.city ? parsedAddress?.city : buyerInfo.city,
        state: parsedAddress?.state ? parsedAddress?.state : buyerInfo.state,
        zipCode: parsedAddress?.postalCode ? parsedAddress?.postalCode : buyerInfo.zipCode,
        country: parsedAddress?.country ? parsedAddress?.country : buyerInfo.country,
        geoLong: parsedAddress?.lng.toString() as string,
        geoLat: parsedAddress?.lat.toString() as string,
      },
    };

    mutate(buyerRequestPayload);
  };

  return (
    <>
      <div className="my_acc_form">
        <Row className="mt-2">
          <Col xs={12} className={`${errors2.newEmail ? 'mb-0 input-error' : 'mb-4'}`}>
            <Form.Label>Email*</Form.Label>
            <div className="d-flex gap-2">
              <Form.Control
                bsPrefix="input form-control mb-0"
                type="email"
                placeholder="Enter email"
                {...register2('newEmail')}
              />

              {!isDirty2 && (
                <div className="d-flex align-items-center">
                  {isEmailVerified ? (
                    <Check sx={{ color: 'green' }} />
                  ) : (
                    <Close sx={{ color: 'red' }} />
                  )}
                  <span className={`verified ${!isEmailVerified && 'not'}`}>
                    {isEmailVerified ? 'Verified' : 'Not Verified'}
                  </span>
                </div>
              )}

              <Button
                disabled={!isDirty2 || isUpdatingEmail}
                variant="contained"
                className="change-email"
                onClick={handleSubmit2(onSubmit2)}
              >
                {isUpdatingEmail ? (
                  <AiOutlineLoading3Quarters
                    size={20}
                    color="blue"
                    style={{ margin: '0.2rem' }}
                    className="loading-animation svg-loading"
                  />
                ) : (
                  'Change Email'
                )}
              </Button>
            </div>
            {errors2 && errors2.newEmail && (
              <p className="text-error mb-2">{errors2.newEmail.message}</p>
            )}
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6} className={`${errors.firstName ? 'mb-0 input-error' : 'mb-4'} `}>
            <Form.Label className="form-labels">First Name*</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0"
              type="text"
              placeholder="Enter first name"
              {...register('firstName')}
            />
            {errors && errors.firstName && (
              <p className="text-error mb-2">{errors.firstName.message}</p>
            )}
          </Col>

          <Col xs={12} md={6} className="mb-4">
            <Form.Label className="form-labels">Last Name*</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0"
              type="text"
              placeholder="Enter last name"
              {...register('lastName')}
            />
            {errors && errors.lastName && (
              <p className="text-error mb-2">{errors.lastName.message}</p>
            )}
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12} className={`${errors.lastName ? 'mb-0 input-error' : 'mb-4'} `}>
            <Form.Label className="form-labels">Phone Number (Optional)</Form.Label>
            <br />
            <Controller
              control={control}
              name={'phoneNumber'}
              render={({ field: { onChange, value } }) => (
                <IntlTelInput
                  initialValue={value}
                  onChangeNumber={onChange}
                  onChangeValidity={setIsValid}
                  onChangeErrorCode={setErrorCode}
                  initOptions={{
                    initialCountry: 'us',
                    utilsScript:
                      'https://cdn.jsdelivr.net/npm/intl-tel-input@19.2.19/build/js/utils.js',
                  }}
                />
              )}
            />
            {errors && errors.phoneNumber && (
              <p className="text-error mb-2">{errors.phoneNumber.message}</p>
            )}
          </Col>
        </Row>

        <Autocomplete
          onLoad={ref => (inputRef.current = ref)}
          types={['address']}
          onPlaceChanged={handlePlaceChanged}
        >
          <Col xs={12} className={`${errors.fullAddress ? 'mb-0 input-error' : 'mb-4'}`}>
            <Form.Label className="form-labels">Address*</Form.Label>
            <Col
              className={`d-flex align-items-center ${
                errors.fullAddress ? 'mb-0 text-error' : 'mb-4'
              }`}
            >
              <input
                ref={placesInputRef}
                className="input form-control mb-0"
                type="text"
                placeholder="Type here to see address suggestions"
                defaultValue={watch('fullAddress')}
              />
              {!isEmpty(watch('fullAddress')) && (
                <p
                  className="m-0 info"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (placesInputRef.current) placesInputRef.current.value = '';

                    setValue('fullAddress', '');
                    trigger('fullAddress');
                  }}
                >
                  Clear
                </p>
              )}
            </Col>
            {errors && errors.fullAddress && (
              <p className="text-error mb-2">{errors.fullAddress.message}</p>
            )}
          </Col>
        </Autocomplete>
      </div>

      <DialogActions
        sx={{ position: 'absolute', left: 0, bottom: 0, width: '100%', background: 'white' }}
      >
        <Button onClick={onCancelPress}>Close</Button>
        <Button disabled={isLoading || !isDirty} onClick={handleSubmit(onSubmit)}>
          {isLoading ? (
            <AiOutlineLoading3Quarters
              size={20}
              color="blue"
              style={{ margin: '0.2rem' }}
              className="loading-animation svg-loading"
            />
          ) : (
            'Update'
          )}
        </Button>
      </DialogActions>
    </>
  );
};

export default PersonalInformation;
