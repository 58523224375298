import * as Yup from 'yup';

export const personalInfoSchema = isProperAddress =>
  Yup.object().shape({
    firstName: Yup.string().required('This field is required.'),
    middleName: Yup.string().nullable(),
    lastName: Yup.string().required('This field is required.'),
    emailAddress: Yup.string().email('Invalid email').required('This field is required.'),
    phoneNumber: Yup.string().test(
      'phoneNumber',
      'Please enter valid phone number!',
      function (value) {
        if (value) {
          const schema = Yup.string().matches(/^\+[1-9]\d{1,14}$/);
          const result = schema.isValidSync(value);

          return result;
        }

        return true;
      },
    ),
    fullAddress: Yup.string()
      .required('This field is required.')
      .test('isProperAddress', 'Please enter your full street address.', function () {
        return isProperAddress;
      }),
    state: Yup.object(),
    zipCode: Yup.string().required('This field is required.'),
  });

export const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('This field is required.'),
  newPassword: Yup.string().required('This field is required.'),
  confirmNewPassword: Yup.string().required('This field is required.'),
});

export const validateChangePassword = ({ newPassword, confirmNewPassword, setError }) => {
  const passwordPatten = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

  if (!newPassword.match(passwordPatten)) {
    return setError('newPassword', {
      type: 'custom',
      message: 'Passwords must meet requirements.',
    });
  } else if (newPassword !== confirmNewPassword) {
    return setError('confirmNewPassword', {
      type: 'custom',
      message: 'Passwords should match.',
    });
  } else {
    return false;
  }
};

export const AboutProgramSchema = Yup.object().shape({
  breedsInProgram: Yup.array().required('This field is required.'),
  yearsOfExperience: Yup.string().required('This field is required.'),
  breederProgramDescription: Yup.string(),
  businessProgram: Yup.string().required('This field is required.'),
  additonalBreeds: Yup.string().nullable(),
  breedingProgramWebsite: Yup.string(),
  facebookUrl: Yup.string(),
  facebookGroups: Yup.string(),
  instagramUrl: Yup.string(),
  twitterUrl: Yup.string(),
  youtubeUrl: Yup.string(),
  tikTokUrl: Yup.string(),
});

export const LitterDetailsSchema = Yup.object().shape({
  breedName: Yup.object().required('This field is required.'),
  minWeight: Yup.number().required('This field is required.'),
  maxWeight: Yup.number().required('This field is required.'),
  goHomeDate: Yup.string().required('This field is required.'),
  minPrice: Yup.number().required('This field is required.'),
  maxPrice: Yup.number().required('This field is required.'),
  notes: Yup.string().required('This field is required.'),
  transportAvailable: Yup.string().required('This field is required.'),
  acceptLeads: Yup.string().required('This field is required.'),
  priceFlexible: Yup.string().required('This field is required.'),
});

export const breederFormSchema = {
  breedName: Yup.string().required('This field is required.'),
  minWeight: Yup.number().when('isSizeVary', (isSizeVary, schema) => {
    if (isSizeVary)
      return schema
        .required('This field is required.')
        .typeError('This field is required.')
        .min(5, 'Min weight can not be less than 5 lbs.')
        .max(195, 'Min weight can not be more than 195 lbs.');

    return schema;
  }),
  maxWeight: Yup.number().when('isSizeVary', (isSizeVary, schema) => {
    if (isSizeVary)
      return schema
        .required('This field is required.')
        .typeError('This field is required.')
        .min(10, 'Max weight can not be less than 10 lbs.')
        .max(200, 'Max weight can not be more than 200 lbs.');

    return schema;
  }),
};

export const buyerMatchSettingsSchema = Yup.object().shape({
  breeds: Yup.array().required('This field is required.'),
  budgetMax: Yup.number()
    .typeError('This field is required.')
    .required('This field is required.')
    .positive('Budget must be a positive number')
    .integer('Budget must be a round number'),
  howSoon: Yup.string().required('This field is required.'),
});

export const buyerAlgTestingSchema = Yup.object().shape({
  breeds: Yup.array()
    .of(Yup.object().shape(breederFormSchema))
    .required('This field is required.')
    .min(1, 'Please add atleast 1 breed.')
    .max(3, 'You can add maximum 3 breeds.'),
  budgetMax: Yup.object().required('This field is required.'),
  importantFactors: Yup.array(),
  zipCode: Yup.string().required('This field is required.'),
  goHomeDate: Yup.date().required('This field is required.').nullable().typeError('Invalid Date'),
});
