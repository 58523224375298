import React from 'react';
import { useNavigate } from 'react-router-dom';

//components
import TTButton from 'components/common/TTButton';

const ActivateProfileWarning = () => {
  const navigate = useNavigate();

  return (
    <div className="activate-profile-warning">
      <span className="activate-profile-warning__heading">
        You’re currently not visible to breeders
      </span>
      <span className="activate-profile-warning__subheading">
        Activate leads to start viewing your breeder leads.
      </span>
      <TTButton
        className="btn-primary-orchid activate-profile-warning__button"
        text="Activate Account"
        fontSize="13px"
        height="30px"
        onClick={() => navigate('/activate-profile')}
      />
    </div>
  );
};

export default ActivateProfileWarning;
