import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { updateConversation } from 'services/api/apiCalls/community';
//types
import { IConversation, INewConversationRequestPayload, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useMutationEditConversation = (setIsFormOpened: (isOpened: boolean) => void) => {
  const queryClient = useQueryClient();
  const {
    mutate: editConversation,
    isLoading: isLoadingEditConversation,
    error,
    isError,
    reset,
  } = useMutation<AxiosResponse<IConversation>, AxiosError<TError>, INewConversationRequestPayload>(
    data => updateConversation(data.conversationId, data.data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QUERY_KEYS.ACTIVITY_FEED_LIST, {
          refetchInactive: true,
        });
        setIsFormOpened(false);
      },
    },
  );

  return {
    editConversation,
    isLoadingEditConversation,
    error,
    isError,
    reset,
  };
};
export default useMutationEditConversation;
