export const breederAccountTabs = [
  {
    name: 'My Leads',
    path: 'leads',
    tooltip: 'Use this section to see information about your leads.',
  },
  {
    name: 'My Litters',
    path: 'litters',
    tooltip:
      'Use this section to update information about your available litters. Submit one form per litter. If you only have one puppy from a litter available, please submit it as a litter. These entries are used to match you with qualified buyers.',
  },
  {
    name: 'Image Gallery',
    path: 'gallery',
    tooltip:
      'Share images here for your public profile. Profiles with images tend to receive more leads, so we highly recommend sharing pictures of your beautiful pups!',
  },
  {
    name: 'My Program',
    path: 'program',
    tooltip:
      'Add information about your breeding program, so that buyers can get to know you. The more information you share, the better your chances are of matching!',
  },
  {
    name: 'Downloads',
    path: 'materials',
    tooltip:
      'As a TellTail breeder, you receive custom content for social media, which you can download here & share to your channels. Check back often - we add to this library regularly!',
  },
  {
    name: 'My Referrals',
    path: 'referrals',
    tooltip:
      'Copy your unique referral code from below and share it with your friends to earn rewards when they become members',
  },
  {
    name: 'Account Settings',
    path: 'settings',
    tooltip: 'Make updates to your account here, including username and subscription information.',
  },
];

export const basicBreederAccountTabs = [
  {
    name: 'My Leads',
    path: 'leads',
    tooltip: 'Use this section to see information about your leads.',
  },
  {
    name: 'My Litters',
    path: 'litters',
    tooltip:
      'Use this section to update information about your available litters. Submit one form per litter. If you only have one puppy from a litter available, please submit it as a litter. These entries are used to match you with qualified buyers.',
  },
  {
    name: 'Image Gallery',
    path: 'gallery',
    tooltip:
      'Share images here for your public profile. Profiles with images tend to receive more leads, so we highly recommend sharing pictures of your beautiful pups!',
  },
  {
    name: 'My Program',
    path: 'program',
    tooltip:
      'Add information about your breeding program, so that buyers can get to know you. The more information you share, the better your chances are of matching!',
  },
  {
    name: 'My Referrals',
    path: 'referrals',
    tooltip:
      'Copy your unique referral code from below and share it with your friends to earn rewards when they become members',
  },
  {
    name: 'Account Settings',
    path: 'settings',
    tooltip: 'Make updates to your account here, including username and subscription information.',
  },
];

export const buyerAccountInfoTabs = [
  {
    name: 'My Matches',
    path: 'matches',
    tooltip: 'Use this section to see information about your matches.',
  },
  {
    name: 'Match Settings',
    path: 'match-settings',
    tooltip: 'Make updates to your match settings here.',
  },
  {
    name: 'Account Settings',
    path: 'information',
    tooltip: 'Make updates to your account here.',
  },
];

export const breederInfoTabs = [
  { name: 'About Me', href: 'about' },
  { name: 'My Posts', href: 'posts' },
  { name: 'My Discussions', href: 'discussions' },
  { name: 'My Program', href: 'program' },
];

export const adminPanelTabs = [
  { name: 'Users', path: 'users' },
  { name: 'Review Queue', path: 'review_queue' },
  { name: 'Resources', path: 'resources' },
  { name: 'Refurral codes', path: 'refurrals' },
  { name: 'Comments', path: 'comments' },
  { name: 'Deals', path: 'deals' },
  { name: 'Pawffice Hours', path: 'pawffice' },
  { name: 'Shopify', path: 'shopify' },
  { name: 'Vouched', path: 'vouched' },
  { name: 'Buyer Verified', path: 'buyer-verified' },
  { name: 'Breedopedia', path: 'breedopedia' },
  { name: 'Confirmation email', path: 'confirmation-email' },
  {
    name: 'Algorithm testing',
    path: 'match-algorithm-testing',
  },
];

export const adminPanelUserSubTabs = [
  // { label: 'All', value: '0' },
  { label: 'Basic Breeders', value: 'breeder_1' },
  { label: 'Premium Breeders', value: 'breeder_7' },
  { label: 'Buyers', value: 'buyers' },
  { label: 'Admins', value: '6' },
];

export const adminPanelResourcesSubTabs = [
  { label: 'Upcoming', value: 'upcoming' },
  { label: 'Current', value: 'current' },
  { label: 'Expired', value: 'expired' },
  { label: 'Deleted', value: 'deleted' },
];

export const adminPanelCommentSubTabs = [
  { label: 'Unread Comments', value: 'unread_comments' },
  { label: 'Read Comments', value: 'read_comments' },
  { label: 'Deleted Comments', value: 'deleted_comments' },
];

export const adminPanelDealsSubTabs = [
  { label: 'Current & Upcoming', value: 'active' },
  { label: 'Expired', value: 'expired' },
  { label: 'Deleted', value: 'deleted' },
];

export const faqTabs = [
  { name: 'About TellTail', href: 'about' },
  { name: 'Scam Free Certified', href: 'scam' },
  { name: 'IT Help', href: 'help' },
];

export const adminPanelPawfficeSubTabs = [
  { label: 'Scheduled', value: 'upcoming' },
  { label: 'Past', value: 'past' },
  { label: 'Deleted', value: 'deleted' },
];

export const adminPanelEditUserSubTabs = [
  { label: 'Basic Info', value: 'step1' },
  { label: 'About Program', value: 'step2' },
  { label: 'About Business', value: 'step3' },
  { label: 'Learn & Connect', value: 'step4' },
  { label: 'Profile Info', value: 'step5' },
];

export const adminPanelEditBuyerSubTabs = [
  { label: 'Match Settings', value: 'buyer-step1' },
  { label: 'Personal Information', value: 'buyer-step2' },
];

export const adminNoteSubTabs = [
  { label: 'General Notes', value: 'general' },
  { label: 'Customer Service Notes', value: 'customer' },
];

export const breedDetailsTabs = [
  { name: 'Overview', href: 'overview' },
  { name: 'Behavior', href: 'behavior' },
  { name: 'History', href: 'history' },
  { name: 'Health', href: 'health' },
  { name: 'Breeding', href: 'breeding' },
];
