import * as Yup from 'yup';

export const AboutBussinessSchema = Yup.object().shape({
  litterAvailability: Yup.object().required('This field is required.'),
  puppiesSoldAnnualy: Yup.string().required('This field is required.'),
  doesShareInfo: Yup.string().required('This field is required.').nullable(),
  haveSellPuppiesToThirdParty: Yup.string().required('This field is required.').nullable(),
  thirdPartyName: Yup.string().when('haveSellPuppiesToThirdParty', {
    is: 'YES',
    then: Yup.string().required('This field is required.'),
  }),
  askToFillApplication: Yup.string().required('This field is required.').nullable(),
  doesSignContract: Yup.string().required('This field is required.').nullable(),
  doesPickPuppiesFromHome: Yup.string().required('This field is required.').nullable(),
  doesStayInTouchForQuestions: Yup.string().required('This field is required.').nullable(),
  additionalProgramDetails: Yup.string(),
});
