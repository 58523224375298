import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import MaterialReactTable, { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';

//components
import TTButton from 'components/common/TTButton';
//types
import { Matches } from 'types';

const MyMatches = ({ matches, isTesting }: { matches: Matches[]; isTesting?: boolean }) => {
  const navigate = useNavigate();

  const columns = useMemo<MRT_ColumnDef<Matches>[]>(
    () => [
      {
        header: 'Score',
        accessorKey: 'score',
        size: 200,
      },
      {
        header: 'Breeder',
        accessorKey: 'breederName',
        size: 330,
        Cell: ({ row }: { row: MRT_Row<Matches> }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={row?.original?.breederProfileImage}
              alt="breeder"
              className="table_breeder_image"
            />
            {row?.original?.breederName !== null && row?.original?.breederName}
          </Box>
        ),
      },
      {
        header: 'Match Date',
        accessorKey: 'matchDate',
        accessorFn: row => moment(row.matchDate).format('MMMM DD, YYYY'),
        size: 200,
      },
      {
        header: 'Link to Profile',
        accessorKey: 'breederId',
        Cell: ({ row }: { row: MRT_Row<Matches> }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <TTButton
              type="submit"
              className="btn-primary-orchid save_btn"
              text="View Profile"
              width="auto"
              height="40px"
              borderRadius="10px"
              onClick={() => {
                navigate(`/public-profile/${row.original.breederId}`);
              }}
            />
          </Box>
        ),
      },
    ],
    [navigate],
  );

  return (
    <>
      {!isTesting && <h6>Your Matches</h6>}

      <MaterialReactTable
        columns={columns}
        data={matches ?? []}
        rowCount={matches?.length ?? 0}
        initialState={{ pagination: { pageSize: 10, pageIndex: 0 } }}
        memoMode="cells"
        muiTablePaginationProps={{
          rowsPerPageOptions: [10],
        }}
        enableColumnOrdering
        enableGlobalFilter={false}
      />
    </>
  );
};
export default MyMatches;
