import { useQuery, useQueryClient, useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { getLitters, getLitter, updateLitter, createLitter } from 'services/api/apiCalls/litters';
//types
import { ILitterListResponse, TError, ILitter, ILitterPayload, ILitterCreate } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useQueryLittersList = (breederId: string) => {
  const {
    isError,
    data,
    isLoading,
    refetch: refetchLitterList,
    isFetching,
  } = useQuery<AxiosResponse<ILitterListResponse>, AxiosError<TError>>(
    [QUERY_KEYS.LITTER_LIST, breederId],
    () => getLitters(breederId, { page: 1, limit: 100000 }),
    {
      keepPreviousData: true,
    },
  );

  return {
    isError,
    data: data?.data?.items,
    total: Number(data?.data?.meta.totalItems),
    isLoading,
    refetchLitterList,
    isFetching,
  };
};

export const useQueryLitterDetails = (
  litterId: string,
  enabled = false,
  setLitterModalVisible: (litterModalVisible: boolean) => void,
) => {
  const {
    isLoading: isLoadingLitterDetails,
    isFetching: isFetchingLitterDetails,
    data: litterDetails,
    isSuccess: isSuccessLitterDetails,
  } = useQuery<AxiosResponse<ILitter>, AxiosError<TError>>(
    [QUERY_KEYS.LITTER_DETAILS, litterId],
    () => getLitter(litterId),
    {
      enabled,
      onSuccess: () => {
        setLitterModalVisible(true);
      },
    },
  );

  return {
    isLoadingLitterDetails,
    litterDetails: litterDetails?.data,
    isSuccessLitterDetails,
    isFetchingLitterDetails,
  };
};

export const useMutationUpdateLitterInfo = () => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation<AxiosResponse<ILitter>, AxiosError<TError>, ILitterPayload>(updateLitter, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.LITTER_LIST, {
        refetchInactive: true,
      });
      addToast('Your litter has been updated!', {
        appearance: 'success',
        autoDismiss: true,
      });
    },
    onError: async () => {
      addToast('Something went wrong with your request.', {
        appearance: 'error',
        autoDismiss: true,
      });
    },
  });
};

export const useMutationCreateLitter = () => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation<AxiosResponse<ILitter>, AxiosError<TError>, ILitterCreate>(createLitter, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.LITTER_LIST, {
        refetchInactive: true,
      });
      addToast('Your litter has been created!', {
        appearance: 'success',
        autoDismiss: true,
      });
    },
    onError: async () => {
      addToast('Something went wrong with your request.', {
        appearance: 'error',
        autoDismiss: true,
      });
    },
  });
};
