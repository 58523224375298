import React, { useContext } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//types
import { TStepper, ILegalNameForm, TAuthContext } from 'types';
//validation
import { legalNameSchema } from './Validation';
//services
import { useDataLayer } from 'services/dataLayerContext';
import { AuthState } from 'services/auth';
//components
import TTButton from 'components/common/TTButton';
//styles
import '../index.scss';

export default function ScamFreeCertified({ setStep, step }: TStepper) {
  const pushToDataLayer = useDataLayer();
  const {
    setRegistrationInfo,
    state: { registrationInfo },
  } = useContext(AuthState) as TAuthContext;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILegalNameForm>({
    resolver: yupResolver(legalNameSchema),
    defaultValues: {
      ...registrationInfo.vouchedLegalNameForm,
    },
  });

  const onSubmit = (data: ILegalNameForm) => {
    pushToDataLayer({
      event: 'legalNameClick',
      componentName: 'Vouched legal name',
      ...data,
    });
    setRegistrationInfo('vouchedLegalNameForm', data);

    setStep(step + 1);
    window.scrollTo({ top: 0 });
  };

  return (
    <div className="about-program-container">
      <div className="d-flex flex-column">
        <h2>Let’s kick off our verification process with your legal name.</h2>
        <h5 className="with-margin">
          In the next step, you’ll see that we partner with Persona to confirm your identity for
          TellTail Match. This is an important step to eliminate scammers. Please know that your
          privacy is our priority.
        </h5>
      </div>

      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="about-program-container__form"
        id="breederRegistrationVouchedLegalNameForm"
      >
        <Row className="fill-available">
          <Col xs={12} md={6} className={`${errors.firstName ? 'mb-0 input-error' : 'mb-4'} `}>
            <Form.Label className="form-labels">First Name*</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0"
              type="text"
              placeholder="Enter first name"
              {...register('firstName')}
            />
            {errors && errors.firstName && (
              <p className="text-error mb-2">{errors.firstName.message}</p>
            )}
          </Col>

          <Col xs={12} md={6} className={`${errors.lastName ? 'mb-0 input-error' : 'mb-4'} `}>
            <Form.Label className="form-labels">Last Name*</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0"
              type="text"
              placeholder="Enter last name"
              {...register('lastName')}
            />
            {errors && errors.lastName && (
              <p className="text-error mb-2">{errors.lastName.message}</p>
            )}
          </Col>
        </Row>

        <div className="form-button-wrapper">
          <TTButton height="50px" type="submit" className="btn-primary-orchid" text="Continue" />
        </div>
      </Form>
    </div>
  );
}
