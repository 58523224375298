import React from 'react';

const Paw = ({ fill }: { fill?: string }) => (
  <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.455 15.5889C15.8371 15.1034 15.4399 14.3972 15.3516 13.6469C15.2633 12.9407 14.9544 12.2786 14.4689 11.749C13.9833 11.2193 13.3213 10.9103 12.6151 10.7779C11.9089 10.6897 11.2027 10.7779 10.5848 11.131C9.96683 11.4841 9.48131 12.0138 9.17235 12.6758C8.86338 13.382 8.24547 13.9558 7.53926 14.2207C6.83306 14.4855 6.21513 15.0151 5.90617 15.7213C5.55307 16.4275 5.50893 17.222 5.72962 17.9282C5.95031 18.6344 6.43582 19.2965 7.09789 19.6496C7.75995 20.0468 8.55443 20.1351 9.26063 19.9585C10.5848 19.6496 11.9972 19.8261 13.1447 20.5323C13.8068 20.9296 14.5571 21.062 15.3075 20.9296C16.0578 20.753 16.7199 20.3117 17.1171 19.6937C17.5585 19.0758 17.735 18.2813 17.6026 17.531C17.4702 16.7365 17.073 16.0744 16.455 15.5889Z"
      fill={fill}
    />
    <path
      d="M20.596 11.3155C20.2871 10.8741 19.8016 10.5651 19.2719 10.4769C18.8747 10.4327 18.4774 10.4769 18.1243 10.6534C17.7712 10.83 17.4623 11.1389 17.2416 11.492C17.0209 11.8451 16.9326 12.2865 16.9326 12.7279C16.9326 13.1692 17.065 13.5665 17.3299 13.9196C17.5505 14.2727 17.9036 14.5375 18.2567 14.6699C18.654 14.8023 19.0512 14.8465 19.4485 14.7141C19.8457 14.6258 20.1988 14.361 20.4636 14.052C20.7284 13.743 20.905 13.3458 20.9933 12.9044C21.0374 12.3748 20.905 11.801 20.596 11.3155Z"
      fill={fill}
    />
    <path
      d="M8.01928 10.9561C8.06342 10.5147 8.01929 10.0733 7.88688 9.72023C7.71033 9.32299 7.44551 9.01403 7.0924 8.79334C6.7393 8.57265 6.34206 8.48438 5.94483 8.48438C5.54759 8.52851 5.15035 8.66093 4.84139 8.92575C4.53242 9.19058 4.26759 9.54368 4.13518 9.94092C4.00277 10.3382 3.95863 10.7795 4.0469 11.2209C4.13518 11.6181 4.35587 12.0154 4.6207 12.3243C4.92966 12.6333 5.28276 12.8099 5.68 12.854C6.20965 12.9423 6.7393 12.7657 7.18067 12.4568C7.62205 12.0595 7.93101 11.5299 8.01928 10.9561Z"
      fill={fill}
    />
    <path
      d="M9.99723 9.41286C10.3945 9.457 10.7917 9.41286 11.1448 9.23631C11.4979 9.05976 11.8069 8.7508 12.0276 8.3977C12.2483 8.0446 12.3365 7.60322 12.3365 7.16184C12.3365 6.72047 12.2041 6.32323 11.9393 5.97013C11.7186 5.61703 11.3655 5.3522 11.0124 5.21979C10.6152 5.08738 10.2179 5.04324 9.82068 5.17565C9.42345 5.26393 9.07035 5.52875 8.80553 5.83772C8.5407 6.14668 8.36414 6.54392 8.27587 6.98529C8.18759 7.55908 8.32001 8.13287 8.62898 8.61839C8.98208 9.01562 9.46758 9.32459 9.99723 9.41286Z"
      fill={fill}
    />
    <path
      d="M15.5216 10.2468C15.9189 10.291 16.3161 10.2468 16.6692 10.0703C17.0223 9.89375 17.3313 9.58478 17.552 9.23168C17.7727 8.87858 17.8609 8.4372 17.8609 7.99583C17.8609 7.55445 17.7285 7.15721 17.4637 6.80411C17.243 6.45101 16.8899 6.18619 16.5368 6.05378C16.1396 5.92136 15.7423 5.87723 15.3451 6.00964C14.9479 6.09791 14.5948 6.36274 14.3299 6.6717C14.0651 6.98066 13.8886 7.3779 13.8003 7.81928C13.712 8.39307 13.8444 8.96686 14.1534 9.45237C14.5065 9.84961 14.992 10.1586 15.5216 10.2468Z"
      fill={fill}
    />
  </svg>
);

export default Paw;
