import React from 'react';
import { useNavigate } from 'react-router-dom';

//components
import TTButton from 'components/common/TTButton';

const Warning = () => {
  const navigate = useNavigate();

  return (
    <div className="warning">
      <span>Your street address is missing. Please update from Account Settings.</span>
      <TTButton
        className="btn-primary-orchid"
        text="Update"
        fontSize="13px"
        height="30px"
        onClick={() => navigate('/profile/information')}
      />
    </div>
  );
};

export default Warning;
