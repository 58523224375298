import React, { useContext, useEffect, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import queryString from 'query-string';
import { isEmpty } from 'lodash';
import { Col } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import 'bootstrap/dist/css/bootstrap.min.css';

//types
import { TAuthContext, ILoginForm } from 'types';
//validation
import { LoginSchema } from './Validation';
//components
import TTButton from 'components/common/TTButton';
//services
import { AuthState } from 'services/auth';
//hooks
import useMutationLogin from './__hooks/useMutationLogin';
import useMutationValidateEmailToken from './__hooks/useMutationValidateEmailToken';
import useMutationResendVerificationEmail from 'pages/AdminPanel/ConfirmationEmail/__hooks/useMutationResendVerificationEmail';
//assets
import login from 'assets/images/landing/login.svg';

//styles
import './index.scss';

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const { addToast } = useToasts();
  const { setTokens } = useContext(AuthState) as TAuthContext;
  const [loading, setLoading] = useState(false);

  const params = queryString.parse(location.search);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ILoginForm>({
    resolver: yupResolver(LoginSchema),
  });

  //Mutation hooks
  const { validateToken, isValidatingEmailToken } = useMutationValidateEmailToken({
    setTokens,
    setLoading,
  });
  const { mutate: resendConfirmationEmail } = useMutationResendVerificationEmail();
  const { mutate, status } = useMutationLogin({ setTokens, setError, resendConfirmationEmail });

  useEffect(() => {
    if (!isEmpty(params.confirmEmailtoken)) {
      setLoading(true);
      validateToken({ accessToken: (params.confirmEmailtoken as string).trim() });
    }
  }, [params.confirmEmailtoken, validateToken]);

  useEffect(() => {
    if (!isEmpty(params.error) && params.error === '403') {
      addToast(
        'Sorry, there is no active account associated with this address. If this is in error, please contact us at hello@telltail.com.',
        {
          appearance: 'error',
          autoDismiss: false,
        },
      );

      window.history.replaceState(null, '', '/login');
    }
  }, [params.error, addToast]);

  const onSubmit = (data: ILoginForm) => mutate(data);

  return (
    <div className="login">
      <Col xs={12} className="w-100 d-flex justify-content-center align-items-center">
        <img src={login} alt="login" className="login-img" />
      </Col>

      {isValidatingEmailToken || loading ? (
        <div className="loading-dots-wrapper">
          We are logging you in
          <span className="loading-dot loading-dot-1">.</span>
          <span className="loading-dot loading-dot-2">.</span>
          <span className="loading-dot loading-dot-3">.</span>
        </div>
      ) : (
        <Container className="login__container">
          <div>
            <h4>Welcome back!</h4>
            <Form onSubmit={handleSubmit(onSubmit)} id="loginForm">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="form-labels">Email address</Form.Label>
                <Form.Control
                  bsPrefix={`login__input ${errors.username && 'input-error'}`}
                  type="text"
                  placeholder="Enter Email Address"
                  {...register('username')}
                />
                {errors && errors.username && (
                  <span className="text-error">
                    <>{errors.username.message}</>
                  </span>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label className="form-labels">Password</Form.Label>
                <Form.Control
                  data-private
                  bsPrefix={`login__input ${errors.password && 'input-error'}`}
                  type="password"
                  placeholder="Enter Password"
                  {...register('password')}
                />
                {errors && errors.password && (
                  <span className="text-error">
                    <>{errors.password.message}</>
                  </span>
                )}
              </Form.Group>

              <p className="forgot-password mt-3 not-centered">
                Forgot password? Click{' '}
                <a
                  href=""
                  onClick={() => navigate('/forgot-password')}
                  className="login__privacy-policy"
                >
                  here
                </a>
                .
              </p>

              <TTButton
                text={
                  status === 'loading' ? (
                    <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
                  ) : errors?.password?.message ===
                    'You need to verify your email address in order to login' ? (
                    'Verify'
                  ) : (
                    'Log In'
                  )
                }
                disabled={status === 'loading'}
                type="submit"
                width="100%"
                height="50px"
                className="btn-primary-orchid"
              />

              {errors?.password?.message ===
                'You need to verify your email address in order to login' && (
                <p className="login__no-account">
                  The verification code already sent to your email please check it on your email.
                  Didn’t receive a code? Message our team{' '}
                  <a href="mailto:hello@telltail.com">here</a>.
                </p>
              )}
            </Form>

            <p className="login__no-account">
              Don’t have an account yet?{' '}
              <span
                className="login__privacy-policy"
                onClick={() => {
                  window.scrollTo({ top: 0 });
                  window.location.href = 'https://www.telltail.com/join';
                }}
              >
                Sign Up
              </span>{' '}
            </p>
          </div>
        </Container>
      )}
    </div>
  );
}
