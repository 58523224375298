/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import find from 'lodash/find';

//services
import { uploadImage } from 'services/api/apiCalls/cloudflare';
//types
import { IFileUpload, TError, IBreederPayload, IBreeder } from 'types';

export const useMutationUploadProfileImage = (
  breederId: string,
  breederInfo: IBreeder,
  updateBreederInfo: (data: IBreederPayload) => void,
) => {
  const { mutate: uploadProfilePicture, isLoading: isUploadingProfilePicture } = useMutation<
    AxiosResponse<any>,
    AxiosError<TError>,
    IFileUpload
  >(req => uploadImage(req), {
    onSuccess: async res => {
      const imageUrl = find(res?.data?.result?.variants, function (item) {
        return item.includes('/public');
      });

      breederInfo.hearAboutUs === undefined
        ? updateBreederInfo({
            data: { ...breederInfo, hearAboutUs: '', profilePicUrl: imageUrl },
            id: breederId,
          })
        : updateBreederInfo({ data: { ...breederInfo, profilePicUrl: imageUrl }, id: breederId });
    },
  });

  return { uploadProfilePicture, isUploadingProfilePicture, isFinite };
};
