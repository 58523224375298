//services
import API from 'services/api';

// Activity feed
export const getActivityFeed = async (params = {}) => {
  const queryParams = new URLSearchParams(params).toString();
  const res = await API.get(`/activityFeed?${queryParams}`);

  return res.data;
};
