import React from 'react';
import { Button } from 'react-bootstrap';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import 'bootstrap/dist/css/bootstrap.min.css';

//types
import { TTellTailButton } from 'types';
//styles
import './index.scss';

export default function TTButton({
  text,
  width,
  maxWidth,
  height,
  onClick,
  marginLeft,
  marginRight,
  loading,
  disabled,
  fontSize,
  className,
  type,
  form,
  id,
  borderRadius,
}: TTellTailButton) {
  return (
    <Button
      id={id}
      type={type}
      form={form}
      className={`tt-button ${className && className}`}
      style={{
        width: width ? width : 'auto',
        maxWidth: maxWidth ? maxWidth : '100%',
        height: height ? height : '50px',

        fontSize: fontSize ? fontSize : '16px',
        borderRadius: borderRadius ? borderRadius : '12px',
        marginLeft: marginLeft ? marginLeft : '0',
        marginRight: marginRight ? marginRight : '0',
      }}
      onClick={onClick}
      disabled={loading || disabled}
    >
      {loading ? (
        <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
      ) : (
        <>{text}</>
      )}
    </Button>
  );
}
