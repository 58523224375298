import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

//types
import { IVideo } from 'types';
//components
import InfoView from 'components/common/InfoView';
import KitImg from 'components/common/KitImg';
import Heading from 'pages/AdminPanel/Heading';
//assets
import avatar from 'assets/images/breederProfile/avatar.png';

const VideoTags = ({ tags }: { tags: string[] }) => {
  if (isEmpty(tags)) return <p>No tag found.</p>;

  return (
    <Box display="flex" flexWrap="wrap">
      {tags?.map((tag, key) => (
        <Box key={`${tag}${key}`} bgcolor="darkblue" borderRadius={5} px={2} m={0.5}>
          <Typography variant="subtitle1" color="white">
            {tag}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const AdminVideoDetails = ({ video }: { video: IVideo }) => {
  if (!video) return <></>;

  return (
    <Box display="flex" flexDirection="column">
      <Heading text="Video Details" bgcolor="darkblue" />

      <Box display="flex">
        <Box flex={1}>
          <InfoView label="Video Id:" value={video._id} />
          <InfoView label="Video Title:" value={video.title} />
          <InfoView
            label="Video Thumbnail:"
            value={
              <Card sx={{ width: 300 }} elevation={3}>
                <KitImg src={video.thumbnailImage} kitwidth={300} />
              </Card>
            }
          />
        </Box>

        <Box width="1px" bgcolor="black" mt={1} />

        <Box display="flex" flexDirection="column" flex={1}>
          <InfoView
            label="Creator Image:"
            value={
              <Card
                sx={{ width: 110, height: 110, borderRadius: 55, border: '1px solid black' }}
                elevation={3}
              >
                <KitImg
                  src={video.createdBy?.profileImage || avatar}
                  kitwidth={110}
                  style={{ width: '100%', height: '100%' }}
                />
              </Card>
            }
          />

          <InfoView
            label="Author Name:"
            value={
              <>
                {video.createdBy.name ? (
                  <span>
                    {video.createdBy.name}{' '}
                    {!video.createdBy ? <span className="text-error">(External Author)</span> : ''}
                  </span>
                ) : (
                  <span>{video.createdBy?.name}`</span>
                )}
              </>
            }
          />
          <InfoView label="Article Tags:" value={<VideoTags tags={video.tags} />} />
        </Box>
      </Box>

      <Box height="1px" bgcolor="black" my={1} mx={3} />

      <InfoView
        label="Article Content:"
        value={
          <Box overflow="hidden">
            <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
              <iframe
                src={`${video.url}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="title"
              />
              <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
          </Box>
        }
        vertical
      />
    </Box>
  );
};

export default AdminVideoDetails;
