/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete } from '@react-google-maps/api';
import { isEmpty, find, includes } from 'lodash';
// @ts-ignore
import IntlTelInput from 'intl-tel-input/react/build/IntlTelInput.esm';
import 'intl-tel-input/build/css/intlTelInput.css';

//validation
import { personalInfoSchema } from './Validation';
//components
import ChangePassword from './ChangePassword';
import TTButton from 'components/common/TTButton';
//constants
import { numberValidationErrors } from 'constants/lists/numberValidationErrors';
//hooks
import { useMutationUpdateBuyerSettings } from './__hooks/useMutationUpdateBuyerSettings';
//types
import { IBuyerInfo, IBuyerMyAccountForm, TGoogleMapAddress } from 'types';
//helpers
import { getPostalCode, getFullAddress } from 'helpers/googleMaps';
//assets
import checkmarkGreen from 'assets/images/breederProfile/checkmarkGreen.svg';

const MyAccount = ({ buyerInfo }: { buyerInfo: IBuyerInfo }) => {
  const navigate = useNavigate();
  const placesInputRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<google.maps.places.Autocomplete>();
  const [parsedAddress, setParsedAddress] = useState<TGoogleMapAddress | null>(
    buyerInfo
      ? {
          streetAddress: buyerInfo.streetAddress,
          city: buyerInfo.city,
          state: buyerInfo.state,
          postalCode: buyerInfo.zipCode,
          country: buyerInfo.country,
          lat: Number(buyerInfo.geoLat),
          lng: Number(buyerInfo.geoLong),
        }
      : null,
  );
  const [errorCode, setErrorCode] = useState(0);
  const [isProperAddress, setIsProperAddress] = useState(true);
  const selectedAddress = useRef(
    parsedAddress ? getFullAddress(parsedAddress) : buyerInfo.fullAddress,
  );

  // Form handling
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<IBuyerMyAccountForm>({
    resolver: yupResolver(personalInfoSchema(isProperAddress)),
    defaultValues: {
      firstName: buyerInfo.firstName,
      lastName: buyerInfo.lastName,
      emailAddress: buyerInfo.email,
      phoneNumber: buyerInfo.phone,
      fullAddress: parsedAddress ? getFullAddress(parsedAddress) : buyerInfo.fullAddress,
      streetAddress: buyerInfo.streetAddress,
      city: buyerInfo.city,
      zipCode: buyerInfo.zipCode,
    },
  });

  // Mutation hooks
  const { mutate, isLoading } = useMutationUpdateBuyerSettings();

  const watchAddress = watch('fullAddress');

  useEffect(() => {
    if (!isEmpty(watchAddress)) trigger('fullAddress');
  }, [isProperAddress, trigger, watchAddress, watch]);

  const handlePlaceChanged = async () => {
    if (!inputRef.current) return;

    const place = inputRef.current.getPlace();

    if (!place) return;

    // Address field which contains street_number and route considers as full and accepted address
    const streetNumber = find(place?.address_components, val =>
      includes(val.types, 'street_number'),
    )?.long_name;
    const route = find(place?.address_components, val => includes(val.types, 'route'))?.long_name;
    setIsProperAddress(!!streetNumber && !!route);

    let postalCode = find(place?.address_components, val =>
      includes(val.types, 'postal_code'),
    )?.long_name;

    if (!postalCode && place.geometry?.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      postalCode = await getPostalCode(lat, lng);
    }

    selectedAddress.current = placesInputRef.current?.value || '';
    const tempParsedAddress = {
      streetAddress: `${streetNumber} ${route}`,
      city: find(place?.address_components, val => includes(val.types, 'locality'))?.long_name,
      state: find(place?.address_components, val =>
        includes(val.types, 'administrative_area_level_1'),
      )?.short_name,
      postalCode,
      country: find(place?.address_components, val => includes(val.types, 'country'))?.long_name,
      lat: place?.geometry?.location?.lat() || 0,
      lng: place?.geometry?.location?.lng() || 0,
    };
    setParsedAddress(tempParsedAddress);

    setValue('fullAddress', getFullAddress(tempParsedAddress) || '');
  };

  const setIsValid = (isValid: boolean) => {
    if (isValid) {
      clearErrors('phoneNumber');
    } else {
      const errorMessage = numberValidationErrors[errorCode] || 'Invalid number';

      setError('phoneNumber', {
        type: 'custom',
        message: errorMessage,
      });
    }
  };

  const onSubmit = async (data: IBuyerMyAccountForm) => {
    if (selectedAddress.current.toLowerCase() !== placesInputRef.current?.value.toLowerCase()) {
      return setError('fullAddress', {
        type: 'custom',
        message: 'Please enter your full street address.',
      });
    }

    const buyerRequestPayload = {
      data: {
        ...buyerInfo,
        breeds: buyerInfo.breeds ? buyerInfo.breeds.map(item => item.breedName) : [],
        ...data,
        phone: data.phoneNumber,
        fullAddress: data.fullAddress,
        streetAddress: parsedAddress?.streetAddress
          ? parsedAddress?.streetAddress
          : buyerInfo.streetAddress,
        city: parsedAddress?.city ? parsedAddress?.city : buyerInfo.city,
        state: parsedAddress?.state ? parsedAddress?.state : buyerInfo.state,
        zipCode: parsedAddress?.postalCode ? parsedAddress?.postalCode : buyerInfo.zipCode,
        country: parsedAddress?.country ? parsedAddress?.country : buyerInfo.country,
        geoLong: parsedAddress?.lng.toString() as string,
        geoLat: parsedAddress?.lat.toString() as string,
      },
    };

    return mutate(buyerRequestPayload);
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="my_acc_form"
        id="buyerProfileMyAccountForm"
      >
        <Row className="mt-2">
          <Col xs={12} md={6} className={`${errors.firstName ? 'mb-0 input-error' : 'mb-4'} `}>
            <Form.Label className="form-labels">First Name*</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0"
              type="text"
              placeholder="Enter first name"
              disabled
              {...register('firstName')}
            />
            {errors && errors.firstName && (
              <p className="text-error mb-2">{errors.firstName.message}</p>
            )}
          </Col>

          <Col xs={12} md={6} className={`${errors.lastName ? 'mb-0 input-error' : 'mb-4'} `}>
            <Form.Label className="form-labels">Last Name*</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0"
              type="text"
              placeholder="Enter last name"
              disabled
              {...register('lastName')}
            />
            {errors && errors.lastName && (
              <p className="text-error mb-2">{errors.lastName.message}</p>
            )}
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6} className={`${errors.emailAddress ? 'mb-0 input-error' : 'mb-4'} `}>
            <Form.Label className="form-labels">Email Address*</Form.Label>
            <Form.Control
              bsPrefix="form-control input mb-0"
              type="email"
              disabled
              placeholder="Enter Email Address"
              {...register('emailAddress')}
            />
            {errors && errors.emailAddress && (
              <p className="text-error mb-2">{errors.emailAddress.message}</p>
            )}
          </Col>

          <Col xs={12} md={6} className={`${errors.phoneNumber ? 'mb-0 input-error' : 'mb-4'} `}>
            <Form.Label className="form-labels">
              Phone Number*{' '}
              {buyerInfo.phoneVerified ? (
                <span className="verification-status verification-status__verified">
                  Verified <img src={checkmarkGreen} alt="phone-verified" />
                </span>
              ) : (
                <span
                  className="verification-status"
                  onClick={() => navigate('/profile/verify-phone')}
                >
                  Click to verify
                </span>
              )}
            </Form.Label>
            <br />
            <Controller
              control={control}
              name={'phoneNumber'}
              render={({ field: { onChange, value } }) => (
                <IntlTelInput
                  initialValue={value}
                  onChangeNumber={onChange}
                  onChangeValidity={setIsValid}
                  onChangeErrorCode={setErrorCode}
                  initOptions={{
                    initialCountry: 'us',
                    utilsScript:
                      'https://cdn.jsdelivr.net/npm/intl-tel-input@19.2.19/build/js/utils.js',
                  }}
                />
              )}
            />
            {errors && errors.phoneNumber && (
              <p className="text-error mb-2 text-error-number-verify">
                {errors.phoneNumber.message}
              </p>
            )}
          </Col>
        </Row>

        <Autocomplete
          onLoad={ref => (inputRef.current = ref)}
          types={['address']}
          onPlaceChanged={handlePlaceChanged}
        >
          <Col xs={12} className={`${errors.fullAddress ? 'mb-0 input-error' : 'mb-4'}`}>
            <Form.Label className="form-labels">Address*</Form.Label>
            <Col
              className={`d-flex align-items-center ${
                errors.fullAddress ? 'mb-0 input-error' : 'mb-4'
              }`}
            >
              <input
                ref={placesInputRef}
                className="input form-control mb-0"
                type="text"
                placeholder="Type here to see address suggestions"
                defaultValue={watch('fullAddress')}
              />
              {!isEmpty(watch('fullAddress')) && (
                <p
                  className="m-0 info"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (placesInputRef.current) placesInputRef.current.value = '';

                    setValue('fullAddress', '');
                    trigger('fullAddress');
                  }}
                >
                  Clear
                </p>
              )}
            </Col>
            {errors && errors.fullAddress && (
              <p className="text-error mb-2">{errors.fullAddress.message}</p>
            )}
          </Col>
        </Autocomplete>

        {/* Buttons area */}
        <div className="d-flex w-100 mt-4  align-items-center justify-content-end">
          <TTButton
            type="submit"
            className="btn-primary-orchid save_btn"
            text="Save"
            fontSize="12px"
            borderRadius="10px"
            loading={isLoading}
            width="100px"
            height="40px"
          />
        </div>
      </Form>

      <ChangePassword />
    </>
  );
};
export default MyAccount;
