import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { UseFormSetError } from 'react-hook-form';

//services
import { updateUserRoles } from 'services/api/apiCalls/roles';
//types
import { IBasicInfoSchema, IUpdateUserRolesPayload, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useMutationProvideAdminAccess = ({
  setShowSuccess,
  setError,
}: {
  setShowSuccess: (showSuccess: boolean) => void;
  setError: UseFormSetError<IBasicInfoSchema>;
}) => {
  const queryClient = useQueryClient();

  const { mutate: provideAdminAccessMutate, isLoading: isProviding } = useMutation<
    AxiosResponse<string>,
    AxiosError<TError>,
    IUpdateUserRolesPayload
  >(data => updateUserRoles(data.userId, data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.ADMIN_BREEDER_INFO, {
        refetchInactive: true,
      });
      setShowSuccess(true);
    },
    onError: err => {
      setError('firstName', {
        type: 'custom',
        message: err?.response?.data?.ExceptionMessage,
      });
    },
  });

  return {
    provideAdminAccessMutate,
    isProviding,
  };
};
