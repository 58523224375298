import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { Box, Dialog, DialogContent, DialogTitle, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Close } from '@mui/icons-material';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

//types
import { IBreedWithSizeVairation, IDialogProps } from 'types';
//hooks
import { useQueryGetBuyerDetails } from '../__hooks/useQueryGetBuyerDetails';
import { useQueryBreedsWithSizeVariation } from 'pages/BuyerRegistration/__hooks/useQueryBreedsWithSizeVariation';
import { useQueryBreedsDropdown } from 'pages/BreedDirectory/__hooks/useQueryBreedsDropdown';
//components
import TabPanel from 'components/common/TabPanel';
import MatchSettings from './MatchSettings';
import PersonalInformation from './PersonalInformation';
//styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
//constants
import { adminPanelEditBuyerSubTabs } from 'constants/tabs';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditBuyerInfoDialog = ({ open, handleClose, displayName, buyerAccountId }: IDialogProps) => {
  const [active, setActive] = useState(adminPanelEditBuyerSubTabs[0]);
  const [showSuccess, setShowSuccess] = useState(false);

  //Query hooks
  const { errorBuyer, buyerInfo, isLoadingBuyer, isFetchingBuyer } = useQueryGetBuyerDetails(
    buyerAccountId!,
  );
  const { breeds, isLoadingBreeds, errorBreeds } = useQueryBreedsWithSizeVariation();
  const {
    breedsDropdown: breedsWithId,
    errorBreedsDropdown,
    isFetchingBreedsDropdown,
  } = useQueryBreedsDropdown();

  useEffect(() => {
    if (showSuccess) setShowSuccess(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, setShowSuccess]);

  const loadViews = () => {
    if (isLoadingBuyer || isFetchingBuyer || isLoadingBreeds || isFetchingBreedsDropdown) {
      return (
        <div className="profileInfo__container">
          <Row>
            <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
          </Row>
        </div>
      );
    } else if (errorBuyer || errorBreeds || errorBreedsDropdown) {
      return null;
    } else if (buyerInfo !== undefined && breedsWithId !== undefined) {
      return (
        <Box display="flex" flexDirection="column" width="1200px" height="80vh" overflow="hidden">
          <TabPanel
            tabsList={adminPanelEditBuyerSubTabs}
            current={active.value}
            setActive={setActive}
          />
          {showSuccess && (
            <Box
              sx={{
                backgroundColor: 'green',
                color: 'white',
                px: 1.5,
                py: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span>Updated successfully.</span>
              <div style={{ cursor: 'pointer' }} onClick={() => setShowSuccess(false)}>
                <Close />
              </div>
            </Box>
          )}
          <div
            style={{
              overflow: 'scroll',
              backgroundColor: '#ddd',
              padding: 40,
              marginBottom: 50,
              borderRadius: 10,
            }}
          >
            <div className="admin_profileInfo__container">
              <div className="admin_profileInfo__content">
                {active.value === 'buyer-step1' && (
                  <MatchSettings
                    buyerAccountId={buyerAccountId!}
                    buyerInfo={buyerInfo}
                    breeds={breeds as IBreedWithSizeVairation[]}
                    breedsWithId={breedsWithId}
                    onCancelPress={handleClose}
                  />
                )}
                {active.value === 'buyer-step2' && (
                  <PersonalInformation
                    buyerAccountId={buyerAccountId!}
                    buyerInfo={buyerInfo}
                    onCancelPress={handleClose}
                  />
                )}
              </div>
            </div>
          </div>
        </Box>
      );
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      maxWidth="xl"
    >
      <DialogTitle>{`Edit ${displayName} Profile`}</DialogTitle>
      <DialogContent>{loadViews()}</DialogContent>
    </Dialog>
  );
};

export default EditBuyerInfoDialog;
