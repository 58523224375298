import React, { useMemo, useState, useRef } from 'react';
import { Box } from '@mui/material';
import MaterialReactTable, { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { Delete, Edit, Launch } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';

//hooks
import useQueryAdminPawfficeHourList from './__hooks/useQueryAdminPawfficeHourList';
import { useQueryUserRoles } from '../Deals/__hooks/useQuery';
import { useQueryKnowledgeTags } from 'pages/BreederAccount/__hooks/useQueryKnowledgeTags';
import { useMutationDeletePawfficeHour } from './__hooks/useMutationDeletePawfficeHour';
//types
import { IPawfficeHour, IDialogContent, ICreateEditPawfficeHourSchema } from 'types';
//components
import TTButton from 'components/common/TTButton';
import KitImg from 'components/common/KitImg';
import ActionButton from 'components/common/ActionButton';
import AlertDialog from 'components/common/AlertDialog';
import TagItem from 'components/common/TagItem';
import PawfficeHourDetails from './PawfficeHourDetails';
import CreatePawfficeHoursDialog from './CreateNewPawfficeHoursDialog';
//helpers
import { csvOptions } from 'helpers/cvsOptions';

const PawfficeHoursTable = ({ active }: { active: { label: string; value: string } }) => {
  const requestPayload = useRef<{ id: string; actionType: string } | null>(null);
  const pawfficeHourToEdit = useRef<ICreateEditPawfficeHourSchema>();
  const [showCreatePawfficeHourDialog, setShowCreatePawfficeHourDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState<IDialogContent | null>(null);

  //Query hooks
  const { isError, data, total, isFetching, isLoading, refreshPawfficeHourList } =
    useQueryAdminPawfficeHourList(active.value);
  const { knowledgeTags, isLoadingKnowledgeTags } = useQueryKnowledgeTags();
  const { userRoles, isLoadingUserRoles } = useQueryUserRoles();
  //Mutation hooks
  const { deletePawfficeHourMutate, isDeleting } = useMutationDeletePawfficeHour(setDialogContent);

  const columns = useMemo<MRT_ColumnDef<IPawfficeHour>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'Image',
        accessorKey: 'pawfficeImageUrl',
        Cell: ({ row }: { row: MRT_Row<IPawfficeHour> }) => (
          <KitImg
            src={
              row?.original?.pawfficeImageUrl ||
              'https://telltail-breeder-resources.s3.amazonaws.com/images/profiles/App Icon Square.png'
            }
            kitwidth={100}
            alt="pawffice-hour"
            style={{
              width: '50px',
              height: '50px',
              borderRadius: '25px',
              marginRight: '10px',
              objectFit: 'cover',
            }}
          />
        ),
      },
      {
        header: 'Host Name',
        accessorKey: 'host.hostName',
      },
      {
        header: 'Start Date',
        accessorKey: 'startDate',
        accessorFn: row => moment(row.startDate).format('LLL'),
      },
      {
        header: 'Duration',
        accessorKey: 'duration',
        accessorFn: row => `${row.duration.hours} hr ${row.duration.minutes} min`,
      },
      {
        header: 'Visibility',
        accessorKey: 'visibility',
        Cell: ({ row }: { row: MRT_Row<IPawfficeHour> }) => (
          <ul className="breederProfile__tags mt-0">
            {row.original.visibility.map((visibility, idx) => (
              <TagItem key={visibility + idx} name={visibility?.toUpperCase()} />
            ))}
          </ul>
        ),
      },
      {
        header: 'Tags',
        accessorKey: 'tags',
        Header: () => <label style={{ width: 110, textAlign: 'center' }}>Tags</label>,
        Cell: ({ row }: { row: MRT_Row<IPawfficeHour> }) => (
          <ul className="breederProfile__tags mt-0">
            {row.original.tags.map((tag, idx) => (
              <TagItem key={tag + idx} name={tag.toUpperCase()} />
            ))}
          </ul>
        ),
      },
    ],
    [],
  );

  // Download table to CSV format logic
  const csvPawfficeHoursOptions = csvOptions(
    columns,
    `${active.label}_Pawffice_Hours_TellTail_${moment()
      .utc()
      .utcOffset('-0700')
      .format('MMMM_D_YYYY_hh_mm_A')}`,
  );
  const csvExporter = new ExportToCsv(csvPawfficeHoursOptions);
  const csvData = data?.map(pawfficeHour => ({
    name: pawfficeHour.name,
    image: pawfficeHour.pawfficeImageUrl,
    hostName: pawfficeHour.host.hostName,
    startDate: moment(pawfficeHour.startDate).format('LLL'),
    duration: `${pawfficeHour.duration.hours}:${pawfficeHour.duration.minutes}`,
    visibility: pawfficeHour?.visibility?.join(', '),
    tags: pawfficeHour?.tags?.join(', '),
  }));
  const handleExportData = () => {
    csvExporter.generateCsv(csvData);
  };

  // Handle table actions logic
  const handleEditPress = (row: MRT_Row<IPawfficeHour>) => {
    const tags = row.original.tags?.map(tag => ({ value: tag, label: tag }));
    const visibility = row.original.visibility?.map(role => ({
      value: role,
      label: role?.charAt(0).toUpperCase() + role?.slice(1),
    }));
    pawfficeHourToEdit.current = {
      ...row.original,
      _id: row.original._id,
      host: row.original.host.hostId === '' ? row.original.host.hostName : row.original.host.hostId,
      hours: row.original.duration.hours,
      minutes: row.original.duration.minutes,
      link: row.original.call.link,
      dialNumber: row.original.call.dialNumber,
      passcode: row.original.call.passcode,
      tags,
      visibility,
    };
    setShowCreatePawfficeHourDialog(true);
  };

  const handleDeletePress = (row: MRT_Row<IPawfficeHour>) => {
    requestPayload.current = { id: row.original._id, actionType: 'delete' };
    setDialogContent({
      title: 'Delete Pawffice Hour?',
      message: `Are you sure you want to delete ${row.original.name}?`,
    });
  };

  const renderPawfficeHourTableActions = ({ row }: { row: MRT_Row<IPawfficeHour> }) => {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" gap="0.5rem">
        <Link to={`/admin-panel/pawffice/${row.original._id}`} target="_blank">
          <ActionButton title="Details Page" Icon={<Launch />} color="blue" />
        </Link>

        {active.label === 'Scheduled' && (
          <>
            <ActionButton
              title="Edit"
              Icon={<Edit />}
              color="gray"
              onClick={() => handleEditPress(row)}
            />
            <ActionButton title="Delete" Icon={<Delete />} onClick={() => handleDeletePress(row)} />
          </>
        )}
      </Box>
    );
  };

  const knowledgeTagsOptions = knowledgeTags?.map(tag => ({ value: tag, label: tag }));
  const visibilityOptions = userRoles?.map(role => ({
    value: role,
    label: role.charAt(0).toUpperCase() + role.slice(1),
  }));

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data ?? []}
        rowCount={total ?? 0}
        initialState={{
          columnVisibility: { status: false },
          pagination: { pageSize: 10, pageIndex: 0 },
        }}
        memoMode="cells"
        state={{
          isLoading: isLoading || isLoadingKnowledgeTags || isLoadingUserRoles,
          showAlertBanner: isError,
          showProgressBars: isFetching,
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 20, 50],
        }}
        enableColumnOrdering
        enableGlobalFilter={false}
        renderTopToolbarCustomActions={() => (
          <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', pb: '2rem', flexWrap: 'wrap' }}>
            <TTButton
              className="btn-primary-orchid"
              height={40}
              width={200}
              disabled={false} // isFetching
              text="Export to CSV"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
            />

            <TTButton
              className="btn-primary-orchid"
              height={40}
              width={280}
              marginLeft="10px"
              text="Create New Pawffice Hour"
              onClick={() => {
                pawfficeHourToEdit.current = undefined;
                setShowCreatePawfficeHourDialog(true);
              }}
            />
          </Box>
        )}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={renderPawfficeHourTableActions}
        renderDetailPanel={({ row }) => <PawfficeHourDetails pawfficeHour={row.original} />}
      />

      <AlertDialog
        isOpened={Boolean(dialogContent)}
        dialogContent={dialogContent}
        isLoading={isDeleting}
        onAgree={() => {
          if (!requestPayload.current) return;

          const { id, actionType } = requestPayload.current;
          if (actionType === 'delete') deletePawfficeHourMutate(id);
        }}
        handleClose={() => setDialogContent(null)}
      />

      {showCreatePawfficeHourDialog && (
        <CreatePawfficeHoursDialog
          open={showCreatePawfficeHourDialog}
          handleClose={() => {
            refreshPawfficeHourList();
            setShowCreatePawfficeHourDialog(false);
          }}
          visibilityOptions={visibilityOptions}
          knowledgeTagsOptions={knowledgeTagsOptions}
          pawfficeHourToEdit={pawfficeHourToEdit.current}
        />
      )}
    </>
  );
};

export default PawfficeHoursTable;
