import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

//components
import ResourceTable from './ResourceTable';
import TabPanel from 'components/common/TabPanel';
//constants
import { adminPanelResourcesSubTabs } from 'constants/tabs';

const Articles = () => {
  const [active, setActive] = useState(adminPanelResourcesSubTabs[1]);

  return (
    <>
      <h3 className="mb-4">Resources</h3>
      <TabPanel
        tabsList={adminPanelResourcesSubTabs}
        current={active.value}
        setActive={setActive}
      />
      <ResourceTable activeTab={active} />
    </>
  );
};

export default Articles;
