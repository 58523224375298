import axios from 'axios';
import find from 'lodash/find';

//types
import { ICloudflareUpload, IFileUpload } from 'types';
//services
import API from 'services/api';

// getCloudflareUploadUrl
export const getCloudflareUploadUrl = async () => {
  try {
    const res = await API.get('/image');

    return res?.data?.result?.uploadURL as string;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error', error);

    return null;
  }
};

export const uploadImageForForms = async (params: ICloudflareUpload | IFileUpload) => {
  const formData = new FormData();
  formData.append('file', params.image);

  // if (params.url) return axios.post(params.url, formData);
  if (params.url) {
    try {
      const res = await axios.post(params.url, formData);

      const imageUrl = find(res?.data?.result?.variants, function (item) {
        return item.includes('/public');
      });

      return imageUrl as string;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);

      return '';
    }
  } else throw new Error('No URL provided');
};

export const uploadImage = async (params: ICloudflareUpload | IFileUpload) => {
  const formData = new FormData();
  formData.append('file', params.image);

  if (params.url) return axios.post(params.url, formData);
  else throw new Error('No URL provided');
};
