//services
import API from 'services/api';

// Resources
export const getResources = async (params = {}) => {
  const queryParams = new URLSearchParams(params).toString();
  const res = await API.get(`/resource?${queryParams}`);

  return res.data;
};

// Public resources
export const getPublicResources = async (params = {}) => {
  const queryParams = new URLSearchParams(params).toString();
  const res = await API.get(`/public-resource?${queryParams}`);

  return res.data;
};
export const getPublicResource = async (id: string) => API.get(`/public-resource/${id}`);
