import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { unregisterFromPawfficeHour } from 'services/api/apiCalls/pawfficeHour';
//types
import { IAddDeleteAttendeeSchema, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useMutationUnregisterFromPawfficeHour = () => {
  const queryClient = useQueryClient();

  const {
    mutate: unregisterPawfficeHourMutate,
    isLoading: isUnregistring,
    error: unregisterPawfficeHourError,
  } = useMutation<AxiosResponse<string>, AxiosError<TError>, IAddDeleteAttendeeSchema>(
    data => unregisterFromPawfficeHour(data.pawfficeId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.PAWFFICE_HOUR_LIST, {
          refetchInactive: true,
        });
      },
    },
  );

  return {
    unregisterPawfficeHourMutate,
    isUnregistring,
    unregisterPawfficeHourError,
  };
};
