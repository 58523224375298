import React from 'react';
import { Box, Rating } from '@mui/material';
import { Form } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldError,
  FieldPath,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';

//types
import { ICreateEditBreedEncyclopaediaSchema } from 'types';
//components
import InfoView from 'components/common/InfoView';
//assets
import ClawFill from 'assets/images/adminPanel/breedEncyclopaedia/claw-fill.svg';
import ClawUnfill from 'assets/images/adminPanel/breedEncyclopaedia/claw-unfill.svg';

const RatingView = ({
  defaultValue = 0,
  ratingLabel,
  ratingFieldName,
  infoLabel,
  infoFieldName,
  control,
  register,
  ratingError,
  infoError,
}: {
  defaultValue?: number;
  ratingLabel: string;
  ratingFieldName: FieldPath<ICreateEditBreedEncyclopaediaSchema>;
  infoLabel: string;
  infoFieldName: FieldPath<ICreateEditBreedEncyclopaediaSchema>;
  control: Control<ICreateEditBreedEncyclopaediaSchema>;
  register: UseFormRegister<ICreateEditBreedEncyclopaediaSchema>;
  watch: UseFormWatch<ICreateEditBreedEncyclopaediaSchema>;
  ratingError: FieldError | undefined;
  infoError: FieldError | undefined;
}) => (
  <Box display="flex" gap={5}>
    <InfoView
      p={0}
      mt={3}
      label={`${ratingLabel}:`}
      value={
        <Controller
          control={control}
          name={ratingFieldName}
          render={({ field: { onChange } }) => (
            <Box display="flex" flexDirection="column">
              <Rating
                name={ratingFieldName}
                defaultValue={defaultValue}
                getLabelText={(val: number) => `${val} Heart${val !== 1 ? 's' : ''}`}
                icon={<img className="ebe_rating-icon" src={ClawFill} alt="" />}
                emptyIcon={<img className="ebe_rating-icon" src={ClawUnfill} alt="" />}
                onChange={onChange}
              />
              {ratingError && <span className="text-error mt-2">{ratingError?.message}</span>}
            </Box>
          )}
        />
      }
      vertical
    />

    <InfoView
      p={0}
      mt={3}
      sx={{ flex: 1 }}
      label={`${infoLabel}:`}
      value={
        <Box display="flex" flexDirection="column">
          <Form.Control
            bsPrefix="input form-control mb-0 mt-1 py-3"
            placeholder={`Enter ${infoLabel?.toLowerCase()}`}
            {...register(infoFieldName)}
          />
          {infoError && <span className="text-error mt-2">{infoError?.message}</span>}
        </Box>
      }
      vertical
    />
  </Box>
);

export default RatingView;
