import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import ImageUploader from 'react-images-upload';
import { isEmpty } from 'lodash';
import { useQueryClient, useIsMutating } from 'react-query';

//hooks
import {
  useMutationUploadBreederGallery,
  useMutationUploadImagesToServer,
} from './__hooks/useMutationUploadBreederGallery';
//components
import TTButton from 'components/common/TTButton';
//services
import { getCloudflareUploadUrl } from 'services/api/apiCalls/cloudflare';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const ImageUpload = ({
  uploadedImages,
  setUploadedImages,
  breederId,
  numberOfImages,
}: {
  uploadedImages: string[];
  setUploadedImages: Dispatch<SetStateAction<string[]>>;
  breederId: string;
  numberOfImages: number;
}) => {
  const queryClient = useQueryClient();
  const isMutating = useIsMutating();
  const [imagesForGallery, setImagesForGallery] = useState<File[]>([]);
  const [isUploadClicked, setIsUploadClicked] = useState<boolean>(false);
  const [numberOfImagesErrorVisible, setNumberOfImagesErrorVisible] = useState<boolean>(false);
  const [key, setKey] = useState('1');

  //Mutation hooks
  const { uploadImagesToServer } = useMutationUploadImagesToServer();
  const { uploadBreederGallery, isUploadingBreederGallery } = useMutationUploadBreederGallery(
    breederId,
    uploadImagesToServer,
  );

  const onDrop = (images: File[], pictureDataURLs: string[]) => {
    if (uploadedImages.length + numberOfImages + images.length < 9) {
      setNumberOfImagesErrorVisible(false);

      setUploadedImages([...uploadedImages, ...pictureDataURLs]);
      setImagesForGallery([...imagesForGallery, ...images]);
    } else {
      setNumberOfImagesErrorVisible(true);

      setKey(key + 1);
      setUploadedImages([]);
      setImagesForGallery([]);
    }
  };

  useEffect(() => {
    if (isMutating === 0 && isUploadClicked) {
      queryClient.invalidateQueries(QUERY_KEYS.BREEDER);
      setIsUploadClicked(false);
    }
  }, [isMutating, isUploadClicked, queryClient]);

  return (
    <div className="upload_container">
      <ImageUploader
        withIcon={true}
        onChange={onDrop}
        imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
        maxFileSize={50242880}
        key={key}
      />

      {!isEmpty(uploadedImages) && (
        <>
          <div className="d-flex w-100 justify-content-center align-items-center">
            <TTButton
              type="submit"
              className="btn-neutral-navy mt-2 m-auto"
              text="Upload to your gallery"
              width="auto"
              height="40px"
              loading={isUploadingBreederGallery}
              onClick={async () => {
                await Promise.all(
                  imagesForGallery.map(async image => {
                    const cloudflareUploadUrl = await getCloudflareUploadUrl();

                    if (cloudflareUploadUrl !== null) {
                      uploadBreederGallery({
                        pathAndName: `images/breeders/${breederId}/${Date.now()}`,
                        image: image,
                        url: cloudflareUploadUrl,
                      });
                    }
                  }),
                );

                setIsUploadClicked(true);
              }}
            />
          </div>

          <div className="gallery_upload">
            {uploadedImages.map((image, index) => (
              <div key={index} className="upload_thumbnail">
                <img alt="not found" src={image} />
              </div>
            ))}
          </div>
        </>
      )}

      {numberOfImagesErrorVisible && (
        <div className="error_images">You can only upload 8 images!</div>
      )}
    </div>
  );
};

export default ImageUpload;
