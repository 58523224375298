export const breedingClubs = [
  'Acadiana Kennel Club (AKC)',
  'Admiral Perry Obedience Training Club',
  'Agility Club of San Diego',
  'Allentown Dog Training Club, Inc.',
  'American Kennel Club, Inc.',
  'Ann Arbor Dog Training Club',
  'Asheville Kennel Club, Inc.',
  'Atlanta Obedience Club, Inc.',
  'Australian Labradoodle Association of America (ALAA)',
  'Basset Hound Club of Greater Fort Worth, Inc.',
  'Bearded Retriever Club of America',
  'Belgian Tervuren Club of Southern California',
  'Bell Vernon Kennel Association, Inc.',
  'Berks County Dog Training Club',
  'Bernese Mountain Dog Club of Nashoba Valley',
  'Big Sky Tracking Dog Club of Montana',
  'Birmingham Obedience Training Club, Inc.',
  'Blue Ridge Dog Training Club',
  'Brevard Kennel Club',
  'Buckeye Tracking Club',
  'Buckhorn Valley Kennel Club, Inc.',
  'Burlington County Kennel Club, Inc.',
  'Burlington Obedience Training Club',
  'Calcasieu Kennel Club, Inc.',
  'Capital Dog Training Club of Washington, D.C., Inc.',
  'Capitol Canine Training Club of Springfield, Illinois',
  'Car-Dun-Al Obedience Dog Training Club, Inc.',
  'Cascade English Cocker Spaniel Fanciers',
  'Cascade Pembroke Welsh Corgi Club',
  'Central Kentucky Herding Group Club',
  'Central New York Kennel Club, Inc.',
  'Charleston Dog Training Club',
  'Charlotte Dog Training Club',
  'Chattahoochee English Springer Spaniel Club of Greater Atlanta',
  'Chuckanut Dog Training Association',
  'Clarksville Kennel Club',
  'Cleveland All-Breed Training Club, Inc.',
  'Colonial Rottweiler Club',
  'Columbia Basin Dog Training Club, Inc.',
  'Columbine State Poodle Club, Inc.',
  'Companion Dog Training Club of Flint, Inc.',
  'Concord Dog Training Club, Inc.',
  'Cornhusker Kennel Club of Lincoln, Nebraska, Inc.',
  'Croix Valley Tracking Club',
  'Cuyahoga Valley Golden Retriever Club',
  'Cyclone Country Kennel Club of Ames',
  'Dal-Tex Basset Hound Club',
  'Dallas Obedience Training Club',
  'Dallas-Ft. Worth Metro Golden Retriever Club',
  'Dauphin Dog Training Club, Inc.',
  'Davis Dog Training Club',
  'Dayton Dog Training Club, Inc.',
  'Deep South All Breed Obedience Training Club',
  'Del Bay Herding Club',
  'Denver Foothills Tracking Association',
  'Des Moines Obedience Training Club',
  'DFW Tracking Club',
  'Dog Obedience Club of Hollywood, Inc.',
  'Dog Obedience Club of Lee County Florida',
  'Dog Obedience Training Club of Anchorage, Inc.',
  'Dog Obedience Training Club of Rochester, New York',
  "Dog Owners' Training Club of Maryland, Inc.",
  'Dog Tracking Club of Maine',
  'Dog Training Club of Champaign - Urbana',
  'Dog Training Club of Tampa',
  'Durham Kennel Club Inc',
  'Emerald Dog Obedience Club, Inc.',
  'Ephrata-Moses Lake Kennel Club',
  'Evergreen Golden Retriever Club',
  'Evergreen State Shetland Sheepdog Club',
  'Farmington Valley Kennel Club, Inc.',
  'Finger Lakes Kennel Club, Inc.',
  'Flagstaff Kennel Club',
  'Forest City Dog Training Club',
  'Fort Detroit Golden Retriever Club',
  'Fort Wayne Obedience Training Club',
  'Fox River Field Spaniel Club',
  'Fox Valley Dog Training Club, Inc.',
  'Fremont Dog Training Club, Inc',
  'Gamblers Agility Club of Greater Las Vegas',
  'Gateway Tracking Club',
  'German Shepherd Dog Club of Austin',
  'German Shepherd Dog Club of Greater Kansas City, Inc.',
  'German Shepherd Dog Club of Northern Ohio, Inc.',
  'German Shepherd Dog Club of Oregon, Inc.',
  'German Shepherd Dog Club of Sacramento Valley, Inc.',
  'German Shepherd Dog Club of San Diego County',
  'German Shepherd Dog Club of Southern Arizona, Inc.',
  'German Shepherd Dog Club of Southern New Hampshire',
  'German Shepherd Dog Club of Washington State, Inc.',
  'German Shepherd Dog Training Club of Chicago, Inc.',
  'German Shorthaired Pointer Club of Middle Tennessee',
  'Glenbard All Breed Obedience Club',
  'Gloucester County Kennel Club, Inc.',
  'Golden Retriever Club of Greater St. Louis',
  'Golden Retriever Club of Illinois',
  'Golden Retriever Club of San Diego County, Inc.',
  'Golden State German Shepherd Dog Club of San Jose, Inc.',
  'Golden State Rottweiler Club',
  'Golden Triangle Obedience Training Club',
  'Goldendoodle Association of North America (GANA)',
  'Grand Rapids Agility Club',
  'Great Falls Dog Training Club',
  'Great Lakes Belgian Tervuren Club',
  'Great Salt Lake Dog Training Club',
  'Greater Clarksburg WV Kennel Club, Inc.',
  'Greater Houston Golden Retriever Club',
  'Greater Kansas City Dog Training Club, Inc.',
  'Greater Kansas City Shetland Sheepdog Club',
  'Greater Lafayette Kennel Club',
  'Greater Louisville Training Club, Inc.',
  'Greater Lowell Kennel Club, Inc.',
  'Greater Ocala Dog Club, Inc.',
  'Greater Pittsburgh Tracking Club',
  'Greater St. Louis Training Club, Inc.',
  'Green Mountain Golden Retriever Club',
  'Gulf Coast German Shorthaired Pointer Club',
  'Hollywood Dog Obedience Club, Inc.',
  'Hot Springs National Park Kennel Club',
  'Houston Obedience Training Dog Club, Inc.',
  'Hudson Valley Tracking Club',
  'Hyattsville Dog Training Club',
  'Idaho Capital City Kennel Club, Inc.',
  'Indian Nations Tracking Club',
  'Inland Empire Collie Club',
  'Iowa City Dog Obedience Club',
  'James River Kennel Club, Inc.',
  'Kalamazoo Kennel Club, Inc.',
  'Kansas City Golden Retriever Club',
  'Lackawanna Kennel Club, Inc.',
  "Land O'Lakes Kennel Club, Inc.",
  'Lenape Tracking Club of Central New Jersey',
  'Lexington Kennel Club Inc',
  'Lincolnwood Training Club, Inc. for German Shepherd Dogs',
  'Longs Peak Dog Training Club',
  'Louisiana Capital City Obedience Club',
  'Lowchen Club of America',
  'Luckiamute Tracking Club',
  'Lyons Township Dog Training Club, Inc.',
  'Marion Alachua Dog Training Association',
  'Marshbanks Golden Retriever Club of South-Central Michigan',
  'Marshfield Area Kennel Club',
  'Mason-Dixon German Shorthaired Pointer Club',
  'Mastiff Club of America, Inc.',
  'Mattaponi Kennel Club',
  'Mayflower Pembroke Welsh Corgi Club',
  'Medallion Rottweiler Club',
  'Mi-Ki Club of America',
  'Miami Obedience Club',
  'Mid Jersey Labrador Retriever Club',
  'Mid New Jersey Petit Basset Griffon Vendeen Association',
  'Mid-Coast Kennel Club of Maine',
  'Mid-Florida Golden Retriever Club',
  'Mid-Kentucky Kennel Club, Inc.',
  'Mile-High Rottweiler Club of Greater Denver, Colorado',
  'Miniature American Shepherd Club of the USA',
  'Miniature Bull Terrier Club of America',
  'Miniature Pinscher Club of America, Inc.',
  'Missouri Rhineland Kennel Club',
  'Mixed Breed Dog Club',
  'Monterey Bay Dog Training Club, Inc.',
  'Moraine Tracking Club',
  'Mound City Obedience Training Club',
  'Mountain States Dog Training Club, Inc.',
  'Mt. Diablo Dog Training Club, Inc.',
  'Mt. Nittany Dog Training Club',
  'Mudi Club of America',
  'Napa Valley Dog Training Club',
  'Nashville Dog Training Club',
  'National American English Coonhound Association',
  'National Beagle Club',
  'National Bluetick Coonhound Association',
  'National Entlebucher Mountain Dog Association',
  'National Redbone Coonhound Club',
  'National Shiba Club of America',
  'National Treeing Walker Coonhound Association',
  'Nebraska Kennel Club',
  'Nederlandse Kooikerhondje Club of the United States of America',
  'New England Dog Training Club, Inc.',
  'Newfoundland Club of America, Inc.',
  'Nisqually Kennel Club',
  'Nita Nee Kennel Club, Inc.',
  'Norcal Golden Retriever Club',
  'Norfolk Terrier Club',
  'North Country Kennel Club, Inc.',
  'North Shore Dog Training Club, Inc.',
  'North Shore Kennel Club',
  'Northern Flyway Golden Retriever Club of Wisconsin',
  'Northwest Obedience Club, Inc. of Suburban Chicago',
  'Norwegian Buhund Club of America',
  'Norwegian Elkhound Association of America, Inc.',
  'Norwegian Lundehund Association of America',
  'Norwich Terrier Club of America',
  'Nova Scotia Duck Tolling Retriever Club (USA)',
  'Oak Ridge Kennel Club',
  'Oakland Dog Training Club, Inc.',
  'Obedience Club of Daytona',
  'Obedience Club of San Diego County, Inc.',
  'Obedience Training Club of Chugiak',
  'Obedience Training Club of Greater Lansing',
  'Obedience Training Club of Hawaii, Inc.',
  'Obedience Training Club of Palm Beach County',
  'Oconee River Kennel Club',
  'Old English Sheepdog Club of America, Inc.',
  'On Track Agility Club of Maine',
  'Orange Empire Dog Club, Inc.',
  'Oriole Dog Training Club',
  'Orlando Dog Training Club',
  'Otterhound Club of America',
  'Pacific Northwest Portuguese Water Dog Club',
  'Pacific Northwest Standard Schnauzer Club',
  'Palo Alto Foothills Tracking Association',
  'Papillon Club of America, Inc.',
  'Parkersburg Obedience Training Club',
  'Parson Russell Terrier Association of America',
  'Pasanita Obedience Club Inc.',
  'Pecatonica Tracking Club, Inc.',
  'Pekingese Club of America',
  'Pembroke Welsh Corgi Club of America, Inc.',
  'Pembroke Welsh Corgi Club of the Rockies',
  'Peoria Obedience Training Club',
  'Peruvian Inca Orchid Club of the United States',
  'Petit Basset Griffon Vendeen Club of America',
  'Pharaoh Hound Club of America',
  'Phoenix Field and Obedience Club',
  'Piedmont Border Collie Association',
  'Pioneer Valley Kennel Club, Inc.',
  'Plott Hound Association of America',
  'Poodle Club of America, Inc.',
  'Port Chester Obedience Training Club, Inc.',
  'Portland Dog Obedience Club, Inc.',
  'Portuguese Podengo Pequenos of America',
  'Portuguese Water Dog Club of America, Inc.',
  'Portuguese Water Dog Club of Greater Chicagoland',
  'Potomac Valley Golden Retriever Club',
  'Princeton Dog Training Club',
  'Pug Dog Club of America, Inc.',
  'Puget Sound Doberman Pinscher Club',
  'Puget Sound Labrador Retriever Association',
  'Puli Club of America, Inc.',
  'Pyrenean Shepherd Club of America',
  'Queen City Dog Training Club, Inc.',
  'Rand Park Dog Training Club',
  'Rat Terrier Club of America',
  'Red Canyons Kennel Club',
  'Redwood Belgian Tervuren Fanciers',
  'Rhodesian Ridgeback Club of the United States, Inc.',
  'Richland Kennel Club, Inc.',
  'Richmond Dog Obedience Club, Inc.',
  'Rock Creek Kennel Club',
  'Russian Toy Club of America',
  'Russian Tsvetnaya Bolonka Club of America',
  'Sacramento Dog Training Club',
  'Sacramento Kennel Club',
  'Sacramento Sierra Norwegian Elkhound Club',
  'Sacramento Valley Weimaraner Club',
  'Saluki Club of America',
  'Samoyed Club of America, Inc.',
  'San Antonio Dog Training Club, Inc.',
  'San Jacinto Kennel Club',
  'San Joaquin Dog Training Club',
  'Sandia Dog Obedience Club, Inc.',
  'Santa Ana Valley Kennel Club, Inc.',
  'Santa Barbara Kennel Club, Inc.',
  'Santa Clara Dog Training Club, Inc.',
  'Schipperke Club of America, Inc.',
  'Scottish Deerhound Club of America, Inc.',
  'Scottish Terrier Club of America',
  'Show Me Agility Club of Central Missouri',
  'Siberian Husky Club of America, Inc.',
  'Silky Terrier Club of America, Inc.',
  'Sioux Empire Kennel Club, Inc.',
  'Skye Terrier Club of America',
  'Skyline Kennel Club, Inc.',
  'Small Munsterlander Club of America',
  'Soft Coated Wheaten Terrier Club of America',
  'Southern Michigan Obedience Training Club',
  'Southern Oregon Kennel Club',
  'Southwest Obedience Club of Los Angeles, Inc.',
  'Southwest Tracking Association of Metropolitan Houston',
  'Southwestern Rottweiler Club of San Diego',
  'Spanish Water Dog Club of America',
  'Spinone Club of America',
  'Spokane Dog Training Club, Inc.',
  "Sportsmen's Dog Training Club of Detroit, Inc.",
  'Springfield MO Dog Training Club, Inc.',
  'St. Bernard Club of America, Inc.',
  'St. Lawrence Valley Dog Club',
  'Staffordshire Bull Terrier Club of America',
  'Staffordshire Terrier Club of America',
  'Standard Schnauzer Club of America',
  'Suffolk Obedience Training Club, Inc.',
  'Suncoast Basset Hound Club of Tampa, FL',
  'Sussex Spaniel Club of America',
  'Swedish Vallhund Club of America',
  'Syracuse Obedience Training Club',
  'Taconic Hills Kennel Club, Inc.',
  'Tailwaggers Agility Club of South FL',
  'Tanana Valley Kennel Club',
  'Tarheel Weimaraner Club',
  'Terry-All Kennel Club, Inc.',
  'Texas Tri-City Obedience Club, Inc.',
  'Thorn Creek Tracking Club',
  'Tibetan Spaniel Club of America',
  'Tibetan Terrier Club of America, Inc.',
  'Tidewater Tracking Club of Virginia',
  'Timber Ridge Obedience Club of Central Wisconsin',
  'Timberline Basset Hound Club',
  'Tioga County Kennel Club',
  'Toledo Kennel Club, Inc.',
  'Tracking Association of North Alabama',
  'Tracking Club of Central Florida',
  'Tracking Club of El Paso',
  'Tracking Club of Massachusetts',
  'Tracking Club of Vermont',
  'Twin Cities Obedience Training Club, Inc.',
  'Twin Ports Dog Training Club',
  'Two Rivers Agility Club of Sacramento',
  'United States Australian Shepherd Association',
  'United States Kerry Blue Terrier Club, Inc.',
  'United States Lakeland Terrier Club',
  'United States Lancashire Heeler Club',
  'United States Neapolitan Mastiff Club',
  'United States of America Coton de Tulear Club',
  'Upper Snake River Valley Dog Training Club, Inc.',
  'Vallejo Dog Training Club, Inc.',
  'Valley Hills Obedience Club, Inc.',
  'Vizsla Club of America, Inc.',
  'Washington State Obedience Training Club, Inc.',
  'Weimaraner Club of America',
  'Weimaraner Club of Northern Illinois',
  'Weimaraner Club of the Washington DC Area',
  'Welsh Springer Spaniel Club of America, Inc.',
  'Welsh Terrier Club of America, Inc.',
  'Wenatchee Kennel Club',
  'West Coast Cocker Spaniel Club',
  'West Highland White Terrier Club of America',
  'West Highland White Terrier Club of Northern Illinois',
  'Western Waukesha County Dog Training Club',
  'Whidbey Island Kennel Club Inc',
  'White River Golden Retriever Club',
  'Williamsport Dog Training Club',
  'Winston-Salem Dog Training Club, Inc.',
  'Wirehaired Vizsla Club of America',
  'Wisconsin English Springer Spaniel Association, Inc.',
  'Worldwide Australian Labradoodle Association (WALA)',
  'Xoloitzcuintli Club of America',
  'Yankee Golden Retriever Club',
  'York County Dog Training Club',
  'Yorkshire Terrier Club of America, Inc.',
  'Youngstown All Breed Training Club, Inc.',
].map(club => ({ label: club, value: club }));
