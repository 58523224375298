import React, { Fragment, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

//hooks
import useQueryGetDealList from './__hooks/useQueryGetDealList';
//types
import { IDeal, IUserInfo } from 'types';
//components
import Banner from 'components/common/HeadingBanner';
import DealCard from './DealCard';
import TTButton from 'components/common/TTButton';
//styles
import './index.scss';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const Deals = () => {
  const { ref, inView } = useInView();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const userInfo = queryClient.getQueryData(QUERY_KEYS.USER_STATUS) as {
    data: IUserInfo | undefined;
  };

  // Query hooks
  const { data, isLoadingDeals, isFetchingNextPage, fetchNextPage, hasNextPage, errorDeals } =
    useQueryGetDealList([]);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  if (errorDeals)
    return (
      <div className="d-flex width-full justify-content-center my-2">
        <p className="text-error">Something went wrong</p>
      </div>
    );

  return (
    <div className="deals">
      <Banner
        title="Tail-Wagging Deals!"
        subTitle={
          <span>
            With all that goes into your program, we want to do what we can by sharing deals, perks,
            and discounts exclusively for the trusted TellTail breeder community.
          </span>
        }
        bgColor="darkGreen"
      />

      <Container>
        {!userInfo?.data?.isSfcBreeder && (
          <div className="deals-banner" id="upgrade">
            <div>
              <h6>Upgrade to TellTail Pro to access our deals</h6>
              <p>Unlock exclusive deals (and more!) by subscribing to TellTail Pro.</p>
            </div>
            <TTButton
              width="auto"
              height="40px"
              fontSize="12px"
              borderRadius="10px"
              type="submit"
              className="btn-primary-orchid"
              text="Subscribe now"
              onClick={() => navigate('/get-certified')}
            />
          </div>
        )}

        <p className="deals_header">Deals & Offers</p>
        {isLoadingDeals ? (
          <div className="d-flex width-full justify-content-center my-3">
            <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
          </div>
        ) : (
          <>
            <Row style={{ marginTop: 12 }}>
              {data?.pages?.map((page, index) => (
                <Fragment key={index}>
                  {page.items.map((deal: IDeal, idx: number) => (
                    <Col lg={3} md={4} sm={6} xs={12} key={`${deal._id}${idx}`} className="mb-4">
                      <DealCard
                        deal={deal}
                        isSfcBreeder={
                          userInfo?.data?.isSfcBreeder ? userInfo?.data?.isSfcBreeder : false
                        }
                      />
                    </Col>
                  ))}
                </Fragment>
              ))}
            </Row>
            <div className="d-flex justify-content-center">
              <button
                ref={ref}
                onClick={() => fetchNextPage()}
                disabled={!hasNextPage || isFetchingNextPage}
                className="btn-loading"
              >
                {isFetchingNextPage ? 'Loading more...' : hasNextPage ? 'Load Newer' : ''}
              </button>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default Deals;
