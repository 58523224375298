import { useInfiniteQuery } from 'react-query';

//services
import { getAdminBreederNoteList, getAdminBuyerNoteList } from 'services/api/apiCalls/admin';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useQueryGetNoteList = (userId: string, filter: string, isBuyer: boolean) => {
  const {
    data,
    isLoading,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    error,
  } = useInfiniteQuery(
    [isBuyer ? QUERY_KEYS.ADMIN_BUYER_NOTE_LIST : QUERY_KEYS.ADMIN_BREEDER_NOTE_LIST, filter],
    isBuyer
      ? ({ pageParam = 1 }) =>
          getAdminBuyerNoteList(userId, {
            page: pageParam,
            limit: 10,
            filter,
          })
      : ({ pageParam = 1 }) =>
          getAdminBreederNoteList(userId, {
            page: pageParam,
            limit: 10,
            filter,
          }),
    {
      getNextPageParam: lastPage => {
        const currentPage = Number(lastPage.meta.currentPage);
        if (lastPage.meta.totalPages === 0 || currentPage === lastPage.meta.totalPages) {
          return undefined;
        } else return currentPage + 1;
      },
    },
  );

  return {
    data,
    isLoading,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    error,
  };
};

export default useQueryGetNoteList;
