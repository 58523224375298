import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { followUnfollowConversation } from 'services/api/apiCalls/community';
//types
import { IFollowUnFollowCoversationResponse, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useMutationFollowDiscussion = () => {
  const queryClient = useQueryClient();

  const { mutate: followDiscussionMutate, isLoading: isLoadingFollowUnfollow } = useMutation<
    AxiosResponse<IFollowUnFollowCoversationResponse>,
    AxiosError<TError>,
    string
  >(conversationId => followUnfollowConversation(conversationId), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.ACTIVITY_FEED_LIST, {
        refetchInactive: true,
      });
    },
  });

  return {
    followDiscussionMutate,
    isLoadingFollowUnfollow,
  };
};

export default useMutationFollowDiscussion;
