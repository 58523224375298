import React, { useEffect, useState, ChangeEvent, useCallback, useRef } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextareaAutosize,
  Card,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { isEmpty } from 'lodash';
import 'react-datepicker/src/stylesheets/datepicker.scss';

//hooks
import { useQueryUserList } from '../../Users/__hooks/useQueryUserList';
import useMutationAddNewArticle from '../__hooks/useMutationAddNewArticle';
import useMutationUpdateArticle from '../__hooks/useMutationUpdateArticle';
import useMutationUploadArticlePhoto from '../__hooks/useMutationUploadArticlePhoto';
//types
import { IDialogProps, IAddNewArticleSchema } from 'types';
//components
import InfoView from 'components/common/InfoView';
import CheckBox from 'components/common/CheckBox';
import KitImg from 'components/common/KitImg';
import RichTextEditor from 'components/common/SunTextEditor';
//assets
import noProfilePic from 'assets/images/adminPanel/no-profile-pic.svg';
//constants
import validExtensions from 'constants/images/validExtensions';
//validation
import { AddNewArticleSchema } from './Validation';
//styles
import { multiSelectStyles, selectStyles } from 'components/styled/select';
//services
import { getCloudflareUploadUrl } from 'services/api/apiCalls/cloudflare';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddNewArticleDialog({
  open,
  handleClose,
  knowledgeTagsOptions,
  userRolesOptions,
  articleDetails,
}: IDialogProps) {
  const TAG_LIMIT = 4;
  const ARTICLE_IMAGE_SIZE = 300;
  const errorSpanRef = useRef<HTMLDivElement | null>(null);
  const [articleImage, setArticleImage] = useState<File | null>(null);
  const [input, setInput] = useState('');

  const {
    register,
    watch,
    handleSubmit,
    setError,
    control,
    trigger,
    clearErrors,
    setValue,
    resetField,
    reset,
    formState: { errors },
  } = useForm<IAddNewArticleSchema>({
    resolver: yupResolver(AddNewArticleSchema),
    defaultValues: !isEmpty(articleDetails)
      ? {
          title: articleDetails?.title,
          authorName: {
            label: articleDetails?.authorName,
            value: articleDetails?.authorName,
          },
          content: articleDetails?.content,
          imageUrl: articleDetails?.imageUrl,
          articleTags:
            articleDetails?.articleTags &&
            articleDetails?.articleTags.map(item => ({ label: item, value: item })),
          visibility:
            articleDetails?.visibility &&
            articleDetails?.visibility.map(item => ({ label: item, value: item })),
          locked: articleDetails?.locked,
          publishStartDate: articleDetails?.publishStartDate,
          publishEndDate: articleDetails?.publishEndDate,
        }
      : {},
  });

  const handleDialogDismiss = () => {
    reset();
    setValue('title', '');
    setValue('description', '');
    setArticleImage(null);
    resetApiState();
    resetApiStateUpdate();
    handleClose();
  };

  const scrollToBottom = () =>
    errorSpanRef.current?.scrollIntoView({ inline: 'end', behavior: 'smooth' });

  //Mutation hooks
  const { data, refetchUserList } = useQueryUserList();
  const {
    addNewArticleMutate,
    addNewArticleIsLoading,

    articleCreationError,
    resetApiState,
  } = useMutationAddNewArticle(handleDialogDismiss, scrollToBottom);
  const { updateArticleMutate, updateArticleIsLoading, articleUpdateError, resetApiStateUpdate } =
    useMutationUpdateArticle(handleDialogDismiss, scrollToBottom);
  const { uploadArticlePhotoMutate } = useMutationUploadArticlePhoto(
    articleDetails?._id !== null && articleDetails?._id !== undefined
      ? updateArticleMutate
      : addNewArticleMutate,
  );

  useEffect(() => {
    if (open) refetchUserList();
  }, [open, refetchUserList]);

  const articleTags = watch('articleTags');

  useEffect(() => {
    if (!articleTags || !setError || !clearErrors) return;

    if (articleTags.length > 0) {
      clearErrors('articleTags');
    } else {
      setError('articleTags', { type: 'min', message: 'This field is required.' });
    }
  }, [articleTags, setError, clearErrors]);

  const handleClickBrowse = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;

    if (validExtensions.includes(file?.type)) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setValue('imageUrl', file.name);
        clearErrors('imageUrl');
        setArticleImage(file);
      };
      fileReader.readAsDataURL(file);
    }
  };

  const handleContentEditorStateChange = useCallback(
    (editorState: string) => {
      setValue('content', editorState, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [setValue],
  );

  const handleSourceEditorStateChange = useCallback(
    (editorState: string) => {
      setValue('source', editorState, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [setValue],
  );

  const onSubmit = async (addNewArticleData: IAddNewArticleSchema) => {
    const authorEmail = addNewArticleData.authorName.label.match(/\((.*)\)/)?.pop() || '';
    const authDisplayName = addNewArticleData.authorName.label.replaceAll(` (${authorEmail})`, '');
    const author = data?.find(
      item => item.displayName === authDisplayName && item.email === authorEmail,
    );
    const tags = addNewArticleData.articleTags.map(item => item.value);
    const visibileRoles = addNewArticleData.visibility.map(item => item.value);

    let payload;

    if (author) {
      payload = {
        ...addNewArticleData,
        articleTags: tags,
        authorName: authDisplayName,
        createdBy: author._id,
        visibility: visibileRoles,
        publishStartDate: moment(addNewArticleData.publishStartDate).utc().format(),
        publishEndDate: addNewArticleData.publishEndDate
          ? moment(addNewArticleData.publishEndDate).utc().format()
          : '',
      };
    } else {
      payload = {
        ...addNewArticleData,
        createdBy: null,
        articleTags: tags,
        authorName: addNewArticleData.authorName.value,
        visibility: visibileRoles,
        publishStartDate: moment(addNewArticleData.publishStartDate).utc().format(),
        publishEndDate: addNewArticleData.publishEndDate
          ? moment(addNewArticleData.publishEndDate).utc().format()
          : '',
      };
    }

    if (!articleImage) {
      if (articleDetails?._id !== null) {
        return updateArticleMutate({ ...payload, _id: articleDetails?._id });
      } else {
        return addNewArticleMutate(payload);
      }
    }

    const cloudflareUploadUrl = await getCloudflareUploadUrl();

    if (cloudflareUploadUrl !== null) {
      uploadArticlePhotoMutate({
        pathAndName: `images/articles/article_photo_${Date.now()}`,
        image: articleImage,
        data: articleDetails?._id ? { ...payload, _id: articleDetails?._id } : payload,
        url: cloudflareUploadUrl,
      });
    }
  };

  return (
    <div>
      <Dialog
        maxWidth="lg"
        open={open}
        TransitionComponent={Transition}
        onClose={handleDialogDismiss}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle bgcolor="darkblue" color="white">
          Add New Article
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            width="50vw"
            height="70vh"
            m={2}
            position="relative"
          >
            <Form.Label>Title</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0 py-3"
              placeholder="Enter title"
              {...register('title')}
            />
            {errors && errors.title && (
              <span className="text-error mt-2">{errors.title.message}</span>
            )}

            <InfoView
              p={0}
              mt={3}
              label="Author Name"
              zIndex={102}
              value={
                <Controller
                  control={control}
                  name="authorName"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      isSearchable
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onChange={(val: any) => onChange(val)}
                      value={value}
                      options={data?.map(item => ({
                        label: `${item.displayName} (${item.email})`,
                        value: `${item.displayName} (${item.email})`,
                      }))}
                      inputValue={input}
                      onInputChange={(val, action) => {
                        setInput(val);
                        if (action.action === 'input-change') {
                          if (val) {
                            setValue('authorName', { label: val, value: val });
                          } else {
                            resetField('authorName', { defaultValue: undefined });
                          }

                          trigger('authorName');
                        }
                      }}
                      isClearable
                      placeholder="Enter author name"
                      styles={selectStyles}
                      classNamePrefix="tt-select"
                    />
                  )}
                />
              }
              vertical
            />
            {errors && errors.authorName && (
              <span className="text-error">{errors.authorName.message}</span>
            )}

            <CheckBox
              checkboxClassName="mt-3"
              text="Unlock for Basic Breeder"
              defaultChecked={isEmpty(articleDetails?.locked)}
              onChange={e => {
                if (e.target.checked) setValue('locked', []);
                else setValue('locked', ['free']);
              }}
            />

            <InfoView
              p={0}
              mt={3}
              label="Visible for:"
              zIndex={100}
              value={
                <Controller
                  control={control}
                  name="visibility"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      isSearchable
                      value={value}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onChange={(val: any, actionMeta: any) => {
                        if (
                          watch('visibility') &&
                          watch('visibility').length >= TAG_LIMIT &&
                          actionMeta.action === 'select-option'
                        ) {
                          setError('visibility', {
                            type: 'custom',
                            message: ' The maximum number of tags you can select here is 4',
                          });
                        } else if (actionMeta.action === 'select-option') {
                          trigger('visibility');

                          return onChange(val);
                        } else if (
                          actionMeta.action === 'remove-value' &&
                          watch('visibility').length <= 1
                        ) {
                          setError('visibility', {
                            type: 'custom',
                            message: 'You must have at least one tag',
                          });
                        } else if (actionMeta.action === 'remove-value') {
                          trigger('visibility');

                          return onChange(val);
                        }
                      }}
                      options={userRolesOptions}
                      placeholder="Enter for which roles this article will be visible"
                      styles={multiSelectStyles}
                      classNamePrefix="tt-select"
                    />
                  )}
                />
              }
              vertical
            />
            {errors && errors.visibility && (
              <span className="text-error mt-2">{errors.visibility.message}</span>
            )}

            <Box display="flex" flexDirection="row" gap="20px" mt={2}>
              <InfoView
                flex={1}
                p={0}
                label="Start Date:"
                value={
                  <Controller
                    control={control}
                    name="publishStartDate"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        selected={value ? moment(value).toDate() : null}
                        minDate={moment(
                          moment().utc().utcOffset('-0700').format('yyyy-MM-DD'),
                        ).toDate()}
                        onChange={(date: Date) => onChange(date)}
                        popperClassName="react-datepicker-popper red-border"
                        dateFormat="yyyy-MM-dd"
                        placeholderText="YYYY-MM-DD"
                        customInput={<Form.Control bsPrefix="input form-control mt-2" />}
                      />
                    )}
                  />
                }
                vertical
              />

              <InfoView
                flex={1}
                p={0}
                label="End Date:"
                value={
                  <Controller
                    control={control}
                    name="publishEndDate"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        selected={value ? moment(value).toDate() : null}
                        minDate={
                          watch('publishStartDate')
                            ? moment(watch('publishStartDate')).toDate()
                            : null
                        }
                        onChange={(date: Date) => onChange(date)}
                        popperClassName="react-datepicker-popper"
                        dateFormat="yyyy-MM-dd"
                        placeholderText="YYYY-MM-DD"
                        customInput={<Form.Control bsPrefix="input form-control mt-2" />}
                      />
                    )}
                  />
                }
                vertical
              />
            </Box>
            <Box display="flex" flexDirection="row" gap="20px">
              <Box flex={1}>
                {errors && errors.publishStartDate && (
                  <span className="text-error mt-2">{errors.publishStartDate.message}</span>
                )}
              </Box>
              <Box flex={1}>
                {errors && errors.publishEndDate && (
                  <span className="text-error mt-2">{errors.publishEndDate.message}</span>
                )}
              </Box>
            </Box>

            <InfoView
              p={0}
              mt={3}
              label="Description:"
              value={
                <TextareaAutosize
                  minRows={8}
                  maxRows={8}
                  placeholder="Description"
                  style={{ width: '100%', marginTop: 2, borderRadius: 5, padding: 5 }}
                  {...register('description')}
                />
              }
              vertical
            />
            <InfoView
              p={0}
              mt={3}
              label="Content:"
              value={
                <RichTextEditor
                  isError={!!errors.content?.message}
                  awsFolderName={process.env.REACT_APP_AWS_OTHERS_FOLDER_PATH!}
                  containerClassName="mt-1"
                  editorStyle={{ borderRadius: 5, height: 600, maxHeight: 600, overflow: 'scroll' }}
                  fileNamePrefix="article_content"
                  onEditorStateChange={handleContentEditorStateChange}
                  knowledgeTagsOptions={knowledgeTagsOptions}
                  editorState={articleDetails?.content}
                />
              }
              vertical
            />
            {errors && errors.content && (
              <span className="text-error">{errors.content.message}</span>
            )}
            <InfoView
              p={0}
              mt={3}
              label="Source:"
              value={
                <RichTextEditor
                  awsFolderName={process.env.REACT_APP_AWS_OTHERS_FOLDER_PATH!}
                  fileNamePrefix="source_content"
                  containerClassName="mt-1"
                  editorStyle={{ borderRadius: 5, height: 200, maxHeight: 200, overflow: 'scroll' }}
                  onEditorStateChange={handleSourceEditorStateChange}
                  knowledgeTagsOptions={knowledgeTagsOptions}
                  editorState={articleDetails?.source}
                />
              }
              vertical
            />
            <InfoView
              p={0}
              mt={3}
              label="Article Image:"
              value={
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Card elevation={3}>
                    <KitImg
                      src={
                        articleImage
                          ? URL.createObjectURL(articleImage)
                          : articleDetails?.imageUrl
                          ? articleDetails?.imageUrl
                          : noProfilePic
                      }
                      kitwidth={ARTICLE_IMAGE_SIZE}
                      style={{
                        width: ARTICLE_IMAGE_SIZE,
                        maxHeight: ARTICLE_IMAGE_SIZE,
                        background: '#f5f5f5',
                        objectFit: 'contain',
                      }}
                    />
                  </Card>
                  {errors && errors.imageUrl && (
                    <span className="text-error mt-2">{errors.imageUrl.message}</span>
                  )}

                  <Box mt={2}>
                    <input
                      type="file"
                      id="admin_article_image"
                      accept="image/*"
                      onChange={handleClickBrowse}
                      onClick={event => {
                        (event.target as HTMLInputElement).value = ''; // Workarround to trigger onChange event for same file
                      }}
                      hidden
                    />
                    <label htmlFor="admin_article_image">
                      <Button variant="outlined" component="span">
                        {articleImage ? 'Change Image' : 'Add Image'}
                      </Button>
                    </label>
                  </Box>
                </Box>
              }
              vertical
            />

            <InfoView
              p={0}
              mt={3}
              label="Article tags:"
              value={
                <Controller
                  control={control}
                  name="articleTags"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      isSearchable
                      value={value}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onChange={(val: any, actionMeta: any) => {
                        if (
                          watch('articleTags') &&
                          watch('articleTags').length >= TAG_LIMIT &&
                          actionMeta.action === 'select-option'
                        ) {
                          setError('articleTags', {
                            type: 'custom',
                            message: ' The maximum number of tags you can select here is 4',
                          });
                        } else if (actionMeta.action === 'select-option') {
                          trigger('articleTags');

                          return onChange(val);
                        } else if (
                          actionMeta.action === 'remove-value' &&
                          watch('articleTags').length <= 1
                        ) {
                          setError('articleTags', {
                            type: 'custom',
                            message: 'You must have at least one tag',
                          });
                        } else if (actionMeta.action === 'remove-value') {
                          trigger('articleTags');

                          return onChange(val);
                        }
                      }}
                      options={knowledgeTagsOptions}
                      placeholder="Enter tags here (E.g. IT/Software, Pet parent communications, etc.)"
                      styles={multiSelectStyles}
                      classNamePrefix="tt-select"
                    />
                  )}
                />
              }
              vertical
            />
            {errors && errors.articleTags && (
              <span className="text-error mt-2">{errors.articleTags.message}</span>
            )}

            <span ref={errorSpanRef} className="text-error mt-4">
              {articleCreationError?.response?.data.ExceptionMessage ||
                articleCreationError?.message}
            </span>
            <span ref={errorSpanRef} className="text-error mt-4">
              {articleUpdateError?.response?.data.ExceptionMessage || articleUpdateError?.message}
            </span>
            <br />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" sx={{ bgcolor: 'lightgray' }} onClick={handleDialogDismiss}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={addNewArticleIsLoading || updateArticleIsLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {addNewArticleIsLoading || updateArticleIsLoading ? (
              <AiOutlineLoading3Quarters
                size={20}
                color="blue"
                style={{ margin: '0.2rem' }}
                className="loading-animation svg-loading"
              />
            ) : (
              'Yes'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
