import React, { useContext } from 'react';
import { intersection, isEmpty } from 'lodash';

//services
import { CBACState } from 'services/cbac';
import { TCBAC } from 'types';

// Claim based access control container component
const CBAC = ({ allowedClaims, children }: TCBAC) => {
  const {
    state: { userClaims },
  } = useContext(CBACState);
  // Check if user has needed claims
  const access = intersection(allowedClaims, userClaims);

  if (!isEmpty(access)) {
    return <>{children}</>;
  } else {
    return null;
  }
};

export default CBAC;
