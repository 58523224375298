import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { Divider } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { includes, isEmpty, map } from 'lodash';
import Cookies from 'js-cookie';

//assets
import logo from 'assets/images/header/logo.svg';
import avatar from 'assets/images/breederProfile/avatar.png';
import userOutline from 'assets/images/header/userOutline.svg';
//components
import CBAC from 'components/common/CBAC';
import TTButton from '../TTButton';
//configs
import claims from 'configs/claims';
//constants
import { getNavLinks } from 'constants/navLinks';
//services
import { AuthState } from 'services/auth';
import { DevPanelState } from 'services/devPanel';
//styles
import './index.scss';
//types
import { IDevPanelContext, IUserInfo, TAuthContext } from 'types';

const Header = ({
  userInfo,
  profilePicUrl,
  name,
  programName,
}: {
  userInfo: IUserInfo | undefined;
  profilePicUrl: string | undefined;
  name: string;
  programName: string | undefined;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    logout,
    state: { isAuthenticated },
  } = useContext(AuthState) as TAuthContext;
  const {
    state: { devPanelBackendUrl },
    clearDevPanelBackendUrl,
  } = useContext(DevPanelState) as IDevPanelContext;

  const [activeLink, setActiveLink] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  const navLinks = useMemo(
    () => getNavLinks(isAuthenticated, userInfo),
    [isAuthenticated, userInfo],
  );

  useEffect(() => {
    const allowedPaths = [...map(map(navLinks, 'links').flat(), 'path'), '/admin-panel', '/login'];
    let currentPath = location.pathname.substring(location.pathname.indexOf('/'));
    if (currentPath === '/profile') {
      currentPath = userInfo?.isBuyer ? '/profile/matches' : '/profile/leads';
    }
    if (currentPath && includes(allowedPaths, currentPath)) {
      setActiveLink(currentPath || activeLink);
    }
  }, [navLinks, location.pathname, activeLink, userInfo?.isBuyer]);

  useEffect(() => {
    document.body.style.overflowY = isOpen ? 'hidden' : 'unset';
    document.body.style.overflowX = 'hidden';
  }, [isOpen]);

  return (
    <>
      {/* Nav Bar */}
      <header>
        <div className="d-flex">
          <a
            onClick={() =>
              !isAuthenticated
                ? (window.location.href = 'https://www.telltail.com')
                : navigate('/profile')
            }
            className="tt-hover"
          >
            <img src={logo} width="200" height="40" alt="telltail logo" />
          </a>
          {!isEmpty(devPanelBackendUrl) && (
            <div
              className="dev-panel"
              title={`You are currently using this API: ${devPanelBackendUrl}`}
            >
              <TTButton
                className="btn-primary-white"
                text="Leave DevPanel"
                height="48px"
                width="180px"
                fontSize=".9rem"
                onClick={() => clearDevPanelBackendUrl()}
              />
            </div>
          )}
        </div>

        <div className="d-flex align-items-center gap-4">
          {/* Login and Signup */}
          {!isAuthenticated && (
            <div className="login-join-container">
              <Link to="/login" className="text">
                Login
              </Link>
              <a href="https://www.telltail.com/join" className="text">
                <TTButton
                  className="btn-primary-orchid"
                  text="Join TellTail"
                  width="150px"
                  height="45px"
                />
              </a>
            </div>
          )}
          <div
            className="hamburger"
            onClick={() => {
              if (!isOpen) setShowDrawer(true);

              setTimeout(() => {
                setIsOpen(!isOpen);
              }, 100);
            }}
          >
            <span className={`bar ${isOpen && 'x'}`}></span>
            <span className={`bar ${isOpen && 'x'}`}></span>
            <span className={`bar ${isOpen && 'x'}`}></span>
          </div>
        </div>
      </header>

      {/* Navigation Drawer */}
      <div className={`overlay ${!isOpen && 'overlay--hide'}`} onClick={() => setIsOpen(false)} />
      <div
        className={`drawer ${!isOpen ? 'drawer--close' : 'drawer--open'} ${
          !showDrawer && 'd-none'
        }`}
        onTransitionEnd={() => {
          if (!isOpen) setShowDrawer(false);
        }}
      >
        <div className="d-flex flex-column gap-2 overflow-auto">
          {isAuthenticated && (
            <>
              <div className="drawer__profile-container">
                <img src={profilePicUrl || avatar} />
                <div className="d-flex flex-column col-9">
                  <span className="drawer__profile-container__name text-truncate">{name}</span>
                  {!!programName && (
                    <span className="drawer__profile-container__program-name text-truncate">
                      {programName}
                    </span>
                  )}
                </div>
              </div>
              {!userInfo?.isBuyer && (
                <Link
                  to={
                    userInfo?.isSfcBreeder
                      ? `/public-profile/${userInfo?.breederId}`
                      : '/get-certified'
                  }
                  replace={!userInfo?.isSfcBreeder}
                  className="text-decoration-none"
                  onClick={() => setIsOpen(false)}
                >
                  <TTButton
                    className="drawer__button"
                    text={
                      <>
                        <img src={userOutline} alt="user-outline" />{' '}
                        {userInfo?.isSfcBreeder ? 'View My Profile' : 'Upgrade Now'}
                      </>
                    }
                    width="100%"
                    height="40px"
                    fontSize="16px"
                  />
                </Link>
              )}
            </>
          )}
          <div className={`d-flex flex-column gap-2 ${userInfo?.isBuyer ? 'mt-0' : 'mt-3'}`}>
            {navLinks.map(option => (
              <Fragment key={option.groupName}>
                <span className="drawer__header">{option.groupName}</span>
                {option.links.map(link => {
                  const isTelltailUrl = link.path.includes('telltail.com');

                  return (
                    <Link
                      key={option.groupName + link.path}
                      to={isTelltailUrl ? '#' : link.path}
                      className={`drawer__link ${
                        activeLink === link.path && 'drawer__link--selected'
                      } `}
                      onClick={() => {
                        setIsOpen(false);
                        setTimeout(() => {
                          if (isTelltailUrl) {
                            window.location.href = link.path;
                          }
                        }, 250);
                      }}
                    >
                      {link.name}
                    </Link>
                  );
                })}
              </Fragment>
            ))}
            <Divider color="#F3F4F8" />
            <CBAC allowedClaims={[claims.ADMIN]}>
              <Link
                to="/admin-panel"
                className={`drawer__link ${
                  activeLink === '/admin-panel' && 'drawer__link--selected'
                } `}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Admin Panel
              </Link>
            </CBAC>
            <Link
              to="/login"
              className={`drawer__link ${activeLink === '/login' && 'drawer__link--selected'} `}
              onClick={() => {
                if (isAuthenticated) {
                  Cookies.remove('__hstc', { sameSite: 'Lax' });
                  Cookies.remove('hubspotutk', { sameSite: 'Lax' });
                  logout();
                } else setIsOpen(false);
              }}
            >
              {isAuthenticated ? 'Sign Out' : 'Sign In'}
            </Link>
            {!isAuthenticated && (
              <Link
                to="#"
                onClick={() => {
                  setIsOpen(false);
                  setTimeout(() => {
                    window.location.href = 'https://www.telltail.com/join';
                  }, 250);
                }}
              >
                <TTButton
                  className="btn-primary-orchid login__button mt-1"
                  text="Join TellTail"
                  width="100%"
                  height="45px"
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
