import * as Yup from 'yup';

export const AddNewArticleSchema = Yup.object().shape({
  title: Yup.string()
    .max(140, 'This field only has 140 char limits.')
    .required('This field is required.'),
  authorName: Yup.object().required('This field is required.').nullable(),
  content: Yup.string().required('This field is required.'),
  imageUrl: Yup.string().required('This field is required.'),
  articleTags: Yup.array().min(1, 'This field is required.').default([]),
  visibility: Yup.array().min(1, 'This field is required.').default([]),
  publishStartDate: Yup.date()
    .required('This field is required.')
    .when('endDate', (endDate, schema) =>
      endDate && endDate !== 'Invalid Date'
        ? schema.max(endDate, "Start date can't be after end date.")
        : undefined,
    ),
  publishEndDate: Yup.date().nullable().typeError('Invalid Date'),
});
