import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { deleteNewAttendeeFromPawfficeHour } from 'services/api/apiCalls/admin';
//types
import { TError, IAddDeleteAttendeeSchema } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useMutationDeleteAttendee = (setDialogContent: (dialogContent: null) => void) => {
  const queryClient = useQueryClient();

  const {
    mutate: deleteAttendeeMutate,
    isLoading: isDeleting,
    error: deleteAttendeeError,
    reset: deleteAttendeeApiReset,
  } = useMutation<AxiosResponse<string>, AxiosError<TError>, IAddDeleteAttendeeSchema>(
    data => deleteNewAttendeeFromPawfficeHour(data.pawfficeId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.PAWFFICE_HOUR, {
          refetchInactive: true,
        });
        setDialogContent(null);
      },
    },
  );

  return {
    deleteAttendeeMutate,
    isDeleting,
    deleteAttendeeError,
    deleteAttendeeApiReset,
  };
};
