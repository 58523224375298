import React, { useMemo } from 'react';

const StatusInfo = ({
  isConfirming,
  isConfirmed,
}: {
  isConfirming: boolean;
  isConfirmed: boolean;
}) => {
  const status = useMemo(() => {
    if (isConfirming)
      return (
        <p>
          Hang tight while we search for order details.
          <br />
          <p className="error">Please do not refresh this page.</p>
        </p>
      );
    else if (isConfirmed) return <p>We’ve confirmed your details!</p>;

    return (
      <p>
        We couldn’t confirm your order details.
        <br />
        Please contact us at <a href="mailto:hello@telltail.com">hello@telltail.com</a>.
      </p>
    );
  }, [isConfirming, isConfirmed]);

  return <div className="status-info">{status}</div>;
};

export default StatusInfo;
