import React, { useReducer } from 'react';
import { isEmpty } from 'lodash';

//types
import {
  IAuthState,
  TAuthProps,
  TAction,
  TAuthContext,
  IUserBasicInfo,
  IRegistrationInfo,
  IBuyerRegistrationInfo,
  TBuyerRegistrationInfoFieldType,
  TRegistrationInfoFieldType,
} from 'types';

const initialState: IAuthState = {
  accessToken: window.localStorage.getItem('ACCESS_TOKEN') || '',
  isAuthenticated: isEmpty(window.localStorage.getItem('ACCESS_TOKEN')) ? false : true,
  email: window.localStorage.getItem('EMAIL') || '',
  breederId: window.localStorage.getItem('BREEDER_ID') || '',
  isFlagged: window.localStorage.getItem('IS_FLAGGED') === 'true' ? true : false,
  basicUserInfo:
    window.localStorage.getItem('BASIC_USER_INFO') !== null
      ? JSON.parse(window.localStorage.getItem('BASIC_USER_INFO') || '')
      : { email: '', userName: '', profilePictureUrl: '' },

  registrationInfo:
    window.localStorage.getItem('REGISTRATION_INFO') !== null
      ? JSON.parse(window.localStorage.getItem('REGISTRATION_INFO') || '')
      : {
          referral: {},
          howSoon: '',
          breedingProgramForm: {},
          createAccountForm: {},
          vouchedLegalNameForm: {},
          moreAboutProgramForm: {},
          addLittersBreeds: [],
          buildProfileForm: { profilePic: null },
          hearAboutUsForm: {},
        },
  buyerRegistrationInfo:
    window.localStorage.getItem('REGISTRATION_INFO_BUYER') !== null
      ? JSON.parse(window.localStorage.getItem('REGISTRATION_INFO_BUYER') || '')
      : {
          howSoon: '',
          breeds: [],
          budget: null,
          createAccountForm: {},
          buildProfileForm: { profilePic: null },
          hearAboutUsForm: {},
        },
};

export const AuthState = React.createContext<TAuthContext | null>(null);

const reducer = (state: IAuthState, action: TAction): IAuthState => {
  switch (action.type) {
    case 'SET_TOKENS':
      return {
        ...state,
        accessToken: action.accessToken,
        isAuthenticated: action.isAuthenticated,
      };
    case 'SET_EMAIL':
      return {
        ...state,
        email: action.email,
      };
    case 'SET_IS_FLAGGED':
      return {
        ...state,
        isFlagged: action.isFlagged,
      };
    case 'SET_BASIC_BREEDER_INFO':
      return {
        ...state,
        basicUserInfo: action.basicUserInfo,
      };
    case 'SET_REGISTRATION_INFO':
      return {
        ...state,
        registrationInfo: {
          ...state.registrationInfo,
          [action.registrationFieldName]: action.registrationFieldValue,
        },
      };
    case 'SET_BUYER_REGISTRATION_INFO':
      return {
        ...state,
        buyerRegistrationInfo: {
          ...state.buyerRegistrationInfo,
          [action.buyerRegistrationFieldName]: action.buyerRegistrationFieldValue,
        },
      };
    case 'SET_BREEDER_ID':
      return {
        ...state,
        breederId: action.breederId,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
      };
    default:
      return state;
  }
};

const AuthProvider = ({ children }: TAuthProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setTokens = (accessToken: string) => {
    window.localStorage.setItem('ACCESS_TOKEN', accessToken);
    dispatch({ type: 'SET_TOKENS', accessToken, isAuthenticated: true });
  };

  const setBreederId = (breederId: string) => {
    window.localStorage.setItem('BREEDER_ID', breederId);
    dispatch({ type: 'SET_BREEDER_ID', breederId });
  };

  const setEmail = (email: string) => {
    window.localStorage.setItem('EMAIL', email);
    dispatch({ type: 'SET_EMAIL', email });
  };

  const setIsFlagged = (isFlagged: boolean) => {
    window.localStorage.setItem('IS_FLAGGED', isFlagged.toString());
    dispatch({ type: 'SET_IS_FLAGGED', isFlagged });
  };

  const setBasicBreederInfo = (basicUserInfo: IUserBasicInfo) => {
    window.localStorage.setItem('BASIC_USER_INFO', JSON.stringify(basicUserInfo));
    dispatch({ type: 'SET_BASIC_BREEDER_INFO', basicUserInfo });
  };

  const setRegistrationInfo = <K extends keyof IRegistrationInfo>(
    registrationFieldName: K,
    registrationFieldValue: TRegistrationInfoFieldType<K>,
  ) => {
    window.localStorage.setItem(
      'REGISTRATION_INFO',
      JSON.stringify({
        ...state.registrationInfo,
        [registrationFieldName]: registrationFieldValue,
      }),
    );
    dispatch({ type: 'SET_REGISTRATION_INFO', registrationFieldName, registrationFieldValue });
  };

  const setBuyerRegistrationInfo = <K extends keyof IBuyerRegistrationInfo>(
    buyerRegistrationFieldName: K,
    buyerRegistrationFieldValue: TBuyerRegistrationInfoFieldType<K>,
  ) => {
    window.localStorage.setItem(
      'BUYER_REGISTRATION_INFO',
      JSON.stringify({
        ...state.buyerRegistrationInfo,
        [buyerRegistrationFieldName]: buyerRegistrationFieldValue,
      }),
    );
    dispatch({
      type: 'SET_BUYER_REGISTRATION_INFO',
      buyerRegistrationFieldName,
      buyerRegistrationFieldValue,
    });
  };

  const logout = () => {
    window.localStorage.removeItem('ACCESS_TOKEN');
    window.localStorage.removeItem('BREEDER_ID');
    window.localStorage.removeItem('EMAIL');
    window.localStorage.removeItem('BASIC_USER_INFO');
    window.localStorage.removeItem('BREEDOPEDIA_FILTERS');
    window.localStorage.removeItem('BUYER_ID');
    window.localStorage.removeItem('REGISTRATION_INFO');
    window.localStorage.removeItem('BUYER_REGISTRATION_INFO');
    window.localStorage.removeItem('IS_AUTOLINKING_TRIED');

    // Reset identity and unload Heap on logout
    window.heap.resetIdentity();
    window.heap.unload();

    dispatch({ type: 'LOGOUT', isAuthenticated: false });
  };

  const value = {
    state,
    setTokens,
    setIsFlagged,
    setEmail,
    setBreederId,
    setBasicBreederInfo,
    setRegistrationInfo,
    setBuyerRegistrationInfo,
    logout,
  };

  return <AuthState.Provider value={value}>{children}</AuthState.Provider>;
};

export default AuthProvider;
