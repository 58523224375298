import * as Yup from 'yup';

export const LearnAndConnectSchema = Yup.object().shape({
  haveCompletedBreederPrograms: Yup.string().required('This field is required.').nullable(),
  breederPrograms: Yup.string().when('haveCompletedBreederPrograms', {
    is: 'YES',
    then: Yup.string().required('This field is required.'),
  }),
  belongsToBreedingClubs: Yup.string().required('This field is required.').nullable(),
  breedingClubs: Yup.array().when('belongsToBreedingClubs', {
    is: 'YES',
    then: Yup.array().required('This field is required.'),
  }),
  breedingProgramWebsite: Yup.string(),
  facebookUrl: Yup.string(),
  facebookGroups: Yup.string(),
  instagramUrl: Yup.string(),
  twitterUrl: Yup.string(),
  youtubeUrl: Yup.string(),
  tikTokUrl: Yup.string(),
});
