import React, { useRef } from 'react';
import { Box } from '@mui/material';

//types
import { IFilePickerInput } from 'types';

const FilePickerInput = ({ name, view, onChange }: IFilePickerInput) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <Box mt={2}>
      <input
        ref={inputRef}
        type="file"
        id={name}
        accept="image/*"
        onChange={onChange}
        onClick={event => {
          (event.target as HTMLInputElement).value = '';
        }}
        hidden
      />
      <label htmlFor={name} onClick={handleClick}>
        {view}
      </label>
    </Box>
  );
};

export default FilePickerInput;
