import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { getVideo } from 'services/api/apiCalls/videos';
//types
import { IVideo, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useQueryVideoDetails = (
  id: string,
  enabled = false,
  setVideoDialogVisible: (videoDialogVisible: boolean) => void,
) => {
  const {
    isLoading: isLoadingVideoDetails,
    isFetching: isFetchingVideoDetails,
    data: videoDetails,
    isSuccess: isSuccessVideoDetails,
  } = useQuery<AxiosResponse<IVideo>, AxiosError<TError>>(
    [QUERY_KEYS.VIDEO_DETAILS, id],
    () => getVideo(id),
    {
      enabled,
      onSuccess: () => {
        setVideoDialogVisible(true);
      },
    },
  );

  return { isLoadingVideoDetails, videoDetails, isSuccessVideoDetails, isFetchingVideoDetails };
};

export default useQueryVideoDetails;
