//services
import API from 'services/api';

//types
import { IAddDeleteAttendeeSchema } from 'types';

//Pawffice Hours
export const getPawfficeHourList = async function (query = {}) {
  const params = new URLSearchParams(query).toString();

  return API.get(`/pawffice-user/list?${params}`);
};
export const registerToPawfficeHour = (pawfficeId: string, data: IAddDeleteAttendeeSchema) =>
  API.put(`/pawffice-user/sign-up/${pawfficeId}`, data);
export const unregisterFromPawfficeHour = (pawfficeId: string, data: IAddDeleteAttendeeSchema) =>
  API.put(`/pawffice-user/unregister/${pawfficeId}`, data);
