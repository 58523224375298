import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { deleteShopifyUnprocessed } from 'services/api/apiCalls/admin';
//types
import { TError, IDialogContent } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useMutationDeleteVideo = (
  setDialogContent: (dialogContent: IDialogContent | null) => void,
) => {
  const queryClient = useQueryClient();

  const { mutate: deleteUser, isLoading: isDeleting } = useMutation<
    AxiosResponse<string>,
    AxiosError<TError>,
    string
  >(webhookId => deleteShopifyUnprocessed(webhookId), {
    onSuccess: () => {
      queryClient.removeQueries(QUERY_KEYS.SHOPIFY_UNPROCESSED_LIST);

      setDialogContent(null);
    },
  });

  return {
    deleteUser,
    isDeleting,
  };
};

export default useMutationDeleteVideo;
