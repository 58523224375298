export const buyerCSVColumns = [
  'Buyer Id',
  'First Name',
  'Last Name',
  'Phone',
  'Email',
  'State',
  'Zip Code',
  'Breeds',
  'Go Home Date',
  'Budget Min',
  'Budget Max',
  'Important Factors',
  'Notes',
  'How Did You Hear About Us',
  'Another Organisation',
  'Another Option',
  'Subscription Type',
  'Subscription Status',
];
