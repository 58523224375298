import React, { useState, useEffect, useMemo } from 'react';
import ImageUploader from 'react-images-upload';
import { isEmpty } from 'lodash';

//components
import TTButton from 'components/common/TTButton';
//services
import { getCloudflareUploadUrl, uploadImageForForms } from 'services/api/apiCalls/cloudflare';
//constants
import { Delete } from '@mui/icons-material';

const ImageGallery = ({
  images,
  setImages,
}: {
  images: string[];
  setImages: (mImages: string[]) => void;
}) => {
  const MAX_IMAGES_COUNT = 8;
  const remainingImages = useMemo(() => MAX_IMAGES_COUNT - images.length, [images]);

  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const [imagesForGallery, setImagesForGallery] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [maxImageCountExceed, setMaxImageCountExceed] = useState<boolean>(false);
  const [key, setKey] = useState(1);

  const onDrop = (imageFiles: File[], pictureDataURLs: string[]) => {
    setUploadedImages([...uploadedImages, ...pictureDataURLs]);
    setImagesForGallery([...imagesForGallery, ...imageFiles]);
    setKey(key + 1);
  };

  useEffect(() => {
    setMaxImageCountExceed(uploadedImages.length >= remainingImages);
  }, [uploadedImages, remainingImages]);

  useEffect(() => {
    if (maxImageCountExceed) {
      setUploadedImages(prev => prev.filter((_, index) => index < remainingImages));
      setImagesForGallery(prev => prev.filter((_, index) => index < remainingImages));
    }
  }, [maxImageCountExceed, remainingImages]);

  return (
    <div className="ebe_gallery_container">
      <div className="ebe_gallery_upload">
        {images.map((image, index) => (
          <div key={index} className="ebe_upload_thumbnail">
            <img alt="not found" src={image} />
            <div
              className="delete-icon"
              onClick={() => setImages(images.filter(img => img !== image))}
            >
              <Delete />
            </div>
          </div>
        ))}
      </div>

      <div className="d-flex flex-column justify-content-center align-items-center ebe_upload_container">
        {!maxImageCountExceed && (
          <ImageUploader
            key={key}
            withIcon={false}
            style={{ width: 300 }}
            onChange={onDrop}
            imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
            maxFileSize={50242880}
          />
        )}

        {!isEmpty(uploadedImages) && (
          <>
            <div className="ebe_gallery_upload">
              {uploadedImages.map((imagePath, index) => (
                <div key={index} className="ebe_upload_thumbnail">
                  <img alt="not found" src={imagePath} />
                  <div
                    className="delete-icon"
                    onClick={() => {
                      setUploadedImages(
                        uploadedImages.filter((_, imageIndex) => imageIndex !== index),
                      );
                      setImagesForGallery(
                        imagesForGallery.filter((_, imageIndex) => imageIndex !== index),
                      );
                      setKey(key + 1);
                    }}
                  >
                    <Delete />
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-4">
              <TTButton
                type="submit"
                className="btn-neutral-navy mt-2 m-auto"
                text="Upload Images To CloudFlare"
                width="auto"
                height="40px"
                loading={isUploading}
                disabled={isUploading}
                onClick={async () => {
                  setIsUploading(true);
                  const result = await Promise.all(
                    imagesForGallery.map(async imageFile => {
                      const cloudflareUploadUrl = await getCloudflareUploadUrl();

                      if (cloudflareUploadUrl !== null) {
                        return Promise.resolve(
                          await uploadImageForForms({
                            pathAndName: 'imageCarousel',
                            image: imageFile,
                            url: cloudflareUploadUrl,
                          }),
                        );
                      }
                    }),
                  );
                  setImages([...images, ...(result as string[])]);
                  setIsUploading(false);
                  //reset image uploader when images are uploaded
                  setUploadedImages([]);
                  setImagesForGallery([]);
                  setKey(key + 1);
                }}
              />
            </div>
          </>
        )}

        {maxImageCountExceed && (
          <div className="ebe_error_images">You can only upload {MAX_IMAGES_COUNT} images!</div>
        )}
      </div>
    </div>
  );
};

export default ImageGallery;
