/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useRef, useEffect } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import { Autocomplete } from '@react-google-maps/api';
import { isEmpty, find, includes } from 'lodash';
// @ts-ignore
import IntlTelInput from 'intl-tel-input/react/build/IntlTelInput.esm';
import 'intl-tel-input/build/css/intlTelInput.css';

//validation
import { personalInfoSchema } from './Validation';
//components
import TTButton from 'components/common/TTButton';
import ChangePassword from './ChangePassword';
//hooks
import { useMutationUpdateBreederInfo } from './__hooks/useMutationUpdateBreederInfo';
//types
import { IBreeder, IRegistrationForm, IUserInfo, TGoogleMapAddress } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';
import { numberValidationErrors } from 'constants/lists/numberValidationErrors';
//helpers
import { getPostalCode, getFullAddress } from 'helpers/googleMaps';

const MyAccount = ({ breederInfo, breederId }: { breederInfo: IBreeder; breederId: string }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const userInfo = queryClient.getQueryData(QUERY_KEYS.USER_STATUS) as {
    data: IUserInfo | undefined;
  };
  const placesInputRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<google.maps.places.Autocomplete>();
  const [parsedAddress, setParsedAddress] = useState<TGoogleMapAddress | null>(
    breederInfo
      ? {
          streetAddress: breederInfo.address,
          city: breederInfo.city,
          state: breederInfo.state,
          postalCode: breederInfo.zipCode,
          country: breederInfo.country,
          lat: Number(breederInfo.geoLat),
          lng: Number(breederInfo.geoLong),
        }
      : null,
  );
  const [errorCode, setErrorCode] = useState(0);
  const [isProperAddress, setIsProperAddress] = useState(true);
  const selectedAddress = useRef(
    parsedAddress ? getFullAddress(parsedAddress) : breederInfo.fullAddress,
  );

  // Form handling
  const {
    control,
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    watch,
    trigger,
    formState: { errors },
  } = useForm<IRegistrationForm>({
    resolver: yupResolver(personalInfoSchema(isProperAddress)),
    defaultValues: {
      firstName: breederInfo.firstName,
      middleName: breederInfo.middleName,
      lastName: breederInfo.lastName,
      emailAddress: breederInfo.email,
      phoneNumber: breederInfo.phoneNumber,
      fullAddress: parsedAddress ? getFullAddress(parsedAddress) : breederInfo.fullAddress,
      streetAddress: breederInfo.address,
      city: breederInfo.city,
      state: { value: breederInfo.state, label: breederInfo.state },
      zipCode: breederInfo.zipCode,
      country: { value: breederInfo.country, label: breederInfo.country },
    },
  });

  // Mutation hooks
  const { mutate, isLoading: isUpdatingUserProfile } = useMutationUpdateBreederInfo(breederId);

  const watchAddress = watch('fullAddress');

  useEffect(() => {
    if (!isEmpty(watchAddress)) trigger('fullAddress');
  }, [isProperAddress, trigger, watchAddress, watch]);

  const handlePlaceChanged = async () => {
    if (!inputRef.current) return;

    const place = inputRef.current.getPlace();

    if (!place) return;

    // Address field which contains street_number and route considers as full and accepted address
    const streetNumber = find(place?.address_components, val =>
      includes(val.types, 'street_number'),
    )?.long_name;
    const route = find(place?.address_components, val => includes(val.types, 'route'))?.long_name;
    setIsProperAddress(!!streetNumber && !!route);

    let postalCode = find(place?.address_components, val =>
      includes(val.types, 'postal_code'),
    )?.long_name;

    if (!postalCode && place.geometry?.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      postalCode = await getPostalCode(lat, lng);
    }

    selectedAddress.current = placesInputRef.current?.value || '';
    const tempParsedAddress = {
      streetAddress: `${streetNumber} ${route}`,
      city: find(place?.address_components, val => includes(val.types, 'locality'))?.long_name,
      state: find(place?.address_components, val =>
        includes(val.types, 'administrative_area_level_1'),
      )?.short_name,
      postalCode,
      country: find(place?.address_components, val => includes(val.types, 'country'))?.long_name,
      lat: place?.geometry?.location?.lat() || 0,
      lng: place?.geometry?.location?.lng() || 0,
    };
    setParsedAddress(tempParsedAddress);

    setValue('fullAddress', getFullAddress(tempParsedAddress) || '');
  };

  const setIsValid = (isValid: boolean) => {
    if (isValid) {
      clearErrors('phoneNumber');
    } else {
      const errorMessage = numberValidationErrors[errorCode] || 'Invalid number';

      setError('phoneNumber', {
        type: 'custom',
        message: errorMessage,
      });
    }
  };

  const onSubmit = async (data: IRegistrationForm) => {
    if (selectedAddress.current.toLowerCase() !== placesInputRef.current?.value.toLowerCase()) {
      return setError('fullAddress', {
        type: 'custom',
        message: 'Please enter your full street address.',
      });
    }

    const breederRequestPayload = {
      data: {
        ...breederInfo,
        phoneNumber: data.phoneNumber,
        firstName: data.firstName || '',
        middleName: data.middleName || '',
        lastName: data.lastName || '',
        breeds: data.breedsInProgram ? data.breedsInProgram.map(item => item.label) : [],
        additonalBreeds: data.additonalBreeds
          ? data.additonalBreeds.split(',').map(val => val)
          : [],
        fullAddress: data.fullAddress,
        address: parsedAddress?.streetAddress ? parsedAddress?.streetAddress : breederInfo.address,
        city: parsedAddress?.city ? parsedAddress?.city : breederInfo.city,
        state: parsedAddress?.state ? parsedAddress?.state : breederInfo.state,
        zipCode: parsedAddress?.postalCode ? parsedAddress?.postalCode : breederInfo.zipCode,
        country: parsedAddress?.country ? parsedAddress?.country : breederInfo.country,
        geoLong: parsedAddress?.lng.toString() as string,
        geoLat: parsedAddress?.lat.toString() as string,
        hearAboutUs: breederInfo.hearAboutUs ? breederInfo.hearAboutUs : '',
      },
      id: breederId,
    };

    return mutate(breederRequestPayload);
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="my_acc_form"
        id="breederProfileMyAccountForm"
      >
        <Row className="mt-2">
          <Col xs={12} md={6} className={`${errors.firstName ? 'mb-0 input-error' : 'mb-4'} `}>
            <Form.Label className="form-labels">First Name*</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0"
              type="text"
              placeholder="Enter first name"
              disabled
              {...register('firstName')}
            />
            {errors && errors.firstName && (
              <p className="text-error mb-2">{errors.firstName.message}</p>
            )}
          </Col>

          <Col xs={12} md={6} className="mb-4">
            <Form.Label className="form-labels">Middle Name</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0"
              type="text"
              placeholder="Enter middle name"
              {...register('middleName')}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6} className={`${errors.lastName ? 'mb-0 input-error' : 'mb-4'} `}>
            <Form.Label className="form-labels">Last Name*</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0"
              type="text"
              placeholder="Enter last name"
              disabled
              {...register('lastName')}
            />
            {errors && errors.lastName && (
              <p className="text-error mb-2">{errors.lastName.message}</p>
            )}
          </Col>

          <Col xs={12} md={6} className={`${errors.phoneNumber ? 'mb-0 input-error' : 'mb-4'} `}>
            <Form.Label className="form-labels">Phone Number (Optional)</Form.Label>
            <br />
            <Controller
              control={control}
              name={'phoneNumber'}
              render={({ field: { onChange, value } }) => (
                <IntlTelInput
                  initialValue={value}
                  onChangeNumber={onChange}
                  onChangeValidity={setIsValid}
                  onChangeErrorCode={setErrorCode}
                  initOptions={{
                    initialCountry: 'us',
                    utilsScript:
                      'https://cdn.jsdelivr.net/npm/intl-tel-input@19.2.19/build/js/utils.js',
                  }}
                />
              )}
            />
            {errors && errors.phoneNumber && (
              <p className="text-error mb-2 text-error-number-verify">
                {errors.phoneNumber.message}
              </p>
            )}
          </Col>
        </Row>

        <Col xs={12} className={`${errors.emailAddress ? 'mb-0 input-error' : 'mb-4'} `}>
          <Form.Label className="form-labels">Email Address*</Form.Label>
          <Form.Control
            bsPrefix="form-control input mb-0"
            type="email"
            disabled
            placeholder="Enter Email Address"
            {...register('emailAddress')}
          />
          {errors && errors.emailAddress && (
            <p className="text-error mb-2">{errors.emailAddress.message}</p>
          )}
        </Col>

        <Autocomplete
          onLoad={ref => (inputRef.current = ref)}
          types={['address']}
          onPlaceChanged={handlePlaceChanged}
        >
          <Col xs={12} className={`${errors.fullAddress ? 'mb-0 input-error' : 'mb-4'}`}>
            <Form.Label className="form-labels">Address*</Form.Label>
            <Col className="d-flex align-items-center gap-2 mb-3">
              <input
                ref={placesInputRef}
                className="input form-control mb-0"
                type="text"
                placeholder="Type here to see address suggestions"
                defaultValue={watch('fullAddress')}
              />
              {!isEmpty(watch('fullAddress')) && (
                <p
                  className="m-0 info"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (placesInputRef.current) placesInputRef.current.value = '';

                    setValue('fullAddress', '');
                    trigger('fullAddress');
                  }}
                >
                  Clear
                </p>
              )}
            </Col>
            {errors && errors.fullAddress && (
              <p className="text-error mb-2">{errors.fullAddress.message}</p>
            )}
          </Col>
        </Autocomplete>

        {/* Buttons area */}
        <div className="d-flex w-100 mt-4  align-items-center justify-content-end">
          <TTButton
            type="submit"
            className="btn-primary-orchid save_btn"
            text="Save"
            fontSize="12px"
            borderRadius="10px"
            loading={isUpdatingUserProfile}
            width="100px"
            height="40px"
          />
        </div>
      </Form>

      <ChangePassword />

      <div className="change_pass_container">
        <h4>Subscription</h4>
        <div id="scamFreeCertified" className="scam-free-certified">
          <div className="scam-free-certified__left">
            <h6>TellTail Match</h6>
            {/* Premium still subscribed breeder */}
            {breederInfo?.badgeDetailsId?.subscribed && (
              <>
                <p>You are a current member of the TellTail Match program.</p>
                <p className="mt-2">
                  You subscription will be renewed at{' '}
                  <span>
                    {' '}
                    {moment(breederInfo?.badgeDetailsId?.expirationDate).format('MMMM DD, YYYY')}.
                  </span>
                </p>
                <p className="mt-2" style={{ color: '#CC7191' }}>
                  <i>Note: To make changes to your subscription, please contact us.</i>
                </p>
              </>
            )}
            {/* Premium but unsubscribed breeder */}
            {!breederInfo?.badgeDetailsId?.subscribed &&
              userInfo?.data?.isSfcBreeder &&
              userInfo?.data?.isVouched && (
                <>
                  <p>You are a current member of the TellTail Match program.</p>
                  <p className="mt-2">Your subscription will deactivated at </p>
                  <span>
                    {' '}
                    {moment(breederInfo?.badgeDetailsId?.expirationDate).format('MMMM DD, YYYY')}.
                  </span>
                  <p>and will not renew.</p>
                </>
              )}
            {/* Basic breeder */}
            {!breederInfo?.badgeDetailsId?.subscribed && !userInfo?.data?.isSfcBreeder && (
              <p className="mt-2">Your subscription is not active.</p>
            )}
          </div>

          {!breederInfo?.badgeDetailsId?.subscribed && !userInfo?.data?.isSfcBreeder ? (
            <TTButton
              type="submit"
              className="btn-primary-orchid save_btn mt-2"
              text="Upgrade now"
              fontSize="12px"
              borderRadius="10px"
              height="40px"
              disabled={breederInfo?.badgeDetailsId?.subscribed}
              onClick={() => navigate('/get-certified')}
            />
          ) : (
            <TTButton
              type="submit"
              className="btn-neutral-navy save_btn mt-2"
              text={breederInfo?.badgeDetailsId?.subscribed ? 'Contact Us' : 'Deactivated'}
              fontSize="12px"
              borderRadius="10px"
              height="40px"
              disabled={!breederInfo?.badgeDetailsId?.subscribed}
              onClick={() => {
                window.location.href = 'mailto:hello@telltail.com&&subject=Deactivate account';
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default MyAccount;
