import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { setToVouched } from 'services/api/apiCalls/admin';
//types
import { TError, IDialogContent } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useMutationSetToVouched = (
  setDialogContent: (dialogContent: IDialogContent | null) => void,
) => {
  const { addToast } = useToasts();
  const queryClient = useQueryClient();

  const { mutate, isLoading, error, reset } = useMutation<
    AxiosResponse<string>,
    AxiosError<TError>,
    string
  >(id => setToVouched(id), {
    onSuccess: () => {
      addToast('Breeder vouched successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
      queryClient.removeQueries(QUERY_KEYS.UNVOUCHED_BREEDERS_LIST);

      setDialogContent(null);
    },
  });

  return {
    mutate,
    isLoading,
    error,
    reset,
  };
};

export default useMutationSetToVouched;
