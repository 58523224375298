const RESOURCES = {
  //getResources
  RESOURCES: 'RESOURCES',
  //getVideo
  VIDEO_DETAILS: 'VIDEO_DETAILS',
  //getResourceList (for admin panel)
  RESOURCE_LIST: 'RESOURCE_LIST',
  //getPublicResources
  RESOURCE_LIST_PUBLIC: 'RESOURCE_LIST_PUBLIC',
  //getPublicResource
  RESOURCE_PUBLIC: 'RESOURCE_PUBLIC',
};

export default RESOURCES;
