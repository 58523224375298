const COMMUNITY = {
  //getCommunityGroups
  COMMUNITY_GROUPS: 'COMMUNITY_GROUPS',
  //getCommunityGroup
  COMMUNITY_GROUP: 'COMMUNITY_GROUP',
  //getConversationsByGroupId
  COMMUNITY_CONVERSATIONS_BY_GROUP: 'COMMUNITY_CONVERSATIONS_BY_GROUP',
  //getConversationsByConversationId
  COMMUNITY_CONVERSATIONS_BY_ID: 'COMMUNITY_CONVERSATIONS_BY_ID',
};

export default COMMUNITY;
