import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { AiOutlineArrowLeft, AiOutlineLoading3Quarters } from 'react-icons/ai';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

//components
import HowSoon from './1_HowSoon';
import AboutProgram from './2_AboutProgram';
import CreateAccount from './3_CreateAccount';
import CheckEmail from './4_CheckEmail';
import ScamFreeCertified from './5_ScamFreeCertified';
import PersonaLegalName from './6_PersonaLegalName';
import PersonaVerify from './7_PersonaVerify';
import PuppyMillCheck from './8_PuppyMillCheck';
import MoreAboutProgram from './9_MoreAboutProgram';
import Litters from './10_Litters';
import BuildProfle from './11_BuildProfile';
import HearAboutUs from './12_HearAboutUs.tsx';
//services
import { AuthState } from 'services/auth';
//hooks
import { useQueryLittersList } from './__hooks/useQueryLitters';
import { useQueryBreederInfo } from 'hooks/commonQueryHooks/useQueryBreederInfo';
//helper
import { getBreederProgressBarPercentage } from 'helpers/getProgressBarPercentage';
//types
import { IUserInfo, TAuthContext } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';
//styles
import './index.scss';

export default function BreederRegistration() {
  const location = useLocation();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const userInfo = queryClient.getQueryData(QUERY_KEYS.USER_STATUS) as {
    data: IUserInfo | undefined;
  };
  const {
    setRegistrationInfo,
    state: { breederId, isAuthenticated, registrationInfo },
  } = useContext(AuthState) as TAuthContext;

  const [step, setStep] = useState(
    userInfo?.data?.registrationStep ? userInfo?.data?.registrationStep : 1,
  );

  // Set referral code for breeder if it exists in the params and its not set already
  useEffect(() => {
    const referralCode = searchParams.get('referral_code');
    const firstName = searchParams.get('first_name');
    const lastName = searchParams.get('last_name');
    const email = searchParams.get('email');

    isEmpty(registrationInfo.referral) &&
      referralCode !== null &&
      setRegistrationInfo('referral', {
        firstName: firstName || '',
        lastName: lastName || '',
        emailAddress: email || '',
        refurralCode: referralCode,
      });
  }, [setRegistrationInfo, searchParams, registrationInfo]);

  // We dont want to show registration flow (steps 9-12) to users who already went trough
  // these steps prior to purchasing
  useEffect(() => {
    location?.state?.from === 'get-certified' && setStep(9);
  }, [setStep, location]);

  useEffect(() => {
    if (
      searchParams.get('step') === null ||
      searchParams.get('step') !== userInfo?.data?.registrationStep.toString()
    ) {
      const newSearchParams = new URLSearchParams(searchParams.toString());
      userInfo?.data?.registrationStep
        ? newSearchParams.set('step', userInfo?.data?.registrationStep?.toString())
        : newSearchParams.set('step', step.toString());

      setSearchParams(newSearchParams);
    }
  }, [searchParams, setSearchParams, userInfo?.data?.registrationStep, step]);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set('step', step.toString());
    setSearchParams(newSearchParams);
  }, [step, setSearchParams, searchParams]);

  //Query hooks
  const { errorBreeder, breederInfo, isLoadingBreeder, isFetchingBreeder } = useQueryBreederInfo(
    breederId,
    isAuthenticated,
  );
  const {
    data: litters,
    isFetchingLitters,
    isLoadingLitters,
  } = useQueryLittersList(breederId, isAuthenticated);

  // Stepper and back button display conditions
  const screensWithBackButton = userInfo?.data?.isSfcBreeder
    ? [1, 2, 3, 7, 10, 11, 12]
    : [1, 2, 3, 7, 9, 10, 11, 12];
  const stepsWithStepper = [1, 2, 3, 9, 10, 11, 12];
  const showStepperOnStepsFiveToSeven =
    (step === 5 || step === 6 || step === 7) &&
    (breederInfo?.hearAboutUs === undefined || breederInfo?.hearAboutUs === '');

  return (
    <div className="registration-container">
      {(includes(stepsWithStepper, step) || showStepperOnStepsFiveToSeven) && (
        <div className="stepper-container">
          {includes(screensWithBackButton, step) && (
            <div
              className="stepper-container__left"
              onClick={() => {
                if (step === 1) {
                  window.location.href = 'https://www.telltail.com/join';
                } else if (step === 9) {
                  setStep(5);
                } else {
                  setStep(step - 1);
                }
              }}
            >
              <AiOutlineArrowLeft color="#CC7191" />
              Back
            </div>
          )}
          <div className="stepper-container__middle">
            <div className={`progress ${getBreederProgressBarPercentage(step)}`} />
          </div>
          <div className="stepper-container__right">Step {step}</div>
        </div>
      )}

      {step === 1 && <HowSoon step={step} setStep={setStep} />}
      {step === 2 && <AboutProgram step={step} setStep={setStep} />}
      {step === 3 && <CreateAccount step={step} setStep={setStep} />}
      {step === 4 && <CheckEmail />}
      {step === 6 && <PersonaLegalName step={step} setStep={setStep} />}
      {step === 7 && <PersonaVerify step={step} setStep={setStep} />}

      {isLoadingBreeder || isFetchingBreeder || isFetchingLitters || isLoadingLitters ? (
        <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
      ) : errorBreeder || breederInfo === undefined || litters === undefined ? null : (
        <>
          {step === 5 && (
            <ScamFreeCertified step={step} setStep={setStep} breederInfo={breederInfo} />
          )}
          {step === 8 && <PuppyMillCheck step={step} setStep={setStep} breederInfo={breederInfo} />}
          {step === 9 && (
            <MoreAboutProgram breederInfo={breederInfo} step={step} setStep={setStep} />
          )}
          {step === 10 && (
            <Litters breederInfo={breederInfo} litters={litters} step={step} setStep={setStep} />
          )}
          {step === 11 && <BuildProfle breederInfo={breederInfo} step={step} setStep={setStep} />}
          {step === 12 && <HearAboutUs breederInfo={breederInfo} step={step} setStep={setStep} />}
        </>
      )}
    </div>
  );
}
