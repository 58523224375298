import React, { ChangeEvent, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useToasts } from 'react-toast-notifications';

//types
import { IBuildProfileForm, TAuthContext, TMoreAboutBreeder } from 'types';
//components
import TTButton from 'components/common/TTButton';
import FilePickerInput from 'components/common/FilePickerInput';
//constants
import validExtensions from 'constants/images/validExtensions';
//helpers
import { getImage } from 'helpers/getImage';
//hooks
import { useMutationUpdateBreederInfo } from '../__hooks/useMutationUpdateBreederInfo';
import { useMutationUploadProfileImage } from '../__hooks/useMutationUploadProfileImage';
//services
import { useDataLayer } from 'services/dataLayerContext';
import { AuthState } from 'services/auth';
import { getCloudflareUploadUrl } from 'services/api/apiCalls/cloudflare';
//assets
import avatar from 'assets/images/breederProfile/avatar.png';
//validations
import { buildProfileSchema } from './Validation';

const BuildProfle = ({ setStep, step, breederInfo }: TMoreAboutBreeder) => {
  const { addToast } = useToasts();
  const pushToDataLayer = useDataLayer();
  const {
    state: { breederId },
  } = useContext(AuthState) as TAuthContext;
  const [profilePic, setProfilePic] = useState<File | string | null>(
    breederInfo.profilePicUrl || null,
  );
  const [isLoading, setIsLoading] = useState(false);

  const {
    setValue,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm<IBuildProfileForm>({
    resolver: yupResolver(buildProfileSchema),
    defaultValues: {
      profilePic: breederInfo.profilePicUrl,
    },
  });

  //Mutation hooks
  const { mutate: updateBreederInfo, isLoading: isUpdatingBreederData } =
    useMutationUpdateBreederInfo({
      setStep,
      step,
    });
  const { uploadProfilePicture, isUploadingProfilePicture } = useMutationUploadProfileImage(
    breederId,
    breederInfo,
    updateBreederInfo,
  );

  const loadProfilePic = (file: File | null) => {
    setValue('profilePic', file);
    trigger('profilePic');
    setProfilePic(file);
  };

  const handleClickBrowse = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) {
      addToast('No file selected. Please select a file.', {
        appearance: 'error',
        autoDismiss: true,
        placement: 'right',
      });

      return;
    }

    if (!validExtensions.includes(file.type)) {
      addToast('Unsupported file type. Please upload a PNG, JPG, or JPEG file.', {
        appearance: 'error',
        autoDismiss: true,
        placement: 'right',
      });

      return;
    }

    const maxSize = 10 * 1024 * 1024; // 10MB max size
    if (file.size > maxSize) {
      addToast('File size exceeds the maximum limit of 10MB. Please upload a smaller file.', {
        appearance: 'error',
        autoDismiss: true,
        placement: 'right',
      });

      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = () => loadProfilePic(file);
    fileReader.onerror = () => {
      addToast('Error reading file. Please try again.', {
        appearance: 'error',
        autoDismiss: true,
        placement: 'right',
      });

      fileReader.abort();
    };
    fileReader.readAsDataURL(file);
  };

  const onSubmit = async (data: IBuildProfileForm) => {
    pushToDataLayer({
      event: 'buildProfileClick',
      componentName: 'Build profile',
      ...data,
    });
    setIsLoading(true);

    const cloudflareUploadUrl = await getCloudflareUploadUrl();
    if (cloudflareUploadUrl !== null) {
      uploadProfilePicture({
        pathAndName: `images/breeders/profile/${breederId}/${Date.now()}`,
        image: data.profilePic as File,
        url: cloudflareUploadUrl,
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="build-profile">
      <div className="build-profile__top">
        <h2>Say cheese and upload a photo!</h2>
        <span>
          Our research shows us that having a profile picture helps build stronger connections with
          buyers.
        </span>
      </div>

      <div className="build-profile__content">
        <div className="image-conatiner">
          <div className="d-flex flex-column align-items-center">
            <img src={getImage(profilePic, avatar)} />
            {errors && errors.profilePic && (
              <span className="text-error mt-2">{errors.profilePic.message}</span>
            )}
          </div>
          <div className="d-flex flex-column gap-3">
            <FilePickerInput
              name="build-profile-pic"
              view={
                <TTButton
                  className="btn-primary-white"
                  width="300px"
                  height="50px"
                  text="Upload Photo"
                />
              }
              onChange={handleClickBrowse}
            />

            <TTButton
              className="btn-primary-navy"
              width="300px"
              height="50px"
              text="Remove Photo"
              onClick={() => loadProfilePic(null)}
            />
          </div>
        </div>
      </div>

      <div className="buttons-container">
        <span className="skip-button" onClick={() => setStep(step + 1)}>
          Skip for now
        </span>

        <TTButton
          height="50px"
          className="btn-primary-orchid"
          text="Continue"
          onClick={
            breederInfo.profilePicUrl === profilePic
              ? () => {
                  setStep(step + 1);
                  window.scrollTo({ top: 0 });
                }
              : handleSubmit(onSubmit)
          }
          disabled={isUploadingProfilePicture || isUpdatingBreederData || isLoading}
        />
      </div>
    </div>
  );
};

export default BuildProfle;
