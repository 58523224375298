import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { focusManager, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

//components
import TTButton from 'components/common/TTButton';
import StatusInfo from './StatusInfo';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';
//services
import { useDataLayer } from 'services/dataLayerContext';
//hooks
import useMutationShopifyAutoLink from './__hooks/useMutationShopifyAutoLink';
//types
import { IUserInfo } from 'types';
//styles
import './index.scss';
//assets
import shopifySuccess from 'assets/images/registrationAndSignIn/shopifySuccess.png';
import shopifySuccessBreeder from 'assets/images/registrationAndSignIn/youAreVerified.svg';

const ShopifySuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');
  const queryClient = useQueryClient();
  const pushToDataLayer = useDataLayer();

  const [isConfirmed, setIsConfirmed] = useState(false);

  //Query hooks
  const userInfo = queryClient.getQueryData(QUERY_KEYS.USER_STATUS) as {
    data: IUserInfo | undefined;
  };

  //Mutation hooks
  const { mutateShopifyAutoLinking, isConfirming } = useMutationShopifyAutoLink(
    setIsConfirmed,
    pushToDataLayer,
  );

  useEffect(() => {
    focusManager.setFocused(true);

    return () => {
      focusManager.setFocused(undefined);
    };
  }, []);

  useEffect(() => {
    if (!orderId) return;

    const isAutolinkingTried = window.localStorage.getItem('IS_AUTOLINKING_TRIED');
    if (!isAutolinkingTried) {
      window.localStorage.setItem('IS_AUTOLINKING_TRIED', 'Yes');
      mutateShopifyAutoLinking(orderId);
    }
  }, [mutateShopifyAutoLinking, orderId]);

  return (
    <div className="shopify-success">
      {userInfo?.data?.isBuyer ? (
        <Container className="shopify-success-container">
          <h2>Success!</h2>
          <p>You’ll start receiving matches via email immediately</p>

          <img src={shopifySuccess} alt="shopify-success" className="shopify-success-img" />

          <TTButton
            className="btn-primary-orchid"
            text="Go to Profile"
            width="280px"
            height="50px"
            onClick={() => {
              window.scrollTo({ top: 0 });
              navigate('/profile');
            }}
          />
        </Container>
      ) : (
        <Container className="shopify-success-container">
          <h4>Thank you for your purchase!</h4>
          <br />
          <p>
            Congrats! You’re a TellTail Certified Breeder!
            <br />
            Next step is Identity Verification - it’s quick and ensures a secure environment for
            everyone.
          </p>
          <img src={shopifySuccessBreeder} alt="shopify-success" className="shopify-success-img" />
          <StatusInfo isConfirming={isConfirming} isConfirmed={isConfirmed} />
          <div className="signupOnboarding__btnContainer">
            <TTButton
              className="btn-primary-orchid mt-4"
              text="Proceed to Verification"
              width="280px"
              height="50px"
              loading={isConfirming}
              disabled={isConfirming || !isConfirmed}
              onClick={() => {
                window.scrollTo({ top: 0 });
                queryClient.invalidateQueries(QUERY_KEYS.USER_STATUS, {
                  refetchInactive: true,
                });
                navigate('/get-certified?step=6');
              }}
            />
          </div>
        </Container>
      )}
    </div>
  );
};

export default ShopifySuccess;
