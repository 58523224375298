import React, { ChangeEvent, useState, useRef, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextareaAutosize,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Form } from 'react-bootstrap';
import { Controller, FieldPath, useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from 'react-query';
import Select, { ActionMeta, MultiValue } from 'react-select';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import 'react-datepicker/src/stylesheets/datepicker.scss';

//types
import {
  IDialogProps,
  ICreateEditBreedEncyclopaediaPayload,
  ICreateEditBreedEncyclopaediaSchema,
  ISelectOption,
} from 'types';
//hooks
import { useQueryBreedsDropdown } from 'pages/BreedDirectory/__hooks/useQueryBreedsDropdown';
import { useQueryResourceArticles } from '../__hooks/useQueryGetArticles';
import { useQueryGetBreedDetailsForAdmin } from '../__hooks/useQueryGetBreedDetailsForAdmin';
import { useQueryAdminFilters } from '../__hooks/useQueryAdminFilters';
import {
  useMutationCreateNewBreedEncyclopaedia,
  useMutationEditBreedEncyclopaedia,
} from '../__hooks/useMutationCreateEditBreedEncyclopaedia';
//components
import InfoView from 'components/common/InfoView';
import KitImg from 'components/common/KitImg';
import FilePickerInput from 'components/common/FilePickerInput';
import RichTextEditor from 'components/common/SunTextEditor';
import SelectFilters from './SelectFilters';
import RatingView from './RatingView';
import ImageGallery from './ImageGallery';
//assets
import NoImageAvailable from 'assets/images/adminPanel/no-profile-pic.svg';
//constants
import validExtensions from 'constants/images/validExtensions';
import { QUERY_KEYS } from 'constants/queryKeys';
//helpers
import { getImage } from 'helpers/getImage';
//validations
import { EditBreedEncyclopaediaSchema } from './Validation';
//styles
import { multiSelectStyles } from 'components/styled/select';
import './index.scss';
//services
import { getCloudflareUploadUrl, uploadImageForForms } from 'services/api/apiCalls/cloudflare';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditBreedEncyclopaedia = ({ open, handleClose, breedId }: IDialogProps) => {
  const MAX_BREED_LIMIT = 3;
  // const MAX_RELATED_ARTICLES_LIMIT = 3;
  const IMAGE_SIZE = 200;
  const queryClient = useQueryClient();
  const errorSpanRef = useRef<HTMLDivElement | null>(null);

  const [profilePic, setProfilePic] = useState<File | string | null>(null);
  const [historyPicture, setHistoryPicture] = useState<File | string | null>(null);
  const [imageCarousel, setImageCarousel] = useState<string[]>([]);

  const handleDialogDismiss = () => {
    creatNewBreedEncyclopaediaResetApiState();
    editBreedEncyclopaediaResetApiState();
    queryClient.removeQueries(QUERY_KEYS.ADMIN_GET_BREED_DETAILS);
    handleClose();
  };

  const scrollToBottom = () =>
    setTimeout(
      () => errorSpanRef.current?.scrollIntoView({ inline: 'end', behavior: 'auto' }),
      350,
    );

  // Query hooks
  const { isLoadingBreedsDropdown, breedsDropdown, errorBreedsDropdown } = useQueryBreedsDropdown();
  const { isLoadingArticles, errorArticles } = useQueryResourceArticles();
  const { isLoadingBreedDetails, breedDetails, errorBreedDetails } =
    useQueryGetBreedDetailsForAdmin(breedId!, !!breedId);
  const { breedTags, isLoadingBreedTags } = useQueryAdminFilters();
  //Mutation hooks
  const {
    createNewBreedEncyclopaediaMutate,
    isCreatingNewBreedEncyclopaedia,
    createNewBreedEncyclopaediaError,
    creatNewBreedEncyclopaediaResetApiState,
  } = useMutationCreateNewBreedEncyclopaedia(handleDialogDismiss, scrollToBottom);
  const {
    editBreedEncyclopaediaMutate,
    isEditingBreedEncyclopaedia,
    editBreedEncyclopaediaError,
    editBreedEncyclopaediaResetApiState,
  } = useMutationEditBreedEncyclopaedia(handleDialogDismiss, scrollToBottom);

  const {
    register,
    watch,
    handleSubmit,
    setError,
    control,
    trigger,
    clearErrors,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ICreateEditBreedEncyclopaediaSchema>({
    resolver: yupResolver(EditBreedEncyclopaediaSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'behavioralCharacteristics',
  });

  const breedTypeFilters = useMemo(
    () => breedTags?.find(tag => tag.categoryName === 'Breed type')?.categoryTags || [],
    [breedTags],
  );
  const sizeFilters = useMemo(
    () => breedTags?.find(tag => tag.categoryName === 'Size')?.categoryTags || [],
    [breedTags],
  );
  const tempramentFilters = useMemo(
    () => breedTags?.find(tag => tag.categoryName === 'Temperament')?.categoryTags || [],
    [breedTags],
  );
  const coatTypeFilters = useMemo(
    () => breedTags?.find(tag => tag.categoryName === 'Coat type')?.categoryTags || [],
    [breedTags],
  );
  const sheddingFilters = useMemo(
    () => breedTags?.find(tag => tag.categoryName === 'Shedding')?.categoryTags || [],
    [breedTags],
  );
  const livingFilters = useMemo(
    () => breedTags?.find(tag => tag.categoryName === 'Living Area')?.categoryTags || [],
    [breedTags],
  );
  const specialtyFilters = useMemo(
    () => breedTags?.find(tag => tag.categoryName === 'Specialty')?.categoryTags || [],
    [breedTags],
  );
  // const articleOptions = useMemo(
  //   () =>
  //     articles?.map(article => ({ label: article.resource.title, value: article.resource._id })),
  //   [articles],
  // );

  useEffect(() => {
    if (!breedDetails || !reset || !breedTypeFilters) return;

    setProfilePic(breedDetails.profilePic);
    setHistoryPicture(breedDetails.historyImage);
    setImageCarousel(breedDetails.imageCarousel);
    reset({
      ...breedDetails,
      breedType: breedTypeFilters.filter(cat => [breedDetails.breedType].includes(cat.slugName)),
      generalSize: sizeFilters.filter(cat =>
        breedDetails.generalSize.some(item => cat.slugName.includes(item)),
      ),
      temperament: tempramentFilters.filter(cat => breedDetails.temperament.includes(cat.slugName)),
      coat: coatTypeFilters.filter(cat => breedDetails.coat.includes(cat.slugName)),
      shedding: sheddingFilters.filter(cat => breedDetails.shedding.includes(cat.slugName)),
      living: livingFilters.filter(cat => breedDetails.living.includes(cat.slugName)),
      speciality: specialtyFilters.filter(cat => breedDetails.speciality.includes(cat.slugName)),
      behavioralCharacteristics: breedDetails.behavioralCharacteristics.map(characterstick => ({
        characterstick,
      })),
      similarBreeds: breedDetails.similarBreeds.map(breed => ({ label: breed, value: breed })),
    });
  }, [
    reset,
    breedDetails,
    breedTypeFilters,
    sizeFilters,
    tempramentFilters,
    coatTypeFilters,
    sheddingFilters,
    livingFilters,
    specialtyFilters,
  ]);

  useEffect(() => {
    setValue('imageCarousel', imageCarousel);
  }, [imageCarousel, setValue]);

  const getButtonText = () => (breedDetails ? 'Update' : 'Create');

  const handleClickBrowse = (e: ChangeEvent<HTMLInputElement>, setImageFor: string) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;

    if (validExtensions.includes(file?.type)) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        if (setImageFor === 'profilePic') {
          setValue('profilePic', file.name);
          clearErrors('profilePic');
          setProfilePic(file);
        } else if (setImageFor === 'historyImage') {
          setValue('historyImage', file.name);
          clearErrors('historyImage');
          setHistoryPicture(file);
        }
      };
      fileReader.readAsDataURL(file);
    }
  };

  const handleEditorStateChange = useCallback(
    (fieldName: FieldPath<ICreateEditBreedEncyclopaediaSchema>, editorState: string) => {
      setValue(fieldName, editorState, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [setValue],
  );

  const onSubmit = async (breedEncyclopaediaSchema: ICreateEditBreedEncyclopaediaSchema) => {
    const cloudflareUploadUrl = await getCloudflareUploadUrl();
    const cloudflareUploadUrlTwo = await getCloudflareUploadUrl();

    const profilePicUrl =
      cloudflareUploadUrl !== null && typeof profilePic !== 'string'
        ? await uploadImageForForms({
            pathAndName: 'dog',
            image: profilePic!,
            url: cloudflareUploadUrl,
          })
        : profilePic;

    const historyPicUrl =
      cloudflareUploadUrlTwo !== null && typeof historyPicture !== 'string'
        ? await uploadImageForForms({
            pathAndName: 'history',
            image: historyPicture!,
            url: cloudflareUploadUrlTwo,
          })
        : historyPicture;

    const payload: ICreateEditBreedEncyclopaediaPayload = {
      ...breedEncyclopaediaSchema,
      profilePic: profilePicUrl as string,
      historyImage: historyPicUrl as string,
      imageCarousel,
      breedType: breedEncyclopaediaSchema.breedType.map(item => item.slugName)[0],
      generalSize: breedEncyclopaediaSchema.generalSize.map(item => item.slugName),
      temperament: breedEncyclopaediaSchema.temperament.map(item => item.slugName),
      coat: breedEncyclopaediaSchema.coat.map(item => item.slugName),
      shedding: breedEncyclopaediaSchema.shedding.map(item => item.slugName),
      living: breedEncyclopaediaSchema.living.map(item => item.slugName),
      speciality: breedEncyclopaediaSchema.speciality.map(item => item.slugName),
      behavioralCharacteristics: breedEncyclopaediaSchema.behavioralCharacteristics.map(
        item => item.characterstick,
      ),
      similarBreeds: breedEncyclopaediaSchema.similarBreeds.map(item => item.label),
    };
    // console.log(payload);

    if (breedId) {
      editBreedEncyclopaediaMutate({ ...payload, _id: breedId });
    } else {
      createNewBreedEncyclopaediaMutate(payload);
    }
  };

  const getDialogContent = () => {
    if (isLoadingArticles || isLoadingBreedsDropdown || isLoadingBreedDetails || isLoadingBreedTags)
      return (
        <div className="d-flex width-full justify-content-center my-3">
          <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
        </div>
      );

    if (errorArticles || errorBreedsDropdown || errorBreedDetails)
      return (
        <div className="d-flex width-full justify-content-center my-2">
          {errorArticles && (
            <p className="text-error">
              {errorArticles?.response?.data.ExceptionMessage || errorArticles?.message}
            </p>
          )}

          {errorBreedsDropdown && <p className="text-error">{errorBreedsDropdown?.message}</p>}
          {errorBreedDetails && <p className="text-error">{errorBreedDetails?.message}</p>}
        </div>
      );

    return (
      <DialogContent>
        <Box
          className="edit_breed_encyclopaedia"
          display="flex"
          flexDirection="column"
          width="60vw"
          height="70vh"
          m={2}
          position="relative"
        >
          {/* Breed Picture  */}
          <InfoView
            p={0}
            flex={1}
            label="Breed Picture:"
            value={
              <Box display="flex" flexDirection="column" alignItems="center">
                <Card elevation={3}>
                  <KitImg
                    src={getImage(profilePic, NoImageAvailable)}
                    kitwidth={IMAGE_SIZE}
                    style={{
                      width: IMAGE_SIZE,
                      maxHeight: IMAGE_SIZE,
                      background: '#f5f5f5',
                      objectFit: 'contain',
                    }}
                  />
                </Card>
                <FilePickerInput
                  name="ebe_breed_picture"
                  view={
                    <Button variant="outlined" component="span">
                      {profilePic ? 'Change Breed Picture' : 'Add Breed Picture'}
                    </Button>
                  }
                  onChange={e => handleClickBrowse(e, 'profilePic')}
                />
                {errors && errors.profilePic && (
                  <span className="text-error mt-2">{errors.profilePic.message}</span>
                )}
              </Box>
            }
            vertical
          />
          {/* Breed Name */}
          <Box mt={3}>
            <Form.Label>Breed Name</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0 py-3"
              placeholder="Enter breed name"
              {...register('breedName')}
            />
          </Box>
          {errors && errors.breedName && (
            <span className="text-error mt-2">{errors.breedName.message}</span>
          )}
          {/* Breed Short Description */}
          <InfoView
            p={0}
            mt={3}
            label="Breed Short Description:"
            value={
              <TextareaAutosize
                minRows={5}
                maxRows={5}
                placeholder="Breed Short Description"
                style={{ width: '100%', marginTop: 2, borderRadius: 5, padding: 5 }}
                {...register('shortDescription')}
              />
            }
            vertical
          />
          {errors && errors.shortDescription && (
            <span className="text-error mt-2">{errors.shortDescription.message}</span>
          )}
          {/*  Life Expectancy*/}
          <Box mt={3}>
            <Form.Label>Life Expectancy</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0 py-3"
              placeholder="Enter life expectancy"
              {...register('lifeExpectancy')}
            />
          </Box>
          {errors && errors.lifeExpectancy && (
            <span className="text-error mt-2">{errors.lifeExpectancy.message}</span>
          )}
          {/* Fun fact */}
          <InfoView
            p={0}
            mt={3}
            label="Fun Fact:"
            value={
              <TextareaAutosize
                minRows={5}
                maxRows={5}
                placeholder="Fun Fact"
                style={{ width: '100%', marginTop: 2, borderRadius: 5, padding: 5 }}
                {...register('funFact')}
              />
            }
            vertical
          />
          {/* Breed Type */}
          <SelectFilters
            fieldLabel="Breed Type"
            fieldName="breedType"
            filters={breedTypeFilters}
            selectedFilters={[breedDetails?.breedType || '']}
            control={control}
            error={errors.breedType}
          />
          {/* Does size variy */}
          <Box display="flex" alignItems="center" mt={3}>
            <label htmlFor="variations-size-checkbox" style={{ fontWeight: '700' }}>
              Is this breeder has size vairations?
            </label>
            <Checkbox
              id={'variations-size-checkbox'}
              defaultChecked={breedDetails?.variationsSize}
              {...register('variationsSize')}
            />
          </Box>
          {/* Size */}
          <SelectFilters
            fieldLabel="Size"
            fieldName="generalSize"
            filters={sizeFilters}
            selectedFilters={breedDetails?.generalSize || []}
            control={control}
            error={errors.generalSize}
          />
          {/* Temperament */}
          <SelectFilters
            fieldLabel="Temperament"
            fieldName="temperament"
            filters={tempramentFilters}
            selectedFilters={breedDetails?.temperament || []}
            control={control}
            error={errors.temperament}
          />
          {/* Coat Type */}
          <SelectFilters
            fieldLabel="Coat Type"
            fieldName="coat"
            filters={coatTypeFilters}
            selectedFilters={breedDetails?.coat || []}
            control={control}
            error={errors.coat}
          />
          {/* Shedding Level */}
          <SelectFilters
            fieldLabel="Shedding Level"
            fieldName="shedding"
            filters={sheddingFilters}
            selectedFilters={breedDetails?.shedding || []}
            control={control}
            error={errors.shedding}
          />
          {/* Hypoallergenic */}
          <Box display="flex" alignItems="center" mt={3}>
            <label htmlFor="hypoallergenic-checkbox" style={{ fontWeight: '700' }}>
              Is this breeder Hypoallergenic?
            </label>
            <Checkbox
              id={'hypoallergenic-checkbox'}
              defaultChecked={breedDetails?.isHypoallergenic}
              {...register('isHypoallergenic')}
            />
          </Box>
          {/* Living Area */}
          <SelectFilters
            fieldLabel="Living Area"
            fieldName="living"
            filters={livingFilters}
            selectedFilters={breedDetails?.living || []}
            control={control}
            error={errors.living}
          />
          {/* Speciality */}
          <SelectFilters
            fieldLabel="Speciality"
            fieldName="speciality"
            filters={specialtyFilters}
            selectedFilters={breedDetails?.speciality || []}
            control={control}
            error={errors.speciality}
          />
          {/* Barking Level */}
          <RatingView
            defaultValue={breedDetails?.barking}
            ratingLabel="Barking Level"
            ratingFieldName="barking"
            infoLabel="Barking Level Info"
            infoFieldName="barkingDescription"
            control={control}
            register={register}
            watch={watch}
            ratingError={errors.barking}
            infoError={errors.barkingDescription}
          />
          {/* Cuteness Level */}
          <RatingView
            defaultValue={breedDetails?.cuteness}
            ratingLabel="Cuteness Level"
            ratingFieldName="cuteness"
            infoLabel="Cuteness Level Info"
            infoFieldName="cutenessDescription"
            control={control}
            register={register}
            watch={watch}
            ratingError={errors.cuteness}
            infoError={errors.cutenessDescription}
          />
          {/* Cuddliness Level */}
          <RatingView
            defaultValue={breedDetails?.cuddliness}
            ratingLabel="Cuddliness Level"
            ratingFieldName="cuddliness"
            infoLabel="Cuddliness Level Info"
            infoFieldName="cuddlinessDescription"
            control={control}
            register={register}
            watch={watch}
            ratingError={errors.cuddliness}
            infoError={errors.cuddlinessDescription}
          />
          {/* Athleticism Level */}
          <RatingView
            defaultValue={breedDetails?.athleticism}
            ratingLabel="Athleticism Level"
            ratingFieldName="athleticism"
            infoLabel="Athleticism Level Info"
            infoFieldName="athleticismDescription"
            control={control}
            register={register}
            watch={watch}
            ratingError={errors.athleticism}
            infoError={errors.athleticismDescription}
          />
          {/* Maintenance Level */}
          <RatingView
            defaultValue={breedDetails?.maintenance}
            ratingLabel="Maintenance Level"
            ratingFieldName="maintenance"
            infoLabel="Maintenance Level Info"
            infoFieldName="maintenanceDescription"
            control={control}
            register={register}
            watch={watch}
            ratingError={errors.maintenance}
            infoError={errors.maintenanceDescription}
          />
          {/* Trainability Level */}
          <RatingView
            defaultValue={breedDetails?.trainability}
            ratingLabel="Trainability Level"
            ratingFieldName="trainability"
            infoLabel="Trainability Level Info"
            infoFieldName="trainabilityDescription"
            control={control}
            register={register}
            watch={watch}
            ratingError={errors.trainability}
            infoError={errors.trainabilityDescription}
          />
          {/* Adaptability Level */}
          <RatingView
            defaultValue={breedDetails?.adaptability}
            ratingLabel="Adaptability Level"
            ratingFieldName="adaptability"
            infoLabel="Adaptability Level Info"
            infoFieldName="adaptabilityDescription"
            control={control}
            register={register}
            watch={watch}
            ratingError={errors.adaptability}
            infoError={errors.adaptabilityDescription}
          />
          {/* Energy Level */}
          <RatingView
            defaultValue={breedDetails?.energy}
            ratingLabel="Energy Level"
            ratingFieldName="energy"
            infoLabel="Energy Level Info"
            infoFieldName="energyDescription"
            control={control}
            register={register}
            watch={watch}
            ratingError={errors.energy}
            infoError={errors.energyDescription}
          />
          {/* Kid-Friendliness Level */}
          <RatingView
            defaultValue={breedDetails?.kidScore}
            ratingLabel="Kid-Friendliness Level"
            ratingFieldName="kidScore"
            infoLabel="Kid-Friendliness Level Info"
            infoFieldName="kidScoreDescription"
            control={control}
            register={register}
            watch={watch}
            ratingError={errors.kidScore}
            infoError={errors.kidScoreDescription}
          />
          <InfoView
            p={0}
            mt={3}
            label="Image Gallery:"
            value={<ImageGallery images={imageCarousel} setImages={setImageCarousel} />}
            vertical
          />
          {errors && errors.imageCarousel && (
            <span className="text-error mt-2">{errors.imageCarousel.message}</span>
          )}
          <InfoView
            p={0}
            mt={3}
            label="Behavioral Characteristics:"
            value={
              <Box display="flex" flexDirection="column">
                {fields.map((item, index) => (
                  <div key={item.characterstick + index} style={{ flex: 1 }}>
                    <Box display="flex" gap={3} p={1}>
                      <input
                        {...register(`behavioralCharacteristics.${index}.characterstick`)}
                        style={{ flex: 1, padding: 8, borderRadius: 8 }}
                      />

                      <Button variant="contained" color="error" onClick={() => remove(index)}>
                        Delete
                      </Button>
                    </Box>
                    {errors && errors.behavioralCharacteristics && (
                      <p className="text-error mb-2">
                        {errors?.behavioralCharacteristics[index]?.characterstick?.message}
                      </p>
                    )}
                  </div>
                ))}
                {errors && errors.behavioralCharacteristics && (
                  <span className="text-error mt-2">
                    {errors.behavioralCharacteristics.message}
                  </span>
                )}

                <Button
                  variant="contained"
                  onClick={() => append({ characterstick: '' })}
                  sx={{ mt: 2, mx: 10 }}
                >
                  Add New Characterstick
                </Button>
              </Box>
            }
            vertical
          />
          {/* Pet Owner Profile Description */}
          <InfoView
            p={0}
            mt={3}
            label="Pet Owner Profile Description:"
            value={
              <TextareaAutosize
                minRows={5}
                maxRows={5}
                placeholder="Pet Owner Profile Description"
                style={{ width: '100%', marginTop: 2, borderRadius: 5, padding: 5 }}
                {...register('petOwner')}
              />
            }
            vertical
          />
          {errors && errors.petOwner && (
            <span className="text-error mt-2">{errors.petOwner.message}</span>
          )}
          <InfoView
            p={0}
            mt={3}
            flex={1}
            label="History Picture:"
            value={
              <Box display="flex" flexDirection="column" alignItems="center">
                <Card elevation={3}>
                  <KitImg
                    src={getImage(historyPicture, NoImageAvailable)}
                    kitwidth={IMAGE_SIZE}
                    style={{
                      width: IMAGE_SIZE,
                      maxHeight: IMAGE_SIZE,
                      background: '#f5f5f5',
                      objectFit: 'contain',
                    }}
                  />
                </Card>
                <FilePickerInput
                  name="ebe_history_picture"
                  view={
                    <Button variant="outlined" component="span">
                      {profilePic ? 'Change History Picture' : 'Add History Picture'}
                    </Button>
                  }
                  onChange={e => handleClickBrowse(e, 'historyImage')}
                />
                {errors && errors.historyImage && (
                  <span className="text-error mt-2">{errors.historyImage.message}</span>
                )}
              </Box>
            }
            vertical
          />
          <InfoView
            p={0}
            mt={3}
            label="History Description:"
            value={
              <TextareaAutosize
                minRows={5}
                maxRows={5}
                placeholder="History Description"
                style={{ width: '100%', marginTop: 2, borderRadius: 5, padding: 5 }}
                {...register('history')}
              />
            }
            vertical
          />
          {errors && errors.history && (
            <span className="text-error mt-2">{errors.history.message}</span>
          )}
          {/* Health Concerns Description */}
          <InfoView
            p={0}
            mt={3}
            label="Health Concerns Description:"
            value={
              <RichTextEditor
                isError={!!errors.health?.message}
                awsFolderName={process.env.REACT_APP_AWS_OTHERS_FOLDER_PATH!}
                containerClassName="mt-1"
                editorStyle={{ borderRadius: 5, height: 600, maxHeight: 600, overflow: 'scroll' }}
                fileNamePrefix="health"
                onEditorStateChange={state => handleEditorStateChange('health', state)}
                knowledgeTagsOptions={undefined}
                editorState={breedDetails?.health}
              />
            }
            vertical
          />
          {errors && errors.health && (
            <span className="text-error mt-2">{errors.health.message}</span>
          )}
          {/* Considerations for Breeding */}
          <InfoView
            p={0}
            mt={3}
            label="Considerations for Breeding:"
            value={
              <RichTextEditor
                isError={!!errors.breedingSummary?.message}
                awsFolderName={process.env.REACT_APP_AWS_OTHERS_FOLDER_PATH!}
                containerClassName="mt-1"
                editorStyle={{ borderRadius: 5, height: 600, maxHeight: 600, overflow: 'scroll' }}
                fileNamePrefix="breedingSummary"
                onEditorStateChange={state => handleEditorStateChange('breedingSummary', state)}
                knowledgeTagsOptions={undefined}
                editorState={breedDetails?.breedingSummary}
              />
            }
            vertical
          />
          {errors && errors.breedingSummary && (
            <span className="text-error mt-2">{errors.breedingSummary.message}</span>
          )}
          <InfoView
            p={0}
            mt={3}
            label="Similar Dog Breeds:"
            value={
              <Controller
                control={control}
                name="similarBreeds"
                render={({ field: { onChange, value } }) => (
                  <Select
                    closeMenuOnSelect={true}
                    isMulti
                    isSearchable
                    value={value}
                    onMenuOpen={scrollToBottom}
                    onChange={(
                      val: MultiValue<ISelectOption>,
                      actionMeta: ActionMeta<ISelectOption>,
                    ) => {
                      if (
                        watch('similarBreeds') &&
                        watch('similarBreeds').length >= MAX_BREED_LIMIT &&
                        actionMeta.action === 'select-option'
                      ) {
                        setError('similarBreeds', {
                          type: 'custom',
                          message: `The maximum number of similar breeds you can select here is ${MAX_BREED_LIMIT}.`,
                        });
                      } else if (actionMeta.action === 'select-option') {
                        return onChange(val);
                      } else if (
                        actionMeta.action === 'remove-value' &&
                        watch('similarBreeds').length <= 1
                      ) {
                        setError('similarBreeds', {
                          type: 'custom',
                          message: 'You must have at least one tag.',
                        });
                      } else if (actionMeta.action === 'remove-value') {
                        trigger('similarBreeds');

                        return onChange(val);
                      }
                    }}
                    options={breedsDropdown}
                    placeholder="Select Similar Breeds"
                    styles={multiSelectStyles}
                    classNamePrefix="tt-select"
                  />
                )}
              />
            }
            vertical
          />
          {errors && errors.similarBreeds && (
            <span className="text-error mt-2">{errors.similarBreeds.message}</span>
          )}
          {/* <InfoView
            p={0}
            mt={3}
            label="Articles about this Breed:"
            value={
              <Controller
                control={control}
                name="relatedArticles"
                render={({ field: { onChange, value } }) => (
                  <Select
                    closeMenuOnSelect={true}
                    isMulti
                    isSearchable
                    value={value}
                    onMenuOpen={scrollToBottom}
                    onChange={(
                      val: MultiValue<ISelectOption>,
                      actionMeta: ActionMeta<ISelectOption>,
                    ) => {
                      if (
                        watch('relatedArticles') &&
                        watch('relatedArticles').length >= MAX_RELATED_ARTICLES_LIMIT &&
                        actionMeta.action === 'select-option'
                      ) {
                        setError('relatedArticles', {
                          type: 'custom',
                          message: `The maximum number of related articles you can select here is ${MAX_RELATED_ARTICLES_LIMIT}.`,
                        });
                      } else if (actionMeta.action === 'select-option') {
                        return onChange(val);
                      } else if (
                        actionMeta.action === 'remove-value' &&
                        watch('relatedArticles').length <= 1
                      ) {
                        setError('relatedArticles', {
                          type: 'custom',
                          message: 'You must have at least one tag.',
                        });
                      } else if (actionMeta.action === 'remove-value') {
                        trigger('relatedArticles');

                        return onChange(val);
                      }
                    }}
                    options={articleOptions}
                    placeholder="Select Related Articles"
                    styles={multiSelectStyles}
                    classNamePrefix="tt-select"
                  />
                )}
              />
            }
            vertical
          />
          {errors && errors.relatedArticles && (
            <span className="text-error mt-2">{errors.relatedArticles.message}</span>
          )} */}
          <span className="text-error mt-4">
            {createNewBreedEncyclopaediaError?.response?.data.ExceptionMessage ||
              createNewBreedEncyclopaediaError?.message}
          </span>
          <span ref={errorSpanRef} className="text-error mt-4">
            {editBreedEncyclopaediaError?.response?.data.ExceptionMessage ||
              editBreedEncyclopaediaError?.message}
          </span>
        </Box>
      </DialogContent>
    );
  };

  return (
    <Dialog
      maxWidth="xl"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="create-deal-dialog-slide-description"
    >
      <DialogTitle bgcolor="darkblue" color="white">
        {breedDetails ? 'Edit Breedopedia' : 'Create Breedopedia'}
      </DialogTitle>
      {getDialogContent()}
      <DialogActions>
        <Button onClick={handleDialogDismiss}>Cancel</Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitting || isCreatingNewBreedEncyclopaedia || isEditingBreedEncyclopaedia}
        >
          {isSubmitting || isCreatingNewBreedEncyclopaedia || isEditingBreedEncyclopaedia ? (
            <AiOutlineLoading3Quarters
              size={20}
              color="blue"
              style={{ margin: '0.2rem' }}
              className="loading-animation svg-loading"
            />
          ) : (
            getButtonText()
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditBreedEncyclopaedia;
