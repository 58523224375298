import React from 'react';
import { Box, Typography } from '@mui/material';

//types
import { IInfoView } from 'types';

const InfoView = ({ label, value, vertical = false, ...props }: IInfoView) => {
  return (
    <Box display="flex" flexDirection={vertical ? 'column' : 'row'} px={3} py={1} {...props}>
      <Typography
        flex={vertical ? 1 : 0.7}
        variant="subtitle2"
        color="#333"
        fontWeight="bold"
        mr={2}
      >
        {label}
      </Typography>

      <Box flex={1}>{value}</Box>
    </Box>
  );
};

export default InfoView;
