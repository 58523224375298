/* eslint-disable @typescript-eslint/no-explicit-any */
//services
import API from 'services/api';
//types
import { TPagination, ILitterCreate, ILitterPayload } from 'types';

// Litters
export const createLitter = (data: ILitterCreate) => API.post('/litter', data);
export const getLitter = (id: string) => API.get(`/litter/${id}`);
export const updateLitter = ({ data, id }: ILitterPayload) => API.put(`/litter/${id}`, data);
export const deleteLitter = (id: string) => API.delete(`/litter/${id}`);

export const getLitters = async function (breederId: string, params: TPagination) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const queryParams = new URLSearchParams(params as any).toString();

  return API.get(`/litter-by-breeder/${breederId}?${queryParams}`);
};
