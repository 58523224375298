import React from 'react';
import { Box, Button, Checkbox, CheckboxProps, FormControlLabel, Typography } from '@mui/material';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//types
import { INonTTBreederPDF } from 'types';
//validations
import { NonTTBreederFormSchema } from './Validation';
//styles
import './index.scss';

const BlueCheckBox = (props: CheckboxProps) => (
  <Checkbox
    sx={{
      color: '#142669',
      '&.Mui-checked': {
        color: '#142669',
      },
    }}
    {...props}
  />
);

const NonTTBreederForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<INonTTBreederPDF>({ resolver: yupResolver(NonTTBreederFormSchema) });

  const onSubmit = (data: INonTTBreederPDF) =>
    window.open(`/non-tt-breeder-pdf/${window.btoa(JSON.stringify(data))}`);

  return (
    <div className="non_tt_breeder">
      <h3 className="mb-2">Buyer Verified</h3>
      <Box display="flex" flexDirection="column" width="98%" height="auto" position="relative">
        <Box mt={3}>
          <Form.Label>Breeder Name</Form.Label>
          <Form.Control
            bsPrefix="input form-control mb-0 py-3"
            placeholder="Enter breeder name"
            {...register('breederName')}
          />
        </Box>
        {errors && errors.breederName && (
          <span className="text-error mt-2">{errors.breederName.message}</span>
        )}

        <Box mt={3}>
          <Form.Label>Breeder Email</Form.Label>
          <Form.Control
            bsPrefix="input form-control mb-0 py-3"
            placeholder="Enter breeder email"
            {...register('breederEmail')}
          />
        </Box>
        {errors && errors.breederEmail && (
          <span className="text-error mt-2">{errors.breederEmail.message}</span>
        )}

        <Box mt={3}>
          <Form.Label>Buyer Name</Form.Label>
          <Form.Control
            bsPrefix="input form-control mb-0 py-3"
            placeholder="Enter buyer name"
            {...register('buyerName')}
          />
        </Box>
        {errors && errors.buyerName && (
          <span className="text-error mt-2">{errors.buyerName.message}</span>
        )}

        <Box mt={3}>
          <Form.Label>Buyer Email</Form.Label>
          <Form.Control
            bsPrefix="input form-control mb-0 py-3"
            placeholder="Enter buyer email"
            {...register('buyerEmail')}
          />
        </Box>
        {errors && errors.buyerEmail && (
          <span className="text-error mt-2">{errors.buyerEmail.message}</span>
        )}

        <FormControlLabel
          sx={{ mt: 2 }}
          control={<BlueCheckBox />}
          label={<Typography className="nttb_checkbox">Is identity verified?</Typography>}
          {...register('isIdentityVerified')}
        />

        <FormControlLabel
          control={<BlueCheckBox />}
          label={<Typography className="nttb_checkbox">Is this ID valid?</Typography>}
          {...register('isIdValid')}
        />

        <FormControlLabel
          control={<BlueCheckBox />}
          label={<Typography className="nttb_checkbox">Was selfie valid?</Typography>}
          {...register('wasSelfieValid')}
        />

        <FormControlLabel
          control={<BlueCheckBox />}
          label={<Typography className="nttb_checkbox">Did ID match the selfie?</Typography>}
          {...register('didIdSelfieMatch')}
        />

        <FormControlLabel
          control={<BlueCheckBox />}
          label={
            <Typography className="nttb_checkbox">Does name on ID match name provided?</Typography>
          }
          {...register('didIdNameMatch')}
        />

        <Button sx={{ marginTop: 5 }} variant="contained" onClick={handleSubmit(onSubmit)}>
          Generate PDF
        </Button>
      </Box>
    </div>
  );
};

export default NonTTBreederForm;
