/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQueryClient } from 'react-query';
import { UseFormSetError } from 'react-hook-form';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { updateAboutProgramByAdmin } from 'services/api/apiCalls/admin';
//types
import { TError, IAboutProgramForm, IUpdateAboutProgramPayload, IBreeder } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useMutationAboutBusiness = ({
  setShowSuccess,
  setError,
}: {
  setShowSuccess: (showSuccess: boolean) => void;
  setError: UseFormSetError<IAboutProgramForm>;
}) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation<
    AxiosResponse<IBreeder>,
    AxiosError<TError>,
    IUpdateAboutProgramPayload
  >(updateAboutProgramByAdmin, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.ADMIN_BREEDER_INFO, {
        refetchInactive: true,
      });
      setShowSuccess(true);
    },
    onError: err => {
      setError('breedsInProgram', {
        type: 'custom',
        message: err?.response?.data?.ExceptionMessage,
      });
    },
  });

  return {
    mutate,
    isLoading,
  };
};

export default useMutationAboutBusiness;
