/* eslint-disable @typescript-eslint/no-explicit-any */
import API from 'services/api';

//types
import {
  INewConversationPayload,
  IReplyPayload,
  IReplyPayloadEdit,
  IRequestNewGroupSchema,
  TPagination,
} from 'types';

// Groups
export const getCommunityGroups = (params: TPagination) => {
  const queryParams = new URLSearchParams(params as any).toString();

  return API.get(`/community/group?${queryParams}`);
};
export const getCommunityGroup = (groupId: string) => {
  return API.get(`/community/group/${groupId}`);
};
export const createNewGroup = (data: IRequestNewGroupSchema) => API.post('/community/group', data);

//Conversations
export const createConversation = (data: INewConversationPayload) =>
  API.post('/community/conversation', data);
export const updateConversation = (conversationId: string, data: INewConversationPayload) =>
  API.put(`/community/conversation/${conversationId}`, data);
export const followUnfollowConversation = (conversationId: string) =>
  API.post('/community/conversation/follow', { conversationId });

export const getConversationsByGroupId = (groupId: string, params: TPagination) => {
  const queryParams = new URLSearchParams(params as any).toString();

  return API.get(`community/${groupId}/conversations?${queryParams}`);
};
export const getConversationsByUser = (id: string) =>
  API.get(`/community/conversation/createdBy/${id}`);
export const getConversationsByConversationId = (conversationId: string) =>
  API.get(`/community/conversation/${conversationId}`);
export const deleteConversationById = (conversationId: string) =>
  API.delete(`/community/conversation/feed/${conversationId}`);

//Conversation replies
export const createConversationReply = (data: IReplyPayload) => API.post('/community/reply', data);
export const updateConversationReply = ({ data, replyId }: IReplyPayloadEdit) =>
  API.put(`/community/reply/${replyId}`, data);

export const likeDislikeResource = (conversationId: string) =>
  API.post('/community/like', { conversationId });
export const likeDislikeResourceReply = (replyId: string) =>
  API.post('/community/reply/like', { replyId });
