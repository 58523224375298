/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useReducer } from 'react';
import { isEmpty } from 'lodash';

//types
import { IDevPanel, IDevPanelContext, IDevPanelAction, TDevPanelContextProps } from 'types';

const initialState: IDevPanel = {
  // @ts-ignore
  devPanelBackendUrl: isEmpty(window.localStorage.getItem('DEV_PANEL_BACKEND_URL'))
    ? undefined
    : window.localStorage.getItem('DEV_PANEL_BACKEND_URL'),
};

export const DevPanelState = React.createContext<IDevPanelContext | null>(null);

const reducer = (state: IDevPanel, action: IDevPanelAction): IDevPanel => {
  switch (action.type) {
    case 'SET_DEV_PANEL_BACKEND_URL':
      return {
        devPanelBackendUrl: action.devPanelBackendUrl,
      };
    case 'CLEAR_DEV_PANEL_BACKEND_URL':
      return {
        devPanelBackendUrl: undefined,
      };
    default:
      return state;
  }
};

const DevPanelProvider = ({ children }: TDevPanelContextProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setDevPanelBackendUrl = (devPanelBackendUrl: string) => {
    window.localStorage.setItem('DEV_PANEL_BACKEND_URL', devPanelBackendUrl);

    dispatch({ type: 'SET_DEV_PANEL_BACKEND_URL', devPanelBackendUrl });
  };

  const clearDevPanelBackendUrl = () => {
    window.localStorage.removeItem('DEV_PANEL_BACKEND_URL');
    dispatch({ type: 'CLEAR_DEV_PANEL_BACKEND_URL', devPanelBackendUrl: undefined });
  };

  const value = {
    state,
    setDevPanelBackendUrl,
    clearDevPanelBackendUrl,
  };

  return <DevPanelState.Provider value={value}>{children}</DevPanelState.Provider>;
};

export default DevPanelProvider;
