import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { markAsShopifyPaid } from 'services/api/apiCalls/admin';
//types
import { TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useMutationMarkAsShopifyPaid = (setDialogContent: (dialogContent: null) => void) => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const { mutate: markAsShopifyPaidMutate, isLoading: isMarkingAsShopifyPaid } = useMutation<
    AxiosResponse<string>,
    AxiosError<TError>,
    { email: string }
  >(payload => markAsShopifyPaid(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.BREEDER_LIST, {
        refetchInactive: true,
      });
      addToast('Successfully marked as shopify paid.', {
        appearance: 'success',
        autoDismiss: true,
        placement: 'right',
      });
      setDialogContent(null);
    },
    onError: error => {
      addToast(
        error?.response?.data?.ExceptionMessage ||
          error?.response?.data?.error_description ||
          error.message,
        {
          appearance: 'error',
          autoDismiss: true,
          placement: 'right',
        },
      );
      setDialogContent(null);
    },
  });

  return {
    markAsShopifyPaidMutate,
    isMarkingAsShopifyPaid,
  };
};

export default useMutationMarkAsShopifyPaid;
