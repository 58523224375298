import React from 'react';

//types
import { IToggleSwitch } from 'types';
//styles
import './index.scss';

function ToggleSwitch({ id, checked, toggleBG = '#cc7191', ...props }: IToggleSwitch) {
  return (
    <label className="switch" htmlFor={id}>
      <input id={id} type="checkbox" {...props} checked={checked} />
      <span className="slider" style={{ '--toggle-bg': toggleBG } as React.CSSProperties}></span>
      <span className="text">{checked ? 'ON' : 'OFF'}</span>
    </label>
  );
}

export default ToggleSwitch;
