import {
  TGoogleMapAddress,
  TAddressComponent,
  TResultAddressComponent,
  TGeocodeResponse,
} from 'types';

export function parseAddress(htmlString: string): TGoogleMapAddress | null {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  const localityElement = doc.querySelector('.locality');
  const regionElement = doc.querySelector('.region');
  const postalCodeElement = doc.querySelector('.postal-code');
  const countryNameElement = doc.querySelector('.country-name');

  const address: TGoogleMapAddress = {
    city: localityElement?.textContent?.trim() || '',
    state: regionElement?.textContent?.trim() || '',
    postalCode: postalCodeElement?.textContent?.trim() || '',
    country: countryNameElement?.textContent?.trim() || '',
    lat: 0,
    lng: 0,
  };

  return address;
}

export const getPostalCode = async (lat: number, lng: number) => {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

  try {
    const response: Response = await fetch(url);
    const data: TGeocodeResponse = await response.json();
    const results: TResultAddressComponent[] = data.results;

    if (results && results.length > 0) {
      const addressComponents: TAddressComponent[] = results[0].address_components;
      const postalCodeObj: TAddressComponent | undefined = addressComponents.find(component =>
        component.types.includes('postal_code'),
      );

      if (postalCodeObj) {
        return postalCodeObj.long_name;
      } else {
        return '';
      }
    }
  } catch (error) {
    return '';
  }
};

export function getFullAddress(parsedAddress: TGoogleMapAddress) {
  return `${parsedAddress.streetAddress}, ${parsedAddress.city}, ${parsedAddress.state} ${parsedAddress.postalCode}, ${parsedAddress.country}`;
}
