import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { UseFormReset, UseFormSetError } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';

//services
import { addNoteForBreederByAdmin, addNoteForBuyerByAdmin } from 'services/api/apiCalls/admin';
//types
import { IAddNotePayload, IAddNoteSchema, INote, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useMutationAddNote = (
  setError: UseFormSetError<IAddNoteSchema>,
  resetForm: UseFormReset<IAddNoteSchema>,
  isBuyer: boolean,
) => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const { mutate: addNoteMutate, isLoading: isAdding } = useMutation<
    AxiosResponse<INote>,
    AxiosError<TError>,
    IAddNotePayload
  >(isBuyer ? addNoteForBuyerByAdmin : addNoteForBreederByAdmin, {
    onSuccess: () => {
      addToast('Note added successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
      resetForm({ note: '' });
      queryClient.invalidateQueries(
        isBuyer ? QUERY_KEYS.ADMIN_BUYER_NOTE_LIST : QUERY_KEYS.ADMIN_BREEDER_NOTE_LIST,
        {
          refetchInactive: true,
        },
      );
    },
    onError: err => {
      setError('note', {
        type: 'custom',
        message: err?.response?.data?.ExceptionMessage || err?.message,
      });
    },
  });

  return {
    addNoteMutate,
    isAdding,
  };
};

export default useMutationAddNote;
