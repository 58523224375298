export const getBreederProgressBarPercentage = (step: number) => {
  switch (step) {
    case 1:
      return 'percent1in12';
    case 2:
      return 'percent2in12';
    case 3:
      return 'percent3in12';
    case 4:
      return 'percent4in12';
    case 5:
      return 'percent5in12';
    case 6:
      return 'percent6in12';
    case 7:
      return 'percent7in12';
    case 8:
      return 'percent8in12';
    case 9:
      return 'percent9in12';
    case 10:
      return 'percent10in12';
    case 11:
      return 'percent11in12';
    case 12:
      return 'percent12in12';

    default:
      return 'percent12in12';
  }
};

export const getBuyerProgressBarPercentageFor9Steps = (step: number) => {
  switch (step) {
    case 1:
      return 'percent1in9';
    case 2:
      return 'percent2in9';
    case 3:
      return 'percent3in9';
    case 4:
      return 'percent4in9';
    case 5:
      return 'percent5in9';
    case 6:
      return 'percent6in9';
    case 7:
      return 'percent7in9';
    case 8:
      return 'percent8in9';
    case 9:
      return 'percent9in9';

    default:
      return 'percent1in9';
  }
};

export const getBuyerProgressBarPercentageFor10Steps = (step: number) => {
  switch (step) {
    case 1:
      return 'percent1in10';
    case 2:
      return 'percent2in10';
    case 3:
      return 'percent3in10';
    case 4:
      return 'percent4in10';
    case 5:
      return 'percent5in10';
    case 6:
      return 'percent6in10';
    case 7:
      return 'percent7in10';
    case 8:
      return 'percent8in10';
    case 9:
      return 'percent9in10';
    case 10:
      return 'percent10in10';

    default:
      return 'percent1in10';
  }
};
