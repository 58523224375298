import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

//constants
import { QUERY_KEYS } from 'constants/queryKeys';
//services
import { getKnowledgeTags } from 'services/api/apiCalls/tags';
// import { getArticlesByUser } from 'services/api/apiCalls/articles';
// import { getConversationsByUser } from 'services/api/apiCalls/community';
//types
// import { IArticle, IConversation } from 'types';

export const useQueryKnowledgeTags = () => {
  const {
    data: knowledgeTags,
    error: errorKnowledgeTags,
    isLoading: isLoadingKnowledgeTags,
  } = useQuery<AxiosResponse<{ tags: string[] }>, Error>(
    QUERY_KEYS.KNOWLEDGE_TAGS,
    getKnowledgeTags,
  );

  return {
    errorKnowledgeTags,
    knowledgeTags: knowledgeTags?.data?.tags?.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0)),
    isLoadingKnowledgeTags,
  };
};

// export const useQueryBreederArticles = (profileBreederId: string) => {
//   const {
//     data: breederArticles,
//     error: errorBreederArticles,
//     isLoading: isLoadingBreederArticles,
//   } = useQuery<AxiosResponse<IArticle[]>, Error>([QUERY_KEYS.ARTICLES, profileBreederId], () =>
//     getArticlesByUser(profileBreederId),
//   );

//   return {
//     errorBreederArticles,
//     breederArticles: breederArticles?.data,
//     isLoadingBreederArticles,
//   };
// };

// export const useQueryBreederDiscussions = (profileBreederId: string) => {
//   const {
//     data: breederDiscussions,
//     error: errorBreederDiscussions,
//     isLoading: isLoadingBreederDiscussions,
//   } = useQuery<AxiosResponse<IConversation[]>, Error>(
//     [QUERY_KEYS.COMMUNITY_CONVERSATIONS_BY_GROUP, profileBreederId],
//     () => getConversationsByUser(profileBreederId),
//   );

//   return {
//     errorBreederDiscussions,
//     breederDiscussions: breederDiscussions?.data,
//     isLoadingBreederDiscussions,
//   };
// };
