import { Dispatch, SetStateAction } from 'react';
import { useInfiniteQuery } from 'react-query';

//services
import { getBreederMatches } from 'services/api/apiCalls/breeder';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useQueryMatches = (
  showArchived: boolean,
  setLeadsSubtab?: Dispatch<SetStateAction<'active' | 'archived'>>,
  enabled = true,
) => {
  const {
    data: matches,
    isError,
    isLoading,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    refetch,
    isFetching,
  } = useInfiniteQuery(
    [QUERY_KEYS.BREEDER_MATCHES, showArchived],
    ({ pageParam = 1 }) =>
      showArchived
        ? getBreederMatches({ page: pageParam, limit: 10, filter: 'archive' })
        : getBreederMatches({ page: pageParam, limit: 10 }),
    {
      getNextPageParam: lastPage => {
        if (lastPage.meta.currentPage === lastPage.meta.totalPages) {
          return undefined;
        } else return lastPage.meta.currentPage + 1;
      },
      enabled,
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      refetchOnMount: false,
      staleTime: 5 * 60 * 1000, // 5 minutes
      onSuccess: res => {
        if (res.pages[0].meta.totalItems === 0 && setLeadsSubtab) {
          showArchived ? setLeadsSubtab('active') : setLeadsSubtab('archived');
        }
      },
    },
  );

  return {
    total: Number(matches?.pages[0].meta.totalItems),
    matches,
    isError,
    isLoading,
    refetch,
    isFetching,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
  };
};

export const useQueryMatchesBasic = () => {
  const {
    data: matchesBasic,
    isError,
    isLoading,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    refetch,
    isFetching,
  } = useInfiniteQuery(
    [QUERY_KEYS.BREEDER_MATCHES],
    ({ pageParam = 1 }) => getBreederMatches({ page: pageParam, limit: 10 }),
    {
      getNextPageParam: lastPage => {
        if (lastPage.meta.currentPage === lastPage.meta.totalPages) {
          return undefined;
        } else return lastPage.meta.currentPage + 1;
      },
      keepPreviousData: true,
    },
  );

  return {
    total: Number(matchesBasic?.pages[0].meta.totalItems),
    matchesBasic,
    isError,
    isLoading,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    refetch,
    isFetching,
  };
};
