export const publicNavLinks = [
  {
    groupName: 'For Pet Buyers',
    links: [
      {
        name: 'Buyer Overview',
        path: 'https://www.telltail.com/buyer-overview',
      },
      {
        name: 'Buyer Match',
        path: 'https://www.telltail.com/buyer-match',
      },
      {
        name: 'How it Works',
        path: 'https://www.telltail.com/how-it-works-buyers',
      },
    ],
  },
  {
    groupName: 'For Breeders',
    links: [
      {
        name: 'Breeder Overview',
        path: 'https://www.telltail.com/breeder-overview',
      },
      {
        name: 'Breeder Membership',
        path: 'https://www.telltail.com/dog-breeder-membership',
      },
      {
        name: 'Breeder Certified',
        path: 'https://www.telltail.com/dog-breeder-certified',
      },
      {
        name: 'Our Approach',
        path: 'https://www.telltail.com/our-approach-to-breeders',
      },
      {
        name: 'How it Works',
        path: 'https://www.telltail.com/how-it-works-breeders',
      },
    ],
  },
  {
    groupName: 'resources',
    links: [
      {
        name: 'Breedopedia',
        path: '/breedopedia',
      },
      {
        name: 'Resources',
        path: 'https://www.telltail.com/learning-center',
      },
    ],
  },
];
