import React from 'react';
import { Box, Card, Typography, ImageList, ImageListItem } from '@mui/material';
import { isEmpty } from 'lodash';

//types
import { IBreeder } from 'types';
//components
import Heading from '../Heading';
import InfoView from 'components/common/InfoView';

const SubHeading = ({ text }: { text: string }) => {
  return (
    <Box bgcolor="pink" py={1} pl={2}>
      <Typography variant="subtitle1" color="#333" fontWeight="bold">
        {text}
      </Typography>
    </Box>
  );
};

const EmptyView = ({ text }: { text: string }) => (
  <Typography variant="subtitle1" textAlign="center" p={3} color="#808080">
    {text}
  </Typography>
);

const getRefurralCodeValue = (refurralCode: string, isRefurralValid: boolean | undefined) => {
  const validityString = isRefurralValid === undefined || isRefurralValid ? 'Valid' : 'Invalid';

  return (
    <span>
      {refurralCode}{' '}
      <span className={validityString === 'Valid' ? 'text-success' : 'text-error'}>
        ({validityString})
      </span>
    </span>
  );
};

const UserDetails = ({ breederInfo }: { breederInfo: IBreeder }) => {
  if (!breederInfo) return <></>;

  return (
    <Box display="flex" maxWidth="75vw">
      {/* Left Side View */}
      <Box flex={1} flexDirection="column" maxWidth="50%">
        {/* About Yourself */}
        <Heading text="About Yourself" />

        <SubHeading text="Personal Information" />
        <InfoView label="Breeder Id:" value={breederInfo._id} />
        {breederInfo.refurralCode ? (
          <InfoView
            label="Refurral Code:"
            value={getRefurralCodeValue(breederInfo.refurralCode, breederInfo.isReferralValid)}
          />
        ) : (
          <InfoView label="Hear about us:" value={breederInfo.hearAboutUs || 'N/A'} />
        )}
        <InfoView
          label="Name:"
          value={`${breederInfo.firstName}  ${breederInfo.middleName} ${breederInfo.lastName}`.replaceAll(
            'null',
            '',
          )}
        />
        <InfoView label="Business/Program Name:" value={breederInfo.businessProgram} />

        <SubHeading text="Your Location" />
        <InfoView
          label="Business/Program Name:"
          value={`${breederInfo.address}, ${breederInfo.state}, ${breederInfo.zipCode},  ${breederInfo.country}`}
        />
        {/* About Yourself */}

        {/* About Your Program */}
        <Heading text="About Your Program" />

        <SubHeading text="Breeding Experience" />
        <InfoView
          label="Breeds in program:"
          value={breederInfo.breeds.map(breed => breed).join(', ')}
        />
        <InfoView label="Years of experience:" value={breederInfo.yearsOfExperience} />

        <SubHeading text="In your program" />
        <InfoView label="How many dams do you have?" value={breederInfo.numberOfDams} />
        <InfoView label="How many studs do you have?" value={breederInfo.numberOfStuds} />
        <InfoView
          label="Additional breeding-related information:"
          value={breederInfo.breederProgramDescription}
          vertical
        />
        <InfoView label="Where do your dams live?" value={breederInfo.damsLocation} />
        <InfoView
          label="For those kept on site, where do they sleep?"
          value={breederInfo.damsSleepLocation}
          vertical
        />
        <InfoView
          label="Do you use the stud services of other breeders as part of your program?"
          value={breederInfo.isUseStudServices ? 'Yes' : 'No'}
          vertical
        />
        <InfoView
          label="Do you sell stud services?"
          value={breederInfo.isSellStudServices ? 'Yes' : 'No'}
        />

        <SubHeading text="What is the youngest age you will..." />
        <InfoView
          label="Send a puppy home to new pet parents?"
          value={breederInfo.minimalPuppyAgeToSend}
        />
        <InfoView label="Breed a new dam?" value={breederInfo.minimalDamAgeToBreed} />

        <SubHeading text="How many times do you breed each dam..." />
        <InfoView label="In an 18 month period?" value={breederInfo.damBreedIn18mPeriod} />
        <InfoView label="In her lifetime?" value={breederInfo.damBreedInLifetime} />

        <SubHeading text="What food do you feed your..." />
        <InfoView label="Dams?" value={breederInfo.damFood} />
        <InfoView label="Puppies?" value={breederInfo.puppiesFood} />

        <SubHeading text="Do you..." />
        <InfoView
          label="Conduct health and/or genetic tests on your dogs?"
          value={breederInfo.isHealthTest ? 'Yes' : 'No'}
          vertical
        />
        <InfoView
          label="What genetic tests do you conduct?"
          value={breederInfo.healthTests.map(healthTest => healthTest).join(', ')}
        />
        <InfoView
          label="What health tests do you conduct?"
          value={breederInfo.healthTests.map(healthTest => healthTest).join(', ')}
        />
        <InfoView
          label="Do you vaccinate puppies before they go home?"
          value={breederInfo.isVaccinatePuppies ? 'Yes' : 'No'}
          vertical
        />
        <InfoView label="Description:" value={breederInfo.vaccinationDescription} vertical />
        <InfoView
          label="Socialize your puppies before they are sent home?"
          value={breederInfo.isSocializePuppies ? 'Yes' : 'No'}
          vertical
        />
        <InfoView label="Description:" value={breederInfo.socializeDescription} vertical />
        <InfoView
          label="Do you have a reproductive veterinarian?"
          value={breederInfo.isReproductiveVeterinarian ? 'Yes' : 'No'}
          vertical
        />
        <InfoView
          label="Name and contact info:"
          value={`${breederInfo.vetName}, ${breederInfo.vetEmail}, ${breederInfo.vetPhoneNumber}`}
        />
        {/* About Your Program */}

        {/* About Your Business */}
        <Heading text="About Your Business" />
        <InfoView
          label="Which best describes your litter availability?"
          value={breederInfo.litterAvailability}
          vertical
        />
        <InfoView
          label="Approximately how many puppies do you sell annually?"
          value={breederInfo.aproxAnnualSellPuppies}
          vertical
        />
        <InfoView
          label="Do you share information (photos, health records) about the dam and stud with prospective pet parents?"
          value={breederInfo.isShareInfoWithParents ? 'Yes' : 'No'}
          vertical
        />
        <InfoView
          label="Have ever sold a puppy to a third-party vendor, such as a pet store?"
          value={breederInfo.isSellPuppyToThirdParty ? 'Yes' : 'No'}
          vertical
        />

        <SubHeading text="Do you typically ask pet parents to..." />
        <InfoView
          label="Fill out an application for a puppy?"
          value={breederInfo.isFillApplication ? 'Yes' : 'No'}
        />
        <InfoView
          label="Pick puppies up from your home?"
          value={breederInfo.isPickPuppyFromHome ? 'Yes' : 'No'}
        />
        <InfoView
          label="Sign a contract to purchase a puppy?"
          value={breederInfo.isContractSigned ? 'Yes' : 'No'}
        />
        <InfoView
          label="Stay in touch for questions over time?"
          value={breederInfo.isStayInTouch ? 'Yes' : 'No'}
        />
        <InfoView
          label="Is there anything else about your program that you would like for us to know?"
          value={breederInfo.programDetails}
          vertical
        />
        {/* About Your Business */}
      </Box>

      <Box sx={{ width: 20 }} />

      {/* Right Side View */}
      <Box flex={1} flexDirection="column" maxWidth="50%">
        {/* About Your Program */}
        <Heading text="How You Currently Learn & Connect" />
        <SubHeading text="Have you completed any..." />
        <InfoView
          label="Breeder educational programs (i.e. Puppy Culture, Midwoofery, Avidog, AKC Canine College, etc.)?"
          value={breederInfo.isCompletedBreederEducation ? 'Yes' : 'No'}
          vertical
        />
        <InfoView
          label="Names (separated by commas):"
          value={breederInfo.educationPrograms.map(educationProgram => educationProgram).join(', ')}
          vertical
        />

        <SubHeading text="Do you belong to any..." />
        <InfoView
          label="Breeding clubs or organizations?"
          value={breederInfo.isBelongToClub ? 'Yes' : 'No'}
        />
        <InfoView
          label="Clubs or Organizations:"
          value={breederInfo.breederClubs.map(breederClub => breederClub).join(', ')}
        />
        <InfoView
          label="Clubs or Organizations:"
          value={breederInfo.breederClubs.map(breederClub => breederClub).join(', ')}
        />
        <SubHeading text="What is your..." />
        <InfoView label="Breeding program website?" value={breederInfo.breedingProgramWebsite} />
        <SubHeading text="What Social Media do you use?" />
        <InfoView label="Facebook:" value={breederInfo.facebookUrl} />
        <InfoView
          label="What Breeder Groups Do You Participate in on Facebook?"
          value={breederInfo.facebookGroups.map(facebookGroup => facebookGroup).join(', ')}
          vertical
        />
        <InfoView label="Instagram:" value={breederInfo.instagramUrl} />
        <InfoView label="Youtube:" value={breederInfo.youtubeUrl} />
        {/* About Your Program */}

        {/* Your Account */}
        <Heading text="Your Account" />
        <SubHeading text="Confirm Username" />
        <InfoView label="Username:" value={breederInfo.displayName} />
        <SubHeading text="Program Picture" />
        {!isEmpty(breederInfo.profilePicUrl) ? (
          <Box display="flex" justifyContent="center" m={2}>
            <Card sx={{ width: '35%', aspectRatio: '1:1' }} elevation={3}>
              <img
                style={{ width: '100%' }}
                alt="Program Picture"
                src={breederInfo.profilePicUrl}
              />
            </Card>
          </Box>
        ) : (
          <EmptyView text="No photo found." />
        )}

        {/* Your Account */}

        {/* Your Profile */}
        <Heading text="Your Profile" />
        <SubHeading text="About Me and My Program..." />
        <InfoView
          label="A very short description of me and my program(max 140 characters):"
          value={breederInfo.breederProgramDescription}
          vertical
        />
        <InfoView
          label="Aim for 2-3 paragraphs explaning what you do and your business:"
          value={breederInfo.programDetails}
          vertical
        />
        <SubHeading text="I am Knowledgeable About..." />
        <InfoView
          label="Add up to 4 tags to help other breeders find you (Ex: photography, genetics):"
          value={breederInfo.knowledgeTags.map(knowledgeTag => knowledgeTag).join(', ')}
          vertical
        />
        {/* Your Account */}

        {/* Photos Gallery */}
        <Heading text="Photos Gallery" />

        {!isEmpty(breederInfo.breederPhotoGalleries) ? (
          <ImageList sx={{ width: '100%', height: 'auto', p: 1 }} cols={3}>
            {breederInfo.breederPhotoGalleries.map(item => {
              const { id, photoUrl } = item;

              return (
                <ImageListItem key={`${photoUrl}${id}`}>
                  <img
                    src={`${photoUrl}?w=100&h=auto&fit=crop&auto=format`}
                    srcSet={`${photoUrl}?w=100&h=auto&fit=crop&auto=format&dpr=2 2x`}
                    alt={`Gallery Photo ${id}`}
                    loading="lazy"
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
        ) : (
          <EmptyView text="No photo found." />
        )}

        {/* Photos Gallery */}
      </Box>
    </Box>
  );
};

export default UserDetails;
