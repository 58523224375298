import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { includes } from 'lodash';
import { useToasts } from 'react-toast-notifications';
import LogRocket from 'logrocket';

//services
import { registerBuyer } from 'services/api/apiCalls/auth';
//types
import { TGeneralInfoMutationBuyer, IResgisteredResponse, IBuyerRegistrationPayload } from 'types';
//helpers
import { determineMemberType, determineSubscriptionType } from 'helpers/users';

const useMutationRegisterBuyer = ({ step, setStep, setError }: TGeneralInfoMutationBuyer) => {
  const { addToast } = useToasts();

  const { mutate, error, isLoading } = useMutation<
    AxiosResponse<IResgisteredResponse>,
    AxiosError<Error>,
    IBuyerRegistrationPayload
  >(registerBuyer, {
    onSuccess: data => {
      setStep(step + 1);
      window.scrollTo({ top: 0 });

      // LogRocket
      LogRocket.identify(data.data?.userId || 'Unknown', {
        userId: data.data?.userId || 'Unknown',
        name: `${data.data?.firstName} ${data.data.lastName}` || 'Unknown',
        email: data.data?.email || 'Unknown',
        ttUser: includes(data.data?.email, 'telltail'),
        subscriptionType: determineSubscriptionType(data.data?.subscriptionType),
        memberType: determineMemberType(data.data.userRoles),
      });

      // Heap Analytics
      window.heap.load(process.env.REACT_APP_HEAP_ANALYTICS_API_KEY);
      window.heap.identify(data.data.userId);
      const userRoles = data.data.userRoles;
      const memberType = userRoles?.includes('admin')
        ? 'Admin'
        : userRoles?.includes('buyer')
        ? 'Buyer'
        : 'Breeder';
      window.heap.addUserProperties({ 'Member Type': memberType });
      window.heap.addUserProperties({ email: data.data.email });
    },
    onError: err => {
      includes(err?.response?.data?.message, 'Email') &&
        setError('emailAddress', {
          type: 'custom',
          message: err?.response?.data?.message,
        });

      addToast(err?.response?.data?.message || 'Something went wrong with your request.', {
        appearance: 'error',
        autoDismiss: true,
      });
    },
  });

  return {
    mutate,
    isLoading,
    error,
  };
};

export default useMutationRegisterBuyer;
