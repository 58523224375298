import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

//components
import VouchedTable from './VouchedTable';

const Vouched = () => {
  return (
    <>
      <h3 className="mb-4">Breeders that are not Vouched</h3>
      <VouchedTable />
    </>
  );
};

export default Vouched;
