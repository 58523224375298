import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { updateBuyerProfile } from 'services/api/apiCalls/buyer';
//types
import { IBuyerInfo, TError, IBuyerAccountPayload } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useMutationUpdateBuyerProfile = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { addToast } = useToasts();

  return useMutation<AxiosResponse<IBuyerInfo>, AxiosError<TError>, IBuyerAccountPayload>(
    updateBuyerProfile,
    {
      onSuccess: async () => {
        window.scrollTo({ top: 0 });
        addToast('Your information has been updated!', {
          appearance: 'success',
          autoDismiss: true,
        });
        await queryClient.invalidateQueries(QUERY_KEYS.USER_STATUS, {
          refetchInactive: true,
        });

        navigate('/profile');

        await queryClient.invalidateQueries(QUERY_KEYS.BUYER_ACCOUNT_DETAILS, {
          refetchInactive: true,
        });
      },

      onError: async () => {
        addToast('Something went wrong with your request.', {
          appearance: 'error',
          autoDismiss: true,
        });
      },
    },
  );
};
