import React, { useEffect } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

export default function FAQsRedirect() {
  useEffect(() => {
    window.location.href = 'https://www.telltail.com/faqs';
  }, []);

  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center mb-5">
      <AiOutlineLoading3Quarters className="loading-animation svg-loading w-25 mt-5" />
    </div>
  );
}
