/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQueryClient } from 'react-query';
import { UseFormSetError } from 'react-hook-form';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { updateBuyerEmailByAdmin } from 'services/api/apiCalls/admin';
//types
import { IUpdateEmailSchema, IUpdateBuyerEmailPayload, IBuyerInfo } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useMutationUpdateBuyerEmail = (setError: UseFormSetError<IUpdateEmailSchema>) => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const { isLoading: isUpdatingEmail, mutate: updateEmailMutate } = useMutation<
    AxiosResponse<IBuyerInfo>,
    AxiosError<Error>,
    IUpdateBuyerEmailPayload
  >(updateBuyerEmailByAdmin, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.BUYER_ACCOUNT_DETAILS_FOR_ADMIN, {
        refetchInactive: true,
      });
      addToast("Buyer's email updated successfully!", {
        appearance: 'success',
        autoDismiss: true,
      });
    },
    onError: err => {
      setError('newEmail', {
        type: 'custom',
        message: err?.response?.data?.message,
      });
    },
  });

  return {
    isUpdatingEmail,
    updateEmailMutate,
  };
};

export default useMutationUpdateBuyerEmail;
