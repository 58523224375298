export function determineSubscriptionType(subscriptionType: string): string {
  if (subscriptionType === 'free') {
    return 'Basic';
  } else if (subscriptionType === 'premium') {
    return 'Premium';
  } else if (subscriptionType === 'free_premium') {
    return 'Free Premium';
  } else {
    return '';
  }
}

export function determineSubscriptionStatus(subscriptionStatus: string): string {
  if (subscriptionStatus === 'in-review') {
    return 'In Review';
  } else {
    return '';
  }
}

export function determineMemberType(userRoles: string[] | undefined): string {
  if (userRoles?.includes('admin')) {
    return 'Admin';
  } else if (userRoles?.includes('buyer')) {
    return 'Buyer';
  } else if (userRoles?.includes('free')) {
    return 'Breeder';
  } else {
    return '';
  }
}
