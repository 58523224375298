import React, { useState, useMemo, useRef } from 'react';
import { Box } from '@mui/material';
import { Delete } from '@mui/icons-material';
import MaterialReactTable, { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import { TbPlugConnected } from 'react-icons/tb';

//hooks
import { useQueryGetShopifyUnprocessed } from './__hooks/useQueryGetShopifyUnprocessed';
import { useQueryUserList } from '../Users/__hooks/useQueryUserList';
import useMutationDeleteFromUnprocessed from './__hooks/useMutationDeleteFromUnprocessed';
//types
import { IShopifyUnlinkedAccount, IDialogContent } from 'types';
//components
import TTButton from 'components/common/TTButton';
import ActionButton from 'components/common/ActionButton';
import UnprocessedDetailsDialog from './UnprocessedDetailsDialog';
import AlertDialog from 'components/common/AlertDialog';
//constants
import { csvOptions } from 'helpers/cvsOptions';

const ShopifyUnlinkedTable = () => {
  const userRef = useRef('');
  const [dialogContent, setDialogContent] = useState<IDialogContent | null>(null);
  const [unprocessedDialogVisible, setUnprocessedDialogVisible] = useState(false);
  const [unprocessedDetails, setUnprocessedDetails] = useState<IShopifyUnlinkedAccount>({
    id: '',
    orderId: '',
    contactEmailShopify: '',
  });

  //Query hooks
  const { isError, data, total, isLoading, refetchShopifyUnprocessed, isFetching } =
    useQueryGetShopifyUnprocessed();
  const { data: userData, refetchUserList } = useQueryUserList();
  //Mutation hooks
  const { deleteUser, isDeleting } = useMutationDeleteFromUnprocessed(setDialogContent);

  const columns = useMemo<MRT_ColumnDef<IShopifyUnlinkedAccount>[]>(
    () => [
      {
        header: 'Internal ID',
        accessorKey: 'id',
        size: 200,
      },
      {
        header: 'Order ID (Shopify)',
        accessorKey: 'orderId',
        size: 200,
      },
      {
        header: 'Shopify Email',
        accessorKey: 'contactEmailShopify',
        size: 200,
      },
    ],
    [],
  );

  // Download table to CSV format logic
  const csvConfig = csvOptions(
    columns,
    `Unlinked_shopify_accounts_${moment().utc().utcOffset('-0700').format('MMMM_D_YYYY_hh_mm_A')}`,
  );
  const csvExporter = new ExportToCsv(csvConfig);
  const csvData = useMemo(
    () =>
      data?.map(item => ({
        id: item.id,
        author: item.orderId,
        contactEmailShopify: item.contactEmailShopify,
      })),
    [data],
  );
  const handleExportData = () => {
    csvExporter.generateCsv(csvData);
  };

  const handleConnectPress = (row: MRT_Row<IShopifyUnlinkedAccount>) => {
    setUnprocessedDetails({
      id: row.original.id,
      orderId: row.original.orderId,
      contactEmailShopify: row.original.contactEmailShopify,
    });
    setUnprocessedDialogVisible(true);
  };

  const handleDeletePress = (row: MRT_Row<IShopifyUnlinkedAccount>) => {
    userRef.current = row.original.id;
    setDialogContent({
      title: 'Delete User?',
      message: 'You are about to delete this user from the table. You really want to do it?',
    });
  };

  // Render table actions column
  const renderTableActions = ({ row }: { row: MRT_Row<IShopifyUnlinkedAccount> }) => {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" gap="0.5rem">
        <ActionButton
          color="black"
          title="Connect"
          Icon={<TbPlugConnected />}
          onClick={() => handleConnectPress(row)}
        />
        <ActionButton title="Delete" Icon={<Delete />} onClick={() => handleDeletePress(row)} />
      </Box>
    );
  };

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data ?? []}
        rowCount={total ?? 0}
        initialState={{ pagination: { pageSize: 10, pageIndex: 0 } }}
        memoMode="cells"
        state={{
          isLoading: isLoading,
          showAlertBanner: isError,
          showProgressBars: isFetching,
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 20, 50],
        }}
        enableColumnOrdering
        enableGlobalFilter={false}
        renderTopToolbarCustomActions={() => (
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              p: '0.5rem',
              pb: '2rem',
            }}
          >
            <TTButton
              className="btn-primary-orchid"
              height={40}
              width={200}
              disabled={isLoading || isFetching}
              text="Export to CSV"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
            />

            <TTButton
              className="ttBlue"
              height={40}
              width={200}
              disabled={isLoading || isFetching}
              text="Refresh"
              onClick={() => {
                refetchShopifyUnprocessed();
                refetchUserList();
              }}
            />
          </Box>
        )}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={renderTableActions}
      />

      {/* Open Unprocessed details modal */}
      {unprocessedDialogVisible && (
        <UnprocessedDetailsDialog
          open={unprocessedDialogVisible}
          handleClose={() => {
            setUnprocessedDetails({
              id: '',
              orderId: '',
              contactEmailShopify: '',
            });
            refetchShopifyUnprocessed();

            setUnprocessedDialogVisible(false);
          }}
          unprocessedDetails={unprocessedDetails}
          users={userData}
        />
      )}

      {/* Confirmation Dialog */}
      <AlertDialog
        isOpened={Boolean(dialogContent)}
        dialogContent={dialogContent}
        isLoading={isDeleting}
        onAgree={() => {
          deleteUser(userRef.current);
        }}
        handleClose={() => setDialogContent(null)}
      />
    </>
  );
};

export default ShopifyUnlinkedTable;
