import React from 'react';
import { Navigate } from 'react-router-dom';

//pages
import ArticleDetails from 'pages/Resources/DetailsArticle';
import VideoDetails from 'pages/Resources/DetailsVideo';
import BreederAccount from 'pages/BreederAccount';
import BuyerAccount from 'pages/BuyerAccount';
import AdminPanel from 'pages/AdminPanel';
import PawfficeHoursDetailsPage from 'pages/AdminPanel/PawfficeHours/PawfficeHoursDetailsPage';
import ActivityFeed from 'pages/ActivityFeed';
import ScamFree from 'pages/ScamFree';
import Deals from 'pages/Deals';
import DealDetails from 'pages/Deals/DealDetails';
import Resources from 'pages/Resources';
import DiscussionDetails from 'pages/DetailsDiscussion';
import VerifyBuyer from 'pages/VerifyBuyer';
import VerifyBadge from 'pages/VerifyBadge';
import Registration from 'pages/BreederRegistration';
import Home from 'pages/Home';
import BuyerOffer from 'pages/BuyerOffer';
import ShopifySuccess from 'pages/ShopifySuccess';
import ErrorScreen from 'pages/404';
import BuyerRegistration from 'pages/BuyerRegistration';
//pdfs
import NonTTBreederPDF from 'pdfs/NonTTBreederPDF';
//claims
import claims from './claims';

export const mainRoutes = (isBuyer: boolean) => [
  // Article details route
  {
    component: <ArticleDetails />,
    path: '/article/:articleId',
    permission: [claims.ADMIN, claims.USER, claims.BUYER],
  },
  // Video details route
  {
    component: <VideoDetails />,
    path: '/video/:videoId',
    permission: [claims.ADMIN, claims.USER, claims.BUYER],
  },
  // Discussion details route
  {
    component: <DiscussionDetails />,
    path: '/discuss/:discussionId',
    permission: [claims.ADMIN],
  },
  // Profile routes
  {
    component: isBuyer ? <BuyerAccount /> : <BreederAccount />,
    path: '/profile',
    permission: [claims.ADMIN, claims.USER, claims.BUYER],
  },
  {
    component: isBuyer ? <BuyerAccount /> : <BreederAccount />,
    path: '/profile/*',
    permission: [claims.ADMIN, claims.USER, claims.BUYER],
  },
  {
    component: isBuyer ? <BuyerAccount /> : <div></div>,
    path: '/profile/verify-phone',
    permission: [claims.ADMIN, claims.USER, claims.BUYER],
  },
  // Admin routes
  {
    component: <AdminPanel />,
    path: '/admin-panel/*',
    permission: [claims.ADMIN],
  },
  {
    component: <PawfficeHoursDetailsPage />,
    path: '/admin-panel/pawffice/:id',
    permission: [claims.ADMIN],
  },
  // Activity feed routes
  {
    component: <ActivityFeed />,
    path: '/activity-feed/*',
    permission: [claims.ADMIN],
  },
  // Deals routes
  {
    component: <Deals />,
    path: '/deals',
    permission: [claims.ADMIN, claims.USER],
  },
  {
    component: <DealDetails />,
    path: '/deals/:dealId',
    permission: [claims.ADMIN, claims.USER],
  },
  // Scam free and registration routes
  {
    component: <ScamFree />,
    path: '/scamfree',
    permission: [claims.ADMIN, claims.USER, claims.BUYER],
  },
  {
    component: <Registration />,
    path: '/get-certified',
    permission: [claims.ADMIN, claims.USER],
  },
  {
    component: <ShopifySuccess />,
    path: '/shopify-success',
    permission: [claims.ADMIN, claims.USER, claims.BUYER],
  },
  {
    component: <BuyerRegistration />,
    path: '/finish-buyer-registration',
    permission: [claims.ADMIN, claims.BUYER],
  },
  {
    component: <BuyerRegistration />,
    path: '/activate-profile',
    permission: [claims.ADMIN, claims.BUYER],
  },
  // Learn routes
  {
    component: !isBuyer ? <Resources /> : <ErrorScreen />,
    path: '/learn/*',
    permission: [claims.ADMIN, claims.USER, claims.BUYER],
  },
  // Verify routes
  {
    component: <VerifyBuyer />,
    path: '/verify',
    permission: [claims.ADMIN, claims.USER, claims.BUYER],
  },
  {
    component: <VerifyBadge />,
    path: '/verify-badge',
    permission: [claims.ADMIN, claims.USER, claims.BUYER],
  },
  // Home route
  {
    component: <Home />,
    path: '/home',
    permission: [claims.ADMIN, claims.USER, claims.BUYER],
  },
  {
    component: <NonTTBreederPDF />,
    path: '/non-tt-breeder-pdf/:content',
    permission: [claims.ADMIN],
  },
  {
    component: <BuyerOffer />,
    path: '/buyer-offer',
    permission: [claims.BUYER],
  },
  {
    component: <Navigate to="/profile" replace />,
    path: '*',
  },
];
