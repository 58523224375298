import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

//hooks
import useInterval from 'hooks/useInterval';
//components
import TTButton from 'components/common/TTButton';
import './index.scss';
//services
import { AuthState } from 'services/auth';
//types
import { TAuthContext } from 'types';

const ErrorScreen = () => {
  const navigate = useNavigate();
  const {
    state: { isAuthenticated },
  } = useContext(AuthState) as TAuthContext;
  const [count, setCount] = useState(0);

  useInterval(() => {
    setCount(count + 1);
  }, 1000);

  useEffect(() => {
    if (count === 5) {
      isAuthenticated ? navigate('/profile') : navigate('/login');
    }
  }, [count, navigate, isAuthenticated]);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center gap-4 wrapper-404">
      {isAuthenticated ? (
        <>
          <div>Something wrong happened! </div>
          <div>You will be redirected to your account in...</div>
        </>
      ) : (
        <>
          <div>This route does not exist! </div>
          <div>You will be redirected to login page in...</div>
        </>
      )}
      <div className="counter">{5 - count}</div>
      <TTButton
        type="submit"
        className="btn-primary-white"
        text={isAuthenticated ? 'Go to home page' : 'Go to login page'}
        onClick={() => {
          isAuthenticated ? navigate('/profile') : navigate('/login');
        }}
      />
    </div>
  );
};

export default ErrorScreen;
