//services
import API from 'services/api';

// Breeds overview page
export const getDropdownBreeds = () => API.get('/dogpedia/dropdown/kv-breeds');
export const getBreedsWithSizeVariation = () => API.get('/dogpedia/dropdown/size-variation-breeds');
export const getBreeds = async (params = {}) => {
  const queryParams = new URLSearchParams(params).toString();
  const res = await API.get(`/dogpedia?${queryParams}`);

  return res.data;
};

export const getBreed = (id: string) => API.get(`/dogpedia/${id}`);
export const getBreedTags = () => API.get('/dogpedia-tags');
