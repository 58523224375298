/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useContext } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//types
import { TMoreAboutBreeder, IMoreAboutProgramForm, TAuthContext, IBreederPayload } from 'types';
//components
import TTButton from 'components/common/TTButton';
//services
import { useDataLayer } from 'services/dataLayerContext';
import { AuthState } from 'services/auth';
//hooks
import { useMutationUpdateBreederInfo } from '../__hooks/useMutationUpdateBreederInfo';
//styles
import '../index.scss';
//validations
import { moreAboutProgramSchema } from './Validation';

export default function MoreAboutProgram({ setStep, step, breederInfo }: TMoreAboutBreeder) {
  const pushToDataLayer = useDataLayer();
  const {
    setRegistrationInfo,
    state: { breederId },
  } = useContext(AuthState) as TAuthContext;

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<IMoreAboutProgramForm>({
    resolver: yupResolver(moreAboutProgramSchema),
    defaultValues: {
      ...breederInfo,
    },
  });

  // Mutation hooks
  const { mutate, isLoading: isUpdatingBreederData } = useMutationUpdateBreederInfo({
    setStep,
    step,
  });

  const onSubmit = (data: IMoreAboutProgramForm) => {
    pushToDataLayer({
      event: 'moreAboutProgramClick',
      componentName: 'More about program',
      ...data,
    });
    setRegistrationInfo('moreAboutProgramForm', data);

    const breederRequestPayload = {
      data: {
        ...breederInfo,
        ...data,
        hearAboutUs: '',
        breederProgramDescription: data.programDetails,
      },
      id: breederId,
    };
    mutate(breederRequestPayload as IBreederPayload);
  };

  return (
    <Form
      onSubmit={
        isDirty
          ? handleSubmit(onSubmit)
          : () => {
              window.scrollTo({ top: 0 });
              setStep(step + 1);
            }
      }
      className="w-100"
      id="breederRegistrationMoreAboutProgramForm"
    >
      <div className="more-about-program-container">
        <h2>Tell us a little more about your program</h2>
        <p>This is your opportunity make your program stand out and shine.</p>

        <Row className="w-auto">
          <Col xs={12} className={`${errors.programDetails ? 'mb-0 input-error' : 'mb-4'}`}>
            <Form.Label className="form-labels">About my Program</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0"
              as="textarea"
              className="textarea"
              placeholder="My breeding program is unique because..."
              {...register('programDetails')}
            />
            {errors && errors.programDetails && (
              <span className="text-error mb-2">{errors.programDetails.message}</span>
            )}
          </Col>
        </Row>

        <Row className="w-auto">
          <Col xs={12} md={6} className={`${errors.facebookUrl ? 'mb-0 input-error' : 'mb-4'}`}>
            <Form.Label className="form-labels">Facebook</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0"
              type="text"
              placeholder="Enter facebook url"
              {...register('facebookUrl')}
            />
            {errors && errors.facebookUrl && (
              <span className="text-error mb-2">{errors.facebookUrl.message}</span>
            )}
          </Col>

          <Col xs={12} md={6} className={`${errors.instagramUrl ? 'mb-0 input-error' : 'mb-4'}`}>
            <Form.Label className="form-labels">Instagram</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0"
              type="text"
              placeholder="Enter instagram url"
              {...register('instagramUrl')}
            />
            {errors && errors.instagramUrl && (
              <span className="text-error mb-2">{errors.instagramUrl.message}</span>
            )}
          </Col>
        </Row>

        <Row className="w-auto">
          <Col xs={12} md={6} className={`${errors.twitterUrl ? 'mb-0 input-error' : 'mb-4'}`}>
            <Form.Label className="form-labels">Twitter(X)</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0"
              type="text"
              placeholder="Enter twitter(X) url"
              {...register('twitterUrl')}
            />
            {errors && errors.twitterUrl && (
              <span className="text-error mb-2">{errors.twitterUrl.message}</span>
            )}
          </Col>

          <Col xs={12} md={6} className={`${errors.youtubeUrl ? 'mb-0 input-error' : 'mb-4'}`}>
            <Form.Label className="form-labels">Youtube</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0"
              type="text"
              placeholder="Enter youtube url"
              {...register('youtubeUrl')}
            />
            {errors && errors.youtubeUrl && (
              <span className="text-error mb-2">{errors.youtubeUrl.message}</span>
            )}
          </Col>
        </Row>

        <Row className="w-auto">
          <Col xs={12} md={6} className={`${errors.tikTokUrl ? 'mb-0 input-error' : 'mb-4'}`}>
            <Form.Label className="form-labels">Tiktok</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0"
              type="text"
              placeholder="Enter tiktok url"
              {...register('tikTokUrl')}
            />
            {errors && errors.tikTokUrl && (
              <span className="text-error mb-2">{errors.tikTokUrl.message}</span>
            )}
          </Col>

          <Col
            xs={12}
            md={6}
            className={`${errors.breedingProgramWebsite ? 'mb-0 input-error' : 'mb-4'}`}
          >
            <Form.Label className="form-labels">Website</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0"
              type="text"
              placeholder="Enter website url"
              {...register('breedingProgramWebsite')}
            />
            {errors && errors.breedingProgramWebsite && (
              <span className="text-error mb-2">{errors.breedingProgramWebsite.message}</span>
            )}
          </Col>
        </Row>

        <div className="buttons-container">
          <span className="skip-button" onClick={() => setStep(step + 1)}>
            Skip for now
          </span>

          <TTButton
            height="50px"
            type="submit"
            className="btn-primary-orchid"
            text="Continue"
            disabled={isUpdatingBreederData}
          />
        </div>
      </div>
    </Form>
  );
}
