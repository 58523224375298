import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

//components
import TabPanel from 'components/common/TabPanel';
import UsersTable from './UsersTableNew';
//constants
import { adminPanelUserSubTabs } from 'constants/tabs';

const Users = () => {
  const [active, setActive] = useState(adminPanelUserSubTabs[0]);

  return (
    <>
      <h3 className="mb-4">Users</h3>
      <TabPanel tabsList={adminPanelUserSubTabs} current={active.value} setActive={setActive} />
      <UsersTable active={active} />
    </>
  );
};

export default Users;
