import React from 'react';
import { Box, Button } from '@mui/material';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//types
import { IConfirmationEmail } from 'types';
//validations
import { ConfirmationEmailSchema } from './Validation';
//hooks
import useMutationResendVerificationEmail from './__hooks/useMutationResendVerificationEmail';
//styles
import './index.scss';

const NonTTBreederForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IConfirmationEmail>({ resolver: yupResolver(ConfirmationEmailSchema) });

  // Mutation hooks
  const { mutate } = useMutationResendVerificationEmail();

  const onSubmit = (data: IConfirmationEmail) => mutate({ emailAddress: data.userEmail });

  return (
    <div className="non_tt_breeder">
      <h3 className="mb-2">Resend confirmation email</h3>
      <Box display="flex" flexDirection="column" width="98%" height="auto" position="relative">
        <Box mt={3}>
          <Form.Label>User Email</Form.Label>
          <Form.Control
            bsPrefix="input form-control mb-0 py-3"
            placeholder="Enter user email"
            {...register('userEmail')}
          />
        </Box>
        {errors && errors.userEmail && (
          <span className="text-error mt-2">{errors.userEmail.message}</span>
        )}

        <Button sx={{ marginTop: 5 }} variant="contained" onClick={handleSubmit(onSubmit)}>
          Resend verification email
        </Button>
      </Box>
    </div>
  );
};

export default NonTTBreederForm;
