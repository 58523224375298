/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { Button, DialogActions } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { concat, filter, find, isEmpty, omit } from 'lodash';
import Select from 'react-select';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

//types
import {
  IBuyerInfo,
  IBuyerMatchSettingsForm,
  IBreedWithSizeVairation,
  ISimilarBreedsData,
} from 'types';
//validation
import { buyerMatchSettingsByAdminSchema } from './Validation';
//hooks
import { useMutationUpdateBuyerSettingsByAdmin } from '../../__hooks/useMutationUpdateBuyerSettingsByAdmin';
import { useMutationUpdateMatchAcceptanceByAdmin } from '../../__hooks/useMutationUpdateMatchAcceptance';
//components
import BreedInfo from 'pages/BuyerRegistration/2_BreedsInProgram/BreedInfo';
import ToggleSwitch from 'components/common/ToggleSwitch';
//styles
import { multiSelectStyles } from 'components/styled/select';

const MatchSettings = ({
  buyerAccountId,
  buyerInfo,
  breeds,
  breedsWithId,
  onCancelPress,
}: {
  buyerAccountId: string;
  buyerInfo: IBuyerInfo;
  breeds: IBreedWithSizeVairation[];
  breedsWithId: { label: string; value: string }[];
  onCancelPress: () => void;
}) => {
  const [receiveMatches, setRecieveMatches] = useState(buyerInfo.acceptsCron);
  const [similarBreeds, setSimilarBreeds] = useState<ISimilarBreedsData[]>([]);

  const {
    handleSubmit,
    register,
    setValue,
    control,
    watch,
    setError,
    trigger,
    formState: { errors },
  } = useForm<IBuyerMatchSettingsForm>({
    resolver: yupResolver(buyerMatchSettingsByAdminSchema),
    defaultValues: {
      budgetMax: buyerInfo.budgetMax,
      breeds: buyerInfo.breeds?.map(breed => ({
        label: breed.breedName,
        value: breed.breedName,
      })),
      howSoon: buyerInfo.howSoon,
    },
  });

  const breedsDropdown = useMemo(
    () =>
      breeds?.map((breed: { breedName: string }) => ({
        label: breed.breedName,
        value: breed.breedName,
      })),
    [breeds],
  );

  // Mutation hooks
  const { mutate, isLoading } = useMutationUpdateBuyerSettingsByAdmin(buyerAccountId);
  const { mutate: updateMatchAcceptance } = useMutationUpdateMatchAcceptanceByAdmin(buyerAccountId);

  const onSubmit = async (data: IBuyerMatchSettingsForm) => {
    const buyerInfoWithoutMatches = omit(buyerInfo, ['matches']);

    const payload = {
      data: {
        ...buyerInfoWithoutMatches,
        breeds: data.breeds.map(breed => breed.value),
        budgetMax: Number(data.budgetMax),
        howSoon: data.howSoon,
      },
    };

    mutate(payload);
  };

  return (
    <>
      <div className="my_acc_form">
        <>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            id="buyerBreedsForm"
            className="about-program-container__form"
          >
            <Col xs={12} className={`${errors?.breeds ? 'mb-0 input-error' : 'mb-4'}`}>
              <Form.Label className="form-labels">Breeds you are interested in*</Form.Label>
              <Controller
                control={control}
                name="breeds"
                render={({ field: { onChange, value } }) => (
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    isSearchable
                    value={value}
                    onChange={(val: any, actionMeta: any) => {
                      if (
                        watch('breeds') &&
                        watch('breeds').length >= 5 &&
                        actionMeta.action === 'select-option'
                      ) {
                        setError('breeds', {
                          type: 'custom',
                          message: ' The maximum number of breeds you can select is 5',
                        });
                      } else if (actionMeta.action === 'select-option') {
                        trigger('breeds');

                        return onChange(val);
                      } else if (
                        actionMeta.action === 'remove-value' &&
                        watch('breeds').length <= 1
                      ) {
                        setError('breeds', {
                          type: 'custom',
                          message: 'You must select at least one breed',
                        });
                      } else if (actionMeta.action === 'remove-value') {
                        trigger('breeds');

                        return onChange(val);
                      }
                    }}
                    options={breedsDropdown}
                    placeholder="Select breeds"
                    styles={multiSelectStyles}
                  />
                )}
              />
              {errors?.breeds && <p className="text-error mb-2">{errors.breeds?.message}</p>}
            </Col>

            <div className="buyer-breeds-container profile">
              {watch('breeds')?.map((breed, index) => (
                <BreedInfo
                  key={index}
                  breedName={breed.label}
                  breedId={find(breedsWithId, { label: breed.label })?.value}
                  withSimilarBreeds={false}
                  similarBreeds={similarBreeds}
                  setSimilarBreeds={setSimilarBreeds}
                  isProfile={true}
                  removeBreed={(breedValue: string) => {
                    setValue(
                      'breeds',
                      filter(watch('breeds'), item => item.value !== breedValue),
                    );
                    trigger('breeds');
                  }}
                  addBreed={(breedValue: string) => {
                    setValue(
                      'breeds',
                      concat(watch('breeds'), { value: breedValue, label: breedValue }),
                    );
                    trigger('breeds');
                  }}
                />
              ))}
            </div>

            <Col xs={12} className={`mt-4 ${errors?.howSoon ? 'mb-0 input-error' : 'mb-4'}`}>
              <Form.Label className="form-labels">When would you like a puppy?*</Form.Label>

              <div className="how-soon-profile-container">
                <div
                  className={`how-soon-profile ${watch('howSoon') === 'immediate' && 'active'}`}
                  onClick={() => setValue('howSoon', 'immediate')}
                >
                  <p>Immediately</p>
                </div>
                <div
                  className={`how-soon-profile ${watch('howSoon') === 'short' && 'active'}`}
                  onClick={() => setValue('howSoon', 'short')}
                >
                  <p>In 1-3 months</p>
                </div>
                <div
                  className={`how-soon-profile ${watch('howSoon') === 'unknown' && 'active'}`}
                  onClick={() => setValue('howSoon', 'unknown')}
                >
                  <p>Not Sure Yet</p>
                </div>
              </div>

              {errors && errors.howSoon && (
                <p className="text-error mb-2">{errors.howSoon.message}</p>
              )}
            </Col>

            <Col xs={12} className={`mt-4 ${errors?.budgetMax ? 'mb-0 input-error' : 'mb-4'}`}>
              <Form.Label className="form-labels">Maximum Budget in USD*</Form.Label>
              <Form.Control
                bsPrefix="input form-control mb-0"
                type="number"
                placeholder="What’s your maximum budget?"
                {...register('budgetMax')}
              />
              {errors && errors.budgetMax && (
                <p className="text-error mb-2">{errors.budgetMax.message}</p>
              )}
            </Col>

            <div className="receive-matches">
              <p>I want to continue receiving matches</p>

              <ToggleSwitch
                id="switch-matching-on-off"
                name="buyers-matching"
                onChange={() => {
                  setRecieveMatches(!receiveMatches);
                  updateMatchAcceptance();
                }}
                checked={receiveMatches}
              />
            </div>
          </Form>
        </>
      </div>

      <DialogActions
        sx={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          background: 'white',
        }}
      >
        <Button onClick={onCancelPress}>Close</Button>
        <Button
          form="buyerBreedsForm"
          disabled={isEmpty(watch('breeds')) || isLoading}
          onClick={handleSubmit(onSubmit)}
          type="submit"
        >
          {isLoading ? (
            <AiOutlineLoading3Quarters
              size={20}
              color="blue"
              style={{ margin: '0.2rem' }}
              className="loading-animation svg-loading"
            />
          ) : (
            'Update'
          )}
        </Button>
      </DialogActions>
    </>
  );
};

export default MatchSettings;
