export const premiumBreederNavLinks = [
  {
    groupName: 'My Account',
    links: [
      {
        name: 'My Leads',
        path: '/profile/leads',
      },
      {
        name: 'My Litters',
        path: '/profile/litters',
      },
      {
        name: 'Image Gallery',
        path: '/profile/gallery',
      },
      {
        name: 'My Program',
        path: '/profile/program',
      },
      {
        name: 'Downloads',
        path: '/profile/materials',
      },
      {
        name: 'My Referrals',
        path: '/profile/referrals',
      },
      {
        name: 'Account Settings',
        path: '/profile/settings',
      },
    ],
  },
  {
    groupName: 'resources',
    links: [
      {
        name: 'Deals',
        path: '/deals',
      },
      {
        name: 'Learning Center',
        path: 'https://www.telltail.com/learning-center',
      },
      {
        name: 'Pro Resources',
        path: '/learn',
      },
      {
        name: 'Breedopedia',
        path: '/breedopedia',
      },
      {
        name: 'Help & FAQs',
        path: '/faq',
      },
    ],
  },
];
