import React, { useState, Fragment, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { FiInfo } from 'react-icons/fi';
import 'bootstrap/dist/css/bootstrap.min.css';

//components
import TTButton from 'components/common/TTButton';
import KitImg from 'components/common/KitImg';
import { Tooltip, TooltipTrigger, TooltipContent } from 'components/common/Tooltip';
//hooks
import { useQueryMatchesBasic } from './__hooks/useQueryMatches';
//helpers
import { formatAddressForBreederLeads } from 'helpers/string';
//assets
import defaultProfileImg from 'assets/images/breederProfile/defaultLogo.svg';
import noLeads from 'assets/images/breederProfile/noLeads.svg';
import scoreInfo from 'assets/images/breederProfile/scoreInfo.svg';

const MyLeads = ({ isSfcBreeder }: { isSfcBreeder: boolean; isInReview: boolean }) => {
  const { ref, inView } = useInView();
  const navigate = useNavigate();
  const [leadsSubtab, setLeadsSubtab] = useState<'active' | 'expired'>('active');
  const [infoOpen, setInfoOpen] = useState<{ [key: string]: boolean }>({});

  const getUserFriendlyMatchDate = (matchDate: string | undefined) => {
    if (matchDate === undefined) return 'Not sure yet';
    else {
      switch (matchDate) {
        case 'immediate':
          return 'Immediately';
        case 'short':
          return 'In 1-3 months';
        case 'unknown':
          return 'Not sure yet';

        default:
          return 'Not sure yet';
      }
    }
  };

  //Query hooks
  const { total, matchesBasic, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useQueryMatchesBasic();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  if (isLoading) {
    return (
      <div className="w-100 d-flex justify-content-center">
        <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
      </div>
    );
  } else {
    const isBasicBreederWithLeads = !isSfcBreeder && total > 0;
    const isBasicBreederWithoutLeads = !isSfcBreeder && total <= 0;

    return (
      <>
        {isBasicBreederWithLeads && leadsSubtab === 'active' && (
          <div className="unlock-matches">
            <h6>Get ready for leads.</h6>
            <p>
              Get ready for leads. Only TellTail Pro members can reach out to their buyer leads.
              We'll show you a preview of your leads when available, but upgrading now will give you
              immediate access to connect and chat with your buyers!
            </p>

            <TTButton
              type="submit"
              className="btn-primary-orchid"
              text="Upgrade"
              width="125px"
              height="40px"
              fontSize="12px"
              borderRadius="10px"
              onClick={() => navigate('/get-certified')}
            />
          </div>
        )}

        {isBasicBreederWithoutLeads && (
          <div className="unlock-matches">
            <h6>Get ready for leads.</h6>
            <p>
              Only TellTail subscribers receive qualified leads. <br />
              Subscribe now to get leads the moment they are available.
            </p>
            <img alt="no-leads" src={noLeads} className="no-leads-img" />

            <TTButton
              type="submit"
              className="btn-primary-orchid"
              text="Upgrade"
              width="125px"
              height="40px"
              fontSize="12px"
              borderRadius="10px"
              onClick={() => navigate('/get-certified')}
            />
          </div>
        )}

        {total > 0 && (
          <>
            <div
              className={`leads-subtabs ${
                isBasicBreederWithLeads &&
                leadsSubtab === 'active' &&
                'leads-subtabs--basic-with-leads'
              }`}
            >
              <p
                className={`leads-subtab ${leadsSubtab === 'active' && 'leads-subtab--active'}`}
                onClick={() => setLeadsSubtab('active')}
              >
                Active
              </p>
              <p
                className={`leads-subtab ${leadsSubtab === 'expired' && 'leads-subtab--active'}`}
                onClick={() => setLeadsSubtab('expired')}
              >
                Expired
              </p>
            </div>
            {leadsSubtab === 'expired' && (
              <div className="unlock-matches">
                <h6>Expired leads.</h6>
                <p>Please upgrade to access all your expired leads.</p>

                <TTButton
                  type="submit"
                  className="btn-primary-orchid"
                  text="Upgrade"
                  width="125px"
                  height="40px"
                  fontSize="12px"
                  borderRadius="10px"
                  onClick={() => navigate('/get-certified')}
                />
              </div>
            )}
            {leadsSubtab === 'active' && (
              <div className="cards-container">
                {map(matchesBasic?.pages, (page, index) => (
                  <Fragment key={index}>
                    {map(page?.items, match => {
                      const expireStatus = match.expirationStatus;

                      const isInWarningWindow =
                        expireStatus.includes('day') &&
                        Number(expireStatus.match(/\d/g)?.join('') || '1') <= 3;

                      return (
                        <div className="leads-card" key={match.id}>
                          <div className="leads-card__info-banner">
                            <div className="d-flex align-items-center gap-1">
                              {match.isNew && <p className="leads-card__info-new-text">NEW!</p>}
                              <p className="leads-card__subtitle">
                                Matched: {moment(match.matchDate).format('MMMM DD, YYYY')}
                              </p>
                            </div>
                            {!isSfcBreeder && (
                              <div className="d-flex align-items-center gap-1">
                                <p
                                  className={`leads-card__subtitle ${
                                    isInWarningWindow && 'leads-card__warning'
                                  }`}
                                >
                                  {expireStatus}
                                </p>
                                <Tooltip placement="top">
                                  <TooltipTrigger className="tooltip_trigger_account--leads">
                                    <FiInfo
                                      size={16}
                                      color={isInWarningWindow ? '#ed5757' : '#142669'}
                                    />
                                  </TooltipTrigger>
                                  <TooltipContent className="tooltip_account--leads">
                                    This Lead{' '}
                                    <span
                                      className={`${
                                        isInWarningWindow && 'leads-card__warning--large'
                                      }`}
                                    >
                                      {expireStatus.toLocaleLowerCase()}
                                    </span>
                                    . Please{' '}
                                    <a className="leads-card__warning--large" href="/get-certified">
                                      upgrade
                                    </a>{' '}
                                    to avoid expiration.
                                  </TooltipContent>
                                </Tooltip>
                              </div>
                            )}
                          </div>

                          {!isEmpty(match.leadGrade) && (
                            <div
                              className={`leads-card__score leads-card__score--${match.leadGrade.toLowerCase()}`}
                            >
                              <p>
                                Compatibility score:{' '}
                                <span className={match.leadGrade.toLowerCase()}>
                                  {match.leadGrade}
                                </span>{' '}
                                <img
                                  alt="score-info"
                                  src={scoreInfo}
                                  onClick={() => {
                                    if (!infoOpen[match.id]) {
                                      setInfoOpen({ ...infoOpen, [match.id]: true });
                                    }
                                    setInfoOpen({ ...infoOpen, [match.id]: !infoOpen[match.id] });
                                  }}
                                />
                              </p>
                              {match.leadGrade === 'Great' && infoOpen[match.id] && (
                                <p className="leads-card__score-description">
                                  This lead aligns exceptionally well with what you're offering,
                                  suggesting a high potential for a successful match.
                                </p>
                              )}
                              {match.leadGrade === 'Good' && infoOpen[match.id] && (
                                <p className="leads-card__score-description">
                                  This lead has a decent alignment with your requirements,
                                  indicating a good chance for compatibility.
                                </p>
                              )}
                              {match.leadGrade === 'Ok' && infoOpen[match.id] && (
                                <p className="leads-card__score-description">
                                  This lead shows some alignment with your offerings, but may
                                  require more consideration to determine fit.
                                </p>
                              )}
                            </div>
                          )}

                          <div className="leads-card__body">
                            <div className="leads-card__heading">
                              <div className="leads-card__heading-left">
                                <KitImg
                                  alt="buyer-profile-img"
                                  src={match.buyerProfileImage || defaultProfileImg}
                                  kitwidth={40}
                                  imgClassName="leads-card__img"
                                />
                                <div className="d-flex flex-column align-items-start">
                                  <p className="leads-card__title">{match.buyerName}</p>
                                  <p className="leads-card__subtitle leads-card__subtitle--navy-50">
                                    {formatAddressForBreederLeads(
                                      match.city,
                                      match.state,
                                      match.country,
                                      match.zipCode,
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="leads-card__content">
                              <div className="leads-card__field">
                                <div className="d-flex flex-column w-100">
                                  <p className="leads-card__title">
                                    {match.desiredBreeds.length > 1
                                      ? 'Desired breeds'
                                      : 'Desired breed'}
                                  </p>
                                  <p className="leads-card__description">
                                    {match.desiredBreeds.length > 1
                                      ? map(match.desiredBreeds, (desiredBreed, key2) => {
                                          return Number(key2) === match.desiredBreeds.length - 1
                                            ? desiredBreed
                                            : `${desiredBreed}, `;
                                        })
                                      : match.breedName}
                                  </p>
                                </div>
                              </div>
                              <div className="leads-card__field">
                                <div className="d-flex flex-column">
                                  <p className="leads-card__title">Desired Go Home</p>
                                  <p className="leads-card__description">
                                    {getUserFriendlyMatchDate(match.howSoon)}
                                  </p>
                                </div>
                              </div>
                              <div className="leads-card__field">
                                <div className="d-flex flex-column">
                                  <p className="leads-card__title">Email</p>
                                  <p className="leads-card__description">{match.email}</p>
                                </div>
                              </div>
                              {/* We want to show "Unverified" status for the users before sms verification feature was enabled. */}
                              {/* We also only want to show phone field only for users that verified their phone while feature was enabled. */}
                              {(!match.isSmsVerificationEnabled ||
                                (match.isSmsVerificationEnabled && match.phoneVerified)) && (
                                <div className="leads-card__field leads-card__field--last">
                                  <div className="d-flex flex-column">
                                    <p className="leads-card__title">
                                      Phone{' '}
                                      {!match.phoneVerified && (
                                        <span className="leads-card__verification-status">
                                          Unverified
                                        </span>
                                      )}
                                      {match.phoneVerified && (
                                        <span className="leads-card__verification-status leads-card__verification-status--verified">
                                          Verified
                                        </span>
                                      )}
                                    </p>{' '}
                                    <p className="leads-card__description">'(xxx) xxx-xxxx'</p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Fragment>
                ))}
                <div className="d-flex justify-content-center w-100">
                  <button
                    ref={ref}
                    onClick={() => fetchNextPage()}
                    disabled={!hasNextPage || isFetchingNextPage}
                    className="btn-loading"
                  >
                    {isFetchingNextPage
                      ? 'Loading more matches...'
                      : hasNextPage
                      ? 'Load Newer'
                      : ''}
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }
};
export default MyLeads;
