import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import parse from 'html-react-parser';

//types
import { IArticle } from 'types';
//components
import InfoView from 'components/common/InfoView';
import KitImg from 'components/common/KitImg';
import Heading from 'pages/AdminPanel/Heading';
//assets
import avatar from 'assets/images/breederProfile/avatar.png';

const ArticleTags = ({ articleTags }: { articleTags: string[] }) => {
  if (isEmpty(articleTags)) return <p>No tag found.</p>;

  return (
    <Box display="flex" flexWrap="wrap">
      {articleTags?.map((tag, key) => (
        <Box key={`${tag}${key}`} bgcolor="darkblue" borderRadius={5} px={2} m={0.5}>
          <Typography variant="subtitle1" color="white">
            {tag}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const AdminArticleDetails = ({ article }: { article: IArticle }) => {
  if (!article) return <></>;

  return (
    <Box display="flex" flexDirection="column">
      <Heading text="Article Details" bgcolor="darkblue" />

      <Box display="flex">
        <Box flex={1}>
          <InfoView label="Article Id:" value={article._id} />
          <InfoView label="Article Title:" value={article.title} />
          <InfoView
            label="Article Image:"
            value={
              <Card sx={{ width: 300 }} elevation={3}>
                <KitImg src={article.imageUrl} kitwidth={300} />
              </Card>
            }
          />
        </Box>

        <Box width="1px" bgcolor="black" mt={1} />

        <Box display="flex" flexDirection="column" flex={1}>
          <InfoView
            label="Creator Image:"
            value={
              <Card
                sx={{ width: 110, height: 110, borderRadius: 55, border: '1px solid black' }}
                elevation={3}
              >
                <KitImg
                  src={article.createdBy?.profileImage || avatar}
                  kitwidth={110}
                  style={{ width: '100%', height: '100%' }}
                />
              </Card>
            }
          />

          <InfoView
            label="Author Name:"
            value={
              <>
                {article.authorName ? (
                  <span>
                    {article.authorName}{' '}
                    {!article.createdBy ? (
                      <span className="text-error">(External Author)</span>
                    ) : (
                      ''
                    )}
                  </span>
                ) : (
                  <span>{article.createdBy?.name}`</span>
                )}
              </>
            }
          />
          <InfoView
            label="Article Tags:"
            value={<ArticleTags articleTags={article.articleTags} />}
          />
        </Box>
      </Box>

      <Box height="1px" bgcolor="black" my={1} mx={3} />

      <InfoView
        label="Article Content:"
        value={
          <Box overflow="hidden">
            {parse(
              ((article?.content || '') + '<br />' + (article?.source || ''))
                .replace(/href/g, "target='_blank' href")
                .replaceAll('/tr:w-300', '/tr:w-2000'),
            )}
          </Box>
        }
        vertical
      />
    </Box>
  );
};

export default AdminArticleDetails;
