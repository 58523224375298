/* eslint-disable @typescript-eslint/no-explicit-any */
import { useInfiniteQuery } from 'react-query';
import { without } from 'lodash';

//services
import { getBreeds } from 'services/api/apiCalls/breeds';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

function useQueryBreedsList(
  filters: string[],
  selectedBreed: { label: string; value: string } | null,
) {
  const filtersWithoutParentFilters = without(
    filters,
    'business',
    'homes',
    'health',
    'because',
    'pregnancy',
    'care',
  );

  const {
    status,
    data,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    refetch,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
  } = useInfiniteQuery(
    [QUERY_KEYS.LIST_OF_BREEDS, filters, selectedBreed],
    async ({ pageParam = 1 }) =>
      getBreeds(
        selectedBreed
          ? {
              page: pageParam,
              limit: 12,
              filter: filtersWithoutParentFilters.join(','),
              id: selectedBreed.value,
            }
          : {
              page: pageParam,
              limit: 12,
              filter: filtersWithoutParentFilters.join(','),
            },
      ),
    {
      getNextPageParam: lastPage => {
        if (lastPage?.meta?.currentPage >= lastPage?.meta?.totalPages) {
          return undefined;
        } else return lastPage?.meta?.currentPage + 1;
      },
    },
  );

  return {
    status,
    data: data,
    isFetching,
    refetch,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
  };
}

export default useQueryBreedsList;
