import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { createPawfficeHour, editPawfficeHour } from 'services/api/apiCalls/admin';
//types
import { ICreateEditPawfficeHourPayload, TError } from 'types';

export const useMutationCreateNewPawfficeHour = (
  handleDialogDismiss: () => void,
  scrollToBottom: () => void,
) => {
  const { addToast } = useToasts();

  const {
    mutate: createNewPawfficeHourMutate,
    isLoading: isCreatingNewPawfficeHour,
    error: createNewPawfficeHourError,
    reset: creatNewPawfficeHourResetApiState,
  } = useMutation<AxiosResponse<string>, AxiosError<TError>, ICreateEditPawfficeHourPayload>(
    data => createPawfficeHour(data),
    {
      onSuccess: () => {
        addToast('Pawffice hour created successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
        handleDialogDismiss();
      },
      onError: () => {
        scrollToBottom();
      },
    },
  );

  return {
    createNewPawfficeHourMutate,
    isCreatingNewPawfficeHour,
    createNewPawfficeHourError,
    creatNewPawfficeHourResetApiState,
  };
};

export const useMutationEditPawfficeHour = (
  handleDialogDismiss: () => void,
  scrollToBottom: () => void,
) => {
  const { addToast } = useToasts();

  const {
    mutate: editPawfficeHourMutate,
    isLoading: isEditingPawfficeHour,
    error: editPawfficeHourError,
    reset: editPawfficeHourResetApiState,
  } = useMutation<AxiosResponse<string>, AxiosError<TError>, ICreateEditPawfficeHourPayload>(
    data => editPawfficeHour(data._id!, data),
    {
      onSuccess: () => {
        addToast('Pawffice hour updated successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
        handleDialogDismiss();
      },
      onError: () => {
        scrollToBottom();
      },
    },
  );

  return {
    editPawfficeHourMutate,
    isEditingPawfficeHour,
    editPawfficeHourError,
    editPawfficeHourResetApiState,
  };
};
