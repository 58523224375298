/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import { includes } from 'lodash';

//services
import { createConversation } from 'services/api/apiCalls/community';
//types
import { INewConversationPayload, TError } from 'types';
//helpers
import eventSink from 'helpers/eventSink';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useMutationNewConversation = (resetForm: () => void) => {
  const { addToast } = useToasts();

  const queryClient = useQueryClient();
  const userInfo = queryClient.getQueryData(QUERY_KEYS.USER_STATUS);
  // @ts-ignore
  const breederInfo = queryClient.getQueryData([QUERY_KEYS.BREEDER, userInfo.data.breederId]);

  return useMutation<AxiosResponse<string>, AxiosError<TError>, INewConversationPayload>(
    data => createConversation(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.ACTIVITY_FEED_LIST, {
          refetchInactive: true,
        });

        addToast('Your post was created successfully.', {
          appearance: 'success',
          autoDismiss: true,
        });
        resetForm();
        window.scrollTo({ top: 0 });

        eventSink(
          {
            // @ts-ignore
            userId: breederInfo?.data?.userId,
            // @ts-ignore
            ttUser: includes(breederInfo?.data?.email, 'telltail'),
            time: moment().toISOString(),
            type: 'create',
            subtype: 'conversation',
          },
          { title: 'create conversation', url: location.pathname },
        );
      },
    },
  );
};

export default useMutationNewConversation;
