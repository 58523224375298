import React from 'react';
import { Row } from 'react-bootstrap';
import { isEmpty, isUndefined } from 'lodash';

// types
import { IBanner } from 'types';
//styles
import './index.scss';

const Banner = ({
  bgColor = 'green',
  title,
  subTitle,
  tags,
  children,
  titleClassName,
}: IBanner) => {
  return (
    <Row className={`banner ${bgColor}`}>
      {/* Title and subtitle */}
      <h3 className={`banner__title ${titleClassName}`}>{title}</h3>
      <p>{subTitle}</p>

      {/* Tags section */}
      {!isUndefined(tags) && !isEmpty(tags) && (
        <ul className="banner__tags">
          {tags.map((tag, idx) => (
            <li key={`${tag}${idx}`}>{tag}</li>
          ))}
        </ul>
      )}
      {children}
    </Row>
  );
};

export default Banner;
