import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { deleteBreedEncyclopaedia } from 'services/api/apiCalls/admin';
//types
import { TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useMutationDeleteBreedEncyclopaedia = (
  setDialogContent: (dialogContent: null) => void,
) => {
  const queryClient = useQueryClient();

  const { mutate: deleteBreedEncyclopaediaMutate, isLoading: isDeleting } = useMutation<
    AxiosResponse<string>,
    AxiosError<TError>,
    string
  >(breedId => deleteBreedEncyclopaedia(breedId), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.ADMIN_GET_BREEDS, {
        refetchInactive: true,
      });
      setDialogContent(null);
    },
  });

  return {
    deleteBreedEncyclopaediaMutate,
    isDeleting,
  };
};
