export const youngestAgeSendToPetParents = [
  '3 weeks of age or less',
  '4 - 5 weeks old',
  '6 - 7 weeks old',
  '8 - 9 weeks old',
  '10 - 11 weeks old',
  '12 - 13 weeks old',
  '14 - 15 weeks old',
  '16 or more weeks old',
].map(ageRange => ({ label: ageRange, value: ageRange }));
