import React from 'react';
import { Box, Typography } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

//hooks
import { useQueryGetBuyerDetails } from './__hooks/useQueryGetBuyerDetails';
//components
import InfoView from 'components/common/InfoView';
import CheckBox from 'components/common/CheckBox';
//assets
import avatar from 'assets/images/breederProfile/avatar.png';

const SubHeading = ({ text }: { text: string }) => {
  return (
    <Box bgcolor="pink" py={1} pl={2}>
      <Typography variant="subtitle1" color="#333" fontWeight="bold">
        {text}
      </Typography>
    </Box>
  );
};

const BuyerDetails = ({ buyerAccountId }: { buyerAccountId: string }) => {
  //Query hooks
  const { errorBuyer, buyerInfo, isLoadingBuyer, isFetchingBuyer } = useQueryGetBuyerDetails(
    buyerAccountId!,
  );

  if (isLoadingBuyer || isFetchingBuyer) {
    return (
      <div className="profileInfo__container">
        <Row>
          <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
        </Row>
      </div>
    );
  } else if (errorBuyer || !buyerInfo) {
    return null;
  }

  return (
    <Box display="flex" maxWidth="75vw">
      {/* Left Side View */}
      <Box flex={1} flexDirection="column" maxWidth="50%">
        <SubHeading text="Buyer Information" />
        <InfoView label="First Name:" value={buyerInfo.firstName || 'N/A'} />
        <InfoView label="Last Name:" value={buyerInfo.lastName || 'N/A'} />
        <InfoView label="Email:" value={buyerInfo.email || 'N/A'} />
        <InfoView label="Phone:" value={buyerInfo.phone || 'N/A'} />
        <InfoView label="State:" value={buyerInfo.state || 'N/A'} />
        <InfoView label="Zip Code:" value={buyerInfo.zipCode || 'N/A'} />
        <InfoView label="Min Budget:" value={'$ ' + buyerInfo.budgetMin || '0'} />
        <InfoView label="Max Budget:" value={'$ ' + buyerInfo.budgetMax || '0'} />
        <InfoView
          label="Go Home Date:"
          value={moment(buyerInfo.goHomeDate).format('LLL') || 'N/A'}
        />
        <InfoView label="Notes:" value={buyerInfo.notes || 'N/A'} />
        <InfoView
          label="How did you hear about us:"
          value={buyerInfo.howDidYouHearAboutUs || 'N/A'}
        />
        <InfoView label="Another Organisation:" value={buyerInfo.anotherOrganisation || 'N/A'} />
        <InfoView label="Another Option:" value={buyerInfo.anotherOption || 'N/A'} />
      </Box>

      <Box sx={{ width: 20 }} />

      {/* Right Side View */}
      <Box flex={1} flexDirection="column" maxWidth="50%">
        <SubHeading text="Buyer Information" />
        <InfoView
          label="Important Factors:"
          value={
            <Row className="my-2">
              {buyerInfo.importantFactors?.map((factor, idx) => (
                <Col key={factor.factor + idx} xs={12} md={6} className="registration_check">
                  <CheckBox
                    text={factor.factor}
                    id={factor.factor}
                    name={factor.factor}
                    checked={factor.isFlexible}
                  />
                </Col>
              ))}
            </Row>
          }
          vertical
        />

        <InfoView
          label="Breeds:"
          value={
            <Col className="my-2">
              <Row style={{ display: 'flex', padding: 8, backgroundColor: '#87CEEB' }}>
                <strong style={{ flex: 1, textAlign: 'center' }}>Breed Name</strong>
                <strong style={{ flex: 1, textAlign: 'center' }}>Min Weight</strong>
                <strong style={{ flex: 1, textAlign: 'center' }}>Max Weight</strong>
              </Row>
              {buyerInfo.breeds?.map((breed, idx) => (
                <Row key={breed.breedName + idx} style={{ display: 'flex', padding: 8 }}>
                  <span style={{ flex: 1, textAlign: 'center' }}>{breed.breedName}</span>
                  <span style={{ flex: 1, textAlign: 'center' }}>{breed.minWeight}</span>
                  <span style={{ flex: 1, textAlign: 'center' }}>{breed.maxWeight}</span>
                </Row>
              ))}
            </Col>
          }
          vertical
        />

        <InfoView
          label="Matches:"
          value={
            <Col className="my-2">
              <Row style={{ display: 'flex', padding: 8, backgroundColor: '#87CEEB' }}>
                <strong style={{ flex: 1, textAlign: 'center' }}>Breeder Name</strong>
                <strong style={{ flex: 1, textAlign: 'center' }}>Breeder Program</strong>
                <strong style={{ flex: 1, textAlign: 'center' }}>Match Date</strong>
              </Row>
              {buyerInfo.matches?.map((match, idx) => (
                <Row
                  key={match.breederName + idx}
                  style={{ display: 'flex', padding: 8, alignItems: 'center' }}
                >
                  <div style={{ flex: 1 }}>
                    <img
                      src={match.breederProfileImage || avatar}
                      alt={`breeder-img-${idx}`}
                      style={{ width: 60, height: 60, borderRadius: 30, marginRight: 8 }}
                    />
                    <span style={{ textAlign: 'center' }}>{match.breederName}</span>
                  </div>
                  <span style={{ flex: 1, textAlign: 'center' }}>{match.breederProgramName}</span>
                  <span style={{ flex: 1, textAlign: 'center' }}>
                    {moment(match.matchDate).format('LLL')}
                  </span>
                </Row>
              ))}
            </Col>
          }
          vertical
        />
      </Box>
    </Box>
  );
};

export default BuyerDetails;
