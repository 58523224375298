import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { getPawfficeHour } from 'services/api/apiCalls/admin';
//types
import { TError, IPawfficeHour } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useQueryGetPawfficeHour = (pawfficeId: string) => {
  const { isLoading, error, data, refetch } = useQuery<
    AxiosResponse<IPawfficeHour>,
    AxiosError<TError>
  >([QUERY_KEYS.PAWFFICE_HOUR, pawfficeId], () => getPawfficeHour(pawfficeId));

  return { isLoading, error, pawfficeHour: data?.data, refetchPawfficeHour: refetch };
};

export default useQueryGetPawfficeHour;
