/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useContext, useRef, useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty, find, includes } from 'lodash';
import { Autocomplete } from '@react-google-maps/api';
// @ts-ignore
import IntlTelInput from 'intl-tel-input/react/build/IntlTelInput.esm';
import 'intl-tel-input/build/css/intlTelInput.css';

//types
import { TStepper, INewCreateAccountForm, TAuthContext, TGoogleMapAddress } from 'types';
//validation
import { createAccountSchema } from './Validation';
//components
import CheckBox from 'components/common/CheckBox';
import TTButton from 'components/common/TTButton';
//constants
import { numberValidationErrors } from 'constants/lists/numberValidationErrors';
//services
import { AuthState } from 'services/auth';
import { useDataLayer } from 'services/dataLayerContext';
//hooks
import useMutationRegisterBuyer from '../__hooks/useMutationRegisterBuyer';
//helpers
import {
  hasUppercaseAndLowercaseLetter,
  hasOneNumberOrDigit,
  hasSpecialCharacter,
} from 'helpers/regex';
import { getFullAddress } from 'helpers/googleMaps';
//styles
import '../index.scss';

export default function CreateAccount({ setStep, step }: TStepper) {
  const pushToDataLayer = useDataLayer();
  const placesInputRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<google.maps.places.Autocomplete>();
  const selectedAddress = useRef('');
  const [errorCode, setErrorCode] = useState(0);
  const {
    setEmail,
    setBuyerRegistrationInfo,
    state: { buyerRegistrationInfo },
  } = useContext(AuthState) as TAuthContext;
  const [parsedAddress, setParsedAddress] = useState<TGoogleMapAddress | null>(
    buyerRegistrationInfo.createAccountForm?.parsedAddress || null,
  );
  const [isProperAddress, setIsProperAddress] = useState(
    buyerRegistrationInfo.createAccountForm?.isProperAddress || false,
  );

  const {
    register,
    handleSubmit,
    setError,
    setFocus,
    setValue,
    trigger,
    watch,
    control,
    clearErrors,
    formState: { errors },
  } = useForm<INewCreateAccountForm>({
    resolver: yupResolver(createAccountSchema(isProperAddress)),
    defaultValues: {
      ...buyerRegistrationInfo.createAccountForm,
    },
  });

  // Mutation hooks
  const { mutate, isLoading } = useMutationRegisterBuyer({
    step,
    setStep,
    setError,
  });

  const watchAddress = watch('fullAddress');

  useEffect(() => {
    if (!isEmpty(errors)) {
      const firstError = Object.keys(errors).reduce((field: string | null, a: string | null) => {
        // @ts-ignore
        return !isEmpty(errors[field]) ? field : a;
      }, null);

      if (firstError && firstError !== 'acceptedTermsOfService' && firstError !== 'phoneNumber') {
        // @ts-ignore
        firstError === 'fullAddress' ? setFocus('emailAddress') : setFocus(firstError);
      }
    }
  }, [errors, setFocus]);

  useEffect(() => {
    if (!isEmpty(watchAddress)) trigger('fullAddress');
  }, [isProperAddress, trigger, watchAddress, watch]);

  const handlePlaceChanged = () => {
    if (!inputRef.current) return;

    const place = inputRef.current.getPlace();

    if (!place) return;

    // Address field which contains street_number and route considers as full and accepted address
    const streetNumber = find(place?.address_components, val =>
      includes(val.types, 'street_number'),
    )?.long_name;
    const route = find(place?.address_components, val => includes(val.types, 'route'))?.long_name;
    setIsProperAddress(!!streetNumber && !!route);

    selectedAddress.current = placesInputRef.current?.value || '';
    const tempParsedAddress = {
      streetAddress: `${streetNumber} ${route}`,
      city: find(place?.address_components, val => includes(val.types, 'locality'))?.long_name,
      state: find(place?.address_components, val =>
        includes(val.types, 'administrative_area_level_1'),
      )?.short_name,
      postalCode: find(place?.address_components, val => includes(val.types, 'postal_code'))
        ?.long_name,
      country: find(place?.address_components, val => includes(val.types, 'country'))?.long_name,
      lat: place?.geometry?.location?.lat() || 0,
      lng: place?.geometry?.location?.lng() || 0,
    };
    setParsedAddress(tempParsedAddress);

    setValue('fullAddress', getFullAddress(tempParsedAddress) || '');
  };

  const setIsValid = (isValid: boolean) => {
    if (isValid) {
      clearErrors('phoneNumber');
    } else {
      const errorMessage = numberValidationErrors[errorCode] || 'Invalid number';

      setError('phoneNumber', {
        type: 'custom',
        message: errorMessage,
      });
    }
  };

  const onSubmit = (data: INewCreateAccountForm) => {
    if (selectedAddress.current.toLowerCase() !== placesInputRef.current?.value.toLowerCase()) {
      return setError('fullAddress', {
        type: 'custom',
        message: 'Please select a location from the provided dropdown suggestions.',
      });
    }

    const object = { ...data, parsedAddress, isProperAddress };

    pushToDataLayer({
      event: 'buyerCreateAccountClick',
      componentName: 'Create buyer account',
      ...object,
    });
    setEmail(data.emailAddress);
    setBuyerRegistrationInfo('createAccountForm', data);

    const payload = {
      ...data,
      phone: data.phoneNumber,
      email: data.emailAddress,
      howSoon: buyerRegistrationInfo.howSoon as 'immediate' | 'short' | 'unknown',
      breeds: buyerRegistrationInfo.breeds as string[],
      budgetMax: buyerRegistrationInfo.budget || 0,
      fullAddress: data.fullAddress,
      streetAddress: parsedAddress?.streetAddress || '',
      city: parsedAddress?.city || '',
      state: parsedAddress?.state || '',
      zipCode: parsedAddress?.postalCode || '',
      country: parsedAddress?.country || '',
      geoLong: parsedAddress?.lng.toString() as string,
      geoLat: parsedAddress?.lat.toString() as string,
    };

    mutate(payload);
  };

  return (
    <div className="about-program-container">
      <h2>Let’s create an account</h2>
      <span className="about-program-container__info-text">
        By completing your TellTail application, we will share your contact information with
        Certified Breeders.
      </span>
      <div className="sub-heading-copy"></div>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="about-program-container__form"
        id="buyerRegistrationCreateAccountForm"
      >
        <Row className="fill-available">
          <Col xs={12} md={6} className={`${errors.firstName ? 'mb-0 input-error' : 'mb-4'} `}>
            <Form.Label className="form-labels">First Name*</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0"
              type="text"
              placeholder="Enter first name"
              {...register('firstName')}
            />
            {errors && errors.firstName && (
              <p className="text-error mb-2">{errors.firstName.message}</p>
            )}
          </Col>

          <Col xs={12} md={6} className={`${errors.lastName ? 'mb-0 input-error' : 'mb-4'} `}>
            <Form.Label className="form-labels">Last Name*</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0"
              type="text"
              placeholder="Enter last name"
              {...register('lastName')}
            />
            {errors && errors.lastName && (
              <p className="text-error mb-2">{errors.lastName.message}</p>
            )}
          </Col>
        </Row>

        <Row className="fill-available">
          <Col xs={12} md={6} className={`${errors.emailAddress ? 'mb-0 input-error' : 'mb-4'} `}>
            <Form.Label className="form-labels">Email Address*</Form.Label>
            <Form.Control
              bsPrefix="form-control input mb-0"
              type="email"
              placeholder="Enter Email Address"
              {...register('emailAddress')}
            />
            {errors && errors.emailAddress && (
              <p className="text-error mb-2">{errors.emailAddress.message}</p>
            )}
          </Col>

          <Col xs={12} md={6} className={`${errors.phoneNumber ? 'mb-0 input-error' : 'mb-4'} `}>
            <Form.Label className="form-labels">Phone Number</Form.Label>
            <br />

            <Controller
              control={control}
              name={'phoneNumber'}
              render={({ field: { onChange, value } }) => (
                <IntlTelInput
                  initialValue={value}
                  onChangeNumber={onChange}
                  onChangeValidity={setIsValid}
                  onChangeErrorCode={setErrorCode}
                  initOptions={{
                    initialCountry: 'us',
                    utilsScript:
                      'https://cdn.jsdelivr.net/npm/intl-tel-input@19.2.19/build/js/utils.js',
                  }}
                />
              )}
            />
            {errors && errors.phoneNumber && (
              <p className="text-error mb-2 text-error-number-verify">
                {errors.phoneNumber.message}
              </p>
            )}
          </Col>

          <Autocomplete
            onLoad={ref => (inputRef.current = ref)}
            types={['address']}
            onPlaceChanged={handlePlaceChanged}
          >
            <Col xs={12} className={`${errors.fullAddress ? 'mb-0 input-error' : 'mb-4'}`}>
              <Form.Label className="form-labels">Address*</Form.Label>
              <Col className="d-flex align-items-center gap-2">
                <input
                  ref={placesInputRef}
                  className="input form-control mb-0 w-100"
                  type="text"
                  placeholder="Type here to see address suggestions"
                  defaultValue={watch('fullAddress')}
                />
                {!isEmpty(watch('fullAddress')) && (
                  <p
                    className="m-0 info w-auto"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (placesInputRef.current) placesInputRef.current.value = '';

                      setValue('fullAddress', '');
                      trigger('fullAddress');
                    }}
                  >
                    Clear
                  </p>
                )}
              </Col>
              {errors && errors.fullAddress && (
                <p className="text-error mb-2">{errors.fullAddress.message}</p>
              )}
            </Col>
          </Autocomplete>
        </Row>

        <Row className="fill-available">
          <Col xs={12} md={6} className={`${errors.password ? 'mb-0 input-error' : 'mb-4'} `}>
            <Form.Label className="form-labels">Password*</Form.Label>
            <Form.Control
              data-private
              bsPrefix="form-control input mb-0"
              type="password"
              placeholder="New password"
              {...register('password')}
            />
            {errors && errors.password && (
              <p className="text-error mb-2">{errors.password.message}</p>
            )}
          </Col>

          <Col
            xs={12}
            md={6}
            className={`${errors.confirmPassword ? 'mb-0 input-error' : 'mb-4'} `}
          >
            <Form.Label className="form-labels">Confirm Password*</Form.Label>
            <Form.Control
              data-private
              bsPrefix="form-control input mb-0"
              type="password"
              placeholder="Confirm New password"
              {...register('confirmPassword')}
            />
            {errors && errors.confirmPassword && (
              <p className="text-error mb-2">{errors.confirmPassword.message}</p>
            )}
          </Col>

          <Col xs={12} md={6} className="password-validation-notes mb-4">
            <li
              className={`password-note ${
                hasUppercaseAndLowercaseLetter(watch('password')) && 'password-note--match'
              }`}
            >
              At least one upper case and one lower case letter
            </li>
            <li
              className={`password-note ${
                hasOneNumberOrDigit(watch('password')) && 'password-note--match'
              }`}
            >
              At least one number/digit (between 0 and 9)
            </li>
            <li
              className={`password-note ${
                hasSpecialCharacter(watch('password')) && 'password-note--match'
              }`}
            >
              At least one special character (e.g. !, #, $, &, etc.)
            </li>
            <li
              className={`password-note ${watch('password')?.length > 7 && 'password-note--match'}`}
            >
              Be at least 8 characters in length
            </li>
          </Col>
        </Row>

        <Col xs={12} className={`${errors.acceptedTermsOfService ? 'mb-0 input-error' : 'mb-4'}`}>
          <CheckBox
            text={
              <div className="terms-and-privacy-text">
                I agree to the{' '}
                <a
                  target="_blank"
                  href="https://www.telltail.com/terms"
                  className="terms-and-privacy-text__link"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>
                .
                <br />
                For details on collection and use of your information, see our{' '}
                <a
                  target="_blank"
                  href="https://www.telltail.com/privacy"
                  className="terms-and-privacy-text__link"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </div>
            }
            id="acceptedTermsOfService"
            checkboxClassName="terms-and-privacy-checkbox"
            onChange={e => {
              setValue('acceptedTermsOfService', e.target.checked);
              trigger('acceptedTermsOfService');
            }}
          />

          {errors && errors.acceptedTermsOfService && (
            <p className="text-error mb-2">{errors.acceptedTermsOfService.message}</p>
          )}
        </Col>

        <div className="form-button-wrapper">
          <TTButton
            height="50px"
            type="submit"
            className="btn-primary-orchid"
            text="Continue"
            disabled={isLoading}
          />
        </div>
      </Form>
    </div>
  );
}
