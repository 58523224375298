import React, { useContext, useCallback } from 'react';
import { Row, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';

//services
import { AuthState } from 'services/auth';
//hooks
import useMutationEditConversation from '../__hooks/useMutationEditConversation';
//types
import { INewConversationSchema, IEditDiscussion, TAuthContext } from 'types';
//components
import TTButton from 'components/common/TTButton';
import KitImg from 'components/common/KitImg';
import CheckBox from 'components/common/CheckBox';
import RichTextEditor from 'components/common/SunTextEditor';
//styles
import './index.scss';
import { multiSelectStyles } from 'components/styled/select';
//assets
import Avatar from 'assets/images/breederProfile/avatar.png';
//validation
import { NewConversationSchema } from './Validation';

export default function EditConversation({
  conversation,
  isFormOpened,
  setIsFormOpened,
  knowledgeTagsOptions,
}: IEditDiscussion) {
  const TAG_LIMIT = 4;
  const {
    state: { basicUserInfo },
  } = useContext(AuthState) as TAuthContext;
  const [paddingBottom, setPaddingBottom] = React.useState<boolean>(false);

  //Mutation functions
  const resetForm = () => {
    reset();
    setValue('title', '');
    setIsFormOpened(false);
    resetApiState();
  };
  //Mutation hooks
  const {
    editConversation,
    isLoadingEditConversation,
    isError,
    error,
    reset: resetApiState,
  } = useMutationEditConversation(setIsFormOpened);

  const {
    register,
    watch,
    handleSubmit,
    setError,
    control,
    trigger,
    setValue,
    reset,
    formState: { errors },
  } = useForm<INewConversationSchema>({
    resolver: yupResolver(NewConversationSchema),
    defaultValues: {
      title: conversation.title,
      contents: conversation.contents,
      isEmailNotification: conversation.isEmailNotification,
      tags:
        conversation &&
        conversation.tags.map(tag => ({
          label: tag,
          value: tag,
        })),
    },
  });

  const handleEditorStateChange = useCallback(
    (editorState: string) => {
      setValue('contents', editorState, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [setValue],
  );

  const onSubmit = (data: INewConversationSchema) => {
    const tags = data.tags.map(tag => tag.value);

    editConversation({
      data: {
        ...data,
        tags,
        groupId: '6331d2ed07bbdaaaaaaaa003',
        isHidden: false,
        isPinned: false,
        images: [],
      },
      conversationId: conversation._id,
    });
  };

  if (!isFormOpened)
    return (
      <div className="newPost w-100">
        <div className="newPost__container">
          <div className="newPost__heading w-100">
            <KitImg src={basicUserInfo.profilePictureUrl || Avatar} kitwidth={100} />

            <Form.Group controlId="formBasicEmail" className="w-100">
              <Form.Control
                bsPrefix="newPost__input"
                type="text"
                placeholder="Start a conversation..."
                maxLength={140}
                onClick={() => setIsFormOpened(true)}
              />
            </Form.Group>
          </div>
        </div>
      </div>
    );

  return (
    <div className={`newPost no-margin-bottom ${paddingBottom && 'padding-bottom'}`}>
      <div className="newPost__container">
        <Form onSubmit={handleSubmit(onSubmit)} className="w-100">
          <div className="newPost__heading w-100">
            <KitImg src={basicUserInfo.profilePictureUrl || Avatar} kitwidth={100} />

            <Form.Group controlId="formBasicEmail" className="w-100">
              <Form.Control
                bsPrefix={`newPost__input ${errors.title && 'input-error'}`}
                type="text"
                placeholder="Start a conversation..."
                maxLength={140}
                {...register('title')}
              />
              {errors && errors.title && (
                <span className="text-error mt-2">{errors.title.message}</span>
              )}
            </Form.Group>
          </div>

          {isFormOpened && (
            <>
              <Form.Group className="mb-3 mt-5" controlId="formBasicTags">
                <div className="newPost__richText">
                  <RichTextEditor
                    placeholder="Add details and photos/video"
                    isError={!!errors.contents?.message}
                    awsFolderName={process.env.REACT_APP_AWS_OTHERS_FOLDER_PATH!}
                    fileNamePrefix="conversation"
                    onEditorStateChange={handleEditorStateChange}
                    showMediaUploadButtonAtBottom
                    knowledgeTagsOptions={knowledgeTagsOptions}
                    editorState={watch('contents')}
                  />
                </div>
                {errors && errors.contents && (
                  <span className="text-error">{errors.contents.message}</span>
                )}
              </Form.Group>

              <Form.Group className="mb-3 w-100" controlId="formBasicTags">
                <Form.Label bsPrefix="newPost__label">
                  Add tags to help others find your post!
                </Form.Label>

                <Controller
                  control={control}
                  name="tags"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      isSearchable
                      value={value}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onChange={(val: any, actionMeta: any) => {
                        if (
                          watch('tags') &&
                          watch('tags').length >= TAG_LIMIT &&
                          actionMeta.action === 'select-option'
                        ) {
                          setError('tags', {
                            type: 'custom',
                            message: ' The maximum number of tags you can select here is 4',
                          });
                        } else if (actionMeta.action === 'select-option') {
                          trigger('tags');

                          return onChange(val);
                        } else if (
                          actionMeta.action === 'remove-value' &&
                          watch('tags').length <= 1
                        ) {
                          setError('tags', {
                            type: 'custom',
                            message: 'You must have at least one tag',
                          });
                        } else if (actionMeta.action === 'remove-value') {
                          trigger('tags');

                          return onChange(val);
                        }
                      }}
                      onMenuOpen={() => setPaddingBottom(true)}
                      onMenuClose={() => setPaddingBottom(false)}
                      options={knowledgeTagsOptions}
                      placeholder="Enter tags here (E.g. IT/Software, Pet parent communications, etc.)"
                      styles={multiSelectStyles}
                      classNamePrefix="tt-select"
                    />
                  )}
                />

                {errors && errors.tags && (
                  <span className="text-error mt-2">{errors.tags.message}</span>
                )}
              </Form.Group>

              {isError && (
                <span className="text-error">{error?.response?.data?.ExceptionMessage}</span>
              )}

              <Row bsPrefix="newPost__bottomRow mt-4 w-100">
                <CheckBox
                  text="Email me with replies (follow post)"
                  checked={
                    watch('isEmailNotification') === undefined ? true : watch('isEmailNotification')
                  }
                  onChange={e => setValue('isEmailNotification', e.target.checked)}
                />
                <div className="newPost__buttonsBottom">
                  <TTButton text="Cancel" width={80} height={32} onClick={resetForm} />
                  <TTButton
                    className="btn-secondary-navy"
                    text="Update"
                    width={80}
                    height={32}
                    loading={isLoadingEditConversation}
                    disabled={isLoadingEditConversation}
                    onClick={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </>
          )}
        </Form>
      </div>
    </div>
  );
}
