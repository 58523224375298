import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { addNewAttendeeToPawfficeHour } from 'services/api/apiCalls/admin';
//types
import { IAddDeleteAttendeeSchema } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useMutationAddNewAttendee = (
  handleDialogDismiss: () => void,
  scrollToBottom: () => void,
) => {
  const { addToast } = useToasts();
  const queryClient = useQueryClient();

  const {
    mutate: addNewAttendeeMutate,
    isLoading: isAdding,
    error: addAttendeeError,
    reset: addAttendeeApiReset,
  } = useMutation<AxiosResponse<string>, AxiosError<Error>, IAddDeleteAttendeeSchema>(
    data => addNewAttendeeToPawfficeHour(data.pawfficeId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.PAWFFICE_HOUR, {
          refetchInactive: true,
        });
        addToast('Attendee added successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
        handleDialogDismiss();
      },
      onError: () => {
        scrollToBottom();
      },
    },
  );

  return {
    addNewAttendeeMutate,
    isAdding,
    addAttendeeError,
    addAttendeeApiReset,
  };
};
