import { StyleSheet, Font } from '@react-pdf/renderer';

import MontLight from 'assets/Mont/Mont-Light.ttf';
import MontRegular from 'assets/Mont/Mont-Regular.ttf';
import MontSemiBold from 'assets/Mont/Mont-SemiBold.ttf';
import MontBold from 'assets/Mont/Mont-Bold.ttf';

Font.register({
  family: 'Mont',
  fonts: [
    {
      src: MontLight,
      fontWeight: 300,
    },
    {
      src: MontRegular,
    },
    {
      src: MontSemiBold,
      fontWeight: 700,
    },
    {
      src: MontBold,
      fontWeight: 900,
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  headerView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  telltailLogo: {
    width: 118,
    height: 31,
  },
  title: {
    fontSize: 32,
    color: '#142669',
    fontFamily: 'Mont',
    fontWeight: 900,
  },
  divider: {
    width: '100%',
    height: 3,
    backgroundColor: '#8BC2DE',
    marginTop: 3,
  },
  content: {
    flexDirection: 'row',
  },
  contentText: {
    fontSize: 10,
    color: '#142669',
    fontFamily: 'Mont',
  },
  contentIcon: {
    width: 14,
    height: 14,
    objectFit: 'contain',
    marginRight: 10,
  },
  vaidationInfoView: { flexDirection: 'row', height: 170 },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  header: {
    fontSize: 20,
    color: '#142669',
    fontFamily: 'Mont',
    fontWeight: 700,
  },
  instructionView: {
    flex: 1,
    height: 155,
    padding: 10,
    borderRadius: 6,
  },
  instructionText: {
    fontSize: 10,
    color: '#142669',
    fontFamily: 'Mont',
  },
  disclaimerText: {
    fontSize: 8,
    color: '#142669',
    fontFamily: 'Mont',
    fontWeight: 300,
    marginTop: 10,
  },
});

export default styles;
