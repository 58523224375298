const BUYER = {
  //getImportantFactors
  IMPORTANT_FACTORS: 'IMPORTANT_FACTORS',
  //getBuyerAccountDetails
  BUYER_ACCOUNT_DETAILS: 'BUYER_ACCOUNT_DETAILS',
  //getPrototypeDefaultWeights
  PROTOTYPE_DEFAULT_WEIGHTS: 'PROTOTYPE_DEFAULT_WEIGHTS',
};

export default BUYER;
