/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction } from 'react';
import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import find from 'lodash/find';

//services
import { uploadImage } from 'services/api/apiCalls/cloudflare';
//types
import {
  IFileUpload,
  TError,
  IBuyerRegistrationInfo,
  TBuyerRegistrationInfoFieldType,
} from 'types';

export const useMutationUploadProfileImage = (
  setBuyerRegistrationInfo: <K extends keyof IBuyerRegistrationInfo>(
    registrationFieldName: K,
    registrationFieldValue: TBuyerRegistrationInfoFieldType<K>,
  ) => void,
  setStep: Dispatch<SetStateAction<number>>,
  step: number,
) => {
  const { mutate: uploadProfilePicture, isLoading: isUploadingProfilePicture } = useMutation<
    AxiosResponse<any>,
    AxiosError<TError>,
    IFileUpload
  >(req => uploadImage(req), {
    onSuccess: async res => {
      const imageUrl = find(res?.data?.result?.variants, function (item) {
        return item.includes('/public');
      });

      setBuyerRegistrationInfo('buildProfileForm', { profilePic: imageUrl });
      setStep(step + 1);
    },
  });

  return { uploadProfilePicture, isUploadingProfilePicture, isFinite };
};
