import React, { Dispatch, SetStateAction } from 'react';
import { Row, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

//validation
import { SendCodeToEmailSchema } from './Validation';
//hooks
import { useMutationSendEmail } from './__hooks/useMutationSendEmail';
//types
import { ISendCodeToEmailForm } from 'types';
//styles
import './index.scss';

const SendCodeToEmailForm = ({
  setShowChangePasswordForm,
  setEmail,
}: {
  setShowChangePasswordForm: Dispatch<SetStateAction<boolean>>;
  setEmail: Dispatch<SetStateAction<string>>;
}) => {
  // Form handling
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISendCodeToEmailForm>({
    resolver: yupResolver(SendCodeToEmailSchema),
  });

  // Mutation hooks
  const { mutate, isLoading } = useMutationSendEmail(setShowChangePasswordForm, setEmail);

  const onSubmit = (data: ISendCodeToEmailForm) => {
    mutate({
      emailAddress: data.emailAddress || '',
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="sendCodeToEmailForm">
      <Row className="m-0 mt-2 align-items-center">
        <Form.Label className="form-labels">Email Address*</Form.Label>
        <Form.Control
          bsPrefix={`input form-control ${errors.emailAddress ? 'mb-0 input-error' : 'mb-4'} `}
          type="email"
          placeholder="Enter email here"
          {...register('emailAddress')}
        />
        {errors && errors.emailAddress && (
          <p className="text-error mb-2">{errors.emailAddress.message}</p>
        )}

        <Button bsPrefix="forgot__button forgot" disabled={isLoading} type="submit">
          {isLoading ? (
            <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
          ) : (
            'Send verification code'
          )}
        </Button>
      </Row>
    </Form>
  );
};
export default SendCodeToEmailForm;
