import React, { useContext } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//types
import { TStepper, IMaxBudgetForm, TAuthContext } from 'types';
//validation
import { maxBudgetSchema } from './Validation';
//services
import { useDataLayer } from 'services/dataLayerContext';
import { AuthState } from 'services/auth';
//components
import TTButton from 'components/common/TTButton';
import CurrencyInput from 'components/common/CurrencyInput';
//styles
import '../index.scss';

export default function ScamFreeCertified({ setStep, step }: TStepper) {
  const pushToDataLayer = useDataLayer();
  const {
    setBuyerRegistrationInfo,
    state: { buyerRegistrationInfo },
  } = useContext(AuthState) as TAuthContext;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IMaxBudgetForm>({
    resolver: yupResolver(maxBudgetSchema),
    defaultValues: {
      maxBudget: buyerRegistrationInfo.budget,
    },
  });

  const onSubmit = (data: IMaxBudgetForm) => {
    pushToDataLayer({
      event: 'buyerMaxBudgetClick',
      componentName: 'Buyer maximum budget',
      ...data,
    });
    setBuyerRegistrationInfo('budget', data.maxBudget);

    setStep(step + 1);
    window.scrollTo({ top: 0 });
  };

  return (
    <div className="about-program-container">
      <h2>What’s your budget?</h2>

      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="about-program-container__form"
        id="buyerRegistrationBudgetForm"
      >
        <Row className="fill-available">
          <Col xs={12} className={`${errors.maxBudget ? 'mb-0 input-error' : 'mb-4'} `}>
            <Form.Label className="form-labels">Maximum Budget in USD*</Form.Label>
            <CurrencyInput
              sign="$"
              placeholder="What’s your maximum budget?"
              register={register}
              name="maxBudget"
            />

            {errors && errors.maxBudget && (
              <p className="text-error mb-2">{errors.maxBudget.message}</p>
            )}
          </Col>
        </Row>

        <div className="did-you-know">
          <h6>Did you know?</h6>
          <p>75% of TellTail Breeders price their puppies at $2,000 or more.</p>
        </div>

        <div className="form-button-wrapper">
          <TTButton height="50px" type="submit" className="btn-primary-orchid" text="Continue" />
        </div>
      </Form>
    </div>
  );
}
