/* eslint-disable @typescript-eslint/no-explicit-any */
//services
import API from 'services/api';
//types
import { TPagination, IBreeder } from 'types';

// Breeders
export const getBreeder = (id: string) => API.get(`/breeder/${id}`);
export const getPublicBreeder = (id: string) => API.get(`/breeder-public/${id}`);
export const updateBreeder = ({ data, id }: { data: IBreeder; id: string }) => {
  if ('matches' in data) delete data.matches;

  return API.put(`/breeder/${id}`, data);
};

export const getBreeders = async function (params: TPagination) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const queryParams = new URLSearchParams(params as any).toString();

  return API.get(`/breeder${queryParams}`);
};
export const getNotAskedBreeders = async function (breederId: string, query = {}) {
  const params = new URLSearchParams(query).toString();
  const res = await API.get(`/breeder-endorsement/not-asked/${breederId}?${params}`);

  return res.data;
};

export const deleteBreeder = (data: any, id: string) => API.delete(`/breeder/${id}`, data);

export const updateBreederGallery = ({
  breederGallery,
  id,
}: {
  breederGallery: string[];
  id: string;
}) => API.put(`/breeder-gallery/${id}`, { breederGallery });
export const deleteBreederGallery = ({
  breederGalleryUrl,
  id,
}: {
  breederGalleryUrl: string;
  id: string;
}) =>
  API.delete(`/breeder-gallery/${id}`, {
    data: {
      breederGalleryUrl: breederGalleryUrl,
    },
  });

export const getBreederMatches = async function (params = {}) {
  const queryParams = new URLSearchParams(params as any).toString();
  const res = await API.get(`breeder/matches?${queryParams}`);

  return res.data;
};

export const archiveBreederMatch = ({
  leadId,
  archived,
}: {
  leadId: string;
  archived: boolean;
}) => {
  return API.put(`/breeder/lead-archive`, { leadId, archived });
};
