import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import parse from 'html-react-parser';
import { Container } from 'react-bootstrap';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

//components
import TTButton from 'components/common/TTButton';
import KitImg from 'components/common/KitImg';
import TagItem from 'components/common/TagItem';
import RichTextEditor from 'components/common/SunTextEditor';
//helpers
import { replaceStringBasedOnObjectKeyValuePairs } from 'helpers/string';
//types
import { IReply } from 'types';
//hooks
import useMutationCreateConversationChildReply from 'pages/DetailsDiscussion/__hooks/useMutationCreateDiscussionChildReply';
import useMutationLikeDislikeResource from 'pages/ActivityFeed/__hooks/useMutationLikeDislikeResource';
//assets
import chatIcon from 'assets/images/discussion/chat.svg';
import likeIcon from 'assets/images/discussion/activityLike.svg';
import likedIcon from 'assets/images/discussion/activityLiked.svg';
//styles
import './index.scss';

const ArticleComment = ({
  item,
  level,
  isFetching,
  conversationId,
  knowledgeTagsOptions,
}: {
  item: IReply;
  level: number;
  isFetching: boolean;
  conversationId: string;
  knowledgeTagsOptions: { value: string; label: string }[] | undefined;
}) => {
  const [showReplies, setShowReplies] = useState(false);
  const [repliesCount, setRepliesCount] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editEditorState, setEditEditorState] = useState<string | null>(null);

  // Mutation hooks
  const { createConversationChildReplyMutate, createConversationChildReplyIsLoading } =
    useMutationCreateConversationChildReply();
  const { likeDislikeMutate, isLoadingLikeDislike } = useMutationLikeDislikeResource(true);

  useEffect(() => {
    if (item.replies?.length) {
      let count = item.replies.length;
      item.replies.forEach(elem => {
        count += elem.replies?.length | 0;
      });
      setRepliesCount(count);
    }
  }, [item]);

  return (
    <>
      {/* Discussion heading */}
      <div className="card-heading">
        <Row className="m-0">
          <Col className="m-0 p-0">
            <KitImg
              width="39"
              src={item.createdBy.profileImage || ''}
              kitwidth="40"
              imgClassName="kit-image-heading"
            />
          </Col>
          <Col className="m-0 p-0 cursor-pointer">
            <Link
              to={`/profile/${item.createdBy._id}`}
              onClick={() => {
                window.scrollTo({ top: 0 });
              }}
              className="text-light"
            >
              <p className="heading-text-left-up"> {item.createdBy.displayName}</p>
              <p className="heading-text-left-down">{item.createdBy.name}</p>
            </Link>
          </Col>
        </Row>
        <div className="heading-text-right">{moment(item.updatedDate).format('MMM DD, YYYY')}</div>
      </div>

      {/* Card text area */}
      <Col className={`card-text-area ${level !== 1 && 'is-reply'}`}>
        {/* Tags  */}
        <ul className={`comment__tags ${level !== 1 && 'is-reply'}`}>
          {item.tags && item.tags.map((tag, idx) => <TagItem key={idx} name={tag} />)}
        </ul>
        {/* Content */}
        <div className="card-content-text">
          {parse(
            replaceStringBasedOnObjectKeyValuePairs(item.contents || '', {
              '<iframe': '<video controls',
              '</iframe>': '</video>',
            }),
          )}
        </div>
      </Col>

      {/* Footer */}
      <div className={`footer ${!showReplies && 'closed'}`}>
        {/* Replies and follow icons */}
        <div className="footer-left">
          <div
            className={`tt-hover ${isLoadingLikeDislike && 'disable-click'}`}
            onClick={() => likeDislikeMutate(item._id)}
          >
            <img src={item.isLiked ? likedIcon : likeIcon} alt="" />
            <span>
              {isLoadingLikeDislike ? (
                <AiOutlineLoading3Quarters className="loading-animation svg-following" />
              ) : (
                `${item.likes} ${item.likes !== 1 ? 'Likes' : 'Like'}`
              )}
            </span>
          </div>

          {level < 2 && (
            <div className="tt-hover" onClick={() => setShowReplies(!showReplies)}>
              <img src={chatIcon} alt="" />
              <span>
                {repliesCount} {repliesCount <= 1 ? 'Comment' : 'Comments'}
              </span>
            </div>
          )}
        </div>

        {level < 2 && (
          <TTButton
            className="btn-secondary-navy reply-btn"
            text={showReplies ? 'Hide replies' : 'Reply'}
            width={showReplies ? '110px' : '75px'}
            height="32px"
            fontSize="12px"
            onClick={() => setShowReplies(!showReplies)}
          />
        )}
      </div>

      {showReplies && (
        <>
          <div className="comment-top-border" onClick={() => setShowReplies(!showReplies)}>
            {showReplies ? (
              <MdKeyboardArrowDown color="#8993B4" className="icon-arrow" />
            ) : (
              <MdKeyboardArrowUp />
            )}
            <div className="top-border" />
          </div>
          <Container
            className={`comment__bottom ${level === 1 && 'reply1'} ${level === 2 && 'reply2'}`}
          >
            {item.replies.map((reply, idx) => (
              <ArticleComment
                key={`${item._id}${reply._id}${idx}`}
                item={reply}
                level={level + 1}
                isFetching={isFetching}
                conversationId={conversationId}
                knowledgeTagsOptions={knowledgeTagsOptions}
              />
            ))}
          </Container>

          <RichTextEditor
            containerClassName="video-card-editor-lvl-2"
            fileNamePrefix="conversation"
            replyInfoText="Reply to this post"
            isSending={createConversationChildReplyIsLoading}
            existingTags={item.tags}
            handleCancelPress={() => {}}
            handleReplyPress={data => {
              const replyObj = { ...data, conversationId, parentReplyId: item._id };
              createConversationChildReplyMutate(replyObj);
              setEditEditorState(null);
            }}
            knowledgeTagsOptions={knowledgeTagsOptions}
            isEdit={false}
          />
        </>
      )}
    </>
  );
};

export default ArticleComment;
