import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';
import { useNavigate } from 'react-router-dom';

//services
import { resetPassword } from 'services/api/apiCalls/auth';
//types
import { IResetPasswordPayload } from 'types';

export const useMutationChangePassword = () => {
  const { addToast } = useToasts();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useMutation<AxiosResponse<{ message: string }>, AxiosError<Error>, IResetPasswordPayload>(
    resetPassword,
    {
      onSuccess: () => {
        addToast('Your password is successfully updated! We are redirecting you to login...', {
          appearance: 'success',
          autoDismiss: true,
        });

        setTimeout(() => {
          navigate('/login');
        }, 3000);
      },
      onError: error => {
        addToast(error?.response?.data?.message || 'Something went wrong with your request.', {
          appearance: 'error',
          autoDismiss: true,
        });
      },
    },
  );
};
