import React, { useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate, useLocation, Link, useParams } from 'react-router-dom';
import moment from 'moment';
import parse from 'html-react-parser';
import { AiOutlineLoading3Quarters, AiOutlineArrowLeft } from 'react-icons/ai';

//hooks
import useQueryArticleDetails from './__hooks/useQueryArticleDetails';
import useMutationLikeDislikeResource from 'pages/DetailsDiscussion/__hooks/useMutationLikeDislikeResource';
import { useQueryKnowledgeTags } from 'pages/BreederAccount/__hooks/useQueryKnowledgeTags';
import useMutationCreateDiscussionReply from 'pages/DetailsDiscussion/__hooks/useMutationCreateDiscussionReply';
//components
import Banner from 'components/common/HeadingBanner';
import Comments from './Comments';
import KitImg from 'components/common/KitImg';
import RichTextEditor from 'components/common/SunTextEditor';
import CBAC from 'components/common/CBAC';
//configs
import claims from 'configs/claims';
//types
import { IReplyPayload } from 'types';
//assets
import chat from 'assets/images/discussion/chat.svg';
import chain from 'assets/images/discussion/chain.svg';
import likeIcon from 'assets/images/discussion/like.svg';
import likedIcon from 'assets/images/discussion/liked.svg';
//styles
import './index.scss';

const ArticleDetails = () => {
  const navigate = useNavigate();
  const { articleId } = useParams() as { articleId: string };
  const { state } = useLocation() as { state: { articleId: string | undefined } };
  const [copyUrlText, setCopyUrlText] = useState('Copy / Share URL');

  // Query hooks
  const { knowledgeTags } = useQueryKnowledgeTags();
  const { isLoading, isFetching, error, data } = useQueryArticleDetails(
    state?.articleId || articleId,
  );
  // Mutation hooks
  const { likeDislikeMutate, isLoadingLikeDislike } = useMutationLikeDislikeResource();
  const { createConversationReplyMutate, createConversationReplyIsLoading } =
    useMutationCreateDiscussionReply();

  const article = useMemo(() => data, [data]);
  const knowledgeTagsOptions = knowledgeTags?.map(tag => ({ value: tag, label: tag }));

  if (isLoading) return <AiOutlineLoading3Quarters className="loading-animation svg-loading" />;
  else if (error || !article)
    return (
      <p className="text-no-found" style={{ width: '100%', color: 'red' }}>
        {error?.response?.data.ExceptionMessage}
      </p>
    );
  else {
    const handleNewReply = (payload: IReplyPayload, resetForm: () => void) => {
      const replyObj = {
        ...payload,
        conversationId: article.conversationId,
        resetForm,
      };
      createConversationReplyMutate(replyObj);
    };

    return (
      <div className="article">
        {/* Banner */}
        <Banner
          title={article.title}
          subTitle={
            <>
              <span>By </span>
              {article.createdBy ? (
                <Link
                  to={`/profile/${article.createdBy?._id}`}
                  onClick={() => {
                    window.scrollTo({ top: 0 });
                  }}
                  className="text-light"
                >
                  <span>{article.authorName}</span>
                </Link>
              ) : (
                <span>{article.authorName}</span>
              )}
              <span>{` | ${moment(article.publishStartDate).format('LL')}`}</span>
            </>
          }
          bgColor="blue"
          tags={article.articleTags}
        >
          <CBAC allowedClaims={[claims.ADMIN]}>
            <div className="reactions-container">
              <div
                className="tt-hover d-flex gap-2 text-decoration-none"
                onClick={() => likeDislikeMutate(article.conversationId)}
              >
                <img src={article.isLiked ? likedIcon : likeIcon} alt="" />
                <span>
                  {isFetching || isLoadingLikeDislike ? (
                    <AiOutlineLoading3Quarters className="loading-animation svg-following" />
                  ) : (
                    `${article.likes} ${article.likes !== 1 ? 'Likes' : 'Like'}`
                  )}
                </span>
              </div>
              <a className="tt-hover d-flex gap-2 text-decoration-none" href="#comments">
                <img className="image-icon" src={chat} alt="" />
                <span>{article?.replies?.length || 0}</span>
                <span>Comments</span>
              </a>
              <a
                className="tt-hover d-flex gap-2 text-decoration-none"
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href);
                  setCopyUrlText('URL is copied to clipboard!');
                  setTimeout(() => {
                    setCopyUrlText('Copy / Share URL');
                  }, 2000);
                }}
              >
                <img className="image-icon" src={chain} alt="" />
                <span>{copyUrlText}</span>
              </a>
            </div>
          </CBAC>
        </Banner>

        {/* Article section */}
        <Container className="article__container">
          <div
            className="article__back d-flex align-items-center"
            onClick={() => navigate('/learn')}
          >
            <AiOutlineArrowLeft />
            Back to Learn
          </div>

          {/* Article */}
          <div className="article__article">
            <KitImg src={article?.imageUrl} alt="" kitwidth="fit-content" className="fit-content" />
            {parse(
              ((article?.content || '') + '<br />' + (article?.source || ''))
                .replace(/href/g, "target='_blank' href")
                .replaceAll('/tr:w-300', '/tr:w-2000'),
            )}
          </div>
        </Container>

        <CBAC allowedClaims={[claims.ADMIN]}>
          {/* Comments */}
          <div className="comment-heading-title container mt-5">Comments</div>
          <div className="video-comments container" id="comments">
            {article?.replies?.length === 0 && <div className="text-no-found">No comments yet</div>}

            {article?.replies?.map((reply, idx) => (
              <div key={`${article._id}${reply._id}${idx}`}>
                <Comments
                  item={reply}
                  level={1}
                  isFetching={false}
                  conversationId={reply.conversationId}
                  knowledgeTagsOptions={knowledgeTagsOptions}
                />
              </div>
            ))}

            <RichTextEditor
              containerClassName="article-card-editor-lvl-1"
              fileNamePrefix="conversation"
              replyInfoText="Reply to this article"
              isSending={createConversationReplyIsLoading}
              existingTags={article.articleTags}
              handleCancelPress={() => {}}
              handleReplyPress={handleNewReply}
              knowledgeTagsOptions={knowledgeTagsOptions}
              isEdit={false}
            />
          </div>
        </CBAC>
      </div>
    );
  }
};
export default ArticleDetails;
