import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

//types
import { TTagItem } from 'types';

export default function TagItem({ name, type }: TTagItem) {
  return (
    <li key={name} className="btn">
      {type === 'comment' ? '#' + name : name}
    </li>
  );
}
