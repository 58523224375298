import { useQuery } from 'react-query';
import { AxiosResponse, AxiosError } from 'axios';

//services
import { getBreedsWithSizeVariation } from 'services/api/apiCalls/breeds';
//types
import { IBreedWithSizeVairation, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useQueryBreedsWithSizeVariation = () => {
  const {
    data,
    error: errorBreeds,
    isLoading: isLoadingBreeds,
  } = useQuery<AxiosResponse<IBreedWithSizeVairation[]>, AxiosError<TError>>(
    QUERY_KEYS.BREEDS_WITH_SIZE_VARIATION,
    getBreedsWithSizeVariation,
  );

  return {
    errorBreeds,
    breeds: data?.data,
    isLoadingBreeds,
  };
};
