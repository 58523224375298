import { montWidths } from 'constants/montWidths';
import { isEmpty } from 'lodash';

const awsUrl = `https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/images`;
const imageKitUrl = `https://ik.imagekit.io/${process.env.REACT_APP_IMAGEKIT_ID}`;

export function stringEllipsis(origin: string, maxLength: number) {
  if (origin?.length > maxLength) {
    return origin.substring(0, maxLength) + '...';
  } else {
    return origin;
  }
}

export function stringWidth(origin: string, widthsPerLetter: { [any: string]: number }) {
  const width = origin.split('').reduce((acc, letter) => {
    return acc + widthsPerLetter[letter];
  }, 0);

  return parseFloat(width.toFixed(0));
}

export function splitStringInTwo(origin: string, widthsPerLetter: { [any: string]: number }) {
  let firstString = '';
  let secondString = '';
  let currentWorld = '';
  let lastWorld = '';
  let isWordTooLong = false;

  const width = origin.split('').reduce((acc, letter) => {
    currentWorld += letter;
    if (letter === ' ') {
      lastWorld = currentWorld;
      currentWorld = '';
    }

    if (acc < 19) {
      firstString += letter;
    } else {
      secondString += letter;
    }

    return acc + widthsPerLetter[letter];
  }, 0);

  if (
    parseFloat(width.toFixed(0)) > 19 &&
    (firstString.charAt(firstString.length - 1) !== ' ' || secondString.charAt(0) !== ' ')
  ) {
    const firstStringLastWordIndex = firstString.lastIndexOf(' ');
    const tempFirstString = firstString.substring(0, firstStringLastWordIndex);
    tempFirstString;
    const tempSecondString =
      firstString.substring(firstStringLastWordIndex + 1, firstString.length) + secondString;
    tempSecondString;

    firstString = tempFirstString;
    secondString = tempSecondString;
  }

  if (
    stringWidth(firstString, montWidths) > 19 ||
    stringWidth(secondString, montWidths) > 19 ||
    parseFloat(width.toFixed(0)) > 40
  ) {
    isWordTooLong = true;
  }

  return {
    width: width.toFixed(0),
    firstString,
    secondString,
    currentWorld,
    lastWorld,
    isWordTooLong,
    originalText: origin,
  };
}

export function stringPadStartAndEnd(origin: string, maxLength: number, isSecondLine?: boolean) {
  const lettersWidth = stringWidth(origin, montWidths);
  const pad = (maxLength - lettersWidth) / 2;
  const factor = isSecondLine ? 2.5 : 2.5;

  if (lettersWidth > 14)
    return origin.padEnd((pad + lettersWidth) * 2, ' ').padStart(maxLength * 2, ' ');
  else return origin.padEnd((pad + lettersWidth) * factor, ' ').padStart(maxLength * factor, ' ');
}

// export function splitStringInTwoAndDontBreakWords(labelText: string) {
//   const firstString = labelText.substring(0, 19);
//   const secondString = labelText.substring(19, labelText.length);

//   if (firstString.lastIndexOf(' ') === 18) {
//     return [
//       stringPadStartAndEnd(firstString.substring(0, 18), 19),
//       stringPadStartAndEnd(secondString, 19),
//       secondString || '',
//     ];
//   } else if (secondString.indexOf(' ') === 0) {
//     return [
//       stringPadStartAndEnd(firstString, 19),
//       stringPadStartAndEnd(secondString.substring(1, 19), 19),
//       secondString.substring(1, 19) || '',
//     ];
//   } else {
//     const firstStringLastWordIndex = firstString.lastIndexOf(' ');

//     const firstStringSplitByWord = firstString.substring(0, firstStringLastWordIndex);
//     const secondStringSplitByWord =
//       firstString.substring(firstStringLastWordIndex + 1, firstString.length) + secondString;

//     if (secondStringSplitByWord.length > 19) {
//       const secondStringLastWordIndex = secondStringSplitByWord.lastIndexOf(' ');
//       const secondStringSplitByWordTrimmed = secondStringSplitByWord.substring(
//         0,
//         secondStringLastWordIndex,
//       );

//       return [
//         stringPadStartAndEnd(firstStringSplitByWord, 19),
//         stringPadStartAndEnd(secondStringSplitByWordTrimmed, 19),
//         secondStringSplitByWordTrimmed || '',
//       ];
//     } else {
//       return [
//         stringPadStartAndEnd(firstStringSplitByWord, 19),
//         stringPadStartAndEnd(secondStringSplitByWord, 19),
//         secondStringSplitByWord || '',
//       ];
//     }
//   }
// }

export function convertImgUrl(url: string | undefined, width: string | number) {
  if (url?.includes(awsUrl)) {
    return url.replace(awsUrl, imageKitUrl).replaceAll('+', '%20') + `/tr:w-${width}`;
  }

  return url;
}

// Replaces all occurrences of a string in a string, using a map object,
// where the key is the string to be replaced and the value is the replacement string
export function replaceStringBasedOnObjectKeyValuePairs(
  string: string,
  mapObj: { [key: string]: string },
) {
  const re = new RegExp(Object.keys(mapObj).join('|'), 'gi');

  return string.replace(re, function (matched: string) {
    return mapObj[matched.toLowerCase()];
  });
}

export function modifyDevPanelUrl(backendUrl: string) {
  let updatedUrl = backendUrl;

  if (backendUrl.startsWith('http://')) {
    updatedUrl = updatedUrl.replace('http://', 'https://');
  } else if (!backendUrl.startsWith('https://')) {
    updatedUrl = 'https://' + updatedUrl;
  }

  if (!backendUrl.endsWith('/api') && !backendUrl.endsWith('/api/') && backendUrl.endsWith('/')) {
    updatedUrl += 'api';
  } else if (!backendUrl.endsWith('/api') && !backendUrl.endsWith('/')) {
    updatedUrl += '/api';
  }

  return updatedUrl;
}

export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
}

export function aOrAn(str: string) {
  const vowels = ['a', 'e', 'i', 'o', 'u'];

  return vowels.includes(str[0].toLowerCase()) ? 'an' : 'a';
}

export function formatAndHideEmailInfo(email: string) {
  const [username, domain] = email.split('@');

  // Check if the username and domain are valid
  if (!username || !domain) {
    return 'No email is available';
  }

  // Format the username and domain with asterisks
  const visibleUsernameChars = 2;
  const visibleDomainChars = 2;
  const formattedUsername =
    username.substring(0, visibleUsernameChars) +
    '*'.repeat(username.length - visibleUsernameChars);
  const domainParts = domain.split('.');
  const formattedDomain = domainParts
    .map((part, index) => {
      if (index === 0) {
        // Only format the first part of the domain
        return part.substring(0, visibleDomainChars) + '*'.repeat(part.length - visibleDomainChars);
      }

      return part;
    })
    .join('.');

  return `${formattedUsername}@${formattedDomain}`;
}

export function formatUSPhoneNumber(number: string) {
  // Remove any non-numeric characters from the input
  const cleaned = ('' + number).replace(/\D/g, '');

  if (cleaned.length === 0) {
    return 'Number is not available.';
  }

  // Split the string into parts
  const part1 = cleaned.slice(0, 3);
  const part2 = cleaned.slice(3, 6);
  const part3 = cleaned.slice(6);

  // Format and return the phone number
  return `(${part1}) ${part2}-${part3}`;
}

export function formatFullNameToInitials(fullName: string) {
  const parts = fullName.split(' ');
  if (parts.length === 0) {
    return '';
  }

  // First name remains unchanged
  let formattedName = parts[0];

  // Process the surname(s)
  for (let i = 1; i < parts.length; i++) {
    formattedName += ' ' + parts[i].charAt(0) + '.';
  }

  return formattedName;
}

export function formatAddressForBreederLeads(
  city: string,
  state: string,
  country: string,
  zipCode: string,
) {
  if (isEmpty(city) && isEmpty(state)) {
    return `ZIP Code: ${zipCode}`;
  } else if (isEmpty(city) && !isEmpty(state)) {
    return country === 'United States' ? state : `${state}, ${country}`;
  } else if (!isEmpty(city) && isEmpty(state)) {
    return country === 'United States' ? city : `${city}, ${country}`;
  } else return country === 'United States' ? `${city}, ${state}` : `${city}, ${state}, ${country}`;
}

// Replace multiple spaces with a single space and trim spaces from the end
export function cleanUserNames(name: string) {
  return name.replace(/\s+/g, ' ').trimEnd();
}
