import { Dispatch, SetStateAction } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { deleteResource } from 'services/api/apiCalls/admin';
//types
import { TError, IDialogContent } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useMutationDeleteVideo = (
  setDialogContent: (dialogContent: IDialogContent | null) => void,
  setResourceAction: Dispatch<SetStateAction<string | null>>,
) => {
  const queryClient = useQueryClient();

  const { mutate: deleteResourceMutate, isLoading: isDeleting } = useMutation<
    AxiosResponse<string>,
    AxiosError<TError>,
    string
  >(videoId => deleteResource(videoId), {
    onSuccess: () => {
      queryClient.removeQueries(QUERY_KEYS.RESOURCE_LIST);

      setResourceAction(null);
      setDialogContent({
        title: 'Info',
        message: `Please remember to take video off the “Production” folder on vimeo to fully delete access to it. 
        If you undelete the video resource in the future, and you have deleted the video on vimeo, 
        the video link will no longer be valid, since the vimeo video link was deleted.`,
      });
    },
  });

  return {
    deleteResourceMutate,
    isDeleting,
  };
};

export default useMutationDeleteVideo;
