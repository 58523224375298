import React, { useMemo, useState, useContext, useRef } from 'react';
import { Box } from '@mui/material';
import MaterialReactTable, { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { Edit, Delete } from '@mui/icons-material';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import moment from 'moment';
import { isEmpty, filter } from 'lodash';

import 'bootstrap/dist/css/bootstrap.min.css';

//components
import TTButton from 'components/common/TTButton';
import ActionButton from 'components/common/ActionButton';
import AddEditLitter from './AddLitter';
//hooks
import {
  useQueryLittersList,
  useMutationCreateLitter,
  useMutationUpdateLitterInfo,
} from './__hooks/useQueryLitters';
import { useMutationDeleteLitter } from './__hooks/useMutationDeleteLitter';
//types
import { ILitter, TAuthContext, IAddLitterData, IAddLitterSchema, ISelectOption } from 'types';
//services
import { AuthState } from 'services/auth';
//assets
import edit from 'assets/images/breederProfile/edit.svg';
import deleteSvg from 'assets/images/registrationAndSignIn/delete.svg';

const MyLitters = ({
  breeds,
  breederBreeds,
}: {
  breeds: ISelectOption[];
  breederBreeds: ISelectOption[];
}) => {
  const {
    state: { breederId },
  } = useContext(AuthState) as TAuthContext;
  const [litterId, setLittterId] = useState<string | undefined>(undefined);
  const [showAddEditLitter, setShowAddEditLitter] = useState(false);
  const addLitterData = useRef<IAddLitterData>();
  const [action, setAction] = useState<'edit' | 'delete' | undefined>(undefined);

  const breederBreedsValues = new Set(breederBreeds.map(item => item.value));
  const breedsWithoutBreederBreeds = filter(breeds, item => !breederBreedsValues.has(item.value));

  //Query hooks
  const { data, total, isFetching, isLoading, refetchLitterList } = useQueryLittersList(breederId);
  //Mutation hooks
  const { mutate: createLitter, isLoading: isLoadingLitterCreation } = useMutationCreateLitter();
  const { mutate: updateLitter, isLoading: isLoadingLitterUpdate } = useMutationUpdateLitterInfo();
  const { deleteLitterMutate, isDeleting } = useMutationDeleteLitter();

  const columns = useMemo<MRT_ColumnDef<ILitter>[]>(
    () => [
      {
        header: 'Go Home Date',
        accessorKey: 'goHomeDate',
        accessorFn: row => moment(row.goHomeDate).format('MMMM DD, YYYY'),
        size: 200,
      },
      {
        header: 'Breed',
        accessorKey: 'breedName',
        accessorFn: row => row?.breedName,
        size: 255,
      },
      {
        header: 'Price Range',
        accessorKey: 'minPrice',
        accessorFn: row => `$${row?.minPrice} - $${row?.maxPrice}`,
        size: 200,
      },
    ],
    [],
  );

  const onAddLitterClick = (litterSchema: IAddLitterSchema) => {
    if (litterId) {
      updateLitter({
        data: {
          ...litterSchema,
          breedName: litterSchema?.breedName.label,
          breederId: breederId,
          notes: '',
          transportAvailable: true,
          priceFlexible: true,
        },
        id: litterId,
      });
    } else {
      createLitter({
        ...litterSchema,
        breederId: breederId,
        breedName: litterSchema?.breedName.label,
        notes: '',
        transportAvailable: true,
        priceFlexible: true,
      });
    }
    setLittterId(undefined);
    setAction(undefined);
    addLitterData.current = undefined;

    setShowAddEditLitter(false);
  };

  const onDeleteLitterClick = () => {
    if (litterId) {
      deleteLitterMutate(litterId);
    }

    addLitterData.current = undefined;
    setLittterId(undefined);
    setAction(undefined);

    setShowAddEditLitter(false);
  };

  const handleEditPress = (id: string, currentLitter?: IAddLitterData) => {
    addLitterData.current = currentLitter;
    setLittterId(id);
    setShowAddEditLitter(true);
  };
  const handleDeletePress = (id: string, currentLitter?: IAddLitterData) => {
    addLitterData.current = currentLitter;
    setLittterId(id);
    setShowAddEditLitter(true);
  };

  const renderLitterTableActions = ({ row }: { row: MRT_Row<ILitter> }) => {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" gap="0.5rem">
        <ActionButton
          color="black"
          title="Edit"
          Icon={<Edit />}
          onClick={async () => {
            handleEditPress(row.original._id, {
              breedName: row.original.breedName,
              litterData: row.original,
            });
            setAction('edit');
          }}
        />
        <ActionButton
          color="black"
          title="Delete"
          Icon={<Delete />}
          onClick={async () => {
            handleDeletePress(row.original._id, {
              breedName: row.original.breedName,
              litterData: row.original,
            });
            setAction('delete');
          }}
        />
      </Box>
    );
  };

  if (isFetching || isLoading) {
    return (
      <div className="w-100 d-flex justify-content-center">
        <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
      </div>
    );
  } else
    return (
      <>
        {!isEmpty(data) ? (
          <>
            <div className="desktop-container">
              <MaterialReactTable
                columns={columns}
                data={data ?? []}
                rowCount={total ?? 0}
                initialState={{ pagination: { pageSize: 10, pageIndex: 0 } }}
                memoMode="cells"
                state={{
                  isLoading: isLoading,
                  showProgressBars: isLoading || isFetching,
                }}
                muiTablePaginationProps={{
                  rowsPerPageOptions: [10],
                }}
                enableColumnOrdering
                enableGlobalFilter={false}
                enableRowActions
                positionActionsColumn="last"
                renderRowActions={renderLitterTableActions}
              />
              <div className="d-flex w-100 mt-4 align-items-center justify-content-end">
                <TTButton
                  type="submit"
                  className="btn-primary-orchid save_btn"
                  text="Add Litter"
                  width="125px"
                  height="40px"
                  fontSize="12px"
                  borderRadius="10px"
                  onClick={() => {
                    setAction('edit');
                    setShowAddEditLitter(true);
                  }}
                />
              </div>
            </div>

            {/* Mobile version */}
            <div className="mobile-container no-padding w-100">
              {data?.map((item: ILitter) => (
                <div className="m-table-card" key={item._id}>
                  <div className="m-table-card__left">
                    <div className="d-flex align-items-center gap-1">
                      <p className="bold-match">{item.breedName}</p>{' '}
                      <p className="new-match">NEW!</p>
                    </div>
                    <p>{moment(item?.goHomeDate).format('MMMM DD, YYYY')}</p>
                    <p>{`$${item?.minPrice} - $${item?.maxPrice}`}</p>
                  </div>
                  <div>
                    <img
                      src={edit}
                      alt="edit"
                      onClick={() => {
                        setAction('edit');
                        handleEditPress(item._id, { breedName: item.breedName, litterData: item });
                      }}
                    />
                    <img
                      src={deleteSvg}
                      alt="delete"
                      onClick={() => {
                        setAction('delete');
                        handleDeletePress(item._id, {
                          breedName: item.breedName,
                          litterData: item,
                        });
                      }}
                    />
                  </div>
                </div>
              ))}

              <div className="d-flex w-100 mt-4 align-items-center justify-content-center">
                <TTButton
                  type="submit"
                  className="btn-primary-orchid save_btn"
                  text="Add Litter"
                  width="125px"
                  height="40px"
                  fontSize="12px"
                  borderRadius="10px"
                  onClick={() => {
                    setAction('edit');
                    setShowAddEditLitter(true);
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="unlock-matches">
            <h6>Let’s Create a Litter</h6>
            <p>
              Creating a litter is the first step to receiving buyer leads. Click on the “Add
              Litter” button below to get started. You can add litters that are available now,
              litters that will be available soon, or even litters that you are planning for the
              future! Once you add a litter to your profile, you will start receiving information
              about buyers that are matched to your program.
            </p>
            <TTButton
              type="submit"
              className="btn-primary-orchid"
              text="Add Litter"
              width="125px"
              height="40px"
              fontSize="12px"
              borderRadius="10px"
              onClick={() => {
                setAction('edit');
                setShowAddEditLitter(true);
              }}
            />
          </div>
        )}

        {showAddEditLitter && (
          <AddEditLitter
            breederBreeds={breederBreeds}
            breedsWithoutBreederBreeds={breedsWithoutBreederBreeds}
            addLitterData={addLitterData.current}
            handleClose={() => {
              setLittterId(undefined);
              addLitterData.current = undefined;
              refetchLitterList();

              setShowAddEditLitter(false);
            }}
            onAddLitterClick={onAddLitterClick}
            onDeleteLitterClick={onDeleteLitterClick}
            loading={isLoadingLitterCreation || isLoadingLitterUpdate || isDeleting}
            action={action}
          />
        )}
      </>
    );
};
export default MyLitters;
