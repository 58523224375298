/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import html2canvas from 'html2canvas';
import { FiDownload } from 'react-icons/fi';
import QRCodeStyling from 'qr-code-styling';

//components
import TTButton from 'components/common/TTButton';
//helpers
import { stringPadStartAndEnd, splitStringInTwo } from 'helpers/string';
import { blobToDataURL } from 'helpers/blob';
//constants
import { montWidths } from 'constants/montWidths';
//assets
import { AnnouncementPost } from 'assets/images/SFC/AnnouncementPost';
import { CertifiedBadgeWithQR } from 'assets/images/SFC/CertifiedBadgeWithQR';
import certifiedMeaning from 'assets/images/SFC/certifiedMeaning.png';
import paw from 'assets/images/SFC/paw.png';
//types
import { IBreeder } from 'types';
//styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

const DownloadMaterials = ({
  breederInfo,
  breederId,
}: {
  breederInfo: IBreeder;
  breederId: string;
}) => {
  const badgeName = breederInfo.badgeDetailsId.nameOnBadge;
  const badgeNumber = breederInfo.badgeDetailsId.badgeNumber;
  const [qrCodeDataURL, setQrCodeDataURL] = useState<string | ArrayBuffer | null>(null);

  const handleDownloadBadgeImage = async (elementId: string, breederName: string) => {
    const element = document.getElementById(elementId) as HTMLElement;

    const canvas = await html2canvas(element, {
      backgroundColor: 'none',
      ignoreElements: (el: Element) => {
        return el.id === `btn_sfc_download_${elementId}`;
      },
      scale: 6,
    });

    const dataURL = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = `${breederName.replace(/ /g, '')}_${elementId}.png`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadButton = (elementId: string, breederName: string) => (
    <TTButton
      id={`btn_sfc_download_${elementId}`}
      className="btn-neutral-navy save_btn_sfc_dl"
      text={<FiDownload size={20} />}
      width="35px"
      height="35px"
      onClick={() => handleDownloadBadgeImage(elementId, breederName)}
    />
  );

  useEffect(() => {
    const getQRCodeRawData = async () => {
      const qrCode = new QRCodeStyling({
        width: 700,
        height: 700,
        image: paw,
        data: `https://match.telltail.com/public-profile/${breederId}`,
        type: 'svg',
        dotsOptions: {
          color: '#142669',
          type: 'rounded',
        },
        imageOptions: {
          imageSize: 0.4,
          crossOrigin: 'anonymous',
          margin: 20,
        },
      });

      const rawData = (await qrCode.getRawData()) as Blob;

      blobToDataURL(rawData, function (dataurl: string | ArrayBuffer | null) {
        setQrCodeDataURL(dataurl);
      });
    };

    getQRCodeRawData();
  }, [breederId]);

  return (
    <>
      <div className="sfc-subtitle">Brand materials</div>
      {qrCodeDataURL && (
        <>
          <div className="badges-content no_margin">
            <div id="announcementPostSFC" className="download_materials_image">
              <AnnouncementPost
                text={badgeName}
                textUpperWithPad={stringPadStartAndEnd(
                  splitStringInTwo(badgeName.toUpperCase(), montWidths).firstString,
                  19,
                )}
                textLowerWithPad={
                  splitStringInTwo(badgeName.toUpperCase(), montWidths).secondString === ''
                    ? ''
                    : stringPadStartAndEnd(
                        splitStringInTwo(badgeName.toUpperCase(), montWidths).secondString,
                        19,
                        true,
                      )
                }
                badgeNumber={badgeNumber}
                qrCodeDataURL={qrCodeDataURL}
              />
              {downloadButton('announcementPostSFC', 'sfc_announcement_badge')}
            </div>

            <div id="whatCertifiedMeans" className="download_materials_image">
              <img src={certifiedMeaning} className="dl_what_certified_means" />
              {downloadButton('whatCertifiedMeans', 'what_certified_means')}
            </div>
          </div>

          <div className="certification_badge">
            <div className="sfc-subtitle">Certification badge</div>
            <div id="certifiedBadge" className="download_materials_image">
              <CertifiedBadgeWithQR
                text={badgeName}
                textUpperWithPad={stringPadStartAndEnd(
                  splitStringInTwo(badgeName.toUpperCase(), montWidths).firstString,
                  19,
                )}
                textLowerWithPad={
                  splitStringInTwo(badgeName.toUpperCase(), montWidths).secondString === ''
                    ? ''
                    : stringPadStartAndEnd(
                        splitStringInTwo(badgeName.toUpperCase(), montWidths).secondString,
                        19,
                        true,
                      )
                }
                badgeNumber={badgeNumber}
                qrCodeDataURL={qrCodeDataURL}
              />
              {downloadButton('certifiedBadge', 'certified_badge')}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DownloadMaterials;
