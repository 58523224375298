/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import find from 'lodash/find';

//services
import { uploadImage } from 'services/api/apiCalls/cloudflare';
//types
import { IAddNewArticlePayload, IUploadArticlePhoto, TError } from 'types';

const useMutationUploadArticlePhoto = (
  addNewArticleMutate: (mData: IAddNewArticlePayload) => void,
) => {
  const { mutate: uploadArticlePhotoMutate } = useMutation<
    AxiosResponse<any>,
    AxiosError<TError>,
    IUploadArticlePhoto
  >(req => uploadImage(req), {
    onSuccess: (res, { data }) => {
      const imageUrl = find(res?.data?.result?.variants, function (item) {
        return item.includes('/public');
      });

      data.imageUrl = imageUrl as string;
      addNewArticleMutate(data);
    },
  });

  return { uploadArticlePhotoMutate };
};

export default useMutationUploadArticlePhoto;
