//services
import API from 'services/api';

// Deals
export const getDealList = async function (query = {}) {
  const params = new URLSearchParams(query).toString();
  const res = await API.get(`/deals-user/list/?${params}`);

  return res.data;
};

export const getDealDetails = (dealId: string) => {
  return API.get(`/deals-user/deal/${dealId}`);
};
