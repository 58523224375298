/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import QRCodeStyling from 'qr-code-styling';

//components
import ProfileInfo from './ProfileInfo';
import AboutMe from './AboutMe';
import MyGallery from './MyGallery';
import TTButton from 'components/common/TTButton';
import GetInTouch from './GetInTouch';
//hooks
import { useQueryPublicBreederInfo } from './__hooks/useQueryPublicBreederInfo';
//types
import { TAuthContext } from 'types';
//services
import { AuthState } from 'services/auth';
//constants
import { montWidths } from 'constants/montWidths';
//helpers
import { blobToDataURL } from 'helpers/blob';
import { stringPadStartAndEnd, splitStringInTwo } from 'helpers/string';
//styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
//assets
import paw from 'assets/images/SFC/paw.png';
import edit from 'assets/images/breederProfile/editWhite.svg';
import { CertifiedBadgeWithQR } from 'assets/images/breederProfile/CertifiedBadgeWithQR';

const PublicBreederProfile = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const isFromAdmin = params.from === 'admin';
  const {
    state: { breederId, isAuthenticated, basicUserInfo },
  } = useContext(AuthState) as TAuthContext;
  const [qrCodeDataURL, setQrCodeDataURL] = useState<string | ArrayBuffer | null>(null);

  //Query hooks
  const { errorPublicBreeder, publicBreederInfo, isLoadingPublicBreeder, isFetchingPublicBreeder } =
    useQueryPublicBreederInfo(userId!, isFromAdmin);

  useEffect(() => {
    if (publicBreederInfo) {
      const getQRCodeRawData = async () => {
        const qrCode = new QRCodeStyling({
          width: 700,
          height: 700,
          image: paw,
          data: `https://match.telltail.com/public-profile/${publicBreederInfo._id}`,
          type: 'svg',
          dotsOptions: {
            color: '#142669',
            type: 'rounded',
          },
          imageOptions: {
            imageSize: 0.4,
            crossOrigin: 'anonymous',
            margin: 20,
          },
        });

        const rawData = (await qrCode.getRawData()) as Blob;

        blobToDataURL(rawData, function (dataurl: string | ArrayBuffer | null) {
          setQrCodeDataURL(dataurl);
        });
      };

      getQRCodeRawData();
    }
  }, [breederId, publicBreederInfo]);

  if (isLoadingPublicBreeder || isFetchingPublicBreeder) {
    return (
      <div className="d-flex width-full justify-content-center my-3">
        <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
      </div>
    );
  } else if (errorPublicBreeder || !publicBreederInfo) {
    isAuthenticated ? navigate('/profile') : navigate('/login');

    return null;
  } else
    return (
      <>
        <Row className="w-100 m-0 mx-auto mt-4 justify-content-end edit_button_container">
          {isAuthenticated && breederId === publicBreederInfo?._id && (
            <TTButton
              type="submit"
              className="btn-secondary-navy"
              text={
                <>
                  Edit My Profile <img src={edit} alt="edit_profile_personal"></img>
                </>
              }
              width="auto"
              height="40px"
              onClick={() => {
                navigate(`/profile/${breederId}`);
              }}
            />
          )}
        </Row>

        {publicBreederInfo && (
          <div className="breederProfile_public loaded">
            <Container className="breederProfile__content">
              <Row className="w-100 m-0 justify-content-center">
                <Col xs={8} md={4} lg={3} className="breeder_tab_container">
                  <div className="mb-4">
                    <ProfileInfo
                      breederInfo={publicBreederInfo}
                      isUserProfile={false}
                      isAuthenticated={isAuthenticated}
                      basicUserInfo={basicUserInfo}
                    />
                  </div>
                  <div className="mb-4 badge_container">
                    <CertifiedBadgeWithQR
                      text={publicBreederInfo.nameOnBadge}
                      textUpperWithPad={stringPadStartAndEnd(
                        splitStringInTwo(publicBreederInfo.nameOnBadge.toUpperCase(), montWidths)
                          .firstString,
                        19,
                      )}
                      textLowerWithPad={
                        splitStringInTwo(publicBreederInfo.nameOnBadge.toUpperCase(), montWidths)
                          .secondString === ''
                          ? ''
                          : stringPadStartAndEnd(
                              splitStringInTwo(
                                publicBreederInfo.nameOnBadge.toUpperCase(),
                                montWidths,
                              ).secondString,
                              19,
                              true,
                            )
                      }
                      badgeNumber={publicBreederInfo.badgeNumber}
                      // @ts-ignore
                      qrCodeDataURL={qrCodeDataURL}
                    />
                  </div>
                </Col>

                <Col xs={12} md={8} lg={9}>
                  <AboutMe isUserProfile={false} breederInfo={publicBreederInfo} />
                  <GetInTouch isAuthenticated={isAuthenticated} breederInfo={publicBreederInfo} />
                  <MyGallery
                    isUserProfile={false}
                    breederGallery={publicBreederInfo?.breederGallery}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </>
    );
};

export default PublicBreederProfile;
