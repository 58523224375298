const DOGPEDIA = {
  //getBreeds
  LIST_OF_BREEDS: 'LIST_OF_BREEDS',
  //getDropdownBreeds
  BREEDS_DROPDOWN: 'BREEDS_DROPDOWN',
  //getBreedsWithSizeVariation
  BREEDS_WITH_SIZE_VARIATION: 'BREEDS_WITH_SIZE_VARIATION',
  //getBreed
  BREED: 'BREED',
};

export default DOGPEDIA;
