import React, { useState, useMemo, useRef } from 'react';
import { Box } from '@mui/material';
import MaterialReactTable, { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { Delete, Edit } from '@mui/icons-material';
import 'bootstrap/dist/css/bootstrap.min.css';

//hooks
import { useQueryAdminBreedsList } from './__hooks/useQueryAdminBreedsList';
import { useMutationDeleteBreedEncyclopaedia } from './__hooks/useMutationDeleteBreedEncyclopaedia';
//types
import { IBreedEncyclopaedia, IDialogContent } from 'types';
//components
import TTButton from 'components/common/TTButton';
import ActionButton from 'components/common/ActionButton';
import KitImg from 'components/common/KitImg';
import EditBreedEncyclopaedia from './EditBreedEncyclopaedia';
import AlertDialog from 'components/common/AlertDialog';

const BreedEncyclopaediaTable = () => {
  const breedToDelete = useRef<string | undefined>();
  const breedIdToEdit = useRef<string | undefined>();

  const [showCreateEditBreedEncyclopaediaDialog, setShowCreateEditBreedEncyclopaediaDialog] =
    useState(false);
  const [dialogContent, setDialogContent] = useState<IDialogContent | null>(null);

  //Query hooks
  const { isLoading, data, total, isError, isFetching, refetchBreedsList } =
    useQueryAdminBreedsList();
  // //Mutation hooks
  const { deleteBreedEncyclopaediaMutate, isDeleting } =
    useMutationDeleteBreedEncyclopaedia(setDialogContent);

  const columns = useMemo<MRT_ColumnDef<IBreedEncyclopaedia>[]>(
    () => [
      {
        header: 'Breed Id',
        accessorKey: '_id',
      },
      {
        header: 'Breed Image',
        accessorKey: 'profilePicture',
        Cell: ({ row }: { row: MRT_Row<IBreedEncyclopaedia> }) => (
          <KitImg
            src={
              row?.original?.profilePicture ||
              'https://telltail-breeder-resources.s3.amazonaws.com/images/profiles/App Icon Square.png'
            }
            kitwidth={100}
            style={{
              width: '50px',
              height: '50px',
              borderRadius: '25px',
              marginRight: '10px',
              objectFit: 'cover',
            }}
          />
        ),
      },
      {
        header: 'Breed Name',
        accessorKey: 'breedName',
      },
    ],
    [],
  );

  // Handle table actions logic
  const handleDeletePress = (row: MRT_Row<IBreedEncyclopaedia>) => {
    breedToDelete.current = row.original._id;
    setDialogContent({
      title: 'Delete Breedopedia?',
      message: `Are you sure you want to delete ${row.original.breedName}?`,
    });
  };

  const renderBreedEncyclopaediaTableActions = ({ row }: { row: MRT_Row<IBreedEncyclopaedia> }) => {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" gap="0.5rem">
        <ActionButton
          title="Edit"
          Icon={<Edit />}
          color="gray"
          disabled={isLoading || isFetching}
          onClick={() => {
            breedIdToEdit.current = row.original._id;
            setShowCreateEditBreedEncyclopaediaDialog(true);
          }}
        />
        <ActionButton title="Delete" Icon={<Delete />} onClick={() => handleDeletePress(row)} />
      </Box>
    );
  };

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data ?? []}
        rowCount={total ?? 0}
        initialState={{ pagination: { pageSize: 10, pageIndex: 0 } }}
        memoMode="cells"
        state={{
          isLoading: isLoading,
          showAlertBanner: isError,
          showProgressBars: isFetching,
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 20, 50],
        }}
        enableColumnOrdering
        enableGlobalFilter={false}
        renderTopToolbarCustomActions={() => (
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              p: '0.5rem',
              pb: '2rem',
            }}
          >
            <TTButton
              className="btn-primary-orchid"
              height={40}
              width={200}
              marginLeft="10px"
              text="Create New Breed"
              onClick={() => {
                breedIdToEdit.current = undefined;
                setShowCreateEditBreedEncyclopaediaDialog(true);
              }}
            />
          </Box>
        )}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={renderBreedEncyclopaediaTableActions}
        renderDetailPanel={({ row }) => <>{row.original.breedName}</>}
      />

      {/* Delete Breedopedia Dialog */}
      <AlertDialog
        isOpened={Boolean(dialogContent)}
        dialogContent={dialogContent}
        isLoading={isDeleting}
        onAgree={() => {
          if (!breedToDelete.current) return;

          deleteBreedEncyclopaediaMutate(breedToDelete.current);
        }}
        handleClose={() => setDialogContent(null)}
      />

      {/* Create/Edit Breedopedia Dialog */}
      {Boolean(showCreateEditBreedEncyclopaediaDialog) && (
        <EditBreedEncyclopaedia
          open={Boolean(showCreateEditBreedEncyclopaediaDialog)}
          handleClose={() => {
            refetchBreedsList();

            setShowCreateEditBreedEncyclopaediaDialog(false);
          }}
          breedId={breedIdToEdit.current}
        />
      )}
    </>
  );
};

export default BreedEncyclopaediaTable;
