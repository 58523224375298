/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState, useEffect, useContext } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useInView } from 'react-intersection-observer';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

//services
import { BreedopediaFiltersState } from 'services/breedopediaFilters';
//types
import { IBreedsFiltersList, TBreedopediaFiltersContext } from 'types';
//components
import Filters from './Filters';
//hooks
import useQueryBreedsList from './__hooks/useQueryBreedsList';
import { useQueryFilters } from './__hooks/useQueryFilters';
import { useQueryBreedsDropdown } from './__hooks/useQueryBreedsDropdown';
import useWindowSize from 'hooks/useWindowSize';
//assets
import headerLogo from 'assets/images/breedDirectory/headerLogo.png';
import placeholderDog from 'assets/images/breedDirectory/placeholderDog.png';
//styles
import './index.scss';
import { selectStylesBreedDirectory } from 'components/styled/select';

const BreedDirectory = () => {
  const navigate = useNavigate();
  const { ref, inView } = useInView();
  const size = useWindowSize();
  const {
    state: { selectedFilters },
    setBreedopediaFilters,
  } = useContext(BreedopediaFiltersState) as TBreedopediaFiltersContext;
  const [displayCategoryFilters, setDisplayCategoryFilters] = useState<boolean>(false);
  // const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [selectedBreed, setSelectedBreed] = useState<{ label: string; value: string } | null>(null);

  //Query hooks
  const { breedTags, isLoadingBreedTags } = useQueryFilters();
  const { breedsDropdown, isFetchingBreedsDropdown } = useQueryBreedsDropdown();
  const { status, data, isFetchingNextPage, fetchNextPage, hasNextPage } = useQueryBreedsList(
    selectedFilters,
    selectedBreed,
  );

  useEffect(() => {
    if (size.width > 768) {
      setDisplayCategoryFilters(true);
    } else if (size.width < 768) {
      setDisplayCategoryFilters(false);
    }
  }, [size.width]);

  useEffect(() => {
    if (!breedTags) return;

    const breedType = breedTags?.find(tag => tag.categoryName === 'Breed type');
    const purebredItem = breedType?.categoryTags?.find(cat => cat.slugName === 'purebred');

    if (purebredItem) {
      setBreedopediaFilters(['purebred']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breedTags]);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  return (
    <div className="breeed_directory_container">
      <div className="directory_heading">
        <div className="heading_left">
          <h1>Breedopedia</h1>
          <p>
            Browse TellTail’s robust breed library to learn more about hundreds of dog breeds.
            Select an individual breed or filter for what is important to you!
          </p>
          {isFetchingBreedsDropdown ? (
            <>
              <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
            </>
          ) : status === 'error' ? (
            <>
              <span>Something went wrong</span>
            </>
          ) : (
            <Select
              closeMenuOnSelect={true}
              isSearchable
              isClearable
              value={selectedBreed}
              onChange={val => setSelectedBreed(val)}
              options={breedsDropdown}
              placeholder="Select a breed"
              hideSelectedOptions={false}
              styles={selectStylesBreedDirectory}
              classNamePrefix="tt-select"
            />
          )}
        </div>
        <div className="heading_right">
          <img src={headerLogo} className="header_logo" />
        </div>
      </div>

      <div className="directory_list_content">
        <div className="directory_filters_mobile_display">
          <h5>Filter by category</h5>
          {displayCategoryFilters ? (
            <MdKeyboardArrowUp
              className="cursor-pointer"
              size={30}
              onClick={() => setDisplayCategoryFilters(!displayCategoryFilters)}
            />
          ) : (
            <MdKeyboardArrowDown
              className="cursor-pointer"
              size={30}
              onClick={() => setDisplayCategoryFilters(!displayCategoryFilters)}
            />
          )}
        </div>

        {displayCategoryFilters && (
          <Filters
            filterList={breedTags as IBreedsFiltersList[]}
            setSelectedFilters={setBreedopediaFilters}
            selectedFilters={selectedFilters}
            setSelectedBreed={setSelectedBreed}
          />
        )}

        <div className="directory_cards_letters_container">
          {status === 'loading' || isLoadingBreedTags ? (
            <>
              <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
            </>
          ) : status === 'error' ? (
            <>
              <span>Something went wrong</span>
            </>
          ) : (
            <>
              <h5 className="with_border">Breeds</h5>
              <div className="cards_container">
                {data?.pages?.map((page, index) => (
                  <Fragment key={index}>
                    {page.items.map((item: any) => (
                      <div
                        key={item._id}
                        className="card"
                        onClick={() => {
                          window.scrollTo({ top: 0 });
                          navigate(`/breedopedia/${item._id}`);
                        }}
                      >
                        <div className="image_container">
                          <img
                            src={item.profilePicture !== '' ? item.profilePicture : placeholderDog}
                          />
                        </div>
                        <h6>{item.breedName}</h6>
                      </div>
                    ))}
                  </Fragment>
                ))}
              </div>

              <div className="w-100 mx-auto text-center">
                <button
                  ref={ref}
                  onClick={() => fetchNextPage()}
                  disabled={!hasNextPage || isFetchingNextPage}
                  className="btn-loading"
                >
                  {isFetchingNextPage ? 'Loading more...' : hasNextPage ? 'Load Newer' : ''}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BreedDirectory;
