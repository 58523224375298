import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

//types
import { IAdminActionButton } from 'types';

const AdminActionButton = ({
  title,
  Icon,
  color = 'red',
  isLoading = false,
  disabled = false,
  onClick,
}: IAdminActionButton) => {
  if (isLoading)
    return (
      <AiOutlineLoading3Quarters
        size={20}
        color={color}
        style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}
        className="loading-animation svg-loading"
      />
    );
  else
    return (
      <Tooltip arrow placement="right" title={title}>
        <IconButton sx={{ color }} disabled={disabled} onClick={onClick}>
          {Icon}
        </IconButton>
      </Tooltip>
    );
};

export default AdminActionButton;
