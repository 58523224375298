/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { ChangeEvent, useState, useRef, useEffect, useMemo } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slide,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { TransitionProps } from '@mui/material/transitions';
import { Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Select from 'react-select';
import { some } from 'lodash';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import 'react-datepicker/src/stylesheets/datepicker.scss';
// @ts-ignore
import IntlTelInput from 'intl-tel-input/react/build/IntlTelInput.esm';
import 'intl-tel-input/build/css/intlTelInput.css';

//types
import { IDialogProps, ICreateEditPawfficeHourSchema } from 'types';
//hooks
import { useQueryUserList } from 'pages/AdminPanel/Users/__hooks/useQueryUserList';
import {
  useMutationCreateNewPawfficeHour,
  useMutationEditPawfficeHour,
} from '../__hooks/useMutationCreateEditNewPawfficeHour';
//components
import InfoView from 'components/common/InfoView';
import KitImg from 'components/common/KitImg';
//assets
import NoImageAvailable from 'assets/images/adminPanel/no-profile-pic.svg';
//constants
import validExtensions from 'constants/images/validExtensions';
import { numberValidationErrors } from 'constants/lists/numberValidationErrors';
//helpers
import { getImage } from 'helpers/getImage';
//validations
import { CreateNewPawfficHourSchema } from './Validation';
//styles
import { multiSelectStyles } from 'components/styled/select';
//services
import { getCloudflareUploadUrl, uploadImageForForms } from 'services/api/apiCalls/cloudflare';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateNewPawfficeHoursDialog = ({
  open,
  handleClose,
  visibilityOptions,
  knowledgeTagsOptions,
  pawfficeHourToEdit,
}: IDialogProps) => {
  const TAG_LIMIT = 3;
  const IMAGE_SIZE = 200;
  const errorSpanRef = useRef<HTMLDivElement | null>(null);
  const [pawfficeHourLogo, setPawfficeHourLogo] = useState<File | string | null>(null);
  const [errorCode, setErrorCode] = useState(0);

  const {
    register,
    watch,
    handleSubmit,
    setError,
    control,
    trigger,
    clearErrors,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ICreateEditPawfficeHourSchema>({
    resolver: yupResolver(CreateNewPawfficHourSchema),
  });

  const radioGroupDefaultValue = useMemo(() => {
    if (pawfficeHourToEdit && some(pawfficeHourToEdit.visibility, item => item.value === 'paid'))
      return 'paid';
    else if (
      pawfficeHourToEdit &&
      some(pawfficeHourToEdit.visibility, item => item.value === 'free')
    )
      return 'free';
    else return undefined;
  }, [pawfficeHourToEdit]);

  const handleDialogDismiss = () => {
    setPawfficeHourLogo(null);
    creatNewPawfficeHourResetApiState();
    editPawfficeHourResetApiState();
    handleClose();
  };

  const scrollToBottom = () =>
    errorSpanRef.current?.scrollIntoView({ inline: 'end', behavior: 'smooth' });

  //Query hooks
  const { data, refetchUserList } = useQueryUserList();
  //Mutation hooks
  const {
    createNewPawfficeHourMutate,
    isCreatingNewPawfficeHour,
    createNewPawfficeHourError,
    creatNewPawfficeHourResetApiState,
  } = useMutationCreateNewPawfficeHour(handleDialogDismiss, scrollToBottom);
  const {
    editPawfficeHourMutate,
    isEditingPawfficeHour,
    editPawfficeHourError,
    editPawfficeHourResetApiState,
  } = useMutationEditPawfficeHour(handleDialogDismiss, scrollToBottom);

  const hostDefaultValue = useMemo(() => {
    if (pawfficeHourToEdit && data) {
      const hostItem = data?.find(item => item._id === pawfficeHourToEdit.host);

      return hostItem ? `${hostItem?.displayName} (${hostItem?.email})` : pawfficeHourToEdit.host;
    } else return '';
  }, [pawfficeHourToEdit, data]);

  /*
   * Filling form with exiting pawffice hour which we want to edit
   */
  useEffect(() => {
    if (!pawfficeHourToEdit) {
      return setValue('visibility', [...[], { label: 'Admin', value: 'admin' }]);
    }

    reset({
      ...pawfficeHourToEdit,
      host: hostDefaultValue,
    });
    setPawfficeHourLogo(pawfficeHourToEdit.pawfficeImageUrl);
  }, [pawfficeHourToEdit, reset, setValue, setPawfficeHourLogo, hostDefaultValue]);

  /**
   * Refetch active users when dialog opens
   */
  useEffect(() => {
    if (open) refetchUserList();
  }, [open, refetchUserList]);

  const getButtonText = () => (pawfficeHourToEdit ? 'Update' : 'Create');

  /**
   * Pick image file from browser
   * */
  const handleClickBrowse = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;

    if (validExtensions.includes(file?.type)) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setValue('pawfficeImageUrl', file.name);
        clearErrors('pawfficeImageUrl');
        setPawfficeHourLogo(file);
      };
      fileReader.readAsDataURL(file);
    }
  };

  const setIsValid = (isValid: boolean) => {
    if (isValid) {
      clearErrors('dialNumber');
    } else {
      const errorMessage = numberValidationErrors[errorCode] || 'Invalid number';

      setError('dialNumber', {
        type: 'custom',
        message: errorMessage,
      });
    }
  };

  /**
   * handle form submission in case of add and edit pawffice hour
   *  */
  const onSubmit = async (creatEditPawfficeHourSchema: ICreateEditPawfficeHourSchema) => {
    const inputHostName = creatEditPawfficeHourSchema.host;
    const hostEmail = inputHostName.match(/\((.*)\)/)?.pop() || '';
    const hostDisplayName = inputHostName.replaceAll(` (${hostEmail})`, '');
    const host = data?.find(
      item => item.displayName === hostDisplayName && item.email === hostEmail,
    );

    const tags = creatEditPawfficeHourSchema.tags.map(item => item.value);
    const visibility = creatEditPawfficeHourSchema.visibility.map(item => item.value);
    const cloudflareUploadUrl = await getCloudflareUploadUrl();

    const pawfficeHourLogoUrl =
      cloudflareUploadUrl !== null && typeof pawfficeHourLogo !== 'string'
        ? await uploadImageForForms({
            pathAndName: 'vendor',
            image: pawfficeHourLogo!,
            url: cloudflareUploadUrl,
          })
        : pawfficeHourLogo;

    const payload = {
      pawfficeImageUrl: pawfficeHourLogoUrl as string,
      name: creatEditPawfficeHourSchema.name,
      host: host?._id,
      hostName: host ? host.displayName : inputHostName,
      description: creatEditPawfficeHourSchema.description,
      startDate: moment(creatEditPawfficeHourSchema.startDate).toISOString(),
      duration: {
        hours: creatEditPawfficeHourSchema.hours,
        minutes: creatEditPawfficeHourSchema.minutes,
      },
      call: {
        link: creatEditPawfficeHourSchema.link,
        dialNumber: creatEditPawfficeHourSchema.dialNumber,
        passcode: creatEditPawfficeHourSchema.passcode,
      },
      visibility,
      tags,
    };

    if (pawfficeHourToEdit) {
      editPawfficeHourMutate({ ...payload, _id: pawfficeHourToEdit._id });
    } else {
      createNewPawfficeHourMutate(payload);
    }
  };

  /**
   * Filter time slots which are before current time
   */
  const filterPassedTime = (time: Date) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="create-deal-dialog-slide-description"
    >
      <DialogTitle bgcolor="darkblue" color="white">
        Create New Pawffice Hour
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          width="50vw"
          height="70vh"
          m={2}
          position="relative"
        >
          <InfoView
            p={0}
            flex={1}
            label="Pawffice Hour Logo:"
            value={
              <Box display="flex" flexDirection="column" alignItems="center">
                <Card elevation={3}>
                  <KitImg
                    src={getImage(pawfficeHourLogo, NoImageAvailable)}
                    kitwidth={IMAGE_SIZE}
                    style={{
                      width: IMAGE_SIZE,
                      maxHeight: IMAGE_SIZE,
                      background: '#f5f5f5',
                      objectFit: 'contain',
                    }}
                  />
                </Card>
                {errors && errors.pawfficeImageUrl && (
                  <span className="text-error mt-2">{errors.pawfficeImageUrl.message}</span>
                )}

                <Box mt={2}>
                  <input
                    type="file"
                    id="admin_vendor_image"
                    accept="image/*"
                    onChange={handleClickBrowse}
                    onClick={event => {
                      (event.target as HTMLInputElement).value = ''; // Workarround to trigger onChange event for same file
                    }}
                    hidden
                  />
                  <label htmlFor="admin_vendor_image">
                    <Button variant="outlined" component="span">
                      {pawfficeHourLogo ? 'Change Logo' : 'Add Logo'}
                    </Button>
                  </label>
                </Box>
              </Box>
            }
            vertical
          />

          <Box mt={3}>
            <Form.Label>Pawffice Hour Name</Form.Label>
            <Form.Control
              bsPrefix="input form-control mb-0 py-3"
              placeholder="Enter pawffice hour name"
              {...register('name')}
            />
          </Box>
          {errors && errors.name && <span className="text-error mt-2">{errors.name.message}</span>}

          <Box mt={3}>
            <Form.Label>Host Name</Form.Label>
            <Autocomplete
              disablePortal
              id="host-autocomplete"
              options={data?.map(item => `${item.displayName} (${item.email})`) || []}
              freeSolo
              defaultValue={hostDefaultValue}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="standard"
                  color={errors.host?.message ? 'error' : 'primary'}
                  {...register('host')}
                />
              )}
            />
          </Box>
          {errors && errors.host && <span className="text-error mt-2">{errors.host.message}</span>}

          <InfoView
            p={0}
            mt={3}
            label="Description:"
            value={
              <TextareaAutosize
                minRows={4}
                maxRows={4}
                placeholder="Description"
                style={{ width: '100%', marginTop: 2, borderRadius: 5, padding: 5 }}
                {...register('description')}
              />
            }
            vertical
          />

          <InfoView
            sx={{ mt: 2 }}
            p={0}
            label="Start Date and Time:"
            value={
              <Controller
                control={control}
                name="startDate"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    selected={value ? moment(value).toDate() : null}
                    minDate={moment(
                      moment().utc().utcOffset('-0700').format('yyyy-MM-DD'),
                    ).toDate()}
                    onChange={(date: Date) => onChange(date)}
                    popperClassName="react-datepicker-popper red-border"
                    dateFormat="yyyy-MM-dd hh:mm a"
                    showTimeSelect
                    timeIntervals={15}
                    filterTime={filterPassedTime}
                    placeholderText="YYYY-MM-DD HH:mm a"
                    customInput={<Form.Control bsPrefix="input form-control mt-2" />}
                  />
                )}
              />
            }
            vertical
          />
          {errors && errors.startDate && (
            <span className="text-error mt-2">{errors.startDate.message}</span>
          )}

          <Box sx={{ bgcolor: '#f1f1f1', mt: 2, p: 1, borderRadius: 1 }}>
            <Typography variant="subtitle2" color="#333" fontWeight="bold">
              Duration:
            </Typography>
            <Box display="flex" flexDirection="row" gap="20px" mt={1}>
              <Box display="flex" flex={1} flexDirection="column">
                <Form.Control
                  type="number"
                  min={0}
                  max={23}
                  bsPrefix="input form-control mb-0 py-3"
                  placeholder="Enter hours"
                  {...register('hours')}
                />
                {errors && errors.hours && (
                  <span className="text-error mt-2">{errors.hours.message}</span>
                )}
              </Box>
              <Box display="flex" flex={1} flexDirection="column">
                <Form.Control
                  type="number"
                  min={0}
                  max={59}
                  bsPrefix="input form-control mb-0 py-3"
                  placeholder="Enter minutes"
                  {...register('minutes')}
                />
                {errors && errors.minutes && (
                  <span className="text-error mt-2">{errors.minutes.message}</span>
                )}
              </Box>
            </Box>
          </Box>

          <Box sx={{ bgcolor: '#f1f1f1', mt: 2, p: 1, borderRadius: 1 }}>
            <Typography variant="subtitle2" color="#333" fontWeight="bold">
              Meeting Info:
            </Typography>

            <Box display="flex" flex={1} flexDirection="column" mt={1}>
              <Form.Control
                bsPrefix="input form-control mb-0 py-3"
                placeholder="Enter meeting link"
                {...register('link')}
              />
              {errors && errors.link && (
                <span className="text-error mt-2">{errors.link.message}</span>
              )}
            </Box>

            <Box display="flex" flexDirection="row" gap="20px" mt={2}>
              <Box display="flex" flex={1} flexDirection="column">
                <Controller
                  control={control}
                  name={'dialNumber'}
                  render={({ field: { onChange, value } }) => (
                    <IntlTelInput
                      initialValue={value}
                      onChangeNumber={onChange}
                      onChangeValidity={setIsValid}
                      onChangeErrorCode={setErrorCode}
                      initOptions={{
                        initialCountry: 'us',
                        utilsScript:
                          'https://cdn.jsdelivr.net/npm/intl-tel-input@19.2.19/build/js/utils.js',
                      }}
                    />
                  )}
                />
                {errors && errors.dialNumber && (
                  <p className="text-error mb-2 text-error-number-verify">
                    {errors.dialNumber.message}
                  </p>
                )}
              </Box>

              <Box display="flex" flex={1} flexDirection="column">
                <Form.Control
                  bsPrefix="input form-control mb-0 py-3"
                  placeholder="Enter passcode"
                  {...register('passcode')}
                />
                {errors && errors.passcode && (
                  <span className="text-error mt-2">{errors.passcode.message}</span>
                )}
              </Box>
            </Box>
          </Box>

          <InfoView
            p={0}
            mt={3}
            label="Visibility:"
            value={
              <Box display="flex" flexDirection="row">
                {visibilityOptions
                  // Filtering Paid and Free roles
                  ?.filter(item => item.label !== 'Paid' && item.label !== 'Free')
                  ?.map((visibilityItem, idx) => (
                    <Controller
                      key={visibilityItem.value + idx}
                      control={control}
                      name="visibility"
                      render={({ field: { onChange, value } }) => (
                        <Box display="flex" alignItems="center">
                          <Checkbox
                            id={visibilityItem.value + idx}
                            key={open + ''}
                            defaultChecked={
                              pawfficeHourToEdit
                                ? pawfficeHourToEdit.visibility.some(
                                    item => item.value === visibilityItem.value,
                                  )
                                : visibilityItem.value === 'admin'
                            }
                            onChange={e => {
                              trigger('visibility');

                              return onChange(
                                e.target.checked
                                  ? [...(value || []), visibilityItem]
                                  : value?.filter(role => role.value !== visibilityItem.value),
                              );
                            }}
                          />
                          <label htmlFor={visibilityItem.value + idx}>{visibilityItem.label}</label>
                        </Box>
                      )}
                    />
                  ))}

                <Controller
                  control={control}
                  name="visibility"
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      aria-labelledby="paid-free-radio-group"
                      defaultValue={radioGroupDefaultValue}
                      row
                      key={open + ''}
                      onChange={e => {
                        const targetValue = e.target.value;
                        const excludeValue = targetValue === 'paid' ? 'free' : 'paid';
                        let tempValue = value || [];
                        tempValue = tempValue?.filter(role => role.value !== excludeValue);
                        tempValue = [
                          ...tempValue,
                          {
                            label: targetValue.charAt(0).toUpperCase() + targetValue.slice(1),
                            value: targetValue,
                          },
                        ];

                        return onChange(tempValue);
                      }}
                      sx={{ ml: 1.5 }}
                    >
                      <FormControlLabel
                        value="paid"
                        control={
                          <Radio icon={<CheckBoxOutlineBlank />} checkedIcon={<CheckBox />} />
                        }
                        label="Paid"
                      />
                      <FormControlLabel
                        value="free"
                        control={
                          <Radio icon={<CheckBoxOutlineBlank />} checkedIcon={<CheckBox />} />
                        }
                        label="Free"
                      />
                    </RadioGroup>
                  )}
                />
              </Box>
            }
            vertical
          />
          {errors && errors.visibility && (
            <span className="text-error mt-2">{errors.visibility.message}</span>
          )}

          <Box mt={2}>
            <Controller
              control={control}
              name="tags"
              render={({ field: { onChange, value } }) => (
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  isSearchable
                  value={value}
                  onMenuOpen={() => setTimeout(scrollToBottom, 100)}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(val: any, actionMeta: any) => {
                    if (
                      watch('tags') &&
                      watch('tags').length >= TAG_LIMIT &&
                      actionMeta.action === 'select-option'
                    ) {
                      setError('tags', {
                        type: 'custom',
                        message: ' The maximum number of tags you can select here is 3',
                      });
                    } else if (actionMeta.action === 'select-option') {
                      return onChange(val);
                    } else if (actionMeta.action === 'remove-value' && watch('tags').length <= 1) {
                      setError('tags', {
                        type: 'custom',
                        message: 'You must have at least one tag',
                      });
                    } else if (actionMeta.action === 'remove-value') {
                      trigger('tags');

                      return onChange(val);
                    }
                  }}
                  options={knowledgeTagsOptions}
                  placeholder="Enter tags here (E.g. IT/Software, Pet parent communications, etc.)"
                  styles={multiSelectStyles}
                  classNamePrefix="tt-select"
                />
              )}
            />

            {errors && errors.tags && (
              <span className="text-error mt-2">{errors.tags.message}</span>
            )}
          </Box>

          <span ref={errorSpanRef} className="text-error mt-4">
            {createNewPawfficeHourError?.response?.data.ExceptionMessage ||
              createNewPawfficeHourError?.message}
          </span>

          <span ref={errorSpanRef} className="text-error mt-4">
            {editPawfficeHourError?.response?.data.ExceptionMessage ||
              editPawfficeHourError?.message}
          </span>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogDismiss}>Cancel</Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitting || isCreatingNewPawfficeHour || isEditingPawfficeHour}
        >
          {isSubmitting || isCreatingNewPawfficeHour || isEditingPawfficeHour ? (
            <AiOutlineLoading3Quarters
              size={20}
              color="blue"
              style={{ margin: '0.2rem' }}
              className="loading-animation svg-loading"
            />
          ) : (
            getButtonText()
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewPawfficeHoursDialog;
