import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

//components
import Users from './Users';
import ReviewQueue from './ReviewQueue';
import Resources from './Resources';
import Comments from './Comments';
import RefurralCodes from './RefurralCodes';
import Deals from './Deals';
import PawfficeHours from './PawfficeHours';
import Shopify from './Shopify';
import Vouched from './Vouched';
import NonTTBreederForm from './NonTTBreederForm';
import BreedEncyclopaedia from './BreedEncyclopaedia';
import ConfirmationEmail from './ConfirmationEmail';
import MatchAlgorithmTest from './MatchAlgorithmTest';
//styles
import './index.scss';
//constants
import { adminPanelTabs } from 'constants/tabs';

const AdminPanel = () => {
  const [activeAdminTab, setActiveAdminTab] = useState(adminPanelTabs[0]);

  return (
    <div className="breederProfile">
      <div className="breederProfile__content px-4">
        <Row>
          <Col xs={12} lg={2} className="breeder__tabContainer">
            <div className="breeder__tabs__users">
              <ul>
                {adminPanelTabs.map((tab, index) => (
                  <li
                    className={`${activeAdminTab.name === tab.name && 'active'} tt-hover`}
                    onClick={() => setActiveAdminTab(tab)}
                    key={index}
                  >
                    <Link to={`/admin-panel/${tab.path}`}>{tab.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </Col>

          <Col xs={12} lg={10}>
            <div id="about" className="breederProfile__aboutMe">
              <div className="breederProfile__usersContainer">
                {activeAdminTab.path === 'users' && <Users />}
                {activeAdminTab.path === 'review_queue' && <ReviewQueue />}
                {activeAdminTab.path === 'resources' && <Resources />}
                {activeAdminTab.path === 'comments' && <Comments />}
                {activeAdminTab.path === 'refurrals' && <RefurralCodes />}
                {activeAdminTab.path === 'deals' && <Deals />}
                {activeAdminTab.path === 'pawffice' && <PawfficeHours />}
                {activeAdminTab.path === 'shopify' && <Shopify />}
                {activeAdminTab.path === 'vouched' && <Vouched />}
                {activeAdminTab.path === 'buyer-verified' && <NonTTBreederForm />}
                {activeAdminTab.path === 'breedopedia' && <BreedEncyclopaedia />}
                {activeAdminTab.path === 'confirmation-email' && <ConfirmationEmail />}
                {activeAdminTab.path === 'match-algorithm-testing' && <MatchAlgorithmTest />}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AdminPanel;
