/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import find from 'lodash/find';
import { useToasts } from 'react-toast-notifications';

//services
import { uploadImage } from 'services/api/apiCalls/cloudflare';
import { updateBreederGallery, deleteBreederGallery } from 'services/api/apiCalls/breeder';
//types
import { IFileUpload, TError, IImageUploadToServer, IBreederImageDelete } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useMutationUploadImagesToServer = () => {
  const { mutate: uploadImagesToServer, isLoading: isUploadingImagesToServer } = useMutation<
    AxiosResponse<any>,
    AxiosError<TError>,
    IImageUploadToServer
  >(updateBreederGallery);

  return { uploadImagesToServer, isUploadingImagesToServer };
};

export const useMutationUploadBreederGallery = (
  breederId: string,
  uploadImagesToServer: (data: IImageUploadToServer) => void,
) => {
  const { mutate: uploadBreederGallery, isLoading: isUploadingBreederGallery } = useMutation<
    AxiosResponse<any>,
    AxiosError<TError>,
    IFileUpload
  >(req => uploadImage(req), {
    onSuccess: async res => {
      const imageUrl = find(res?.data?.result?.variants, function (item) {
        return item.includes('/public');
      });

      uploadImagesToServer({ breederGallery: [imageUrl], id: breederId });
    },
  });

  return { uploadBreederGallery, isUploadingBreederGallery, isFinite };
};

export const useMutationDeleteBreederGallery = () => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const { mutate: deleteBreederImage, isLoading: isDeletingBreederImage } = useMutation<
    AxiosResponse<any>,
    AxiosError<TError>,
    IBreederImageDelete
  >(deleteBreederGallery, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.BREEDER, {
        refetchInactive: true,
      });
      addToast('Your image has been deleted!', {
        appearance: 'success',
        autoDismiss: true,
      });
    },
  });

  return { deleteBreederImage, isDeletingBreederImage };
};
