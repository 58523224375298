import './logrocketSetup';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

//components
import App from './App';
import ErrorBoundary from './components/common/ErrorBoundary';
//services
import AuthProvider from './services/auth';
import DevPanelProvider from './services/devPanel';
//styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <DevPanelProvider>
            <App />
          </DevPanelProvider>
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorBoundary>
  </BrowserRouter>,
);
