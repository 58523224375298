import React, { useState, useEffect, useMemo } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { AiOutlineArrowLeft, AiOutlineLoading3Quarters } from 'react-icons/ai';
import includes from 'lodash/includes';

//components
import HowSoon from './1_HowSoon';
import BreedsInProgram from './2_BreedsInProgram';
import Budget from './3_Budget';
import CreateAccount from './4_CreateAccount';
import CheckEmail from './5_CheckEmail';
import VerifyPhoneNumber from './6_VerifyPhoneNumber';
import SMSVerification from './7_SMSVerification';
import BuildProfle from './8_BuildProfile';
import BuyerActiveProfile from './BuyerActiveProfile';
import HearAboutUs from './9_HearAboutUs';
//helper
import {
  getBuyerProgressBarPercentageFor10Steps,
  getBuyerProgressBarPercentageFor9Steps,
} from 'helpers/getProgressBarPercentage';
//types
import { IUserInfo } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';
//hooks
import { useQueryBuyerInfo } from './__hooks/useQueryBuyerInfo';
//styles
import './index.scss';

export default function BuyerRegistration() {
  const location = useLocation();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const userInfo = queryClient.getQueryData(QUERY_KEYS.USER_STATUS) as {
    data: IUserInfo | undefined;
  };

  const firstPath = location.pathname.split('/')[1];
  const getStep = () => {
    if (firstPath === 'finish-buyer-registration') return 6;
    else if (firstPath === 'activate-profile') return 9;
    else return 1;
  };
  const [step, setStep] = useState(getStep());
  const screensWithoutBackButton = [firstPath === 'activate-profile' ? 9 : 6];

  const { errorBuyer, buyerInfo, isLoadingBuyer, isFetchingBuyer } = useQueryBuyerInfo();

  const hadTriggerMatchOption = buyerInfo?.hadTriggerMatchOption;

  const progressPercentage = useMemo(
    () =>
      hadTriggerMatchOption
        ? getBuyerProgressBarPercentageFor10Steps(step)
        : getBuyerProgressBarPercentageFor9Steps(step),
    [hadTriggerMatchOption, step],
  );

  useEffect(() => {
    location?.state?.from === 'get-certified' && setStep(hadTriggerMatchOption ? 10 : 9);
  }, [setStep, hadTriggerMatchOption, location]);

  useEffect(() => {
    if (
      searchParams.get('step') === null ||
      searchParams.get('step') !== userInfo?.data?.registrationStep.toString()
    ) {
      const newSearchParams = new URLSearchParams(searchParams.toString());
      userInfo?.data?.registrationStep
        ? newSearchParams.set('step', userInfo?.data?.registrationStep?.toString())
        : newSearchParams.set('step', step.toString());

      setSearchParams(newSearchParams);
    }
  }, [searchParams, setSearchParams, userInfo?.data?.registrationStep, step]);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set('step', step.toString());
    setSearchParams(newSearchParams);
  }, [step, setSearchParams, searchParams]);

  return (
    <div className="registration-container">
      <div className="stepper-container">
        {!includes(screensWithoutBackButton, step) && (
          <div
            className="stepper-container__left"
            onClick={() => {
              if (step === 1) {
                window.location.href = 'https://www.telltail.com/join';
              } else if (step === 8) {
                setStep(step - 2);
              } else {
                setStep(step - 1);
              }
            }}
          >
            <AiOutlineArrowLeft color="#CC7191" />
            Back
          </div>
        )}
        <div className="stepper-container__middle">
          <div className={`progress ${progressPercentage}`} />
        </div>
        <div className="stepper-container__right">Step {step}</div>
      </div>

      {step === 1 && <HowSoon step={step} setStep={setStep} />}
      {step === 2 && <BreedsInProgram step={step} setStep={setStep} />}
      {step === 3 && <Budget step={step} setStep={setStep} />}
      {step === 4 && <CreateAccount step={step} setStep={setStep} />}
      {step === 5 && <CheckEmail />}

      {isLoadingBuyer || isFetchingBuyer ? (
        <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
      ) : errorBuyer || buyerInfo === undefined ? null : (
        <>
          {step === 6 && <VerifyPhoneNumber step={step} setStep={setStep} buyerInfo={buyerInfo} />}
          {step === 7 && <SMSVerification step={step} setStep={setStep} buyerInfo={buyerInfo} />}
          {step === 8 && <BuildProfle step={step} setStep={setStep} buyerInfo={buyerInfo} />}
          {hadTriggerMatchOption ? (
            <>
              {step === 9 && (
                <BuyerActiveProfile step={step} setStep={setStep} buyerInfo={buyerInfo} />
              )}
              {step === 10 && <HearAboutUs step={step} setStep={setStep} buyerInfo={buyerInfo} />}
            </>
          ) : (
            <>{step === 9 && <HearAboutUs step={step} setStep={setStep} buyerInfo={buyerInfo} />}</>
          )}
        </>
      )}
    </div>
  );
}
