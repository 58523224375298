import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

//services
import { getPublicBreeder } from 'services/api/apiCalls/breeder';
import { getPublicBreederForAdmin } from 'services/api/apiCalls/admin';
//types
import { IBreederPublic } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useQueryPublicBreederInfo = (id: string, isFromAdmin: boolean) => {
  const {
    data,
    error: errorPublicBreeder,
    isLoading: isLoadingPublicBreeder,
    isFetching: isFetchingPublicBreeder,
  } = useQuery<AxiosResponse<IBreederPublic>, Error>(
    [isFromAdmin ? QUERY_KEYS.PUBLIC_BREEDER_FOR_ADMIN : QUERY_KEYS.PUBLIC_BREEDER, id],
    () => (isFromAdmin ? getPublicBreederForAdmin(id) : getPublicBreeder(id)),
    {
      retry: false,
    },
  );

  return {
    errorPublicBreeder,
    publicBreederInfo: data?.data,
    isLoadingPublicBreeder,
    isFetchingPublicBreeder,
  };
};
