/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useMemo } from 'react';
import { Dialog, Slider } from '@mui/material';
import { Col, Row, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Select, { SingleValue } from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import filter from 'lodash/filter';

//types
import {
  IAddLitterData,
  IAddLitterSchema,
  TSelectOptionsType,
  ISelectOption,
  GroupedOption,
} from 'types';
//components
import TTButton from 'components/common/TTButton';
import ToggleSwitch from 'components/common/ToggleSwitch';
import { addLitterSchema } from './Validation';
import GroupHeading from 'components/common/ReactSelectGroupHeading';
//hooks
import { useQueryBreedsWithSizeVariation } from 'pages/BreederRegistration/__hooks/useQueryBreedsWithSizeVariation';
//styles
import { selectStyles } from 'components/styled/select';
import { sliderStyle } from 'components/styled/slider';

const priceText = (value: number) => {
  return `$${value}`;
};

const weightText = (value: number) => {
  return `${value} lbs`;
};

const AddEditLitter = ({
  addLitterData,
  handleClose,
  onAddLitterClick,
  loading,
  breederBreeds,
}: {
  addLitterData: IAddLitterData | undefined;
  handleClose: () => void;
  onAddLitterClick: (litterData: IAddLitterSchema) => void;
  loading: boolean;
  breederBreeds: ISelectOption[];
}) => {
  const [priceRange, setPriceRange] = React.useState<number[]>([
    addLitterData?.litterData?.minPrice !== undefined ? addLitterData?.litterData?.minPrice : 1500,
    addLitterData?.litterData?.maxPrice !== undefined ? addLitterData?.litterData?.maxPrice : 4000,
  ]);
  const [weightRange, setWeightRange] = React.useState<number[]>([
    addLitterData?.litterData?.minWeight !== undefined ? addLitterData?.litterData?.minWeight : 40,
    addLitterData?.litterData?.maxWeight !== undefined ? addLitterData?.litterData?.maxWeight : 70,
  ]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    trigger,
    formState: { errors },
  } = useForm<IAddLitterSchema>({
    resolver: yupResolver(addLitterSchema),
    defaultValues: {
      goHomeDate: addLitterData?.litterData?.goHomeDate,
      minPrice: addLitterData?.litterData?.minPrice,
      maxPrice: addLitterData?.litterData?.maxPrice,
      minWeight: addLitterData?.litterData?.minWeight,
      maxWeight: addLitterData?.litterData?.maxWeight,
      acceptLeads: addLitterData?.litterData?.acceptLeads === false ? false : true,
    },
  });

  //Query hooks
  const { breeds, isLoadingBreeds } = useQueryBreedsWithSizeVariation();
  const breedsDropdown = useMemo(
    () =>
      breeds?.map((breed: { breedName: string }) => ({
        label: breed.breedName,
        value: breed.breedName,
      })),
    [breeds],
  );

  const breederBreedsValues = new Set(breederBreeds.map(item => item.value));
  const breedsWithoutBreederBreeds = filter(
    breedsDropdown,
    item => !breederBreedsValues.has(item.value),
  );
  const groupedOptions: readonly GroupedOption[] = [
    {
      label: 'Program breeds',
      options: breederBreeds,
    },
    {
      label: 'Other breeds',
      options: breedsWithoutBreederBreeds,
    },
  ];

  useEffect(() => {
    if (!breedsDropdown) return;

    if (addLitterData) {
      setValue('breedName', { label: addLitterData.breedName, value: addLitterData.breedName });
    } else {
      setValue('breedName', breedsDropdown[0]);
    }
  }, [breedsDropdown, addLitterData, setValue]);

  useEffect(() => {
    setValue('minPrice', priceRange[0]);
    setValue('maxPrice', priceRange[1]);
  }, [priceRange, setValue]);

  useEffect(() => {
    setValue('minWeight', weightRange[0]);
    setValue('maxWeight', weightRange[1]);
  }, [weightRange, setValue]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    const name = (event?.target as HTMLInputElement)?.name || '';
    if (name === 'price-range-slider') {
      setPriceRange(newValue as number[]);
      trigger('minPrice');
      trigger('maxPrice');
    } else if (name === 'weight-range-slider') {
      setWeightRange(newValue as number[]);
      trigger('minWeight');
      trigger('maxWeight');
    }
  };

  return (
    <Dialog maxWidth="lg" open={true} keepMounted onClose={handleClose} PaperProps={{ sx: null }}>
      <div className="add-litter">
        <Form id="breederRegistrationAddLitterForm">
          {isLoadingBreeds ? (
            <div className="align_center">
              <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
            </div>
          ) : (
            <>
              <div className="add-litter__top">
                <h4>Add or edit a litter</h4>
                <span>
                  Setting up a litter is currently required to match with buyers. Do your best to
                  estimate.
                </span>
              </div>
              <div className="add-litter__content">
                <div className="litter-info">
                  <span className="litter-info__heading">Litter information</span>

                  <Row className="w-100 ms-0">
                    <Col
                      xs={12}
                      md={6}
                      className={`${errors?.breedName?.label ? 'mb-0 input-error' : 'mb-4'}`}
                    >
                      <Form.Label className="form-labels">Breed of Litter*</Form.Label>
                      <Controller
                        control={control}
                        name={'breedName'}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            closeMenuOnSelect={true}
                            isSearchable
                            value={value}
                            onChange={(val: SingleValue<TSelectOptionsType>) => {
                              onChange(val);
                              trigger('breedName');
                            }}
                            options={groupedOptions}
                            placeholder="Choose from dropdown"
                            styles={selectStyles}
                            components={{ GroupHeading }}
                            classNamePrefix="tt-select"
                          />
                        )}
                      />
                      {errors?.breedName && (
                        <p className="text-error mb-2">{errors.breedName.label?.message}</p>
                      )}
                    </Col>

                    <Col
                      xs={12}
                      md={6}
                      className={`${errors.goHomeDate ? 'mb-0 input-error' : 'mb-4'} `}
                    >
                      <Form.Label className="form-labels">Estimated go-home date?*</Form.Label>
                      <Controller
                        control={control}
                        name="goHomeDate"
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            selected={value ? moment(value).toDate() : null}
                            minDate={moment().toDate()}
                            onChange={(date: Date) => {
                              onChange(date);
                              trigger('goHomeDate');
                            }}
                            popperClassName="react-datepicker-popper"
                            dateFormat="yyyy-MM-dd"
                            placeholderText="Enter go home date"
                            customInput={
                              <Form.Control
                                bsPrefix="input form-controls mb-0"
                                placeholder="Enter go home date"
                                {...register('goHomeDate')}
                              />
                            }
                          />
                        )}
                      />
                      {errors?.goHomeDate && (
                        <p className="text-error mb-2">{errors.goHomeDate.message}</p>
                      )}
                    </Col>
                  </Row>

                  <Row className="w-100 ms-0 d-flex align-items-center">
                    <Col xs={12} md={6}>
                      <p className="form-labels local-label">Price Range (in USDs)</p>
                    </Col>

                    <Col xs={12} md={6}>
                      <div className="d-flex align-items-center gap-2">
                        <Form.Label className="form-labels label-visibility mb-0">Min</Form.Label>
                        <div className="flex-fill">
                          <Controller
                            control={control}
                            name="minPrice"
                            render={({ field: { onChange, value } }) => (
                              <Form.Control
                                bsPrefix="input form-labels"
                                type="number"
                                value={value === 0 ? '' : value}
                                onChange={e => {
                                  onChange(e);
                                  trigger('minPrice');
                                  trigger('maxPrice');

                                  const inputVal = Number(e.target.value);
                                  const newPriceRange = [...priceRange];
                                  newPriceRange[0] = inputVal;
                                  setPriceRange(newPriceRange);
                                }}
                              />
                            )}
                          />
                        </div>
                        <div>-</div>
                        <Form.Label className="form-labels label-visibility mb-0">Max</Form.Label>
                        <div className="flex-fill">
                          <Controller
                            control={control}
                            name="maxPrice"
                            render={({ field: { onChange, value } }) => (
                              <Form.Control
                                bsPrefix="input form-labels"
                                type="number"
                                value={value === 0 ? '' : value}
                                onChange={e => {
                                  onChange(e);
                                  trigger('minPrice');
                                  trigger('maxPrice');

                                  const inputVal = Number(e.target.value);
                                  const newPriceRange = [...priceRange];
                                  newPriceRange[1] = inputVal;
                                  setPriceRange(newPriceRange);
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                      {errors?.minPrice && (
                        <p className="text-error mt-2">{errors.minPrice.message}</p>
                      )}
                      {errors?.maxPrice && (
                        <p className="text-error mt-2">{errors.maxPrice.message}</p>
                      )}
                    </Col>
                  </Row>

                  {/* Price Range Slider */}
                  <Slider
                    name="price-range-slider"
                    getAriaLabel={() => 'Price range'}
                    value={priceRange}
                    min={100}
                    max={10000}
                    step={100}
                    sx={sliderStyle}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    valueLabelFormat={value => <div className="thumb">${value}</div>}
                    getAriaValueText={priceText}
                  />

                  <Row className="w-100 ms-0 d-flex align-items-center">
                    <Col xs={12} md={6}>
                      <p className="form-labels local-label">Weight Range (in lbs)</p>
                    </Col>

                    <Col xs={12} md={6}>
                      <div className="d-flex align-items-center gap-2">
                        <Form.Label className="form-labels label-visibility mb-0">Min</Form.Label>
                        <div className="flex-fill">
                          <Controller
                            control={control}
                            name="minWeight"
                            render={({ field: { onChange, value } }) => (
                              <Form.Control
                                bsPrefix="input form-labels"
                                type="number"
                                value={value === 0 ? '' : value}
                                onChange={e => {
                                  onChange(e);
                                  trigger('minWeight');
                                  trigger('maxWeight');

                                  const inputVal = Number(e.target.value);
                                  const newWeightRange = [...weightRange];
                                  newWeightRange[0] = inputVal;
                                  setWeightRange(newWeightRange);
                                }}
                              />
                            )}
                          />
                        </div>
                        <div>-</div>
                        <Form.Label className="form-labels label-visibility mb-0">Max</Form.Label>
                        <div className="flex-fill">
                          <Controller
                            control={control}
                            name="maxWeight"
                            render={({ field: { onChange, value } }) => (
                              <Form.Control
                                bsPrefix="input form-labels"
                                type="number"
                                value={value === 0 ? '' : value}
                                onChange={e => {
                                  onChange(e);
                                  trigger('minWeight');
                                  trigger('maxWeight');

                                  const inputVal = Number(e.target.value);
                                  const newWeightRange = [...weightRange];
                                  newWeightRange[1] = inputVal;
                                  setWeightRange(newWeightRange);
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                      {errors?.minWeight && (
                        <p className="text-error mt-2">{errors.minWeight.message}</p>
                      )}
                      {errors?.maxWeight && (
                        <p className="text-error mt-2">{errors.maxWeight.message}</p>
                      )}
                    </Col>
                  </Row>

                  {/* Weight Range Slider */}
                  <Slider
                    name="weight-range-slider"
                    getAriaLabel={() => 'Price range'}
                    value={weightRange}
                    min={5}
                    max={200}
                    step={5}
                    sx={sliderStyle}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    valueLabelFormat={value => <div className="thumb">{value} lbs</div>}
                    getAriaValueText={weightText}
                  />
                </div>

                <div className="receive-leads">
                  <span className="receive-leads--text">Receive Leads for this Litter</span>
                  <div className="receive-leads__container">
                    <span className="receive-leads__container--text">
                      To stop receiving leads, toggle them off. You can toggle them back on at any
                      time
                    </span>
                    <ToggleSwitch
                      id={`add-litter-receive-leads-toggle-for-${addLitterData?.breedName}`}
                      name={`add-litter-receive-leads-toggle-for-${addLitterData?.breedName}`}
                      toggleBG="#142669"
                      onChange={e => setValue('acceptLeads', e.target.checked)}
                      checked={watch('acceptLeads')}
                    />
                  </div>
                </div>
              </div>
              <div className="add-litter__bottom">
                <TTButton
                  className="btn-primary-navy"
                  width="173px"
                  height="40px"
                  text="Cancel"
                  onClick={handleClose}
                />
                <TTButton
                  className="btn-primary-orchid"
                  width="173px"
                  height="40px"
                  text={addLitterData?.litterData ? 'Update' : 'Save'}
                  onClick={handleSubmit(onAddLitterClick)}
                  loading={loading}
                  disabled={loading}
                />
              </div>
            </>
          )}
        </Form>
      </div>
    </Dialog>
  );
};

export default AddEditLitter;
