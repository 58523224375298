export const basicPointsList = [
  'Preview of Puppy Buyer Matches',
  'Select Learning Resources',
  'Access to Monthly Newsletter and Private Facebook Group',
  'Exclusive Deals and Benefits',
  'Certified Breeder Badge',
  'Shareable Custom Profile & Social Media Content',
  'Community and Mentorship, with Access to Breeder Support Team',
  'Scam Protection',
  'Earn Money via TellTail’s Referral Program',
];

export const premiumPointsList = [
  'Unlimited Puppy Buyer Matches',
  'Exclusive Learning Resources',
  'Access to Monthly Newsletter and Private Facebook Group',
  'Exclusive Deals and Benefits',
  'Certified Breeder Badge',
  'Shareable Custom Profile & Social Media Content',
  'Community and Mentorship, with Access to Breeder Support Team',
  'Scam Protection',
  'Earn Money via TellTail’s Referral Program',
];
