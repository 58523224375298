import React from 'react';
import { Container } from 'react-bootstrap';

//components
import Banner from 'components/common/HeadingBanner';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfServices from './TermsOfServices';
//styles
import './index.scss';

export default function TermsAndPrivacy({ isTermsPage }: { isTermsPage?: boolean }) {
  return (
    <div className="termsandpolicy">
      <Banner
        title={isTermsPage ? 'Terms of Use' : 'Privacy Policy'}
        subTitle={
          <span>Last Updated: {isTermsPage ? 'January 16th, 2023' : 'January 16th, 2023'}</span>
        }
        bgColor="blue"
      />
      <Container className="termsandpolicy__container">
        {isTermsPage ? <TermsOfServices /> : <PrivacyPolicy />}
      </Container>
    </div>
  );
}
