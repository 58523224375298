import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { updateVideo } from 'services/api/apiCalls/admin';
//types
import { IAddNewVideoPayload, TError } from 'types';

const useMutationUpdateVideo = (handleDialogDismiss: () => void, scrollToBottom: () => void) => {
  const { addToast } = useToasts();

  const {
    mutate: updateVideoMutate,
    isLoading: updateVideoIsLoading,
    error: videoUpdateError,
    reset: resetApiStateUpdate,
  } = useMutation<AxiosResponse<string>, AxiosError<TError>, IAddNewVideoPayload>(
    data => updateVideo({ id: data._id!, videoPayload: data }),
    {
      onSuccess: () => {
        addToast('Video updated successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
        handleDialogDismiss();
      },
      onError: () => {
        scrollToBottom();
      },
    },
  );

  return {
    updateVideoMutate,
    updateVideoIsLoading,
    videoUpdateError,
    resetApiStateUpdate,
  };
};

export default useMutationUpdateVideo;
