import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function usePageTracking() {
  const location = useLocation();
  const lastPath = useRef('');

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    if (window.dataLayer && lastPath.current !== currentPath) {
      window.dataLayer.push({
        event: 'pageview',
        page: currentPath,
      });

      lastPath.current = currentPath;
    }
  }, [location]);
}

export default usePageTracking;
