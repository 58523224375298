import React from 'react';
import { isEmpty } from 'lodash';
import { Col, Row, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//validation
import { changePasswordSchema, validateChangePassword } from './Validation';
//components
import TTButton from 'components/common/TTButton';
//hooks
import { useMutationChangePassword } from './__hooks/useMutationChangePassword';
//types
import { IChangePasswordForm } from 'types';

const ChangePassword = () => {
  // Form handling
  const {
    register,
    handleSubmit,
    setError,
    getFieldState,
    formState: { errors },
  } = useForm<IChangePasswordForm>({
    resolver: yupResolver(changePasswordSchema),
  });

  // Mutation hooks
  const { mutate, isLoading: isUpdatingPassword } = useMutationChangePassword();

  const onSubmit = (data: IChangePasswordForm) => {
    validateChangePassword({
      newPassword: data.newPassword,
      confirmNewPassword: data.confirmNewPassword,
      setError,
    });

    if (
      !isEmpty(errors) ||
      !isEmpty(getFieldState('newPassword').error) ||
      !isEmpty(getFieldState('confirmNewPassword').error)
    ) {
      return errors;
    } else {
      mutate({
        newPassword: data.newPassword,
        oldPassword: data.oldPassword,
      });
    }
  };

  return (
    <div className="change_pass_container">
      <h4>Change password</h4>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="my_acc_form"
        id="breederProfileChangePasswordForm"
      >
        <Row className="mt-2">
          <Col xs={12} md={6} className={`${errors.oldPassword ? 'mb-0 input-error' : 'mb-4'} `}>
            <Form.Label className="form-labels">Old Password*</Form.Label>
            <Form.Control
              data-private
              bsPrefix="input form-control mb-0"
              type="password"
              placeholder="Old password"
              {...register('oldPassword')}
            />
            {errors && errors.oldPassword && (
              <p className="text-error mb-2">{errors.oldPassword.message}</p>
            )}
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6} className={`${errors.newPassword ? 'mb-0 input-error' : 'mb-4'} `}>
            <Form.Label className="form-labels">New Password*</Form.Label>
            <Form.Control
              data-private
              bsPrefix="input form-control mb-0"
              type="password"
              placeholder="New password"
              {...register('newPassword')}
            />
            {errors && errors.newPassword && (
              <p className="text-error mb-2">{errors.newPassword.message}</p>
            )}
          </Col>

          <Col
            xs={12}
            md={6}
            className={`${errors.confirmNewPassword ? 'mb-0 input-error' : 'mb-4'} `}
          >
            <Form.Label className="form-labels">Confirm New Password*</Form.Label>
            <Form.Control
              data-private
              bsPrefix="input form-control mb-0"
              type="password"
              placeholder="Confirm New password"
              {...register('confirmNewPassword')}
            />
            {errors && errors.confirmNewPassword && (
              <p className="text-error mb-2">{errors.confirmNewPassword.message}</p>
            )}
          </Col>
        </Row>

        {/* Buttons area */}
        <div className="d-flex w-100 mt-4  align-items-center justify-content-end">
          <TTButton
            type="submit"
            className="btn-primary-orchid save_btn"
            text="Save"
            fontSize="12px"
            borderRadius="10px"
            loading={isUpdatingPassword}
            width="100px"
            height="40px"
          />
        </div>
      </Form>
    </div>
  );
};
export default ChangePassword;
