import React, { useMemo } from 'react';
import { AiOutlineLoading3Quarters, AiOutlineArrowLeft } from 'react-icons/ai';
import { useParams, useNavigate } from 'react-router-dom';

//components
import DiscussionCard from './DiscussionCard';
//types
import { IConversation } from 'types';
//hooks
import useQueryGetConversationById from './__hooks/useQueryGetConversationById';
//styles
import './index.scss';

export const ActivityFeed = () => {
  const navigate = useNavigate();
  const { discussionId } = useParams() as { discussionId: string };

  //Query hooks
  const { isLoading, isFetching, isError, data } = useQueryGetConversationById(
    discussionId as string,
  );

  const discussion = useMemo(() => data, [data]);

  return (
    <div className="d-flex px-5 w-100 justify-content-center ">
      {isLoading ? (
        <>
          <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
        </>
      ) : isError ? (
        <>
          <span>Something went wrong</span>
        </>
      ) : (
        <div className="discuss-details-container">
          <div className="article__back" onClick={() => navigate('/activity-feed')}>
            <AiOutlineArrowLeft />
            Back
          </div>

          <div key={discussionId} className="feed-card-discussion no-max-height" id={discussionId}>
            <DiscussionCard
              item={discussion as unknown as IConversation}
              isReply={false}
              level={1}
              isFetching={isFetching}
            />
          </div>
          {/* <div>Deals and offers</div> */}
        </div>
      )}
    </div>
  );
};

export default ActivityFeed;
