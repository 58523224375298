import React from 'react';
import { Box, Typography } from '@mui/material';

//types
import { IHeading } from 'types';

const Heading = ({ text, ...props }: IHeading) => {
  return (
    <Box bgcolor="lightblue" py={1.2} pl={1} {...props}>
      <Typography color="white" fontSize="22px" fontWeight="bold" variant="subtitle1">
        {text}
      </Typography>
    </Box>
  );
};

export default Heading;
