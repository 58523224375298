import { TObject, IArrayOfObjects } from 'types';

export const discussionGroupsTypes = ['Programs', 'Locations', 'Breeds'];

export const learnResourcesTypes = [
  { label: 'videos', value: 'Videos' },
  { label: 'articles', value: 'Articles' },
  // { label: 'pawffice', value: 'Pawffice hours' },
];

export const activityFeedFilters = [
  {
    label: 'business',
    value: 'Business & Marketing',
    subFilters: [
      { label: 'accounting', value: 'Accounting & Finance' },
      { label: 'actors', value: 'Bad actors' },
      { label: 'shows', value: 'Dog Shows' },
      { label: 'software', value: 'IT/Software' },
      { label: 'legal', value: 'Legal' },
      { label: 'mentorship', value: 'Mentorship' },
      { label: 'marketing', value: 'Marketing & Social Media' },
      { label: 'payments', value: 'Pricing & Payments' },
      { label: 'management', value: 'Program Management' },
    ],
  },
  {
    label: 'homes',
    value: 'Forever Homes',
    subFilters: [
      { label: 'homeday', value: 'Go-Home Day' },
      { label: 'communications', value: 'Pet Parent Communications' },
      { label: 'rehoming', value: 'Re-Homing' },
    ],
  },
  {
    label: 'health',
    value: 'Health & Nutrition',
    subFilters: [
      { label: 'doghealth', value: 'Dog Health' },
      { label: 'genetics', value: 'Genetics' },
      { label: 'nutrition', value: 'Nutrition' },
      { label: 'predigree', value: 'Pedigree' },
      { label: 'vaccines', value: 'Vaccines' },
    ],
  },
  {
    label: 'because',
    value: 'Just Because',
    subFilters: [
      { label: 'intros', value: 'Breeders Intros' },
      { label: 'funny', value: 'Funny' },
      { label: 'random', value: 'Random' },
      { label: 'wins', value: 'Wins' },
    ],
  },
  {
    label: 'pregnancy',
    value: 'Pregnancy & Whelping',
    subFilters: [
      { label: 'dams', value: 'Dams' },
      { label: 'guardian', value: 'Guardian Homes' },
      { label: 'studs', value: 'Studs' },
      { label: 'whelping', value: 'Whelping' },
    ],
  },
  {
    label: 'care',
    value: 'Puppy Care',
    subFilters: [
      { label: 'enrichment', value: 'Canine Enrichment' },
      { label: 'socialization', value: 'Socialization' },
      { label: 'training', value: 'Training' },
      { label: 'testing', value: 'Testing' },
    ],
  },
];

export const activityFeedSubFiltersDependencies: TObject = {
  accounting: 'business',
  actors: 'business',
  shows: 'business',
  software: 'business',
  legal: 'business',
  mentorship: 'business',
  marketing: 'business',
  payments: 'business',
  management: 'business',

  homeday: 'homes',
  communications: 'homes',
  rehoming: 'homes',

  doghealth: 'health',
  genetics: 'health',
  nutrition: 'health',
  predigree: 'health',
  vaccines: 'health',

  intros: 'because',
  funny: 'because',
  random: 'because',
  wins: 'because',

  dams: 'pregnancy',
  guardian: 'pregnancy',
  studs: 'pregnancy',
  whelping: 'pregnancy',

  enrichment: 'care',
  socialization: 'care',
  training: 'care',
  testing: 'care',
};

export const activityFeedSubfiltersPerParent: IArrayOfObjects = {
  business: [
    'accounting',
    'actors',
    'shows',
    'software',
    'legal',
    'mentorship',
    'marketing',
    'payments',
    'management',
  ],
  homes: ['homeday', 'communications', 'rehoming'],
  health: ['doghealth', 'genetics', 'nutrition', 'predigree', 'vaccines'],
  because: ['intros', 'funny', 'random', 'wins'],
  pregnancy: ['dams', 'guardian', 'studs', 'whelping'],
  care: ['enrichment', 'socialization', 'training', 'testing'],
};
