import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

//services
import { getBreeder } from 'services/api/apiCalls/breeder';
//types
import { IBreeder } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useQueryAdminBreederInfo = (id: string) => {
  const {
    data: breederInfo,
    error: errorBreeder,
    isLoading: isLoadingBreeder,
    isFetching: isFetchingBreeder,
  } = useQuery<AxiosResponse<IBreeder>, Error>([QUERY_KEYS.ADMIN_BREEDER_INFO, id], () =>
    getBreeder(id),
  );

  return { errorBreeder, breederInfo: breederInfo?.data, isLoadingBreeder, isFetchingBreeder };
};
