import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { getAdminBreederLogsList, getAdminBuyerLogsList } from 'services/api/apiCalls/admin';
//types
import { IBreederLog, IBuyerLog, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useQueryGetLogList = (userId: string, isBuyer: boolean) => {
  const { error, data, isLoading } = useQuery<
    AxiosResponse<IBreederLog[] | IBuyerLog[]>,
    AxiosError<TError>
  >(
    [isBuyer ? QUERY_KEYS.ADMIN_BUYER_LOGS_LIST : QUERY_KEYS.ADMIN_BREEDER_LOGS_LIST, userId],
    isBuyer ? () => getAdminBuyerLogsList(userId) : () => getAdminBreederLogsList(userId),
  );

  return {
    error,
    logs: data?.data,
    isLoading,
  };
};

export default useQueryGetLogList;
