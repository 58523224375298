/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useInView } from 'react-intersection-observer';

//components
import ArticleCard from './ArticleCard';
import DiscussionCard from './DiscussionCard';
import PawfficeHours from './PawfficeHours';
import VideoCard from './VideoCard';
import NewConversationCard from './NewConversationCard';
import Filters from './Filters';
//types
import { IArticle, IConversation, IVideo, IParentFiltersState } from 'types';
//hooks
import { useQueryKnowledgeTags } from 'pages/BreederAccount/__hooks/useQueryKnowledgeTags';
import useQueryGetActivityFeed from './__hooks/useQueryActivityFeed';
import useQueryPawfficeHourList from './__hooks/useQueryPawfficeHourList';
//constants
import { activityFeedFilters } from 'constants/filters';
//styles
import './index.scss';

export const ActivityFeed = () => {
  const { ref, inView } = useInView();
  const [isFormOpened, setIsFormOpened] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [showWholeCard, setShowWholeCard] = useState<{ [key: string]: boolean }>({});
  const [isParentFilterSemiChecked, setIsParentFilterSemiChecked] = useState<IParentFiltersState>(
    {},
  );
  //Query hooks
  const { knowledgeTags, isLoadingKnowledgeTags } = useQueryKnowledgeTags();
  const { status, data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useQueryGetActivityFeed(selectedFilters);
  const { isLoading, pawfficeHours, error } = useQueryPawfficeHourList();

  const isPawfficeHoursEmpty = useMemo(() => isEmpty(pawfficeHours), [pawfficeHours]);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  const isElementOverflowing = (elementId: string) => {
    const element = document.getElementById(elementId);

    return element && element.scrollHeight > 600;
  };

  const knowledgeTagsOptions = knowledgeTags?.map(tag => ({ value: tag, label: tag }));

  return (
    <div className="d-flex gap-4 px-5">
      <Filters
        listOfFilters={activityFeedFilters}
        setSelectedFilters={setSelectedFilters}
        selectedFilters={selectedFilters}
        isParentFilterSemiChecked={isParentFilterSemiChecked}
        setIsParentFilterSemiChecked={setIsParentFilterSemiChecked}
      />

      <div className="feed-cards-container" style={{ maxWidth: isPawfficeHoursEmpty ? 900 : 630 }}>
        {status === 'loading' || isLoadingKnowledgeTags ? (
          <>
            <AiOutlineLoading3Quarters className="loading-animation svg-loading" />
          </>
        ) : status === 'error' ? (
          <>
            <span>Something went wrong</span>
          </>
        ) : (
          <>
            {/* Activity feed cards section */}
            <NewConversationCard
              isFormOpened={isFormOpened}
              setIsFormOpened={setIsFormOpened}
              knowledgeTagsOptions={knowledgeTagsOptions}
            />
            {data?.pages?.map((page, index) => (
              <Fragment key={index}>
                {page.items.map((item: any) => (
                  <div
                    key={item._id}
                    className={`feed-card ${showWholeCard[item._id] && 'no-max-height'}`}
                    id={item._id}
                  >
                    {item.itemType === 'article' && (
                      <ArticleCard
                        item={item.item as unknown as IArticle}
                        setShowWHoleCard={setShowWholeCard}
                        showWholeCard={showWholeCard}
                        itemId={item._id}
                        status={status}
                        isFetching={isFetching}
                      />
                    )}
                    {item.itemType === 'conversation' && (
                      <DiscussionCard
                        item={item.item as unknown as IConversation}
                        isReply={false}
                        level={1}
                        status={status}
                        isFetching={isFetching}
                        setShowWHoleCard={setShowWholeCard}
                        showWholeCard={showWholeCard}
                        itemId={item._id}
                        knowledgeTagsOptions={knowledgeTagsOptions}
                      />
                    )}
                    {item.itemType === 'video' && (
                      <VideoCard
                        item={item.item as unknown as IVideo}
                        setShowWHoleCard={setShowWholeCard}
                        showWholeCard={showWholeCard}
                        itemId={item._id}
                        status={status}
                        isFetching={isFetching}
                      />
                    )}

                    {isElementOverflowing(item._id) && !showWholeCard[item._id] && (
                      <div
                        className="read-more"
                        onClick={() => setShowWholeCard({ ...showWholeCard, [item._id]: true })}
                      >
                        Read more
                      </div>
                    )}
                  </div>
                ))}
              </Fragment>
            ))}

            <div>
              <button
                ref={ref}
                onClick={() => fetchNextPage()}
                disabled={!hasNextPage || isFetchingNextPage}
                className="btn-loading"
              >
                {isFetchingNextPage ? 'Loading more...' : hasNextPage ? 'Load Newer' : ''}
              </button>
            </div>
          </>
        )}
      </div>
      {!isPawfficeHoursEmpty && (
        <PawfficeHours isLoading={isLoading} data={pawfficeHours} error={error} />
      )}
    </div>
  );
};

export default ActivityFeed;
