import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { updateMatchAcceptanceByAdmin } from 'services/api/apiCalls/admin';
//types
import { IBuyerInfo, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useMutationUpdateMatchAcceptanceByAdmin = (buyerAccountId: string) => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation<AxiosResponse<IBuyerInfo>, AxiosError<TError>>(
    () => updateMatchAcceptanceByAdmin(buyerAccountId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QUERY_KEYS.BUYER_ACCOUNT_DETAILS_FOR_ADMIN, {
          refetchInactive: true,
        });
        addToast('Match preferences has been updated for this buyer!', {
          appearance: 'success',
          autoDismiss: true,
        });
      },
      onError: async () => {
        addToast('Something went wrong with your request.', {
          appearance: 'error',
          autoDismiss: true,
        });
      },
    },
  );
};
