import * as Yup from 'yup';

export const AboutProgramSchema = Yup.object().shape({
  breedsInProgram: Yup.array().required('This field is required.'),
  yearsOfExperience: Yup.string().required('This field is required.'),
  numberOfDams: Yup.object().required('This field is required.'),
  numberOfStuds: Yup.object().required('This field is required.'),
  damsLocation: Yup.object().required('This field is required.'),
  damsSleepLocation: Yup.object().when('damsLocation', damsLocation => {
    if (damsLocation && damsLocation.label === 'All in guardian homes') {
      return Yup.object().required('This field is required.');
    }
  }),
  doesUseStudServices: Yup.string().required('This field is required.').nullable(),
  doesSellStudServices: Yup.string().required('This field is required.').nullable(),
  youngestAgeSendToPetParents: Yup.object().required('This field is required.'),
  youngestAgeBreedNewDam: Yup.object().required('This field is required.'),
  timesBreedDamInEightteenMonthPeriod: Yup.object().required('This field is required.'),
  timesBreedDamInLifetime: Yup.object().required('This field is required.'),
  additionalInfo: Yup.string(),
  damsFood: Yup.string().required('This field is required.'),
  puppiesFood: Yup.string().required('This field is required.'),
  doesConductTests: Yup.string().required('This field is required.').nullable(),
  labs: Yup.string().when('doesConductTests', {
    is: 'YES',
    then: Yup.string().required('This field is required.'),
  }),
  healthTests: Yup.string().when('doesConductTests', {
    is: 'YES',
    then: Yup.string().required('This field is required.'),
  }),
  doesSocializePuppies: Yup.string().required('This field is required.').nullable(),
  socializeDescription: Yup.string(),
  doesVaccinatePuppies: Yup.string().required('This field is required.').nullable(),
  vaccinationDescription: Yup.string(),
  haveVeterinarian: Yup.string().required('This field is required.').nullable(),
  vetName: Yup.string(),
  vetEmail: Yup.string(),
  vetPhoneNumber: Yup.string().test(
    'vetPhoneNumber',
    'Please enter valid phone number!',
    async function (value) {
      if (value) {
        const schema = Yup.string().matches(
          /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/gm,
        );

        const result = schema.isValidSync(value);

        return result;
      }

      return true;
    },
  ),
});
