import React, { ReactNode } from 'react';
import { Document, Page, View, Image, Text, PDFViewer } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import moment from 'moment';

//styles
import styles from './styles';
//helpers
import { isJsonString } from 'helpers/string';
//assets
import TellTailBlue from 'assets/images/nonTTBreeder/TellTailBlue.png';
import VerifyIcon from 'assets/images/nonTTBreeder/tick.png';
import UnVerifiedIcon from 'assets/images/nonTTBreeder/close.png';
import Image1 from 'assets/images/nonTTBreeder/image1.png';

const ContentView = ({
  label,
  value,
  Icon,
  marginVertical = 2,
}: {
  label: string;
  value: string;
  Icon?: ReactNode | undefined;
  marginVertical?: number | string;
}) => {
  return (
    <View style={[styles.content, { marginVertical }]}>
      {Icon}
      <Text style={[styles.contentText, { fontWeight: 700 }]}>
        {label}
        {'  '}
      </Text>
      <Text style={[styles.contentText, { fontWeight: 300 }]}>{value}</Text>
    </View>
  );
};

const InstructionView = ({
  heading,
  text,
  bgColor,
}: {
  heading: string;
  text: string;
  bgColor: string;
}) => {
  return (
    <View style={[styles.instructionView, { backgroundColor: bgColor }]}>
      <Text style={[styles.instructionText, { fontWeight: 700 }]}>{heading}</Text>
      <Text style={[styles.instructionText, { fontWeight: 300, marginTop: 5 }]}>{text}</Text>
    </View>
  );
};

const NonTTBreederPDF = () => {
  const { content } = useParams();
  const decodedJSONString = window.atob(content as string);
  const isJSON = isJsonString(decodedJSONString);

  if (!isJSON)
    return (
      <div className="d-flex justify-content-center mt-3 mb-2">
        <p className="text-error">Something went wrong.</p>
      </div>
    );

  const {
    breederName,
    breederEmail,
    buyerName,
    buyerEmail,
    isIdentityVerified,
    isIdValid,
    wasSelfieValid,
    didIdSelfieMatch,
    didIdNameMatch,
  } = JSON.parse(decodedJSONString);

  return (
    <PDFViewer width="100%" height="1000px">
      <Document title={`Certificate for Non TT Breeders (${breederName})`}>
        <Page style={styles.body}>
          <View style={styles.headerView}>
            <Image style={styles.telltailLogo} src={TellTailBlue} />
            <View>
              <ContentView label="Breeder Name:" value={breederName} />
              <ContentView label="Breeder Email:" value={breederEmail} />
            </View>
          </View>
          <Text style={[styles.title, { marginTop: 15 }]}>Buyer Verified Report</Text>
          <View style={styles.divider} />
          <View style={{ marginTop: 10 }}>
            <ContentView label="As of:" value={moment(Date.now()).format('LL')} />
            <ContentView label="Buyer Name:" value={buyerName} />
            <ContentView label="Buyer Email:" value={buyerEmail} />
          </View>
          <View style={styles.vaidationInfoView}>
            <View style={{ flex: 1, justifyContent: 'space-around', paddingVertical: 15 }}>
              <ContentView
                label="Is identity verified?"
                value={isIdentityVerified ? 'Yes' : 'No'}
                Icon={
                  <Image
                    style={styles.contentIcon}
                    src={isIdentityVerified ? VerifyIcon : UnVerifiedIcon}
                  />
                }
              />
              <ContentView
                label="Is this ID valid?"
                value={isIdValid ? 'Yes' : 'No'}
                Icon={
                  <Image style={styles.contentIcon} src={isIdValid ? VerifyIcon : UnVerifiedIcon} />
                }
              />
              <ContentView
                label="Was selfie valid?"
                value={wasSelfieValid ? 'Yes' : 'No'}
                Icon={
                  <Image
                    style={styles.contentIcon}
                    src={wasSelfieValid ? VerifyIcon : UnVerifiedIcon}
                  />
                }
              />
              <ContentView
                label="Did ID match the selfie?"
                value={didIdSelfieMatch ? 'Yes' : 'No'}
                Icon={
                  <Image
                    style={styles.contentIcon}
                    src={didIdSelfieMatch ? VerifyIcon : UnVerifiedIcon}
                  />
                }
              />
              <ContentView
                label="Does name on ID match name provided?"
                value={didIdNameMatch ? 'Yes' : 'No'}
                Icon={
                  <Image
                    style={styles.contentIcon}
                    src={didIdNameMatch ? VerifyIcon : UnVerifiedIcon}
                  />
                }
              />
            </View>
            <View style={{ flex: 1 }}>
              <Image style={styles.image} src={Image1} />
            </View>
          </View>
          <Text style={styles.header}>What does this report mean:</Text>
          <View style={styles.divider} />
          <View style={{ flexDirection: 'row', gap: 10, marginTop: 10 }}>
            <InstructionView
              heading="Valid ID"
              text="The government-issued ID card submitted by this user is valid (not expired; is real) and was confirmed to exist in a government database."
              bgColor="#FDF5E2"
            />
            <InstructionView
              heading="Valid Selfie"
              text="The “selfie”  submitted by the applicant was valid. The photo was real, taken live, and does not appear to be stolen from another individual."
              bgColor="#E8F3F8"
            />
            <InstructionView
              heading="Verified Match"
              text="The government-issued ID and the selfie were a match, indicating that the applicant is the individual who holds the ID."
              bgColor="#D4E0DE"
            />
            <InstructionView
              heading="Buyer Verified"
              text="The identity of this individual has been verified, and this applicant appears to be who they say they are."
              bgColor="#F5E3E9"
            />
          </View>
          <Text style={styles.disclaimerText}>
            <Text style={[styles.disclaimerText, { fontWeight: 700 }]}>Disclaimer:</Text> The above
            named buyer (the “User”) has requested this service, and has asked us to share the
            results with the breeder named above, in connection with a potential future transaction.
            TellTail is offering certain verification related services, whereby a User chooses to
            submit to an identity verification process conducted by a third party business (in this
            case, Vouched.id) with whom we partner for such purpose. This identity verification
            process entails the User uploading an image of their government ID, which is then
            compared with real time images of the User, as well as available database information
            that the third party partner has access to (including address, email address, and phone
            number), in order to help create extra assurance that the User is in fact the person
            that they purport to be. This check also verifies that the government ID is valid and is
            associated with the applicant. The third party partner also ensures that the applicant
            does not appear in a known dark web database, which can be an indicator of fraudulent or
            nefarious behavior. This identity verification process does not entail a detailed
            background check or other more detailed investigations into the User, and the
            certification that we may offer is not intended as an endorsement of the trustworthiness
            (or of any other characteristic) of the User in question. The intent is for the identity
            verification service to serve as an indicator that the individual appears to be who they
            say they are. The decision to proceed with a transaction is always left to the breeder
            and prospective buyer.
          </Text>
          <Text style={styles.disclaimerText}>
            <Text style={[styles.disclaimerText, { fontWeight: 700 }]}>
              For more information, please reach out to us at
            </Text>{' '}
            <Text style={[styles.disclaimerText, { fontWeight: 900, textDecoration: 'underline' }]}>
              hello@telltail.com
            </Text>{' '}
            and read TellTail’s{' '}
            <Text style={[styles.disclaimerText, { fontWeight: 900, textDecoration: 'underline' }]}>
              Privacy Policy
            </Text>{' '}
            and{' '}
            <Text style={[styles.disclaimerText, { fontWeight: 900, textDecoration: 'underline' }]}>
              Terms of Use.
            </Text>
          </Text>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default NonTTBreederPDF;
