import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { updateShopifyUnprocessed } from 'services/api/apiCalls/admin';
//types
import { IUpdateShopifyUnprocessedOrdersPayload, TError } from 'types';

const useMutationUpdateShopifyUnprocessed = (handleDialogDismiss: () => void) => {
  const { addToast } = useToasts();

  const { mutate, isLoading, error, reset } = useMutation<
    AxiosResponse<string>,
    AxiosError<TError>,
    IUpdateShopifyUnprocessedOrdersPayload
  >(data => updateShopifyUnprocessed({ payload: data }), {
    onSuccess: () => {
      addToast('Breeder connected successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
      handleDialogDismiss();
    },
  });

  return {
    mutate,
    isLoading,
    error,
    reset,
  };
};

export default useMutationUpdateShopifyUnprocessed;
