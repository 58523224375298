import React, { useContext } from 'react';

//assets
import calendarImmediately from 'assets/images/registrationAndSignIn/calendarImmediately.png';
import calendarOneThreeMonths from 'assets/images/registrationAndSignIn/calendarOneThreeMonths.png';
import calendarNotSure from 'assets/images/registrationAndSignIn/calendarNotSure.png';
//types
import { TStepper, TAuthContext } from 'types';
//services
import { useDataLayer } from 'services/dataLayerContext';
import { AuthState } from 'services/auth';
//styles
import './index.scss';

export default function HowSoon({ setStep, step }: TStepper) {
  const pushToDataLayer = useDataLayer();
  const { setRegistrationInfo } = useContext(AuthState) as TAuthContext;

  const handleClick = (howSoon: 'immediate' | 'short' | 'unknown') => {
    pushToDataLayer({
      event: 'howSoonClick',
      componentName: 'How soon',
      howSoon,
    });
    setRegistrationInfo('howSoon', howSoon);

    setStep(step + 1);
  };

  return (
    <>
      <h2 className="centered">How soon are you looking to sell your puppies?</h2>
      <div className="calendar-container">
        <div className="calendar-square" onClick={() => handleClick('immediate')}>
          <img src={calendarImmediately} alt="calendarImmediately" />
          <h4>Immediately</h4>
        </div>
        <div className="calendar-square" onClick={() => handleClick('short')}>
          <img src={calendarOneThreeMonths} alt="calendarOneThreeMonths" />
          <h4>In 1-3 months</h4>
        </div>
        <div className="calendar-square" onClick={() => handleClick('unknown')}>
          <img src={calendarNotSure} alt="calendarNotSure" />
          <h4>Not Sure Yet</h4>
        </div>
      </div>
    </>
  );
}
