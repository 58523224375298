import { useMutation, useQueryClient } from 'react-query';
// import { PutObjectCommandInput } from '@aws-sdk/client-s3';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { archiveBreederMatch } from 'services/api/apiCalls/breeder';
//types
import { TError, IBreederMatchArchive } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useMutationArchiveMatch = () => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation<AxiosResponse<null>, AxiosError<TError>, IBreederMatchArchive>(
    archiveBreederMatch,
    {
      onSuccess: async (_, variables) => {
        if (variables.archived) {
          await queryClient.invalidateQueries([QUERY_KEYS.BREEDER_MATCHES, false], {
            refetchInactive: true,
          });
          await queryClient.invalidateQueries([QUERY_KEYS.BREEDER_MATCHES, true], {
            refetchInactive: true,
          });

          addToast('Your match is successfully archived!', {
            appearance: 'success',
            autoDismiss: true,
          });
        }
      },
      onError: async () => {
        addToast('Something went wrong with your request.', {
          appearance: 'error',
          autoDismiss: true,
        });
      },
    },
  );
};

export const useMutationUnarchiveMatch = () => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation<AxiosResponse<null>, AxiosError<TError>, IBreederMatchArchive>(
    archiveBreederMatch,
    {
      onSuccess: async (_, variables) => {
        if (!variables.archived) {
          await queryClient.invalidateQueries([QUERY_KEYS.BREEDER_MATCHES, true], {
            refetchInactive: true,
          });
          await queryClient.invalidateQueries([QUERY_KEYS.BREEDER_MATCHES, false], {
            refetchInactive: true,
          });

          addToast('Your match is successfully restored!', {
            appearance: 'success',
            autoDismiss: true,
          });
        }
      },
      onError: async () => {
        addToast('Something went wrong with your request.', {
          appearance: 'error',
          autoDismiss: true,
        });
      },
    },
  );
};
