import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
} from '@mui/material';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

//types
import { ISubscriptionEndSchema, IDialogProps } from 'types';
//hooks
import useMutationSubscriptionEnd from '../__hooks/useMutationSubscriptionEnd';
//validation
import { SubscriptionEndSchema } from './Validation';
//components
import CheckBox from 'components/common/CheckBox';

const SubscriptionEndDialog = ({
  open,
  handleClose,
  displayName,
  userId,
  subscriptionEndType,
}: IDialogProps) => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<ISubscriptionEndSchema>({
    resolver: yupResolver(SubscriptionEndSchema),
  });

  //Mutation hooks
  const { subscriptionEndMutate, isSubmitting } = useMutationSubscriptionEnd(
    setError,
    handleClose,
    subscriptionEndType!,
  );

  const onSubmit = (payload: ISubscriptionEndSchema) => {
    subscriptionEndMutate({
      note: payload.note,
      userId: userId!,
    });
  };

  const getTitle = () => {
    if (subscriptionEndType === 'downgrade-next') {
      return `Downgrade ${displayName}'s account.`;
    } else if (subscriptionEndType === 'downgrade-immediate') {
      return `Immediate downgrade ${displayName}'s account to basic.`;
    } else if (subscriptionEndType === 'close-account') {
      return `Close ${displayName}'s account.`;
    }
  };

  const getMessage = () => {
    if (subscriptionEndType === 'downgrade-next') {
      return `Are you sure you want to downgrade ${displayName}'s account to basic from next billing cycle?`;
    } else if (subscriptionEndType === 'downgrade-immediate') {
      return `Are you sure you want to immediately downgrade ${displayName}'s account to basic?`;
    } else if (subscriptionEndType === 'close-account') {
      return `Are you sure you want to close ${displayName}'s account?`;
    }
  };

  const getButtonText = () => {
    if (subscriptionEndType === 'downgrade-next') {
      return 'Downgrade account';
    } else if (subscriptionEndType === 'downgrade-immediate') {
      return 'Immediately Downgrade account';
    } else if (subscriptionEndType === 'close-account') {
      return 'Close account';
    }
  };

  return (
    <Dialog open={open} keepMounted aria-describedby="alert-dialog-slide-description" maxWidth="lg">
      <DialogTitle>{getTitle()}</DialogTitle>
      <DialogContent sx={{ overflow: 'hidden' }}>
        <Box display="flex" flexDirection="column" width="35vw">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Box display="flex" flexDirection="column" bgcolor="#f5f5f5" p={2} borderRadius={2}>
              <TextareaAutosize
                minRows={5}
                maxRows={5}
                placeholder="Enter Note"
                style={{ width: '100%', marginTop: 2, borderRadius: 5, padding: 5 }}
                {...register('note')}
              />

              {errors && errors.note && (
                <span className="text-error mt-2">{errors.note.message}</span>
              )}

              <CheckBox
                text={`${getMessage()}*`}
                id="isChecked"
                checkboxClassName="terms-checkbox mt-3"
                onChange={e => {
                  setValue('isChecked', e.target.checked);
                  trigger('isChecked');
                }}
              />

              {errors && errors.isChecked && (
                <p className="text-error mb-2">{errors.isChecked.message}</p>
              )}
            </Box>
          </Form>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          type="submit"
          variant="contained"
          color="error"
          disabled={isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          {isSubmitting ? (
            <AiOutlineLoading3Quarters
              size={20}
              color="red"
              style={{ margin: '0.2rem' }}
              className="loading-animation svg-loading"
            />
          ) : (
            getButtonText()
          )}
        </Button>
        <Button variant="contained" sx={{ bgcolor: '#ccc', color: 'black' }} onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionEndDialog;
