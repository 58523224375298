const ADMIN = {
  //getUserList
  USER_LIST: 'USER_LIST',
  //getBreederList
  BREEDER_LIST: 'BREEDER_LIST',
  //getBuyerList
  BUYER_LIST: 'BUYER_LIST',
  //getReviewQueueList
  REVIEW_QUEUE_LIST: 'REVIEW_QUEUE_LIST',
  //getPublicBreederForAdmin
  PUBLIC_BREEDER_FOR_ADMIN: 'PUBLIC_BREEDER_FOR_ADMIN',
  //getArticleList
  ARTICLE_LIST: 'ARTICLE_LIST',
  //getCommentList
  COMMENT_LIST: 'COMMENT_LIST',
  //getRefurralCodes
  REFURRAL_CODE_LIST: 'REFURRAL_CODE_LIST',
  //getRefurralCodeDetails
  REFURRAL_CODE_DETAILS: 'REFURRAL_CODE_DETAILS',
  //getAdminDealList
  ADMIN_DEAL_LIST: 'ADMIN_DEAL_LIST',
  //getAdminPawfficeHourList
  ADMIN_PAWFFICE_HOURS_LIST: 'ADMIN_PAWFFICE_HOURS_LIST',
  //getPawfficeHour
  PAWFFICE_HOUR: 'PAWFFICE_HOUR',
  //getVideo
  VIDEO_LIST: 'VIDEO_LIST',
  //getAdminBreederNoteList
  ADMIN_BREEDER_NOTE_LIST: 'ADMIN_BREEDER_NOTE_LIST',
  //getAdminBuyerNoteList
  ADMIN_BUYER_NOTE_LIST: 'ADMIN_BUYER_NOTE_LIST',
  //getAdminBreederLogsList
  ADMIN_BREEDER_LOGS_LIST: 'ADMIN_BREEDER_LOGS_LIST',
  //getAdminBuyerLogsList
  ADMIN_BUYER_LOGS_LIST: 'ADMIN_BUYER_LOGS_LIST',
  //getShopifyUnprocessed
  SHOPIFY_UNPROCESSED_LIST: 'SHOPIFY_UNPROCESSED_LIST',
  //getUnvouchedUsers
  UNVOUCHED_BREEDERS_LIST: 'UNVOUCHED_BREEDERS_LIST',
  //getResourceArticles
  RESOURCE_ARTICLES: 'RESOURCE_ARTICLES',
  //getBreedsForAdmin
  ADMIN_GET_BREEDS: 'ADMIN_GET_BREEDS',
  //getBreedDetailsForAdmin
  ADMIN_GET_BREED_DETAILS: 'ADMIN_GET_BREED_DETAILS',
  //getAdminFilterDropdowns
  ADMIN_ENCYCLOPAEDIA_FILTERS_DROPDOWNS: 'ADMIN_ENCYCLOPAEDIA_FILTERS_DROPDOWNS',
  //getBuyerDetailsForAdmin
  BUYER_ACCOUNT_DETAILS_FOR_ADMIN: 'BUYER_ACCOUNT_DETAILS_FOR_ADMIN',
  //getBreeder
  ADMIN_BREEDER_INFO: 'ADMIN_BREEDER_INFO',
};

export default ADMIN;
