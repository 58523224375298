import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

//types
import { IAlertDialog } from 'types';

export default function AlertDialog({
  isOpened,
  dialogContent,
  isLoading,
  handleClose,
  onAgree,
}: IAlertDialog) {
  return (
    <div>
      <Dialog
        open={isOpened}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogContent?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogContent?.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleClose}>
            No
          </Button>

          <Button variant="contained" disabled={isLoading} onClick={onAgree} autoFocus>
            {isLoading ? (
              <AiOutlineLoading3Quarters
                size={20}
                color="blue"
                style={{ margin: '0.2rem' }}
                className="loading-animation svg-loading"
              />
            ) : (
              'Yes'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
