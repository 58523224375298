import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { sendCodeToPhone, verifySMSCode } from 'services/api/apiCalls/buyer';
//types
import { IBuyerInfo, TError, IStepper } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useMutationSendCodeToPhone = ({ step, setStep }: IStepper) => {
  const { addToast } = useToasts();

  return useMutation<AxiosResponse<boolean>, AxiosError<TError>, string>(sendCodeToPhone, {
    onSuccess: async () => {
      window.scrollTo({ top: 0 });
      setStep(step + 1);
    },

    onError: async () => {
      addToast('Something went wrong with your request.', {
        appearance: 'error',
        autoDismiss: true,
      });
    },
  });
};

export const useMutationVerifySMSCode = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { addToast } = useToasts();

  return useMutation<AxiosResponse<IBuyerInfo>, AxiosError<TError>, string>(verifySMSCode, {
    onSuccess: async () => {
      window.scrollTo({ top: 0 });

      navigate('/profile/information');
      window.location.reload();

      await queryClient.invalidateQueries(QUERY_KEYS.BUYER_ACCOUNT_DETAILS, {
        refetchInactive: true,
      });
    },

    onError: async () => {
      addToast('Something went wrong with your request.', {
        appearance: 'error',
        autoDismiss: true,
      });
    },
  });
};
