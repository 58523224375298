import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { getDealDetails } from 'services/api/apiCalls/deals';
//types
import { TError, IDeal } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useQueryGetDealDetails = (dealId: string) => {
  const { data, isLoading, error } = useQuery<AxiosResponse<IDeal>, AxiosError<TError>>(
    [QUERY_KEYS.GET_DEAL_DETAILS, dealId],
    () => getDealDetails(dealId),
  );

  return {
    deal: data?.data,
    isLoading,
    error,
  };
};

export default useQueryGetDealDetails;
