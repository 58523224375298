//services
import API from 'services/api';

//types
import { IUpdateUserRolesPayload } from 'types';

// User Roles
export const getUserRoles = () => API.get('/user/roles');
export const updateUserRoles = (userId: string, updateUserRolesPayload: IUpdateUserRolesPayload) =>
  API.put(`/user/roles/${userId}`, updateUserRolesPayload);
