import * as Yup from 'yup';

export const NewConversationSchema = Yup.object().shape({
  title: Yup.string()
    .max(140, 'This field only has 140 char limits.')
    .required('This field is required.'),
  contents: Yup.string().required('This field is required.'),
  tags: Yup.array().min(1, 'This field is required.').default([]),
  isEmailNotification: Yup.boolean().default(true),
});
