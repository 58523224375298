//services
import API from 'services/api';
//types
import {
  IAboutProgramPayload,
  IAboutBusinessPayload,
  IAboutEducationPayload,
  ICreateUserProfilePayload,
} from 'types';

export const createAboutProgram = (data: IAboutProgramPayload) =>
  API.post('/application/program', data);
export const updateAboutBusiness = ({ payload, breederId }: IAboutBusinessPayload) =>
  API.put(`/application/business/${breederId}`, payload);
export const updateAboutEducation = ({ payload, breederId }: IAboutEducationPayload) =>
  API.put(`/application/education/${breederId}`, payload);
export const updateUserProfile = ({ payload, breederId }: ICreateUserProfilePayload) =>
  API.put(`/application/account/${breederId}`, payload);
export const acceptTermsOfServices = () => API.put('/application/tos');
export const setVouchFlagged = (reasonForFlag: string) =>
  API.put('/application/vouch-flagged', { reasonForFlag });
