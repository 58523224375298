import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { getBreedsForAdmin } from 'services/api/apiCalls/admin';
//types
import { IBreedEncyclopaediaResponse, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useQueryAdminBreedsList = () => {
  const {
    isError,
    data,
    isLoading,
    isFetching,
    refetch: refetchBreedsList,
  } = useQuery<AxiosResponse<IBreedEncyclopaediaResponse>, AxiosError<TError>>(
    QUERY_KEYS.ADMIN_GET_BREEDS,
    () => getBreedsForAdmin({ page: 1, limit: 1000000 }),
  );

  return {
    isError,
    data: data?.data?.items,
    total: Number(data?.data?.meta.itemsCount),
    isLoading,
    isFetching,
    refetchBreedsList,
  };
};
