import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { updateBuyerDetailsByAdmin } from 'services/api/apiCalls/admin';
//types
import { IBuyerInfo, TError, IBuyerSettingsPayload } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useMutationUpdateBuyerSettingsByAdmin = (buyerAccountId: string) => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation<
    AxiosResponse<IBuyerInfo>,
    AxiosError<TError>,
    { data: IBuyerSettingsPayload }
  >(data => updateBuyerDetailsByAdmin(buyerAccountId, data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.BUYER_ACCOUNT_DETAILS_FOR_ADMIN, {
        refetchInactive: true,
      });
      addToast('Buyer info updated successfully!', {
        appearance: 'success',
        autoDismiss: true,
      });
    },
    onError: async () => {
      addToast('Something went wrong with your request.', {
        appearance: 'error',
        autoDismiss: true,
      });
    },
  });
};
