import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { getResourceArticles } from 'services/api/apiCalls/admin';
//types
import { IResources, TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useQueryResourceArticles = () => {
  const {
    error: errorArticles,
    data,
    isLoading: isLoadingArticles,
  } = useQuery<AxiosResponse<IResources>, AxiosError<TError>>(QUERY_KEYS.RESOURCE_ARTICLES, () =>
    getResourceArticles({ page: 1, limit: 1000000, filter: 'articles' }),
  );

  return {
    errorArticles,
    articles: data?.data?.items,
    isLoadingArticles,
  };
};
