export const youngestAgeBreedNewDam = [
  'Less than 6 months old',
  'Between 6 - 11 months old',
  'Between 12 - 17 months old',
  'Between 18 - 23 months old',
  'Between 24 - 29 months old',
  'Between 30 - 35 months old',
  'Between 3 - 4 years old',
  'Between 4 - 5 years old',
  '6+ years old',
].map(ageRange => ({ label: ageRange, value: ageRange }));
