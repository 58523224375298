/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { Button, DialogActions } from '@mui/material';
import { IoIosClose } from 'react-icons/io';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import { isEmpty } from 'lodash';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

//constants
import { breedingClubs } from 'constants/dropdowns/breedingClubs';
//validation
import { LearnAndConnectSchema } from './Validation';
//types
import { IAboutEducationForm, IUpdateBreeder } from 'types';
//hooks
import useMutationLearnAndConnect from './__hooks/useMutationLearnAndConnect';
//styles
import { multiSelectStyles } from 'components/styled/select';
import './index.scss';

const LearnAndConnect = ({ breeder, setShowSuccess, onCancelPress }: IUpdateBreeder) => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm<IAboutEducationForm>({
    resolver: yupResolver(LearnAndConnectSchema),
    defaultValues: !isEmpty(breeder)
      ? {
          haveCompletedBreederPrograms:
            breeder?.isCompletedBreederEducation === true ? 'YES' : 'NO',
          breederPrograms: breeder?.educationPrograms && breeder?.educationPrograms.join(','),
          belongsToBreedingClubs: breeder?.isBelongToClub === true ? 'YES' : 'NO',
          breedingClubs:
            breeder?.breederClubs &&
            breeder?.breederClubs.map(item => ({ label: item, value: item })),
          breedingProgramWebsite: breeder.breedingProgramWebsite,
          facebookUrl: breeder.facebookUrl,
          instagramUrl: breeder.instagramUrl,
          twitterUrl: breeder.twitterUrl,
          youtubeUrl: breeder.youtubeUrl,
          tikTokUrl: breeder.tikTokUrl,
          facebookGroups: breeder?.facebookGroups && breeder?.facebookGroups.join(','),
        }
      : {},
  });

  //Mutation hooks
  const { isLoading, mutate } = useMutationLearnAndConnect({
    setShowSuccess,
    setError,
  });

  const onSubmit: SubmitHandler<IAboutEducationForm> = data => {
    const payload = {
      breederEducationDetail: '',
      isCompletedBreederEducation: data.haveCompletedBreederPrograms === 'YES' ? true : false,
      educationPrograms: data.breederPrograms
        ? data.breederPrograms.split(',').map(val => val)
        : [],
      isBelongToClub: data.belongsToBreedingClubs === 'YES' ? true : false,
      breederClubs: data.breedingClubs ? data.breedingClubs.map(val => val.label) : [],
      breedingProgramWebsite: data.breedingProgramWebsite || '',
      facebookUrl: data.facebookUrl || '',
      instagramUrl: data.instagramUrl || '',
      twitterUrl: data.twitterUrl || '',
      youtubeUrl: data.youtubeUrl || '',
      tikTokUrl: data.tikTokUrl || '',
      facebookGroups: data.facebookGroups ? data.facebookGroups.split(',').map(val => val) : [],
    };

    mutate({ payload, breederId: breeder._id });
  };

  return (
    <>
      <div className="editUserProfile__inputs">
        <Form onSubmit={handleSubmit(onSubmit)} id="learn-and-connect-form">
          {/* HAVE YOU COMPLETED ANY SECTION */}
          <Row>
            <h2 className="mt-0">Have you completed any...</h2>
            <Col
              xs={12}
              className={`${errors.haveCompletedBreederPrograms ? 'mb-0 input-error' : 'mb-4'}`}
            >
              <h4 className="mt-0">
                Breeder educational programs (i.e. Puppy Culture, Midwoofery, AKC Canine College
                courses, Avidog, etc.)?*
              </h4>
              <div className="editUserProfile__listInput">
                <label
                  className="editUserProfile__radio"
                  htmlFor={'haveCompletedBreederProgramsYes'}
                >
                  YES
                  <input
                    type="radio"
                    value={'YES'}
                    id="haveCompletedBreederProgramsYes"
                    {...register('haveCompletedBreederPrograms')}
                  />
                  <span></span>
                </label>
                <label
                  className="editUserProfile__radio"
                  htmlFor={'haveCompletedBreederProgramsNo'}
                >
                  NO
                  <input
                    type="radio"
                    value={'NO'}
                    id="haveCompletedBreederProgramsNo"
                    {...register('haveCompletedBreederPrograms')}
                  />
                  <span></span>
                </label>
              </div>
              {errors && errors.haveCompletedBreederPrograms && (
                <p className="text-error mb-2">{errors.haveCompletedBreederPrograms.message}</p>
              )}

              {watch('haveCompletedBreederPrograms') === 'YES' && (
                <Row>
                  <Col
                    xs={12}
                    md={8}
                    className={`${errors.breederPrograms ? 'mb-0 input-error' : 'mb-4'}`}
                  >
                    <Form.Label className="sublabel">
                      (Enter the names separated by commas)
                    </Form.Label>
                    <Form.Control
                      bsPrefix="input form-control mb-0"
                      type="text"
                      placeholder="Enter groups*"
                      {...register('breederPrograms')}
                    />
                  </Col>
                  {errors && errors.breederPrograms && (
                    <p className="text-error mb-2">{errors.breederPrograms.message}</p>
                  )}

                  <ul className="editUserProfile__tags pl-2">
                    {watch('breederPrograms') &&
                      watch('breederPrograms')
                        ?.split(',')
                        .map((item: string, idx) => (
                          <li key={idx}>
                            {item}
                            <IoIosClose
                              className="signupOnboading__deleteIcon"
                              onClick={() => {
                                const currentValue = watch('breederPrograms')?.split(',');
                                const newValue = currentValue?.filter(value => value !== item);
                                setValue('breederPrograms', newValue?.toString());
                              }}
                            />
                          </li>
                        ))}
                  </ul>
                </Row>
              )}
            </Col>

            {/* DO YOU BELONG TO ANY SECTION */}
            <h2 className="mt-3">Do you belong to any...</h2>
            <Col
              xs={12}
              className={`${errors.belongsToBreedingClubs ? 'mb-0 input-error' : 'mb-4'} `}
            >
              <h4 className="mt-0">Breeding clubs or organizations?*</h4>
              <div className="editUserProfile__listInput">
                <label className="editUserProfile__radio" htmlFor={'belongsToBreedingClubsYes'}>
                  YES
                  <input
                    type="radio"
                    value="YES"
                    id="belongsToBreedingClubsYes"
                    {...register('belongsToBreedingClubs')}
                  />
                  <span></span>
                </label>
                <label className="editUserProfile__radio" htmlFor={'belongsToBreedingClubsNo'}>
                  NO
                  <input
                    type="radio"
                    value="NO"
                    id="belongsToBreedingClubsNo"
                    {...register('belongsToBreedingClubs')}
                  />
                  <span></span>
                </label>
              </div>
              {errors && errors.belongsToBreedingClubs && (
                <p className="text-error mb-2">{errors.belongsToBreedingClubs.message}</p>
              )}
            </Col>

            {watch('belongsToBreedingClubs') === 'YES' && (
              <>
                <h2 className="mt-3">Choose Clubs or Organizations</h2>
                <Col xs={12}>
                  <Form.Group className={`${errors.breedingClubs ? 'mb-0 input-error' : 'mb-4'}`}>
                    <Controller
                      control={control}
                      name="breedingClubs"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          closeMenuOnSelect={false}
                          isMulti
                          isSearchable
                          value={value}
                          onChange={(val: any) => onChange(val)}
                          options={breedingClubs}
                          placeholder={
                            isEmpty(value)
                              ? 'Select clubs or organizations*'
                              : `${value?.length} option${
                                  value?.length && value?.length > 1 && 's'
                                } selected`
                          }
                          styles={multiSelectStyles}
                          classNamePrefix="tt-select"
                        />
                      )}
                    />
                  </Form.Group>
                  {errors && errors.breedingClubs && (
                    <span className="mb-2 text-error">{errors.breedingClubs.message}</span>
                  )}
                </Col>
              </>
            )}

            {/* WHAT IS YOUR SECTION */}
            <h2 className="mt-3">What is your...</h2>
            <Col
              xs={12}
              md={6}
              className={`${errors.breedingProgramWebsite ? 'mb-0 input-error' : 'mb-4'}`}
            >
              <Form.Group>
                <Form.Label>Breeding program website?</Form.Label>
                <Form.Control
                  bsPrefix="input form-control mb-0"
                  type="text"
                  placeholder="Enter Website"
                  {...register('breedingProgramWebsite')}
                />
              </Form.Group>
              {errors && errors.breedingProgramWebsite && (
                <p className="text-error mb-2">{errors.breedingProgramWebsite.message}</p>
              )}
            </Col>

            {/* SOCIAL MEDIA SECTION */}
            <Col xs={12}>
              <h2 className="mt-3">What Social Media do you use?</h2>
              <div className="d-flex flex-column editUserProfile__socialMedia">
                <Col xs={12} md={8} className="mb-3">
                  <Form.Label>What breeder groups do you participate in on Facebook?</Form.Label>
                  <Form.Label className="sublabel">(Enter text separated by commas)</Form.Label>
                  <Form.Control
                    bsPrefix="input form-control w-full"
                    type="text"
                    placeholder="Enter Group"
                    {...register('facebookGroups')}
                  />
                </Col>

                <ul className="editUserProfile__tags">
                  {watch('facebookGroups') &&
                    watch('facebookGroups')
                      ?.split(',')
                      .map((item: string, idx) => (
                        <li key={idx}>
                          {item}
                          <IoIosClose
                            className="signupOnboading__deleteIcon"
                            onClick={() => {
                              const currentValue = watch('facebookGroups')?.split(',');
                              const newValue = currentValue?.filter(value => value !== item);
                              setValue('facebookGroups', newValue?.toString());
                            }}
                          />
                        </li>
                      ))}
                </ul>

                <Col xs={12} md={8} className="d-flex align-items-center mb-3 mt-4">
                  <h4>Facebook</h4>
                  <Form.Control
                    bsPrefix="input form-control mb-0"
                    type="text"
                    placeholder="Enter URL"
                    {...register('facebookUrl')}
                  />
                </Col>

                <Col xs={12} md={8} className="d-flex align-items-center mb-3">
                  <h4 className="network-label">Instagram</h4>
                  <Form.Control
                    bsPrefix="input form-control mb-0"
                    type="text"
                    placeholder="Enter URL"
                    {...register('instagramUrl')}
                  />
                </Col>

                <Col xs={12} md={8} className="d-flex align-items-center mb-3">
                  <h4 className="network-label">Twitter</h4>
                  <Form.Control
                    bsPrefix="input form-control mb-0"
                    type="text"
                    placeholder="Enter URL"
                    {...register('twitterUrl')}
                  />
                </Col>

                <Col xs={12} md={8} className="d-flex align-items-center mb-3">
                  <h4 className="network-label">YouTube</h4>
                  <Form.Control
                    bsPrefix="input form-control mb-0"
                    type="text"
                    placeholder="Enter URL"
                    {...register('youtubeUrl')}
                  />
                </Col>

                <Col xs={12} md={8} className="d-flex align-items-center mb-3">
                  <h4 className="network-label">TikTok</h4>
                  <Form.Control
                    bsPrefix="input form-control mb-0"
                    type="text"
                    placeholder="Enter URL"
                    {...register('tikTokUrl')}
                  />
                </Col>
              </div>
            </Col>
          </Row>
        </Form>
      </div>

      <DialogActions
        sx={{ position: 'absolute', left: 0, bottom: 0, width: '100%', background: 'white' }}
      >
        <Button onClick={onCancelPress}>Close</Button>
        <Button disabled={isLoading} onClick={handleSubmit(onSubmit)}>
          {isLoading ? (
            <AiOutlineLoading3Quarters
              size={20}
              color="blue"
              style={{ margin: '0.2rem' }}
              className="loading-animation svg-loading"
            />
          ) : (
            'Update'
          )}
        </Button>
      </DialogActions>
    </>
  );
};

export default LearnAndConnect;
