import React, { useState, useEffect } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import html2canvas from 'html2canvas';
import QRCodeStyling from 'qr-code-styling';

//types
import { IDialogProps } from 'types';
//constants
import { montWidths } from 'constants/montWidths';
//helpers
import { splitStringInTwo, stringPadStartAndEnd } from 'helpers/string';
import { blobToDataURL } from 'helpers/blob';
//assets
import { CertifiedBadgeWithQR } from 'assets/images/SFC/CertifiedBadgeWithQR';
import paw from 'assets/images/SFC/paw.png';

const DownloadBreederBadge = ({ open, handleClose, existingBreeder }: IDialogProps) => {
  const badgeName = existingBreeder?.badgeDetailsId?.nameOnBadge || '';
  const badgeNumber = existingBreeder?.badgeDetailsId?.badgeNumber || '';
  const [qrCodeDataURL, setQrCodeDataURL] = useState<string | ArrayBuffer | null>(null);

  useEffect(() => {
    const getQRCodeRawData = async () => {
      const qrCode = new QRCodeStyling({
        width: 700,
        height: 700,
        image: paw,
        data: `https://match.telltail.com/public-profile/${existingBreeder?._id}`,
        type: 'svg',
        dotsOptions: {
          color: '#142669',
          type: 'rounded',
        },
        imageOptions: {
          imageSize: 0.4,
          crossOrigin: 'anonymous',
          margin: 20,
        },
      });

      const rawData = (await qrCode.getRawData()) as Blob;

      blobToDataURL(rawData, function (dataurl: string | ArrayBuffer | null) {
        setQrCodeDataURL(dataurl);
      });
    };

    getQRCodeRawData();
  }, [existingBreeder]);

  const handleDownloadBadgeImage = async (elementId: string, breederName: string) => {
    const element = document.getElementById(elementId) as HTMLElement;

    const canvas = await html2canvas(element, {
      backgroundColor: 'none',
      ignoreElements: (el: Element) => {
        return el.id === `btn_sfc_download_${elementId}`;
      },
      scale: 6,
    });

    const dataURL = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = `${breederName.replace(/ /g, '')}_${elementId}.png`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Dialog open={open} keepMounted aria-describedby="alert-dialog-slide-description" maxWidth="lg">
      <DialogTitle>Certification badge for {existingBreeder?.displayName}</DialogTitle>
      <DialogContent sx={{ overflow: 'hidden' }}>
        <Box display="flex" flexDirection="column" width="500px" alignItems="center" my={3}>
          <div className="certification_badge">
            <div id="certifiedBadge" className="download_materials_image">
              <CertifiedBadgeWithQR
                text={badgeName}
                textUpperWithPad={stringPadStartAndEnd(
                  splitStringInTwo(badgeName.toUpperCase(), montWidths).firstString,
                  19,
                )}
                textLowerWithPad={
                  splitStringInTwo(badgeName.toUpperCase(), montWidths).secondString === ''
                    ? ''
                    : stringPadStartAndEnd(
                        splitStringInTwo(badgeName.toUpperCase(), montWidths).secondString,
                        19,
                        true,
                      )
                }
                badgeNumber={badgeNumber}
                qrCodeDataURL={qrCodeDataURL || ''}
              />
            </div>
          </div>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => handleDownloadBadgeImage('certifiedBadge', 'certified_badge')}>
          Download
        </Button>
        <Button sx={{ color: 'red' }} onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadBreederBadge;
