import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

//types
import { TBuyerStepper } from 'types';
//hooks
import { useMutationActivateBuyerProfile } from './__hooks/useMutationActivateBuyerProfile';
//components
import TTButton from 'components/common/TTButton';
//assets
import Rachel from 'assets/images/registrationAndSignIn/rachel.png';
import Kjristen from 'assets/images/registrationAndSignIn/kjristen.png';
import Jennifer from 'assets/images/registrationAndSignIn/jennifer.png';
import BuyerDummy from 'assets/images/registrationAndSignIn/buyer-dummy.svg';
import Arrows from 'assets/images/registrationAndSignIn/arrows.svg';

const BreederCard = ({
  className,
  profilePic,
  name,
  location,
}: {
  className?: string;
  profilePic: string;
  name: string;
  location: string;
}) => (
  <div className={`breeders__breeder-card ${className}`}>
    <img src={profilePic} alt="Rachel M." />
    <span className="breeders__breeder-card__name">{name}</span>
    <span className="breeders__breeder-card__location">{location}</span>
  </div>
);

const BuyerActiveProfile = ({ setStep, step, buyerInfo }: TBuyerStepper) => {
  const navigate = useNavigate();
  const triggeredMatching = useMemo(() => buyerInfo.triggeredMatching, [buyerInfo]);
  const skipText = useMemo(
    () =>
      triggeredMatching
        ? "You've already activated your profile. Please go to next step >>"
        : 'Skip for now',
    [triggeredMatching],
  );
  const skipHearAboutUsStep = useMemo(() => buyerInfo.howDidYouHearAboutUs !== '', [buyerInfo]);

  const handleNextDestination = () => {
    if (skipHearAboutUsStep) navigate('/profile');
    else setStep(step + 1);
  };

  //Mutation hooks
  const { mutate, isLoading } = useMutationActivateBuyerProfile(handleNextDestination);

  return (
    <div className="buyer-active-profile">
      <h2 className="centered">Let our best Certified Breeders find you immediately</h2>
      <span>
        When you activate your TellTail profile, our Certified Breeders will begin reaching out to
        you.
      </span>
      <div className="breeders">
        <div className="breeders__arrows">
          <img src={Arrows} alt="arrows" />
        </div>
        <BreederCard
          className="breeders__breeder-card--margin"
          profilePic={Rachel}
          name="Rachel M."
          location="California"
        />
        <BreederCard profilePic={Kjristen} name="Kjristen B." location="Colorado" />
        <BreederCard
          className="breeders__breeder-card--margin"
          profilePic={Jennifer}
          name="Jennifer R."
          location="Indiana"
        />
      </div>
      <div className="content">
        <img src={BuyerDummy} alt="buyer-dummy" />
        {!triggeredMatching && (
          <TTButton
            className="btn-primary-orchid active-profile-button"
            text="Activate my TellTail Profile"
            width="280px"
            height="45px"
            disabled={isLoading}
            loading={isLoading}
            onClick={mutate}
          />
        )}
        <span onClick={handleNextDestination}>{skipText}</span>
      </div>
    </div>
  );
};

export default BuyerActiveProfile;
