import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { shopifyAutoLinking } from 'services/api/apiCalls/auth';
//types
import { IDataLayerObject, TError } from 'types';

const useMutationShopifyAutoLink = (
  setIsConfirmed: (isConfirmed: boolean) => void,
  pushToDataLayer: (obj: IDataLayerObject) => void,
) => {
  const { mutate: mutateShopifyAutoLinking, isLoading: isConfirming } = useMutation<
    AxiosResponse<string>,
    AxiosError<TError>,
    string
  >(orderId => shopifyAutoLinking({ orderId }), {
    onSuccess: () => {
      setIsConfirmed(true);
    },
    onError: error => {
      // Push dataLayer event
      pushToDataLayer({
        event: 'shopifyTimeout',
        componentName: 'Shopify Success',
        error,
      });
    },
    retry: 24,
    retryDelay: 5000,
  });

  return {
    mutateShopifyAutoLinking,
    isConfirming,
  };
};

export default useMutationShopifyAutoLink;
