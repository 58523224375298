/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

//types
import { IBreeder } from 'types';
//components
import TTButton from 'components/common/TTButton';
//assets
import giftCard from 'assets/images/breederProfile/giftCard.svg';

const MyReferrals = ({
  isSfcBreeder,
  breederInfo,
}: {
  isSfcBreeder: boolean;
  breederInfo: IBreeder;
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    // @ts-ignore
    window.RF = {
      user: {
        first_name: breederInfo.firstName,
        last_name: breederInfo.lastName,
        email: breederInfo.email,
      },
    };
    const existingScript = document.getElementById('ZUwxag');

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://referral-factory.com/assets/js/popup.js?code=ZUwxag';
      script.id = 'ZUwxag';
      script.setAttribute('data-code', 'ZUwxag');
      script.className = 'rf-popup-script';
      script.async = true;

      document.body.appendChild(script);
    }
    // return () => {
    //   script && document?.body?.removeChild(script);
    // };
  }, [breederInfo.email, breederInfo.firstName, breederInfo.lastName]);

  return (
    <div className="referral-offer">
      {isSfcBreeder ? (
        <>
          <div className="referral-offer-text">
            Refer a friend to TellTail and if they go Pro, you both get $25 gift cards to Amazon or
            Petco.
          </div>
          <img src={giftCard} className="referral-offer-img" />

          <div className="referral-terms">
            <a
              target="_blank"
              href="https://www.telltail.com/referral-program-terms-and-conditions"
              rel="noreferrer"
            >
              Additional terms apply
            </a>
          </div>

          <TTButton
            type="submit"
            className="btn-primary-orchid rf-popup-ZUwxag"
            text="Refer a Friend"
            height="40px"
            fontSize="12px"
            borderRadius="10px"
          />
        </>
      ) : (
        <>
          <div className="referral-offer-text">
            TellTail Pro members can earn $25 gift cards for referring a friend to TellTail Pro.
            <br />
            <div className="referral-offer-subtext">
              When you are Pro and you refer a friend to become Pro, you both get $25 gift cards to
              Amazon or Petco.
            </div>
          </div>
          <img src={giftCard} className="referral-offer-img" />

          <TTButton
            type="submit"
            className="btn-primary-orchid"
            text="Upgrade to TellTail Pro"
            height="40px"
            fontSize="12px"
            borderRadius="10px"
            onClick={() => navigate('/get-certified', { replace: true })}
          />
        </>
      )}
    </div>
  );
};
export default MyReferrals;
