import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import moment from 'moment';
import parse from 'html-react-parser';
import { isEmpty } from 'lodash';

//types
import { IDeal } from 'types';
//components
import InfoView from 'components/common/InfoView';
import TagItem from 'components/common/TagItem';

const SubHeading = ({ text }: { text: string }) => {
  return (
    <Box bgcolor="pink" py={1} pl={2}>
      <Typography variant="subtitle1" color="#333" fontWeight="bold">
        {text}
      </Typography>
    </Box>
  );
};

const DealDetails = ({ deal }: { deal: IDeal }) => {
  if (!deal) return <></>;

  return (
    <Box display="flex" maxWidth="100vw">
      {/* Left Side View */}
      <Box flex={1} flexDirection="column" maxWidth="50%">
        <SubHeading text="Deal Information" />
        <InfoView label="Deal Id:" value={deal._id} />
        <InfoView
          label="Deal Picture:"
          value={
            !isEmpty(deal.dealPicture) ? (
              <Box display="flex">
                <Card sx={{ width: '75%', aspectRatio: '1:1' }} elevation={3}>
                  <img
                    style={{ width: '100%', height: 300, objectFit: 'contain' }}
                    alt="Program Picture"
                    src={deal.dealPicture}
                  />
                </Card>
              </Box>
            ) : (
              <p className="mt-0">No photo found.</p>
            )
          }
        />
        <InfoView label="Deal Title:" value={deal.dealTitle} />
        <InfoView
          label="Deal Description:"
          value={parse(
            (deal.dealDescription || 'N/A')
              .replace(/href/g, "target='_blank' href")
              .replaceAll('/tr:w-300', '/tr:w-2000'),
          )}
        />
        <InfoView label="Discount Code:" value={deal.discountCode} />
        <InfoView
          label="Visibility:"
          value={
            <ul className="breederProfile__tags mt-0">
              {deal.visibility.map((visibility, idx) => (
                <TagItem key={visibility + idx} name={visibility.toUpperCase()} />
              ))}
            </ul>
          }
        />
      </Box>

      <Box sx={{ width: 20 }} />

      {/* Right Side View */}
      <Box flex={1} flexDirection="column" maxWidth="50%">
        <SubHeading text="Vendor Information" />

        <InfoView
          label="Vendor Logo:"
          value={
            !isEmpty(deal.dealPicture) ? (
              <Box display="flex">
                <Card sx={{ width: '75%', aspectRatio: '1:1' }} elevation={3}>
                  <img
                    style={{ width: '100%', height: 300, objectFit: 'contain' }}
                    alt="Program Picture"
                    src={deal.vendorLogo}
                  />
                </Card>
              </Box>
            ) : (
              <p className="mt-0"> No photo found.</p>
            )
          }
        />
        <InfoView label="Vendor Name:" value={deal.vendorName} />
        <InfoView label="Start Date:" value={moment(deal.startDate).format('LL')} />
        <InfoView
          label="End Date:"
          value={deal.endDate ? moment(deal.endDate).format('LL') : 'N/A'}
        />
        <InfoView
          label="Tags:"
          value={
            <ul className="breederProfile__tags mt-0">
              {deal.tags.map((tag, idx) => (
                <TagItem key={tag + idx} name={tag.toUpperCase()} />
              ))}
            </ul>
          }
        />
      </Box>
    </Box>
  );
};

export default DealDetails;
