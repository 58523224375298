import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { getBuyerList } from 'services/api/apiCalls/admin';
//types
import { IAdminBuyersResponse, TPaginationState, TError, TColumnFilterState } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useQueryBuyerList = (
  status: string,
  pagination: TPaginationState,
  columnFilters: TColumnFilterState[],
  enabled: boolean,
) => {
  const {
    isError: isBuyerListError,
    data,
    isLoading: isLoadingBuyerList,
    refetch: refetchBuyerList,
    isFetching: isFetchingBuyerList,
  } = useQuery<AxiosResponse<IAdminBuyersResponse>, AxiosError<TError>>(
    [
      QUERY_KEYS.BUYER_LIST,
      status,
      pagination.pageIndex,
      pagination.pageSize,
      JSON.stringify(columnFilters),
    ],
    () => {
      const filtersObj = columnFilters.reduce(
        (acc, { id, value }) => ({ ...acc, [id]: value }),
        {},
      );

      return getBuyerList({
        page: pagination.pageIndex + 1,
        limit: pagination.pageSize,
        ...filtersObj,
      });
    },
    {
      enabled,
      keepPreviousData: true,
    },
  );

  return {
    isBuyerListError,
    buyers: data?.data?.items,
    totalBuyers: Number(data?.data?.meta.totalItems),
    isLoadingBuyerList,
    refetchBuyerList,
    isFetchingBuyerList,
  };
};
