export const howDidYouHearAboutUs = [
  'Email',
  'Facebook',
  'TikTok',
  'Podcast',
  'Instagram',
  'Google Search',
  'Article I read',
  'Through another organization / association',
  'Other',
].map(option => ({ label: option, value: option }));
