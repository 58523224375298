import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { deleteLitter } from 'services/api/apiCalls/litters';
//types
import { TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useMutationDeleteLitter = () => {
  const queryClient = useQueryClient();

  const { mutate: deleteLitterMutate, isLoading: isDeleting } = useMutation<
    AxiosResponse<string>,
    AxiosError<TError>,
    string
  >(litterId => deleteLitter(litterId), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.LITTER_LIST, {
        refetchInactive: true,
      });
    },
  });

  return {
    deleteLitterMutate,
    isDeleting,
  };
};
