import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { setBreederSawOffer } from 'services/api/apiCalls/auth';
//types
import { TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

export const useMutationBreederHasSeenOffer = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError<TError>>(setBreederSawOffer, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.USER_STATUS, {
        refetchInactive: true,
      });
    },
  });
};
