import * as Yup from 'yup';

export const CreateNewPawfficHourSchema = Yup.object().shape({
  pawfficeImageUrl: Yup.string().required('This field is required.'),
  name: Yup.string()
    .max(140, 'This field only has 140 char limits.')
    .required('This field is required.'),
  host: Yup.string().required('This field is required.'),
  description: Yup.string().max(140, 'This field only has 140 char limits.'),
  startDate: Yup.date().required('This field is required.'),
  hours: Yup.number()
    .min(1, 'Hours can not be less than 1.')
    .max(20, 'Hours can not be more than 20.')
    .typeError('This field is required.')
    .required('This field is required.'),
  minutes: Yup.number()
    .max(59, 'Minutes can not be more than 59.')
    .typeError('This field is required.')
    .required('This field is required.'),
  link: Yup.string().required('This field is required.'),
  dialNumber: Yup.string()
    .test('dialNumber', 'Please enter valid phone number!', function (value) {
      if (value) {
        const schema = Yup.string().matches(/^\+[1-9]\d{1,14}$/);
        const result = schema.isValidSync(value);

        return result;
      }

      return true;
    })
    .required('This field is required.'),
  passcode: Yup.string().required('This field is required.'),
  visibility: Yup.array().min(1, 'This field is required.').default([]),
  tags: Yup.array().min(1, 'This field is required.').default([]),
});
