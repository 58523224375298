/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useContext, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

//styles
import './index.scss';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';
//assets
import buyerOffer from 'assets/images/registrationAndSignIn/buyerOffer.png';
//components
import TTButton from 'components/common/TTButton';
//types
import { TAuthContext, IUserInfo } from 'types';
//services
import { AuthState } from 'services/auth';
//hooks
import { useMutationBuyerHasSeenOffer } from './__hooks/useMutationBuyerHasSeenOffer';

const BuyerOffer = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    state: { email },
  } = useContext(AuthState) as TAuthContext;

  const encodedEmail = encodeURIComponent(email);

  const userInfo = queryClient.getQueryData(QUERY_KEYS.USER_STATUS) as { data: IUserInfo };
  //Mutation hooks
  const { mutate: setBuyerSawOffer } = useMutationBuyerHasSeenOffer();

  useEffect(() => {
    userInfo?.data?.buyerSawOffer === false && setBuyerSawOffer();
  }, [setBuyerSawOffer, userInfo?.data?.buyerSawOffer]);

  return (
    <div className="buyer-offer">
      <Container className="buyer-offer-container">
        <h4>We’ve found Breeders that match your search!</h4>
        <p>
          Let’s kick off this process to match you with a certified dog breeder. We will send you up
          to three matches immediately via email (or otherwise request more info).
        </p>
        <img src={buyerOffer} alt="buyer-offer" className="buyer-offer-img" />

        <TTButton
          className="btn-primary-orchid mb-3"
          text="Get Started"
          width="100%"
          onClick={() =>
            (window.location.href = `https://telltail-digital.myshopify.com/cart/45456576020753:1?channel=buy_button&checkout[email]=${encodedEmail}`)
          }
        />

        <div className="not-ready d-flex justify-content-center">
          Not ready yet?
          <p
            onClick={() => {
              window.scrollTo({ top: 0 });
              navigate('/profile');
            }}
          >
            Maybe later
          </p>
        </div>
      </Container>
    </div>
  );
};

export default BuyerOffer;
