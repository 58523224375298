import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

//services
import { deleteConversationById } from 'services/api/apiCalls/community';
//types
import { TError } from 'types';
//constants
import { QUERY_KEYS } from 'constants/queryKeys';

const useMutationDeleteConversation = () => {
  const queryClient = useQueryClient();
  const { mutate: deleteConversation, isLoading: isLoadingDeleteConversation } = useMutation<
    AxiosResponse<string>,
    AxiosError<TError>,
    string
  >(conversationId => deleteConversationById(conversationId), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.ACTIVITY_FEED_LIST, {
        refetchInactive: true,
      });
    },
  });

  return {
    deleteConversation,
    isLoadingDeleteConversation,
  };
};
export default useMutationDeleteConversation;
