import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useToasts } from 'react-toast-notifications';

//services
import { resendConfirmationEmail } from 'services/api/apiCalls/auth';
//types
import { TError, IResendConfirmationEmailForm } from 'types';

const useMutationResendVerificationEmail = () => {
  const { addToast } = useToasts();

  return useMutation<AxiosResponse<void>, AxiosError<TError>, IResendConfirmationEmailForm>(
    resendConfirmationEmail,
    {
      onSuccess: () => {
        addToast('New verification token has been sent to your email!', {
          appearance: 'success',
          autoDismiss: true,
        });
      },
      onError: () => {
        addToast('Failed to sent new verification token!', {
          appearance: 'error',
          autoDismiss: true,
        });
      },
    },
  );
};

export default useMutationResendVerificationEmail;
