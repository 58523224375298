import React, { useState } from 'react';
import { Dropdown as BSDropdown } from 'react-bootstrap';
import { MdKeyboardArrowDown } from 'react-icons/md';

//types
import { IDropdown, ISelectOption } from 'types';
//styles
import './index.scss';

const Dropdown = ({ activeItem, items, error, smallText, onItemClick, ...props }: IDropdown) => {
  const [active, setActive] = useState(activeItem);

  const handleOnClick = (item: ISelectOption) => {
    setActive(item);
    if (onItemClick) {
      onItemClick(item);
    }
  };

  return (
    <BSDropdown className="dropdown" {...props}>
      <BSDropdown.Toggle
        className={`${active && 'active'} ${error && 'mb-0 input-error'} ${smallText && 'small'}`}
        id="dropdown-button-dark-example1"
        variant="none"
      >
        {active?.label || 'Select Here'}
        <MdKeyboardArrowDown />
      </BSDropdown.Toggle>

      <BSDropdown.Menu>
        {items.map(({ label, value }) => (
          <BSDropdown.Item
            key={value}
            onClick={e => {
              e.preventDefault();
              handleOnClick({ label, value });
            }}
            href="#/action-1"
            active={label === active?.label}
          >
            {label}
          </BSDropdown.Item>
        ))}
      </BSDropdown.Menu>
    </BSDropdown>
  );
};

export default Dropdown;
