export const montWidths = {
  A: 18 / 14,
  B: 15 / 14,
  C: 18 / 14,
  D: 15 / 14,
  E: 11 / 14,
  F: 11 / 14,
  G: 19 / 14,
  H: 15 / 14,
  I: 7 / 14,
  J: 14 / 14,
  K: 15 / 14,
  L: 13 / 14,
  M: 24 / 14,
  N: 16 / 14,
  O: 20 / 14,
  P: 14 / 14,
  Q: 19 / 14,
  R: 14 / 14,
  S: 14 / 14,
  T: 15 / 14,
  U: 15 / 14,
  V: 18 / 14,
  W: 25 / 14,
  X: 16 / 14,
  Y: 16 / 14,
  Z: 13 / 14,
  ' ': 4 / 14,
  0: 15 / 14,
  1: 8 / 14,
  2: 12 / 14,
  3: 12 / 14,
  4: 13 / 14,
  5: 12 / 14,
  6: 13 / 14,
  7: 13 / 14,
  8: 13 / 14,
  9: 12 / 14,
  '-': 9 / 14,
  ',': 4 / 14,
  '.': 4 / 14,
  '&': 14 / 14,
  '’': 4 / 14,
  "'": 4 / 14,
  '/': 10 / 14,
  '(': 10 / 14,
  ')': 10 / 14,
};
