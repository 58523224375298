import React, { ChangeEvent, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { sample, includes } from 'lodash';

//types
import { IBuildProfileForm, TBuyerStepper, TAuthContext } from 'types';
//components
import TTButton from 'components/common/TTButton';
import FilePickerInput from 'components/common/FilePickerInput';
//constants
import validExtensions from 'constants/images/validExtensions';
import { buyerProfileImages } from 'constants/images/buyerProfileImages';
//helpers
import { getImage } from 'helpers/getImage';
//hooks
import { useMutationUploadProfileImage } from '../__hooks/useMutationUploadProfileImage';
//services
import { AuthState } from 'services/auth';
import { useDataLayer } from 'services/dataLayerContext';
import { getCloudflareUploadUrl } from 'services/api/apiCalls/cloudflare';
//assets
import avatar from 'assets/images/breederProfile/avatar.png';
//validations
import { buildProfileSchema } from './Validation';

const BuildProfle = ({ setStep, step, buyerInfo }: TBuyerStepper) => {
  const pushToDataLayer = useDataLayer();
  const {
    setBuyerRegistrationInfo,
    state: { buyerRegistrationInfo },
  } = useContext(AuthState) as TAuthContext;
  const [isLoading, setIsLoading] = useState(false);

  const {
    setValue,
    trigger,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IBuildProfileForm>({
    resolver: yupResolver(buildProfileSchema),
    defaultValues: {
      profilePic: buyerRegistrationInfo?.buildProfileForm?.profilePic || sample(buyerProfileImages),
    },
  });

  //Mutation hooks
  const { uploadProfilePicture, isUploadingProfilePicture } = useMutationUploadProfileImage(
    setBuyerRegistrationInfo,
    setStep,
    step,
  );

  const loadProfilePic = (file: File | null) => {
    setValue('profilePic', file);
    trigger('profilePic');
  };

  const handleClickBrowse = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;

    if (validExtensions.includes(file?.type)) {
      const fileReader = new FileReader();
      fileReader.onload = () => loadProfilePic(file);
      fileReader.readAsDataURL(file);
    }
  };

  const onSubmit = async (data: IBuildProfileForm) => {
    pushToDataLayer({
      event: 'buyerBuildProfileClick',
      componentName: 'Buyer build profile',
      ...data,
    });
    setIsLoading(true);

    const cloudflareUploadUrl = await getCloudflareUploadUrl();
    if (cloudflareUploadUrl !== null) {
      uploadProfilePicture({
        pathAndName: `images/buyers/profile/${buyerInfo?._id}/${Date.now()}`,
        image: data.profilePic as File,
        url: cloudflareUploadUrl,
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="build-profile">
      <div className="build-profile__top">
        <h2>Say cheese and upload a photo!</h2>
        <span>
          Our research shows us that having a profile picture helps build stronger connections with
          breeders.
        </span>
      </div>

      <div className="build-profile__content">
        <div className="image-conatiner">
          <div className="d-flex flex-column align-items-center">
            <img src={getImage(watch('profilePic'), avatar)} />
            {errors && errors.profilePic && (
              <span className="text-error mt-2">{errors.profilePic.message}</span>
            )}
          </div>
          <div className="d-flex flex-column gap-3">
            <FilePickerInput
              name="build-profile-pic"
              view={
                <TTButton
                  className="btn-primary-white"
                  width="300px"
                  height="50px"
                  text="Upload Photo"
                />
              }
              onChange={handleClickBrowse}
            />

            <TTButton
              className="btn-primary-navy"
              width="300px"
              height="50px"
              text="Remove Photo"
              onClick={() => loadProfilePic(null)}
            />
          </div>
        </div>
      </div>

      <div className="buttons-container">
        <span
          className="skip-button"
          onClick={() => {
            setBuyerRegistrationInfo('buildProfileForm', {
              profilePic: watch('profilePic'),
            });

            setStep(step + 1);
            window.scrollTo({ top: 0 });
          }}
        >
          Skip for now
        </span>

        <TTButton
          height="50px"
          className="btn-primary-orchid"
          text="Continue"
          onClick={
            includes(buyerProfileImages, watch('profilePic')) ||
            watch('profilePic') === buyerRegistrationInfo.buildProfileForm?.profilePic
              ? () => {
                  setBuyerRegistrationInfo('buildProfileForm', {
                    profilePic: watch('profilePic'),
                  });

                  setStep(step + 1);
                  window.scrollTo({ top: 0 });
                }
              : handleSubmit(onSubmit)
          }
          disabled={isUploadingProfilePicture || isLoading}
        />
      </div>
    </div>
  );
};

export default BuildProfle;
