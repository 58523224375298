import * as Yup from 'yup';

// Create a validation schema for the entire form
export const aboutProgramSchema = Yup.object().shape({
  businessProgramName: Yup.string().required('This field is required.'),
  yearsOfExperience: Yup.number()
    .typeError('This field is required.')
    .required('This field is required.')
    .positive('Years of experience must be a positive number')
    .integer('Years of experience must be a round number'),
  breedsAndLeads: Yup.array().of(
    Yup.object().shape({
      breedsInProgram: Yup.mixed().test(
        'is-required',
        'This breed should be removed or this field is required.',
        (value, context) => {
          if (context.path.includes('[0]') && !value) {
            return context.createError({ message: 'At least one breed is required' });
          }

          if (!value) {
            return context.createError({
              message: 'This field is required or this breed should be removed.',
            });
          }

          return true;
        },
      ),
      leads: Yup.boolean(),
    }),
  ),
});
