import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import parse from 'html-react-parser';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { MdDelete } from 'react-icons/md';

//components
import TTButton from 'components/common/TTButton';
import KitImg from 'components/common/KitImg';
import TagItem from 'components/common/TagItem';
import Comments from './Comments';
import EditConversation from './EditConversationCard';
//helpers
import { replaceStringBasedOnObjectKeyValuePairs } from 'helpers/string';
//types
import { IDiscussionCard, IConversation, TAuthContext } from 'types';
//hooks
import useMutationFollowDiscussion from './__hooks/useMutationFollowDiscussion';
import useMutationLikeDislikeResource from './__hooks/useMutationLikeDislikeResource';
import useMutationDeleteConversation from './__hooks/useMutationDeleteConversation';
// import useMutationEditConversation from './__hooks/useMutationEditConversation';
//services
import { AuthState } from 'services/auth';
//assets
import likeIcon from 'assets/images/discussion/activityLike.svg';
import likedIcon from 'assets/images/discussion/activityLiked.svg';
import chatIcon from 'assets/images/discussion/chat.svg';
import followIcon from 'assets/images/discussion/follow.svg';
import followingIcon from 'assets/images/discussion/following.svg';
//styles
import './index.scss';

const DiscussionCard = ({
  item,
  isReply,
  level,
  setShowWHoleCard,
  showWholeCard,
  itemId,
  knowledgeTagsOptions,
}: IDiscussionCard) => {
  const {
    state: { breederId },
  } = useContext(AuthState) as TAuthContext;
  const [showReplies, setShowReplies] = useState(false);
  const [repliesCount, setRepliesCount] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  // Mutation hooks
  const { followDiscussionMutate, isLoadingFollowUnfollow } = useMutationFollowDiscussion();
  const { likeDislikeMutate, isLoadingLikeDislike } = useMutationLikeDislikeResource();
  // const { editConversation, isLoadingEditConversation } = useMutationEditConversation();
  const { deleteConversation, isLoadingDeleteConversation } = useMutationDeleteConversation();

  const isEditable = useMemo(() => item.createdBy._id === breederId, [item, breederId]);

  useEffect(() => {
    if (item.replies?.length) {
      let count = item.replies.length;
      item.replies.forEach(elem => {
        count += elem.replies?.length | 0;
      });
      setRepliesCount(count);
    }
  }, [item]);

  return (
    <>
      {/* Discussion heading */}
      <div className={`card-heading ${level === 1 && 'conversation'}`}>
        <Row className="m-0">
          <Col className="m-0 p-0">
            <KitImg
              width="39"
              src={item.createdBy.profileImage || ''}
              kitwidth="40"
              imgClassName="kit-image-heading"
            />
          </Col>
          <Col className="m-0 p-0 cursor-pointer">
            <Link
              to={`/profile/${item.createdBy._id}`}
              onClick={() => {
                window.scrollTo({ top: 0 });
              }}
              className="text-light"
            >
              <p className="heading-text-left-up"> {item.createdBy.displayName}</p>
              <p className="heading-text-left-down">{item.createdBy.programName}</p>
            </Link>
          </Col>
        </Row>
        <div className="heading-text-right">{moment(item.updatedDate).format('MMM DD, YYYY')}</div>
      </div>

      {/* Card text area */}
      <Col className={`card-text-area ${level !== 1 && 'is-reply'}`}>
        {/* Tags  */}
        <ul className={`comment__tags ${level !== 1 && 'is-reply'}`}>
          {item.tags && item.tags.map((tag, idx) => <TagItem key={idx} name={tag} />)}
        </ul>
        {/* Content */}
        <div className="card-content-text">
          {parse(
            replaceStringBasedOnObjectKeyValuePairs(item.contents || '', {
              '<iframe': '<video controls',
              '</iframe>': '</video>',
            }),
          )}
        </div>
      </Col>

      {/* Footer */}
      <div className="footer">
        {/* Replies and follow icons */}
        <div className="footer-left">
          {level === 1 && (
            <div
              className={`tt-hover ${isLoadingLikeDislike && 'disable-click'}`}
              onClick={() => likeDislikeMutate(item._id)}
            >
              <img src={item.isLiked ? likedIcon : likeIcon} alt="" />
              <span>
                {isLoadingLikeDislike ? (
                  <AiOutlineLoading3Quarters className="loading-animation svg-following" />
                ) : (
                  `${item.likes} ${item.likes !== 1 ? 'Likes' : 'Like'}`
                )}
              </span>
            </div>
          )}

          <div
            className="tt-hover"
            onClick={() => {
              setShowWHoleCard({ ...showWholeCard, [itemId]: true });
              setShowReplies(!showReplies);
            }}
          >
            <img src={chatIcon} alt="" />
            <span>
              {repliesCount} {repliesCount <= 1 ? 'Comment' : 'Comments'}
            </span>
          </div>

          <div
            className={`tt-hover ${isLoadingFollowUnfollow && 'disable-click'}`}
            onClick={() => !isReply && followDiscussionMutate(item._id)}
          >
            {isReply ? null : (
              <>
                <img
                  src={'isFollowing' in item && item.isFollowing ? followingIcon : followIcon}
                  alt="follow"
                />
                <span>
                  {isLoadingFollowUnfollow ? (
                    <AiOutlineLoading3Quarters className="loading-animation svg-following" />
                  ) : 'isFollowing' in item && item.isFollowing ? (
                    'Following'
                  ) : (
                    'Follow'
                  )}
                </span>
              </>
            )}
          </div>
        </div>

        <div className="footer-right">
          {isEditable && level === 1 && (
            <TTButton
              className="btn-primary-white reply-btn"
              text={isEdit ? 'Cancel' : 'Edit'}
              width={isEdit ? '70px' : '65px'}
              height="32px"
              fontSize="12px"
              onClick={() => {
                setShowWHoleCard({ ...showWholeCard, [itemId]: true });
                setIsEdit(!isEdit);
              }}
            />
          )}

          <Link
            to={`/discuss/${item._id}`}
            onClick={() => {
              window.scrollTo({ top: 0 });
            }}
            className="text-light"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TTButton
              className="btn-secondary-navy reply-btn"
              text="Reply"
              width="65px"
              height="32px"
              fontSize="12px"
            />
          </Link>

          {isEditable && level === 1 && (
            <>
              <div className="divider-vertical" />

              <TTButton
                className="tt-primary-icons reply-btn img-icon-button"
                text={<MdDelete color="#cc7190" size={26} />}
                width="26px"
                height="32px"
                loading={isLoadingDeleteConversation}
                disabled={isLoadingDeleteConversation}
                onClick={() => {
                  deleteConversation(item._id);
                }}
              />
            </>
          )}
        </div>
      </div>

      {isEdit && level === 1 && (
        <EditConversation
          conversation={item as IConversation}
          isFormOpened={isEdit}
          setIsFormOpened={setIsEdit}
          knowledgeTagsOptions={knowledgeTagsOptions}
        />
      )}

      {showReplies && item.replies.length > 0 && (
        <>
          <div className="comment-top-border" onClick={() => setShowReplies(!showReplies)}>
            {showReplies ? (
              <MdKeyboardArrowDown color="#8993B4" className="icon-arrow" />
            ) : (
              <MdKeyboardArrowUp />
            )}
            <div className="top-border" />
          </div>

          <div className="activity-comments container m-0" id="comments">
            {item?.replies?.map((reply, idx) => (
              <div key={`${reply._id}${idx}`}>
                <Comments
                  item={reply}
                  level={1}
                  isFetching={false}
                  resourceLink={`/discuss/${item._id}`}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default DiscussionCard;
