import React, { useRef, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import 'react-datepicker/src/stylesheets/datepicker.scss';

//types
import { IDialogProps, IBreeder, IAddDeleteAttendeeSchema } from 'types';
//hooks
import { useQueryUserList } from 'pages/AdminPanel/Users/__hooks/useQueryUserList';
import { useMutationAddNewAttendee } from '../__hooks/useMutationAddNewAttendee';
//component
import InfoView from 'components/common/InfoView';
//validations
import { AddNewAttendeeSchema } from './Validation';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddNewAttendeeDialog = ({ open, handleClose, pawfficeId }: IDialogProps) => {
  const errorSpanRef = useRef<HTMLDivElement | null>(null);
  const [selectedAttendee, setSelectedAttendee] = useState<IBreeder | undefined>(undefined);

  const {
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<IAddDeleteAttendeeSchema>({
    resolver: yupResolver(AddNewAttendeeSchema),
  });

  const handleDialogDismiss = () => {
    addAttendeeApiReset();
    handleClose();
  };

  const scrollToBottom = () =>
    errorSpanRef.current?.scrollIntoView({ inline: 'end', behavior: 'smooth' });

  //Query hooks
  const { data, refetchUserList } = useQueryUserList();
  //Mutation hooks
  const { addNewAttendeeMutate, isAdding, addAttendeeError, addAttendeeApiReset } =
    useMutationAddNewAttendee(handleDialogDismiss, scrollToBottom);

  useEffect(() => {
    if (open) refetchUserList();
  }, [open, refetchUserList]);

  const onSubmit = async (addNewAttendeeSchema: IAddDeleteAttendeeSchema) => {
    addNewAttendeeMutate({ ...addNewAttendeeSchema, pawfficeId: pawfficeId! });
  };

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="create-deal-dialog-slide-description"
    >
      <DialogTitle bgcolor="darkblue" color="white">
        Add New Attendee
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          width="30vw"
          height="30vh"
          m={2}
          position="relative"
        >
          <Autocomplete
            disablePortal
            id="attendee-autocomplete"
            options={data?.map(item => `${item.displayName} (${item.email})`) || []}
            sx={{ mt: 2 }}
            freeSolo
            onChange={(event, value) => {
              const attendeeEmail = value?.match(/\((.*)\)/)?.pop() || '';
              const attendItem = data?.find(item => item.email === attendeeEmail);
              setSelectedAttendee(attendItem);

              setValue('breederId', attendItem ? attendItem._id : '');
              clearErrors('breederId');
            }}
            renderInput={params => (
              <TextField
                {...params}
                color={errors.breederId?.message ? 'error' : 'primary'}
                label="Select Attendee"
              />
            )}
          />
          {errors && errors.breederId && (
            <span className="text-error mt-2">{errors.breederId.message}</span>
          )}

          <InfoView label="Breeder Id:" value={selectedAttendee?._id || '-'} sx={{ mt: 2 }} />
          <InfoView label="Full Name:" value={selectedAttendee?.displayName || '-'} />
          <InfoView label="Email:" value={selectedAttendee?.email || '-'} />
          <InfoView label="Program Name:" value={selectedAttendee?.businessProgram || '-'} />

          <span ref={errorSpanRef} className="text-error mt-4">
            {addAttendeeError?.response?.data.message || addAttendeeError?.message}
          </span>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogDismiss}>Cancel</Button>
        <Button onClick={handleSubmit(onSubmit)} disabled={isAdding}>
          {isAdding ? (
            <AiOutlineLoading3Quarters
              size={20}
              color="blue"
              style={{ margin: '0.2rem' }}
              className="loading-animation svg-loading"
            />
          ) : (
            'Add'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewAttendeeDialog;
