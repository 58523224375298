import React, { useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import MaterialReactTable, { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { Delete, Edit, PushPin, SettingsBackupRestore } from '@mui/icons-material';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import moment from 'moment';
import { capitalize } from 'lodash';
import 'bootstrap/dist/css/bootstrap.min.css';

//types
import { ICreateEditDealSchema, IDeal, IDialogContent } from 'types';
//hooks
import { useQueryAdminDealList } from './__hooks/useQuery';
import { useQueryKnowledgeTags } from 'pages/BreederAccount/__hooks/useQueryKnowledgeTags';
import {
  useMutationDeleteDeal,
  useMutationUndeleteDeal,
} from './__hooks/useMutationDeleteUndeleteDeal';
import { useMutationPinDeal, useMutationUnpinDeal } from './__hooks/useMutationPinUnpinDeal';
//components
import TTButton from 'components/common/TTButton';
import KitImg from 'components/common/KitImg';
import ActionButton from 'components/common/ActionButton';
import AlertDialog from 'components/common/AlertDialog';
import TagItem from 'components/common/TagItem';
import DealDetails from './DealDetails';
import CreateEditNewDealDialog from './CreateEditNewDealDialog';
//constants
import { csvOptions } from 'helpers/cvsOptions';

const DealsTable = ({ active }: { active: { label: string; value: string } }) => {
  const requestPayload = useRef<{ id: string; actionType: string } | null>(null);
  const dealToEdit = useRef<ICreateEditDealSchema>();
  const [showCreateDealDialog, setShowCreateDealDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState<IDialogContent | null>(null);

  //Query hooks
  const { isError, data, total, isFetching, isLoading, refetchDealList } = useQueryAdminDealList(
    active.value,
  );
  const { knowledgeTags, isLoadingKnowledgeTags } = useQueryKnowledgeTags();
  //Mutation hooks
  const { deleteDealMutate, isDeleting } = useMutationDeleteDeal(setDialogContent);
  const { undeleteDealMutate, isUndeleting } = useMutationUndeleteDeal(setDialogContent);
  const { pinDealMutate, isPinning } = useMutationPinDeal(setDialogContent);
  const { unpinDealMutate, isUnpinning } = useMutationUnpinDeal(setDialogContent);

  const columns = useMemo<MRT_ColumnDef<IDeal>[]>(
    () => [
      {
        header: 'Vendor Name',
        accessorKey: 'vendorName',
      },
      {
        header: 'Vendor Logo',
        accessorKey: 'vendorLogo',
        Cell: ({ row }: { row: MRT_Row<IDeal> }) => (
          <KitImg
            src={
              row?.original?.vendorLogo ||
              'https://telltail-breeder-resources.s3.amazonaws.com/images/profiles/App Icon Square.png'
            }
            kitwidth={100}
            alt="Vendor logo"
            style={{
              width: '50px',
              height: '50px',
              borderRadius: '25px',
              marginRight: '10px',
              objectFit: 'cover',
            }}
          />
        ),
      },
      {
        header: 'Start date',
        accessorKey: 'startDate',
        accessorFn: row => moment(row.startDate).format('LL'),
      },
      {
        header: 'Deal picture',
        accessorKey: 'dealPicture',
        Cell: ({ row }: { row: MRT_Row<IDeal> }) => (
          <KitImg
            src={
              row?.original?.dealPicture ||
              'https://telltail-breeder-resources.s3.amazonaws.com/images/profiles/App Icon Square.png'
            }
            kitwidth={100}
            alt="Deal picture"
            style={{
              width: '50px',
              height: '50px',
              borderRadius: '25px',
              marginRight: '10px',
              objectFit: 'cover',
            }}
          />
        ),
      },
      {
        header: 'Deal title',
        accessorKey: 'dealTitle',
      },
      {
        header: 'Discount code',
        accessorKey: 'discountCode',
      },
      {
        header: 'Visibility',
        accessorKey: 'visibility',
        Cell: ({ row }: { row: MRT_Row<IDeal> }) => (
          <ul className="breederProfile__tags mt-0">
            {row.original.visibility.map((visibility, idx) => (
              <TagItem
                key={visibility + idx}
                name={visibility === 'free' ? 'Breeder' : capitalize(visibility)}
              />
            ))}
          </ul>
        ),
      },
      {
        header: 'Tags',
        accessorKey: 'tags',
        Header: () => <label style={{ width: 110, textAlign: 'center' }}>Tags</label>,
        Cell: ({ row }: { row: MRT_Row<IDeal> }) => (
          <ul className="breederProfile__tags mt-0">
            {row.original.tags.map((tag, idx) => (
              <TagItem key={tag + idx} name={tag.toUpperCase()} />
            ))}
          </ul>
        ),
      },
    ],
    [],
  );

  // Download table to CSV format logic
  const csvDealOptions = csvOptions(
    columns,
    `${active.label}_Deals_TellTail_${moment()
      .utc()
      .utcOffset('-0700')
      .format('MMMM_D_YYYY_hh_mm_A')}`,
  );
  const csvExporter = new ExportToCsv(csvDealOptions);
  const csvData = data?.map(deal => ({
    vendorName: deal.vendorName,
    vendorLogo: deal.vendorLogo,
    startDate: moment(deal.startDate).format('LL'),
    dealPicture: deal.dealPicture,
    dealTitle: deal.dealTitle,
    discountCode: deal.discountCode,
    visibility: deal?.visibility?.join(', '),
    tags: deal?.tags?.join(', '),
  }));
  const handleExportData = () => {
    csvExporter.generateCsv(csvData);
  };

  // Handle table actions logic
  const handleEditPress = (row: MRT_Row<IDeal>) => {
    const tags = row.original.tags?.map(tag => ({ value: tag, label: tag }));
    dealToEdit.current = { ...row.original, tags };
    setShowCreateDealDialog(true);
  };

  const handleDeletePress = (row: MRT_Row<IDeal>) => {
    requestPayload.current = { id: row.original._id, actionType: 'delete' };
    setDialogContent({
      title: 'Delete Deal?',
      message: `Are you sure you want to delete ${row.original.dealTitle}?`,
    });
  };

  const handleRestorePress = (row: MRT_Row<IDeal>) => {
    requestPayload.current = { id: row.original._id, actionType: 'restore' };
    setDialogContent({
      title: 'Restore Deal?',
      message: `Are you sure you want to restore ${row.original.dealTitle}?`,
    });
  };

  const handlePinPress = (row: MRT_Row<IDeal>) => {
    requestPayload.current = { id: row.original._id, actionType: 'pin' };
    pinDealMutate(row.original._id);
  };

  const handleUnpinPress = (row: MRT_Row<IDeal>) => {
    requestPayload.current = { id: row.original._id, actionType: 'unpin' };
    unpinDealMutate(row.original._id);
  };

  const renderDealTableActions = ({ row }: { row: MRT_Row<IDeal> }) => {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" gap="0.5rem">
        {active.label === 'Current & Upcoming' && (
          <>
            <ActionButton
              title="Edit"
              Icon={<Edit />}
              color="gray"
              onClick={() => handleEditPress(row)}
            />
            <ActionButton
              title={row.original.isPinned ? 'Unpin' : 'Pin'}
              Icon={<PushPin />}
              color={row.original.isPinned ? 'green' : 'silver'}
              isLoading={
                (isPinning || isUnpinning) && requestPayload.current?.id === row.original._id
              }
              onClick={() => (row.original.isPinned ? handleUnpinPress(row) : handlePinPress(row))}
            />
            <ActionButton title="Delete" Icon={<Delete />} onClick={() => handleDeletePress(row)} />
          </>
        )}

        {active.label === 'Deleted' && (
          <ActionButton
            title="Restore"
            Icon={<SettingsBackupRestore />}
            color="green"
            onClick={() => handleRestorePress(row)}
          />
        )}
      </Box>
    );
  };

  const knowledgeTagsOptions = knowledgeTags?.map(tag => ({ value: tag, label: tag }));

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data ?? []}
        rowCount={total ?? 0}
        initialState={{
          columnVisibility: { status: false },
          pagination: { pageSize: 10, pageIndex: 0 },
        }}
        memoMode="cells"
        state={{
          isLoading: isLoading || isLoadingKnowledgeTags,
          showAlertBanner: isError,
          showProgressBars: isFetching,
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 20, 50],
        }}
        enableColumnOrdering
        enableGlobalFilter={false}
        renderTopToolbarCustomActions={() => (
          <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', pb: '2rem', flexWrap: 'wrap' }}>
            <TTButton
              className="btn-primary-orchid"
              height={40}
              width={200}
              disabled={isFetching}
              text="Export to CSV"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
            />

            <TTButton
              className="btn-primary-orchid"
              height={40}
              width={200}
              marginLeft="10px"
              text="Create New Deal"
              onClick={() => {
                dealToEdit.current = undefined;
                setShowCreateDealDialog(true);
              }}
            />
          </Box>
        )}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={renderDealTableActions}
        renderDetailPanel={({ row }) => <DealDetails deal={row.original} />}
      />

      <AlertDialog
        isOpened={Boolean(dialogContent)}
        dialogContent={dialogContent}
        isLoading={isDeleting || isUndeleting}
        onAgree={() => {
          if (!requestPayload.current) return;

          const { id, actionType } = requestPayload.current;
          if (actionType === 'delete') deleteDealMutate(id);
          else if (actionType === 'restore') undeleteDealMutate(id);
        }}
        handleClose={() => setDialogContent(null)}
      />

      <CreateEditNewDealDialog
        open={showCreateDealDialog}
        handleClose={() => {
          refetchDealList();
          setShowCreateDealDialog(false);
        }}
        knowledgeTagsOptions={knowledgeTagsOptions}
        dealToEdit={dealToEdit.current}
      />
    </>
  );
};

export default DealsTable;
