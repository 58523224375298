/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { map, includes } from 'lodash';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import LogRocket from 'logrocket';
import moment from 'moment';

//components
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
//pages
import ErrorScreen from 'pages/404';
import Login from 'pages/Login';
import ResendVerificationEmail from 'pages/Login/ResendVerificationEmail';
import Home from 'pages/Home';
import About from 'pages/About';
import FAQs from 'pages/FAQs';
import TermsAndPrivacy from 'pages/TermsAndPrivacy';
import CommunityGuidelines from 'pages/CommunityGuidelines';
import ForgotPassword from 'pages/ForgotPassword';
import BreederRegistration from 'pages/BreederRegistration';
import BuyerRegistration from 'pages/BuyerRegistration';
import DevPanel from 'pages/DevPanel';
import PublicBreederProfile from 'pages/PublicBreederProfile';
import NonExistingRoute from 'pages/NonExistingRoute';
import BreedDirectory from 'pages/BreedDirectory';
import BreedDetails from 'pages/BreedDirectory/BreedDetails';
import ThankYou from 'pages/ThankYou';
//routes
import { PublicRoute, ProtectedRoute } from './RoutesWrappers';
//configs
import { mainRoutes } from 'configs/privateRoutesAccessConfigs';
//helpers
import getAllowedRoutes from 'helpers/routes/getAllowedRoutes';
import eventSink from 'helpers/eventSink';
//hooks
import { useQueryBreederInfo } from 'hooks/commonQueryHooks/useQueryBreederInfo';
import { useQueryUserStatus } from './__hooks/useQueryUserStatus';
import { useQueryBuyerInfo } from 'pages/BuyerAccount/__hooks/useQueryBuyerInfo';
import { useQueryMatches } from 'pages/BreederAccount/__hooks/useQueryMatches';
//services
import { AuthState } from 'services/auth';
import CBACProvider from 'services/cbac';
//types
import { TAuthContext, IUserInfo } from 'types';

const AppRoutes = () => {
  const {
    setBreederId,
    state: { isAuthenticated },
  } = useContext(AuthState) as TAuthContext;
  const [currentUserInfo, setCurrentUserInfo] = useState<IUserInfo | undefined>(undefined);
  const { state } = useLocation();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const prevPath = state?.prevPath;

  // Query hooks
  const { userInfo, isLoadingUser, isFetchingUser, errorUser } = useQueryUserStatus(
    setBreederId,
    isAuthenticated, //enable only if user is authenticated
  );
  const { breederInfo } = useQueryBreederInfo(
    userInfo?.breederId as string,
    isAuthenticated && !!userInfo?.breederId,
  );
  const { buyerInfo } = useQueryBuyerInfo(isAuthenticated, userInfo?.isBuyer);

  const userImage = useMemo(() => {
    if (userInfo?.isBuyer) return buyerInfo?.profilePicture;
    else return breederInfo?.profilePicUrl;
  }, [breederInfo?.profilePicUrl, buyerInfo?.profilePicture, userInfo?.isBuyer]);

  const userName = useMemo(() => {
    if (userInfo?.isBuyer) return buyerInfo?.firstName + ' ' + buyerInfo?.lastName;
    else return breederInfo?.firstName + ' ' + breederInfo?.lastName;
  }, [
    breederInfo?.firstName,
    breederInfo?.lastName,
    buyerInfo?.firstName,
    buyerInfo?.lastName,
    userInfo?.isBuyer,
  ]);

  const programName = useMemo(() => {
    if (!userInfo?.isBuyer) return breederInfo?.businessProgram;
  }, [breederInfo?.businessProgram, userInfo?.isBuyer]);

  useEffect(() => {
    // Triggers event sink only on login, skips it on page refresh and background refetch
    if (prevPath && userInfo && breederInfo && isAuthenticated) {
      // Identify user for LogRocket session
      // LogRocket.identify(breederInfo?.displayName || 'Unknown', {
      //   name: breederInfo?.displayName || 'Unknown',
      //   email: breederInfo?.userId || 'Unknown',
      //   ttUser: includes(breederInfo?.email, 'telltail'),
      // });
      eventSink(
        // Any data is of type IEventSink is first param
        {
          userId: breederInfo?.userId || '',
          ttUser: includes(breederInfo.email, 'telltail'),
          time: moment().toISOString(),
          type: 'login',
          subtype: '',
        },
        // Meta data is second param (IEventSinkMeta)
        // Its UTM data for login and join events, empty for now
        {},
      );
    }
    isAuthenticated && setCurrentUserInfo(userInfo);
    !isAuthenticated && setCurrentUserInfo(undefined);
  }, [userInfo, breederInfo, isAuthenticated, prevPath]);

  const allowedMainRoutes =
    userInfo && getAllowedRoutes(userInfo.userRoles, mainRoutes(userInfo.isBuyer));

  if (isLoadingUser || isFetchingUser)
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center mb-5">
        <AiOutlineLoading3Quarters className="loading-animation svg-loading w-25 mt-5" />
      </div>
    );
  else if (errorUser) return <Navigate to="/404" replace />;

  return (
    <CBACProvider userRoles={userInfo?.userRoles}>
      <Header
        userInfo={currentUserInfo}
        profilePicUrl={userImage}
        name={userName}
        programName={programName}
      />
      {/* All application routes */}
      <Routes>
        {/* Public routes */}
        <Route path="*" element={<NonExistingRoute />} />
        <Route
          index
          element={
            <PublicRoute isAuthenticated={isAuthenticated} userInfo={currentUserInfo}>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="devpanel"
          element={
            <PublicRoute isAuthenticated={isAuthenticated} userInfo={currentUserInfo}>
              <DevPanel />
            </PublicRoute>
          }
        />
        {/* Reroutes to telltail.com */}
        <Route
          path="home"
          element={
            <PublicRoute isAuthenticated={isAuthenticated} userInfo={currentUserInfo}>
              <Home />
            </PublicRoute>
          }
        />
        <Route
          path="breedopedia"
          element={
            <PublicRoute isAuthenticated={isAuthenticated} userInfo={currentUserInfo}>
              <BreedDirectory />
            </PublicRoute>
          }
        />
        <Route
          path="breedopedia/:breedId"
          element={
            <PublicRoute isAuthenticated={isAuthenticated} userInfo={currentUserInfo}>
              <BreedDetails />
            </PublicRoute>
          }
        />
        <Route
          path="about"
          element={
            <PublicRoute isAuthenticated={isAuthenticated} userInfo={currentUserInfo}>
              <About />
            </PublicRoute>
          }
        />
        <Route
          path="faq"
          element={
            <PublicRoute isAuthenticated={isAuthenticated} userInfo={currentUserInfo}>
              <FAQs />
            </PublicRoute>
          }
        />
        <Route
          path="terms"
          element={
            <PublicRoute isAuthenticated={isAuthenticated} userInfo={currentUserInfo}>
              <TermsAndPrivacy isTermsPage />
            </PublicRoute>
          }
        />
        <Route
          path="privacy"
          element={
            <PublicRoute isAuthenticated={isAuthenticated} userInfo={currentUserInfo}>
              <TermsAndPrivacy />
            </PublicRoute>
          }
        />
        {/* Rest of the public routes */}
        <Route
          path="community-guidelines"
          element={
            <PublicRoute isAuthenticated={isAuthenticated} userInfo={currentUserInfo}>
              <CommunityGuidelines />
            </PublicRoute>
          }
        />
        <Route
          path="buyer-login"
          element={
            <PublicRoute isAuthenticated={isAuthenticated} userInfo={currentUserInfo}>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="login"
          element={
            <PublicRoute isAuthenticated={isAuthenticated} userInfo={currentUserInfo}>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="breeder-registration"
          element={
            <PublicRoute
              isAuthenticated={isAuthenticated}
              userInfo={currentUserInfo}
              breederInfo={breederInfo}
            >
              <BreederRegistration />
            </PublicRoute>
          }
        />

        <Route
          path="thank-you"
          element={
            <PublicRoute isAuthenticated={isAuthenticated} userInfo={currentUserInfo}>
              <ThankYou />
            </PublicRoute>
          }
        />
        <Route
          path="buyer-registration"
          element={
            <PublicRoute isAuthenticated={isAuthenticated} userInfo={currentUserInfo}>
              <BuyerRegistration />
            </PublicRoute>
          }
        />
        <Route
          path="forgot-password"
          element={
            <PublicRoute isAuthenticated={isAuthenticated} userInfo={currentUserInfo}>
              <ForgotPassword />
            </PublicRoute>
          }
        />
        <Route
          path="public-profile/:userId"
          element={
            <PublicRoute isAuthenticated={isAuthenticated} userInfo={currentUserInfo}>
              <PublicBreederProfile />
            </PublicRoute>
          }
        />
        <Route
          path="resend-verification-email"
          element={
            <PublicRoute isAuthenticated={isAuthenticated} userInfo={currentUserInfo}>
              <ResendVerificationEmail />
            </PublicRoute>
          }
        />

        {/* Private routes */}
        {userInfo &&
          map(allowedMainRoutes, ({ path, component: Component }, key) => (
            <Route
              key={key}
              path={path}
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated} userInfo={userInfo}>
                  {Component}
                </ProtectedRoute>
              }
            />
          ))}
        {/* Error routes */}
        <Route path="404" element={<ErrorScreen />} />
      </Routes>
      <Footer />
    </CBACProvider>
  );
};

export default withTranslation()(AppRoutes);
