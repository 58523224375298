import * as Yup from 'yup';

export const addLitterSchema = Yup.object().shape({
  breedName: Yup.object()
    .shape({
      value: Yup.string().required('Breed is required'),
      label: Yup.string().required('Breed is required'),
    })
    .required('Breed name is required'),
  goHomeDate: Yup.date().required('Go home date is required.').nullable().typeError('Invalid Date'),
  minPrice: Yup.number()
    .typeError('Min. price is required')
    .required('Min. price is required')
    .positive('Min. price must be a positive number')
    .integer('Min. price must not contain any deciamls')
    .min(1, 'Min. price must be greater than 1 USDs')
    .test({
      name: 'max',
      exclusive: false,
      params: {},
      message: '',
      test: function (value) {
        if (value >= this.parent.maxPrice)
          return this.createError({
            message: `Min. price must be less than ${this.parent.maxPrice} USDs`,
          });

        return true;
      },
    })
    .max(50000, 'Min. price must be less than 50000 USDs'),
  maxPrice: Yup.number()
    .typeError('Max. price is required')
    .required('Max. price is required')
    .positive('Max. price must be a positive number')
    .integer('Max. price must not contain any deciamls')
    .test({
      name: 'min',
      exclusive: false,
      params: {},
      message: '',
      test: function (value) {
        if (this.parent.minPrice < 1 || this.parent.minPrice > 50000) return true;

        if (value < this.parent.minPrice)
          return this.createError({
            // message: `Max. price must be greater than or equals to ${this.parent.minPrice} USDs`,
            message: 'Please set the max price to above the min price.',
          });

        return true;
      },
    })
    .max(50000, 'Max. price must be less than 50000 USDs'),
  minWeight: Yup.number()
    .typeError('Min. weight is required')
    .required('Min. weight is required')
    .positive('Min. weight must be a positive number')
    .integer('Min. weight must not contain any deciamls')
    .min(1, 'Min. weight must be greater than 1 lbs')
    .test({
      name: 'max',
      exclusive: false,
      params: {},
      message: '',
      test: function (value) {
        if (value >= this.parent.maxWeight)
          return this.createError({
            message: `Min. weight must be less than ${this.parent.maxWeight} lbs`,
          });

        return true;
      },
    })
    .max(200, 'Min. weight must be less than 200 lbs'),
  maxWeight: Yup.number()
    .typeError('Max. weight is required')
    .required('Max. weight is required')
    .positive('Max. weight must be a positive number')
    .integer('Max. weight must not contain any deciamls')
    .test({
      name: 'min',
      exclusive: false,
      params: {},
      message: '',
      test: function (value) {
        if (this.parent.minWeight < 1 || this.parent.minWeight > 200) return true;

        if (value < this.parent.minWeight)
          return this.createError({
            // message: `Max. weight must be greater than or equals to ${this.parent.minWeight} lbs`,
            message: 'Please set the max weight to above the min weight.',
          });

        return true;
      },
    })
    .max(200, 'Max. weight must be less than 200 lbs'),
  acceptLeads: Yup.boolean(),
});
