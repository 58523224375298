import { useMutation } from 'react-query';
import { useToasts } from 'react-toast-notifications';

//services
import { validateEmailToken } from 'services/api/apiCalls/auth';
//types
import { IValidateEmailToken, ILoginResponse, TConfirmEmailToken } from 'types';

const useMutationValidateEmailToken = ({ setTokens, setLoading }: TConfirmEmailToken) => {
  const { addToast } = useToasts();

  const { mutate: validateToken, isLoading } = useMutation<
    ILoginResponse,
    Error,
    IValidateEmailToken
  >(validateEmailToken, {
    retry: false,
    onSuccess: data => {
      setLoading(false);
      if (data !== undefined) {
        setTokens(data?.data?.accessToken);
      }
      addToast(
        'Your email is successfully confirmed. You can now login with your username and password!',
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );
    },
    onError: () => {
      setLoading(false);
      addToast('Your email is not confirmed. Please follow a link from your email.', {
        appearance: 'error',
        autoDismiss: true,
      });
    },
  });

  return { validateToken, isValidatingEmailToken: isLoading };
};
export default useMutationValidateEmailToken;
